import { useEffect, useState, useRef, useContext } from 'react';
import { Link, useHistory, useLocation, NavLink } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsWallet2 } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import ProgressBar from 'react-bootstrap/ProgressBar';
import JWT from 'jsonwebtoken';
import LogRocket from 'logrocket';
import { useIntercom } from 'react-use-intercom';

import store, { update } from '../../states';
import { api } from '../../services/constants';
import { UserInfoContext } from '../contexts/userInfoContext';

import Tour from '../modals/tour';
import MsaSign from '../modals/msa-sign';
import DemandTour from '../components/tour/Demand';
import logo from '../../assets/images/logo.svg';
import TextSkeleton from '../loaders/text';
import ButtonSkeleton from '../loaders/button';
import consumerData from '../contexts/OverViewContext';

const DemandHeader = () => {
  // Context API
  const { msaDetails, customerInfo, userInfo, clearAll }: any = useContext(UserInfoContext);
  const { clearAllStates }: any = consumerData();

  const { push } = useHistory();
  const location = useLocation();

  const [balance, sBalance] = useState({
    spending: '$0.00',
    platformCost: '$0.00',
  });
  const [topBar, sTopBar] = useState<any>('block');
  const [topBarAccount, sTopBarAccount] = useState(true);
  const [topBarCreateEdpoint, sTopBarCreateEdpoint] = useState(true);
  const [topBarMSA, sTopBarMSA] = useState(true);
  const [tour, sTour] = useState(false);
  const [tourCompleted, sTourCompleted] = useState(false);
  const [steps, sSteps] = useState(false);
  const [tourStepDone, sTourStepDone] = useState(false);
  const [topBarProgress, sTopBarProgress] = useState<number>(0);
  const [companyLogo, sCompanyLogo] = useState();
  const [companyName, sCompanyName] = useState();
  const [showMsaSign, sShowMsaSign] = useState(false);
  const [endPointStatus, sEndPointStatus] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  const [loader, sLoader] = useState(false);
  const [loaderCustomer, sLoaderCustomer] = useState(false);
  const [tourStage, setTourStage] = useState('');
  const [isBarVisible, setIsBarVisible] = useState(true); // Onboard bar hide/show

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  /* Update Company Profile picture in header */
  useEffect(() => {
    if (customerInfo?.thumb) sCompanyLogo(customerInfo?.thumb);
    if (customerInfo?.company_name) sCompanyName(customerInfo?.company_name);
  }, [customerInfo.thumb, customerInfo.company_name]);

  const completeTour = () => {
    sTourCompleted(false);
    setTourStage('info');
    // getUserInfo();
    // @ts-ignore
    // document.getElementsByClassName('react-joyride__beacon')[0].click();
  };

  useEffect(() => {
    if (loader) {
      if (tourStage === 'info' && steps) {
        setTimeout(() => {
          //@ts-ignore
          document.getElementsByClassName('react-joyride__beacon')[0]?.click();
        }, 500);
      }
    }
  }, [tourStage, loader]);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      sTopBar('none');
    }
  }, [location.pathname]);

  useEffect(() => {
    fetch(api + '/frontend-glossary/en')
      .then((response) => response.json())
      .then((result) => {
        store.dispatch(update(result.data));
      });
  }, []);

  useEffect(() => {
    // User Info
    if (userInfo?.user?.id) {
      sLoader(true);

      setTourStage(userInfo?.user?.tour);
      sBalance({
        spending: userInfo?.balance?.spending_formatted ?? '-',
        platformCost: userInfo?.balance?.platform_cost_formatted ?? '-',
      });

      localStorage.setItem('iTour', userInfo?.user?.tour ?? 'false'); //Intercom
      localStorage.setItem(
        'user',
        JSON.stringify({
          email: userInfo?.user?.email ?? '',
          firstName: userInfo?.user?.firstname ?? '',
          lastName: userInfo?.user?.lastname ?? '',
          photo: userInfo?.user?.thumb ?? '',
        })
      );

      if (
        userInfo?.user?.tour !== 'info' &&
        userInfo?.user?.tour !== 'done' &&
        !userInfo?.user?.infyadmin
      ) {
        sTour(true);
      }

      // Step Tour
      if (userInfo?.user?.tour !== 'done' && !userInfo?.user?.infyadmin) {
        sSteps(true);
      }
    }

    // Milestone Funcationality
    if (customerInfo?.id) {
      sLoaderCustomer(true); // Verify & Removed it if not useable
      if (customerInfo?.milestone) {
        const milestones = customerInfo?.milestone;
        sTopBarProgress(customerInfo?.milestone_percent);
        sEndPointStatus(customerInfo?.milestone?.endpoint);
        if (customerInfo?.milestone_percent === 100) sTopBar('none');
        if (customerInfo?.milestone_percent < 100) {
          sTopBar('block');
        }
        localStorage.setItem('endpointStatus', customerInfo?.milestone.endpoint);

        const keys = Object.keys(milestones);
        keys.forEach((key) => {
          switch (key) {
            case 'account':
              sTopBarAccount(milestones[key]);
              break;
            case 'endpoint':
              sTopBarCreateEdpoint(milestones[key]);
              break;
            case 'msa_status':
              sTopBarMSA(milestones[key]);
              break;
            default:
              break;
          }
        });
      }
    }
  }, [userInfo, customerInfo]);

  // On boarding bar is hide for next 24 hour
  useEffect(() => {
    const shouldHideBar = localStorage.getItem('onboard_bar');

    if (shouldHideBar) {
      const currentTime = new Date().getTime();
      if (currentTime < parseInt(shouldHideBar)) {
        setIsBarVisible(false);
      } else {
        setIsBarVisible(true);
        localStorage.removeItem('onboard_bar');
      }
    }
  }, []);

  /* hide on click top bar */
  const hideTopBar = () => {
    sTopBar(!topBar);
    const currentTime = new Date().getTime();
    const hideUntil: any = currentTime + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    localStorage.setItem('onboard_bar', hideUntil);
    setIsBarVisible(false);
  };

  /* open modal on click MSA execute */
  const onClickMsaSignButton = (msaData: any) => {
    if (!msaData.generate_button) {
      sShowMsaSign(true);
    }
    if (msaData.generate_button && !msaData.status) {
      toast.success('MSA was sent to your email. Please check your mailbox.');
    }
  };

  const [clickOnCreate, setClickOnCreate] = useState(false);
  const [countdown, setCountdown] = useState(100);
  const refCreate = useRef(null);

  const handleClickCreate = () => {
    setClickOnCreate(!clickOnCreate);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!tour && tourStepDone && endPointStatus) {
        //@ts-ignore
        refCreate?.current?.click();
      }
      setClickOnCreate(true);
      setCountdown(countdown - 1);
    }, 2000); //miliseconds
    return () => {
      clearTimeout(timeout);
    };
  }, [tour, tourStepDone, endPointStatus]);

  const [MSAStatusCHeck, sMSAStatusCHeck] = useState(false);
  const refCreate1 = useRef<any>(null);

  const handleClickCheckMSA = () => {
    sMSAStatusCHeck(true);
  };

  useEffect(() => {
    refCreate1?.current?.click();
  }, [MSAStatusCHeck]);

  // tour step
  const getTourDoneProps = (data: any) => {
    sTourStepDone(data);
  };

  useEffect(() => {
    if (tourStepDone) sSteps(false);
  }, [tourStepDone]);

  const onImageError = (e: any) => {
    e.target.src = 'https://infy-ads.s3.us-east-2.amazonaws.com/static/placeholder.png';
  };

  // Intercom & LogRocket Initialization
  const { boot } = useIntercom();

  useEffect(() => {
    if (localStorage.getItem('iTour') === 'done' || tourStage === 'done') {
      const token = localStorage.getItem('token') || '';
      const decoded = JWT.decode(token, { complete: true });
      const { user_id, company_id: customer_id, identity: email }: any = decoded?.payload || {};
      const userName = JSON.parse(localStorage.getItem('user') || '{}').firstName || '';
      bootWithProps({ user_id, customer_id, email, userName });
      bootLogRocket({ user_id, email, userName });
    }
  }, [tourStage, localStorage.getItem('iTour')]);

  const bootWithProps = ({ user_id, customer_id, email, userName }: any) => {
    boot({
      name: userName,
      email: email,
      customAttributes: { user_id: user_id, customerID: customer_id },
    });
  };

  const bootLogRocket = ({ user_id, email, userName }: any) => {
    LogRocket.identify(user_id, {
      email: email,
      name: userName,
      subscriptionType:
        (customerInfo?.customer_type === 'demand' ? 'Demand' : 'SPO') ||
        (localStorage.getItem('customer_type') === 'demand' ? 'Demand' : 'SPO'),
    });
  };

  return (
    <>
      {/* MSA Function */}
      {!MSAStatusCHeck && (
        <MsaSign
          show={showMsaSign}
          msaSignCheck={sMSAStatusCHeck}
          onHide={() => {
            sShowMsaSign(false);
          }}
        />
      )}

      {/* Step Tour Start */}
      {location.pathname.startsWith('/sources') && tourStage === 'info' && (
        <DemandTour
          endPointStatus={endPointStatus}
          steps={steps}
          loader={loader}
          tourDoneProps={getTourDoneProps}
        />
      )}

      {/* Modal Tour */}
      {location.pathname.startsWith('/sources') && tour && (
        <Tour
          show={tour}
          onHide={() => {
            sTour(false);
          }}
          tourCompleted={completeTour}
        />
      )}

      <div
        className={
          topBar === 'block' && loaderCustomer && isBarVisible ? 'view-top-bar header' : 'header'
        }
      >
        {/* Milestone View Start */}
        {topBarProgress !== 100 && loaderCustomer && isBarVisible && (
          <>
            {(location.pathname.startsWith('/sources') ||
              location.pathname.startsWith('/settings')) && (
              <div
                className={
                  topBar
                    ? 'top__bar d-flex justify-content-between align-items-center d-block'
                    : 'top__bar d-flex justify-content-between align-items-center d-none'
                }
              >
                <p>{glossary.onboarding_title}</p>
                <Form.Group className='d-flex form-group gap-3'>
                  <div className='top__bar__child'>
                    <Form.Check
                      checked={topBarAccount}
                      disabled={topBarAccount}
                      type='checkbox'
                      label={glossary.onboarding_step_create_account}
                      className='m-0'
                    />
                  </div>
                  <div
                    className='top__bar__child'
                    onClick={() => {
                      // @ts-ignore
                      document.getElementById('first-btn')?.children[0]?.click();
                    }}
                  >
                    <Form.Check
                      checked={topBarCreateEdpoint}
                      disabled={topBarCreateEdpoint}
                      type='checkbox'
                      label={glossary.dsp_onboarding_step_create_ednpoint}
                      className='m-0'
                    />
                  </div>
                  <div
                    className='top__bar__child'
                    ref={refCreate}
                    onClick={() => {
                      if (msaDetails) onClickMsaSignButton(msaDetails);
                      if (!MSAStatusCHeck) handleClickCreate();
                    }}
                  >
                    <Form.Check
                      ref={refCreate1}
                      checked={topBarMSA || MSAStatusCHeck}
                      disabled={topBarMSA || MSAStatusCHeck}
                      type='checkbox'
                      label={glossary.onboarding_step_msa}
                      className='m-0'
                      onClick={() => {
                        handleClickCheckMSA();
                      }}
                      style={{ pointerEvents: 'none', cursor: 'default' }}
                    />
                  </div>
                </Form.Group>
                <div className='align-items-center d-flex gap-2' style={{ width: 150 }}>
                  <ProgressBar
                    style={{ width: 120 }}
                    now={topBarProgress}
                    label={`${topBarProgress}%`}
                  />
                  <small
                    className='fw-lighter pt-1'
                    style={{ fontSize: 11 }}
                  >{`${topBarProgress}%`}</small>
                </div>
                <p className='top__bar__hide' onClick={() => hideTopBar()}>
                  {glossary.onboarding_hide_button}
                </p>
              </div>
            )}
          </>
        )}
        {/* Milestone View End */}

        <div
          className={
            tourCompleted && !tourStepDone
              ? 'header-menu d-flex justify-content-between align-items-center my-0 w-auto tour-overlay'
              : 'header-menu d-flex justify-content-between align-items-center my-0 w-auto'
          }
        >
          <div className={'col col-auto header__left'}>
            <Link to={{ pathname: '/sources/demand', state: 'clickLogo' }}>
              <img src={logo} alt={'infy-tv-logo'} height='50px' width='150px' />
            </Link>
          </div>

          {/* {!endPointStatus && (
            <div className={'col header__middle'}>
              <TextSkeleton height={20} width={80} lineCount={1} />
              {localStorage.getItem('customer_type') === 'spo' && (
                <TextSkeleton height={20} width={80} lineCount={1} />
              )}
              <TextSkeleton height={20} width={80} lineCount={1} />
            </div>
          )} */}

          {endPointStatus && (
            <div className={'col header__middle'}>
              <NavLink
                to={{ pathname: '/sources/demand', state: 'clickLogo' }}
                activeClassName='active'
              >
                {localStorage.getItem('customer_type') === 'spo' ? 'My Demand' : 'Demand'}
              </NavLink>
              {localStorage.getItem('customer_type') === 'spo' && (
                <NavLink to='/sources/publishers' activeClassName='active'>
                  Publishers
                </NavLink>
              )}
              <NavLink to='/sources/deals' activeClassName='active'>
                Request Deal
              </NavLink>
            </div>
          )}

          <div className={'col col-auto header__right d-none d-md-block'}>
            <div className={'d-flex align-items-center'}>
              <>
                {/* {!endPointStatus && (
                  <div className='header__right__wrap '>
                    <div className='header__right__wrap__box'>
                      <div className={'tour-payouts'}>
                        <ButtonSkeleton width={110} height={38} />
                      </div>
                    </div>
                    <div className='header__right__wrap__box tour-balance'>
                      <span className='header__right__wrap__box__lable'>
                        <TextSkeleton height={15} width={90} lineCount={1} />
                      </span>
                      <span className='header__right__wrap__box__value'>
                        <TextSkeleton height={20} width={70} lineCount={1} />
                      </span>
                    </div>
                  </div>
                )} */}
                <div className='header__right__wrap'>
                  <div className='header__right__wrap__box'>
                    <div className={'tour-finance'}>
                      <button
                        className='button button-secondary'
                        onClick={() => {
                          push('/settings/finance', { section: 'finance' });
                          localStorage.setItem('section', 'finance');
                          localStorage.setItem('selectedTabSetting', 'finance');
                        }}
                      >
                        <BsWallet2 className='svg-icon' strokeWidth='0.3' />
                        {glossary.dsp_header_button_finance}
                      </button>
                    </div>
                  </div>
                  <div className='header__right__wrap__box tour-spending'>
                    <span className='header__right__wrap__box__lable'>
                      {glossary.dsp_header_field_label_spending}
                    </span>
                    <span className='header__right__wrap__box__value'>{balance?.spending}</span>
                  </div>
                </div>

                {/* Header Menu start*/}
                {!companyName ? (
                  <ButtonSkeleton width={150} height={50} />
                ) : (
                  <Dropdown className='tour-settings'>
                    <Dropdown.Toggle
                      className={
                        'button button-secondary d-flex justify-content-end align-items-center'
                      }
                      style={{ minWidth: '110px' }}
                    >
                      {/* Any Issue width: '110px' */}
                      <span
                        className='text-truncate text-start'
                        style={{ maxWidth: '110px', minWidth: '70px' }}
                        title={companyName}
                      >
                        {companyName === '' ? 'New User' : companyName}
                      </span>
                      <img
                        // @ts-ignore
                        src={
                          companyLogo ??
                          'https://infy-ads.s3.us-east-2.amazonaws.com/static/placeholder.png'
                        }
                        height={42}
                        width={42}
                        className='rounded-circle ms-2'
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          objectFit: 'contain',
                        }}
                        onError={onImageError}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          localStorage.removeItem('selectedTabSetting');
                          push('/settings/profile');
                        }}
                      >
                        {glossary.account_ddl_option_settings}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          localStorage.clear();
                          push('/login');
                          clearAll();
                          clearAllStates();
                        }}
                      >
                        {glossary.account_ddl_option_logout}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {/* Header Menu end*/}
              </>
            </div>
          </div>
          {/* {loaderCustomer ? null : (
            <div className={'col-4'}>
              <div className='d-flex align-items-center justify-content-end' style={{ gap: 36 }}>
                <ButtonSkeleton width={110} height={37} />
                <TextSkeleton width={115} height={20} lineCount={4} />
                <ButtonSkeleton width={170} height={50} />
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default DemandHeader;
