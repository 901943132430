import { useEffect } from 'react';

interface Props {
  companyID: number;
  tagID: number | undefined;
  resolution: any;
  device: 'mobile' | 'tablet' | 'desktop';
}

const CreativeNewsPage = ({ companyID, tagID, resolution, device }: Props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      process.env.REACT_APP_API_URL === 'https://webapp.infy.tv/'
        ? 'https://media.begenuin.com/cxr/gen_ext.min.js'
        : 'https://genuin-qa-media.s3.us-west-2.amazonaws.com/cxr/gen_ext.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [tagID, resolution]);

  return (
    <>
      {device === 'mobile' && (
        <div className='usatoday-mobile'>
          {/* Header */}
          <div className='d-flex justify-content-between'>
            <img src='https://www.usatoday.com/sitelogos/d-oc.svg' width={80} />
            <div className='d-flex align-items-center'>
              <span style={{ marginRight: '5px' }}>Subscribe |</span>
              <span>Sign In</span>
            </div>
          </div>

          {/* First Content */}
          <div className='main-content'>
            <img
              src='https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/02/USAT/71426695007-ap-congress-foreign-aid-5.jpg?crop=4999,2812,x0,y260&width=210&height=118&format=pjpg&auto=webp'
              width={'100%'}
            />
            <div className='mt-2'>
              <b className='single-content-title'>
                Here&apos;s where Speaker Mike Johnson stands on Social Security, Medicare
              </b>
              <p>
                Mike Johnson has called for a bipartisan commission on the national debt.
                Here&apos;s a look at what that could mean for Social Security and Medicare.
              </p>
            </div>
          </div>

          {/* Advertisement */}
          <div className='advertisement mt-2'>
            <p className='label text-center'>Advertisement</p>
            <div
              className='gen-ext'
              data-tag-id={tagID}
              data-company-id={companyID}
              style={{
                width: resolution?.width,
                height: resolution?.height,
              }}
            ></div>
          </div>

          {/* Single Content List */}
          <div className='single-content d-flex justify-content-between mt-3 mb-2'>
            <div className='me-1'>
              <h5>Winner and losers from Cowboys&apos; MNF win over Chargers</h5>
              <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                <span style={{ fontWeight: 600, fontSize: '10px', letterSpacing: '0.2px' }}>
                  NFL |
                </span>{' '}
                1 hour ago
              </p>
            </div>
            <img
              src='https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/07/USAT/71492080007-shedeur.jpg?width=660&height=441&fit=crop&format=pjpg&auto=webp'
              width='90px'
              height='90px'
              style={{ objectFit: 'cover' }}
            />
          </div>

          <div className='single-content d-flex justify-content-between mb-2'>
            <div className='me-1'>
              <h5>Craig Counsell explains why he left Brewers for Cubs job</h5>
              <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                <span style={{ fontWeight: 600, fontSize: '10px', letterSpacing: '0.2px' }}>
                  MLB |
                </span>{' '}
                2 hour ago
              </p>
            </div>
            <img
              src='https://www.usatoday.com/gcdn/authoring/authoring-images/2023/10/06/PMJS/71084374007-2021-gan-20210516-jrs-ma-9-0185.JPG?crop=2828,2828,x209,y0&width=120&height=120&format=pjpg&auto=webp'
              width='90px'
              height='90px'
              style={{ objectFit: 'cover' }}
            />
          </div>

          <div className='single-content d-flex justify-content-between mb-2'>
            <div className='me-1'>
              <h5> Fantasy football start &apos;em, sit &apos;em for Week 10</h5>
              <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                <span style={{ fontWeight: 600, fontSize: '10px', letterSpacing: '0.2px' }}>
                  FOOTBALL |
                </span>{' '}
                1 day ago
              </p>
            </div>
            <img
              src='https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/06/USAT/71475006007-usatsi-21771196.jpg?crop=3243,3243,x705,y2&width=240&height=240&format=pjpg&auto=webp'
              width='90px'
              height='90px'
              style={{ objectFit: 'cover' }}
            />
          </div>
        </div>
      )}

      {device === 'tablet' && (
        <div className='usatoday-tablet'>
          {/* Header */}
          <div className='d-flex justify-content-between align-items-center'>
            <img src='https://www.usatoday.com/sitelogos/d-oc.svg' width={80} />
            <p style={{ marginRight: '5px' }}>Subscribe | Sign In</p>
          </div>

          <div className='gnt_bc mt-2'></div>

          <div className='d-flex'>
            {/* Left Content */}
            <div className='left-content' style={{ width: '400px' }}>
              <div className='main-content'>
                <img
                  src={
                    'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/08/USAT/71496731007-usatsi-21333097.jpg?crop=5178,2912,x6,y313&width=660&height=370&format=pjpg&auto=webp'
                  }
                  width={'100%'}
                />
                <h4>GMs brace for Ohtani&apos;s decision heard &apos;round the baseball world</h4>
                <p
                  style={{
                    margin: ' 2px 0px',
                    color: '#666666',
                    fontSize: '10px',
                    letterSpacing: '0.2px',
                  }}
                >
                  <span style={{ fontWeight: 300, color: '#AA1E23' }}>BOB NIGHTENGALE |</span> 9:22
                  p.m. Nov 7
                </p>
              </div>

              {/* More Top Stories */}
              <div>
                <h3 className='py-2'>More Top Stories</h3>
                <div className='single-content d-flex justify-content-between mb-2'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/presto/2023/05/02/USAT/44899af7-e5f8-49c1-9180-688514568742-USP_NBA__Playoffs-Memphis_Grizzlies_at_Los_Angeles.jpg?crop=5334,5334,x1333,y0&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5>
                      Dillon Brooks pokes the bear again, &apos;ready to lock up&apos; LeBron James
                    </h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#AA1E23',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        HUSTON ROCKETS |
                      </span>{' '}
                      8:49 p.m. Nov 7
                    </p>
                  </div>
                </div>

                <div className='single-content d-flex justify-content-between mb-2'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/07/USAT/71492074007-selfdeal.jpg?crop=2706,2706,x763,y78&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5>
                      KU&apos;s Bill Self will be highest-paid hoops coach ever at public college
                    </h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#AA1E23',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        BIG 12 |
                      </span>{' '}
                      6:50 p.m. Nov 7
                    </p>
                  </div>
                </div>

                <div className='single-content d-flex justify-content-between mb-2'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/07/USAT/71492838007-usatsi-21647488.jpg?crop=1600,1599,x480,y0&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5>How Ric Flair became Jim Harbaugh&apos;s &apos;very close friend&apos;</h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#AA1E23',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        COLLEGE FOOTBALL |
                      </span>{' '}
                      4:13 p.m. Nov 7
                    </p>
                  </div>
                </div>

                <div className='single-content d-flex justify-content-between mb-2'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/07/USAT/71491961007-usatsi-21741632.jpg?crop=3710,3710,x925,y0&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5>Buy-low, sell-high candidates for Week 10 of fantasy football season</h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#AA1E23',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        FOOTBALL |
                      </span>{' '}
                      8:04 p.m. Nov 7
                    </p>
                  </div>
                </div>

                <div className='single-content d-flex justify-content-between'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/06/USAT/71477922007-usatsi-21817226.jpg?crop=2711,2711,x1425,y327&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5>Ohio State holds off Georgia for No.1 spot in playoff rankings</h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#AA1E23',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        COLLEGE FOOTBALL |
                      </span>{' '}
                      8:22 p.m. Nov 7
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Content */}
            <div className='right-content' style={{ width: '300px', marginLeft: '6px' }}>
              <div className='single-content mt-2'>
                <h5>Jason Kelce featured in People&apos;s &apos;Sexiest Man Alive&apos; issue</h5>
                <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                  <span
                    style={{
                      fontWeight: 300,
                      color: '#AA1E23',
                      fontSize: '10px',
                      letterSpacing: '0.2px',
                    }}
                  >
                    NFL |
                  </span>{' '}
                  12:13 a.m. Nov 8
                </p>
              </div>
              <hr className='m-0' />
              <div className='single-content mt-2'>
                <h5>Brittney Griner is a proud American. Don&apos;t let critics fool you</h5>
                <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                  <span
                    style={{
                      fontWeight: 300,
                      color: '#AA1E23',
                      fontSize: '10px',
                      letterSpacing: '0.2px',
                    }}
                  >
                    NANCY ARMOUR |
                  </span>{' '}
                  6:07 p.m. Nov
                </p>
              </div>
              <hr className='m-0' />
              <div className='single-content mt-2'>
                <h5>SafeSport rules ex-US fencing coach ineligible, citing sexual misconduct</h5>
                <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                  <span
                    style={{
                      fontWeight: 300,
                      color: '#AA1E23',
                      fontSize: '10px',
                      letterSpacing: '0.2px',
                    }}
                  >
                    OLYMPICS |
                  </span>{' '}
                  11:04 p.m. Nov
                </p>
              </div>

              {/* Advertisement */}
              <div className='advertisement text-center p-0'>
                <p className='label'>Advertisement</p>
                <div
                  className='gen-ext'
                  data-tag-id={tagID}
                  data-company-id={companyID}
                  style={{
                    width: resolution?.width,
                    height: resolution?.height,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {device === 'desktop' && (
        <div className='usatoday-desktop'>
          <div className='d-flex justify-content-between align-items-center'>
            <img src='https://www.usatoday.com/sitelogos/d-oc.svg' width={80} />
            <p style={{ marginRight: '5px' }}>Subscribe | Sign In</p>
          </div>

          <div className='gnt_bc mt-2'></div>

          <div className='en-nav'>
            <div className='en-nav-left'>
              <h2>Entertainment</h2>
            </div>
            <div className='en-nav-right'>
              <span className='en-nav-btn'>Movies</span>
              <span className='en-nav-btn'>Celebrities</span>
              <span className='en-nav-btn'>TV</span>
              <span className='en-nav-btn'>Music</span>
              <span className='en-nav-btn'>Books</span>
            </div>
          </div>

          <div className='d-flex'>
            <div className='left-content'>
              {/* Main News */}
              <div className='main-content'>
                <img
                  src={
                    'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/08/USAT/71499454007-ap-people-sexiest-man.jpg?crop=5087,2863,x0,y264&width=660&height=370&format=pjpg&auto=webp'
                  }
                  width={'100%'}
                />
                <h4>
                  It&apos;s McDreamy! Patrick Dempsey named People&apos;s Sexiest Man Alive for 2023
                </h4>
                <p
                  style={{
                    margin: ' 2px 0px',
                    color: '#666666',
                    fontSize: '10px',
                    letterSpacing: '0.2px',
                  }}
                >
                  <span style={{ fontWeight: 300, color: '#691464' }}>CELEBRITIES |</span> 2:00 a.m.
                  Nov 8
                </p>
              </div>

              {/* Horizontal View */}
              <div className='d-flex mt-2'>
                <div className='single-content'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/08/USAT/71496675007-nup-202168-05763.jpg?crop=692,389,x72,y47&width=210&height=118&format=pjpg&auto=webp'
                    }
                    width={180}
                    height={85}
                  />
                  <div className='mt-1'>
                    <h5>
                      &apos;The Voice&apos;: Which Team Gwen singer moved Mega Mentor Wynonna Judd?
                    </h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#691464',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        TV |
                      </span>{' '}
                      1:46 a.m. Nov 8
                    </p>
                  </div>
                </div>

                <div className='single-content'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/08/USAT/71496194007-ap-film-review-the-marvels.jpg?crop=2999,1687,x0,y0&width=210&height=118&format=pjpg&auto=webp'
                    }
                    width={180}
                    height={85}
                  />
                  <div className='mt-1'>
                    <h5>
                      &apos;The Marvels&apos; release date, cast and what to watch ahead of the
                      showing
                    </h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#691464',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        MOVIES |
                      </span>{' '}
                      9:12 a.m. Nov 7
                    </p>
                  </div>
                </div>
              </div>

              {/* More Top Stories */}
              <div className='mt-2'>
                <h4 className='py-2'>More Top Stories</h4>
                <div className='single-content d-flex justify-content-between mb-2'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/07/USAT/71493539007-gty-1680986995.jpg?crop=1284,1284,x841,y65&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5>
                      Jeremy Renner on what snowplow accident has taught him: &apos;The will to be
                      here&apos;
                    </h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#691464',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        CELEBRITIES |
                      </span>{' '}
                      5:20 p.m. Nov 7
                    </p>
                  </div>
                </div>

                <div className='single-content d-flex justify-content-between mb-2'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/06/USAT/71478630007-gb-limited-series-3-time-traveler-cover.png?crop=1082,1082,x332,y4&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5>
                      Garth Brooks&apos; love of country music, storytelling shine on new album
                    </h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#691464',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        MUSIC |
                      </span>{' '}
                      4:34 p.m. Nov 7
                    </p>
                  </div>
                </div>

                <div className='single-content d-flex justify-content-between mb-2'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/07/USAT/71488578007-t-vspecialsl-070426.jpg?crop=469,469,x223,y71&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5>
                      Barbra Streisand regrets turning down Marlon Brando: &apos;How stupid!&apos;
                    </h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#691464',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        BOOKS |
                      </span>{' '}
                      7:52 p.m. Nov 7
                    </p>
                  </div>
                </div>

                <div className='single-content d-flex justify-content-between mb-2'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/07/USAT/71485191007-ap-2023-cfda-fashion-awards.jpg?crop=2233,2231,x508,y0&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5>Stars walk the white carpet at the CFDA Fashion Awards</h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#691464',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        ENTERTAINMENT |
                      </span>{' '}
                      7:16 p.m. Nov 7
                    </p>
                  </div>
                </div>

                <div className='single-content d-flex justify-content-between mb-2'>
                  <img
                    src={
                      'https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/07/USAT/71481302007-simon-bob.jpeg?crop=3108,3109,x0,y756&width=120&height=120&format=pjpg&auto=webp'
                    }
                    width={80}
                    height={80}
                  />
                  <div className='ms-2'>
                    <h5> Kenny Chesney, Zac Brown going on tour: How to get tickets</h5>
                    <p style={{ margin: ' 5px 0px', color: '#666666', fontSize: '12px' }}>
                      <span
                        style={{
                          fontWeight: 300,
                          color: '#691464',
                          fontSize: '10px',
                          letterSpacing: '0.2px',
                        }}
                      >
                        MUSIC |
                      </span>{' '}
                      2:28 p.m. Nov 7
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Content */}
            <div className='right-content'>
              <div className='single-content mb-2'>
                <h5>
                  Suspect charged with killing Tupac Shakur gets June date for Vegas murder trial
                </h5>
                <p
                  style={{
                    margin: ' 5px 0px',
                    color: '#666666',
                    fontSize: '12px',
                    lineHeight: '14px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 300,
                      color: '#691464',
                      fontSize: '10px',
                      letterSpacing: '0.2px',
                    }}
                  >
                    CELEBRITIES |
                  </span>{' '}
                  8:25 p.m. Nov 7
                </p>
              </div>

              <hr className='m-0' />

              <div className='single-content mt-2'>
                <h5>
                  &apos;A creator of imaginary worlds&apos;: Luis Mateo Díez wins Cervantes Prize
                </h5>
                <p
                  style={{
                    margin: ' 5px 0px',
                    color: '#666666',
                    fontSize: '12px',
                    lineHeight: '14px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 300,
                      color: '#691464',
                      fontSize: '10px',
                      letterSpacing: '0.2px',
                    }}
                  >
                    BOOKS |
                  </span>{' '}
                  8:12 p.m. Nov 7
                </p>
              </div>

              <hr className='m-0' />

              <div className='single-content mt-2'>
                <h5>
                  K-pop icon Taemin chats &apos;Guilty&apos; EP: &apos;I feel like it&apos;s a fresh
                  start&apos;
                </h5>
                <p
                  style={{
                    margin: ' 5px 0px',
                    color: '#666666',
                    fontSize: '12px',
                    lineHeight: '14px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 300,
                      color: '#691464',
                      fontSize: '10px',
                      letterSpacing: '0.2px',
                    }}
                  >
                    MUSIC |
                  </span>{' '}
                  6:13 p.m. Nov 7
                </p>
              </div>

              <div className='advertisement text-center p-0 mt-2'>
                <p className='label'>Advertisement</p>
                <div
                  className='gen-ext'
                  data-tag-id={tagID}
                  data-company-id={companyID}
                  style={{
                    width: resolution?.width,
                    height: resolution?.height,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreativeNewsPage;
