import { useEffect, useState } from 'react';

interface Item {
  id: string;
  label: string;
}

interface Props {
  items: Item[];
  selected: string;
  select?(id: string): void;
  uName?(label: string): void;
}

export default (props: Props) => {
  const { items, select, selected, uName } = props;

  const [active, sActive] = useState<any>();

  useEffect(()=>{
    sActive(selected)
  },[selected])

  return (
    <div className={'pill'}>
      {items.map((item, index) => {
        return (
          <button
            key={index}
            onClick={() => {
              sActive(item.id);
              if (select) select(item.id);
              if (uName) uName(item.label);
            }}
            className={
              active === item.id
                ? `pill-button ${index === 0 ? 'pill-button-left' : ''} ${
                    index === items.length - 1 ? 'pill-button-right' : ''
                  } pill-button-selected`
                : `pill-button ${index === 0 ? 'pill-button-left' : ''} ${
                    index === items.length - 1 ? 'pill-button-right' : ''
                  }`
            }
          >
            <p>{item.label}</p>
          </button>
        );
      })}
    </div>
  );
};
