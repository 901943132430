import React, { useState, useRef, useEffect } from 'react';
import { FixedSizeList } from 'react-window';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import { ListChildComponentProps } from 'react-window';
import ListGroup from 'react-bootstrap/ListGroup';

interface Props {
  option: any;
  isMulti?: boolean;
  isClearable?: boolean;
  hasSelectAll?: boolean;
  value: any;
  onSelect(selected: any): void;
  label: string;
  menuPlacement?: string;
  filtersOption: any;
  filter(filter: any): void;
  currentKey?: string;
  setCurrentKey(key: string): void;
}

const MacroDropdown = (Props: Props) => {
  const {
    option,
    value,
    label,
    isClearable,
    isMulti,
    hasSelectAll,
    menuPlacement,
    filtersOption,
    filter,
    onSelect,
    currentKey,
    setCurrentKey,
  } = Props;

  const [selectedItems, setSelectedItems] = useState<any>(value ?? []); // Selected items state
  const [searchQuery, setSearchQuery] = useState(''); // Search input value
  const [isOpen, setIsOpen] = useState(false); // Dropdown open/close state

  const [activeFilter, sActiveFilter] = useState(''); // Right side filter

  const dropdownRef = useRef(null); // Ref to the dropdown container
  const listRef = useRef(null); // Ref to the list container

  useEffect(() => {
    if (value) {
      setSelectedItems(value);
    }
  }, [value]);

  // Toggle dropdown open/close state
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    filter('All');
    sActiveFilter(activeFilter !== 'All' ? '' : 'All');
    setCurrentKey('All');
  };

  // Handle click outside dropdown to close it
  const handleClickOutside = (event: any) => {
    //@ts-ignore
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchQuery('');
      // filter('All');
      setCurrentKey('All');
    }
  };

  // Attach click outside event listener on mount and remove it on unmount
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleItemSelection = (item: any) => {
    if (isMulti) {
      // Multi-select
      const isSelected = selectedItems.some(
        (selectedItem: any) => selectedItem.value === item.value
      );
      let updatedItems: any = [];
      if (isSelected) {
        updatedItems = selectedItems.filter(
          (selectedItem: any) => selectedItem.value !== item.value
        );
      } else {
        updatedItems = [...selectedItems, item];
      }
      setSelectedItems(updatedItems);
      onSelect(updatedItems);
    } else {
      // Single-select
      setSelectedItems([item]);
      setIsOpen(false);
      onSelect([item]);
    }
  };

  const selectAllFilteredItems = () => {
    if (isAllFilteredItemsSelected()) {
      // Uncheck all selected items in the filtered list
      const unselectedItems = selectedItems.filter(
        (selectedItem: any) =>
          !filteredItems.some((filteredItem: any) => filteredItem.value === selectedItem.value)
      );
      setSelectedItems(unselectedItems);
      onSelect(unselectedItems);
    } else {
      // Check all filtered items
      const selectedFilteredItems = filteredItems.filter(
        (filteredItem: any) =>
          !selectedItems.some((selectedItem: any) => selectedItem.value === filteredItem.value)
      );
      const updatedItems = [...selectedItems, ...selectedFilteredItems];
      setSelectedItems(updatedItems);
      onSelect(updatedItems);
    }
  };

  // Filter items based on search query
  const filteredItems = option.filter(
    (item: any) =>
      item.param.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.value.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Check if all filtered items are selected
  const isAllFilteredItemsSelected = () => {
    return (
      filteredItems.length > 0 &&
      filteredItems.every((filteredItem: any) =>
        selectedItems.some((selectedItem: any) => selectedItem.value === filteredItem.value)
      )
    );
  };

  // Rendered row for each item
  const Row = ({ index, style }: ListChildComponentProps) => {
    const item = filteredItems[index];
    const isSelected = selectedItems.some((selectedItem: any) => selectedItem.value === item.value);

    return (
      <div
        key={index}
        className={`custom-dropdown-list-item d-flex align-items-center ${
          isSelected ? 'selected' : ''
        }`}
        style={style}
        onClick={() => toggleItemSelection(item)}
      >
        {isMulti && (
          <input
            type='checkbox'
            checked={isSelected}
            className='custom-dropdown-list-item-checkbox col-1'
            onChange={() => toggleItemSelection(item)}
          />
        )}

        <label className='mt-1 col-5 text-truncate'>{item.param}</label>
        <label className='mt-1 w-100 text-truncate'>{item.value}</label>
      </div>
    );
  };

  // Calculate the height of the list container
  const getListHeight = () => {
    if (filteredItems.length > 5) {
      return 5 * 40; // Display 5 items at a time
    }
    return filteredItems.length * 40;
  };

  // Entire list clear by click on cross icon
  const handleSelectedClear = () => {
    setSelectedItems([]);
    onSelect([]);
  };

  const selectedItemsText = isAllFilteredItemsSelected()
    ? `${currentKey} items are selected.`
    : selectedItems.map((item: any) => item.value).join(', ');

  // const selectedItemsText =
  //   activeFilter === 'All' && option.length > 0 && selectedItems.length === option.length
  //     ? 'All items are selected.'
  //     : selectedItems.map((item: any) => item.value).join(', ');

  // Rendered dropdown
  return (
    <>
      <label className='label'>{label}</label>
      <div className='custom-dropdown-container' ref={dropdownRef}>
        <div className='custom-dropdown-input-container' onClick={toggleDropdown} tabIndex={0}>
          <input
            type='text'
            className='custom-dropdown-input'
            placeholder='Select an item'
            value={selectedItemsText}
            readOnly
          />
          <div className={'dropdown-icon'}>
            {isMulti && selectedItems.length > 0 && (
              <RxCross2 className='svg-icon me-2' fontSize={20} onClick={handleSelectedClear} />
            )}
            {isOpen ? (
              <IoIosArrowUp className='svg-icon up-icon' fontSize={20} />
            ) : (
              <IoIosArrowDown className='svg-icon down-icon' fontSize={20} />
            )}
          </div>
        </div>
        {isOpen && ( // Render the dropdown only if it's open
          <div className='row mt-0'>
            <div
              className={`custom-dropdown-list-container d-flex p-0 ${menuPlacement ?? 'bottom'}`}
            >
              <div className='col-9' style={{ borderRight: '1px solid #ccc' }}>
                {option.length > 0 && (
                  <div
                    className='custom-dropdown-list-item'
                    style={{ backgroundColor: '#F9F6F9', borderBottom: '1px solid #ccc' }}
                  >
                    <input
                      type='search'
                      placeholder='Search'
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)} // Handle search query change
                      autoFocus
                    />
                  </div>
                )}
                {hasSelectAll && (
                  <div className='macros-selectall'>
                    <div
                      className={`custom-dropdown-list-item select-all d-flex`}
                      // ${isAllFilteredItemsSelected() ? 'selected' : ''}
                      onClick={selectAllFilteredItems}
                    >
                      {searchQuery !== ''
                        ? filteredItems.length > 5
                        : option.length > 0 && (
                            <input
                              type='checkbox'
                              checked={isAllFilteredItemsSelected()}
                              className='custom-dropdown-list-item-checkbox col-2'
                              onChange={selectAllFilteredItems}
                            />
                          )}
                      <div className='col-5'>
                        <strong>Param</strong>
                      </div>
                      <div className='col-5'>
                        <strong>Macro</strong>
                      </div>
                    </div>
                  </div>
                )}
                {filteredItems.length > 0 ? (
                  <div className='list-container' ref={listRef}>
                    <FixedSizeList
                      height={getListHeight()}
                      width={`100%`}
                      itemSize={40}
                      itemCount={filteredItems.length}
                      outerRef={listRef}
                    >
                      {Row}
                    </FixedSizeList>
                  </div>
                ) : (
                  <div
                    className='label text-center mt-1 d-flex align-items-center justify-content-center'
                    style={{ height: '35px' }}
                  >
                    No options
                  </div>
                )}
              </div>
              <div className='col-3'>
                <ListGroup className='p-1' defaultActiveKey={'All'}>
                  {filtersOption.map((opt: string, index: string) => {
                    return (
                      <ListGroup.Item
                        key={index}
                        active={activeFilter === opt}
                        action
                        onClick={() => {
                          filter(opt);
                          sActiveFilter(opt);
                        }}
                        eventKey={opt}
                      >
                        {opt}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MacroDropdown;
