import { useEffect, useState } from 'react';
import { categories } from '../helpers/dropdowns';
import store from '../../states';
import { IoCopyOutline } from 'react-icons/io5';

// interface Props {
//   dealTerms?: any;
//   newid?: string;
// }

const RequestedDealDetails = (props: any) => {
  const { dealTerms, newid, kind } = props;

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // Dropdown Options Start
  const contextOpt = [
    { label: 'Video', value: 1 },
    { label: 'Game', value: 2 },
    { label: 'Music', value: 3 },
    { label: 'Application', value: 4 },
    { label: 'Text', value: 5 },
    { label: 'Other', value: 6 },
    { label: 'Unknown', value: 7 },
  ];

  const prodqOpt = [
    { label: 'Unknown', value: 0 },
    { label: 'Professionally Produced', value: 1 },
    { label: 'Prosumer', value: 2 },
    { label: 'User Generated (UGC)', value: 3 },
  ];

  const qagmediaratingOpt = [
    { label: 'All Audiences', value: 1 },
    { label: 'Everyone Over Age 12', value: 2 },
    { label: 'Mature Audiences', value: 3 },
  ];
  // Dropdown Options End

  const [name, sName] = useState('');
  const [selectedCategories, sSelectedCategories] = useState<any>([]);

  useEffect(() => {
    sName(dealTerms.name);
    if (newid) sSelectedCategories([]);

    if (dealTerms.cat) {
      if (dealTerms.cat && dealTerms.cat.length > 0) {
        const newArr: any = [];
        categories.forEach((e1: any) => {
          dealTerms.cat.forEach((e2: any) => {
            if (e1.value === e2) {
              newArr.push(e1);
            }
          });
        });
        sSelectedCategories(newArr);
      }
    }
  }, [dealTerms, newid]);

  return (
    <>
      <div className='w-100'>
        <table className='i-table i-table-bordered'>
          <tbody>
            {kind !== 'deal' && (
              <tr className='i-tr'>
                <td className='i-td fw-bold col-3'>Deal ID</td>
                <td className='i-td text-wrap'>
                  {dealTerms?.id}{' '}
                  <IoCopyOutline
                    className='ms-2 svg-icon'
                    fontSize={18}
                    onClick={() => navigator.clipboard.writeText(dealTerms?.id)}
                    title='Copy'
                  />
                </td>
              </tr>
            )}
            <tr className='i-tr'>
              <td className='i-td fw-bold col-3'>Name</td>
              <td className='i-td text-wrap'>{name}</td>
            </tr>
            <tr className='i-tr'>
              <td className='i-td fw-bold col-3'>
                {kind === 'deal' ? 'Deal Type' : 'Requested deal type'}
              </td>
              <td className='i-td text-wrap'>
                {dealTerms?.requestedDealType?.toUpperCase() ?? 'PG'}
              </td>
            </tr>
            {dealTerms?.requestedDealType === 'pmp' ? (
              <>
                <tr className='i-tr'>
                  <td className='i-td fw-bold col-3'>Min CPM</td>
                  <td className='i-td text-wrap'>{`${dealTerms.minCpm}`}</td>
                </tr>
                <tr className='i-tr'>
                  <td className='i-td fw-bold col-3'>Max CPM</td>
                  <td className='i-td text-wrap'>{`${dealTerms.maxCpm}`}</td>
                </tr>
              </>
            ) : (
              <>
                <tr
                  className={
                    dealTerms?.minCpm !== undefined && dealTerms.minCpm !== '' ? 'i-tr' : 'd-none'
                  }
                >
                  <td className='i-td fw-bold col-3'>CPM</td>
                  <td className='i-td text-wrap'>{`${dealTerms.minCpm}`}</td>
                </tr>
                <tr
                  className={
                    dealTerms?.impression !== undefined && dealTerms?.impression !== ''
                      ? 'i-tr'
                      : 'd-none'
                  }
                >
                  <td className='i-td fw-bold col-3'>Impression</td>
                  <td className='i-td text-wrap'>{`${dealTerms?.impression?.toLocaleString(
                    'en-US'
                  )}`}</td>
                </tr>
              </>
            )}
            <tr className={dealTerms.description !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3' style={{ verticalAlign: 'top' }}>
                Description
              </td>
              <td className='i-td text-wrap'>{dealTerms.description}</td>
            </tr>
            <tr className={selectedCategories.length > 0 ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3' style={{ verticalAlign: 'top' }}>
                IAB Categories
              </td>
              <td className='i-td text-wrap'>
                {selectedCategories.map((item: any, index: any) => {
                  if (index === selectedCategories.length - 1) {
                    return `${item.label}`;
                  } else {
                    return `${item.label}, `;
                  }
                })}
              </td>
            </tr>
            <tr className={dealTerms.ctr.length > 0 ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3' style={{ verticalAlign: 'top' }}>
                Countries
              </td>
              <td className='i-td text-wrap'>
                {dealTerms.ctr.map((ctr: any, index: any) => {
                  if (index === dealTerms.ctr.length - 1) {
                    return `${ctr.label}`;
                  } else {
                    return `${ctr.label}, `;
                  }
                })}
              </td>
            </tr>
            <tr className={dealTerms.stt.length > 0 ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3' style={{ verticalAlign: 'top' }}>
                States
              </td>
              <td className='i-td text-wrap'>
                {dealTerms.stt.map((stt: any, index: any) => {
                  if (index === dealTerms.stt.length - 1) {
                    return `${stt.label}`;
                  } else {
                    return `${stt.label}, `;
                  }
                })}
              </td>
            </tr>
            <tr className={dealTerms.cty.length > 0 ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3' style={{ verticalAlign: 'top' }}>
                Cities
              </td>
              <td className='i-td text-wrap'>
                {dealTerms.cty.map((cty: any, index: any) => {
                  if (index === dealTerms.cty.length - 1) {
                    return `${cty.label}`;
                  } else {
                    return `${cty.label}, `;
                  }
                })}
              </td>
            </tr>
            <tr className={dealTerms.dev.length > 0 ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Device Type</td>
              <td className='i-td text-wrap'>
                {dealTerms.dev.map((dev: any, index: any) => {
                  if (index === dealTerms.dev.length - 1) {
                    return `${dev.charAt(0).toUpperCase() + dev.slice(1)}`;
                  } else {
                    return `${dev.charAt(0).toUpperCase() + dev.slice(1)}, `;
                  }
                })}
              </td>
            </tr>

            <tr className={dealTerms.p_type.length > 0 ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Platform Type</td>
              <td className='i-td text-wrap'>
                {dealTerms.p_type.map((item: any, index: any) => {
                  if (index === dealTerms.p_type.length - 1) {
                    return `${item.toUpperCase()}`;
                  } else {
                    return `${item.toUpperCase()}, `;
                  }
                })}
              </td>
            </tr>

            <tr className={dealTerms.applicationType.length > 0 ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Application Type</td>
              <td className='i-td text-wrap'>
                {dealTerms.applicationType.map((item: any, index: any) => {
                  if (index === dealTerms.applicationType.length - 1) {
                    return `${item.charAt(0).toUpperCase() + item.slice(1)}`;
                  } else {
                    return `${item.charAt(0).toUpperCase() + item.slice(1)}, `;
                  }
                })}
              </td>
            </tr>

            <tr className={dealTerms.startDate !== undefined ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Start Date</td>
              <td className='i-td text-wrap'>{dealTerms.startDate}</td>
            </tr>
            <tr className={dealTerms.artist !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Artist</td>
              <td className='i-td text-wrap'>{dealTerms.artist}</td>
            </tr>
            <tr className={dealTerms.series !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Series</td>
              <td className='i-td text-wrap'>{dealTerms.series}</td>
            </tr>
            <tr className={dealTerms.title !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Title</td>
              <td className='i-td text-wrap'>{dealTerms.title}</td>
            </tr>
            <tr className={dealTerms.genre !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Genre</td>
              <td className='i-td text-wrap'>{dealTerms.genre}</td>
            </tr>
            <tr className={dealTerms.isrc !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>ISRC Code</td>
              <td className='i-td text-wrap'>{dealTerms.isrc}</td>
            </tr>
            <tr className={dealTerms.producerName !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Producer Name</td>
              <td className='i-td text-wrap'>{dealTerms.producerName}</td>
            </tr>
            <tr className={dealTerms.producerDomain !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Producer Domain</td>
              <td className='i-td text-wrap'>{dealTerms.producerDomain}</td>
            </tr>
            <tr
              className={dealTerms.prodq !== '' && dealTerms?.prodq?.length > 0 ? 'i-tr' : 'd-none'}
            >
              <td className='i-td fw-bold col-3'>Product Quality</td>
              <td className='i-td text-wrap'>
                {prodqOpt.find((x: any) => dealTerms.prodq === x.value)?.label}
              </td>
            </tr>
            <tr className={dealTerms.context !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Context</td>
              <td className='i-td text-wrap'>
                {dealTerms.context &&
                  contextOpt.find((x: any) => dealTerms.context == x.value)?.label}
              </td>
            </tr>
            <tr className={dealTerms.contentrating !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Content Rating</td>
              <td className='i-td text-wrap'>{dealTerms.contentrating}</td>
            </tr>
            <tr className={dealTerms.userrating !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>User Rating</td>
              <td className='i-td text-wrap'>{dealTerms.userrating}</td>
            </tr>
            <tr className={dealTerms.qagmediarating !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Media Rating per IQG g</td>
              <td className='i-td text-wrap'>
                {qagmediaratingOpt.find((x: any) => dealTerms.qagmediarating === x.value)?.label}
              </td>
            </tr>
            <tr className={dealTerms.keywords !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Keywords</td>
              <td className='i-td text-wrap'>{dealTerms.keywords}</td>
            </tr>
            <tr className={dealTerms.kwarray.length > 0 ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3' style={{ verticalAlign: 'top' }}>
                Keywords Array
              </td>
              <td className='i-td text-wrap'>
                {dealTerms.kwarray.map((x: any, index: any) => {
                  if (index === dealTerms.kwarray.length - 1) {
                    return `${x}`;
                  } else {
                    return `${x}, `;
                  }
                })}
              </td>
            </tr>
            <tr className={dealTerms.livestream !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Live Stream</td>
              <td className='i-td text-wrap'>
                {dealTerms.livestream === 0 ? 'Not live' : 'Content is live'}
              </td>
            </tr>
            <tr className={dealTerms.sourceRelationship !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Source Relationship</td>
              <td className='i-td text-wrap'>
                {dealTerms.sourceRelationship === 0 ? 'Indirect' : 'Direct'}
              </td>
            </tr>
            <tr className={dealTerms.len !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Length</td>
              <td className='i-td text-wrap'>{dealTerms.len}</td>
            </tr>
            <tr className={dealTerms.language !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Language</td>
              <td className='i-td text-wrap'>{dealTerms.language}</td>
            </tr>
            <tr className={dealTerms.embeddable !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Embeddable</td>
              <td className='i-td text-wrap'>{dealTerms.embeddable == 0 ? 'No' : 'Yes'}</td>
            </tr>
            <tr className={dealTerms.networkDomain !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Network Domain</td>
              <td className='i-td text-wrap'>{dealTerms.networkDomain}</td>
            </tr>
            <tr className={dealTerms.channelDomain !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Channel Domain</td>
              <td className='i-td text-wrap'>{dealTerms.channelDomain}</td>
            </tr>
            <tr className={dealTerms.networkName !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Network Name</td>
              <td className='i-td text-wrap'>{dealTerms.networkName}</td>
            </tr>
            <tr className={dealTerms.channelName !== '' ? 'i-tr' : 'd-none'}>
              <td className='i-td fw-bold col-3'>Channel Name</td>
              <td className='i-td text-wrap'>{dealTerms.channelName}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <div className='row'>
        <div className='col-12'>
          <div className='d-flex mb-2'>
            <strong className='col-4'>Name</strong>
            <p className='col-8'>{name}</p>
          </div>
          <div className='d-flex mb-2'>
            <strong className='col-4'>Min CPM</strong>
            <p className='col'>{dealTerms.minCpm}</p>
          </div>
          <div className='d-flex mb-2'>
            <strong className='col-4'>Max CPM</strong>
            <p className='col'>{dealTerms.maxCpm}</p>
          </div>
          <div className={dealTerms.description !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Description</strong>
            <p className='col'>{dealTerms.description}</p>
          </div>
          <div className={selectedCategories.length > 0 ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>IAB Category</strong>
            <p className='col'>
              {selectedCategories.map((item: any, index: any) => {
                if (index === selectedCategories.length - 1) {
                  return `${item.label}`;
                } else {
                  return `${item.label}, `;
                }
              })}
            </p>
          </div>
          <div className={dealTerms.ctr.length > 0 ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Countries</strong>
            <p className='col'>
              {dealTerms.ctr.map((ctr: any, index: any) => {
                if (index === dealTerms.ctr.length - 1) {
                  return `${ctr.label}`;
                } else {
                  return `${ctr.label}, `;
                }
              })}
            </p>
          </div>
          <div className={dealTerms.stt.length > 0 ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>States</strong>
            <p className='col'>
              {dealTerms.stt.map((stt: any, index: any) => {
                if (index === dealTerms.stt.length - 1) {
                  return `${stt.label}`;
                } else {
                  return `${stt.label}, `;
                }
              })}
            </p>
          </div>
          <div className={dealTerms.cty.length > 0 ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Cities</strong>
            <p className='col'>
              {dealTerms.cty.map((cty: any, index: any) => {
                if (index === dealTerms.cty.length - 1) {
                  return `${cty.label}`;
                } else {
                  return `${cty.label}, `;
                }
              })}
            </p>
          </div>
          <div className={dealTerms.dev.length > 0 ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Device Type</strong>
            <p className='col'>
              {dealTerms.dev.map((dev: any, index: any) => {
                if (index === dealTerms.dev.length - 1) {
                  return `${dev.charAt(0).toUpperCase() + dev.slice(1)}`;
                } else {
                  return `${dev.charAt(0).toUpperCase() + dev.slice(1)}, `;
                }
              })}
            </p>
          </div>
          <div className={dealTerms.p_type.length > 0 ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Platform Type</strong>
            <p className='col'>
              {dealTerms.p_type.map((item: any, index: any) => {
                if (index === dealTerms.p_type.length - 1) {
                  return `${item.toUpperCase()}`;
                } else {
                  return `${item.toUpperCase()}, `;
                }
              })}
            </p>
          </div>
          <div className='d-flex mb-2'>
            <strong className='col-4'>Start Date</strong>
            <p className='col'>{formattedDate}</p>
          </div>
          <div className={dealTerms.artist !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Artist</strong>
            <p className='col'>{dealTerms.artist}</p>
          </div>
          <div className={dealTerms.series !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Series</strong>
            <p className='col'>{dealTerms.series}</p>
          </div>

          <div className={dealTerms.title !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Title</strong>
            <p className='col'>{dealTerms.title}</p>
          </div>
          <div className={dealTerms.genre !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Genre</strong>
            <p className='col'>{dealTerms.genre}</p>
          </div>
          <div className={dealTerms.isrc !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>ISRC Code</strong>
            <p className='col'>{dealTerms.isrc}</p>
          </div>
          <div className={dealTerms.producerName !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Producer Name</strong>
            <p className='col'>{dealTerms.producerName}</p>
          </div>
          <div className={dealTerms.producerDomain !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Producer Domain</strong>
            <p className='col'>{dealTerms.producerDomain}</p>
          </div>
          <div className={dealTerms.prodq !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Product Quality</strong>
            <p className='col'>{dealTerms.prodq}</p>
          </div>
          <div className={dealTerms.context !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Context</strong>
            <p className='col'>{dealTerms.context}</p>
          </div>
          <div className={dealTerms.contentrating !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Content Rating</strong>
            <p className='col'>{dealTerms.contentrating}</p>
          </div>
          <div className={dealTerms.userrating !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>User Rating</strong>
            <p className='col'>{dealTerms.userrating}</p>
          </div>
          <div className={dealTerms.qagmediarating !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Media Rating per IQG g</strong>
            <p className='col'>{dealTerms.qagmediarating}</p>
          </div>
          <div className={dealTerms.keywords !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Keywords</strong>
            <p className='col'>{dealTerms.keywords}</p>
          </div>
          <div className={dealTerms.kwarray.length > 0 ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Keywords Array</strong>
            <p className='col'>
              {dealTerms.kwarray.map((x: any, index: any) => {
                if (index === dealTerms.kwarray.length - 1) {
                  return `${x}`;
                } else {
                  return `${x}, `;
                }
              })}
            </p>
          </div>
          <div className={dealTerms.livestream !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Live Stream</strong>
            <p className='col'>{dealTerms.livestream}</p>
          </div>
          <div className={dealTerms.sourceRelationship !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Source Relationship</strong>
            <p className='col'>{dealTerms.sourceRelationship}</p>
          </div>
          <div className={dealTerms.len !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Length</strong>
            <p className='col'>{dealTerms.len}</p>
          </div>
          <div className={dealTerms.language !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Language</strong>
            <p className='col'>{dealTerms.language}</p>
          </div>
          <div className={dealTerms.embeddable !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Embeddable</strong>
            <p className='col'>{dealTerms.embeddable}</p>
          </div>
          <div className={dealTerms.networkDomain !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Network Domain</strong>
            <p className='col'>{dealTerms.networkDomain}</p>
          </div>
          <div className={dealTerms.channelDomain !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Channel Domain</strong>
            <p className='col'>{dealTerms.channelDomain}</p>
          </div>
          <div className={dealTerms.networkName !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Network Name</strong>
            <p className='col'>{dealTerms.networkName}</p>
          </div>
          <div className={dealTerms.channelName !== '' ? 'd-flex mb-2' : 'd-none'}>
            <strong className='col-4'>Channel Name</strong>
            <p className='col'>{dealTerms.channelName}</p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default RequestedDealDetails;
