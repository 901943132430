import { useCallback, useEffect, useMemo, useState } from 'react';

import Button from '../widgets/button';
import Card from '../widgets/card';

import { ReactComponent as SizeIcon } from '../../assets/icons/size.svg';
import { ReactComponent as CompactIcon } from '../../assets/icons/compact.svg';

import { MdSearch } from 'react-icons/md';
import { IoIosArrowForward, IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

import CardLoader from '../loaders/card';
import store from '../../states';
import Sorting from '../widgets/sorting';
import { FixedSizeList } from 'react-window';
import { AiOutlineHome } from 'react-icons/ai';
import TableSkeleton from '../loaders/table';
import ButtonSkeleton from '../loaders/button';

interface Props {
  title?: string;
  kind: 'customer' | 'SPO' | 'PublisherSPO';
  size: 'collapsed' | 'expanded';
  card: 'collapsed' | 'expanded';
  action?: {
    icon: string;
    text: string;
    click(): void;
    className?: string;
  };
  overviewAction?: {
    text: string;
    click(): void;
    className?: string;
    isOverview?: boolean;
  };
  items: any[];
  sidebar?(size: string): void;
  info?(card: string): void;
  getTags?(updated: any): void;
  empty?: boolean;
  handleQueries?(query: any): void;
  selected?: string;
  setSelected?(): void;
  selectedEndpoint?: any;
  flag?: boolean;
  TagsListApi?(id: any): void;
  getEndpointApi?(data: any, x: string): void;
  getMoreData?(page: any): void;
  currentPage?: any;
  loading?: boolean;
  hasMore?: boolean;
  sortingBy?(sort: any): void;
  params?: any;
  handleParams?: any;
  expandSidebar(expand: boolean): void;
  searchToggle(expand: boolean): void;
  allData?: any;
}

const SPOSidebar = (props: Props) => {
  const {
    title,
    kind,
    size,
    card,
    action,
    overviewAction,
    items,
    selectedEndpoint,
    info,
    getTags,
    setSelected,
    TagsListApi,
    getEndpointApi,
    currentPage,
    loading,
    hasMore,
    params,
    handleParams,
    allData,
  } = props;

  const [displayItems, sDisplayItems] = useState(items);
  const [selected, sSelected] = useState(props.selected);
  const [cardSize, sCardSize] = useState(card);
  const [searchContent, sSearchContent] = useState('');
  const [searchBar, sSearchBar] = useState(false);
  const [expandSearchBar, sExpandSearchBar] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    sSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {
    sDisplayItems(items);
  }, [items, searchContent]);

  const [crSrt, sCrSrt] = useState(params.sortBy);

  useEffect(() => {
    if (selectedEndpoint && selectedEndpoint) {
      select(selectedEndpoint);
    }
  }, [selectedEndpoint]);

  const select = (id: string, item?: any) => {
    sSelected(id);
    if (info) info(id);
  };

  const toggleSearchBar = (e: Event) => {
    const allow1 = document.getElementById(`search-bar`);
    const allow3 = document.getElementById(`search-bar-expand`);
    const allow2 = document.getElementById(`search-btn`);
    const allow4 = document.getElementById(`search-btn-expand`);
    const target = e.target;
    if (target === allow1 || target === allow2 || target === allow3 || target === allow4) {
      //
    } else {
      // sSearchBar(false);
      sExpandSearchBar(false);
    }
  };

  const sorter = (key: any, desc: boolean) => {
    if (key === crSrt.id && (desc || !desc)) {
      sCrSrt({
        id: key,
        desc: desc,
      });
    } else {
      sCrSrt({
        id: key,
        desc: desc,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('click', toggleSearchBar, false);
    return () => {
      document.removeEventListener('click', toggleSearchBar, false);
    };
  }, []);

  const [expand, setExpand] = useState(false);

  // Call the expandSidebar function with the updated value of expand
  const handleSidebar = () => {
    if (expand) {
      setExpand(false);
      props?.expandSidebar(expand);
    } else {
      setExpand(true);
      props?.expandSidebar(expand);
    }
  };

  const handleSearch = () => {
    if (searchBar) {
      sSearchBar(false);
      props?.searchToggle && props?.searchToggle(searchBar);
    } else {
      sSearchBar(true);
      props?.searchToggle && props?.searchToggle(searchBar);
    }
  };

  /* Searching  */
  useEffect(() => {
    if (searchContent !== params.query) {
      const getData: any = setTimeout(() => {
        handleParams(0, searchContent, params.sortBy, true);
        sDisplayItems([]);
      }, 300);
      return () => clearTimeout(getData);
    }
  }, [searchContent, params.sortBy]);

  /***** Onscroll Fetch Data Start *****/
  const memoizedRenderCard = useMemo(() => {
    const renderCard = ({ index, style }: any) => {
      const item = displayItems && displayItems[index];
      return (
        <div style={style}>
          <Card
            id={item.id}
            key={index}
            text={item.name || item.company_name}
            kind={kind}
            size={cardSize}
            info={{
              type: item.customer_type ?? item.tag_type ?? '',
              thumb: item.thumb,
              status: item.status,
              created: item.created_on,
              impressions: item.impression,
              fillRate: item.fill_rate,
              floorPrice: item.floor_price,
              earning: item.earning,
              config: item.config,
              endpointItem: item,
              endpointType: item.type,
              floorPriceFormatted: item.floor_price_formatted, // Demand & Publisher demand Card
            }}
            selected={selected}
            click={() => select(item.id, item)}
            getTags={(updated: any) => getTags && getTags(updated)}
            setSelected={setSelected}
            TagsListApi={TagsListApi} // get Publisher demand traffic table data
            getEndpointApi={getEndpointApi} //Get Publisher demand list data
            expandClass={expand} //add class name when open sidebar
            allData={allData}
          />
        </div>
      );
    };

    return renderCard;
  }, [
    displayItems,
    // kind,
    cardSize,
    selected,
    expand,
    allData,
  ]);

  const lastItem = displayItems[displayItems.length - 1];
  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      if (
        displayItems?.length >= 500 &&
        hasMore &&
        visibleStopIndex === displayItems.length - 1 &&
        lastItem
      ) {
        handleParams(params.page + 1, params.query, params.sortBy, true);
      }
    },
    [params.page, params.query, params.sortBy, displayItems.length, lastItem]
  );
  /***** Onscroll Fetch Data End *****/

  /***** Handle Sorting Start *****/
  const handleSort = (sort: any) => {
    if (sort) {
      sCrSrt(sort);
      handleParams(0, params.query, sort, true);
      sDisplayItems([]);
    }
  };

  return (
    <>
      <div className={expand ? 'newsidebar open' : 'newsidebar'}>
        <div className={'sidebar-row sidebar-row-first'}>
          <div className={'sidebar-col'}>
            <h3>{title}</h3>
          </div>
          <div className={'sidebar-col'}>
            <div className={'sidebar-row'}>
              {/***** Search ******/}
              {searchBar && (
                <div className={'sidebar-col'}>
                  <input
                    autoFocus
                    onChange={(e) => {
                      if (e.target.value === '') {
                        handleParams(0, '', params.sortBy, true);
                        sDisplayItems([]);
                      }
                      sSearchContent(e.target.value);
                    }}
                    id={'search-bar'}
                    value={searchContent}
                    type={'search'}
                    maxLength={32}
                    placeholder={'Search Keywords..'}
                    className='search-input ps-0'
                    style={{
                      width: '245px',
                      maxWidth: '245px',
                      position: expand ? 'inherit' : 'absolute',
                      left: '25px',
                      height: '35px',
                    }}
                  />
                </div>
              )}
              <div className={'sidebar-col'}>
                <MdSearch
                  className='svg-icon'
                  id={'search-btn'}
                  onClick={() => {
                    handleSearch();
                  }}
                  title={'Search'}
                  fontSize={20}
                  style={{ marginLeft: 8, marginRight: 5 }}
                />
              </div>

              {/***** Dropdown Sorting ******/}
              <div className={!searchBar ? 'sidebar-col' : 'd-none'}>
                <Sorting kind={'publishers'} sortBy={handleSort} sortedBy={crSrt} />
              </div>

              {!searchBar && (
                <>
                  <div
                    className={'sidebar-col compact-card'}
                    onClick={() => sCardSize(cardSize === 'collapsed' ? 'expanded' : 'collapsed')}
                  >
                    {cardSize === 'expanded' ? (
                      <SizeIcon
                        className='svg-icon'
                        width={20}
                        height={20}
                        title='Collapse'
                        style={{ marginLeft: 8, cursor: 'pointer' }}
                      />
                    ) : (
                      <CompactIcon
                        className='svg-icon'
                        width={20}
                        height={20}
                        title='Expand'
                        style={{ marginLeft: 8, cursor: 'pointer' }}
                      />
                    )}
                  </div>
                </>
              )}

              {displayItems?.length > 0 && (
                <div className='sidebar-col toggle ms-1' onClick={handleSidebar}>
                  <IoIosArrowForward fontSize={18} className='svg-icon' />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={'sidebar-row sidebar-row-secound'}>
          <div className={'sidebar-col'} id='first-btn'>
            {action && (
              <div style={{ marginTop: 16, marginBottom: 16, height: '37px' }}>
                <Button
                  kind={'primary'}
                  icon={action.icon}
                  text={action.text}
                  click={action.click}
                  customClass={action.className}
                />
              </div>
            )}
          </div>
          {/* <div className={'sidebar-col'} id='first-btn'>
            {loading ? (
              <>
                <ButtonSkeleton height={38} width={90} />
              </>
            ) : (
              action && (
                <div style={{ marginTop: 16, marginBottom: 16, height: '37px' }}>
                  <Button
                    kind={'primary'}
                    icon={action.icon}
                    text={action.text}
                    click={action.click}
                    customClass={loading ? `${action.className} visually-hidden` : action.className}
                  />
                </div>
              )
            )}
          </div> */}

          <div className={'sidebar-col'}>
            {!expand && overviewAction && displayItems?.length > 0 && (
              <div style={{ marginTop: 16, marginBottom: 16 }}>
                <button
                  className={
                    !overviewAction.isOverview
                      ? 'button button-overview p-0'
                      : 'button-secondary button p-0'
                  }
                  onClick={overviewAction.click}
                  style={{ minWidth: '40px' }}
                >
                  <AiOutlineHome fontSize={20} />
                </button>
              </div>
            )}
          </div>
        </div>

        {expand && (
          <div className={'sidebar-expand'}>
            <div className='sidebar-expand-item title'>
              <div
                onClick={() => sorter('name', !crSrt.desc)}
                className={crSrt.id === 'name' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.spo_dash_sidebar_expand_column_name}
                {crSrt.id === 'name' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'name' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item date'>
              <div
                onClick={() => sorter('created_on', !crSrt.desc)}
                className={crSrt.id === 'created_on' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.spo_dash_sidebar_expand_column_created}
                {crSrt.id === 'created_on' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'created_on' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item impression'>
              <div
                onClick={() => sorter('impression', !crSrt.desc)}
                className={crSrt.id === 'impression' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.spo_dash_sidebar_expand_column_impression}
                {crSrt.id === 'impression' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'impression' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item spending'>
              <div
                onClick={() => sorter('earning', !crSrt.desc)}
                className={crSrt.id === 'earning' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.spo_dash_sidebar_expand_column_earnings}
                {crSrt.id === 'earning' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'earning' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item bidrate'>
              <div
                onClick={() => sorter('fill_rate', !crSrt.desc)}
                className={crSrt.id === 'fill_rate' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.spo_dash_sidebar_expand_column_fillrate}
                {crSrt.id === 'fill_rate' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'fill_rate' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
            <div className='sidebar-expand-item status'>
              <div
                onClick={() => sorter('status', !crSrt.desc)}
                className={crSrt.id === 'status' ? 'active-link' : 'text-black list-col'}
              >
                {glossary.spo_dash_sidebar_expand_column_status}
                {crSrt.id === 'status' && crSrt.desc && (
                  <IoIosArrowUp className='svg-icon' fontSize={18} />
                )}
                {crSrt.id === 'status' && !crSrt.desc && (
                  <IoIosArrowDown className='svg-icon' fontSize={18} />
                )}
              </div>
            </div>
          </div>
        )}

        <div className={'sidebar-list'} style={{ minWidth: 300 }}>
          {/***** List of Sidebar Card *****/}
          <FixedSizeList
            height={1000}
            width={300}
            itemCount={displayItems.length}
            itemSize={expand || cardSize === 'collapsed' ? 75 : 130}
            onItemsRendered={handleItemsRendered}
            style={{
              width: '100%',
              height: displayItems.length > 0 ? `calc(100vh - 140px)` : `0px`,
              overflowY: 'auto',
            }}
          >
            {memoizedRenderCard}
          </FixedSizeList>

          {/***** Skeleton View *****/}
          {loading && !expand && (
            <>
              <CardLoader />
              <CardLoader />
              <CardLoader />
            </>
          )}

          {/***** Skeleton View for Expand View *****/}
          {loading && expand && (
            <>
              <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
            </>
          )}

          {/***** When data not found on search *****/}
          {(!displayItems || displayItems.length < 1) && !loading && props.empty && (
            <p style={{ textAlign: 'center', padding: 24 }}>No Results</p>
          )}
        </div>
      </div>
      <div className={expand ? 'overlay' : ''} onClick={handleSidebar}></div>
    </>
  );
};

export default SPOSidebar;
