import { useContext, useEffect, useState } from 'react';
import { BsWallet2 } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory, useLocation } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';

import store, { update } from '../../states';
import { api } from '../../services/constants';
import { UserInfoContext } from '../contexts/userInfoContext';
import ButtonSkeleton from '../loaders/button';
import TextSkeleton from '../loaders/text';

const AdminHeader = () => {
  // Context API
  const { userInfo, clearAll }: any = useContext(UserInfoContext);

  const { push, replace } = useHistory();
  const location = useLocation();

  // const [p, sP] = useState('-');
  // const [r, sR] = useState('-');
  // const [e, sE] = useState('-');
  // const [pc, sPC] = useState('-');
  const [companyLogo, sCompanyLogo] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [glossary, sGlossary] = useState<any>({});

  const [balance, setBalance] = useState({
    earning: '',
    platformCost: '',
  });

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetch(api + '/frontend-glossary/en')
      .then((response) => response.json())
      .then((result) => {
        store.dispatch(update(result.data));
      });
  }, []);

  const user = localStorage.getItem('user');
  const first_name = user ? JSON.parse(user).firstName : '';
  const last_name = user ? JSON.parse(user).lastName : '';
  const company_logo = user ? JSON.parse(user).photo : '';

  useEffect(() => {
    // User Info
    if (userInfo) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          email: userInfo?.user?.email ?? '',
          firstName: userInfo?.user?.firstname ?? '',
          lastName: userInfo?.user?.lastname ?? '',
          photo: userInfo?.user?.thumb ?? '',
        })
      );

      setBalance({
        earning: userInfo?.balance?.infy_earnings_formatted ?? '-',
        platformCost: userInfo?.balance?.platform_cost_formatted ?? '-',
      });

      setFirstName(userInfo?.user?.firstname || first_name);
      setLastName(userInfo?.user?.lastname || last_name);
      sCompanyLogo(userInfo?.user?.thumb || company_logo);
    }
  }, [userInfo]);

  const onImageError = (e: any) => {
    e.target.src =
      'https://s3.us-east-2.amazonaws.com/infywebapp/profile_images/4348419929_yPk4EoqAgmd6lxv7VNQ3.png';
  };

  return (
    <>
      <div className={'header'}>
        <div
          className={'header-menu d-flex justify-content-between align-items-center my-0 w-auto'}
        >
          {/* Logo Start*/}
          <div className={'col col-auto header__left'}>
            <Link to='/customers'>
              <img src={logo} alt={'infy-tv-logo'} height='50px' width='150px' />
            </Link>
          </div>
          {/* Logo End*/}

          {/* Header Link Start*/}
          {location.pathname.startsWith('/inventory') && (
            <div className={'col header__middle'}>
              <div className='d-flex gap-5'>
                <Link
                  to='/inventory'
                  className={location.pathname === '/inventory' ? 'active' : ''}
                >
                  {glossary.admin_inv_header_tab_open_exchange}
                </Link>
                <Link
                  to='/inventory/deals'
                  className={location.pathname.startsWith('/inventory/deals') ? 'active' : ''}
                >
                  {glossary.admin_inv_header_tab_pmp_deals}
                </Link>
              </div>
            </div>
          )}
          {/* Header Link End*/}

          {/* Header Right Section Start*/}
          <div className={'col col-auto header__right d-none d-md-block'}>
            <div className={'d-flex align-items-center'}>
              {balance?.earning === '-' || balance?.platformCost === '-' ? (
                <>
                  <div className='header__right__wrap '>
                    <div className='header__right__wrap__box'>
                      <div className={'tour-payouts'}>
                        <ButtonSkeleton width={110} height={38} />
                      </div>
                    </div>
                    <div className='header__right__wrap__box tour-balance'>
                      <span className='header__right__wrap__box__lable'>
                        <TextSkeleton height={15} width={113} lineCount={1} />
                      </span>
                      <span className='header__right__wrap__box__value'>
                        <TextSkeleton height={20} width={70} lineCount={1} />
                      </span>
                    </div>
                    <div className='header__right__wrap__box tour-platform-cost'>
                      <span className='header__right__wrap__box__lable'>
                        <TextSkeleton height={15} width={113} lineCount={1} />
                      </span>
                      <span className='header__right__wrap__box__value'>
                        <TextSkeleton height={20} width={70} lineCount={1} />
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className='header__right__wrap'>
                  <div className='header__right__wrap__box'>
                    <button
                      className={
                        localStorage.getItem('role_id') === '2'
                          ? 'd-none'
                          : 'button button-secondary'
                      }
                      onClick={() => {
                        replace('/settings/finance');
                      }}
                    >
                      <BsWallet2 className='svg-icon' strokeWidth='0.3' />
                      {glossary.admin_header_button_finance}
                    </button>
                  </div>
                  <div className='header__right__wrap__box'>
                    <span className='header__right__wrap__box__lable'>
                      {glossary.admin_header_field_label_earnings}
                    </span>
                    <span className='header__right__wrap__box__value'>{balance?.earning}</span>
                  </div>
                  <div className='header__right__wrap__box'>
                    <span className='header__right__wrap__box__lable'>
                      {glossary.ssp_header_platform_cost}
                    </span>
                    <span className='header__right__wrap__box__value'>{balance?.platformCost}</span>
                  </div>
                </div>
              )}

              {/* Header Menu start*/}
              <Dropdown>
                <Dropdown.Toggle
                  className={
                    'button button-secondary d-flex justify-content-end align-items-center'
                  }
                  style={{ minWidth: '110px' }}
                >
                  <span
                    className='text-truncate text-start'
                    style={{ maxWidth: '110px', minWidth: '70px' }} // Any Issue width: '110px'
                    title={firstName + ' ' + lastName}
                  >
                    {firstName + ' ' + lastName}
                  </span>
                  <img
                    src={
                      companyLogo ??
                      'https://infy-ads.s3.us-east-2.amazonaws.com/static/placeholder.png'
                    }
                    height={42}
                    width={42}
                    className='rounded-circle ms-2'
                    style={{ border: 'none', backgroundColor: 'transparent' }}
                    onError={onImageError}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      push('/inventory');
                    }}
                  >
                    {glossary.admin_account_ddl_option_inventory}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      push('/traffic-dashboard');
                    }}
                  >
                    {glossary.admin_account_ddl_option_traffic}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.removeItem('selectedTabSetting');
                      push('/settings/profile');
                    }}
                  >
                    {glossary.account_ddl_option_settings}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.clear();
                      push('/login');
                      clearAll();
                    }}
                  >
                    {glossary.account_ddl_option_logout}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* Header Menu end*/}
            </div>
          </div>
          {/* Header Right Section Start*/}
        </div>
      </div>
    </>
  );
};

export default AdminHeader;
