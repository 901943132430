import Avatar from 'react-avatar';
import { GoLinkExternal } from 'react-icons/go';

const InviteCustomerColumn = (props: any) => {
  const { accessor, row } = props;

  return (
    <>
      {/* User & Manager Table in show avatar */}
      {accessor === 'name' && (
        <div className='align-items-center d-flex'>
          <Avatar className='me-2' src={row?.logo_url ?? 'U'} size={'25'} round />
          <a href={`http://www.${row?.domain}`} target='_blank' rel='noreferrer'>
            {row?.name} <GoLinkExternal className='svg-icon' fontSize={15} title='Redirect URL' />
          </a>
        </div>
      )}

      {/* Manager Assign Funtionality */}
      {/* {accessor === 'domain' && (
        <>
          <a href={`http://www.${row?.domain}`} target='_blank' rel='noreferrer'>
            {row?.domain}
          </a>
        </>
      )} */}
    </>
  );
};

export default InviteCustomerColumn;
