import React, { useCallback, useEffect, useState } from 'react';

import Button from '../widgets/button';
import Card from '../widgets/card';

import { ReactComponent as SizeIcon } from '../../assets/icons/size.svg';
import { ReactComponent as CompactIcon } from '../../assets/icons/compact.svg';

import { MdSearch } from 'react-icons/md';
import { IoIosArrowForward, IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

import CardLoader from '../loaders/card';
import Sorting from '../widgets/sorting';
// import { FixedSizeList } from 'react-window';
import TableSkeleton from '../loaders/table';
import ButtonSkeleton from '../loaders/button';
import { Accordion } from 'react-bootstrap';

interface Props {
  title: string;
  kind: 'creative';
  card: 'collapsed' | 'expanded';
  action?: {
    icon: string;
    text: string;
    click(): void;
    className?: string;
  };
  items: any[];
  empty?: boolean;
  selected?: string;
  info?(card: string, _item: any): void;
  currentPage?: any;
  loading?: boolean;
  hasMore?: boolean;
  params?: any;
  handleParams?: any;
  glossary?: any;
}

const MyCreativeSidebar = (props: Props) => {
  const {
    title,
    kind,
    card,
    action,
    items,
    info,
    currentPage,
    loading,
    hasMore,
    params,
    handleParams,
    glossary,
  } = props;

  const [displayItems, sDisplayItems] = useState(items);
  const [selected, sSelected] = useState(props.selected);
  const [cardSize, sCardSize] = useState(card);
  const [searchContent, sSearchContent] = useState('');
  const [searchBar, sSearchBar] = useState(false);

  useEffect(() => {
    sSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {
    sDisplayItems(items);
  }, [items, searchContent]);

  const [crSrt, sCrSrt] = useState(params.sortBy);

  const select = (id: string, _item: any) => {
    sSelected(id);
    if (info) info(id, _item);
  };

  const toggleSearchBar = (e: Event) => {
    const allow1 = document.getElementById(`search-bar`);
    const allow3 = document.getElementById(`search-bar-expand`);
    const allow2 = document.getElementById(`search-btn`);
    const allow4 = document.getElementById(`search-btn-expand`);
    const target = e.target;
    if (target === allow1 || target === allow2 || target === allow3 || target === allow4) {
      //
    } else {
      sSearchBar(false);
    }
  };

  const sorter = (key: any, desc: boolean) => {
    if (key === crSrt.id && (desc || !desc)) {
      sCrSrt({
        id: key,
        desc: desc,
      });
    } else {
      sCrSrt({
        id: key,
        desc: desc,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('click', toggleSearchBar, false);
    return () => {
      document.removeEventListener('click', toggleSearchBar, false);
    };
  }, []);

  const [expand, setExpand] = useState(false);

  const handleSidebar = () => {
    if (expand) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  };

  useEffect(() => {
    if (searchContent !== params.query) {
      const getData: any = setTimeout(() => {
        handleParams(0, searchContent, params.sortBy, true);
        sDisplayItems([]);
      }, 300);
      return () => clearTimeout(getData);
    }
  }, [searchContent, params.sortBy]);

  /***** Onscroll Fetch Data Start *****/

  // const renderCard = ({ index, style }: any) => {
  //   const item = displayItems && displayItems[index];
  //   return (
  //     <div style={style} key={index}>
  //       <Card
  //         id={item?.id?.toString()}
  //         text={item.name || item.company_name}
  //         kind={kind}
  //         size={cardSize}
  //         info={{
  //           items: item,
  //           type: item.tag_type ?? '',
  //           created: item.created_on,
  //           impressions: item.impression,
  //           earning: item.earning,
  //           status: item.status,
  //           floorPriceFormatted: item.floor_price_formatted, // Demand & Publisher demand Card
  //         }}
  //         selected={selected}
  //         click={() => select(item?.id?.toString(), item)}
  //         setSelected={() => null}
  //         expandClass={expand} //add class name when open sidebar
  //         allData={true}
  //       />
  //     </div>
  //   );
  // };

  // const lastItem = displayItems[displayItems.length - 1];
  // const handleItemsRendered = useCallback(
  //   ({ visibleStartIndex, visibleStopIndex }) => {
  //     if (
  //       //params.page === currentPage &&
  //       displayItems?.length >= 500 &&
  //       hasMore &&
  //       visibleStopIndex === displayItems.length - 1 &&
  //       lastItem
  //     ) {
  //       handleParams(params.page + 1, params.query, params.sortBy, true);
  //     }
  //   },
  //   [params.page, params.query, params.sortBy, displayItems.length, lastItem]
  // );

  /***** Onscroll Fetch Data End *****/

  /***** Handle Sorting Start *****/

  const handleSort = (sort: any) => {
    if (sort) {
      sCrSrt(sort);
      handleParams(0, params.query, sort, true);
      sDisplayItems([]);
    }
  };
  /***** Handle Sorting End *****/

  /***** Handle Accordion Active *****/
  // const [activeItem, setActiveItem] = useState(null);
  // const toggleItem = (index: any) => {
  // console.log('selectToggle', index);
  // setActiveItem(index);
  // if (activeItem === index) {
  //   setActiveItem(null); // Collapse the active item if clicked again
  // } else {
  //   setActiveItem(index); // Expand the clicked item
  // }
  // };

  return (
    <>
      <div className='creative-sidebar'>
        <div className={expand ? 'newsidebar open' : 'newsidebar'}>
          <div className={'sidebar-row sidebar-row-first'}>
            <div className={'sidebar-col'}>
              <h3>{title}</h3>
            </div>
            <div className={'sidebar-col'}>
              <div className={'sidebar-row'}>
                {searchBar && (
                  <div className={'sidebar-col'}>
                    <input
                      autoFocus
                      onChange={(e) => {
                        if (e.target.value === '') {
                          handleParams(0, '', params.sortBy, true);
                          sDisplayItems([]);
                        }
                        sSearchContent(e.target.value);
                      }}
                      id={'search-bar'}
                      value={searchContent}
                      type={'search'}
                      placeholder={'Search Keywords..'}
                      maxLength={32}
                      className='search-input ps-0'
                    />
                  </div>
                )}
                <div className={'sidebar-col'}>
                  <MdSearch
                    className='svg-icon'
                    id={'search-btn'}
                    onClick={() => {
                      sSearchBar(searchBar ? false : true);
                    }}
                    title={'Search'}
                    fontSize={20}
                    style={{ marginLeft: 8, marginRight: 5 }}
                  />
                </div>

                <div className={!searchBar ? 'sidebar-col' : 'd-none'}>
                  <Sorting kind={'creative'} sortBy={handleSort} sortedBy={crSrt} />
                </div>

                {/* {!searchBar && (
                  <>
                    <div
                      className={'sidebar-col compact-card'}
                      onClick={() => sCardSize(cardSize === 'collapsed' ? 'expanded' : 'collapsed')}
                    >
                      {cardSize === 'expanded' ? (
                        <SizeIcon
                          className='svg-icon'
                          width={20}
                          height={20}
                          title='Collapse'
                          style={{ marginLeft: 8, cursor: 'pointer' }}
                        />
                      ) : (
                        <CompactIcon
                          className='svg-icon'
                          width={20}
                          height={20}
                          title='Expand'
                          style={{ marginLeft: 8, cursor: 'pointer' }}
                        />
                      )}
                    </div>
                  </>
                )} */}

                {/* <div
                  className={`sidebar-col toggle ms-1 ${displayItems?.length < 1 && 'disabled'}`}
                  onClick={handleSidebar}
                >
                  <IoIosArrowForward fontSize={18} className='svg-icon' />
                </div> */}
              </div>
            </div>
          </div>

          <div className={'sidebar-row sidebar-row-secound'}>
            <div className={'sidebar-col'} id='first-btn'>
              {action && (
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                  {loading &&
                  params.query === '' &&
                  params?.page === 0 &&
                  displayItems.length < 1 ? (
                    <ButtonSkeleton height={'40px'} width={'115px'} />
                  ) : (
                    <Button
                      kind={'primary'}
                      icon={action.icon}
                      text={action.text}
                      click={action.click}
                      customClass={action.className}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {expand && (
            <div className='sidebar-expand'>
              <div className='sidebar-expand-item title'>
                <div
                  onClick={() => sorter('name', !crSrt.desc)}
                  className={crSrt.id === 'name' ? 'active-link' : 'text-black list-col'}
                >
                  {glossary.ssp_tags_sidebar_expand_column_name}
                  {crSrt.id === 'name' && crSrt.desc && (
                    <IoIosArrowUp className='svg-icon' fontSize={18} />
                  )}
                  {crSrt.id === 'name' && !crSrt.desc && (
                    <IoIosArrowDown className='svg-icon' fontSize={18} />
                  )}
                </div>
              </div>
              <div className='sidebar-expand-item date'>
                <div
                  onClick={() => sorter('created_on', !crSrt.desc)}
                  className={crSrt.id === 'created_on' ? 'active-link' : 'text-black list-col'}
                >
                  {glossary.ssp_tags_sidebar_expand_column_created}
                  {crSrt.id === 'created_on' && crSrt.desc && (
                    <IoIosArrowUp className='svg-icon' fontSize={18} />
                  )}
                  {crSrt.id === 'created_on' && !crSrt.desc && (
                    <IoIosArrowDown className='svg-icon' fontSize={18} />
                  )}
                </div>
              </div>
              <div className='sidebar-expand-item impression'>
                <div
                  onClick={() => sorter('impression', !crSrt.desc)}
                  className={crSrt.id === 'imp' ? 'active-link' : 'text-black list-col'}
                >
                  {glossary.ssp_tags_sidebar_expand_column_impressions}
                  {crSrt.id === 'impression' && crSrt.desc && (
                    <IoIosArrowUp className='svg-icon' fontSize={18} />
                  )}
                  {crSrt.id === 'impression' && !crSrt.desc && (
                    <IoIosArrowDown className='svg-icon' fontSize={18} />
                  )}
                </div>
              </div>
              <div className='sidebar-expand-item floorprice'>
                <div
                  onClick={() => sorter('floor_price', !crSrt.desc)}
                  className={crSrt.id === 'flr' ? 'active-link' : 'text-black list-col'}
                >
                  {glossary.ssp_tags_sidebar_expand_column_floorprice}
                  {crSrt.id === 'floor_price' && crSrt.desc && (
                    <IoIosArrowUp className='svg-icon' fontSize={18} />
                  )}
                  {crSrt.id === 'floor_price' && !crSrt.desc && (
                    <IoIosArrowDown className='svg-icon' fontSize={18} />
                  )}
                </div>
              </div>
              <div className='sidebar-expand-item earnings'>
                <div
                  onClick={() => sorter('earning', !crSrt.desc)}
                  className={crSrt.id === 'ern' ? 'active-link' : 'text-black list-col'}
                >
                  {glossary.ssp_tags_sidebar_expand_column_earnings}
                  {crSrt.id === 'earning' && crSrt.desc && (
                    <IoIosArrowUp className='svg-icon' fontSize={18} />
                  )}
                  {crSrt.id === 'earning' && !crSrt.desc && (
                    <IoIosArrowDown className='svg-icon' fontSize={18} />
                  )}
                </div>
              </div>
              <div className='sidebar-expand-item status'>
                <div
                  onClick={() => sorter('status', !crSrt.desc)}
                  className={crSrt.id === 'status' ? 'active-link' : 'text-black list-col'}
                >
                  {glossary.ssp_tags_sidebar_expand_column_status}
                  {crSrt.id === 'status' && crSrt.desc && (
                    <IoIosArrowUp className='svg-icon' fontSize={18} />
                  )}
                  {crSrt.id === 'status' && !crSrt.desc && (
                    <IoIosArrowDown className='svg-icon' fontSize={18} />
                  )}
                </div>
              </div>
              <div className='sidebar-expand-item action'>Action</div>
            </div>
          )}

          <div className={'sidebar-list creative-accordion'} style={{ minWidth: 300 }}>
            {/***** List of Sidebar Card *****/}
            {/* <FixedSizeList
              height={1000}
              width={300}
              itemCount={displayItems.length}
              itemSize={expand || cardSize === 'collapsed' ? 75 : 130}
              onItemsRendered={handleItemsRendered}
              style={{
                width: '100%',
                height: displayItems.length > 0 ? `calc(100vh - 211px)` : `0px`,
                overflowY: 'auto',
              }}
            >
              {renderCard}
            </FixedSizeList> */}

            <Accordion defaultActiveKey={selected}>
              {displayItems?.map((_item: any, index: any) => (
                <React.Fragment key={index}>
                  <Accordion.Toggle
                    className={`p-2 accordion-title d-flex align-items-center gap-2 ${
                      selected === _item?.id?.toString() ? 'active' : ''
                    }`}
                    eventKey={_item?.id?.toString()}
                    title={`${_item?.name} (${_item?.tag_size_count} Sizes)`}
                    onClick={(_event: any) => {
                      _event.preventDefault();
                      if (selected !== _item?.id?.toString()) {
                        select(_item?.id?.toString(), _item);
                      }
                      // toggleItem(index);
                    }}
                  >
                    <p
                      className='align-items-center d-flex justify-content-center rounded-circle text-white flex-shrink-0'
                      style={{ fontSize: '10px' }}
                    >
                      EXT
                    </p>
                    <p className='text-truncate'>{`${_item?.name} (${_item?.tag_size_count} Sizes)`}</p>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={_item?.id?.toString()}>
                    <div className='d-flex flex-column align-items-center' key={index}>
                      {_item?.tag_sizes?.length > 0 ? (
                        _item?.tag_sizes?.map((_subItem: any, _i: any) => (
                          <div
                            key={_i}
                            className={`card ${
                              selected === _subItem?.tag_id ? 'card-selected' : ''
                            }`}
                            onClick={() => select(_subItem?.tag_id, _subItem)}
                            style={{
                              maxWidth: '280px',
                            }}
                          >
                            <div className={'card-row'}>
                              <div className={'card-col'} style={{ width: '112px' }}>
                                <h6
                                  className={'truncate'}
                                  style={{
                                    fontSize: '14px',
                                    width: '236px',
                                  }}
                                  title={_subItem?.name}
                                >
                                  {_subItem?.name}
                                </h6>
                              </div>
                            </div>
                            <div className={'card-expanded'}>
                              <div className={'card-row'}>
                                <div className={'card-col'}>
                                  <label>Tag Impressions</label>
                                  <b>
                                    <label>{_subItem?.impression_formatted ?? '-'}</label>
                                  </b>
                                </div>
                                <div className={'card-col'}>
                                  <label>Tag Spend ($)</label>
                                  <b>
                                    <label>{_subItem?.earning_formatted ?? '-'}</label>
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <label className='label'>Tag not currently in use.</label>
                      )}
                    </div>
                  </Accordion.Collapse>
                </React.Fragment>
              ))}
            </Accordion>

            {/***** Skeleton View *****/}
            {loading && !expand && (
              <>
                <CardLoader />
                <CardLoader />
                <CardLoader />
              </>
            )}

            {/***** Skeleton View for Expand View *****/}
            {loading && expand && (
              <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
            )}

            {(!displayItems || displayItems?.length < 1) &&
              !loading &&
              params?.page === 0 &&
              searchContent?.length < 1 &&
              props.empty && (
                <div style={{ textAlign: 'center', padding: 24, marginTop: 100 }}>
                  <h4 className='mb-1'>No creatives to display</h4>
                  <label className='label'>Add a creative to start seeing some status here.</label>
                </div>
              )}

            {/***** When data not found on search *****/}
            {(!displayItems || displayItems.length < 1) && searchContent.length > 0 && !loading && (
              <>{props.empty && <p style={{ textAlign: 'center', padding: 24 }}>No Results</p>}</>
            )}
          </div>
        </div>
      </div>
      <div className={expand ? 'overlay' : ''} onClick={handleSidebar}></div>
    </>
  );
};

export default MyCreativeSidebar;
