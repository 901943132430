import { useEffect, useState } from 'react';
import FormikController from '../../comman/form/FormikController';
import store from '../../../states';

const WebsiteUrl = ({ websiteURLprops, selectedTagDetails, createTag }: any) => {
  const [websiteTodos, sWebsiteTodos] = useState<any>(
    createTag
      ? []
      : selectedTagDetails?.config?.extension_config?.allowed_domains ||
          selectedTagDetails?.config?.allowed_domains
  );
  const [webSiteValue, sWebSiteValue] = useState('');
  const [websiteErrorCheck, sWebsiteErrorCheck] = useState(true);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const hanleWebsiteUrl = (event: any) => {
    sWebSiteValue(event.target.value);
    const value = event.target.value;
    const res = value.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
    );
    if (res == null) sWebsiteErrorCheck(false);
    else sWebsiteErrorCheck(true);
  };

  //add website url handle event
  const handleAddTodo = () => {
    if (webSiteValue.trim()) {
      sWebsiteTodos([...websiteTodos, webSiteValue]);
      sWebSiteValue('');
    }
  };

  //delete website url from list
  const handleDeleteTodo = (index: any) => {
    const newWebsiteTodos = [...websiteTodos];
    newWebsiteTodos.splice(index, 1);
    sWebsiteTodos(newWebsiteTodos);
  };

  websiteURLprops(websiteTodos);

  return (
    <>
      <div className='mb-3 row'>
        <div className='col-12'>
          <div className='row flex-nowrap'>
            <div className='col-10'>
              <FormikController
                kind='withouticon'
                control='input'
                type='text'
                label={glossary.ssp_createext_input_label_website_url}
                placeholder={glossary.ssp_createext_input_website_url_placeholder}
                name={'websiteURL'}
                value={webSiteValue}
                onChange={hanleWebsiteUrl}
              />
            </div>
            <div className='col-auto d-flex flex-column'>
              <label className='label'>&nbsp;</label>
              <button
                type='button'
                onClick={handleAddTodo}
                className={
                  websiteErrorCheck
                    ? 'button button-primary add-button'
                    : 'button button-primary add-button button-disabled'
                }
              >
                +
              </button>
            </div>
          </div>
        </div>
        {!websiteErrorCheck && webSiteValue.length > 0 && (
          <div className='col-12'>
            <p className='small text-danger'>Enter valid website URL.</p>
          </div>
        )}
      </div>
      <div className='row website'>
        {websiteTodos &&
          websiteTodos?.length > 0 &&
          websiteTodos?.map((todo: any, index: any) => (
            <div className='col-auto' key={index}>
              <div className='bg-light ps-2 d-flex align-item-top'>
                <span className='me-2' style={{ fontSize: 12 }}>
                  {todo}
                </span>
                <button
                  type='button'
                  onClick={() => handleDeleteTodo(index)}
                  className='button button-gray p-0 h-auto'
                  style={{
                    minWidth: 25,
                    fontWeight: 400,
                  }}
                >
                  x
                </button>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default WebsiteUrl;
