import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import JWT from 'jsonwebtoken';
import store from '../../../states';
import Button from '../../widgets/button';
import { customerInviteSPOAPI, customerInviteSSPAPI } from '../../../services/spo';
import SpoCustomerList from './SpoCustomerList';
import { HBLoginUrlAPI, HBSendEmailLoginAPI, getHBlogoutAPI } from '../../../services/headerbidder';
import { useGoogleLogin } from '@react-oauth/google';
import { MdInfoOutline } from 'react-icons/md';
import ButtonSkeleton from '../../loaders/button';

const InvitePublisherList = ({ id, onHide, show, getEndPointList, type }: any) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  const [selectedArray, sSelectedArray] = useState([]);
  const [googleLogin, sGoogleLogin] = useState(false);
  const [userEmailID, sUserEmailID] = useState('');
  const [csvAgencyList, sCsvAgencyList] = useState([]);

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const handleSelectedAgency = (agcyList: any) => {
    sSelectedArray(agcyList);
  };
  //handle invite publisher
  const handleInvitePublisher = () => {
    const payload = {
      customers: selectedArray,
      // customers: [...selectedArray, ...csvAgencyList],
    };
    setLoadingBtn(true);
    if (type === 'spo-publisher') {
      customerInviteSSPAPI(payload)
        .then((data: any) => {
          // data?.customers.map((item:any, index:any)=>{
          //   if(item.invite_status === true){
          //     toast.error(item?.website_url);
          //   }
          // })

          if (data.invitation_status === 0) {
            toast.error(data?.message);
            setLoadingBtn(false);
          }
          if (data.invitation_status === 1) {
            toast.error(data?.message);
            setLoadingBtn(false);
          }
          if (data.invitation_status === 2) {
            getEndPointList(data, 'add');
            setLoadingBtn(false);
            toast.success(data?.message);
          }
          onHide();
        })
        .catch((error: any) => {
          toast.error(error);
        });
    } else {
      customerInviteSPOAPI(payload)
        .then((data: any) => {
          if (data.invitation_status === 0) {
            toast.error(data?.message);
            setLoadingBtn(false);
          }
          if (data.invitation_status === 1) {
            toast.error(data?.message);
            setLoadingBtn(false);
          }
          if (data.invitation_status === 2) {
            // data.status === true
            getEndPointList(data, 'add');
            setLoadingBtn(false);
            toast.success(data?.message);
          }
          onHide();
        })
        .catch((error: any) => {
          toast.error(error);
        });
    }
  };

  useEffect(() => {
    if (localStorage.getItem('is_google_login') === 'true') {
      sGoogleLogin(true);
    }
  }, [localStorage]);

  /* Get customer id from JWT token */
  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    const decoded = JWT.decode(token, { complete: true });
    // @ts-ignore
    sUserEmailID(decoded.payload.identity);
  }, []);
  /* Get customer id from JWT token */

  const handleCsvArray = (array: any) => {
    sCsvAgencyList(array);
  };

  /* Google Login */

  useEffect(() => {
    if (!localStorage.getItem('is_google_login')) {
      verifyGoogle();
    }
  }, []);

  // const BASE_URL = process.env.REACT_APP_API_URL ? 'https://app.infy.tv' : 'https://sbapp.infy.tv';

  const [needLogin, setNeedLogin] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const verifyGoogle = () => {
    const payload = {
      redirect_uri: window.location.origin,
    };
    setIsVerifying(true);
    HBLoginUrlAPI(payload)
      .then((data: any) => {
        if (data.status) {
          setNeedLogin(data.need_login);
          sUserEmailID(data.email);
        }
        setIsVerifying(false);
      })
      .catch((error: any) => {
        //
      });
  };

  /* Open Google Login Modal */
  const openGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.send',
    onSuccess: async (codeResponse) => {
      const payload = {
        code: codeResponse.code,
        redirect_uri: window.location.origin,
      };
      handleGoogleLogin(payload);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const handleGoogleLogin = (payload: any) => {
    HBSendEmailLoginAPI(payload)
      .then((data: any) => {
        if (data.status) {
          verifyGoogle();
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error: any) => {
        //
      });
  };

  const handleGoogleLogout = () => {
    getHBlogoutAPI()
      .then((data: any) => {
        if (data.status) setNeedLogin(true);
      })
      .catch((error: any) => {
        //ok
      });
  };

  return (
    <>
      <Modal.Body
        className='p-3'
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto',
        }}
      >
        <SpoCustomerList
          show={show}
          type={type}
          getSelectedAgency={(selectedAgcy: any) => handleSelectedAgency(selectedAgcy)}
          getCsvArray={handleCsvArray}
        />
      </Modal.Body>
      <Modal.Footer className='d-flex modal-footer justify-content-between px-3'>
        <div className='d-flex'>
          {googleLogin ? (
            <>
              <img
                className='google-icon me-2'
                src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
                width={25}
                height={25}
              />
              <p>
                {glossary.spo_invite_popup_instruction}{' '}
                <span className='fw-light'>{userEmailID}</span>
              </p>
            </>
          ) : (
            <div className='d-flex align-items-center'>
              {isVerifying ? (
                <ButtonSkeleton height={38} width={200} />
              ) : (
                <>
                  {needLogin ? (
                    <>
                      <button
                        className='btn google-btn m-0 ps-2 pe-2'
                        type='button'
                        onClick={() => openGoogleLogin()}
                        style={{ maxWidth: 'fit-content' }}
                      >
                        <p className='btn-text'>
                          <img
                            className='google-icon'
                            src='https://i.postimg.cc/PJVtc2LR/google-logo.png'
                          />
                          {glossary.login_button_google}
                        </p>
                      </button>

                      <MdInfoOutline className='s-svg-icon ms-2 me-1' fontSize={18} />
                      <p>To sent mail from your gmail account use google login.</p>
                    </>
                  ) : (
                    <>
                      <img
                        className='google-icon me-2'
                        src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
                        width={25}
                        height={25}
                      />
                      <p>
                        {localStorage.getItem('customer_type') !== 'spo'
                          ? glossary.spo_invite_popup_instruction
                          : glossary.spo_publisher_invite_popup_instruction}
                        <span className='fw-light'>{' ' + userEmailID}</span>
                      </p>
                    </>
                  )}

                  {/* Logout Button */}
                  {!needLogin && (
                    <Button
                      text={'Logout'}
                      type={'button'}
                      kind={'secondary'}
                      customClass='ms-3'
                      click={() => handleGoogleLogout()}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <div className='d-flex gap-3'>
          <Button
            kind={'secondary'}
            text={glossary.spo_invite_popup_button_cancel}
            click={() => onHide()}
          />
          {!loadingBtn ? (
            <Button
              kind={'primary'}
              text={glossary.spo_invite_popup_button_invite}
              click={() => handleInvitePublisher()}
              customClass={
                selectedArray?.length > 0 || csvAgencyList?.length > 0
                  ? 'primary'
                  : 'button-disabled'
              }
            />
          ) : (
            <Button type='button' kind={'spinner'} text={'Loading...'} />
          )}
        </div>
      </Modal.Footer>
    </>
  );
};

export default InvitePublisherList;
