import React, { useEffect, useMemo, forwardRef, useRef, useState, useCallback } from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  //useBlockLayout,
  useFlexLayout,
  // useMountedLayoutEffect,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import scrollbarWidth from './scrollbarWidth';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import store from '../../states';
// import { GoLinkExternal } from 'react-icons/go';
import VideoPlayer from '../modals/video-player';
import { BsPlayFill } from 'react-icons/bs';
import Tooltip from '../comman/tooltip/Tooltip';

const SelectCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;
  const [checked, setChecked] = useState(rest.checked);

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChecked = (event: any) => {
    setChecked(!checked);
    rest.onChange(event);
  };

  return (
    <>
      <input
        type='checkbox'
        ref={resolvedRef}
        {...rest}
        onClick={handleChecked}
        checked={checked}
      />
    </>
  );
});

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className='search-wrap'>
      {/* <label className='label mb-0 me-1'>Search: </label>{' '} */}
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        //placeholder={`${count} records...`}
        placeholder={glossary.ssp_deals_input_field_seach_placeholder}
        className='form-control'
        maxLength={32}
      />
    </div>
  );
};

const CTATextCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}: any) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <textarea
      key={index}
      className='text-input'
      placeholder={'Please enter CTA text not exceeding 24 characters including spaces'}
      value={value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={24}
      style={{
        height: '100px',
        resize: 'none',
      }}
    />
  );
};

const CTALinkCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}: any) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    let newValue = value;
    if (!newValue.startsWith('https://') && !newValue.startsWith('http://') && newValue !== '') {
      newValue = 'https://' + newValue;
    }
    if (newValue === 'https://' || newValue === 'http://') {
      newValue = '';
    }
    updateMyData(index, id, newValue);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <textarea
      key={index}
      className='text-input'
      placeholder={'When a user clicks the CTA button, where do you want to land them on?'}
      value={value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      style={{
        height: '100px',
        resize: 'none',
      }}
    />
  );
};

const HashtagCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}: any) => {
  // We need to keep and update the state of the cell normally
  const [inputText, setInputText] = React.useState(initialValue?.join(' '));
  const [resultText, setResultText] = React.useState('');

  const processInput = (text: string) => {
    const tags = text.split(' ')?.filter((tag: string) => tag !== '');
    const filteredTags = tags?.filter((tag: string) =>
      tag === '#' ? tags?.indexOf(tag) < 4 : true
    );
    return filteredTags?.join(' ');
  };

  const handleInputChange = (event: any) => {
    const newText = event?.target?.value;

    if (newText?.includes('  ')) {
      return;
    }

    if (newText?.split('#').length > 4) {
      return;
    }

    if (newText?.endsWith(' ') && newText?.split('#')?.length === 4) {
      return;
    }

    if (!newText?.startsWith('#')) {
      return;
    }

    setInputText(newText);
    const processedText = processInput(newText);
    setResultText(processedText);
  };

  const handleKeyDown = (event: any) => {
    if (event?.key === 'Enter') {
      event?.preventDefault();
    }

    if (event?.key === 'Backspace' && inputText?.endsWith('#')) {
      setInputText(inputText?.slice(0, -1));
      const processedText = processInput(inputText?.slice(0, -1));
      setResultText(processedText);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, inputText?.length > 0 ? inputText?.split(' ') : []);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setInputText(initialValue?.join(' '));
  }, [initialValue]);

  return (
    <textarea
      key={index}
      className='text-input'
      placeholder={'Up to 3 hashtags separated by spaces which you want to go with the video'}
      value={inputText ?? ''}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      style={{
        height: '100px',
        resize: 'none',
      }}
    />
  );
};

const ShowMetaCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}: any) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: any) => {
    setValue(!value);
    // setTimeout(() => {
    updateMyData(index, id, !value);
    // }, 200);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className='d-flex justify-content-center w-100'>
      <input key={index} type='checkbox' onChange={onChange} defaultChecked={value} />
    </div>
  );
};

const AdCopyCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}: any) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <textarea
      key={index}
      className='text-input'
      placeholder={'Keep under 30 characters for best representation. Max limit: 60 characters'}
      value={value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={60}
      style={{
        height: '100px',
        resize: 'none',
      }}
    />
  );
};

const PixelUrlCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}: any) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    let newValue = value;
    if (!newValue.startsWith('https://') && !newValue.startsWith('http://') && newValue !== '') {
      newValue = 'https://' + newValue;
    }
    if (newValue === 'https://' || newValue === 'http://') {
      newValue = '';
    }
    updateMyData(index, id, newValue);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <textarea
      key={index}
      className='text-input'
      placeholder={'Please put your pixel URL here'}
      value={value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      style={{
        height: '100px',
        resize: 'none',
      }}
    />
  );
};

const createSelectionMapping = (selectedRow: any, data: any) => {
  const selectionMapping: any = {};

  if (selectedRow && data) {
    selectedRow.forEach((_i: any, index: any) => {
      const matchingIndex: any = data.findIndex((_j: any) => _i.video_id === _j?.video_id);
      if (matchingIndex !== -1) {
        selectionMapping[matchingIndex] = true;
      }
    });
  }

  return selectionMapping;
};

const Table = ({
  columns: userColumns,
  data,
  selectedData, // Data which is pre selected.
  updateMyData,
  onSelectedRowsChange,
  getNextPage,
  hasMore,
  isListLoading,
  kind,
  type,
}: any) => {
  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    //selectedRowIds,
    totalColumnsWidth,
    state: { selectedRowIds },
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: userColumns,
      data,
      defaultColumn,
      //   getSubRows: (row) => row.subRows || [],
      updateMyData,
      initialState: {
        //@ts-ignore
        selectedRowIds: createSelectionMapping(selectedData, data), // Pass selected & Original data for pre selection
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <div>{/* <SelectCheckbox {...getToggleAllRowsSelectedProps()} /> */}</div>
          ),
          Cell: ({ row }: any) => (
            <div>
              <SelectCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          width: 40,
        },
        ...columns,
      ]);
    }
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <>
          <tr
            {...row.getRowProps({
              style,
            })}
            className={`${
              selectedFlatRows?.length >= 10 && row?.isSelected === false ? 'disabled' : ''
            } tr`}
          >
            {row.cells.map((cell: any, index: any) => {
              return (
                <td {...cell.getCellProps()} className='td' key={index} title={cell.value}>
                  {isListLoading && rows.length - 1 === row.index ? (
                    <div className='creative-table-skeleton w-100'>
                      {cell?.column?.id === 'selection' ? (
                        <div className='creative-table-skeleton__checkbox creative-table-skeleton__comman'></div>
                      ) : (
                        <div
                          className={`${
                            cell?.column?.id === 'video_thumbnail_s'
                              ? 'creative-table-skeleton__thumbnail-col'
                              : 'creative-table-skeleton__col'
                          } creative-table-skeleton__comman `}
                        ></div>
                      )}
                    </div>
                  ) : (
                    <>{cell.render('Cell')}</>
                  )}
                </td>
              );
            })}
          </tr>
        </>
      );
    },
    [prepareRow, rows, selectedRowIds, isListLoading]
  );

  // Use only for preselecting videos
  const handleSelecting = (flatrows: any) => {
    onSelectedRowsChange(flatrows?.flatMap((item: any) => item?.original));
  };

  useEffect(() => {
    if (data?.length > 0 && selectedFlatRows) {
      setTimeout(() => {
        handleSelecting(selectedFlatRows);
      }, 200);
      // onSelectedRowsChange(selectedFlatRows?.flatMap((item: any) => item?.original));
    }
  }, [data, selectedFlatRows]);

  const lastItem = data[data.length - 1];
  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      if (
        !isListLoading &&
        hasMore &&
        visibleStopIndex === data?.length - 1 &&
        lastItem &&
        kind === 'profile'
      ) {
        getNextPage((prev: number) => prev + 1);
      }
    },
    [data?.length, hasMore, lastItem, isListLoading, getNextPage, kind]
  );

  const _height = type === 'video-creative' ? 220 : 260;

  const calculatedHeight = data?.length === 0 ? 0 : window?.innerHeight - _height;

  return (
    <>
      {/* <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}

      <div className='table-wrap mt-0'>
        <table {...getTableProps()} className='table inventories mb-0'>
          <thead className='table-header'>
            {headerGroups.map((headerGroup: any, index: any) => (
              <tr {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <th
                    {...column.getHeaderProps()}
                    // column.getSortByToggleProps({ title: undefined })
                    className='th'
                    key={index}
                  >
                    <b>{column.render('Header')}</b>
                    <span>
                      {column.isSorted && column.isSortedDesc && (
                        <IoMdArrowDropdown className='svg-icon' fontSize={20} />
                      )}
                      {column.isSorted && !column.isSortedDesc && (
                        <IoMdArrowDropup className='svg-icon' fontSize={20} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='table-body' {...getTableBodyProps()}>
            <FixedSizeList
              height={calculatedHeight}
              itemCount={rows?.length}
              itemSize={150}
              width={`100%`}
              onItemsRendered={handleItemsRendered}
            >
              {RenderRow}
            </FixedSizeList>

            {!isListLoading && data?.length === 0 && (
              <div className='text-center mt-5'>
                <h4>No Videos Found</h4>
              </div>
            )}

            {isListLoading && data?.length === 0 && (
              <div className='mt-2'>
                {Array(3)
                  .fill('data')
                  .map((_: any, index: any) => (
                    <div
                      className='creative-table-skeleton d-flex align-items-center justify-content-between'
                      key={index}
                    >
                      <div className='creative-table-skeleton__checkbox creative-table-skeleton__comman'></div>
                      <div className='creative-table-skeleton__thumbnail-col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__meta-col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                      <div className='creative-table-skeleton__col creative-table-skeleton__comman initial'></div>
                    </div>
                  ))}
              </div>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

const CreativeVideoTable = ({
  data, // Pass Normal data which are getting from API Response
  selectedData, // Pass selected data only
  handleSelect, // Get selected row from table
  handleUpdate, // Get updated row data from table
  getNextPage, // Handle Pagination
  hasMore,
  isListLoading,
  kind,
  type,
}: any) => {
  const [tableData, setTableData] = useState(data ?? []);
  const [showPlayer, setShowPlayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const handleVideo = (videoUrl: any) => {
    setVideoUrl(videoUrl);
    setShowPlayer(true);
  };

  //
  // Get pagination data and bind with existing data
  // useMemo(() => {
  // setTableData(data ?? []);
  // if (data?.length > 0) {
  // const isUpdated =
  //   data[data.length - 1]?.video_id !== tableData[tableData.length - 1]?.video_id;
  // if (data && isUpdated && kind === 'profile') {
  //   setTableData([...tableData, ...data]);
  // } else {
  //   setTableData(data);
  // }
  // }
  // console.count('Render Count');
  // }, [data]);

  useEffect(() => {
    const isUpdated = data[data.length - 1]?.video_id !== tableData[tableData.length - 1]?.video_id;
    if (isUpdated) {
      setTableData(data ?? []);
    }
  }, [data]);

  const coltitle = () => {
    return (
      <>
        CTA Link{' '}
        <Tooltip kind='info' text={'Example URL \n https://begenuin.com'} placement={'top'} />
      </>
    );
  };

  const onImageError = (e: any) => {
    e.target.src = 'https://i.postimg.cc/tC1GW5fV/default-placeholder.png';
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Videos',
        accessor: 'video_thumbnail_s',
        width: 210,
        Cell: ({ row }: any) => (
          <div className='d-flex align-items-center'>
            <div className='video-col'>
              <BsPlayFill
                className='play-icon'
                fontSize={26}
                color='#fff'
                onClick={() => {
                  handleVideo(row?.original?.video_url);
                }}
              />
              <span className='duration'>{row?.original?.duration}</span>
              <img
                src={row?.original?.video_thumbnail_s}
                alt={row?.original?.video_thumbnail_s}
                height={130}
                width={80}
                onClick={() => {
                  handleVideo(row?.original?.video_url);
                }}
                onError={onImageError}
                style={{
                  objectFit: 'cover',
                }}
              />
            </div>

            <div className='ms-2'>
              <label style={{ color: '#a2a2a2', fontSize: '14px', lineHeight: '16px' }}>
                Added On:
              </label>
              <p style={{ fontSize: '14px', lineHeight: '16px' }}>{row?.original?.import_date}</p>
              <br />
              <label style={{ color: '#a2a2a2', fontSize: '14px', lineHeight: '16px' }}>
                Added From:
              </label>
              <p style={{ fontSize: '14px', lineHeight: '16px' }}>{row?.original?.source}</p>
            </div>
          </div>
        ),
      },
      {
        Header: 'CTA Text',
        accessor: 'cta_text',
        width: 230,
        Cell: CTATextCell,
      },
      {
        Header: coltitle,
        accessor: 'cta_link',
        width: 230,
        Cell: CTALinkCell,
      },
      {
        Header: 'Hashtags',
        accessor: 'hash_tags',
        width: 230,
        Cell: HashtagCell,
      },
      {
        Header: 'URL Meta',
        accessor: 'show_url_meta',
        width: 90,
        Cell: ShowMetaCell,
      },
      {
        Header: 'Ad Copy',
        accessor: 'ad_copy',
        width: 230,
        Cell: AdCopyCell,
      },
      {
        Header: 'Pixel URL',
        accessor: 'pixel_link',
        width: 230,
        Cell: PixelUrlCell,
      },
    ],
    []
  );

  const updateMyData = (rowIndex: any, columnId: any, value: any) => {
    // We also turn on the flag to not reset the page
    // setTableData((old: any) =>
    //   old?.map((row: any, index: any) => {
    //     if (index === rowIndex) {
    //       return {
    //         ...old[rowIndex],
    //         [columnId]: value,
    //       };
    //     }
    //     return row;
    //   })
    // );

    // Send updated table data to parent component
    const updatedList = tableData?.map((row: any, index: any) => {
      if (index === rowIndex) {
        return {
          ...tableData[rowIndex],
          [columnId]: value,
        };
      }
      return row;
    });
    setTableData(updatedList);
    handleUpdate && handleUpdate(updatedList);
  };

  return (
    <>
      <VideoPlayer show={showPlayer} onHide={() => setShowPlayer(false)} video={videoUrl} />

      <Table
        columns={columns}
        data={tableData}
        selectedData={selectedData}
        expandedRows={true}
        subRowsKey='inventories'
        updateMyData={updateMyData}
        onSelectedRowsChange={handleSelect && handleSelect}
        getNextPage={getNextPage}
        hasMore={hasMore}
        isListLoading={isListLoading}
        kind={kind}
        type={type}
      />
    </>
  );
};

export default CreativeVideoTable;
