import CustomerTabs from 'react-bootstrap/Tabs';
import CustomerTab from 'react-bootstrap/Tab';
import InfyTableComponent from './InfyTableComponent';
import { routes } from '../../../services/constants';

interface Props {
    selectedCustomer?: any;
    customerType?: any;
}

export default (props: Props) => {
    const {selectedCustomer, customerType } = props;

    const tabConfig = [
        {
          key: 'users',
          title: 'Users',
          api: routes.getCustomerUser,
          method: 'POST',
          payload: {
            id: selectedCustomer,
            remove_action: true,
          },
          tableName: 'Admin Customer Details',
          downloadCSV: false,
          search: false,
        },
        {
          key: 'managers',
          title: 'Managers',
          api: routes.getManagersList + `/${selectedCustomer}/company_managers`,
          method: 'GET',
          tableName: 'Admin Customer Details',
          downloadCSV: false,
          search: false,
        },
        {
          key: 'payouts',
          title: 'Payouts',
          api: routes.getPayouts,
          method: 'POST',
          payload: {
            id: selectedCustomer,
            remove_action: true,
          },
          tableName: 'Admin Customer Details',
          downloadCSV: false,
          search: false,
        },
        {
          key: 'tags',
          title: customerType === 'demand' ? 'Sources' : 'Tags',
          api: routes.getSources,
          method: 'POST',
          payload: {
            id: selectedCustomer,
            remove_action: true,
          },
          tableName: 'Admin Customer Details',
          downloadCSV: false,
          search: false,
        },
      ];

    return (
        <CustomerTabs defaultActiveKey={tabConfig[0]?.key} className='my-3'>
            {tabConfig?.map((tab: any) => (
                <CustomerTab key={tab.key} eventKey={tab.key} title={tab.title}>
                    <InfyTableComponent
                        columnWidth={130}
                        api={tab.api}
                        method={tab.method}
                        payload={tab.payload}
                        tableName={tab.tableName}
                        downloadCSV={tab.downloadCSV}
                        search={tab.search}
                        selectedCustomerID={selectedCustomer}
                    />
                </CustomerTab>
            ))}
        </CustomerTabs>
    );
};
