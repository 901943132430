import { resolutionOpt } from './ExtOptions';

interface Props {
  resolution: string;
  companyID: number;
  tagID: number;
  location: string;
}

export default ({ resolution, companyID, tagID, location }: Props) => {
  const squareView = [
    'Medium Rectangle (300 x 250 pixels)',
    'Large Rectangle (336 x 280 pixels)',
    'Square (250 x 250 pixels)',
    'Small Square (200 x 200 pixels)',
  ];

  const landscapeView = [
    'Leaderboard (728 x 90 pixels)',
    'Banner (468 x 60 pixels)',
    'Large Leaderboard (970 x 90 pixels)',
  ];

  return (
    <>
      {/* Right-side square view */}
      {location === 'sidebar' && (
        <div
          style={{
            height: '220px',
            width: '110px',
            overflow: 'hidden',
          }}
        >
          {/* {squareView?.includes(resolution) && (
            <div
              className='gen-ext'
              data-company-id={`${companyID ?? ''}`}
              data-tag-id={`${tagID ?? ''}`}
              style={{
                height: '300px',
                width: '250px',
                transform: 'scale(0.442)',
                transformOrigin: 'top left',
              }}
            ></div>
          )} */}

          {(squareView?.includes(resolution) ||
            !resolution ||
            !resolutionOpt?.includes(resolution)) && (
            <div
              className='gen-ext'
              data-company-id={`${companyID ?? ''}`}
              data-tag-id={`${tagID ?? ''}`}
              style={{
                width: '250px',
                height: '650px',
                transform: 'scale(0.442)',
                transformOrigin: 'top left',
              }}
            ></div>
          )}
        </div>
      )}

      {location === 'center' && (
        <>
          {/* Landscape View */}
          {landscapeView?.includes(resolution) && (
            <div
              style={{
                height: '250px',
                width: '260px',
                overflow: 'hidden',
              }}
            >
              <div
                className='gen-ext'
                data-company-id={`${companyID ?? ''}`}
                data-tag-id={`${tagID ?? ''}`}
                style={{
                  width: '890px',
                  height: '850px',
                  transform: 'scale(0.293)',
                  transformOrigin: 'top left',
                }}
              ></div>
            </div>
          )}
        </>
      )}
    </>
  );
};
