import { useState } from 'react';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
//import { pl, enGB } from 'date-fns/locale';
import { defaultStaticRanges } from './DefaultRange';
import { IoCalendarOutline, IoChevronDown } from 'react-icons/io5';
// import ruLocale from "date-fns/locale/ru";
import { useLocation } from 'react-router-dom';

const staticRangesLabels = {
  Today: 'Today',
  Yesterday: 'Yesterday',
  'This Week': 'This Week',
  'This Month': 'This Month',
};

const DateRangeFilter = ({ onChange, open, setOpen, selectionRange }: any) => {
  const location = useLocation();

  const trafficUrl = location.pathname.startsWith('/traffic-dashboard');

  const dateRange = {
    startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
    endDate: new Date(),
    key: 'selection',
  };

  const dateRangeTraffic = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };

  const [state, setState] = useState<any>([selectionRange]);
  const [openCalendar, sOpenCalendar] = useState(false);

  const handleOnChange = (ranges: any) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
    if (
      moment(ranges.selection.startDate).format('MM-DD-YYYY') !==
      moment(ranges.selection.endDate).format('MM-DD-YYYY')
    ) {
      sOpenCalendar(false);
    } else if (ranges.selection.main === 'today' || ranges.selection.main === 'yesterday') {
      sOpenCalendar(false);
    }
  };

  function translateRange(dictionary: any) {
    return (item: any) =>
      dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
  }

  const ruStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));

  const formatDate = (date: any) => moment(date).format('MM-DD-YYYY');
  // const Endate =
  //   state[0]?.endDate === null ? formatDate(state[0]?.startDate) : formatDate(state[0]?.endDate);
  // //const startDate = state[0]?.startDate ? formatDate(state[0]?.startDate) : '';
  // const startDate =
  //   state[0]?.startDate === null
  //     ? formatDate(state[0]?.startDate)
  //     : formatDate(state[0]?.startDate);

  const Endate = formatDate(state[0]?.endDate);
  //const startDate = state[0]?.startDate ? formatDate(state[0]?.startDate) : '';
  const startDate = formatDate(state[0]?.startDate);

  // const onChange = (ranges: any) => {
  //   setSelectionRange(ranges);
  //   if (
  //     moment(ranges.startDate).format('MM-DD-YYYY') !== moment(ranges.endDate).format('MM-DD-YYYY')
  //   ) {
  //     sOpenCalendar(false);
  //   } else if (ranges.main === 'today' || ranges.main === 'yesterday') {
  //     sOpenCalendar(false);
  //   }
  // };

  const root = document.documentElement;
  const primary2 = getComputedStyle(root).getPropertyValue('--primary-2');

  return (
    <>
      <div className='date-range-picker'>
        {!openCalendar ? (
          <div className='calendar__wrap__range' onClick={() => sOpenCalendar(!openCalendar)}>
            <IoCalendarOutline className='svg-icon me-1' fontSize={20} />
            {state[0]?.startDate !== '' && state[0]?.endDate !== '' && (
              <p>
                {startDate} {'- '}
                {Endate}
              </p>
            )}
            <IoChevronDown className='svg-icon' fontSize={20} style={{ marginLeft: 5 }} />
          </div>
        ) : (
          <div className='calendar__wrap__modal'>
            <DateRangePicker
              onChange={handleOnChange}
              //showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              // editableDateInputs={true}
              //dateDisplayFormat='MM-dd-yyyy'
              //maxDate={new Date()}
              ranges={state}
              //ranges={state.length > 0 ? [state] : []}
              direction='horizontal'
              //locale={enGB}
              rangeColors={[primary2, primary2, primary2]}
              staticRanges={ruStaticRanges}
              inputRanges={[]}
              maxDate={new Date()}
            />
            <div className='calendar__wrap__overlay' onClick={() => sOpenCalendar(false)}></div>
          </div>
        )}
      </div>
    </>
  );
};

export default DateRangeFilter;
