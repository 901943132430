import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
// import { useIntercom } from 'react-use-intercom';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
// import LogRocket from 'logrocket';
import ReactGA from 'react-ga4';
// import JWT from 'jsonwebtoken';
import { toast } from 'react-toastify';
import { MdInfoOutline } from 'react-icons/md';
import { BiEditAlt } from 'react-icons/bi';

import Box from '../../loaders/box';
import XYChart from '../../loaders/chart';

import Line from '../../charts/line';
import Tile from '../../widgets/tile';
import DemandTraffic from './Traffic';
import MsaSign from '../../modals/msa-sign';
import DemandOnboarding from './Onboarding';
import DemandSidebar from '../../sidebar/Demand';
import AddEndpoint from '../../modals/endpoint-add';
import EditEndpoint from '../../modals/endpoint-edit';
import DetailsEndpoint from '../../modals/endpoint-details';
import DemandOverview from '../../components/overview/demand';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';

import add from '../../../assets/icons/add.svg';

import store from '../../../states';
import Reports from '../../helpers/reports';
import { tag as tagTiles } from '../../helpers/tiles';

import { routes } from '../../../services/constants';
import { UserInfoContext } from '../../contexts/userInfoContext';
import { getEndpointsListAPI, getEndpointReportAPI } from '../../../services/endpoint';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';

export const headers: any = {
  Accept: 'application/xml',
};

const css = {
  minCol: {
    minWidth: 'fit-content',
  },
  maxCol: {
    maxWidth: 'fit-content',
  },
};

const DemandSources = () => {
  const { getEndpointsListAPI, getBidderListAPI }: any = useAuthentication();
  const {
    endPointData,
    sEndPointData,
    bidderListData,
    sBidderListData,
    dateRangeDemand,
    sDateRangeDemand,
  }: any = consumerData();

  const { pathname } = useLocation();
  const history = useHistory();

  const [selectedTile, sSelectedTile] = useState(0);
  const [selectedCustomer, sSelectedCustomer] = useState('');
  const [customer, sCustomer] = useState(false);
  const [singleTileData, sSingleTileData] = useState<any>([]);
  const [lineData, sLineData] = useState<any>(null);
  const [attribute /* , sAttribute */] = useState<any>(null);
  const [label /* , sLabel */] = useState('Impressions');
  const [rawLineData /* , sRawLineData */] = useState<any>([]);
  const [refinedLineData, sRefinedLineData] = useState<any>([]);
  const [empty, sEmpty] = useState(false);
  //const [endPointData, sEndPointData] = useState<any>([]);
  const [customerDetails, sCustomerDetails] = useState<any>(null);
  const [pDatatables, sPDatatables] = useState([]);
  const [random, sRandom] = useState(Math.random());
  const [editHBandRTBShow, sEditHBandRTBShow] = useState(false);
  const [editHBandRTBShow1, sEditHBandRTBShow1] = useState(false);
  const [detailsModal, sDetailsModal] = useState(false);
  const [endpointStatus, setEndpointStatus] = useState();
  const [updatedData, setUpdatedData] = useState<any>([]);
  const [currentSelectedId, sCurrentSelectedId] = useState(''); //Current selected Card
  const [showMsaSign, sShowMsaSign] = useState(false);
  const [vastXML, setVastXML] = useState<any>();
  const [skeleton, sSkeleton] = useState(true);
  const [isCardLoading, sIsCardLoading] = useState(false);
  const [allData, setAllData] = useState(false);
  const { msaDetails, customerInfo }: any = useContext(UserInfoContext);

  useEffect(() => {
    if (customerInfo?.milestone?.endpoint || !customerInfo?.milestone?.endpoint) {
      setTimeout(() => {
        sSkeleton(false);
      }, 2000);
    }
  }, [customerInfo]);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  /* date picker start */
  // const [selectionRange, setSelectionRange] = useState({
  //   startDate: new Date(new Date().setDate(new Date().getDate() - 6)), // Number for days before
  //   endDate: new Date(),
  //   key: 'selection',
  // });
  const [currentDateSelection, sCurrentDateSelection] = useState<any>(dateRangeDemand);

  const GetSelectionRangeProps = (data: any) => {
    //setSelectionRange(data);
    sDateRangeDemand(data);
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  /* overview data above chart */
  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  /* select card */
  const selectedCard = (id: string) => {
    sCurrentSelectedId(id);
    if (selectedCustomer !== id) {
      history.push(`/sources/demand/${id}`);
    }
    if (selectedCustomer !== id && id !== currentSelectedId) {
      history.push(`/sources/demand/${id}`);
      sPDatatables([]);
      sSingleTileData([]);
      sRefinedLineData([]);
      sLineData([]);
      sSelectedCustomer(id);
      getCustomerDetails(id);
    }
    handleTabs('performance');
  };

  useEffect(() => {
    if (selectedCustomer !== '') {
      sLineData(singleTileData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
    }
  }, [selectedTile, random]);

  const getCustomerDetails = (id: string) => {
    const payload = {
      id,
      start: convert(dateRangeDemand.startDate) || '',
      end: convert(dateRangeDemand.endDate) || '',
      time_zone: 'Asia/Kolkata',
    };
    sCurrentDateSelection(dateRangeDemand);
    setAllData(false);
    if (dateRangeDemand.startDate !== dateRangeDemand.endDate) {
      getEndpointReportAPI(payload)
        .then((data: any) => {
          if (data) {
            const tileData = tagTiles(data.total);
            sSingleTileData(tileData);
            sSelectedTile(0);
            sRandom(Math.random());
            sPDatatables(endPointData.find((x: any) => x.id === id)?.tables ?? []);
            setAllData(true);
          }
        })
        .catch(() => {
          // toast.error('Service unavailable, Check back after sometime.');
        });
    }
  };
  /* Get costomer data */

  /***** Handle on scroll API's start *****/
  const [isEmpty, sIsEmpty] = useState(false);
  const [currentPage, sCurrentPage] = useState(0);
  const [hasMore, sHasMore] = useState(true);
  const [endpointLegnth, sEndpointLegnth] = useState<any>(null);

  const [params, setParams] = useState({
    page: 0,
    query: '',
    sortBy: {
      id: 'earning',
      desc: true,
    },
  });
  const [paramsStatus, setParamsStatus] = useState(false);

  useEffect(() => {
    const endpointid = pathname.split('/sources/demand/')[1];
    if (endPointData.length === 0 || params.query === '') {
      getEndpointListInfo(params, parseInt(endpointid));
    }
    if (paramsStatus === true) {
      getEndpointListInfo(params, parseInt(endpointid));
    }
  }, [params.page, params.query, params.sortBy, paramsStatus]);

  const handleParamsChange = (page: number, query: string, sortBy: any, status: boolean) => {
    if (
      page !== params.page ||
      query !== params.query ||
      sortBy.id !== params.sortBy.id ||
      sortBy.desc !== params.sortBy.desc
    ) {
      setParams({ page, query, sortBy });
      setParamsStatus(status);
    }
    if (params.sortBy !== sortBy || params.query !== query) {
      sEndPointData([]);
    }
  };

  /***** Handle on scroll API's end *****/
  // const getEndpointListInfo = async (params: any, _id: any) => {
  //   const payload = {
  //     page: params.page,
  //     page_size: 500,
  //     query: params.query,
  //     sort_by: {
  //       sortBy: [params.sortBy],
  //     },
  //   };

  //   try {
  //     sIsCardLoading(true);
  //     setAllData(false);
  //     const data:any = await getEndpointsListAPI(payload);

  //     if (data) {
  //       sCurrentPage(params.page);
  //       sIsCardLoading(false);
  //       sIsEmpty(params.page === 0 && data.data.length < 1 ? true : false);
  //       setEndpointStatus(data?.status);

  //       if (params.page > 0) {
  //         if (data.data.length !== 0) {
  //           sEndPointData((prevData:any) => [...prevData, ...data.data]);
  //         } else {
  //           sEndPointData(endPointData);
  //           sHasMore(false);
  //         }
  //       } else {
  //         if (params.page === 0 && params.query.length < 1 && data.data.length < 1) sEmpty(true);
  //         sEndPointData(data.data);
  //         // compare id when type id in param
  //         if (_id) {
  //           selectedCard(data.data.find((x: any) => x.id === _id)?.id);
  //         }
  //       }

  //       sCustomer(false);
  //       sEditHBandRTBShow(false);
  //       sEditHBandRTBShow1(false);
  //       setAllData(true);
  //     }
  //   } catch (error) {
  //     // Handle error gracefully, e.g., toast.error('Service unavailable, Check back after sometime.');
  //   }
  // };
  const getEndpointListInfo = (params: any, _id: any) => {
    const payload = {
      page: params.page,
      page_size: 500,
      query: params.query,
      sort_by: {
        sortBy: [params.sortBy],
      },
    };
    sIsCardLoading(true);
    setAllData(false);
    getEndpointsListAPI(payload)
      .then((data: any) => {
        if (data) {
          sCurrentPage(params.page);
          sIsCardLoading(false);
          sIsEmpty(params.page === 0 && data.data.length < 1 ? true : false);
          setEndpointStatus(data?.status);
          if (params.page > 0) {
            if (data.data.length !== 0) {
              sEndPointData([...endPointData, ...data.data]);
              sEndpointLegnth(1);
            } else {
              sEndPointData(endPointData);
              sEndpointLegnth(endPointData.length);
              sHasMore(false);
              // selectedCard(params.page === 0 ? endPointData[0].id : selectedCustomer);
            }
          } else {
            if (params.page === 0 && params.query.length < 1 && data.data.length < 1) sEmpty(true);
            sEndPointData(data.data);
            // sCurrentSelectedId(data.data[0].id);
            //compare id when type id in param
            if (params.query?.length > 0) {
              sEndpointLegnth(1);
            } else {
              sEndpointLegnth(data.data.length);
            }
            if (_id) {
              selectedCard(data.data.find((x: any) => x.id === _id)?.id);
            }
            // else {
            //   selectedCard(data.data[0].id);
            // }
          }
          sCustomer(false);
          sEditHBandRTBShow(false);
          sEditHBandRTBShow1(false);
          setAllData(true);
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  /***** UPDATE ENDPOINT LIST BY ADD/UPDATE ENDPOINT START *****/
  const handleEndpointLists = (endpointObject: any, kind: string, enType: string) => {
    if (kind === 'add') {
      if (enType !== 'GAM') {
        const newEndpointObject = { ...endpointObject.data };
        const newArr = [newEndpointObject, ...endPointData];
        // newArr.splice(1, 0, newEndpointObject);
        selectedCard(newEndpointObject.id);
        sEndPointData(newArr);
        sCustomer(false);
      } else {
        const newEndpointArray = endpointObject.data;
        // const newArr = [...endPointData.slice(0, 1), ...newEndpointArray, ...endPointData.slice(1)];
        const newArr = [...newEndpointArray, ...endPointData];
        selectedCard(newEndpointArray[0].id);
        sEndPointData(newArr);
        sCustomer(false);
      }
    }

    if (kind === 'update') {
      const newEndpointObj = { ...endpointObject.data };
      const newArray = [...endPointData];
      const index = newArray.findIndex((item) => item.id === newEndpointObj.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...newEndpointObj };
      }
      sEndPointData(newArray);
      setUpdatedData(newArray);
      sEditHBandRTBShow(false);
      sEditHBandRTBShow1(false);
    }
  };

  /***** UPDATE ENDPOINT LIST BY ADD/UPDATE ENDPOINT END *****/
  useEffect(() => {
    if (updatedData.length > 0) {
      sEndPointData(updatedData ?? []);
      sPDatatables(updatedData.tables);
      const getCustomer: any = updatedData.find((x: any) => x.id === selectedCustomer);
      sCustomerDetails(getCustomer);
    }
  }, [updatedData]);

  useEffect(() => {
    if (
      selectedCustomer &&
      endPointData &&
      (convert(currentDateSelection.startDate) !== convert(dateRangeDemand.startDate) ||
        convert(currentDateSelection.endDate) !== convert(dateRangeDemand.endDate))
    ) {
      getCustomerDetails(selectedCustomer);
    }
  }, [dateRangeDemand, endPointData]);

  useMemo(() => {
    if (endPointData?.length > 0 || selectedCustomer) {
      sPDatatables(endPointData?.find((x: any) => x?.id === selectedCustomer)?.tables ?? []);
    }
  }, [endPointData, selectedCustomer]);

  useEffect(() => {
    const getCustomer: any = endPointData?.find((x: any) => x.id === selectedCustomer);
    sCustomerDetails(getCustomer);

    /* get xml hash code number */
    if (selectedCustomer && getCustomer?.type === 'vast_xml') {
      const xmlEndpoint = endPointData?.find((x: any) => x.id === selectedCustomer)?.endpoint;
      initiateGetVastXml(xmlEndpoint);
    }
  }, [selectedCustomer, endPointData]);

  /* Chart sata start */
  useEffect(() => {
    const bottom: any = [];
    rawLineData.map((_: any, i: any) => {
      return bottom.push({ date: _.date, [attribute]: _[attribute] });
    });
    sRefinedLineData({
      labels: bottom.map((a: any) => a.date),
      values: bottom.map((a: any) => a[attribute]),
    });
  }, [rawLineData, attribute]);

  useEffect(() => {
    if (lineData && lineData.length) {
      let newlabels: any = [];
      newlabels = lineData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = lineData.map((_: any) => _[Object.keys(_)[0]]);
      /* replace new value array */
      sRefinedLineData({ labels: newValues, values: newlabels });
      //sRefinedLineData({ labels: newlabels, values: newValues });
    } else {
      sRefinedLineData({ labels: [], values: [] });
    }
  }, [lineData]);
  /* Chart sata end */

  const editHBandEndPoint = (tagId: any) => {
    sEditHBandRTBShow(true);
  };

  const editHBandEndPoint1 = (tagId: any) => {
    sEditHBandRTBShow1(true);
    if (tagId) sDetailsModal(true);
  };

  const createEndpoint = () => {
    ReactGA.event('add_new_button', {
      category: 'end_point_header_biding',
      action: 'click',
      label: '+_add_new_button_click',
    });
    if (msaDetails.status) {
      sCustomer(true);
    }
    if (!msaDetails.generate_button) {
      sShowMsaSign(true);
    }
    if (!msaDetails.status && msaDetails.generate_button) {
      toast.success('MSA was sent to your email. Please check your mailbox.');
    }
  };

  /* get xml vast code */
  const initiateGetVastXml = (xmlCode: any) => {
    headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
    const options = {
      headers,
      method: 'GET',
    };
    return new Promise((resolve, reject) => {
      fetch(routes.getVastXml + `/${xmlCode}/vast_xml`, options)
        .then((res) => res.text())
        .then((xmlString) => {
          new window.DOMParser().parseFromString(xmlString, 'text/xml');
          setVastXML(xmlString);
        })
        .catch((err) => {
          // console.log(err);
        });
    });
  };

  const location = useLocation();
  const endpoint_id = location.pathname.split('/sources/demand/')[1];

  useEffect(() => {
    if (location?.state === 'clickLogo') {
      sSelectedCustomer('');
      sCurrentSelectedId('');
    }
  }, [endPointData, location?.state]);

  const [selectedTab, setSelectedTab] = useState('performance');

  const handleTabs = (key: any) => {
    setSelectedTab(key);
  };

  /* Get Bidder list api */
  useEffect(() => {
    if (Object.keys(bidderListData).length === 0 && customerInfo?.milestone?.endpoint) {
      // Your code when bidderListData is empty
      getHeaderBiderList();
    }
  }, [customerInfo, bidderListData]);

  const getHeaderBiderList = () => {
    getBidderListAPI()
      .then((data: any) => {
        sBidderListData(data);
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  return (
    <>
      {/* Modal Component For MSA*/}
      <MsaSign
        show={showMsaSign}
        onHide={() => {
          sShowMsaSign(false);
        }}
      />

      {/* Modal Component For Add Endpoint */}
      <AddEndpoint
        show={customer}
        getEndPointList={handleEndpointLists}
        onHide={() => {
          sCustomer(false);
        }}
        customerID={customerInfo?.id}
      />

      {/* Modal Component For Endpoint Details */}
      <DetailsEndpoint
        show={editHBandRTBShow1}
        getEndPointList={handleEndpointLists}
        onHide={() => {
          sEditHBandRTBShow1(false);
        }}
        enpointListData={endPointData?.find((x: any) => x.id === selectedCustomer)}
        enpointListDataConfigObj={endPointData?.find((x: any) => x.id === selectedCustomer)?.config}
        endpointId={endPointData?.find((x: any) => x.id === selectedCustomer)?.id}
        Hbkey={endPointData?.find((x: any) => x.id === selectedCustomer)?.config?.bidderTitle}
        openDetailsModal={detailsModal}
        xmlEndpoint={vastXML}
        bidderListData={bidderListData}
      />

      {/* Modal Component For Edit Endpoint Details */}
      <EditEndpoint
        show={editHBandRTBShow}
        getEndPointList={handleEndpointLists}
        onHide={() => {
          sEditHBandRTBShow(false);
        }}
        enpointListData={endPointData?.find((x: any) => x.id === selectedCustomer)}
        enpointListDataConfigObj={endPointData?.find((x: any) => x.id === selectedCustomer)?.config}
        endpointId={endPointData?.find((x: any) => x.id === selectedCustomer)?.id}
        Hbkey={endPointData?.find((x: any) => x.id === selectedCustomer)?.config?.bidderTitle}
        openDetailsModal={detailsModal}
        xmlEndpoint={vastXML}
        bidderListData={bidderListData}
      />
      {/* <div
        className={
          skeleton ? 'd-block spinner position-relative' : 'd-none spinner position-relative'
        }
      >
        <Spinner animation='border' className='spinner-wrap' />
      </div> */}

      {/*Start Onboarding section*/}
      {endpointLegnth === 0 && customerInfo?.milestone?.endpoint === false && (
        <DemandOnboarding endpointStatus={endpointStatus} customerID={customerInfo?.id} />
      )}

      {/* Regular Section */}
      <div className={'content d-flex'}>
        <DemandSidebar
          title={glossary.dsp_dash_sidebar_title}
          kind={'dsp'}
          size={'collapsed'}
          action={{
            icon: add,
            text: glossary.dsp_dash_sidebar_button_add_new,
            className: 'tour-addnew-dsp',
            click: createEndpoint,
          }}
          overviewAction={{
            text: glossary.overview_button,
            click: () => {
              sSelectedCustomer('');
              sCurrentSelectedId('');
              history.push('/sources/demand');
            },
            isOverview: location.pathname === '/sources/demand' ? true : false,
          }}
          card={'expanded'}
          items={endPointData}
          info={selectedCard}
          tagDetails={editHBandEndPoint1}
          tagDetails1={editHBandEndPoint}
          empty={isEmpty}
          selected={selectedCustomer}
          currentPage={currentPage}
          loading={isCardLoading}
          hasMore={hasMore}
          params={params}
          handleParams={handleParamsChange}
          allData={true}
        />
        <div className='view-section'>
          {selectedCustomer === '' && endPointData && !endpoint_id ? (
            <DemandOverview empty={empty} />
          ) : (
            <Tab.Container
              defaultActiveKey={'performance'}
              activeKey={selectedTab}
              onSelect={(key: any) => handleTabs(key)}
            >
              <div className='row align-items-center'>
                <div className='col-4'>
                  <div className='d-flex gap-2 align-items-center'>
                    <h2
                      className={'title-truncate'}
                      style={{ maxWidth: '300px', fontWeight: 200, width: 'fit-content' }}
                    >
                      {customerDetails?.type === 'hb_connector'
                        ? customerDetails?.config?.integrationName
                        : customerDetails?.name}
                    </h2>
                    <MdInfoOutline
                      className='svg-icon tour-dspinfo'
                      onClick={() => {
                        editHBandEndPoint1(
                          endPointData?.find((x: any) => x.id === selectedCustomer).id
                        );
                      }}
                      title='Details'
                      fontSize={20}
                    />
                    <BiEditAlt
                      className='svg-icon tour-dspedit'
                      onClick={() => {
                        sEditHBandRTBShow(true);
                      }}
                      title='Edit'
                      fontSize={20}
                    />
                  </div>
                </div>

                <div className='col-4'>
                  <Nav variant='pills'>
                    <Nav.Item>
                      <Nav.Link eventKey='performance'>
                        {glossary.dsp_dash_tab_performance}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='traffic'>{glossary.dsp_dash_tab_traffic}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <div className='col-4'>
                  {selectedTab !== 'traffic' && (
                    <div
                      className={'calendar__wrap tour-calendar-wrap'}
                      style={{ position: 'relative' }}
                    >
                      <div className={'calendar__wrap tour-calendar-wrap'}>
                        <DateRangeFilter
                          onChange={GetSelectionRangeProps}
                          selectionRange={dateRangeDemand}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className='row'
                style={{ marginTop: selectedTab !== 'traffic' ? '50px' : '40px' }}
              >
                <div className='col-12'>
                  <Tab.Content>
                    <Tab.Pane eventKey={'performance'}>
                      <div className='row'>
                        <div className={'col'} style={css.maxCol}>
                          <div className={'row overview-tiles'}>
                            {singleTileData.map((tile: any, index: any) => {
                              return (
                                <div key={index} className={'col'} style={css.minCol}>
                                  {refinedLineData.labels < 1 &&
                                  refinedLineData.values < 1 ? null : (
                                    <Tile
                                      id={tile.id}
                                      selected={selectedTile}
                                      click={selectTile}
                                      label={tile.label}
                                      value={tile.value}
                                      info={tile.info}
                                      arrow={tile.arrow}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      {refinedLineData.labels < 1 && refinedLineData.values < 1 ? (
                        <>
                          <div
                            className={'row'}
                            style={{ marginTop: '-10px', justifyContent: 'space-between' }}
                          >
                            <div className='col-6'>
                              <div className='d-flex gap-4'>
                                {[1, 2, 3, 4].map((index) => {
                                  return <Box key={index} />;
                                })}
                              </div>
                            </div>
                          </div>
                          <div className='row mt-4'>
                            <div className='col w-100 m-auto'>
                              <XYChart />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={'row'}
                            style={{
                              height: '320px',
                              marginTop: '24px',
                            }}
                          >
                            <Line label={label} data={refinedLineData} attribute={attribute} />
                          </div>
                        </>
                      )}

                      <div className={'row report-table'} style={{ marginTop: 24 }}>
                        <Reports
                          id={selectedCustomer}
                          start={dateRangeDemand.startDate}
                          end={dateRangeDemand.endDate}
                          tabs={pDatatables}
                          random={random}
                          entityType={'end_point'}
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'traffic'}>
                      <DemandTraffic endpoint_id={selectedCustomer} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          )}
        </div>
      </div>
    </>
  );
};

export default DemandSources;
