import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { generateTokenAPI } from '../../services/authentication';
import * as Yup from 'yup';
import Button from '../widgets/button';
import store from '../../states';
import FormikController from '../comman/form/FormikController';

export default () => {
  const { push, location } = useHistory<{ email: string }>();

  const [spinnerBtn, sSpinnerBtn] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // Formik Initials value
  const InitialValues = {
    email: location?.state?.email ?? '',
  };

  // Field Validation
  const Validation = Yup.object().shape({
    email: Yup.string()
      .email(glossary.forgot_password_email_valid_error)
      .required(glossary.forgot_password_email_empty_error),
  });

  const handleForgotPass = (event: any) => {
    sSpinnerBtn(true);
    generateTokenAPI({ method: 'forget', email: event.email })
      .then((data: any) => {
        if (data.status) {
          push('/link-sent', { email: event.email });
          sSpinnerBtn(false);
        } else {
          toast.error(data.msg);
          sSpinnerBtn(false);
        }
      })
      .catch(() => null);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100vw',
          height: 'calc(100vh - 66px)',
        }}
      >
        <div
          className={'card-box'}
          style={{
            margin: 'auto',
            rowGap: '16px',
            width: '550px',
            display: 'flex',
            padding: '72px 118px',
            flexDirection: 'column',
          }}
        >
          <h3 className='fw-light text-center'>{glossary.forget_password_title}</h3>
          <p className='text-center'>{glossary.forget_password_description}</p>
          <Formik
            validationSchema={Validation}
            initialValues={InitialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(e) => {
              handleForgotPass(e);
            }}
          >
            {() => (
              <Form className='rtb-form'>
                <>
                  <div className='row mb-3'>
                    <div className='col-12 mb-2'>
                      <div className='form-group'>
                        <FormikController
                          kind='withouticon'
                          control='input'
                          type='text'
                          label={glossary.forget_password_input_field_label}
                          placeholder={glossary.forget_password_input_field_label}
                          name='email'
                          maxLength={320}
                          disable={true}
                        />
                      </div>
                    </div>
                  </div>
                </>
                {spinnerBtn ? (
                  <Button
                    type='button'
                    kind={'spinner'}
                    customClass={'w-100'}
                    text={'Loading...'}
                  />
                ) : (
                  <Button
                    type='submit'
                    kind={'primary'}
                    text={glossary.forget_password_button}
                    size={'full'}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
