import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { toast } from 'react-toastify';
import { reqDealExcludeAPI, reqDealIncludeAPI } from '../../services/deals';
import Switch from '../widgets/switch';

const RequestedDealInventories = (Props: any) => {
  const { accessor, row, controller, dealID } = Props;

  const inventoriesToggleInclude = (_row: any, id: number) => {
    const inventoriesID: any = [];
    if (row.depth === 0) {
      row.originalSubRows.forEach((item: any) => {
        inventoriesID.push(item.a_id);
      });
    } else {
      inventoriesID.push(row.original.a_id);
    }
    const payload = {
      deal_id: row.depth === 0 ? row.original.id : row.original.deal_id,
      inventory_ids: inventoriesID,
      requested_deal_id: id,
    };
    reqDealIncludeAPI(payload)
      .then((data: any) => {
        if (data) {
          toast.success(data);
          controller && controller(dealID);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const inventoriesToggleExclude = (_row: any, id: number) => {
    const inventoriesID: any = [];
    if (row.depth === 0) {
      row.originalSubRows.forEach((item: any) => {
        inventoriesID.push(item.a_id);
      });
    } else {
      inventoriesID.push(row.original.a_id);
    }
    const payload = {
      deal_id: row.depth === 0 ? row.original.id : row.original.deal_id,
      inventory_ids: inventoriesID,
      requested_deal_id: id,
    };
    reqDealExcludeAPI(payload)
      .then((data: any) => {
        if (data) {
          toast.success(data);
          controller && controller(dealID);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  return (
    <>
      {accessor === 'expand' &&
        (row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <MdOutlineKeyboardArrowDown className='svg-icon' fontSize={18} />
            ) : (
              <MdOutlineKeyboardArrowRight className='svg-icon' fontSize={18} />
            )}
          </span>
        ) : null)}

      {accessor === 'excluded' && (
        <>
          <Switch
            api={(chec: any) => {
              chec ? inventoriesToggleInclude(row, dealID) : inventoriesToggleExclude(row, dealID);
            }}
            status={!row.original.excluded}
          />
        </>
      )}
    </>
  );
};

export default RequestedDealInventories;
