import { useContext, useEffect, useState } from 'react';
import v from 'validator';

import store from '../../../states';
import Input from '../../widgets/input';
import PlatformCost from './platform-cost';
import MsaSign from '../../modals/msa-sign';
import Dropdown from '../../widgets/dropdown';
import ImageCropper from '../../helpers/ImageCrop';
import { getMsaDocAPI } from '../../../services/msaSign';
import { updateAccountAPI } from '../../../services/settings';
import { UserInfoContext } from '../../contexts/userInfoContext';

export default ({ toast, /* store */ status }: any) => {
  // const accountInputFile = useRef<HTMLInputElement>(null);

  // Context API
  const { msaDetails, setCustomerInfo, customerInfo }: any = useContext(UserInfoContext);

  const [companyName, sCompanyName] = useState('');
  const [companySize, sCompanySize] = useState(customerInfo?.company_size ?? '-');
  const [industryName, sIndustryName] = useState(customerInfo?.industry ?? '-');
  const [websiteUrl, sWebsiteUrl] = useState(customerInfo?.website_url ?? '');
  const [urlError, setUrlError] = useState('');
  const [showMsaSign, sShowMsaSign] = useState(false);
  const [companySizeValue, setCompanySizeValue] = useState('');
  const [industryValue, setIndustryValue] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const updateAccountValues = (key: string, value: any) => {
    if (key === 'accountCompanyName') sCompanyName(value);
    if (key === 'accountCompanySize') sCompanySize(value);
    if (key === 'accountIndustryName') sIndustryName(value);
    if (key === 'accountCompanyUrl') {
      if (!v.isURL(value)) {
        setUrlError(glossary.setting_company_input_company_website_url_valid_error);
      } else {
        setUrlError('');
        sWebsiteUrl(value);
      }
    }
  };

  const getMSADoc = () => {
    setBtnDisabled(true);
    getMsaDocAPI().then(async (data: any) => {
      if (!data.status) {
        toast.error(data.message);
      } else {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MSA.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
      setBtnDisabled(false);
    });
  };

  const companySizeDropdown = (value: any) => {
    setCompanySizeValue(value.value ?? companySize);
    sCompanySize(value.value);
  };

  const industryDropdown = (value: any) => {
    setIndustryValue(value.value ?? industryName);
    sIndustryName(value.value);
  };

  useEffect(() => {
    if (customerInfo) {
      sCompanyName(customerInfo?.company_name ?? '');
      sCompanySize(customerInfo?.company_size ?? '-');
      sIndustryName(customerInfo?.industry ?? '-');
      sWebsiteUrl(customerInfo?.website_url);
    }
  }, [customerInfo]);

  useEffect(() => {
    if (industryValue) {
      updateAccountDetails();
    }
  }, [industryValue]);

  useEffect(() => {
    if (companySizeValue) {
      updateAccountDetails();
    }
  }, [companySizeValue]);

  const updateAccountDetails = () => {
    const payload = {
      company_name: companyName ?? '',
      company_size: companySizeValue ?? '',
      industry: industryValue ?? '',
      website_url: websiteUrl ?? '',
    };
    updateAccountAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          setCustomerInfo((prev: any) => {
            return {
              ...prev,
              company_name: companyName,
              company_size: companySizeValue || companySize,
              industry: industryValue || industryName,
              website_url: websiteUrl,
            };
          });
        }
      })
      .catch(() => {
        //
      });
  };

  /* open modal on click MSA execute */
  const onClickMsaSignButton = () => {
    if (!msaDetails.generate_button) {
      sShowMsaSign(true);
    }
    if (!msaDetails.status && msaDetails.generate_button) {
      toast.success('MSA was sent to your email. Please check your mailbox.');
    }
  };

  return (
    <>
      <MsaSign
        status={status}
        show={showMsaSign}
        onHide={() => {
          sShowMsaSign(false);
        }}
      />
      <div className={'row'}>
        <div className={'row'} style={{ justifyContent: 'space-between' }}>
          <div className={'col'}>
            <h2 style={{ fontWeight: 200 }}>{glossary.setting_company_title}</h2>
          </div>
        </div>
        <div className={'col profile__main'}>
          <div className={'row'}>
            <div className={'col col-auto'}>
              <div className={'profile__main__image me-4'}>
                <div className='profile__main__image__icon 123'>
                  <ImageCropper />
                </div>
              </div>
            </div>
            <div className='col-6 col-lg-5'>
              <div className={'row gap-5 mb-5'}>
                <div className='col'>
                  <Input
                    name={'accountCompanyName'}
                    kind={'mini'}
                    type={'text'}
                    length={160}
                    change={updateAccountValues}
                    blur={updateAccountDetails}
                    label={glossary.setting_company_input_field_label_company_name}
                    value={companyName}
                    placeholder={'Enter Your Company Name'}
                  />
                </div>
                <div className='col'>
                  <div>
                    <Dropdown
                      label={glossary.setting_company_ddl_label_industry_type}
                      name={'accountIndustryName'}
                      option={[
                        { label: 'Sports', value: 'Sports' },
                        { label: 'Entertainment', value: 'Entertainment' },
                        { label: 'News', value: 'News' },
                        { label: 'Business', value: 'Business' },
                        { label: 'Kids/Family', value: 'Kids/Family' },
                        { label: 'Music', value: 'Music' },
                        { label: 'Multicultural', value: 'Multicultural' },
                        { label: 'Food & Cooking', value: 'Food & Cooking' },
                        { label: 'Travel', value: 'Travel' },
                        { label: 'Screensavers', value: 'Screensavers' },
                        { label: 'Games', value: 'Games' },
                        { label: 'Hobbies & Interests', value: 'Hobbies & Interests' },
                        { label: 'Other', value: 'Other' },
                      ]}
                      onchange={industryDropdown}
                      defaultValue={{ label: industryName, value: industryName } ?? []}
                      placeHolder={industryName}
                      search={false}
                    />
                  </div>
                </div>
              </div>
              <div className={'row gap-5 mb-5'}>
                <div className='col'>
                  <Dropdown
                    label={glossary.setting_company_ddl_label_company_size}
                    name={'accountCompanySize'}
                    option={[
                      { label: '1-10', value: '1-10' },
                      { label: '11-50', value: '11-50' },
                      { label: '51-100', value: '51-100' },
                      { label: '101-500', value: '101-500' },
                      { label: '500+', value: '500+' },
                    ]}
                    onchange={companySizeDropdown}
                    placeHolder={companySize}
                    defaultValue={{ label: companySize, value: companySize } ?? []}
                    search={false}
                  />
                </div>
                <div className='col'>
                  {localStorage.getItem('customer_type') !== 'creative' && (
                    <Input
                      error={urlError}
                      name={'accountCompanyUrl'}
                      kind={'mini'}
                      type={'text'}
                      length={2048}
                      change={updateAccountValues}
                      blur={updateAccountDetails}
                      label={glossary.setting_company_input_field_label_company_website}
                      value={websiteUrl}
                      placeholder={'Enter Company Website'}
                      disabled={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr className='mt-5'></hr>
          {localStorage.getItem('customer_type') !== 'creative' && (
            <>
              <div className='row align-items-center my-2'>
                <div className='col-auto'>
                  <label className='label mb-0'>{glossary.setting_company_mas_field_label}</label>
                </div>
                <div className='col'>
                  <div className='d-flex gap-3'>
                    {msaDetails.status ? (
                      <button
                        className={
                          btnDisabled
                            ? 'button button-disabled'
                            : 'button button-primary text-white'
                        }
                        onClick={getMSADoc}
                      >
                        {glossary.setting_company_mas_button_download}
                      </button>
                    ) : (
                      <button
                        className='button button-primary text-white'
                        onClick={() => {
                          onClickMsaSignButton();
                          //handleClickCreate();
                        }}
                      >
                        {glossary.setting_company_mas_button_request}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <hr />
            </>
          )}

          {/* {stripeLoading ? (
            <div className='d-flex gap-3'>
              <ButtonSkeleton height={60} width={160} />
              <ButtonSkeleton height={60} width={160} />
            </div>
          ) : (
            <StripConnect />
          )}

          <hr /> */}
          {(localStorage.getItem('customer_type') === 'publisher' ||
            localStorage.getItem('customer_type') === 'supply' ||
            localStorage.getItem('customer_type') === 'spo') && <PlatformCost />}
        </div>
      </div>
    </>
  );
};
