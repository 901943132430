import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { removeMacrosAPI } from '../../services/macros';
import { toast } from 'react-toastify';
import store from '../../states';
import Button from '../widgets/button';

export default ({ show, onHide, api, data }: any) => {
  const [glossary, sGlossary] = useState<any>({});

  //get glossary
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  //delete macros
  const removeMacros = (id: any) => {
    const payload = {
      id: Number(id),
    };
    removeMacrosAPI(payload)
      .then((data: any) => {
        if (data) {
          api();
        }
        if (data.status === true) toast.success(data.message);
      })
      .catch((error: any) => {
        //ok
      });
  };

  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Header className='px-3'>
        <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
          {glossary.setting_ssp_delete_macros_popup_title}
        </h3>
      </Modal.Header>
      <Modal.Body className='p-3'>
        <p className='py-4'>{glossary.setting_ssp_delete_macros_popup_instructions}</p>
      </Modal.Body>
      <Modal.Footer className='px-3 gap-2'>
        <Button
          kind={'secondary'}
          text={glossary.setting_ssp_delete_macros_popup_button_cancel}
          click={() => {
            onHide();
          }}
        />
        <Button
          kind={'primary'}
          text={glossary.setting_ssp_delete_macros_popup_button_delete}
          click={() => {
            removeMacros(data.id);
            onHide();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
