import { useContext } from 'react';
import Coretable from '../../tables/Coretable';
import { UserInfoContext } from '../../contexts/userInfoContext';

const PlatformCost = () => {
  const { customerInfo }: any = useContext(UserInfoContext);
  const isCreativeCustomer = localStorage.getItem('customer_type') === 'creative';

  return (
    <div className='row my-4'>
      <div className={isCreativeCustomer ? 'col-12' : 'col-12 mb-4'}>
        <div className='shadow p-3'>
          <div className='col'>
            <div className='mb-3'>
              <h4>{isCreativeCustomer ? 'Subscription Cost: $3000.00' : 'Platform Cost:'}</h4>
            </div>
          </div>

          <div className='table-responsive platformcost-table'>
            <Coretable
              column={customerInfo?.platform_cost?.columns ?? []}
              data={customerInfo?.platform_cost?.data ?? []}
              search={false}
              fixedHeight={isCreativeCustomer ? '80px' : '150px'}
              ignoreCol={[]}
              tableName={'PMPDeals-Customers'}
            />
            <p className='mt-3 mb-0'>
              The calculations of platform cost will be sum of tier-wise spending
            </p>
            <p className='mt-2'>
              {isCreativeCustomer ? (
                <ul>
                  <li>
                    First 1Mm Monthly impressions the platform cost of creative has no cost. After
                    each 1000 miles Monthly impressions will charge at 3$.
                  </li>
                  <li>You can create an unlimited number of creatives.</li>
                  <li>We offer a real-time reporting dashboard for tracking your data.</li>
                  <li>You can invite up to 10 users.</li>
                  <li>Enjoy streamlined user management.</li>
                  <li>Our user support is available to assist you.</li>
                </ul>
              ) : (
                `For example in case of 8Mm Monthly impressions the platform cost for VAST integration
              will be $9500 (1000 miles * $1.50 + 4000 miles * $1.25 + 3000 miles * $1.00)`
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformCost;
