import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SplitForm from './SplitForm';
// import { BsBank, BsCreditCard } from 'react-icons/bs';
// import BankAccountForm from './BankAccountForm';
// import { UserInfoContext } from '../../contexts/userInfoContext';
import store from '../../../states';
import { MdInfoOutline } from 'react-icons/md';

const STRIP_ENV: any =
  process.env.REACT_APP_STRIP_KEY ||
  'pk_test_51KVsfULnuRI7m9H0d7qtT9npr0QbZLPGrYRZI2CB159u5auobrFnDv6PKwEIFo3O8GuhYKEMybMhRhQnxA0FGZzV00SNQhGHay';

const stripePromise: any = loadStripe(STRIP_ENV);

const StripeElement = ({ modal, onHide }: any) => {
  // Context API
  // const { userInfo }: any = useContext(UserInfoContext);

  // const [paymentMethodType, setPaymentMethodType] = useState('card');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className='row'>
      <div className='d-flex'>
        <span>
          <MdInfoOutline fontSize={16} className='s-svg-icon me-1' style={{ marginTop: '2px' }} />
        </span>
        <p>{glossary.srtipe_card_instruction}</p>
      </div>

      <div className='d-flex mb-1'>
        {/* <button
          className={`stripe-tab ${paymentMethodType === 'card' ? 'active' : ''}`}
          onClick={() => setPaymentMethodType('card')}
        >
          <BsCreditCard fontSize={18} className='me-2' /> {glossary.stripe_tab_label_card}
        </button> */}

        {/* <button
          className={`stripe-tab ${paymentMethodType === 'bank_account' ? 'active' : ''}`}
          onClick={() => setPaymentMethodType('bank_account')}
        >
          <BsBank fontSize={16} className='me-2' />
          {glossary.stripe_tab_label_bank}
        </button> */}
        <span>
          <MdInfoOutline fontSize={16} className='s-svg-icon me-1' />
        </span>
        <p>{glossary.srtipe_card_note}</p>
      </div>

      {stripePromise && (
        <Elements stripe={stripePromise}>
          {/* {paymentMethodType === 'card' && ( */}
          <SplitForm modal={modal ? true : false} onHide={onHide} />
          {/* )} */}
          {/* {paymentMethodType === 'bank_account' && (
            <BankAccountForm
              userInfo={userInfo?.user}
              modal={modal ? true : false}
              onHide={onHide}
            />
          )} */}
        </Elements>
      )}
    </div>
  );
};

export default StripeElement;
