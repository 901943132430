import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import store from '../../../states';
import RequestDealSidebar from '../../sidebar/RequestDealSidebar';
//import { getRequestedDealListAPI } from '../../../services/deals';
import RequestedDealDetails from '../../helpers/requestedDealDetails';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';

interface Props {
  sSearchToggleProp: any;
}

const IncomingRequests = (props: Props) => {
  const { getReqestDelasListAPI }: any = useAuthentication();
  const { requestedDealList, sRequestedDealList }: any = consumerData();

  const history = useHistory();

  //const [empty, setEmpty] = useState(false);
  //const [requestedDealList, sRequestedDealList] = useState<any>([]);
  const [selectedReqDeal, sSelectedReqDeal] = useState('');
  const [reqDealDetails, sReqDealDetails] = useState<any>({});
  const [reqDealConfigDetails, sReqDealConfigDetails] = useState<any>({});

  const [noReqDeal, sNoReqDeal] = useState(false);
  //const [reqDealSkeleton, sReqDealSkeleton] = useState(true);
  const [cardLoadingReq, sCardLoadingReq] = useState(false);

  const [currentPageReq, sCurrentPageReq] = useState(0);
  const [hasMoreReq, sHasMoreReq] = useState(true);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const [paramsReq, setParamsReq] = useState({
    page: 0,
    query: '',
    sortBy: {
      id: 'created_on',
      desc: true,
    },
  });
  const [paramsStatus, setParamsStatus] = useState(false);

  useEffect(() => {
    if (requestedDealList.length === 0 || paramsReq.query === '') {
      getIncommingReqList(paramsReq);
    } else {
      sSelectedReqDeal(requestedDealList[0].id);
    }
    if (paramsStatus === true) {
      getIncommingReqList(paramsReq);
    }
  }, [paramsReq.page, paramsReq.query, paramsReq.sortBy]);

  //Get Assigned Requested Deal
  const getIncommingReqList = (paramsReq: any) => {
    const payload = {
      page: paramsReq.page,
      page_size: 500,
      query: paramsReq.query,
      sort_by: {
        sortBy: [paramsReq.sortBy],
      },
    };
    sCardLoadingReq(true);
    sHasMoreReq(true);
    getReqestDelasListAPI(payload)
      .then((data: any) => {
        if (data) {
          sCurrentPageReq(paramsReq.page);
          if (paramsReq.page > 0) {
            if (data.data.length !== 0) {
              sRequestedDealList([...requestedDealList, ...data.data]);
            } else {
              selectReqDeal(paramsReq.page === 0 ? requestedDealList[0]?.id : selectedReqDeal);
              sHasMoreReq(false);
              sRequestedDealList(requestedDealList);
            }
          } else {
            sRequestedDealList(data.data);
            //setEmpty(data.data.length < 1);
            selectReqDeal(data.data[0]?.id);
            sNoReqDeal(data.data.length < 1);
          }
        }
        //sReqDealSkeleton(false);
        sCardLoadingReq(false);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const handleParamsChangeReq = (page: number, query: string, sortBy: any, status: boolean) => {
    if (page !== paramsReq.page || query !== paramsReq.query) {
      setParamsReq({ page, query, sortBy });
      setParamsStatus(status);
    } else {
      setParamsReq({ page, query, sortBy });
      setParamsStatus(status);
    }
    //setParamsStatus(status);
    // if (paramsReq.query !== query || paramsReq.sortBy !== sortBy) {
    //  setDealsList([]);
    // }
  };

  const selectReqDeal = (id: any) => {
    if (selectedReqDeal !== id) {
      history.push(`/publisher/deals/incoming-requests/${id}`);
      sSelectedReqDeal(id);
    }
  };

  useEffect(() => {
    sReqDealConfigDetails(
      requestedDealList?.find((x: any) => x.id === selectedReqDeal)?.config ?? {}
    );
    sReqDealDetails(requestedDealList?.find((x: any) => x.id === selectedReqDeal) ?? {});
  }, [selectedReqDeal, requestedDealList]);

  /* Sidebar Searchbar */
  const [searchToggle, sSearchToggle] = useState<any>(true);
  const getSearchToggle = (status: any) => {
    sSearchToggle(status);
  };
  props.sSearchToggleProp(searchToggle);

  return (
    <>
      <RequestDealSidebar
        title={glossary.ssp_deals_sidebar_title}
        kind={'requestDeal'}
        size={'collapsed'}
        card={'expanded'}
        items={requestedDealList}
        info={selectReqDeal}
        selected={selectedReqDeal}
        empty={noReqDeal}
        icons={false}
        customerType={'publisher'}
        currentPage={currentPageReq}
        loading={cardLoadingReq}
        hasMore={hasMoreReq}
        params={paramsReq}
        handleParams={handleParamsChangeReq}
        searchToggle={getSearchToggle}
        allData={true}
      />
      <div className='view-section'>
        <div className='row align-items-center'>
          <div className='col-4'>
            <h2
              className='me-2 title-truncate'
              style={{ maxWidth: '300px', fontWeight: 200, width: 'fit-content' }}
            >
              {reqDealDetails.name}
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-12' style={{ marginTop: '55px' }}>
            {noReqDeal ? (
              <h5 className='text-center'>No requested deal found</h5>
            ) : (
              <RequestedDealDetails
                dealTerms={{
                  id: reqDealDetails?.id ?? '',
                  name: reqDealDetails?.name ?? '',
                  minCpm: reqDealDetails?.min_cpm_formatted ?? '',
                  maxCpm: reqDealDetails?.max_cpm_formatted ?? '',
                  startDate: reqDealDetails?.start_date_formatted ?? '',
                  requestedDealType: reqDealConfigDetails.requested_deal_type,
                  impression: reqDealConfigDetails.pg_impression,
                  description: reqDealConfigDetails['content.description'] ?? '',
                  ctr: reqDealConfigDetails['user.geo.country'] ?? [],
                  stt: reqDealConfigDetails['user.geo.region'] ?? [],
                  cty: reqDealConfigDetails['user.geo.city'] ?? [],
                  cat: reqDealConfigDetails['content.cat'] ?? [],
                  dev: reqDealConfigDetails['device.type'] ?? [],
                  p_type: reqDealConfigDetails['platform.type'] ?? [],
                  artist: reqDealConfigDetails['content.artist'] ?? '',
                  series: reqDealConfigDetails['content.series'] ?? '',
                  title: reqDealConfigDetails['content.title'] ?? '',
                  genre: reqDealConfigDetails['content.genre'] ?? '',
                  isrc: reqDealConfigDetails['content.isrc'] ?? '',
                  producerName: reqDealConfigDetails['content.producer.name'] ?? '',
                  producerDomain: reqDealConfigDetails['content.producer.domain'] ?? '',
                  contentrating: reqDealConfigDetails['content.contentrating'] ?? '',
                  userrating: reqDealConfigDetails['content.userrating'] ?? '',
                  qagmediarating: reqDealConfigDetails['content.qagmediarating'] ?? '',
                  keywords: reqDealConfigDetails['content.keywords'] ?? '',
                  len: reqDealConfigDetails['content.len'] ?? '',
                  language: reqDealConfigDetails['content.language'] ?? '',
                  networkDomain: reqDealConfigDetails['content.network.domain'] ?? '',
                  networkName: reqDealConfigDetails['content.network.name'] ?? '',
                  channelName: reqDealConfigDetails['content.channel.name'] ?? '',
                  channelDomain: reqDealConfigDetails['content.channel.domain'] ?? '',
                  context: reqDealConfigDetails['content.context'] ?? '',
                  prodq: reqDealConfigDetails['content.prodq'] ?? [],
                  livestream: reqDealConfigDetails['content.livestream'] ?? [],
                  sourceRelationship: reqDealConfigDetails['content.sourcerelationship'] ?? [],
                  embeddable: reqDealConfigDetails['content.embeddable'] ?? [],
                  kwarray: reqDealConfigDetails['content.kwarray'] ?? [],
                  applicationType: reqDealConfigDetails['application.type'] ?? [],
                }}
                newid={selectedReqDeal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomingRequests;
