import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { deleteRequestedDealAPI } from '../../services/deals';
import store from '../../states';

import Button from '../widgets/button';

export default ({ id, onHide, show, removeObj }: any) => {
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const delDeal = (removeObj: any) => {
    const payload = {
      requested_deal_id: id,
    };
    setBtnDisabled(true);
    deleteRequestedDealAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          onHide();
          removeObj(id);
          setBtnDisabled(false);
          toast.success(data.message);
        } else {
          toast.error(data.message);
          setBtnDisabled(false);
        }
      })
      .catch(() => {
        //
      });
  };

  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Header>
        <h2 style={{ margin: 10 }}>Delete Requested Deal</h2>
      </Modal.Header>
      <Modal.Body style={{ padding: '40px' }}>
        Are you sure you want to delete this requested deal?
      </Modal.Body>
      <Modal.Footer>
        <Button kind={'secondary'} text={'Cancel'} click={() => onHide()} />
        <Button
          kind={btnDisabled ? 'disabled' : 'primary'}
          text={'Delete'}
          click={() => delDeal(removeObj)}
        />
      </Modal.Footer>
    </Modal>
  );
};
