import { useContext, useEffect, useState } from 'react';
import { FaStripe } from 'react-icons/fa';
import { GrPowerReset } from 'react-icons/gr';
import StripeElement from '../../components/stripe/StripeElement';
import { UserInfoContext } from '../../contexts/userInfoContext';
import {
  getCreativeStripeLinkAPI,
  getCustomerStripeLinkAPI,
  getPublisherStripeAccountAPI,
} from '../../../services/stripe';
import { GoLinkExternal } from 'react-icons/go';
import { MdInfoOutline } from 'react-icons/md';
import store from '../../../states';
import StripeCard from '../../components/stripe/StripeCard';
import ButtonSkeleton from '../../loaders/button';
import TextSkeleton from '../../loaders/text';
import { toast } from 'react-toastify';
import { getCustomerInfoAPI } from '../../../services/customers';
import { Spinner } from 'react-bootstrap';
import { openNewTab } from '../../helpers/functions';
import Button from '../../widgets/button';
import { BsCheckCircle } from 'react-icons/bs';
import PlatformCost from './platform-cost';

const StripConnect = () => {
  //Context API
  const { customerInfo, setCustomerInfo }: any = useContext(UserInfoContext);

  const [paymentMethod, setPaymentMethod] = useState(false);
  const [paymentMethodUpdateURL, setPaymentMethodUpdateURL] = useState('');
  const [viewDashBtn, setViewDashBtn] = useState(false);
  const [isAccountDetails, setIsAccountDetails] = useState(false);
  const [isPayoutEnabled, setIsPayoutEnabled] = useState(false);
  const [defaultPaymentMethodError, setDefaultPaymentMethodError] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [glossary, sGlossary] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [disableStripeBtn, setDisableStripeBtn] = useState(false);

  const publisherType =
    localStorage.getItem('customer_type') === 'publisher' ||
    localStorage.getItem('customer_type') === 'supply';

  const demand_spo =
    localStorage.getItem('customer_type') === 'demand' ||
    localStorage.getItem('customer_type') === 'spo';

  const creativeType =
    localStorage.getItem('customer_type') === 'creative' && customerInfo?.stripe_data?.subscribe;

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (customerInfo?.stripe_data?.payment_method_data?.id) {
      setPaymentMethod(true);
    } else {
      setPaymentMethod(false);
    }

    if (customerInfo?.stripe_data?.account_data?.id) {
      setViewDashBtn(true);
    } else {
      setViewDashBtn(false);
    }

    // Verify Stripe Account Details is Submitted or Not
    if (customerInfo?.stripe_data?.account_data?.details_submitted) {
      setIsAccountDetails(true);
    } else {
      setIsAccountDetails(false);
    }

    // Verify Stripe Account Payouts Enabled is Verified or Not
    if (customerInfo?.stripe_data?.account_data?.payouts_enabled) {
      setIsPayoutEnabled(true);
    } else {
      setIsPayoutEnabled(false);
    }

    //Verify Default Payment method set or not
    if (
      customerInfo?.stripe_data?.payment_method_data?.invoice_settings?.default_payment_method !==
      null
    ) {
      setDefaultPaymentMethodError(false);
    } else {
      setDefaultPaymentMethodError(true);
    }

    if (customerInfo?.stripe_data?.payment_method_data?.id) {
      if (localStorage?.getItem('customer_type') !== 'creative') {
        getCustomerStripeLinkAPI()
          .then((data: any) => {
            if (data?.data?.url) {
              setPaymentMethodUpdateURL(data?.data?.url);
            }
          })
          .catch((error: any) => {
            //
          });
      }
    }

    if (customerInfo.id) setIsLoading(false);
  }, [customerInfo]);

  const createStripeAccount = () => {
    setDisableStripeBtn(true);
    getPublisherStripeAccountAPI()
      .then((data: any) => {
        if (data?.data?.url) {
          openNewTab(data?.data?.url);
          setIsReload(true);
        } else {
          toast.error(data?.message);
        }
        setDisableStripeBtn(false);
      })
      .catch((error: any) => {
        //
      });
  };

  const getCustomerInfo = () => {
    getCustomerInfoAPI()
      .then((data: any) => {
        if (data.status === true) {
          setCustomerInfo(data.data);
        }
      })
      .catch((error) => {
        // error
      });
  };

  // Creative Customer
  const [cancelPlanLoading, setCancelPlanLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const CreativeSubscription = () => {
    const payload = `?redirect_url=${window?.location?.href}`;
    getCreativeStripeLinkAPI(payload)
      .then((data: any) => {
        if (data?.url) {
          openNewTab(data?.url);
        } else {
          toast.error(data?.message);
        }
        setDetailsLoading(false);
        setCancelPlanLoading(false);
      })
      .catch((error: any) => {
        setDetailsLoading(false);
        setCancelPlanLoading(false);
      });
  };

  return (
    <>
      {/* Skeleton View */}
      {isLoading && (
        <div className='row'>
          <div
            className='col-6 d-flex flex-column gap-4'
            style={{ borderRight: publisherType ? '1px solid #cbcbcb' : 'none' }}
          >
            <TextSkeleton height={20} width={200} lineCount={1} />
            <TextSkeleton height={10} width={400} lineCount={4} />
            {!publisherType && <ButtonSkeleton height={40} width={300} />}
            <ButtonSkeleton height={40} width={200} />
          </div>
          {publisherType && (
            <div className='col-6 d-flex flex-column gap-4'>
              <TextSkeleton height={20} width={200} lineCount={1} />
              <TextSkeleton height={10} width={400} lineCount={4} />
              <ButtonSkeleton height={40} width={300} />
              <ButtonSkeleton height={40} width={100} />
            </div>
          )}
        </div>
      )}

      {publisherType && !isLoading && (
        <div className='row'>
          {/* Publisher Connect Stripe Section */}
          <div
            className='col-6'
            style={{ borderRight: '1px solid #cbcbcb', height: `calc(100vh - 250px)` }}
          >
            <h4 className='mb-2'>Connect Your Account</h4>

            {isAccountDetails && isPayoutEnabled ? (
              <div className='d-flex mb-3'>
                <MdInfoOutline
                  fontSize={16}
                  className='s-svg-icon me-1 flex-shrink-0'
                  style={{ marginTop: '3px' }}
                />
                <p>{glossary.stripe_business_details_instruction}</p>
              </div>
            ) : (
              <div className='d-flex mb-3'>
                <MdInfoOutline
                  fontSize={16}
                  className='s-svg-icon me-1 flex-shrink-0'
                  style={{ marginTop: '3px' }}
                />
                <p>{glossary.stripe_account_connect_instruction}</p>
              </div>
            )}

            <div className='d-flex gap-4'>
              {isAccountDetails && isPayoutEnabled ? (
                <>
                  <button className='button stripe-btn' type='button' onClick={createStripeAccount}>
                    {glossary.stripe_button_update_business_details}
                  </button>
                </>
              ) : (
                <div>
                  {disableStripeBtn ? (
                    <button className='button stripe-btn' type='button'>
                      <Spinner
                        className='me-2'
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                      {glossary.loading}
                    </button>
                  ) : (
                    <button
                      className='button stripe-btn'
                      disabled={disableStripeBtn ? true : false}
                      onClick={createStripeAccount}
                    >
                      Connect with <FaStripe fontSize={40} className='pb-1' />
                    </button>
                  )}

                  {isReload && (
                    <p
                      className='text-danger mt-2'
                      onClick={() => {
                        setIsReload(false);
                        getCustomerInfo();
                      }}
                    >
                      <GrPowerReset
                        className='svg-icon me-2'
                        fontSize={18}
                        style={{ fill: '#f1494a', color: '#f1494a' }}
                      />
                      {glossary.stripe_page_reload_error}
                    </p>
                  )}
                </div>
              )}

              {viewDashBtn && (
                <a
                  href='https://dashboard.stripe.com/'
                  target='_blank'
                  className='text-primary d-flex align-items-center'
                  rel='noreferrer'
                  title=''
                >
                  {glossary.stripe_button_view_dashboard}{' '}
                  <GoLinkExternal
                    className='svg-icon ms-1 mb-1'
                    fontSize={16}
                    title='Redirect URL'
                  />
                </a>
              )}
            </div>

            {/* Error Messages */}
            {!isReload && !isAccountDetails && viewDashBtn && (
              <p className='text-danger mt-2'>{glossary.stripe_fill_business_details_error}</p>
            )}

            {!isReload && isAccountDetails && !isPayoutEnabled && (
              <p className='text-danger mt-2'>{glossary.stripe_fill_payout_details_method_error}</p>
            )}
          </div>

          {/* Publisher Payment Method Section */}
          <div className='col-6 ps-3'>
            {/* <h4>{glossary.stripe_title}</h4> */}
            <h4 className='mb-2'>{glossary.stripe_payment_method_title}</h4>

            {!paymentMethod ? (
              <div className='row'>
                <div className='stripe-form'>
                  <StripeElement />
                </div>
              </div>
            ) : (
              <div className='row'>
                <div className='d-flex mb-3'>
                  <MdInfoOutline
                    fontSize={18}
                    className='s-svg-icon me-1'
                    style={{ marginTop: '3px' }}
                  />
                  <p>{glossary.stripe_payment_method_instruction}</p>
                </div>

                {!defaultPaymentMethodError && (
                  <div className='mb-3'>
                    <StripeCard paymentMethodAddUrl={paymentMethodUpdateURL} />
                  </div>
                )}

                <>
                  <a href={paymentMethodUpdateURL} target='_blank' rel='noreferrer'>
                    <button className='button stripe-btn' type='button'>
                      {glossary.stripe_button_view_payment_method}
                    </button>
                  </a>
                  {defaultPaymentMethodError && (
                    <p className='text-danger mt-2'>
                      {glossary.stripe_default_payment_method_error}
                    </p>
                  )}
                </>
              </div>
            )}
          </div>
        </div>
      )}

      {/* For Demand & SPO */}
      {demand_spo && !isLoading && (
        <div className='row my-2'>
          <div className='col-12 mb-2'>
            <h4>{glossary.stripe_payment_method_title}</h4>
          </div>

          {!paymentMethod ? (
            <div className='col-6'>
              <div className='stripe-form'>
                <StripeElement />
              </div>
            </div>
          ) : (
            <div className='col-12'>
              <div className='row'>
                <div className='d-flex mb-3'>
                  <MdInfoOutline
                    fontSize={18}
                    className='s-svg-icon me-1'
                    style={{ marginTop: '3px' }}
                  />
                  <p>{glossary.stripe_payment_method_instruction}</p>
                </div>

                {!defaultPaymentMethodError && (
                  <div className='mb-3 col-6'>
                    <StripeCard paymentMethodAddUrl={paymentMethodUpdateURL} />
                  </div>
                )}

                <a href={paymentMethodUpdateURL} target='_blank' rel='noreferrer'>
                  <button className='button stripe-btn' type='button'>
                    {glossary.stripe_button_view_payment_method}
                  </button>
                </a>

                {defaultPaymentMethodError && (
                  <p className='text-danger'>{glossary.stripe_default_payment_method_error}</p>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {/* For Creative Customer */}
      {creativeType && !isLoading && (
        <>
          <div className='row'>
            <div className='col-12'>
              {localStorage.getItem('customer_type') === 'creative' && <PlatformCost />}
            </div>
          </div>

          <div className='row'>
            <div className='col-12 d-flex gap-2'>
              <Button
                text={detailsLoading ? 'Loading...' : 'View Details'}
                kind={detailsLoading ? 'spinner' : 'primary'}
                type={'button'}
                click={() => {
                  setDetailsLoading(true);
                  CreativeSubscription();
                }}
              />
              <Button
                text={cancelPlanLoading ? 'Loading...' : 'Cancel Subscription'}
                kind={cancelPlanLoading ? 'spinner' : 'secondary'}
                type={'button'}
                click={() => {
                  setCancelPlanLoading(true);
                  CreativeSubscription();
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StripConnect;
