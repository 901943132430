import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import store from '../../states';
import CreateVideoExtension from '../components/extension/Create';
import { genuinTab } from '../helpers/functions';
import SSAIEndpoint from '../components/ssai/SSAIEndpoint';
import VastTag from '../components/VAST/VAST-create';
import AddReel from '../components/add-reel/add-reel';

export default (props: any) => {
  const {
    show,
    onHide,
    getTags,
    pSection,
    scriptProp,
    adsTxt,
    tagId,
    tagType,
    createTag,
    selectedTagDetails,
  } = props;

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <Modal
        className={createTag ? 'tabs-modal vasttag' : 'tabs-modal vasttag hide-tabs-header'}
        onHide={() => {
          onHide();
        }}
        show={show}
        centered
        size={'sm'}
      >
        {/* {console.log("tagType", tagType)} */}
        {/* <Tabs  defaultActiveKey={tagType === 'ssai' ? 'ssai' : 'vast-tag'}> */}
        <Tabs
          defaultActiveKey={
            tagType === 'normal' ? 'vast-tag' : tagType === 'ssai' ? 'ssai' : 'reel'
          }
        >
          <Tab eventKey='reel' title='AdReel'>
            <AddReel
              pSection={pSection}
              getTags={getTags}
              onHide={onHide}
              tagId={tagId}
              createTag={createTag}
              selectedTagDetails={selectedTagDetails}
            />
          </Tab>
          <Tab eventKey='vast-tag' title={glossary.ssp_supply_add_popup_tab_tag}>
            <VastTag
              scriptProp={scriptProp}
              pSection={pSection}
              getTags={getTags}
              onHide={onHide}
              adsTxt={adsTxt}
              tagId={tagId}
              createTag={createTag}
              selectedTagDetails={selectedTagDetails}
            />
          </Tab>
          <Tab eventKey='ssai' title={glossary.ssp_supply_add_popup_tab_ssai}>
            <SSAIEndpoint
              pSection={pSection}
              getTags={getTags}
              onHide={onHide}
              adsTxt={adsTxt}
              tagId={tagId}
              createTag={createTag}
              selectedTagDetails={selectedTagDetails}
            />
          </Tab>
          {/* <Tab
            tabClassName='genuin-tab'
            eventKey='videoextensions'
            title={genuinTab(glossary.ssp_supply_add_popup_tab_adreels)}
          >
            <CreateVideoExtension
              pSection={'1'}
              getTags={getTags}
              onHide={onHide}
              adsTxt={adsTxt}
              tagId={tagId}
              createTag={createTag}
              selectedTagDetails={selectedTagDetails}
            />
          </Tab> */}
        </Tabs>
      </Modal>
    </>
  );
};
