import { useEffect, useState } from 'react';
import store from '../../../states';

interface Props {
  customerDetails?: any;
}

export default (props: Props) => {
  const { customerDetails } = props;

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className={'main__container__box'} style={{ marginTop: 60 }}>
        <div className={'row'}>
          <div className={'col'}>
            <h3>{glossary?.admin_cus_detail_tab_title}</h3>
          </div>
        </div>
        {customerDetails?.status !== 'pending' ? (
          <>
            <div className={'row'}>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_cus_detail_tab_field_label_customer_id}</label>
                <p>{customerDetails?.id ?? '-'}</p>
              </div>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_cus_detail_tab_field_label_admin}</label>
                <p className='text-truncate'>{customerDetails?.email ?? '-'}</p>
              </div>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_cus_detail_tab_field_label_since}</label>
                <p>{customerDetails?.created_on_formatted ?? '-'}</p>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12 col-lg-4'}>
                <label>
                  {customerDetails.customer_type === 'demand'
                    ? glossary?.admin_cus_detail_tab_field_label_demand
                    : glossary?.admin_cus_detail_tab_field_label_publisher}
                </label>
                {/* <p>{customerDetails?.publisher_name ?? '-'}</p> */}
                <p>{customerDetails?.company_name ?? '-'}</p>
              </div>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_cus_detail_tab_field_label_country}</label>
                <p>{customerDetails?.country ?? '-'}</p>
              </div>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_cus_detail_tab_field_label_users}</label>
                <p>{customerDetails?.users ?? '-'}</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={'row'}>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_customer_ssp_req_field_label_company}</label>
                <p>{customerDetails?.company_name ?? '-'}</p>
              </div>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_customer_ssp_req_field_label_name}</label>
                <p>{customerDetails?.name ?? '-'}</p>
              </div>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_customer_ssp_req_field_label_email}</label>
                <p>{customerDetails?.email ?? '-'}</p>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12 col-lg-4'}>
                <label>
                  Name of&nbsp;{' '}
                  {customerDetails.customer_type === 'demand' ? 'Demand' : 'Publisher'}
                </label>
                <p>{customerDetails?.publisher_name ?? '-'}</p>
              </div>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_customer_ssp_req_field_label_avg_traffic}</label>
                <p>{customerDetails?.traffic_formatted ?? '-'}</p>
              </div>
              <div className={'col-12 col-lg-4'}>
                <label>{glossary?.admin_customer_ssp_req_field_label_web_url}</label>
                <p>
                  <a
                    href={`https://${customerDetails?.website_url}` ?? '#'}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {customerDetails?.website_url ?? '-'}
                  </a>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
