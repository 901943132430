import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import JWT from 'jsonwebtoken';
import { Formik, Form, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import * as Yup from 'yup';
import FormikController from '../../comman/form/FormikController';
import Button from '../../widgets/button';
import store from '../../../states';
import {
  customerInviteSPOAPI,
  customerInviteSSPAPI,
  inviteCheckEmailAPI,
} from '../../../services/spo';
import CSVTable from '../../comman/csvUpload/CsvTable';

const InviteForm = ({ id, onHide, show, getEndPointList, type }: any) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [domainName, sDomainName] = useState<any>([]);
  const [glossary, sGlossary] = useState<any>({});
  const [invitePartnerMsg, sInvitePartnerMsg] = useState<any>([]);

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const handleInvite = (e: any) => {
    const payload = {
      customers: e.customer,
    };
    //console.log('payload', payload);
    setLoadingBtn(true);
    if (type === 'spo-publisher') {
      customerInviteSSPAPI(payload)
        .then((data: any) => {
          sInvitePartnerMsg(data?.users);
          // console.log('data', data);
          // if (data.invitation_status === 0) {
          //   toast.error(data?.message);
          //   setLoadingBtn(false);
          // }
          // if (data.invitation_status === 1) {
          //   toast.error(data?.message);
          //   setLoadingBtn(false);
          // }
          // if (data.status === true && data.invitation_status === 2) {
          //   getEndPointList(data, 'add');
          //   setLoadingBtn(false);
          //   toast.success(data?.message);
          // }
          if (data.error === 400) {
            toast.error(data?.message?.failed?.[0]?.message);
          }
          setLoadingBtn(false);
        })
        .catch((error: any) => {
          toast.error(error);
        });
    } else {
      customerInviteSPOAPI(payload)
        .then((data: any) => {
          sInvitePartnerMsg(data?.users);
          // if (data.invitation_status === 0) {
          //   toast.error(data?.message);
          //   setLoadingBtn(false);
          // }
          // if (data.invitation_status === 1) {
          //   toast.error(data?.message);
          //   setLoadingBtn(false);
          // }
          // if (data.status === true && data.invitation_status === 2) {
          //   getEndPointList(data, 'add');
          //   setLoadingBtn(false);
          //   toast.success(data?.message);
          // }
          if (data.error === 400) {
            toast.error(data?.message?.failed?.[0]?.message);
          }
          setLoadingBtn(false);
        })
        .catch((error: any) => {
          toast.error(error);
        });
    }
  };

  const validationSchema = Yup.object().shape({
    customer: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(glossary.spo_invite_popup_input_company_name_empty_error),
        users: Yup.array().of(
          Yup.object().shape({
            first_name: Yup.string().required(
              glossary.spo_invite_popup_input_first_name_empty_error
            ),
            last_name: Yup.string().required(glossary.spo_invite_popup_input_last_name_empty_error),
            email: Yup.string()
              .email(glossary.spo_invite_popup_input_email_valid_error)
              .required(glossary.spo_invite_popup_input_email_empty_error),
          })
        ),
      })
    ),
  });

  const debouncedHandleCheckEmail = debounce(handleCheckEmail, 500);

  const [isValidating, setIsValidating] = useState(true);
  const [customerEmail, sCustomerEmail] = useState('');

  useEffect(() => {
    //getUsers();
    const token = localStorage.getItem('token') || '';
    const decoded: any = JWT.decode(token, { complete: true });
    sCustomerEmail(decoded?.payload?.identity);
  }, []);

  function handleCheckEmail(event: any, index: any) {
    const { value } = event.target;
    const payload = {
      email: value,
    };
    setIsValidating(true);
    inviteCheckEmailAPI(payload)
      .then((data: any) => {
        if (data.status === false) {
          if (data?.message !== 'email is not valid') {
            toast.error(data?.message);
          }
          setLoadingBtn(false);
        } else {
          const domain = data.data.domain;
          sDomainName((prevDomainName: any) => [...prevDomainName, { domain, index }]);
          setIsValidating(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setIsValidating(true);
      });
  }

  const [convertedArray, setConvertedArray] = useState([]);

  const handleConvertedArray = (array: any) => {
    setConvertedArray(array);
  };

  return (
    <>
      {!convertedArray.length && (
        <Formik
          initialValues={{
            customer: [
              {
                name: '',
                domain: '',
                users: [{ first_name: '', last_name: '', email: '' }],
              },
            ],
          }}
          validationSchema={validationSchema}
          onSubmit={(e) => {
            e.customer.forEach((customer: any, customerIndex: any) => {
              const domainObj = domainName.find((obj: any) => obj.index === customerIndex);
              customer.domain = domainObj?.domain || '';
            });
            handleInvite(e);
          }}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ values }) => (
            <>
              <Form>
                <Modal.Body
                  className={'p-3'}
                  style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto',
                  }}
                >
                  <FieldArray name='customer'>
                    {({ remove, push }) => (
                      <div className='col'>
                        {values.customer.map((customer, customerIndex) => (
                          <>
                            <div className='row mb-3' key={customerIndex}>
                              <div className='col-3'>
                                <FormikController
                                  type='text'
                                  control='input'
                                  kind='withouticon'
                                  name={`customer[${customerIndex}].name`}
                                  placeholder={
                                    glossary.spo_invite_popup_input_company_name_placeholder
                                  }
                                  label={glossary.spo_invite_popup_input_field_label_company_name}
                                />
                              </div>
                              <div className='col'>
                                <FieldArray name={`customer.${customerIndex}.users`}>
                                  {({ remove: removeUser, push: pushUser }) => (
                                    <>
                                      {customer.users.map((users, userIndex) => (
                                        <div className='row' key={userIndex}>
                                          <div className='col-4'>
                                            <FormikController
                                              type='text'
                                              control='input'
                                              kind='withouticon'
                                              name={`customer.${customerIndex}.users.${userIndex}.first_name`}
                                              placeholder={
                                                glossary.spo_invite_popup_input_first_name_placeholder
                                              }
                                              label={
                                                glossary.spo_invite_popup_input_field_label_first_name
                                              }
                                            />
                                          </div>
                                          <div className='col-4'>
                                            <FormikController
                                              type='text'
                                              control='input'
                                              kind='withouticon'
                                              name={`customer.${customerIndex}.users.${userIndex}.last_name`}
                                              placeholder={
                                                glossary.spo_invite_popup_input_last_name_placeholder
                                              }
                                              label={
                                                glossary.spo_invite_popup_input_field_label_last_name
                                              }
                                            />
                                          </div>
                                          <div className='col-4'>
                                            <FormikController
                                              type='text'
                                              control='input'
                                              kind='withouticon'
                                              name={`customer.${customerIndex}.users.${userIndex}.email`}
                                              placeholder={
                                                glossary.spo_invite_popup_input_email_placeholder
                                              }
                                              label={
                                                glossary.spo_invite_popup_input_field_label_email
                                              }
                                              onInput={(e: any) =>
                                                debouncedHandleCheckEmail(e, customerIndex)
                                              }
                                            />
                                            {values?.customer[0]?.users[0]?.email ===
                                              customerEmail &&
                                              values?.customer[0]?.users[0]?.email.length > 0 && (
                                                <small className='text-danger'>
                                                  {
                                                    glossary.spo_invite_popup_input_email_same_email_login_error
                                                  }
                                                </small>
                                              )}
                                          </div>

                                          {/* {userIndex > 0 && (
                                        <button type='button' onClick={() => removeUser(userIndex)}>
                                          Remove User
                                        </button>
                                      )} */}
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                              <div className='col-auto'>
                                <div className='d-flex gap-3'>
                                  {customerIndex > 0 && (
                                    <div className='d-flex flex-column'>
                                      <label className='label'>&nbsp;</label>
                                      <Button
                                        type='button'
                                        customClass={'button'}
                                        text='x'
                                        click={() => remove(customerIndex)}
                                      />
                                    </div>
                                  )}
                                  <div className='d-flex flex-column'>
                                    <label className='label'>&nbsp;</label>
                                    <Button
                                      type='button'
                                      kind={
                                        values.customer.length < 10
                                          ? 'primary'
                                          : 'disabled-secondary'
                                      }
                                      text='+'
                                      click={() =>
                                        push({
                                          name: '',
                                          domain: '',
                                          users: [{ email: '', first_name: '', last_name: '' }],
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='col-12'>
                                {invitePartnerMsg &&
                                  invitePartnerMsg.map((item: any, msgIndex: any) =>
                                    customerIndex === msgIndex ? (
                                      <div key={msgIndex}>
                                        <p
                                          className={
                                            item.invite_status ? 'text-success' : 'text-danger'
                                          }
                                        >
                                          {item.invite_status
                                            ? `${item.email} Invitation Sent`
                                            : `${item.message}`}
                                        </p>
                                      </div>
                                    ) : null
                                  )}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <p className='text-danger'>
                    {values.customer.length < 10 ? '' : 'You can invite upto 10 partners.'}
                  </p>
                </Modal.Body>
                <Modal.Footer className='px-3 gap-2'>
                  <Button
                    type='button'
                    kind={'secondary'}
                    customClass={'float-end'}
                    text={glossary.spo_invite_popup_button_cancel}
                    click={() => {
                      onHide();
                    }}
                  />
                  {!loadingBtn ? (
                    <Button
                      type='submit'
                      kind={
                        isValidating || values?.customer[0]?.users[0]?.email === customerEmail
                          ? 'disabled-secondary'
                          : 'primary'
                      }
                      //customClass={emailStatus ? 'float-end' : 'button-disabled'}
                      customClass={'float-end'}
                      text={glossary.spo_invite_popup_button_invite}
                    />
                  ) : (
                    <Button type='button' kind={'spinner'} text={'Loading...'} />
                  )}
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      )}
      <CSVTable
        onConvertedArray={handleConvertedArray}
        onHide={onHide}
        type={type}
        getEndPointList={getEndPointList}
      />
    </>
  );
};

export default InviteForm;
