import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';

import Button from '../widgets/button';
import store from '../../states';
import Dropdown from '../widgets/dropdown';
import FormikController from '../comman/form/FormikController';
import { countries, states, cities } from '../helpers/dropdowns';

import { signupAPI } from '../../services/authentication';
import { getCountriesAPI, getStatesAPI, getCitiesAPI } from '../../services/inventory';

export default () => {
  const { push, location } = useHistory<{ email: string }>();

  const [countriesList, sCountriesList] = useState<any>([]);
  const [selectedCountries, sSelectedCountries] = useState<any>([]);
  const [statesList, sStatesList] = useState<any>([]);
  const [selectedStates, sSelectedStates] = useState<any>([]);
  const [citiesList, sCitiesList] = useState<any>([]);
  const [selectedCities, sSelectedCities] = useState<any>([]);
  const [trafficValue, setTrafficValue] = useState(100000);

  const trafficDropdown = (value: any) => {
    setTrafficValue(value.value);
  };

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  /* define initialValues */
  const initialValues = {
    companyName: '',
    email: location.state.email ?? '',
    name: '',
    demandName: '',
    websiteUrl: '',
  };

  /* validation schema */
  const websiteUrlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(glossary.signup_details_field_one_empty_error),
    name: Yup.string().required(glossary.signup_details_field_two_empty_error),
    demandName: Yup.string().required(glossary.signup_details_field_seven_empty_error),
    websiteUrl: Yup.string()
      .required(glossary.signup_details_field_five_empty_error)
      .matches(websiteUrlRegex, glossary.signup_details_field_five_valid_error),
  });

  const handSubmitForm = (e: any) => {
    const payload = {
      email: e.email,
      name: e.name,
      publisher_name: e.demandName,
      company_name: e.companyName,
      website_url: e.websiteUrl,
      traffic: trafficValue,
      //customer_id: '',
      customer_type: 'demand',
      country: selectedCountries?.label ?? '',
      state: selectedStates?.label ?? '',
      city: selectedCities?.label ?? '',
    };
    signupAPI(payload)
      .then((data: any) => {
        if (data.status) {
          push('/signup-done');
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error(glossary.service_unavailable);
      });
  };

  useEffect(() => {
    getCountriesAPI()
      .then((data: any) => {
        sCountriesList(data.data);
      })
      .catch(() => {
        // ok
      });
  }, []);

  useEffect(() => {
    if (selectedCountries) {
      const payload = {
        countries: [selectedCountries?.id],
      };
      getStatesAPI(payload)
        .then((data: any) => {
          sStatesList(data.data);
        })
        .catch(() => {
          // ok
        });
    }
  }, [selectedCountries]);

  useEffect(() => {
    if (selectedStates) {
      const payload = {
        states: [selectedStates?.id],
      };
      getCitiesAPI(payload)
        .then((data: any) => {
          sCitiesList(data.data);
        })
        .catch(() => {
          // ok
        });
    }
  }, [selectedStates]);

  useEffect(() => {
    if (selectedCountries?.id && selectedStates?.id) {
      if (selectedStates?.country !== selectedCountries?.value) {
        sSelectedStates([]);
        sSelectedCities([]);
        sStatesList([]);
        sCitiesList([]);
      }
    }
    if (selectedStates?.id && selectedCities?.id) {
      if (selectedStates?.value !== selectedCities?.state) {
        sSelectedCities([]);
        sCitiesList([]);
      }
    }
  }, [selectedCountries, selectedStates, selectedCities]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100vw',
          height: 'calc(100vh - 66px)',
        }}
      >
        <div
          className={'card-box'}
          style={{
            margin: 'auto',
            rowGap: '24px',
            width: '700px',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
          }}
        >
          <h3 style={{ fontWeight: 300 }}>{glossary.signup_details_title}</h3>
          <p>{glossary.signup_details_description}</p>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(e) => {
              handSubmitForm(e);
              ReactGA.event({
                category: 'signup',
                action: 'click',
                label: 'startnew_form_next_button',
              });
            }}
          >
            {() => (
              <Form>
                <div className='row mb-3'>
                  <div className='col-12 col-md-6'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.signup_details_field_one_title}
                        placeholder={glossary.signup_details_field_one_placeholder}
                        name='companyName'
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.signup_details_field_three_title}
                        placeholder={glossary.signup_details_field_three_placeholder}
                        name='email'
                        disable={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-12 col-md-6'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.signup_details_field_two_title}
                        placeholder={glossary.signup_details_field_two_placeholder}
                        name='name'
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.signup_details_field_seven_title}
                        placeholder={glossary.signup_details_field_seven_placeholder}
                        name='demandName'
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-12 col-md-6'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.signup_details_field_five_title}
                        placeholder={glossary.signup_details_field_five_placeholder}
                        name='websiteUrl'
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <Dropdown
                      label={glossary.signup_details_field_six_title}
                      name={'traffic'}
                      option={[
                        { label: '100K', value: 100000 },
                        { label: '200K', value: 200000 },
                        { label: '500K', value: 500000 },
                        { label: '1M', value: 1000000 },
                        { label: '2M+', value: 2000000 },
                      ]}
                      onchange={trafficDropdown}
                      value={{ label: '100K', value: 100000 }}
                      search={false}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-12 col-md-6'>
                    <Dropdown
                      label={glossary.ddl_country}
                      name={'countries'}
                      option={countries(countriesList)}
                      onchange={sSelectedCountries}
                      defaultValue={selectedCountries}
                      search={true}
                      placeHolder={glossary.ddl_country_placeholder}
                    />
                    {/* <CustomDropdown
                      label={glossary.ddl_country}
                      option={countries(countriesList)}
                      onSelect={sSelectedCountries}
                      value={selectedCountries}
                      placeholder={glossary.ddl_country_placeholder}
                    /> */}
                  </div>
                  <div className='col-12 col-md-6'>
                    <Dropdown
                      label={glossary.ddl_state}
                      name={'states'}
                      option={states(statesList)}
                      onchange={sSelectedStates}
                      defaultValue={selectedStates}
                      search={true}
                      placeHolder={glossary.ddl_state_placeholder}
                    />
                    {/* <CustomDropdown
                      label={glossary.ddl_state}
                      option={states(statesList)}
                      onSelect={sSelectedStates}
                      value={selectedStates}
                      placeholder={glossary.ddl_state_placeholder}
                    /> */}
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-12 col-md-6'>
                    <Dropdown
                      label={glossary.ddl_city}
                      name={'cities'}
                      option={cities(citiesList)}
                      onchange={sSelectedCities}
                      defaultValue={selectedCities}
                      search={true}
                      placeHolder={glossary.ddl_city_placeholder}
                    />
                    {/* <CustomDropdown
                      label={glossary.ddl_city}
                      option={cities(citiesList)}
                      onSelect={sSelectedCities}
                      value={selectedCities}
                      placeholder={glossary.ddl_city_placeholder}
                    /> */}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 align-items-center col-12 d-flex gap-4 justify-content-end'>
                    <Link to='/login'>
                      <span
                        onClick={() => {
                          ReactGA.event({
                            category: 'signup',
                            action: 'click',
                            label: 'startnew_form_cancel_button',
                          });
                          setTrafficValue(100000);
                        }}
                      >
                        {glossary.signup_details_cancel_button}
                      </span>
                    </Link>
                    <Button
                      type='submit'
                      kind={'primary'}
                      text={glossary.signup_details_next_button}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div
            className={'row'}
            style={{
              right: '0',
              width: '100%',
              bottom: '-50px',
              position: 'absolute',
              justifyContent: 'flex-end',
            }}
          >
            <a
              style={{
                fontWeight: 200,
                margin: '6px 12px',
                width: 'fit-content',
              }}
              href='https://infy.tv/privacy-policy'
              target='_blank'
              rel='noreferrer'
            >
              {glossary.signup_details_hyperlink_privacy_policy}
            </a>
            <a
              style={{
                fontWeight: 200,
                margin: '6px 12px',
                width: 'fit-content',
              }}
              href='https://infy.tv/terms-conditions'
              target='_blank'
              rel='noreferrer'
            >
              {glossary.signup_details_hyperlink_terms}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
