import { useState } from 'react';
import { BrowserRouter, NavLink, Route, Switch} from 'react-router-dom';
import MyDealsPage from './MyDeals';
import IncomingRequests from './IncomingRequests';

const PgPmp = () => {

  /* Sidebar Searchbar */
  const [searchToggle, sSearchToggle] = useState<any>(true);

  const getSearchToggle = (status: any) => {
    sSearchToggle(status);
  };

  return (
    <>
      <div className={'publisher-spo content d-flex'}>
        {/* Sidebar Tabs */}
        <BrowserRouter>
          <div className={searchToggle ? 'ssp-deals-sidebar-tabs' : 'ssp-deals-sidebar-tabs  d-none'}>
            <NavLink to='/publisher/deals/my-deals' activeClassName='active'>
                My Deals
            </NavLink>
            <NavLink to='/publisher/deals/incoming-requests' activeClassName='active'>
                Incoming Requests
            </NavLink>
          </div>
          <Switch>
            <Route path='/publisher/deals/my-deals'>
              <MyDealsPage sSearchToggleProp={getSearchToggle} />
            </Route>
            <Route path='/publisher/deals/incoming-requests'>
              <IncomingRequests sSearchToggleProp={getSearchToggle} />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </>
  );
};

export default PgPmp;
