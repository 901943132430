import ReactGA from 'react-ga4';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import AddTag from '../../modals/tag-add';
import Bar from '../../charts/bar';
import Line from '../../charts/line';
import Tile from '../../widgets/tile';

import { BiEditAlt } from 'react-icons/bi';
import { MdInfoOutline } from 'react-icons/md';
import add from '../../../assets/icons/add.svg';

import Reports from '../../helpers/reports';
import { overview as overviewTiles, tag as tagTiles } from '../../helpers/tiles';

import store from '../../../states';
import GenuinReporting from './GenuinReporting';
import { extensionDetailsAPI } from '../../../services/extension';
import {
  //overviewAPI,
  getTagsAPI,
  getTagDetailsAPI,
  getTagSettingAPI,
} from '../../../services/tags';

import Box from '../../loaders/box';
import XYChart from '../../loaders/chart';
import TextSkeleton from '../../loaders/text';
import BarGraph from '../../loaders/bar-graph';
import TableSkeleton from '../../loaders/table';
import LineGraph from '../../loaders/line-graph';
import ButtonSkeleton from '../../loaders/button';

import MsaSign from '../../modals/msa-sign';
import EditTag from '../../modals/tag-edit';
import ExtensionModalDetails from '../../modals/extension-details';

import PublisherOnbording from './Onbording';
import ReactTable from '../../tables/reactTable';
import TagSetting from '../../components/tagSetting/tag-setting';
import PublisherSidebar from '../../sidebar/Publisher';
import { UserInfoContext } from '../../contexts/userInfoContext';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';
import InventoryOverview from '../../components/overview/inventory';

import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';

export const headers: any = {
  'Content-Type': 'application/json',
};

const css = {
  minCol: {
    minWidth: 'fit-content',
  },
  maxCol: {
    maxWidth: 'fit-content',
  },
};

const PublisherSupply = () => {
  const history = useHistory();
  const location = useLocation();

  /* Context API's */
  const { msaDetails, customerInfo }: any = useContext(UserInfoContext);

  const { getTagsListAPI }: any = useAuthentication();
  const { tags, sTags, dateRangeInventory, sDateRangeInventory }: any = consumerData();

  const [selectedTile, sSelectedTile] = useState<any>(null);
  const [sidebar, sSidebar] = useState<'collapsed' | 'expanded'>('collapsed');
  const [selectedTag, sSelectedTag] = useState('');
  const [create, sCreate] = useState(false);
  const [section, sSection] = useState('1');
  const [overviewTilesData, sOverviewTilesData] = useState<any>([]);
  const [singleTileData, sSingleTile] = useState<any>([]);
  //const [tags, sTags] = useState<any>([]);
  const [lineData, sLineData] = useState<any>(null);
  const [barData, sBarData] = useState<any>(null);
  const [attribute /* , sAttribute */] = useState<any>(null);
  const [rawBarData /* , sRawBarData */] = useState<any>([]);
  const [refinedBarData, sRefinedBarData] = useState<any>([]);
  const [rawLineData /* , sRawLineData */] = useState<any>([]);
  const [refinedLineData, sRefinedLineData] = useState<any>([]);
  const [glossary, sGlossary] = useState<any>({});
  const [empty, sEmpty] = useState(false);
  const [datatables, sDatatables] = useState<any>([]);
  // const [skeleton, sSkeleton] = useState(true);
  // const [overviewData, setOverviewData] = useState<any>({});
  const [createClick, sCreateClick] = useState(false);
  const [tagSettingData, setTagSettingData] = useState<any>();
  const [showMsaSign, sShowMsaSign] = useState(false);
  const [editModal, sEditModal] = useState(false);
  const [selectedTagDetails, setSelectedTagDetails] = useState<any>(null);
  const [genuinLoading, setGenuinLoading] = useState(true);
  const [genuinSingleTile, setGenuinSingleTile] = useState();
  const [genuinRandom, setGenuinRandom] = useState(Math.random());
  const [allData, setAllData] = useState(true);

  //const [selectionRange, setSelectionRange] = useState<any>();
  const [currentDateSelection, sCurrentDateSelection] = useState<any>();

  // useEffect(()=>{
  //   setSelectionRange(dateRangeInventory);
  //   sCurrentDateSelection(dateRangeInventory);
  // },[dateRangeInventory])

  const GetSelectionRangeProps = (date: any) => {
    //setSelectionRange(date);
    sDateRangeInventory(date);
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  const setSidebar = (size: 'collapsed' | 'expanded') => {
    sSidebar(size);
  };

  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  const [random, sRandom] = useState(Math.random());

  useEffect(() => {
    if (selectedTag !== '') {
      sLineData(singleTileData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
    } else {
      sBarData(overviewTilesData?.find((x: any) => x.id === selectedTile)?.barData ?? []);
      sLineData(overviewTilesData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
    }
  }, [selectedTile, random]);

  useEffect(() => {
    // if (location) {
    //   const tagid = location.pathname.split('/publisher/supply/')[1];
    //   if (tags.length > 0 && tagid) {
    //     tags.find((x: any) => {
    //       if (x.id == tagid) {
    //         selectedCard(x.id);
    //         getSelectedTagData(x.id);
    //       }
    //     });
    //   }
    // }
    if (location.state === 'clickLogo') {
      //getOverviewData();
      sSelectedTag('');
    }
  }, [location.pathname, location.state, tags]);

  const getSelectedTagData = (id: string) => {
    const payload = {
      id,
      start_date: convert(dateRangeInventory?.startDate) || '',
      end_date: convert(dateRangeInventory?.endDate) || '',
      time_zone: 'Asia/Kolkata',
    };
    sCurrentDateSelection(dateRangeInventory);
    if (dateRangeInventory?.startDate !== dateRangeInventory?.endDate) {
      setAllData(true);
      getTagDetailsAPI(payload)
        .then((data: any) => {
          if (data) {
            const tileData = tagTiles(data.total);
            sSingleTile(tileData);
            sSelectedTile(0);
            sRandom(Math.random());
            sDatatables(tags.find((x: any) => x.id === id)?.tables ?? []);
            setAllData(false);
          }
        })
        .catch(() => {
          // toast.error('Service unavailable, Check back after sometime.');
        });
    }
  };

  const getGenuinOverview = (id: any) => {
    const payload = {
      id: id,
      param: 'overview',
      _payload: {
        start_date: convert(dateRangeInventory?.startDate) || '',
        end_date: convert(dateRangeInventory?.endDate) || '',
      },
    };
    sCurrentDateSelection(dateRangeInventory);
    if (dateRangeInventory?.startDate !== dateRangeInventory?.endDate) {
      setGenuinLoading(true);
      extensionDetailsAPI(payload)
        .then((data: any) => {
          if (data.status) {
            const tileData = tagTiles(data.total);
            setGenuinSingleTile(tileData);
            setGenuinLoading(false);
            setGenuinRandom(Math.random());
          }
        })
        .catch((error: any) => {
          // error
        });
    }
  };

  const selectedCard = (id: any, tagType: string) => {
    // if (id && id) sSelectedTag(id);
    if (selectedTag !== id && selectedTag !== undefined && id !== undefined) {
      sSelectedTag(id);
      history.push(`/publisher/supply/${id}`);
      sLineData([]);
      sSingleTile([]);
      sDatatables([]);
      sRefinedLineData([]);
      getSelectedTagData(id);
      if (tagType === 'extension') {
        getGenuinOverview(id);
      } else {
        getTagSettingDetails(id);
      }
    }
  };

  const createTag = () => {
    ReactGA.event('ssp_supply_sidebar_addnew_button', {
      category: 'publisher',
      action: 'click',
      label: 'ssp_supply_sidebar_addnew_button',
    });
    if (msaDetails.status) {
      sCreate(true);
      sCreateClick(true);
    }
    if (!msaDetails.generate_button) {
      sShowMsaSign(true);
    }
    if (!msaDetails.status && msaDetails.generate_button) {
      toast.success('MSA was sent to your email. Please check your mailbox.');
    }
  };

  const tagDetails = (tagId: any) => {
    sCreate(true);
    sSection('2');
  };

  const [selectedTabSSP, setSelectedTabSSP] = useState('performance');
  const [loaderTags, sLoaderTags] = useState(false);
  const [loaderSetting, sLoaderSetting] = useState(true);
  const [tagsLegnth, sTagsLegnth] = useState<any>(null);

  /***** Handle on scroll API's start *****/
  const [currentPage, sCurrentPage] = useState(0);
  const [hasMore, sHasMore] = useState(true);
  const [params, setParams] = useState({
    page: 0,
    query: '',
    sortBy: {
      id: 'earning',
      desc: true,
    },
  });
  const [paramsStatus, setParamsStatus] = useState(false);

  useEffect(() => {
    const tagid = location.pathname.split('/publisher/supply/')[1];
    if (tags.length === 0 || params.query === '') {
      getTags(params, parseInt(tagid));
    }
    if (paramsStatus === true) {
      getTags(params, parseInt(tagid));
    }
  }, [params.page, params.query, params.sortBy, paramsStatus]);

  // useEffect(() => {
  //   if (tags.length === 0) {
  //     // Only make the API call when overviewData is empty and selectionRange is defined
  //     const tagid = location.pathname.split('/publisher/supply/')[1];
  //     getTags(params, parseInt(tagid));
  //   } else {
  //     sLoaderTags(false); // If data is already present, setSkeleton to false
  //   }
  // }, [tags, params.page, params.query, params.sortBy, location.pathname]);

  /***** Handle on scroll API's end *****/

  const handleParamsChange = (page: number, query: string, sortBy: any, status: boolean) => {
    if (
      page !== params.page ||
      query !== params.query ||
      sortBy.id !== params.sortBy.id ||
      sortBy.desc !== params.sortBy.desc
    ) {
      setParams({ page, query, sortBy });
      setParamsStatus(status);
    }
  };

  const getTags = (params: any, id: any) => {
    const payload = {
      page: params.page,
      page_size: 500,
      query: params.query,
      sort_by: {
        sortBy: [params.sortBy],
      },
    };
    sLoaderTags(true);
    getTagsListAPI(payload)
      .then((data: any) => {
        if (data.status) {
          sCurrentPage(params.page);
          //For Page Scroll
          if (params.page > 0) {
            if (data.data.length !== 0) {
              sTags([...tags, ...data.data]);
              sTagsLegnth(1);
            } else {
              sHasMore(false);
              sTags(tags);
              sTagsLegnth(tags.length);
              sEmpty(data.data.length < 1);
            }
          } else {
            sTags(data.data);
            if (data.data.length < 1) sEmpty(true);
            if (params.query?.length > 0) {
              sTagsLegnth(1);
              sSelectedTag('');
            } else {
              sTagsLegnth(data.data.length);
            }
            if (id) {
              selectedCard(
                data.data.find((x: any) => x.id === id)?.id,
                data.data.find((x: any) => x.id === id)?.tag_type
              );
            }
          }
        }
        sLoaderTags(false);
      })
      .catch((error: any) => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const handleAddTag = (added: any) => {
    const newlyAdded = { ...added.data };
    const newArr = [newlyAdded, ...tags];
    sTags(newArr);
    selectedCard(newArr[0].id, newArr[0].tag_type);
  };

  const handleUpdateTag = (updated: any) => {
    const newArray = [...tags];
    const index = newArray.findIndex((item) => item.id === updated.id);
    if (index !== -1) {
      newArray[index] = { ...newArray[index], ...updated }; //whole object update
      //newArray[index] = { ...newArray[index], status: updated.status }; // only key value update
    }
    sTags(newArray);
  };

  // const getOverviewData = () => {
  //   const payload = {
  //     start_date: convert(selectionRange?.startDate) || '',
  //     end_date: convert(selectionRange?.endDate) || '',
  //     time_zone: 'Asia/Kolkata',
  //   };
  //   setAllData(true);
  //   if (selectionRange?.startDate !== selectionRange?.endDate) {
  //     overviewAPI(payload)
  //       .then((data: any) => {
  //         if (data) {
  //           sSkeleton(false);
  //           setOverviewData(data);
  //           const tileData = overviewTiles(data.total);
  //           sOverviewTilesData(tileData);
  //           sRandom(Math.random());
  //           selectTile(0);
  //           setAllData(true);
  //         }
  //       })
  //       .catch((error) => {
  //         // toast.error('Service unavailable, Check back after sometime.');
  //       });
  //   }
  // };

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    //if (selectedTag === '') getOverviewData();
    if (
      selectedTag &&
      (convert(currentDateSelection?.startDate) !== convert(dateRangeInventory?.startDate) ||
        convert(currentDateSelection?.endDate) !== convert(dateRangeInventory?.endDate))
    ) {
      getSelectedTagData(selectedTag);
      if (selectedTagDetails?.tag_type === 'extension') {
        getGenuinOverview(selectedTag);
      }
    }
  }, [dateRangeInventory, selectedTag]);

  useEffect(() => {
    if (tags.length > 0 && selectedTag) {
      sDatatables(tags.find((x: any) => x.id === selectedTag)?.tables ?? []);
      setSelectedTagDetails(tags.find((x: any) => x.id === selectedTag));
    }
    if (selectedTag) {
      setSelectedTabSSP('performance');
    }
  }, [tags, selectedTag]);

  useEffect(() => {
    const bottom: any = [];
    rawBarData.map((_: any, i: any) => {
      return bottom.push({ tag: _.tag, [attribute]: _[attribute] });
    });
    const top = bottom.sort((a: any, b: any) => b[attribute] - a[attribute]).slice(0, 5);
    sRefinedBarData({
      labels: top.map((a: any) => a.tag),
      values: top.map((a: any) => a[attribute]),
    });
  }, [rawBarData, attribute]);

  useEffect(() => {
    if (barData && barData.length) {
      const newData = barData
        .sort((a: any, b: any) => b[Object.keys(b)[0]] - a[Object.keys(a)[0]])
        .slice(0, 5);
      let newlabels: any = [];
      newlabels = newData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = newData.map((_: any) => _[Object.keys(_)[0]]);
      sRefinedBarData({ labels: newlabels, values: newValues });
    }
  }, [barData]);

  useEffect(() => {
    const bottom: any = [];
    rawLineData.map((_: any, i: any) => {
      return bottom.push({ date: _.date, [attribute]: _[attribute] });
    });
    sRefinedLineData({
      labels: bottom.map((a: any) => a.date),
      values: bottom.map((a: any) => a[attribute]),
    });
  }, [rawLineData, attribute]);

  useEffect(() => {
    if (lineData && lineData.length) {
      let newlabels: any = [];
      newlabels = lineData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = lineData.map((_: any) => _[Object.keys(_)[0]]);
      /* replace new value array */
      //sRefinedLineData({ labels: newlabels, values: newValues });
      sRefinedLineData({ labels: newValues, values: newlabels });
    } else {
      sRefinedLineData({ labels: [], values: [] });
    }
  }, [lineData]);

  const handleTabs = (key: any) => {
    setSelectedTabSSP(key);
  };

  /* get tag setting data start */
  const getTagSettingDetails = (id: any) => {
    const payload = {
      id: id,
    };
    sLoaderSetting(true);
    getTagSettingAPI(payload)
      .then((data: any) => {
        setTagSettingData(data?.data);
        sLoaderSetting(false);
      })
      .catch((error) => {
        toast.error(glossary.dsp_add_endpoint_server_error);
      });
  };
  /* get tag setting data end */

  const [showModalExtDetails, sShowModalExtDetails] = useState(false);

  const handleExtDetails = () => {
    sShowModalExtDetails(true);
  };

  const handleUpdateExtension = () => {
    sEditModal(true);
  };

  return (
    <>
      {/* MSA modal Start*/}
      <MsaSign
        show={showMsaSign}
        onHide={() => {
          sShowMsaSign(false);
        }}
      />

      {/* Publisher Onboarding */}
      {tagsLegnth === 0 && customerInfo?.milestone?.tag === false && (
        <PublisherOnbording pSection={section} />
      )}

      {tagsLegnth !== 0 && (
        <div className={'content d-flex'}>
          {/* Tag Modal Create*/}
          <AddTag
            kind={'create'}
            show={create}
            pSection={section}
            onHide={(id: any) => {
              sCreate(false);
              sCreateClick(false);
              sSection('1');
            }}
            pSSection={sSection}
            getTags={handleAddTag}
            tagDetails={tagDetails}
            scriptProp={tags?.find((x: any) => x.id === selectedTag)?.script}
            adsTxt={tags?.find((x: any) => x.id === selectedTag)?.ads_txt}
            tagId={tags?.find((x: any) => x.id === selectedTag)?.id}
            tagType={tags?.find((x: any) => x.id === selectedTag)?.tag_type}
            createTag={createClick}
            selectedTagDetails={tags?.find((x: any) => x.id === selectedTag)}
          />

          {/* Tag Modal Update*/}
          <EditTag
            kind={'update'}
            show={editModal}
            pSection={section}
            onHide={(id: any) => {
              sEditModal(false);
              sCreateClick(false);
              sSection('1');
            }}
            pSSection={sSection}
            getTags={handleUpdateTag}
            tagDetails={tagDetails}
            scriptProp={tags?.find((x: any) => x.id === selectedTag)?.script}
            adsTxt={tags?.find((x: any) => x.id === selectedTag)?.ads_txt}
            tagId={tags?.find((x: any) => x.id === selectedTag)?.id}
            tagType={tags?.find((x: any) => x.id === selectedTag)?.tag_type}
            createTag={createClick}
            selectedTagDetails={tags?.find((x: any) => x.id === selectedTag)}
          />

          {/* Extension details modal*/}
          <ExtensionModalDetails
            show={showModalExtDetails}
            onHide={() => {
              sShowModalExtDetails(false);
            }}
            //pSection={'3'}
            pSection={'2'}
            getTags={getTags}
            tagId={tags?.find((x: any) => x.id === selectedTag)?.id}
            createTag={createClick}
            selectedTagDetails={tags?.find((x: any) => x.id === selectedTag)}
          />

          <PublisherSidebar
            title={glossary.ssp_dash_sidebar_title_tags}
            kind={'tag'}
            size={sidebar}
            action={{
              icon: add,
              text: glossary.ssp_dash_sidebar_button_add_new,
              click: createTag,
              className: 'tour-addnew-ssp',
            }}
            overviewAction={{
              text: glossary.overview_button,
              click: () => {
                sSelectedTag('');
                history.push('/publisher/supply');
              },
              isOverview: location.pathname === '/publisher/supply' ? true : false,
            }}
            card={'expanded'}
            items={tags}
            sidebar={setSidebar}
            info={selectedCard}
            getTags={(updated: any) => {
              handleUpdateTag(updated);
              selectedCard(selectedTag ?? 0, selectedTagDetails?.tag_type);
            }}
            empty={empty}
            selected={selectedTag}
            tagDetails={tagDetails}
            currentPage={currentPage}
            loading={loaderTags}
            hasMore={hasMore}
            params={params}
            handleParams={handleParamsChange}
            allData={true}
          />

          <>
            <div className='view-section'>
              {selectedTag === '' && tags && !location.pathname.split('/publisher/supply/')[1] ? (
                <InventoryOverview empty={empty} />
              ) : (
                <Tab.Container
                  defaultActiveKey={selectedTabSSP}
                  activeKey={selectedTabSSP}
                  onSelect={(key: any) => handleTabs(key)}
                >
                  {/* {refinedLineData.labels < 1 && refinedLineData.values < 1 && (
                    <div className='row align-items-center justify-content-between'>
                      <div className='col col-4 mt-0'>
                        <div className='d-flex gap-3 align-items-center mt-2'>
                          <TextSkeleton height={20} width={113} lineCount={1} />
                          <TextSkeleton height={20} width={20} lineCount={1} />
                          <TextSkeleton height={20} width={20} lineCount={1} />
                        </div>
                      </div>
                      <div className='col col-4 mt-0'>
                        <ButtonSkeleton width={190} height={38} />
                      </div>
                      <div className='col col-4 mt-0'>
                        <TextSkeleton height={26} width={230} lineCount={1} />
                      </div>
                    </div>
                  )} */}
                  <div className='row align-items-center'>
                    <div className='col-4 mt-0'>
                      {tags?.find((x: any) => x.id === selectedTag)?.tag_type === 'extension' ? (
                        <div className='d-flex gap-3 align-items-center'>
                          <h2 className='text-truncate'>
                            {tags?.find((x: any) => x.id === selectedTag)?.name}
                          </h2>
                          <MdInfoOutline
                            className='svg-icon flex-shrink-0'
                            onClick={() => {
                              handleExtDetails();
                            }}
                            title='Details'
                            fontSize={20}
                          />
                          <BiEditAlt
                            className='svg-icon tour-sspinfo flex-shrink-0'
                            onClick={() => {
                              handleUpdateExtension();
                            }}
                            style={{ marginRight: 5 }}
                            title='Edit'
                            fontSize={20}
                          />
                        </div>
                      ) : (
                        <div className='d-flex gap-3 align-items-center'>
                          <h2 className='text-truncate'>
                            {tags?.find((x: any) => x.id === selectedTag)?.name}
                          </h2>
                          <MdInfoOutline
                            className='svg-icon tour-sspinfo flex-shrink-0'
                            onClick={() => {
                              tagDetails(tags?.find((x: any) => x.id === selectedTag).id);
                            }}
                            style={{ marginRight: 5 }}
                            title='Details'
                            fontSize={20}
                          />
                          <BiEditAlt
                            className='svg-icon tour-sspinfo flex-shrink-0'
                            onClick={() => {
                              sEditModal(true);
                              sSection('1');
                            }}
                            style={{ marginRight: 5 }}
                            title='Edit'
                            fontSize={20}
                          />
                        </div>
                      )}
                    </div>

                    <div className='col-4 mt-0'>
                      <Nav variant='pills'>
                        <Nav.Item>
                          {tags?.find((x: any) => x.id === selectedTag)?.tag_type ===
                          'extension' ? (
                            <Nav.Link eventKey='performance'>Ads Performance</Nav.Link>
                          ) : (
                            <Nav.Link eventKey='performance'>Performance</Nav.Link>
                          )}
                        </Nav.Item>
                        <Nav.Item>
                          {tags?.find((x: any) => x.id === selectedTag)?.tag_type ===
                          'extension' ? (
                            <Nav.Link eventKey='settings'>Video Performance</Nav.Link>
                          ) : (
                            <Nav.Link eventKey='settings'>Settings</Nav.Link>
                          )}
                        </Nav.Item>
                      </Nav>
                    </div>

                    <div className='col-4 mt-0'>
                      <div
                        className={
                          selectedTabSSP === 'performance' ||
                          selectedTagDetails?.tag_type === 'extension'
                            ? 'calendar__wrap tour-calendar-wrap'
                            : 'calendar__wrap tour-calendar-wrap d-none'
                        }
                        style={{ position: 'relative' }}
                      >
                        <DateRangeFilter
                          onChange={GetSelectionRangeProps}
                          selectionRange={dateRangeInventory}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <Tab.Content>
                        <Tab.Pane eventKey='performance'>
                          {(refinedLineData.labels < 1 && refinedLineData.values < 1) || allData ? (
                            <>
                              <div
                                className={'row'}
                                style={{ marginTop: '56px', justifyContent: 'space-between' }}
                              >
                                <div className='col-6'>
                                  <div className='d-flex gap-4'>
                                    {[1, 2, 3, 4].map((index) => {
                                      return <Box key={index} />;
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className='row mt-4'>
                                <div className='col w-100 m-auto'>
                                  <XYChart />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className={'row'} style={{ marginTop: 56 }}>
                                {singleTileData.map((tile: any, index: any) => {
                                  return (
                                    <div key={index} className={'col-auto'}>
                                      {refinedLineData.labels < 1 &&
                                      refinedLineData.values < 1 ? null : (
                                        <Tile
                                          id={tile.id}
                                          selected={selectedTile}
                                          click={selectTile}
                                          label={tile.label}
                                          value={tile.value}
                                          info={tile.info}
                                          arrow={tile.arrow}
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                              <div
                                className={'row'}
                                style={{
                                  height: '320px',
                                  marginTop: '24px',
                                }}
                              >
                                <Line
                                  label={'Impressions'}
                                  data={refinedLineData}
                                  attribute={attribute}
                                />
                              </div>
                            </>
                          )}

                          <div className={'row report-table'} style={{ marginTop: 24 }}>
                            <Reports
                              id={selectedTag}
                              start={dateRangeInventory?.startDate}
                              end={dateRangeInventory?.endDate}
                              tabs={datatables}
                              random={random}
                              customerId={customerInfo.id}
                              entityType={'tag'}
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='settings'>
                          <div className={'row'} style={{ marginTop: 73 }}>
                            {tags?.find((x: any) => x.id === selectedTag)?.tag_type ===
                            'extension' ? (
                              <GenuinReporting
                                singleTileData={genuinSingleTile}
                                random={genuinRandom}
                                loading={genuinLoading}
                                tagID={selectedTag}
                                start={dateRangeInventory?.startDate}
                                end={dateRangeInventory?.endDate}
                                datatables={selectedTagDetails?.extension_tables}
                                customerId={customerInfo.id}
                              />
                            ) : (
                              <>
                                {loaderSetting ? (
                                  <div className='col-12'>
                                    <div className='row mb-4'>
                                      <div className='col-6'>
                                        <TextSkeleton width={500} height={30} lineCount={3} />
                                      </div>
                                      <div className='col-2'></div>
                                      <div className='col-4'>
                                        <div className='float-end pe-4'>
                                          <ButtonSkeleton width={150} height={38} />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-12'>
                                      <TableSkeleton
                                        columns={1}
                                        rows={9}
                                        rowSpacing={2}
                                        tableHeight={400}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <TagSetting
                                    tagId={tags?.find((x: any) => x.id === selectedTag)?.id}
                                    settingGetObj={tagSettingData?.setting}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default PublisherSupply;
