import { useEffect, useState } from 'react';
import Tile from '../../widgets/tile';
//import ReactTable from '../../tables/reactTable';
import Line from '../../charts/line';
import Bar from '../../charts/bar';
import { overviewDsp as overviewTiles } from '../../helpers/tiles';
import TableSkeleton from '../../loaders/table';
import BarGraph from '../../loaders/bar-graph';
import LineGraph from '../../loaders/line-graph';
import { getDealDetailsAPI } from '../../../services/deals';
import Box from '../../loaders/box';
import XYChart from '../../loaders/chart';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';
import OverViewTable from '../../tables/overview';
import TextSkeleton from '../../loaders/text';

const css = {
  minCol: {
    minWidth: 'fit-content',
  },
  maxCol: {
    maxWidth: 'fit-content',
  },
};

const DealsOverview = (props: any) => {
  const { dealsOverviewAPI }: any = useAuthentication();
  const {
    dealsOverviewData,
    setDealsOverviewData,
    dealsOverviewTilesData,
    setDealsOverviewTilesData,
    sDateRangeMyDealsOverview,
    dateRangeMyDealsOverview,
  }: any = consumerData();

  const { deals, empty, dealid, overviewCall } = props;

  /* States */
  //const [overviewTilesData, sOverviewTilesData] = useState<any>([]);
  const [random, sRandom] = useState(Math.random());
  const [selectedTile, sSelectedTile] = useState<any>(0);
  const [lineData, sLineData] = useState<any>(null);
  const [barData, sBarData] = useState<any>(null);
  //const [overviewData, setOverviewData] = useState<any>({});
  // const [singleTileData, sSingleTile] = useState<any>([]);
  const [refinedBarData, sRefinedBarData] = useState<any>([]);
  const [refinedLineData, sRefinedLineData] = useState<any>([]);
  const [skeleton, sSkeleton] = useState(true);
  const [rawBarData, sRawBarData] = useState<any>([]);
  const [attribute, sAttribute] = useState<any>(null);
  const [rawLineData, sRawLineData] = useState<any>([]);
  const [dateChange, setDateChange] = useState(false);

  // const [selectionRange, setSelectionRange] = useState({
  //   startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
  //   endDate: new Date(),
  //   key: 'selection',
  // });

  const GetSelectionRangeProps = (data: any) => {
    sDateRangeMyDealsOverview(data);
    selectTile(0);
    if (data.startDate !== data.endDate) {
      setDateChange(true);
    }
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  useEffect(() => {
    if (Object.keys(dealsOverviewData).length === 0) {
      // Only make the API call when overviewData is empty and selectionRange is defined
      getOverviewData();
    } else {
      sSkeleton(false); // If data is already present, setSkeleton to false
    }
  }, [dealsOverviewData]);

  useEffect(() => {
    if (
      (dateChange || overviewCall) &&
      convert(dateRangeMyDealsOverview?.startDate) !== convert(dateRangeMyDealsOverview?.endDate)
    )
      getOverviewData();
    setDateChange(false);
  }, [dateChange, overviewCall]);

  // useEffect(() => {
  //   if (
  //     convert(dateRangeMyDealsOverview?.startDate) !== convert(dateRangeMyDealsOverview?.endDate)
  //   ) {
  //     getOverviewData();
  //   }
  // }, [dateRangeMyDealsOverview]);

  useEffect(() => {
    if (dealid && dateRangeMyDealsOverview?.startDate && dateRangeMyDealsOverview?.endDate) {
      getDealDetails(dealid);
    }
  }, [dealid, dateRangeMyDealsOverview]);

  /* Overview API */
  const getOverviewData = () => {
    const payload = {
      start_date: convert(dateRangeMyDealsOverview.startDate) || '',
      end_date: convert(dateRangeMyDealsOverview.endDate) || '',
      time_zone: 'Asia/Kolkata',
    };
    if (dateRangeMyDealsOverview.startDate !== dateRangeMyDealsOverview.endDate) {
      sSkeleton(true);
      dealsOverviewAPI(payload)
        .then((data: any) => {
          if (data) {
            setDealsOverviewData(data);
            const tileData = overviewTiles(data?.total);
            setDealsOverviewTilesData(tileData);
            sRandom(Math.random());
            selectTile(0);
            sSkeleton(false);
            setDateChange(false);
          }
        })
        .catch((error: any) => {
          // toast.error('Service unavailable, Check back after sometime.');
        });
    }
  };

  const getDealDetails = (id: any) => {
    const payload = {
      start_date: convert(dateRangeMyDealsOverview.startDate) || '',
      end_date: convert(dateRangeMyDealsOverview.endDate) || '',
      time_zone: 'Asia/Kolkata',
    };
    sSkeleton(true);
    getDealDetailsAPI(payload, id)
      .then((data: any) => {
        if (data) {
          setDealsOverviewData(data);
          const tileData = overviewTiles(data?.total);
          setDealsOverviewTilesData(tileData);
          sRandom(Math.random());
          selectTile(0);
          sSkeleton(false);
          // setDateChange(false);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  useEffect(() => {
    sBarData(dealsOverviewTilesData?.find((x: any) => x.id === selectedTile)?.barData ?? []);
    sLineData(dealsOverviewTilesData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
  }, [selectedTile, random]);

  useEffect(() => {
    const bottom: any = [];
    rawBarData.map((_: any, i: any) => {
      return bottom.push({ tag: _.tag, [attribute]: _[attribute] });
    });
    const top = bottom.sort((a: any, b: any) => b[attribute] - a[attribute]).slice(0, 5);
    sRefinedBarData({
      labels: top.map((a: any) => a.tag),
      values: top.map((a: any) => a[attribute]),
    });
  }, [rawBarData, attribute]);

  useEffect(() => {
    if (barData && barData.length) {
      const newData = barData
        .sort((a: any, b: any) => b[Object.keys(b)[0]] - a[Object.keys(a)[0]])
        .slice(0, 5);
      let newlabels: any = [];
      newlabels = newData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = newData.map((_: any) => _[Object.keys(_)[0]]);
      sRefinedBarData({ labels: newlabels, values: newValues });
    }
  }, [barData]);

  useEffect(() => {
    const bottom: any = [];
    rawLineData.map((_: any, i: any) => {
      return bottom.push({ date: _.date, [attribute]: _[attribute] });
    });
    sRefinedLineData({
      labels: bottom.map((a: any) => a.date),
      values: bottom.map((a: any) => a[attribute]),
    });
  }, [rawLineData, attribute]);

  useEffect(() => {
    if (lineData && lineData.length) {
      let newlabels: any = [];
      newlabels = lineData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = lineData.map((_: any) => _[Object.keys(_)[0]]);
      /* replace new value array */
      //sRefinedLineData({ labels: newlabels, values: newValues });
      sRefinedLineData({ labels: newValues, values: newlabels });
    } else {
      sRefinedLineData({ labels: [], values: [] });
    }
  }, [lineData]);

  return (
    <>
      {/* <div className='row'>
        <div className={dealid ? 'col overview-text' : 'col'}>
          <h2>Overview</h2>
        </div>
        <div className={dealid ? 'col overview-date' : 'col'} style={{ position: 'relative' }}>
          <DateRangeFilter onChange={GetSelectionRangeProps} selectionRange={dateRangeMyDealsOverview}/>
        </div>
      </div> */}
      <div className='row justify-content-between'>
        <div className={dealid ? 'overview-text col col-6' : 'col'}>
          {skeleton ? <TextSkeleton height={26} width={100} lineCount={1} /> : <h2>Overview</h2>}
        </div>
        <div
          className={dealid ? 'col col-6 overview-date' : 'col'}
          style={{ position: 'relative' }}
        >
          {skeleton ? (
            <TextSkeleton height={26} width={230} lineCount={1} />
          ) : (
            <DateRangeFilter
              onChange={GetSelectionRangeProps}
              selectionRange={dateRangeMyDealsOverview}
            />
          )}
        </div>
      </div>
      <div className={'row overview-tiles'} style={{ marginTop: dealid ? 0 : 65 }}>
        <div className={'col'} style={css.maxCol}>
          <div className={'row'}>
            {skeleton ? (
              <div className='col-6 d-flex gap-4'>
                {[1, 2, 3, 4, 5, 6].map((index) => {
                  return <Box key={index} />;
                })}
              </div>
            ) : (
              dealsOverviewTilesData.length > 0 &&
              dealsOverviewTilesData.map((tile: any, index: any) => {
                return (
                  <div key={index} className={'col'} style={css.minCol}>
                    <Tile
                      id={tile.id}
                      selected={selectedTile}
                      click={selectTile}
                      label={tile.label}
                      value={tile.value}
                      info={tile.info}
                      arrow={tile.arrow}
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {!dealid ? (
        <div
          className={'row overview-graphs'}
          style={{
            marginTop: 24,
            marginLeft: 0,
            marginRight: 0,
            minHeight: '250px',
            maxHeight: 'fit-content',
            borderRadius: '4px',
            padding: '24px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div className={'col'} style={{ maxWidth: '50%' }}>
            {skeleton ? (
              <BarGraph empty={empty} />
            ) : (
              <Bar label={'Impressions'} data={refinedBarData} attribute={attribute} />
            )}
          </div>
          <div className={'col'} style={{ maxWidth: '50%' }}>
            {skeleton ? (
              <LineGraph empty={empty} />
            ) : (
              <Line label={'Impressions'} data={refinedLineData} attribute={attribute} />
            )}
          </div>
        </div>
      ) : (
        <>
          {!skeleton && deals.length > 0 ? (
            <div
              className={'row'}
              style={{
                height: '320px',
                marginTop: '24px',
              }}
            >
              <Line label={'Impressions'} data={refinedLineData} attribute={attribute} />
            </div>
          ) : (
            <div
              className={'row'}
              style={{
                marginTop: '-10px',
                justifyContent: 'space-between',
              }}
            >
              <div className='col w-100 mt-5'>
                <XYChart />
              </div>
            </div>
          )}
        </>
      )}

      {!dealid && (
        <div className='row overview-tables'>
          {skeleton ? (
            <div className='pt-5'>
              <TableSkeleton columns={5} rows={3} rowSpacing={10} tableHeight={150} />
            </div>
          ) : (
            <>
              <OverViewTable
                tableColumns={dealsOverviewData?.table?.columns ?? []}
                tableRows={dealsOverviewData?.table?.data ?? []}
                search={true}
                searchPlaceholder={'Search Here...'}
                downloadCSV={true}
              />
              {/* {dealsOverviewData?.table?.data.length > 0 && (
                <ReactTable
                  tableColumns={dealsOverviewData?.table?.columns ?? []}
                  tableRows={dealsOverviewData?.table?.data ?? []}
                  search={true}
                  searchPlaceholder={'Search Here...'}
                  kind={'view-only'}
                  styletable={true}
                />
              )} */}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DealsOverview;
