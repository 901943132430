import { useState } from 'react';
import ContentLoader from 'react-content-loader';

import Card from '../widgets/card';

const Loader = (props: any) => {
  const { empty, expand } = props;
  const [show, sShow] = useState(false);
  return (
    <>
      {empty ? (
        <div
          style={{ marginTop: 10 }}
          onMouseEnter={() => sShow(true)}
          onMouseLeave={() => sShow(false)}
        >
          {show ? (
            <Card
              id={Math.random().toString()}
              text={'Your Tag'}
              kind={'tag'}
              size={'expanded'}
              info={{
                type: '',
                traffic: 'xxx',
                thumb: '',
                duration: 'xx',
                created: 'xx-xx-xxxx',
                impressions: 'xxxxx',
                fillRate: 'xx',
                floorPrice: 'xx',
                earning: 'xx',
                status: 'active',
              }}
              selected={'2'}
              click={() => null}
            />
          ) : (
            <ContentLoader
              title=''
              speed={0}
              width={289}
              height={152}
              viewBox='0 0 289 152'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
              {...props}
            >
              <rect x='471' y='226' rx='4' ry='4' width='288' height='151' />
              <circle cx='28' cy='28' r='12' />
              <path d='M 48 19.5 h 83 v 17 H 48 z M 16 56 h 61 v 14 H 16 z M 16 74 h 91 v 14 H 16 z M 123 56 h 31 v 14 h -31 z M 123 74 h 63 v 14 h -63 z M 202 56 h 41 v 14 h -41 z M 202 74 h 69 v 14 h -69 z M 16 104 h 61 v 14 H 16 z M 16 122 h 91 v 14 H 16 z M 124 104 h 31 v 14 h -31 z M 124 122 h 63 v 14 h -63 z M 204 104 h 41 v 14 h -41 z M 204 122 h 69 v 14 h -69 z' />
              <rect x='498' y='280' rx='4' ry='4' width='288' height='151' />
            </ContentLoader>
          )}
        </div>
      ) : (
        <ContentLoader
          title=''
          speed={1.2}
          width={289}
          height={152}
          viewBox='0 0 289 152'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          {...props}
        >
          <rect x='471' y='226' rx='4' ry='4' width='288' height='151' />
          <circle cx='28' cy='28' r='12' />
          <path d='M 48 19.5 h 83 v 17 H 48 z M 16 56 h 61 v 14 H 16 z M 16 74 h 91 v 14 H 16 z M 123 56 h 31 v 14 h -31 z M 123 74 h 63 v 14 h -63 z M 202 56 h 41 v 14 h -41 z M 202 74 h 69 v 14 h -69 z M 16 104 h 61 v 14 H 16 z M 16 122 h 91 v 14 H 16 z M 124 104 h 31 v 14 h -31 z M 124 122 h 63 v 14 h -63 z M 204 104 h 41 v 14 h -41 z M 204 122 h 69 v 14 h -69 z' />
          <rect x='498' y='280' rx='4' ry='4' width='288' height='151' />
        </ContentLoader>
      )}

      {expand && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #f3f3f3',
            borderRadius: '5px',
            padding: '16px',
          }}
        >
          <div
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              backgroundColor: '#f3f3f3',
              marginRight: '10px',
            }}
          ></div>
          <div className='d-flex w-100 justify-content-between'>
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                style={{
                  width: '150px',
                  height: '12px',
                  backgroundColor: '#f3f3f3',
                  marginRight: '10px',
                }}
              ></div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
