import { useEffect, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { MdInfoOutline } from 'react-icons/md';

import Button from '../widgets/button';
import store from '../../states';
import { getPublisherDealDemandAPI, sspDemandAssignToDealAPI } from '../../services/tags';
import {
  assignRequestedDealAPI,
  getRequestedDealListAPI,
  offerDealToAgenciesAPI,
} from '../../services/deals';
import TableSkeleton from '../loaders/table';
import SpoCustomerList from '../components/spo/SpoCustomerList';
import { toast } from 'react-toastify';
import { HBLoginUrlAPI, HBSendEmailLoginAPI, getHBlogoutAPI } from '../../services/headerbidder';
import { useGoogleLogin } from '@react-oauth/google';
import useAuthentication from '../contexts/OverViewContext';
import consumerData from '../contexts/OverViewContext';

export default ({ id, onHide, show, addedCustomers, api, addedRequestedDeal, dealapi }: any) => {
  const { HBLoginUrlAPI }: any = useAuthentication();
  const { needLogin, setNeedLogin }: any = consumerData();

  const [customers, sCustomers] = useState([]);
  const [filteredCustomers, sFilteredCustomers] = useState([]);
  const [selectedCustomers, sSelectedCustomers] = useState<any>([]);
  const [noCustomers, setNoCustomers] = useState(false);
  const [skeletonOne, sSkeletonOne] = useState(true);
  const [selectedAgenciesList, setSelectedAgenciesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const getCustomers = () => {
    getPublisherDealDemandAPI()
      .then((data: any) => {
        if (data) {
          sCustomers(data.data);
          if (data.data.length === 0) setNoCustomers(true);
          sSkeletonTwo(false);
        }
      })
      .catch((error) => {
        //ok
      });
  };

  const [noRequestedDeal, sNoRequestedDeal] = useState(false);
  const [requestedDealList, sRequestedDealList] = useState([]);
  const [filteredReqDeal, sFilteredReqDeal] = useState([]);
  const [skeletonTwo, sSkeletonTwo] = useState(true);

  //Get Requested Deal List
  const getRequestDealList = () => {
    const payload = {
      //ok
    };
    getRequestedDealListAPI(payload)
      .then((data: any) => {
        if (data.data.length > 0) {
          sRequestedDealList(data.data);
        }
        if (data.data.length === 0) sNoRequestedDeal(true);
        sSkeletonOne(false);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const selectCustomer = (id: any, e: any) => {
    if (e.target.checked) {
      sSelectedCustomers([...selectedCustomers, id]);
    } else {
      sSelectedCustomers(selectedCustomers.filter((x: any) => x !== id));
    }
  };

  const [selectedReqDeal, sSelectedReqDeal] = useState<any>([]);
  const selectRequestedDeal = (id: any, e: any) => {
    if (e.target.checked) {
      sSelectedReqDeal([...selectedReqDeal, id]);
    } else {
      sSelectedReqDeal(selectedReqDeal.filter((x: any) => x !== id));
    }
  };

  const assignRequestedDeal = (id: any) => {
    const payload = {
      deal_id: id,
      requested_deal_id: selectedReqDeal,
    };
    setIsLoading(true);
    assignRequestedDealAPI(payload)
      .then((data: any) => {
        if (data) {
          onHide();
          dealapi(id);
          sSelectedReqDeal([]);
          sFilteredReqDeal([]);
          setIsLoading(false);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const addCustomers = (id: any) => {
    const payload = {
      deal_id: id,
      endpoints: selectedCustomers,
    };
    setIsLoading(true);
    sspDemandAssignToDealAPI(payload)
      .then((data: any) => {
        if (data) {
          onHide();
          api(id);
          setTimeout(() => {
            sSelectedCustomers([]);
            sFilteredCustomers([]);
          }, 200);
          setIsLoading(false);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const [skeletonThree, setSkeletonThree] = useState(false);
  const offerDealToAgencies = (id: any) => {
    const payload = {
      agency: selectedAgenciesList,
      deal_id: id,
    };
    setSkeletonThree(true);
    offerDealToAgenciesAPI(payload)
      .then((data: any) => {
        if (data.status) {
          onHide();
          toast.success(data.message);
        } else {
          onHide();
          toast.error(data.message);
        }
        setSkeletonThree(false);
      })
      .catch((error: any) => {
        // Error
      });
  };

  useEffect(() => {
    if (customers.length > 0) {
      sFilteredCustomers(customers.filter((x: any) => !addedCustomers.includes(x.id)));
    }
    if (requestedDealList.length > 0) {
      sFilteredReqDeal(requestedDealList.filter((x: any) => !addedRequestedDeal.includes(x.id)));
    }
  }, [customers, requestedDealList]);

  useEffect(() => {
    if (show) {
      getCustomers();
      getRequestDealList();
    }
  }, [show]);

  const BASE_URL = process.env.REACT_APP_API_URL ? 'https://app.infy.tv' : 'https://sbapp.infy.tv';

  //const [needLogin, setNeedLogin] = useState(false);
  const [mailSentEmail, setMailSentEmail] = useState('');

  /* Google Login */
  useEffect(() => {
    // if (!localStorage.getItem('is_google_login')) {
    //   verifyGoogle();
    // }
    if (needLogin === false) {
      verifyGoogle();
    }
  }, []);

  const verifyGoogle = () => {
    const payload = {
      redirect_uri: window.location.origin,
    };
    HBLoginUrlAPI(payload)
      .then((data: any) => {
        if (data.status) {
          setNeedLogin(data.need_login);
          setMailSentEmail(data.email);
        }
      })
      .catch((error: any) => {
        console.log('Google Error!', error);
      });
  };

  /* Open Google Login Modal */
  const openGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.send',
    onSuccess: async (codeResponse) => {
      const payload = {
        code: codeResponse.code,
        redirect_uri: window.location.origin,
      };
      handleGoogleLogin(payload);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const handleGoogleLogin = (payload: any) => {
    HBSendEmailLoginAPI(payload)
      .then((data: any) => {
        if (data.status) {
          verifyGoogle();
        }
      })
      .catch((error: any) => {
        //
      });
  };

  const handleGoogleLogout = () => {
    getHBlogoutAPI()
      .then((data: any) => {
        if (data.status) setNeedLogin(true);
      })
      .catch((error: any) => {
        //ok
      });
  };

  return (
    <Modal onHide={onHide} show={show} centered size={'xl'} className={'tabs-modal'}>
      <Tabs defaultActiveKey={'deal-requests'}>
        <Tab eventKey='deal-requests' title={glossary.ssp_deals_add_demand_popup_tab_deal_requests}>
          <div
            className='modal-body p-3'
            style={{
              // maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            {skeletonOne ? (
              <>
                <TableSkeleton columns={3} rows={5} rowSpacing={2} tableHeight={150} />
              </>
            ) : (
              <>
                <div className={filteredReqDeal.length > 0 ? 'row pb-3' : 'd-none'}>
                  <span className='col-1'></span>
                  <span className='col'>
                    <strong>
                      {glossary.ssp_deals_add_demand_popup_tab_deal_requests_title_deal_id}
                    </strong>
                  </span>
                  <span className='col'>
                    <strong>
                      {glossary.ssp_deals_add_demand_popup_tab_deal_requests_title_deal_name}
                    </strong>
                  </span>
                </div>
                <div style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                  {noRequestedDeal ? (
                    <div className='mb-3 text-center'>
                      {glossary.ssp_deals_add_demand_popup_tab_deal_requests_empty_msg}
                    </div>
                  ) : filteredReqDeal.length > 0 ? (
                    filteredReqDeal.length > 0 &&
                    filteredReqDeal.map((customer: any, index: any) => (
                      <div className='row mb-3 m-0' key={index}>
                        <div className='col-1'>
                          <Form.Check
                            name={'id'}
                            onChange={(e) => selectRequestedDeal(customer.id, e)}
                            type='checkbox'
                            label={''}
                          />
                        </div>
                        <div className='col'>{customer.id}</div>
                        <div className='col'>{customer.name}</div>
                      </div>
                    ))
                  ) : (
                    <p className='text-center m-4'>
                      {glossary.ssp_deals_add_demand_popup_tab_deal_requests_assigned_msg}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
          <div className='modal-footer px-3 gap-2'>
            <Button
              kind={'secondary'}
              text={glossary.close_btn}
              // ssp_deals_add_demand_popup_button_cancel
              click={() => onHide()}
            />
            {isLoading ? (
              <Button kind={'spinner'} type={'button'} text={'Adding...'} />
            ) : (
              <Button
                kind={selectedReqDeal.length > 0 ? 'primary' : 'disabled'}
                text={glossary.add_btn}
                // ssp_deals_add_demand_popup_button_add
                click={() => {
                  assignRequestedDeal(id);
                }}
              />
            )}
          </div>
        </Tab>

        <Tab eventKey='my-customers' title={glossary.ssp_deals_add_demand_popup_tab_my_demands}>
          <div
            className='modal-body p-3'
            style={{
              // maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            {skeletonTwo ? (
              <TableSkeleton columns={3} rows={5} rowSpacing={2} tableHeight={150} />
            ) : (
              <>
                <div className={filteredCustomers.length > 0 ? 'row pb-3' : 'd-none'}>
                  <span className='col-1'></span>
                  <span className='col'>
                    <strong>
                      {glossary.ssp_deals_add_demand_popup_tab_my_demands_title_demand_id}
                    </strong>
                  </span>
                  <span className='col'>
                    <strong>
                      {glossary.ssp_deals_add_demand_popup_tab_my_demands_title_demand_name}
                    </strong>
                  </span>
                </div>
                <div style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                  {noCustomers ? (
                    <div className='mb-3 text-center'>
                      {glossary.ssp_deals_add_demand_popup_no_demand_msg}
                    </div>
                  ) : filteredCustomers.length > 0 ? (
                    filteredCustomers.length > 0 &&
                    filteredCustomers.map((customer: any, index: any) => (
                      <div className='row mb-3 m-0' key={index}>
                        <div className='col-1'>
                          <Form.Check
                            name={'id'}
                            onChange={(e) => selectCustomer(customer.id, e)}
                            type='checkbox'
                            label={''}
                          />
                        </div>
                        <div className='col'>{customer.id}</div>
                        <div className='col'>{customer.name}</div>
                      </div>
                    ))
                  ) : (
                    <p className='text-center m-4'>
                      {glossary.ssp_deals_add_demand_popup_all_demand_selected_msg}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
          <div className='modal-footer px-3 gap-2'>
            <Button
              kind={'secondary'}
              text={glossary.close_btn}
              // ssp_deals_add_demand_popup_button_cancel
              click={() => onHide()}
            />
            {isLoading ? (
              <Button kind={'spinner'} type={'button'} text={'Adding...'} />
            ) : (
              <Button
                kind={selectedCustomers.length > 0 ? 'primary' : 'disabled'}
                text={glossary.add_btn}
                // ssp_deals_add_demand_popup_button_add
                click={() => {
                  addCustomers(id);
                }}
              />
            )}
          </div>
        </Tab>

        <Tab eventKey={'offer-deal'} title={glossary.ssp_deals_add_demand_popup_tab_offer_deal}>
          <div
            className='modal-body p-3'
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            <SpoCustomerList
              type={'spo-publisher'}
              show={show}
              dealID={id}
              getSelectedAgency={(agcy: any) => setSelectedAgenciesList(agcy)}
            />
          </div>
          <div className='modal-footer d-flex justify-content-between px-3'>
            <div className='d-flex align-items-center'>
              {needLogin ? (
                <>
                  <button
                    className='btn google-btn m-0 ps-2 pe-2'
                    type='button'
                    onClick={() => openGoogleLogin()}
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <p className='btn-text'>
                      <img
                        className='google-icon'
                        src='https://i.postimg.cc/PJVtc2LR/google-logo.png'
                      />
                      {glossary.login_button_google}
                    </p>
                  </button>

                  <MdInfoOutline className='svg-icon ms-2 me-1' fontSize={18} />
                  <p>To sent mail from your gmail account use google login.</p>
                </>
              ) : (
                <>
                  <img
                    className='google-icon me-2'
                    src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
                    width={25}
                    height={25}
                  />
                  <p>
                    {`Selected Agencies will receive an offer deal from `}
                    <span className='fw-light'>{mailSentEmail}</span>
                  </p>
                </>
              )}

              {/* Logout Button */}
              {!needLogin && (
                <Button
                  text={'Logout'}
                  type={'button'}
                  kind={'secondary'}
                  customClass='ms-3'
                  click={() => handleGoogleLogout()}
                />
              )}
            </div>

            <div className='d-flex gap-3'>
              <Button
                text={glossary.close_btn}
                kind={'secondary'}
                type={'button'}
                click={() => onHide()}
              />
              <Button
                text={skeletonThree ? glossary.loading : 'Offer Deal'}
                kind={
                  selectedAgenciesList.length > 0
                    ? skeletonThree
                      ? 'spinner'
                      : 'primary'
                    : 'disabled-secondary'
                }
                click={() => offerDealToAgencies(id)}
              />
            </div>
          </div>
        </Tab>
      </Tabs>
    </Modal>
  );
};
