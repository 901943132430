import { Bar } from 'react-chartjs-2';

export default (props: any) => {
  const root = document.documentElement;
  const primary1 = getComputedStyle(root).getPropertyValue('--primary-1');
  const primary2 = getComputedStyle(root).getPropertyValue('--primary-2');
  const primary3 = getComputedStyle(root).getPropertyValue('--primary-3');
  const primary4 = getComputedStyle(root).getPropertyValue('--primary-4');
  const primary5 = getComputedStyle(root).getPropertyValue('--primary-5');

  const bgColor = [primary1, primary2, primary3, primary4, primary5];

  const { data } = props;
  const values = {
    labels: data ? data.labels : [],
    datasets: [
      {
        label: [],
        data: data ? data.values : [],
        backgroundColor: bgColor,
        // backgroundColor: [
        //   'rgb(18, 55, 135)',
        //   'rgb(29, 88, 215)',
        //   'rgb(81, 115, 251)',
        //   'rgb(32, 201, 150)',
        //   'rgb(99, 230, 190)',
        // ],
      },
    ],
  };
  const settings = {
    plugins: {
      legend: {
        display: false,
      },
    },
    indexAxis: 'y',
    borderWidth: 2,
    borderRadius: 5,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    animations: false,
    maintainAspectRatio: true,
  };
  // @ts-ignore
  return <Bar data={values} options={settings} />;
};
