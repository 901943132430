import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
// import { Range, getTrackBackground } from 'react-range';

import Button from './button';
// import Card from './card';
// import List from './list';
// import Input from './input';
// import Pill from './pill';

// import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
// import { ReactComponent as SizeIcon } from '../../assets/icons/size.svg';
// import { ReactComponent as CompactIcon } from '../../assets/icons/compact.svg';

// import sortDescendingIcon from '../../assets/icons/sort-za.svg';
// import sortAscendingIcon from '../../assets/icons/sort-az.svg';

// import { MdSearch } from 'react-icons/md';
// import { IoIosArrowForward, IoIosArrowBack, IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

// import CardLoader from '../loaders/card';
import { categories, platforms, countries, states, cities, sources } from '../helpers/dropdowns';
import { useLocation } from 'react-router';
import {
  getCountriesAPI,
  getStatesAPI,
  getCitiesAPI,
  // getInventoriesAPI,
} from '../../services/inventory';
import store from '../../states';
import { getCustomersAPI } from '../../services/customers';

import DropDown from '../widgets/dropdown';
// import CustomDropdown from './customDropdown';
import Dropdown from '../widgets/dropdown';

interface Props {
  title: string;
  kind: 'tag' | 'customer' | 'inventory' | 'deal' | 'dsp';
  dealTerms?(terms: any): void;
  setsubmitnotFound?(data: any): void;
  loading?: boolean;
}

export default (props: Props) => {
  const { title, kind, dealTerms, setsubmitnotFound, loading } = props;

  const { pathname } = useLocation();

  const [countriesList, sCountriesList] = useState<any>([]);
  const [selectedCountries, sSelectedCountries] = useState<any>([]);
  const [statesList, sStatesList] = useState<any>([]);
  const [selectedStates, sSelectedStates] = useState<any>([]);
  const [citiesList, sCitiesList] = useState<any>([]);
  const [selectedCities, sSelectedCities] = useState<any>([]);

  const [sourcesList, sSourcesList] = useState<any>([]);
  const [selectedSources, sSelectedSources] = useState<any>([]);

  const [selectedDeviceTypes, sSelectedDeviceTypes] = useState<any>([]);
  const [selectedPlatformTypes, sSelectedPlatformTypes] = useState<any>([]);

  const [selectedPlatformCategories, sSelectedPlatformCategories] = useState<any>([]);

  const [selectedCategories, sSelectedCategories] = useState<any>([]);

  const [searchsubmitDataMsg, setSearchsubmitDataMsg] = useState(false);

  const [lowerCpm, setLowerCpm] = useState<any>(0);
  const [upperCpm, setUpperCpm] = useState<any>(0);
  const [cpmError, setCpmError] = useState(false);
  const [newDealObj, setNewDealObj] = useState<any>();
  const [isApplying, setIsApplying] = useState(false);

  useEffect(() => {
    if (parseFloat(lowerCpm) > parseFloat(upperCpm)) {
      setCpmError(true);
    }
    if (parseFloat(lowerCpm) <= parseFloat(upperCpm)) setCpmError(false);
  }, [lowerCpm, upperCpm]);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (loading === false) {
      setIsApplying(false);
    }
  }, [loading]);

  //Hadling Float Value for CPM Start
  const step = 0.01;
  const max = 999.99;

  const handleLowerCpm = (e: any) => {
    const newValue = e.target.value;

    if (/^-?[0-9]*\.?[0-9]*$/.test(newValue) && newValue <= max) {
      setLowerCpm(newValue);
    } else {
      //ok
    }
  };

  const handleUpperCpm = (e: any) => {
    const newValue = e.target.value;

    if (/^-?[0-9]*\.?[0-9]*$/.test(newValue) && newValue <= max) {
      setUpperCpm(newValue);
    } else {
      //ok
    }
  };
  //Hadling Float Value for CPM End

  const handleCheckBoxes = (e: any) => {
    if (e.target.name.startsWith('deviceTypes')) {
      let newDeviceTypes = [...selectedDeviceTypes];
      if (e.target.name === 'deviceTypesTV') {
        if (e.target.checked) {
          newDeviceTypes.push('tv');
        } else {
          newDeviceTypes = newDeviceTypes.filter((x) => x !== 'tv');
        }
      }
      if (e.target.name === 'deviceTypesMobile') {
        if (e.target.checked) {
          newDeviceTypes.push('mobile');
        } else {
          newDeviceTypes = newDeviceTypes.filter((x) => x !== 'mobile');
        }
      }
      if (e.target.name === 'deviceTypesDesktop') {
        if (e.target.checked) {
          newDeviceTypes.push('desktop');
        } else {
          newDeviceTypes = newDeviceTypes.filter((x) => x !== 'desktop');
        }
      }
      sSelectedDeviceTypes(newDeviceTypes);
    }
    if (e.target.name.startsWith('platformTypes')) {
      let newPlatformTypes = [...selectedPlatformTypes];
      if (e.target.name === 'platformTypesOTT') {
        if (e.target.checked) {
          newPlatformTypes.push('ott');
        } else {
          newPlatformTypes = newPlatformTypes.filter((x) => x !== 'ott');
        }
      }
      if (e.target.name === 'platformTypesCTV') {
        if (e.target.checked) {
          newPlatformTypes.push('ctv');
        } else {
          newPlatformTypes = newPlatformTypes.filter((x) => x !== 'ctv');
        }
      }
      sSelectedPlatformTypes(newPlatformTypes);
    }
  };

  const onSubmitLoading = () => {
    if (setsubmitnotFound) setsubmitnotFound(searchsubmitDataMsg);
  };

  useEffect(() => {
    let newCountries = [],
      newStates = [],
      newCities = [],
      newCategories = [],
      newPlatformsCategories = [],
      newSources = [];
    if (selectedCountries.length > 0) {
      newCountries = selectedCountries;
    }
    if (selectedStates.length > 0) {
      newStates = selectedStates;
    }
    if (selectedCities.length > 0) {
      newCities = selectedCities;
    }
    if (selectedCategories.length > 0) {
      newCategories = selectedCategories.map(({ value }: any) => value);
    }
    if (selectedPlatformCategories.length > 0) {
      newPlatformsCategories = selectedPlatformCategories.map(({ value }: any) => value);
    }
    if (selectedSources.length > 0) {
      newSources = selectedSources.map(({ value }: any) => value);
    }
    setNewDealObj({
      ssp_id: newSources,
      p_cat: newPlatformsCategories, //Roku, Apple TV, Chrome OS //Add This
      lower_cpm: parseFloat(lowerCpm),
      upper_cpm: parseFloat(upperCpm),
      config: {
        'content.cat': newCategories, //IAB Categories
        'device.type': selectedDeviceTypes, //TV, Desktop, Mobile
        'platform.type': selectedPlatformTypes, //OTT, CTV
        'user.geo.country': newCountries, //Country
        'user.geo.region': newStates, //State
        'user.geo.city': newCities, //Cities
      },
    });
  }, [
    selectedPlatformCategories,
    selectedCategories,
    selectedDeviceTypes,
    selectedPlatformTypes,
    selectedCountries,
    selectedStates,
    selectedCities,
    selectedSources,
    lowerCpm,
    upperCpm,
  ]);

  useEffect(() => {
    if (pathname === '/inventory') getCustomers();
    getCountriesAPI()
      .then((data: any) => {
        sCountriesList(data.data);
      })
      .catch(() => {
        // ok
      });
  }, []);

  useEffect(() => {
    if (selectedCountries.length > 0) {
      const payload = {
        countries: selectedCountries.map(({ id }: any) => id),
      };
      getStatesAPI(payload)
        .then((data: any) => {
          sStatesList(data.data);
        })
        .catch(() => {
          // ok
        });
    } else {
      sStatesList([]);
      sSelectedStates([]);
    }
    const newArr: any[] = [];
    if (selectedCountries.length > 0) {
      selectedStates.forEach((e1: any) =>
        selectedCountries.forEach((e2: any) => {
          if (e1.country == e2.value) {
            newArr.push(e1);
          }
        })
      );
    }
    sSelectedStates(newArr);
  }, [selectedCountries]);

  useEffect(() => {
    if (selectedStates.length > 0) {
      const payload = {
        states: selectedStates.map(({ id }: any) => id),
      };
      getCitiesAPI(payload)
        .then((data: any) => {
          sCitiesList(data.data);
        })
        .catch(() => {
          // ok
        });
    } else {
      sCitiesList([]);
      sSelectedCities([]);
    }
    const newArr: any[] = [];
    if (selectedStates.length > 0) {
      selectedCities.forEach((e1: any) =>
        selectedStates.forEach((e2: any) => {
          if (e1.state == e2.value) {
            newArr.push(e1);
          }
        })
      );
    }
    sSelectedCities(newArr);
  }, [selectedStates]);

  const getCustomers = () => {
    const payload = {
      order: 'asc',
      order_by: 'created_on',
      query: '',
      // id: '',
    };
    getCustomersAPI(payload)
      .then((data: any) => {
        if (data) {
          sSourcesList(data.data);
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const [sourceList, sSourceList] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('customer_type') === 'demand') {
      sSourceList(true);
    }
  }, []);

  return (
    <>
      <div className='newsidebar'>
        <div className={'sidebar-row sidebar-row-first'}>
          <div className={'sidebar-col'}>
            <h3>{title}</h3>
          </div>
        </div>

        <div
          className='filter__sidebar'
          style={{ height: `calc(100vh - 160px)`, overflowY: 'scroll' }}
        >
          <div className='filter__sidebar__filed'>
            <DropDown
              kind={'multi'}
              label={glossary.admin_inv_sidebar_ddl_label_iab_category}
              name={'iabCategory'}
              option={categories}
              onchange={sSelectedCategories}
              search={true}
              value={selectedCategories} //optional
            />
          </div>
          <div className='filter__sidebar__filed d-flex flex-column justify-content-around'>
            <label className='filter__sidebar__label label'>
              {glossary.admin_inv_sidebar_field_label_device_type}
            </label>
            <Form.Group className='d-flex gap-2'>
              <Form.Check
                name={'deviceTypesTV'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label={glossary.admin_inv_sidebar_checkbox_tv}
                checked={selectedDeviceTypes.find((d_type: any) => {
                  if (d_type === 'tv') {
                    return true;
                  } else {
                    return false;
                  }
                })}
              />
              &nbsp;
              <Form.Check
                name={'deviceTypesDesktop'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label={glossary.admin_inv_sidebar_checkbox_desktop}
                checked={selectedDeviceTypes.find((d_type: any) => {
                  if (d_type === 'desktop') {
                    return true;
                  } else {
                    return false;
                  }
                })}
              />
              &nbsp;
              <Form.Check
                name={'deviceTypesMobile'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label={glossary.admin_inv_sidebar_checkbox_mobile}
                checked={selectedDeviceTypes.find((d_type: any) => {
                  if (d_type === 'mobile') {
                    return true;
                  } else {
                    return false;
                  }
                })}
              />
            </Form.Group>
          </div>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <label style={{ color: '#a2a2a2' }}>Platform Type</label>
            <Form.Group style={{ display: 'flex', columnGap: 5, marginTop: 5 }}>
              <Form.Check
                name={'platformTypesOTT'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label='OTT'
                checked={selectedPlatformTypes.find((d_cat: any) => {
                  if (d_cat === 'ott') {
                    return true;
                  } else {
                    return false;
                  }
                })}
              />
              &nbsp;
              <Form.Check
                name={'platformTypesCTV'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label='CTV'
                checked={selectedPlatformTypes.find((d_cat: any) => {
                  if (d_cat === 'ctv') {
                    return true;
                  } else {
                    return false;
                  }
                })}
              />
            </Form.Group>
          </div> */}
          <div className='filter__sidebar__filed d-flex flex-column'>
            <DropDown
              kind={'multi'}
              label={glossary.admin_inv_sidebar_ddl_label_platform}
              name={'iabCategory'}
              option={platforms}
              onchange={sSelectedPlatformCategories}
              search={true}
              value={selectedPlatformCategories} //optional
            />
          </div>
          <div className='filter__sidebar__filed floor__price d-flex justify-content-between my-3'>
            <div className='d-flex flex-column filter__sidebar__filed__groupe'>
              <label className='filter__sidebar__label label'>
                {glossary.admin_inv_sidebar_input_field_label_lower_floor}
              </label>
              <input
                type='text'
                value={lowerCpm}
                maxLength={5}
                onChange={handleLowerCpm}
                className='text-input-mini'
                placeholder={glossary.admin_inv_sidebar_input_lower_floor_placeholder}
                style={{ maxWidth: '150px', minWidth: '100px' }}
              />
            </div>
            <div className='d-flex flex-column filter__sidebar__filed__groupe'>
              <label className='filter__sidebar__label label'>
                {glossary.admin_inv_sidebar_input_field_label_upper_floor}
              </label>
              <input
                type='text'
                value={upperCpm}
                maxLength={5}
                onChange={handleUpperCpm}
                className='text-input-mini'
                placeholder={glossary.admin_inv_sidebar_input_upper_floor_placeholder}
                style={{ maxWidth: '150px', minWidth: '100px' }}
              />
              <p className={cpmError ? 'd-block text-input-error-label small' : 'd-none'}>
                Upper CPM more than lower
              </p>
            </div>
          </div>
          <div className='filter__sidebar__filed d-flex flex-column justify-content-center react-select-dropdown'>
            {/* <CustomDropdown
              label={glossary.ddl_countries}
              option={countries(countriesList)}
              value={selectedCountries}
              onSelect={sSelectedCountries}
              placeholder={glossary.ddl_countries_placeholder}
              isMulti
            /> */}
            <Dropdown
              kind='multi'
              label={glossary.ddl_countries}
              option={countries(countriesList)}
              onchange={sSelectedCountries}
              value={selectedCountries}
              search={true}
              selectAll={false}
            />
          </div>
          <div className='filter__sidebar__filed d-flex flex-column justify-content-center react-select-dropdown'>
            {/* <CustomDropdown
              label={glossary.ddl_states}
              option={states(statesList)}
              value={selectedStates}
              onSelect={sSelectedStates}
              placeholder={glossary.ddl_states_placeholder}
              isMulti
            /> */}
            <Dropdown
              kind='multi'
              label={glossary.ddl_states}
              option={states(statesList)}
              onchange={sSelectedStates}
              value={selectedStates}
              search={true}
              selectAll={false}
            />
          </div>
          <div className='filter__sidebar__filed d-flex flex-column react-select-dropdown'>
            {/* <CustomDropdown
              label={glossary.ddl_cities}
              option={cities(citiesList)}
              value={selectedCities}
              onSelect={sSelectedCities}
              placeholder={glossary.ddl_cities_placeholder}
              isMulti
              menuPlacement='top'
            /> */}
            <Dropdown
              kind='multi'
              label={glossary.ddl_cities}
              option={cities(citiesList)}
              onchange={sSelectedCities}
              value={selectedCities}
              search={true}
              selectAll={false}
            />
          </div>
          {!sourceList && (
            <div className='filter__sidebar__filed d-flex flex-column'>
              <DropDown
                kind='multi'
                name='sources'
                label={glossary.admin_inv_sidebar_ddl_label_sources}
                option={sources(sourcesList)}
                value={selectedSources}
                onchange={sSelectedSources}
                search={true}
              />
            </div>
          )}
          <div className='filter__sidebar__filed d-flex flex-column justify-content-end pb-1'>
            {isApplying ? (
              <Button text={'Applying...'} type={'button'} kind={'spinner'} />
            ) : (
              <Button
                click={() => {
                  if (dealTerms) dealTerms(newDealObj);
                  onSubmitLoading();
                  setIsApplying(true);
                }}
                kind={'primary'}
                text={glossary.admin_inv_sidebar_button_submit}
                type={'submit'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
