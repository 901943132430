import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { BsPlayFill } from 'react-icons/bs';
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import { AiFillInstagram } from 'react-icons/ai';
import { checkIdSequence } from '../../helpers/functions';
import Button from '../../widgets/button';

// const getItems = (count: any, offset = 0) =>
//   Array.from({ length: count }, (v, k) => k).map((k) => ({
//     id: `item-${k + offset}-${new Date().getTime()}`,
//     content: `item ${k + offset}`,
//   }));

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const root = document.documentElement;
const primary7 = getComputedStyle(root).getPropertyValue('--primary-7');

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 5, //grid * 2
  margin: `0 0 ${grid}px 0`,
  borderRadius: '5px',
  border: '1px solid #f9f9f9',

  // change background colour if dragging
  background: isDragging ? primary7 : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver: any) => ({
  // background: isDraggingOver ? 'lightgrey' : 'lightgrey', // 'lightblue' : 'lightgrey',
  boxShadow: '0 0 8px rgba(0,0,0,0.04)',
  padding: grid,
  width: 250,
});

function CardSorting({ cardList, formSubmit, getUpdatedList, mode, boxHeight }: any) {
  const [state, setState] = useState([cardList]);
  const [saveButton, setSaveButton] = useState(false);

  function onDragEnd(result: any) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      setSaveButton(true);
      const items = reorder(state[sInd], source.index, destination.index);
      const newState: any = [...state];
      newState[sInd] = items;
      setState(newState);
      getUpdatedList(...newState);
    }
    // else {
    // const result = move(state[sInd], state[dInd], source, destination);
    // const newState = [...state];
    // newState[sInd] = result[sInd];
    // newState[dInd] = result[dInd];
    // setState(newState.filter((group) => group.length));
    // }
  }

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'>
        <h4 className='mb-2'>Video Sequence</h4>
        {mode !== 'create' && (
          <div
            className='position-absolute'
            style={{
              top: '-15px',
              right: '10px',
            }}
          >
            {!saveButton ? (
              <Button kind={'disabled'} text={'Save'} type='button' />
            ) : (
              <Button
                kind={checkIdSequence(state) ? 'primary' : 'disabled'}
                text={'Save'}
                type='button'
                click={() => {
                  setSaveButton(false);
                  formSubmit();
                }}
              />
            )}
          </div>
        )}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        {state.map((el, ind) => (
          <Droppable key={ind} droppableId={`${ind}`} direction='vertical'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
                style={{
                  boxShadow: '0 0 8px rgba(0,0,0,0.04)',
                  padding: grid,
                  width: 250,
                  // height: `calc(100vh - 260px)`,
                  height: `${boxHeight ?? '602px'}`,
                  overflowY: 'scroll',
                }}
                {...provided.droppableProps}
              >
                {el.map((item: any, index: any) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div className='d-flex align-items-center position-relative'>
                          <div className='d-flex align-items-center flex-column top-0 position-absolute'>
                            <b>{index + 1}</b>
                            <PiDotsSixVerticalBold fontSize={20} className='svg-icon' />
                          </div>
                          <div className='ms-4 video-col'>
                            {/* <BsPlayFill
                                className='play-icon'
                                fontSize={26}
                                color='#fff'
                                onClick={() => {
                                  // handleVideo(row?.original?.video_url);
                                }}
                              /> */}
                            {/* <span className='duration'>{'30 Sec'}</span> */}
                            <img
                              src={item?.video_thumbnail_s}
                              alt={item?.video_thumbnail_s}
                              height={70}
                              width={45}
                              onClick={() => {
                                // handleVideo(row?.original?.video_url);
                              }}
                              style={{ objectFit: 'cover' }}
                            />
                          </div>

                          <div className='ms-2 d-flex justify-content-between w-100'>
                            <div>
                              <label
                                style={{ color: '#a2a2a2', fontSize: '12px', lineHeight: '14px' }}
                              >
                                Added On:
                              </label>
                              <p style={{ fontSize: '12px', lineHeight: '14px' }}>
                                {item?.import_date}
                              </p>
                              <label
                                style={{ color: '#a2a2a2', fontSize: '12px', lineHeight: '14px' }}
                              >
                                Duration:
                              </label>
                              <p style={{ fontSize: '12px', lineHeight: '14px' }}>
                                {item?.duration}
                              </p>
                            </div>
                            <div>
                              <AiFillInstagram className='s-svg-icon' fontSize={16} />
                            </div>
                            {/* <label
                              style={{ color: '#a2a2a2', fontSize: '12px', lineHeight: '14px' }}
                            >
                              From:
                            </label>
                            <p style={{ fontSize: '12px', lineHeight: '14px' }}>Instagram</p> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </div>
  );
}

export default CardSorting;

// This below code for Remove card directly from sequences

{
  /* <button
  type='button'
  onClick={() => {
  const newState = [...state];
  newState[ind].splice(index, 1);
  setState(newState.filter((group) => group.length));
  }}
>
  delete
</button>  */
}
