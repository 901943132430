import React, { useState, createContext, useContext } from 'react';
import { headers, routes } from '../../services/constants';

interface MyContextType {
  demandOverviewData: any;
  setDemandOverviewData: any;
  demandOverviewTilesData: any;
  setDemandOverviewTilesData: any;

  inventoryOverviewData: any;
  setInventoryOverviewData: any;
  inventoryOverviewTilesData: any;
  setInventoryOverviewTilesData: any;

  dealsOverviewData: any;
  setDealsOverviewData: any;
  dealsOverviewTilesData: any;
  setDealsOverviewTilesData: any;

  partnersOverviewData: any;
  setPartnersOverviewData: any;
  partnersOverviewTilesData: any;
  setPartnersOverviewTilesData: any;

  tags: any;
  sTags: any;

  endPointData: any;
  sEndPointData: any;

  publisherData: any;
  sPublisherData: any;

  dealsList: any;
  setDealsList: any;

  requestedDealList: any;
  sRequestedDealList: any;

  spoPartner: any;
  sSpoPartner: any;

  reqDealList: any;
  sReqDealList: any;

  customers: any;
  sCustomers: any;

  inventories: any;
  sInventories: any;

  publisherInventories: any;
  setPublisherInventories: any;

  genreList: any;
  setGenreList: any;

  countriesList: any;
  sCountriesList: any;

  deamndInventories: any;
  sDemandInventories: any;

  demandGenreList: any;
  sDemandGenreList: any;

  demandCountriesList: any;
  sDemandCountriesList: any;

  dealInventories: any;
  sDealInventories: any;

  needLogin: any;
  setNeedLogin: any;

  deals: any;
  sDeals: any;

  adminGenreList: any;
  sAdminGenreList: any;

  adminCountriesList: any;
  sAdminCountriesList: any;

  inventoryTilesData: any;
  sInventoryTilesData: any;

  trafficTotalData: any;
  sTrafficTotalData: any;

  trafficTableData: any;
  sTrafficTableData: any;

  bidderListData: any;
  sBidderListData: any;

  sDateRangeInventoryOverview: any;
  dateRangeInventoryOverview: any;

  sDateRangeSourcesOverview: any;
  dateRangeSourcesOverview: any;

  sDateRangePartnersOverview: any;
  dateRangePartnersOverview: any;

  sDateRangeMyDealsOverview: any;
  dateRangeMyDealsOverview: any;

  dateRangeInventory: any;
  sDateRangeInventory: any;

  dateRangeSources: any;
  sDateRangeSources: any;

  dateRangePartners: any;
  sDateRangePartners: any;

  adsTxtDataPublisher: any;
  sAdsTxtDataPublisher: any;

  dateRangeSPOPublisher: any;
  sDateRangeSPOPublisher: any;

  dateRangeDemand: any;
  sDateRangeDemand: any;

  dateRangeCustomer: any;
  sDateRangeCustomer: any;

  dateRangeTraffic: any;
  sDateRangeTraffic: any;

  clearAllStates: any;
}

export const OverViewContext = createContext<MyContextType | undefined>(undefined);

function useAuthentication() {
  return {
    demandOverviewAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.demandOverview, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    inventoryOverviewAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.overview, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    dealsOverviewAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.dealOverview, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    partnerOverviewAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.partnerOverview, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getTagsListAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getTags, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getEndpointsListAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getEndpointList, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getPartnersListAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.customerListSSP, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getMyDelasListAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getDeals, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getReqestDelasListAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getRequestedDealList, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getPublishersSpoListAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.customerListSPO, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getCustomersAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getCustomers, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getInventoriesAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getInventories, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getPublisherInventoriesAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getPublisherInventories, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getRtbGenreListAPI() {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'GET',
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getRtbGenreList, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getCountriesAPI() {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        key: 'fa763f0c3465b71b2fde7ef40add574a8981149319d6d419f433ecba5c77a1f4',
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getCountries, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getDemandInventoriesAPI() {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'GET',
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getPublishersList, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getRequestedDealInventoriesAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getRequestedDealInventories, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    HBLoginUrlAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.HBLoginUrl, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getInventoryOverviewAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.inventoryOverview, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getTrafficDashboardAPI(payload: any) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getTrafficDashboard, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getBidderListAPI() {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'GET',
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getBidderList, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getPublisherAdstxtAPI() {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
      const options = {
        headers,
        method: 'GET',
      };
      return new Promise((resolve, reject) => {
        fetch(routes.getPublisherAdstxt, options)
          .then((data) => data.json())
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
  };
}

export const OverviewDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [demandOverviewData, setDemandOverviewData] = useState({});
  const [demandOverviewTilesData, setDemandOverviewTilesData] = useState([]);

  const [inventoryOverviewData, setInventoryOverviewData] = useState({});
  const [inventoryOverviewTilesData, setInventoryOverviewTilesData] = useState([]);

  const [dealsOverviewData, setDealsOverviewData] = useState({});
  const [dealsOverviewTilesData, setDealsOverviewTilesData] = useState([]);

  const [partnersOverviewData, setPartnersOverviewData] = useState({});
  const [partnersOverviewTilesData, setPartnersOverviewTilesData] = useState([]);

  const [tags, sTags] = useState([]);
  const [endPointData, sEndPointData] = useState<any>([]);
  const [publisherData, sPublisherData] = useState<any>([]);
  const [dealsList, setDealsList] = useState<any>([]);
  const [requestedDealList, sRequestedDealList] = useState<any>([]);
  const [spoPartner, sSpoPartner] = useState<any>([]);
  const [reqDealList, sReqDealList] = useState<any>([]);
  const [customers, sCustomers] = useState<any>([]); // customer list for admin
  const [inventories, sInventories] = useState<any>([]); // admin inventories list
  const [publisherInventories, setPublisherInventories] = useState([]); // publisher inventories list
  const [genreList, setGenreList] = useState([]); // get genre list in publisher
  const [countriesList, sCountriesList] = useState([]); // get country list
  const [deamndInventories, sDemandInventories] = useState([]); // get inventories demand side
  const [demandGenreList, sDemandGenreList] = useState([]); // get genre list in publisher
  const [demandCountriesList, sDemandCountriesList] = useState([]); // get country list
  const [dealInventories, sDealInventories] = useState<any>([]);
  const [needLogin, setNeedLogin] = useState(false);
  const [deals, sDeals] = useState<any>([]); // admin deals
  const [adminGenreList, sAdminGenreList] = useState([]); // get genre list in publisher
  const [adminCountriesList, sAdminCountriesList] = useState([]); // get country list
  const [inventoryTilesData, sInventoryTilesData] = useState<any>([]); // Tile data for admin
  const [trafficTotalData, sTrafficTotalData] = useState<any>([]); // traffic data admin side
  const [trafficTableData, sTrafficTableData] = useState<any>([]); // traffic data admin side
  const [bidderListData, sBidderListData] = useState<any>({}); // header bidder list
  const [adsTxtDataPublisher, sAdsTxtDataPublisher] = useState<any>();

  const generateInitialDateRange = () => ({
    startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
    endDate: new Date(),
    key: 'selection',
  });

  const [dateRangeInventoryOverview, sDateRangeInventoryOverview] = useState(
    generateInitialDateRange()
  );

  const [dateRangeSourcesOverview, sDateRangeSourcesOverview] = useState(
    generateInitialDateRange()
  );

  const [dateRangePartnersOverview, sDateRangePartnersOverview] = useState(
    generateInitialDateRange()
  );

  const [dateRangeMyDealsOverview, sDateRangeMyDealsOverview] = useState(
    generateInitialDateRange()
  );

  const [dateRangeInventory, sDateRangeInventory] = useState(generateInitialDateRange());

  const [dateRangeSources, sDateRangeSources] = useState(generateInitialDateRange());

  const [dateRangePartners, sDateRangePartners] = useState(generateInitialDateRange());

  const [dateRangeSPOPublisher, sDateRangeSPOPublisher] = useState(generateInitialDateRange());

  const [dateRangeDemand, sDateRangeDemand] = useState(generateInitialDateRange());

  const [dateRangeCustomer, sDateRangeCustomer] = useState(generateInitialDateRange());

  const [dateRangeTraffic, sDateRangeTraffic] = useState(generateInitialDateRange());

  const clearAllStates = () => {
    setDemandOverviewData({});
    setDemandOverviewTilesData([]);
    setInventoryOverviewData({});
    setInventoryOverviewTilesData([]);
    setDealsOverviewData({});
    setDealsOverviewTilesData([]);
    setPartnersOverviewData({});
    setPartnersOverviewTilesData([]);
    sTags([]);
    sEndPointData([]);
    sPublisherData([]);
    setDealsList([]);
    sRequestedDealList([]);
    sSpoPartner([]);
    sReqDealList([]);
    sCustomers([]);
    sInventories([]);
    setPublisherInventories([]);
    setGenreList([]);
    sCountriesList([]);
    sDemandInventories([]);
    sDemandGenreList([]);
    sDemandCountriesList([]);
    sDealInventories([]);
    setNeedLogin(false);
    sDeals([]);
    sAdminGenreList([]);
    sAdminCountriesList([]);
    sInventoryTilesData([]);
    sTrafficTotalData([]);
    sTrafficTableData([]);
    sBidderListData({});
    sAdsTxtDataPublisher(undefined);
    sDateRangeInventoryOverview(generateInitialDateRange());
    sDateRangeSourcesOverview(generateInitialDateRange());
    sDateRangePartnersOverview(generateInitialDateRange());
    sDateRangeMyDealsOverview(generateInitialDateRange());
    sDateRangeInventory(generateInitialDateRange());
    sDateRangeSources(generateInitialDateRange());
    sDateRangePartners(generateInitialDateRange());
    sDateRangeSPOPublisher(generateInitialDateRange());
    sDateRangeDemand(generateInitialDateRange());
    sDateRangeCustomer(generateInitialDateRange());
    sDateRangeTraffic(generateInitialDateRange());
  };

  return (
    <OverViewContext.Provider
      value={{
        ...useAuthentication(),

        demandOverviewData,
        setDemandOverviewData,
        demandOverviewTilesData,
        setDemandOverviewTilesData,

        inventoryOverviewData,
        setInventoryOverviewData,
        inventoryOverviewTilesData,
        setInventoryOverviewTilesData,

        dealsOverviewData,
        setDealsOverviewData,
        dealsOverviewTilesData,
        setDealsOverviewTilesData,

        partnersOverviewData,
        setPartnersOverviewData,
        partnersOverviewTilesData,
        setPartnersOverviewTilesData,

        tags,
        sTags,

        endPointData,
        sEndPointData,

        publisherData,
        sPublisherData,

        dealsList,
        setDealsList,

        requestedDealList,
        sRequestedDealList,

        spoPartner,
        sSpoPartner,

        reqDealList,
        sReqDealList,

        customers,
        sCustomers,

        inventories,
        sInventories,

        publisherInventories,
        setPublisherInventories,

        genreList,
        setGenreList,

        countriesList,
        sCountriesList,

        deamndInventories,
        sDemandInventories,

        demandGenreList,
        sDemandGenreList,

        demandCountriesList,
        sDemandCountriesList,

        dealInventories,
        sDealInventories,

        needLogin,
        setNeedLogin,

        deals,
        sDeals,

        adminGenreList,
        sAdminGenreList,

        adminCountriesList,
        sAdminCountriesList,

        inventoryTilesData,
        sInventoryTilesData,

        trafficTotalData,
        sTrafficTotalData,

        trafficTableData,
        sTrafficTableData,

        bidderListData,
        sBidderListData,

        sDateRangeInventoryOverview,
        dateRangeInventoryOverview,

        sDateRangeSourcesOverview,
        dateRangeSourcesOverview,

        sDateRangePartnersOverview,
        dateRangePartnersOverview,

        sDateRangeMyDealsOverview,
        dateRangeMyDealsOverview,

        dateRangeInventory,
        sDateRangeInventory,

        dateRangeSources,
        sDateRangeSources,

        dateRangePartners,
        sDateRangePartners,

        adsTxtDataPublisher,
        sAdsTxtDataPublisher,

        dateRangeSPOPublisher,
        sDateRangeSPOPublisher,

        dateRangeDemand,
        sDateRangeDemand,

        dateRangeCustomer,
        sDateRangeCustomer,

        dateRangeTraffic,
        sDateRangeTraffic,

        clearAllStates,
      }}
    >
      {children}
    </OverViewContext.Provider>
  );
};

export default function Consumer() {
  return useContext(OverViewContext);
}
