import React, { useEffect, useMemo, forwardRef, useRef, useState } from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  //useBlockLayout,
  useFlexLayout,
  useMountedLayoutEffect,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import scrollbarWidth from './scrollbarWidth';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import defaultGlobalFilter from '../helpers/DefaultGlobalFilter';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
  const defaultRef: any = useRef();
  const resolvedRef: any = ref || defaultRef;
  const [checked, setChecked] = useState(rest.checked);

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChecked = (event: any) => {
    // setChecked(!checked);
    // rest.onChange(event);
    if (rest?.row?.check_box) {
      rest.row.check_box = !checked;
      setChecked(!checked);
      rest.onChange(event);
    } else {
      setChecked(!checked);
      rest.onChange(event);
    }
  };

  return (
    <>
      <input
        type='checkbox'
        ref={resolvedRef}
        {...rest}
        onClick={handleChecked}
        checked={checked}
      />
    </>
  );
});

// const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   const [glossary, sGlossary] = useState<any>({});
//   useEffect(() => {
//     sGlossary(store.getState());
//     const unsubscribe = store.subscribe(() => {
//       sGlossary(store.getState());
//     });
//     return () => unsubscribe();
//   }, []);

//   return (
//     <div className='search-wrap mt-0'>
//       {/* <label className='label mb-0 me-1'>Search: </label>{' '} */}
//       <input
//         value={value || ''}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         //placeholder={`${count} records...`}
//         placeholder={'Search Data'}
//         className='form-control'
//       />
//     </div>
//   );
// };

const Table = ({ columns: userColumns, data, onSelectedRowsChange, globalFilter }: any) => {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      //minWidth: 30, // minWidth is only used as a limit for resizing
      //width: 150, // width is used for both the flex-basis and flex-grow
      //maxWidth: 200, // maxWidth is only used as a limit for resizing
      // Filter: DefaultColumnFilter, // Let's set up our default Filter UI
      width: 150,
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);
  const filterOptions = { filteredIds: [] };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    //selectedRowIds,
    totalColumnsWidth,
    state: { selectedRowIds },
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: userColumns,
      data,
      getSubRows: (row) => row.subRows || [],
      initialState: {
        //@ts-ignore
        globalFilter,
      },
      globalFilter: (rows: any, columnIds: any, filterValue: any) =>
        defaultGlobalFilter(rows, columnIds, filterValue, filterOptions),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    useRowSelect,
    useFlexLayout
    //useBlockLayout
  );

  React.useEffect(() => {
    setGlobalFilter(globalFilter || undefined);
  }, [globalFilter]);

  /* pass props selected row data */
  useMountedLayoutEffect(() => {
    const selectedIds = Object.keys(selectedRowIds);
    const selectedRowsData = selectedIds
      .map((x) => data[x])
      .filter(function (x) {
        return x != null;
      });

    const rowIds: any = [];
    //const childRowIds: any = [];

    selectedFlatRows.forEach((row: any) => {
      rowIds.push(row?.original?.id);
      // rowIds.push(
      //   row?.original?.map(function (id: any) {
      //     console.log('Inner', row);
      //     return id?.a_id;
      //   })
      // );
    });
    data.forEach((x: any) => {
      if (x.check_box == true) {
        rowIds.push(x.id);
      } else {
        rowIds.push();
      }
    });

    // selectedFlatRows.forEach((row: any) => {
    //   return childRowIds.push(row?.original?.a_id); //Before childRowIds
    // });

    onSelectedRowsChange(rowIds?.flat(Infinity));
    //onSelectedRowsChange(childRowIds?.flat(Infinity));
  }, [onSelectedRowsChange, selectedRowIds, data]);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className='tr'
        >
          {row.cells.map((cell: any, index: any) => {
            return (
              <div {...cell.getCellProps()} className='td' key={index} title={cell.value}>
                <div
                  className={
                    cell?.column?.Header == 'Ad Unit' ||
                    cell?.column?.Header == 'Code' ||
                    cell?.column?.Header == 'Parent'
                      ? 'macros-truncate'
                      : ''
                  }
                >
                  {cell.render('Cell')}
                </div>
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds, selectedFlatRows]
  );

  return (
    <>
      {/* <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}
      <div className='table-wrap mt-0'>
        <div {...getTableProps()} className='table inventories mb-0'>
          <div className='table-header'>
            {headerGroups.map((headerGroup: any, index: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    className='th'
                    key={index}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoMdArrowDropdown className='svg-icon' fontSize={20} />
                        ) : (
                          <IoMdArrowDropup className='svg-icon' fontSize={20} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className='table-body' {...getTableBodyProps()}>
            <FixedSizeList
              height={800}
              itemCount={rows.length}
              itemSize={35}
              width={totalColumnsWidth + scrollBarSize}
              style={{ width: '100%', height: `350px`, overflowX: 'hidden' }}
            >
              {RenderRow}
            </FixedSizeList>
          </div>
        </div>
      </div>
    </>
  );
};

// const handleChildInventories = (inventoriesArr: any) => {
//   const aIdArray: any = [];
//   inventoriesArr.map((inventory: any) => {
//     aIdArray.push(inventory.a_id);
//   });
//   return aIdArray;
// };

const GAMEndpoints = (GAMData: any) => {
  const [globalFilter, setGlobalFilter] = React.useState('');

  const columns = useMemo(
    () => [
      {
        expander: true,
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <MdOutlineKeyboardArrowDown className='svg-icon' fontSize={18} />
            ) : (
              <MdOutlineKeyboardArrowRight className='svg-icon' fontSize={18} />
            )}
          </span>
        ),
        Cell: ({ row }: any) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row

          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {/* {console.log("row", row)} */}
              {row.isExpanded ? (
                <MdOutlineKeyboardArrowDown className='svg-icon' fontSize={18} />
              ) : (
                <MdOutlineKeyboardArrowRight className='svg-icon' fontSize={18} />
              )}
            </span>
          ) : null,
        width: 20,
      },
      {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className='table-checkbox'>
            {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }: any) => {
          return (
            <>
              <div className='table-checkbox'>
                {row.original.available ? (
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    checked={row.original.available}
                    row={row.original}
                  />
                ) : (
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    // checked={row.original.check_box}
                    // row={row.original}
                  />
                )}
              </div>
            </>
          );
        },
        width: 20,
      },
      {
        Header: 'Ad Unit',
        accessor: 'name',
        width: 100,
      },
      {
        Header: 'Code',
        accessor: 'adUnitCode',
        width: 100,
      },
      {
        Header: 'Network',
        accessor: 'network',
        width: 70,
      },
      {
        Header: 'Parent',
        accessor: 'parent',
        width: 100,
      },
    ],
    []
  );

  const data = GAMData.rows;

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    GAMData && GAMData?.selectedRowData(selectedRows);
  }, [GAMData, selectedRows]);

  const onChange = (e: any) => setGlobalFilter(e.target.value);

  return (
    <>
      <div className='search-wrap mt-0'>
        <input
          value={globalFilter}
          onChange={onChange}
          placeholder={`Search Ad Unit`}
          className='form-control'
          maxLength={32}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        expandedRows={true}
        //subRowsKey='inventories'
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        globalFilter={globalFilter}
      />
    </>
  );
};

export default GAMEndpoints;
