import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import validator from 'validator';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga4';
import JWT from 'jsonwebtoken';

import Input from '../widgets/input';
import Button from '../widgets/button';

import { signupAPI, findTeamsAPI, loginWithGoogleAPI } from '../../services/authentication';
import store from '../../states';
import { Link } from 'react-router-dom';

import ButtonR from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useGoogleLogin } from '@react-oauth/google';

interface SignupForm {
  email: string;
}

const signupFormInitialValues = {
  email: '',
};

const signupFormInitialErrors: SignupForm = {
  email: '',
};

export default () => {
  const { push } = useHistory();

  const [glossary, sGlossary] = useState<any>({});
  const [teams, sTeams] = useState([]);
  const [errors, sErrors] = useState<SignupForm>(signupFormInitialErrors);
  const [signupForm, sSignupForm] = useState<SignupForm>(signupFormInitialValues);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [emailID, sEmailID] = useState('');

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const updateSignupFormValues = (key: string, value: any) => {
    sErrors(signupFormInitialErrors);
    const newSignupForm = { ...signupForm };
    newSignupForm[key as keyof typeof signupFormInitialValues] = value;
    sSignupForm(newSignupForm);
  };

  const validateSignupform = () => {
    let valid = true;
    const newErrors = { ...errors };
    if (!validator.isEmail(signupForm.email)) {
      valid = false;
      newErrors['email'] = glossary.signup_input_validation_error;
    }
    if (validator.isEmpty(signupForm.email)) {
      valid = false;
      newErrors['email'] = glossary.signup_input_field_empty_error;
    }
    if (valid) {
      return true;
    } else {
      sErrors(newErrors);
      return false;
    }
  };

  const initiateSignup = () => {
    ReactGA.event({
      category: 'signup',
      action: 'click',
      label: 'create_account_button',
    });
    if (validateSignupform()) {
      const payload = {
        email: signupForm.email,
      };
      setLoadingBtn(true);
      findTeamsAPI(payload)
        .then((data: any) => {
          if (data && !data.user_exist && data.companies.length > 0) {
            sTeams(data.companies);
          } else if (data && data.user_exist) {
            push('/login', {
              email: data.email,
            });
            setLoadingBtn(false);
          } else {
            push('/signup-pass-dsp', { email: signupForm.email });
          }
        })
        .catch(() => {
          toast.error('Service unavailable, Check back after sometime.');
        });
    }
  };

  const joinTeam = (id: any) => {
    ReactGA.event('signup_join_team', {
      category: 'signup',
      action: 'click',
      label: 'join_team_button',
      email: signupForm.email,
    });
    const payload = {
      email: signupForm.email,
      //name: '',
      //publisher_name: '',
      //company_name: '',
      //website_url: '',
      //traffic: '',
      customer_id: id,
      //customer_type: 'demand',
      //country: '',
      //state: '',
      //city: '',
    };
    signupAPI(payload)
      .then((data: any) => {
        if (data.status) {
          ReactGA.event('signup_join_team_status', {
            category: 'signup',
            action: 'api_response',
            label: data.status,
          });
          push('/signup-done');
        } else {
          toast.error(data.message);
          ReactGA.event('signup_join_team_api_error', {
            category: 'signup',
            action: 'api_response',
            label: data.status,
          });
        }
      })
      .catch(() => {
        toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const [scope, setScope] = useState('');
  const [scopeCode, setScopeCode] = useState('');

  // const BASE_URL = process.env.REACT_APP_API_URL ? 'https://app.infy.tv' : 'https://sbapp.infy.tv';

  useEffect(() => {
    if (scope) {
      const payload = {
        code: scopeCode,
        redirect_uri: window.location.origin,
        scope: scope,
      };
      loginWithGoogleAPI(payload)
        .then((data: any) => {
          const email = data.res.user_data.email;
          sEmailID(email);
          const companySize = data.res.customer_list;
          if (data.res.login === false && data.res.status === true && companySize.length > 0) {
            sTeams(companySize);
          } else {
            push('/signup-pass-dsp', { email: email });
          }
          // if (data.res.login === true && data.res.status === true) {
          //   const token = data.res.access_token;
          //   localStorage.setItem('token', token.access_token);
          //   if (token) {
          //     const tokenPayload: any = JWT.decode(token.access_token);
          //     localStorage.setItem('role_id', tokenPayload?.role);
          //     if (tokenPayload?.admin) {
          //       localStorage.setItem('r', 'infy-admin');
          //       push('/customers', { email: data.res.user_data.email, companySize: companySize });
          //     } else {
          //       localStorage.setItem('r', 'customer');
          //       localStorage.setItem('customer_type', token.customer_type);
          //       if (data.customer_type === 'demand' || token.customer_type === '') {
          //         push('/sources/demand');
          //       } else {
          //         localStorage.setItem('customer_type', token.customer_type);
          //         push('/publisher/supply');
          //       }
          //     }
          //   }
          // }
          if (data.error === 400) {
            toast.error(data.message);
          } else {
            toast.success(data.message);
          }
        })
        .catch((error: any) => {
          toast.error(error);
        });
    }
  }, [scope]);

  const scopes =
    'openid email profile https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/dfp';

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: scopes,
    onSuccess: async (codeResponse) => {
      setScopeCode(codeResponse.code);
      setScope(codeResponse.scope);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <>
      <div className='signup__wrap'>
        <div className='signup__wrap__left'>
          <div className='signup__wrap__left__box card-box'>
            <h3 style={{ fontWeight: 300 }} className='signup__wrap__left__box__padding'>
              {teams.length === 0 && glossary.signup_title}
              {teams.length > 0 && glossary.signup_join_title}
            </h3>
            <p className='signup__wrap__left__box__padding'>
              {teams.length === 0 &&
                `Create a Demand account and begin to grow your CTV Advertising Revenue.`}
              {teams.length > 0 &&
                `Looks like your team already has an account with us. Click “Join” to send the request to your Admin.`}
            </p>
            {teams.length === 0 && (
              <>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    initiateSignup();
                  }}
                  noValidate
                >
                  <div style={{ marginBottom: 16 }}>
                    <Input
                      name={'email'}
                      kind='text'
                      label={glossary.signup_email_field_title}
                      change={updateSignupFormValues}
                      type={'email'}
                      length={320}
                      error={errors.email}
                      placeholder={glossary.signup_email_field_placeholder}
                    />
                  </div>
                  {!loadingBtn ? (
                    <>
                      <Button
                        size={'full'}
                        type={'submit'}
                        kind={'primary'}
                        text={glossary.signup_button}
                      />
                      <button
                        className='btn google-btn'
                        type='button'
                        onClick={() => {
                          googleLogin();
                        }}
                      >
                        <p className='btn-text'>
                          <img
                            className='google-icon'
                            src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
                          />
                          {glossary.signup_button_google}
                        </p>
                      </button>
                    </>
                  ) : (
                    <ButtonR
                      className='button button-loading w-100 d-flex align-items-center justify-content-center'
                      disabled
                    >
                      <Spinner
                        className='me-2'
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                      Loading...
                    </ButtonR>
                  )}
                </form>
              </>
            )}
            {teams.length > 0 && (
              <>
                <div className='signup__wrap__left__box__wrap'>
                  {teams.map((team: any) => {
                    return (
                      <>
                        <div className='signup__wrap__left__box__row'>
                          <div className='d-flex align-items-center'>
                            <img className='me-3' src={team.thumb} width={50} height={50} />
                            <p className='signup__wrap__left__box__title me-3'>
                              {team.company_name}
                              <small className='signup__wrap__left__box__subtitle'>
                                {team?.customer_type}
                              </small>
                            </p>
                          </div>
                          <p
                            className='signup__wrap__left__box__link'
                            onClick={() => joinTeam(team.id)}
                          >
                            {glossary.signup_hyperlink}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>
                <Button
                  size={'full'}
                  kind={'primary'}
                  click={() => {
                    push('/signup-pass-dsp', { email: signupForm.email });
                    ReactGA.event({
                      category: 'signup',
                      action: 'click',
                      label: 'startnew_button',
                    });
                  }}
                  text={glossary.signup_startnew_button}
                />
              </>
            )}
            <p className=' d-md-none'>
              {glossary.signup_header_text}
              <Link to='/login' className='d-inline-block fw-lighter ms-1'>
                {glossary.signup_header_button}
              </Link>
            </p>
          </div>
        </div>
        <div className='signup__wrap__right dsp' />
      </div>
    </>
  );
};
