import { Modal } from 'react-bootstrap';
import VideoExtention from '../components/extension/Create';
import AddReel from '../components/add-reel/add-reel';

const ExtensionModalDetails = (props: any) => {
  const { show, onHide, selectedTagDetails, pSection, createTag, getTags } = props;

  return (
    <Modal
      centered
      size={'xl'}
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      {/* <Modal.Header className='px-3'>
        <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>Extension Details</h3>
      </Modal.Header> */}
      <Modal.Body className={'p-0'}>
        <AddReel 
           pSection={pSection}
           createTag={createTag}
           selectedTagDetails={selectedTagDetails}
           onHide={onHide}
           getTags={getTags}
        />
        {/* <VideoExtention
          pSection={pSection}
          createTag={createTag}
          selectedTagDetails={selectedTagDetails}
          onHide={onHide}
          getTags={getTags}
        /> */}
      </Modal.Body>
    </Modal>
  );
};

export default ExtensionModalDetails;
