import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Card, Form } from 'react-bootstrap';
// import { AiOutlineAppstore } from 'react-icons/ai';
import { TbWorld } from 'react-icons/tb';
import { VscSymbolKeyword } from 'react-icons/vsc';
import { getPartnersFilterListAPI } from '../../../services/spo';
// import { GrTechnology } from 'react-icons/gr';
import store from '../../../states';
import { firstLetterCap } from '../../helpers/functions';

const DiscoverFilter = ({ getFilter }: any) => {
  // Glossary
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const [filteredCountries, setFilteredCountries] = useState([]);
  // const [filteredTechnologies, setFilteredTechnologies] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  // const [filteredCategories, setFilteredCategories] = useState([]);

  const [filtersList, setFilterList] = useState<any>({});

  const getPartnersFilter = () => {
    getPartnersFilterListAPI()
      .then((data: any) => {
        if (data.status) {
          setFilteredCountries(data?.data?.country);
          // setFilteredTechnologies(data?.data?.technology);
          setFilteredKeywords(data?.data?.keywords);
          // setFilteredCategories(data?.data?.category);
          setFilterList(data?.data);
        }
      })
      .catch((error) => {
        // error
      });
  };

  useEffect(() => {
    getPartnersFilter();
  }, []);

  const [selectedCountries, setSelectedCountries] = useState<any>([]);
  // const [selectedTechnologies, setSelectedTechnologies] = useState<any>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<any>([]);
  // const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [filterKeys, setFilterKeys] = useState({
    country: [],
    keyword: [],
    technology: [],
    category: [],
  });

  useEffect(() => {
    getFilter(filterKeys);
  }, [filterKeys]);

  /* Filtering Data Handle */
  const searchData = useMemo(() => {
    return (e: any) => {
      const { name, value } = e.target;
      if (name === 'country') {
        const filteredItems = filtersList?.country?.filter((item: any) =>
          item.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCountries(filteredItems);
      }
      // if (name === 'technology') {
      //   const filteredItems = filtersList?.technology?.filter((item: any) =>
      //     item.toLowerCase().includes(value.toLowerCase())
      //   );
      //   setFilteredTechnologies(filteredItems);
      // }
      if (name === 'keyword') {
        const filteredItems = filtersList?.keywords?.filter((item: any) =>
          item.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredKeywords(filteredItems);
      }
      // if (name === 'category') {
      //   const filteredItems = filtersList?.category?.filter((item: any) =>
      //     item.toLowerCase().includes(value.toLowerCase())
      //   );
      //   setFilteredCategories(filteredItems);
      // }
    };
  }, [filtersList]);

  /* Checkboxes Handle */

  const memoizedCountriesCheckBoxes = useMemo(() => {
    return (e: any) => {
      const { name, checked } = e.target;
      setSelectedCountries((prevSelectedCountries: any) => {
        const newCountries = checked
          ? [...prevSelectedCountries, name]
          : prevSelectedCountries.filter((x: any) => x !== name);

        setFilterKeys((prevFilterKeys: any) => ({
          ...prevFilterKeys,
          country: newCountries,
        }));
        return newCountries;
      });
    };
  }, []);

  // const memoizedTechnologyCheckBoxes = useMemo(() => {
  //   return (e: any) => {
  //     const { name, checked } = e.target;
  //     setSelectedTechnologies((prevSelectedTechnologies: any) => {
  //       const newTechnologies = checked
  //         ? [...prevSelectedTechnologies, name]
  //         : prevSelectedTechnologies.filter((x: any) => x !== name);

  //       setFilterKeys((prevFilterKeys: any) => ({
  //         ...prevFilterKeys,
  //         technology: newTechnologies,
  //       }));
  //       return newTechnologies;
  //     });
  //   };
  // }, []);

  const memoizedKeywordCheckBoxes = useMemo(() => {
    return (e: any) => {
      const { name, checked } = e.target;
      setSelectedKeywords((prevSelectedKeywords: any) => {
        const newKeywords = checked
          ? [...prevSelectedKeywords, name]
          : prevSelectedKeywords.filter((x: any) => x !== name);

        setFilterKeys((prevFilterKeys: any) => ({
          ...prevFilterKeys,
          keyword: newKeywords,
        }));
        return newKeywords;
      });
    };
  }, []);

  // const memoizedCategoryCheckBoxes = useMemo(() => {
  //   return (e: any) => {
  //     const { name, checked } = e.target;
  //     setSelectedCategories((prevSelectedCategories: any) => {
  //       const newCategories = checked
  //         ? [...prevSelectedCategories, name]
  //         : prevSelectedCategories.filter((x: any) => x !== name);

  //       setFilterKeys((prevFilterKeys: any) => ({
  //         ...prevFilterKeys,
  //         category: newCategories,
  //       }));
  //       return newCategories;
  //     });
  //   };
  // }, []);

  return (
    <>
      <div className='spo-discoverby' style={{ marginTop: '19px' }}>
        <Accordion defaultActiveKey='0'>
          {/* Country */}
          <div className='mb-2'>
            <Accordion.Toggle className='w-100 m-0 accordion-title' eventKey='0'>
              <div className='d-flex gap-2 align-items-center'>
                <TbWorld fontSize={18} className='svg-icon' />
                <label className='mt-1'>
                  {glossary.spo_invite_popup_filter_field_country} ({selectedCountries.length})
                </label>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body className='px-2 accordion-body'>
                <input
                  name='country'
                  type='search'
                  placeholder='Search here...'
                  onChange={searchData}
                />
                <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                  {filteredCountries.map((item: any, index: any) => {
                    const isChecked = selectedCountries?.includes(item);
                    return (
                      <Form.Check
                        key={index}
                        name={item}
                        type='checkbox'
                        label={item}
                        onChange={memoizedCountriesCheckBoxes}
                        checked={isChecked}
                      />
                    );
                  })}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </div>

          {/* Technology */}
          {/* <div className='mb-2'>
            <Accordion.Toggle className='w-100 m-0 accordion-title' eventKey='1'>
              <div className='d-flex gap-2 align-items-center'>
                <GrTechnology fontSize={18} className='svg-icon' />
                <label className='mt-1'>
                  {glossary.spo_invite_popup_filter_field_technology} ({selectedTechnologies.length}
                  )
                </label>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='1'>
              <Card.Body className='px-2 accordion-body'>
                <input
                  name='technology'
                  type='search'
                  placeholder='Search here...'
                  onChange={searchData}
                />
                <div style={{ maxHeight: '180px', overflowY: 'auto' }}>
                  {filteredTechnologies.map((item: any, index: any) => {
                    const isChecked = selectedTechnologies?.includes(item);
                    return (
                      <Form.Check
                        key={index}
                        name={item}
                        type='checkbox'
                        label={item}
                        onChange={memoizedTechnologyCheckBoxes}
                        checked={isChecked}
                      />
                    );
                  })}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </div> */}

          {/* Keywords */}
          <div className='mb-2'>
            <Accordion.Toggle className='w-100 m-0 accordion-title' eventKey='2'>
              <div className='d-flex gap-2 align-items-center'>
                <VscSymbolKeyword fontSize={18} className='svg-icon' />
                <label className='mt-1'>
                  {glossary.spo_invite_popup_filter_field_keyword} ({selectedKeywords.length})
                </label>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='2'>
              <Card.Body className='px-2 accordion-body'>
                <input
                  name='keyword'
                  type='search'
                  placeholder='Search here...'
                  onChange={searchData}
                />
                <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                  {filteredKeywords.map((item: any, index: any) => {
                    const isChecked = selectedKeywords?.includes(item);
                    return (
                      <Form.Check
                        key={index}
                        name={item}
                        type='checkbox'
                        label={firstLetterCap(item)}
                        onChange={memoizedKeywordCheckBoxes}
                        checked={isChecked}
                      />
                    );
                  })}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </div>

          {/* Category */}
          {/* <div className='mb-2'>
            <Accordion.Toggle className='d-flex gap-2 w-100 m-0 accordion-title' eventKey='5'>
              <div className='d-flex gap-2 align-items-center'>
                <AiOutlineAppstore fontSize={18} className='svg-icon' />
                <label className='mt-1'>
                  {glossary.spo_invite_popup_filter_field_category} ({selectedCategories.length})
                </label>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='5'>
              <Card.Body className='px-2 accordion-body'>
                <input
                  name='category'
                  type='search'
                  placeholder='Search here...'
                  onChange={searchData}
                />
                <div style={{ maxHeight: '180px', overflowY: 'auto' }}>
                  {filteredCategories.map((item: any, index: any) => {
                    const isChecked = selectedCategories?.includes(item);
                    return (
                      <Form.Check
                        key={index}
                        name={item}
                        type='checkbox'
                        label={item}
                        onChange={memoizedCategoryCheckBoxes}
                        checked={isChecked}
                      />
                    );
                  })}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </div> */}
        </Accordion>
      </div>
    </>
  );
};

export default DiscoverFilter;
