import { GoLinkExternal } from 'react-icons/go';
import AdminSettingsCustomerTable from '../../helpers/adminSettingCusManage';
import CustomerDetailsManage from '../../helpers/customersDetailsManage';
import EarningByPartner from '../../helpers/EarningByPartner';
import FinanceReceivableManage from '../../helpers/financeReceivable';
import InviteCustomerColumn from '../../helpers/InviteCustomerColumn';
import MacrosTableManage from '../../helpers/macrosTableManage';
import PayoutRequestManage from '../../helpers/payoutRequestManage';
import PlatformCostTable from '../../helpers/PlatformCostManage';
import ReportingTable from '../../helpers/ReportingTableManage';
import SettingsUser from '../../helpers/settingsUsers';
import SpendingByPartner from '../../helpers/SpendingByPartner';
import SspFinancePayoutManage from '../../helpers/sspFinancePayoutManage';
import { MdDeleteOutline } from 'react-icons/md';

const InfyActionColumn = (props: any) => {
  const {
    columnKey,
    data,
    apiCallBack,
    tableName,
    customerId,
    reportTableType,
    selectedCardId,
    toggleInclude,
    toggleExclude,
    actionController, // This props use in Coretable
  } = props;

  return (
    <>
      {tableName === 'Macros' && (
        <MacrosTableManage accessor={columnKey} row={data.row.original} apiCallBack={apiCallBack} />
      )}
      {tableName === 'Platform Cost' && (
        <PlatformCostTable accessor={columnKey} row={data.row.original} controller={apiCallBack} />
      )}
      {tableName === 'Earning-By-Partner' && (
        <EarningByPartner accessor={columnKey} row={data.row.original} controller={apiCallBack} />
      )}
      {tableName === 'Spending-By-Partner' && (
        <SpendingByPartner accessor={columnKey} row={data.row.original} controller={apiCallBack} />
      )}

      {tableName === 'Receivables' && (
        <FinanceReceivableManage
          accessor={columnKey}
          row={data.row.original}
          controller={apiCallBack}
        />
      )}
      {tableName === 'Admin Customers' && (
        <AdminSettingsCustomerTable
          accessor={columnKey}
          row={data.row.original}
          getCustomers={apiCallBack}
        />
      )}
      {tableName === 'Admin User' && (
        <SettingsUser
          accessor={columnKey}
          row={data.row.original}
          rowsId={data.row.id}
          getUsersList={apiCallBack}
        />
      )}
      {tableName === 'Payout Requests' && (
        <PayoutRequestManage accessor={columnKey} row={data.row.original} api={apiCallBack} />
      )}
      {tableName === 'Report' && (
        <ReportingTable
          accessor={columnKey}
          rowsId={data.row.id}
          row={data.row.original}
          tableType={reportTableType}
          customerId={customerId}
          selectedCardId={selectedCardId}
          toggleInclude={toggleInclude}
          toggleExclude={toggleExclude}
          //videoModal={videoModal}
          //video={video}
        />
      )}
      {tableName === 'Admin Customer Details' && (
        <CustomerDetailsManage
          accessor={columnKey}
          rowsId={data.row.id}
          row={data.row.original}
          selectedCustomer={selectedCardId}
          getManagersList={apiCallBack}
        />
      )}
      {tableName === 'Finance Payout' && (
        <SspFinancePayoutManage accessor={columnKey} row={data.row.original} />
      )}
      {tableName === 'invite-customer' && (
        <InviteCustomerColumn accessor={columnKey} row={data.row.original} />
      )}

      {tableName === 'MyDeals-AssignedTo' && columnKey === 'action' && (
        <MdDeleteOutline
          className='svg-icon'
          fontSize={20}
          onClick={() =>
            actionController({ id: data.row.original.id, from: data.row.original.from })
          }
          title={'Remove'}
        />
      )}

      {tableName === 'PMPDeals-Customers' && (
        <>
          {selectedCardId?.publishers === 0 ? (
            <MdDeleteOutline
              className='svg-icon'
              fontSize={20}
              onClick={() =>
                actionController({ customerID: data.row.original.id, dealID: selectedCardId.id })
              }
              title={'Remove'}
            />
          ) : (
            <>-</>
          )}
        </>
      )}
    </>
  );
};

export default InfyActionColumn;
