import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import VastTag from '../../components/VAST/VAST-create';
import SSAIEndpoint from '../../components/ssai/SSAIEndpoint';
//import VideoExtention from '../../components/extension/Create';
import AddReel from '../../components/add-reel/add-reel';

const PublisherOnbording = (props: any) => {
  const { pSection } = props;
  return (
    <>
      <div className={'content on-bording'} style={{ padding: 36 }}>
        {/* Create Video Extension */}
        {/* <div className='mb-4 light-shadow rounded-1 createtag tour-create-videoextension'>
          <VideoExtention pSection={pSection} createTag={true} initialCreate={true} />
        </div> */}

        <Tabs defaultActiveKey='addReel' className='on-bording-tabs'>
          <Tab eventKey='addReel' title='AdReel'>
            {/* Create AdReel Tag */}
            <div className='mb-4 light-shadow rounded-1 createtag'>
              <AddReel pSection={pSection} createTag={true} initialCreate={true} />
            </div>
          </Tab>
          <Tab eventKey='vastTag' title='VAST Tag'>
            {/* Create VAST Tag */}
            <div className='mb-4 light-shadow rounded-1 createtag'>
              <VastTag pSection={pSection} createTag={true} initialCreate={true} />
            </div>
          </Tab>
          <Tab eventKey='ssai' title='SSAI'>
            {/* Create SSAI End Point */}
            <div className='mb-4 light-shadow rounded-1 createtag'>
              <SSAIEndpoint pSection={pSection} createTag={true} initialCreate={true} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default PublisherOnbording;
