import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tab } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import store from '../../../states';

import {
  editDealAPI,
  getDealInventoriesAPI,
  sspGetInventoryOverviewAPI,
} from '../../../services/inventory';
import { sspAssignedDealDemandListAPI } from '../../../services/tags';
import { getRequestedDealListAPI } from '../../../services/deals';

import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';

import DeleteDeal from '../../modals/delete-deal';
import PublisherDeal from '../../modals/publisher-deal';
import RequestedDealDetails from '../../helpers/requestedDealDetails';
import SspDealsSidebar from '../../sidebar/SspDeals';
import SspDealCreate from '../../modals/ssp-deal';
import DeleteAssignDeal from '../../modals/delete-assign-deal';
import DeleteAssignCustomer from '../../modals/customer-assign-delete';

import Box from '../../loaders/box';
import TableSkeleton from '../../loaders/table';
import Button from '../../widgets/button';
import Tile from '../../widgets/tile';
import DealsOverview from '../../components/overview/deals';

import { inventory as inventoryTiles } from '../../helpers/tiles';

import add from '../../../assets/icons/add.svg';
import NewInvTable from '../../tables/NewInvTable';
import Coretable from '../../tables/Coretable';

interface Props {
  sSearchToggleProp: any;
}

const MyDealsPage = (props: Props) => {
  const {
    getMyDelasListAPI,
    getPublisherInventoriesAPI,
    getRtbGenreListAPI,
    getCountriesAPI,
  }: any = useAuthentication();
  const {
    dealsList,
    setDealsList,
    publisherInventories,
    setPublisherInventories,
    genreList,
    setGenreList,
    countriesList,
    sCountriesList,
  }: any = consumerData();

  const location = useLocation();
  const history = useHistory();

  const [empty, setEmpty] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState<any>('');
  const [inventories, setInventories] = useState({
    columns: [],
    data: [],
  });
  const [customersList, setCustomersList] = useState([]);
  const [assignedReqDeal, setAssignedReqDeal] = useState([]);
  const [dealDetails, setDealDetails] = useState<any>({});
  const [inventoryTilesData, sInventoryTilesData] = useState<any>([]);
  const [createDealModal, setCreateDealModal] = useState(false);
  const [dealEditModal, setDealEditModal] = useState(false);
  const [deleteDealModal, setDeleteDealModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [dealConfig, setDealConfig] = useState<any>({});
  const [isDealInvLoading, setIsDealInvLoading] = useState(true);
  const [isInvUpdating, setIsInvUpdating] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(false);
  //const [sidebarTab, setSidebarTab] = useState('1');
  const [sidebar, setSidebar] = useState<'collapsed' | 'expanded'>('collapsed');
  const [editInv, setEditInv] = useState(false);
  const [selectedTabSources, setSelectedTabSources] = useState('performace');
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);
  const [overviewCall, setOverviewCall] = useState(false);

  const handleSourcesTabs = (key: any) => {
    setSelectedTabSources(key);
  };

  /***** My Deals Section *****/

  /* Handle on scroll API's start */
  const [currentPage, sCurrentPage] = useState(0);
  const [hasMore, sHasMore] = useState(true);

  const [params, setParams] = useState({
    page: 0,
    query: '',
  });
  const [paramsStatus, setParamsStatus] = useState(false);

  const handleParamsChange = (page: number, query: string, status: boolean) => {
    if (page !== params.page || query !== params.query) {
      setParams({ page, query });
      setParamsStatus(status);
    }
    if (params.query !== query) {
      setDealsList([]);
    }
  };

  // const myDealId = location.pathname.split('/publisher/deals/my-deals/')[1];
  useEffect(() => {
    const myDealId = location.pathname.split('/publisher/deals/my-deals/')[1];
    if (dealsList.length === 0 || params.query === '') {
      getDeals(params, parseInt(myDealId));
    }
    if (paramsStatus === true) {
      getDeals(params, parseInt(myDealId));
    }
  }, [params.page, params.query, paramsStatus]);
  /* Handle on scroll API's end */

  /* Get My Deals List */
  const getDeals = (params: any, _id: any) => {
    const payload = {
      page: params.page,
      page_size: 500,
      query: params.query,
      sort_by: {
        sortBy: [
          { id: 'publishers', desc: false },
          { id: 'impressions', desc: true },
        ],
      },
    };
    sHasMore(true);
    setIsCardLoading(true);
    getMyDelasListAPI(payload)
      .then((data: any) => {
        if (data) {
          sCurrentPage(params.page);
          if (params.page > 0) {
            if (data.data.length !== 0) {
              setDealsList([...dealsList, ...data.data]);
            } else {
              sHasMore(false);
              setDealsList(dealsList);
              setEmpty(data.data.length < 1);
            }
          } else {
            setDealsList(data.data);
            setEmpty(data.data.length < 1);
            //if (data.data.length === 0) setSidebarTab('2');

            if (_id) {
              if (params.query !== '') {
                setSelectedDeal('');
              } else {
                selectDeal(_id);
              }
            }
          }
          setIsCardLoading(false);
        }
      })
      .catch(() => {
        //
      });
  };

  const selectDeal = (id: any) => {
    if (selectedDeal !== id) {
      history.push(`/publisher/deals/my-deals/${id}`);
      handleSourcesTabs('performance');
      setSelectedDeal(id);
      setEditInv(false);
      getDealInventories(id);
      inventoryOverview(id);
      getCustomersList(id);
      getAssignedReqDeals(id);
    }
  };

  useEffect(() => {
    setDealDetails(dealsList?.find((x: any) => x.id === selectedDeal) ?? {});
    setDealConfig(dealsList?.find((x: any) => x.id === selectedDeal)?.config ?? {});
  }, [selectedDeal, dealsList]);

  /* Handle New Added & Updated Deals list */
  const handleDealsList = (dealObject: any, kind: string) => {
    const newDealObject = { ...dealObject.data };
    if (kind === 'add') {
      const newArr = [newDealObject, ...dealsList];
      setDealsList(newArr);
      setDealDetails(newDealObject);
      setDealConfig(newDealObject.config);
      selectDeal(newDealObject.id);
    }

    if (kind === 'update') {
      const newArray = [...dealsList];
      const index = newArray.findIndex((item) => item.id === newDealObject.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...newDealObject };
      }
      setDealsList(newArray);
      setDealDetails(newDealObject);
      setDealConfig(newDealObject.config);
      selectDeal(newDealObject.id);
    }
  };

  const handleDeleteReqDeal = (id: number) => {
    setDealsList((prevData: any) => {
      const indexToRemove = prevData.findIndex((item: any) => item.id === id);
      const newData = [...prevData.slice(0, indexToRemove), ...prevData.slice(indexToRemove + 1)];
      if (indexToRemove < prevData.length - 1) {
        const nextId = prevData[indexToRemove + 1].id;
        selectDeal(nextId);
      } else if (indexToRemove > 0) {
        const prevId = prevData[indexToRemove - 1].id;
        selectDeal(prevId);
      } else {
        selectDeal('');
      }

      return newData;
    });
  };

  /* Get Customers List */
  const getCustomersList = (id: number) => {
    sspAssignedDealDemandListAPI({
      id: id ?? selectedDeal,
    })
      .then((data: any) => {
        setCustomersList(data?.data ?? []);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const getAssignedReqDeals = (id: any) => {
    const payload = {
      deal_id: id,
    };
    getRequestedDealListAPI(payload)
      .then((data: any) => {
        if (data.data) {
          setAssignedReqDeal(data.data ?? []);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const getDealInventories = (id: any) => {
    const payload = {
      deal_id: id,
    };
    setIsDealInvLoading(true);
    getDealInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          setInventories(data);
          setIsDealInvLoading(false);
        }
      })
      .catch((err: any) => {
        // console.log('error', err);
      });
  };

  /* Inventories Overview Tile */
  const inventoryOverview = (id: any) => {
    const payload = {
      deal_id: id,
    };
    sspGetInventoryOverviewAPI(payload)
      .then((data: any) => {
        if (data) {
          const tileData = inventoryTiles(data?.data ?? []);
          sInventoryTilesData(tileData);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const dealInventoriesUpdate = (id: number, invId: any) => {
    const payload = {
      id: id,
      inventories_ids: invId,
    };
    setIsInvUpdating(true);
    editDealAPI(payload)
      .then((data: any) => {
        if (data) {
          getDealInventories(id);
          inventoryOverview(id);
          toast.success(glossary.ssp_deals_update_inv_list_success_msg);
          setEditInv(false);
          setIsInvUpdating(false);
        }
      })
      .catch((error: any) => {
        toast.error('Something went wrong, please try after later.');
        setIsInvUpdating(false);
      });
  };

  const handleInventoriesId = (data: any) => {
    setSelectedRow(data);
  };

  const dealCreate = () => {
    setCreateDealModal(!createDealModal);
  };

  useEffect(() => {
    if (publisherInventories.length === 0) {
      getAllInventories();
    }
    if (genreList.length === 0) {
      getGenreList();
    }
    if (countriesList.length === 0) {
      getCountriesList();
    }
  }, []);

  const getAllInventories = () => {
    const payload = {
      iab_category: [],
      device_type: [],
      platform_type: [],
      platform_category: [],
      country: [],
      state: [],
      city: [],
    };
    getPublisherInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          setPublisherInventories(data?.data);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  // Get Genere list
  const getGenreList = () => {
    getRtbGenreListAPI()
      .then((data: any) => {
        if (data) {
          setGenreList(data.data);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  // Get Countries List
  const getCountriesList = () => {
    getCountriesAPI()
      .then((data: any) => {
        sCountriesList(data.data);
      })
      .catch(() => {
        //
      });
  };

  /* Sidebar Searchbar */
  const [searchToggle, sSearchToggle] = useState<any>(true);
  const getSearchToggle = (status: any) => {
    sSearchToggle(status);
  };
  props.sSearchToggleProp(searchToggle);

  /* delete assing deal */
  const [requestDealId, setRequestDealId] = useState('');
  const [showRequestDealModal, setShowRequestDealModal] = useState(false);
  const removeRequestDeal = (id: any) => {
    setShowRequestDealModal(true);
    setRequestDealId(id);
  };

  /* delete assing customer */
  const [customerDealId, setCustomerDealId] = useState('');
  const [showCustomerDealModal, setShowCustomerDealModal] = useState(false);
  const removeCustomer = (id: any) => {
    setShowCustomerDealModal(true);
    setCustomerDealId(id);
  };

  const assignToColumn = [
    {
      key: 'id',
      sort: true,
      title: 'ID',
      type: 'number',
    },
    {
      key: 'name',
      sort: true,
      title: 'Assign To',
      type: 'string',
    },
    {
      key: 'from',
      sort: true,
      title: 'Deal/Customer',
      type: 'string',
    },
    {
      key: 'action',
      sort: false,
      sticky: true,
      title: 'Action',
      type: 'action',
    },
  ];

  const assignToRows = useMemo(() => {
    const customerRows = customersList.map(({ id, name }: any) => ({
      id,
      name,
      from: 'Customer',
    }));

    const dealRows = assignedReqDeal.map(({ id, name }: any) => ({
      id,
      name,
      from: 'Deal',
    }));

    return customerRows.concat(dealRows);
  }, [customersList, assignedReqDeal]);

  return (
    <>
      {/* delete assigned deal */}
      <DeleteAssignDeal
        id={requestDealId}
        selectedDeal={selectedDeal}
        onHide={() => setShowRequestDealModal(false)}
        show={showRequestDealModal}
        api={getAssignedReqDeals}
      />

      {/* delete assigned customer */}
      <DeleteAssignCustomer
        id={customerDealId}
        selectedDeal={selectedDeal}
        onHide={() => setShowCustomerDealModal(false)}
        show={showCustomerDealModal}
        api={getCustomersList}
      />

      {/* Create new deals */}
      <SspDealCreate
        show={createDealModal}
        onHide={() => {
          setCreateDealModal(false);
        }}
        dealTerms={{}}
        dealTermsConfig={{}}
        section={'1'}
        updateList={handleDealsList}
        publisherInventories={publisherInventories}
        genreList={genreList}
        countriesList={countriesList}
      />

      {/* Edit existing deals */}
      <SspDealCreate
        show={dealEditModal}
        onHide={() => {
          setDealEditModal(false);
        }}
        dealTerms={dealDetails}
        dealTermsConfig={dealConfig}
        section={'2'}
        updateList={handleDealsList}
        publisherInventories={publisherInventories}
        genreList={genreList}
        countriesList={countriesList}
        edit
      />

      {/* Delete deals */}
      <DeleteDeal
        id={dealDetails.id}
        onHide={() => setDeleteDealModal(false)}
        show={deleteDealModal}
        api={handleDeleteReqDeal}
      />

      <PublisherDeal
        id={dealDetails.id}
        onHide={() => setCustomerModal(false)}
        show={customerModal}
        addedCustomers={customersList.map(({ id }: any) => id)}
        addedRequestedDeal={assignedReqDeal.map(({ id }: any) => id)} // Pending
        api={getCustomersList}
        dealapi={getAssignedReqDeals}
      />

      {/* Sidebar Tabs */}
      <SspDealsSidebar
        title={glossary.ssp_deals_sidebar_title}
        kind={'deal'}
        size={sidebar}
        card={'expanded'}
        items={dealsList}
        sidebar={() => null}
        action={{
          icon: add,
          text: 'Add New',
          click: dealCreate,
        }}
        overviewAction={{
          text: glossary.overview_button,
          click: () => {
            setSelectedDeal('');
            setOverviewCall(true);
            history.push('/publisher/deals/my-deals');
          },
          isOverview: location.pathname === '/publisher/deals/my-deals' ? true : false,
        }}
        empty={empty}
        info={selectDeal}
        selected={selectedDeal}
        currentPage={currentPage}
        loading={isCardLoading}
        hasMore={hasMore}
        params={params}
        handleParams={handleParamsChange}
        searchToggle={getSearchToggle}
        allData={true}
      />
      {/* {console.log("overviewCall", overviewCall)} */}
      <div className='view-section'>
        {selectedDeal === '' ? (
          <DealsOverview deals={dealsList} empty={empty} overviewCall={overviewCall} />
        ) : (
          <Tab.Container
            defaultActiveKey={'performance'}
            activeKey={selectedTabSources}
            onSelect={(key: any) => handleSourcesTabs(key)}
          >
            <div className='row align-items-center'>
              <div className='col-4'>
                <div className='d-flex gap-3 align-items-center'>
                  {selectedDeal !== '' && dealDetails && (
                    <>
                      <h2 className='text-truncate'>{dealDetails.name}</h2>
                      <MdDeleteOutline
                        className='svg-icon flex-shrink-0'
                        fontSize={22}
                        onClick={() => {
                          setDeleteDealModal(true);
                        }}
                        title={glossary.admin_deal_inventory_delete_icon_title}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className='col-4'>
                <Nav variant='pills'>
                  <Nav.Item>
                    <Nav.Link eventKey='performance'>{glossary.ssp_deals_tab_performance}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='details'>{glossary.ssp_deals_tab_inventory}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='assign'>{glossary.ssp_deals_tab_assigned_demand}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <div className='col-4 d-flex justify-content-end'>
                {selectedTabSources === 'assign' && (
                  <Button
                    kind={'primary'}
                    text={'Assign To'}
                    type={'button'}
                    click={() => setCustomerModal(true)}
                  />
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Tab.Content>
                  <Tab.Pane eventKey={'performance'}>
                    {selectedDeal !== '' && (
                      <div className={'row deal-performance ssp-deal-main'}>
                        <DealsOverview deals={dealsList} empty={empty} dealid={selectedDeal} />
                      </div>
                    )}
                  </Tab.Pane>

                  <Tab.Pane eventKey={'details'}>
                    <div className='row mb-2'>
                      <div className='col-6'>
                        <div className='row '>
                          {isDealInvLoading ? (
                            <>
                              <div
                                className='align-items-center justify-content-between'
                                style={{ marginTop: '54px' }}
                              >
                                <div className='col-6 d-flex gap-4'>
                                  {[1, 2, 3].map((index) => {
                                    return <Box key={index} />;
                                  })}
                                </div>
                              </div>
                            </>
                          ) : (
                            inventoryTilesData.length > 0 &&
                            inventoryTilesData.map((tile: any, index: any) => {
                              return (
                                <div
                                  key={index}
                                  className={'col-auto'}
                                  style={{ marginTop: '45px' }}
                                >
                                  <Tile
                                    onHover={'disabled'}
                                    id={tile.id}
                                    selected={99}
                                    click={() => null}
                                    label={tile.label}
                                    value={tile.value}
                                    info={tile.info}
                                    arrow={tile.arrow}
                                    deals
                                    type={'number'}
                                  />
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                      <div className='col-6  d-flex align-items-end justify-content-end mb-2'>
                        <Button
                          kind={'primary'}
                          text='Edit Deal'
                          type='button'
                          click={() => setDealEditModal(true)}
                        />
                      </div>
                    </div>

                    {/* Deal Details Section */}
                    <div className='col-12'>
                      <RequestedDealDetails
                        dealTerms={{
                          id: dealDetails?.id ?? '',
                          name: dealDetails?.name ?? '',
                          maxCpm: dealConfig?.max_cpm_formatted ?? '',
                          minCpm:
                            dealConfig.deal_type === 'pmp'
                              ? dealConfig?.min_cpm_formatted
                              : dealDetails?.cpm_formatted,
                          impression: dealConfig?.pg_impression ?? '',
                          requestedDealType: dealConfig.deal_type ?? 'pg',
                          description: dealConfig['content.description'] ?? '',
                          ctr: dealConfig['user.geo.country'] ?? [],
                          stt: dealConfig['user.geo.region'] ?? [],
                          cty: dealConfig['user.geo.city'] ?? [],
                          cat: dealConfig['content.cat'] ?? [],
                          dev: dealConfig['device.type'] ?? [],
                          p_type: dealConfig['platform.type'] ?? [],
                          artist: dealConfig['content.artist'] ?? '',
                          series: dealConfig['content.series'] ?? '',
                          title: dealConfig['content.title'] ?? '',
                          genre: dealConfig['content.genre'] ?? '',
                          isrc: dealConfig['content.isrc'] ?? '',
                          producerName: dealConfig['content.producer.name'] ?? '',
                          producerDomain: dealConfig['content.producer.domain'] ?? '',
                          contentrating: dealConfig['content.contentrating'] ?? '',
                          userrating: dealConfig['content.userrating'] ?? '',
                          qagmediarating: dealConfig['content.qagmediarating'] ?? '',
                          keywords: dealConfig['content.keywords'] ?? '',
                          len: dealConfig['content.len'] ?? '',
                          language: dealConfig['content.language'] ?? '',
                          networkDomain: dealConfig['content.network.domain'] ?? '',
                          networkName: dealConfig['content.network.name'] ?? '',
                          channelName: dealConfig['content.channel.name'] ?? '',
                          channelDomain: dealConfig['content.channel.domain'] ?? '',
                          context: dealConfig['content.context'] ?? '',
                          prodq: dealConfig['content.prodq'] ?? [],
                          livestream: dealConfig['content.livestream'] ?? [],
                          sourceRelationship: dealConfig['content.sourcerelationship'] ?? [],
                          embeddable: dealConfig['content.embeddable'] ?? [],
                          kwarray: dealConfig['content.kwarray'] ?? [],
                          applicationType: dealConfig['application.type'] ?? [],
                        }}
                        // newid={selectedReqDeal}
                        kind={'deal'}
                      />
                    </div>

                    {/* Deal Inventory Section */}
                    <div className='col-12' style={{ marginTop: '60px', position: 'relative' }}>
                      <h3>Inventories</h3>
                      <hr />
                      <div
                        className='row justify-content-end mb-2'
                        style={{ position: 'absolute', right: '0px', top: '70px' }}
                      >
                        <div className='col-auto d-flex gap-3'>
                          {editInv && !isInvUpdating && (
                            <>
                              <Button
                                kind={!isInvUpdating ? 'secondary' : 'disabled-secondary'}
                                text={'Cancel'}
                                type={'button'}
                                click={() => setEditInv(false)}
                              />

                              <Button
                                kind={selectedRow.length <= 0 ? 'disabled' : 'primary'}
                                text={'Save Changes'}
                                type={'button'}
                                click={() => {
                                  dealInventoriesUpdate(selectedDeal, selectedRow);
                                }}
                              />
                            </>
                          )}
                          {inventories?.data?.length > 0 && isInvUpdating && (
                            <Button kind={'spinner'} text='Saving...' type={'button'} />
                          )}
                        </div>
                      </div>
                    </div>

                    {isDealInvLoading ? (
                      <div style={{ marginTop: '80px' }}>
                        <TableSkeleton columns={6} rows={10} rowSpacing={2} tableHeight={400} />
                      </div>
                    ) : (
                      <>
                        {!editInv ? (
                          <Coretable
                            column={inventories?.columns}
                            ignoreCol={['excluded', 'check_box']}
                            data={inventories?.data?.filter((item: any) => item?.check_box) ?? []}
                            search={true}
                            heightCount={'390px'}
                            action={{
                              text: 'Edit Inventories',
                              click: () => setEditInv(true),
                            }}
                            tableName={'Inv-Read-Only'}
                          />
                        ) : (
                          <NewInvTable
                            data={inventories?.data ?? []}
                            handleSelect={handleInventoriesId}
                            isAdmin={false}
                          />
                        )}
                      </>
                    )}
                  </Tab.Pane>

                  <Tab.Pane eventKey={'assign'}>
                    {customersList.length === 0 && assignedReqDeal.length === 0 ? (
                      <div className={'text-center'} style={{ marginTop: '100px' }}>
                        <h3>{glossary.ssp_deals_no_demand_msg}</h3>
                        <p style={{ color: '#a2a2a2' }}>
                          {glossary.ssp_deals_no_demand_instruction}
                        </p>
                      </div>
                    ) : (
                      <>
                        <div style={{ marginTop: '55px' }}>
                          <Coretable
                            column={assignToColumn}
                            data={assignToRows}
                            search={true}
                            heightCount={'347px'}
                            ignoreCol={[]}
                            actionController={(_e: any) => {
                              if (_e.from === 'Deal') {
                                removeRequestDeal(_e.id);
                              } else {
                                removeCustomer(_e.id);
                              }
                            }}
                            tableName={'MyDeals-AssignedTo'}
                          />
                        </div>
                      </>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        )}
      </div>
    </>
  );
};

export default MyDealsPage;
