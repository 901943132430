import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import ProgressBar from '@ramonak/react-progress-bar';
import { Tab } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { BiEditAlt } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';

import Table from '../../widgets/table';
import Button from '../../widgets/button';
import Switch from '../../widgets/switch';
import Filter from '../../widgets/filter';

import Deal from '../../modals/deal';
import DeleteDeal from '../../modals/delete-deal';
import DealCustomer from '../../modals/customer-deal';

import InvetoryCard from '../../loaders/invetory-card';
import TableSkeleton from '../../loaders/table';

import { inventoryCustomers } from '../../helpers/tables';

import {
  getDealCustomersAPI,
  getDealsAPI,
  getInventoryCardsAPI,
  getDealInventoriesAPI,
  editDealAPI,
  removeDealCustomersAPI,
} from '../../../services/inventory';

import store from '../../../states';
// import DealsInventories from '../../tables/dealsInventories';
import DealsSidebar from '../../sidebar/Deals';
// import SSPInventories from '../../tables/sspinventories';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';
import NewInvTable from '../../tables/NewInvTable';
import Coretable from '../../tables/Coretable';

const ChartSection = ({ title, data }: any) => {
  return (
    <div
      style={{
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 14,
        borderRadius: 4,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.05)',
        height: '300px',
        overflowY: 'auto',
      }}
    >
      <h4>{title}</h4>
      {data &&
        data.length > 0 &&
        data.map((el: any, index: any) => {
          return (
            <div key={index}>
              <ProgressBar
                completed={parseFloat(el.monthly_imp_percent)}
                bgColor={'#731780'}
                height={'3px'}
                borderRadius={'0px'}
                baseBgColor={'#fff'}
                isLabelVisible={false}
                animateOnRender={true}
              />
              <p style={{ margin: 2, fontSize: 12 }}>
                <b>{el.name}</b> {el.monthly_imp_percent}%
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default () => {
  const { getMyDelasListAPI, getRtbGenreListAPI, getCountriesAPI }: any = useAuthentication();
  const {
    deals,
    sDeals,
    adminGenreList,
    sAdminGenreList,
    adminCountriesList,
    sAdminCountriesList,
  }: any = consumerData();

  const [sidebar, sSidebar] = useState<'collapsed' | 'expanded'>('collapsed');
  const [cards, sCards] = useState([]);
  //const [deals, sDeals] = useState<any>([]);
  const [selected, sSelected] = useState<any>('');
  const [show, sShow] = useState(false);
  const [cSshow, sCsShow] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [cardShow, setCardShow] = useState(false);
  const [cardloading, setCardLoading] = useState(true);
  const [tableSkeletonView, setTableSkeletonView] = useState(true);
  const [noDeal, setNoDeal] = useState(false);
  const [noDealInv, setNoDealInv] = useState(false);
  const [dealCustomers, sDealCustomers] = useState([]);
  const [delShow, sDelShow] = useState(false);
  const [dealTerms, setDealTeams] = useState({});
  const [dealCardLoading, sDealCardLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [inventories, setInventories] = useState({
    columns: [],
    data: [],
  });
  const [selectedInv, setSelectedInv] = useState([]);
  const [selectedTabSources, setSelectedTabSources] = useState('inventory');
  const [isSaving, setIsSaving] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const location = useLocation();
  const history = useHistory();

  const setSidebar = (size: 'collapsed' | 'expanded') => {
    sSidebar(size);
  };

  /***** Handle on scroll API's start *****/
  const [currentPage, sCurrentPage] = useState(0);
  const [hasMore, sHasMore] = useState(true);
  const [params, setParams] = useState({
    page: 0,
    query: '',
  });

  const [paramsStatus, setParamsStatus] = useState(false);
  const handleParamsChange = (page: number, query: string, status: boolean) => {
    if (page !== params.page || query !== params.query) {
      setParams({ page, query });
      setParamsStatus(status);
    }
    if (params.query !== query) {
      sDeals([]);
    }
  };

  useEffect(() => {
    const dealId = location.pathname.split('/inventory/deals/')[1];
    //getDeals(params, dealId);
    if (deals.length === 0 || params.query === '') {
      getDeals(params, dealId);
    } else {
      sSelected(deals[0]);
    }
    if (paramsStatus === true) {
      getDeals(params, dealId);
    }
  }, [params.page, params.query, paramsStatus]);
  /***** Handle on scroll API's end *****/

  const getDeals = (params: any, _id: any) => {
    const payload = {
      page: params.page,
      page_size: 500,
      query: params.query,
      sort_by: {
        sortBy: [
          { id: 'publishers', desc: false },
          { id: 'impressions', desc: true },
        ],
      },
    };
    sDealCardLoading(true);
    getMyDelasListAPI(payload)
      .then((data: any) => {
        if (data) {
          sCurrentPage(params.page);
          sDealCardLoading(false);
          if (params.page > 0) {
            if (data.data.length !== 0) {
              sDeals([...deals, ...data.data]);
            } else {
              //sSelected(deals[0]);
              sHasMore(false);
              sDeals(deals);
              setNoDeal(data.data.length < 1);
            }
          } else {
            sDeals(data.data);
            setNoDeal(data.data.length < 1);
            if (_id) {
              if (data.data.length > 0) sSelected(data.data.find((x: any) => x.id == _id));
            } else {
              if (data.data.length > 0) sSelected(data.data[0]);
            }
          }
        }
      })
      .catch(() => {
        //
      });
  };

  const updateDealTerms = (newObj: any) => {
    setDealTeams(newObj);
  };

  /***** Handle New Added & Updated Deals list START *****/
  const handleDealsList = (dealObject: any, kind: string) => {
    if (kind === 'update') {
      const newArray = [...deals];
      const index = newArray.findIndex((item) => item.id === dealObject.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...dealObject };
      }
      sDeals(newArray);
      sSelected(newArray.find((x: any) => x.id === dealObject.id));
    }
  };

  const handleDeleteReqDeal = (id: number) => {
    sDeals((prevData: any) => {
      const indexToRemove = prevData.findIndex((item: any) => item.id === id);
      const newData = [...prevData.slice(0, indexToRemove), ...prevData.slice(indexToRemove + 1)];
      if (indexToRemove < prevData.length - 1) {
        const nextId = prevData[indexToRemove + 1];
        sSelected(nextId);
      } else if (indexToRemove > 0) {
        const prevId = prevData[indexToRemove - 1];
        sSelected(prevId);
      } else {
        sSelected('');
      }

      return newData;
    });
  };
  /***** Handle New Added & Updated Deals list END *****/

  const getCustomers = () => {
    getDealCustomersAPI({
      id: selected.id,
    })
      .then((data: any) => {
        if (data.status) {
          sDealCustomers(data?.data ?? []);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const inventoryCards = (newObj?: any) => {
    let payload: any;
    if (newObj) {
      payload = {
        deal_id: selected.id,
        iab_category: newObj.cat,
        device_type: newObj.dev,
        platform_type: newObj.p_type,
        platform_category: newObj.p_cat,
        country: newObj.ctr,
        state: newObj.stt,
        city: newObj.cty,
        lower_cpm: newObj.lower_cpm,
        upper_cpm: newObj.upper_cpm,
        ssp_id: newObj.ssp_id,
        'content.artist': newObj.artist,
        'content.series': newObj.series,
        'content.title': newObj.title,
        'content.genre': newObj.genre,
        'content.isrc': newObj.isrc,
        'content.producer.name': newObj.producerName,
        'content.producer.domain': newObj.producerDomain,
        'content.prodq': newObj.prodq,
        'content.context': newObj.context,
        'content.contentrating': newObj.contentrating,
        'content.userrating': newObj.userrating,
        'content.qagmediarating': newObj.qagmediarating,
        'content.keywords': newObj.keywords,
        'content.kwarray': newObj.keywordsArray,
        'content.livestream': newObj.livestream,
        'content.sourcerelationship': newObj.sourceRelationship,
        'content.len': parseInt(newObj.len),
        'content.language': newObj.language,
        'content.embeddable': newObj.embeddable,
        'content.network.domain': newObj.networkDomain,
        'content.channel.domain': newObj.channelDomain,
        'content.network.name': newObj.networkName,
        'content.channel.name': newObj.channelName,
        'application.type': newObj.applicationType,
      };
    } else {
      payload = {
        deal_id: selected.id,
        iab_category: selected.iab_category,
        device_type: selected.device_type,
        platform_type: selected.playform_type ?? [],
        platform_category: selected.platform_category,
        country: selected.country,
        state: selected.state,
        city: selected.city,
        lower_cpm: selected.lower_cpm ?? 0,
        upper_cpm: selected.upper_cpm ?? 0,
      };
    }
    getInventoryCardsAPI(payload)
      .then((data: any) => {
        sCards(data.data);
        setCardLoading(false);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const dealsInventories = (newObj?: any) => {
    let payload: any;
    if (newObj) {
      payload = {
        iab_category: newObj.cat,
        device_type: newObj.dev,
        platform_type: newObj.p_type,
        platform_category: newObj.p_cat,
        country: newObj.ctr,
        state: newObj.stt,
        city: newObj.cty,
        lower_cpm: newObj.lower_cpm,
        upper_cpm: newObj.upper_cpm,
        deal_id: selected.id,
        ssp_id: newObj.ssp_id,
        'content.artist': newObj.artist,
        'content.series': newObj.series,
        'content.title': newObj.title,
        'content.genre': newObj.genre,
        'content.isrc': newObj.isrc,
        'content.producer.name': newObj.producerName,
        'content.producer.domain': newObj.producerDomain,
        'content.prodq': newObj.prodq,
        'content.context': newObj.context,
        'content.contentrating': newObj.contentrating,
        'content.userrating': newObj.userrating,
        'content.qagmediarating': newObj.qagmediarating,
        'content.keywords': newObj.keywords,
        'content.kwarray': newObj.keywordsArray,
        'content.livestream': newObj.livestream,
        'content.sourcerelationship': newObj.sourceRelationship,
        'content.len': parseInt(newObj.len),
        'content.language': newObj.language,
        'content.embeddable': newObj.embeddable,
        'content.network.domain': newObj.networkDomain,
        'content.channel.domain': newObj.channelDomain,
        'content.network.name': newObj.networkName,
        'content.channel.name': newObj.channelName,
        'application.type': newObj.applicationType,
      };
    } else {
      payload = {
        deal_id: selected.id,
        iab_category: selected.iab_category,
        device_type: selected.device_type,
        platform_type: selected.playform_type ?? [],
        platform_category: selected.platform_category,
        country: selected.country,
        state: selected.state,
        city: selected.city,
        lower_cpm: selected.lower_cpm ?? 0,
        upper_cpm: selected.upper_cpm ?? 0,
      };
    }
    setTableSkeletonView(true);
    setNoDealInv(false);
    getDealInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          setFilterShow(false);
          setTableSkeletonView(false);
          setInventories(data ?? {});
          setNoDealInv(data?.data?.length === 0);
          if (data.data && newObj) {
            inventoryCards(newObj);
          } else {
            inventoryCards();
          }
        }
      })
      .catch((err: any) => {
        // console.log('error', err);
      });
  };

  const downloadInv = (newObj: any) => {
    let payload: any;
    if (newObj && newObj !== undefined) {
      payload = {
        deal_id: selected.id,
        iab_category: newObj.cat,
        device_type: newObj.dev,
        platform_type: newObj.p_type,
        platform_category: newObj.p_cat,
        country: newObj.ctr,
        state: newObj.stt,
        city: newObj.cty,
        lower_cpm: newObj.lower_cpm,
        upper_cpm: newObj.upper_cpm,
        ssp_id: newObj.ssp_id,
        export_type: 'xlsx',
        export_data_format: 'all',
        // page_size: '',
        'content.artist': newObj.artist,
        'content.series': newObj.series,
        'content.title': newObj.title,
        'content.genre': newObj.genre,
        'content.isrc': newObj.isrc,
        'content.producer.name': newObj.producerName,
        'content.producer.domain': newObj.producerDomain,
        'content.prodq': newObj.prodq,
        'content.context': newObj.context,
        'content.contentrating': newObj.contentrating,
        'content.userrating': newObj.userrating,
        'content.qagmediarating': newObj.qagmediarating,
        'content.keywords': newObj.keywords,
        'content.kwarray': newObj.keywordsArray,
        'content.livestream': newObj.livestream,
        'content.sourcerelationship': newObj.sourceRelationship,
        'content.len': parseInt(newObj.len),
        'content.language': newObj.language,
        'content.embeddable': newObj.embeddable,
        'content.network.domain': newObj.networkDomain,
        'content.channel.domain': newObj.channelDomain,
        'content.network.name': newObj.networkName,
        'content.channel.name': newObj.channelName,
        'application.type': newObj.applicationType,
      };
    } else {
      payload = {
        deal_id: selected.id,
        iab_category: selected.iab_category,
        device_type: selected.device_type,
        platform_type: selected.playform_type ?? [],
        platform_category: selected.platform_category,
        country: selected.country,
        state: selected.state,
        city: selected.city,
        lower_cpm: selected.lower_cpm ?? 0,
        upper_cpm: selected.upper_cpm ?? 0,
        export_type: 'xlsx',
        export_data_format: 'all',
        // page_size: '',
      };
    }
    setIsDownloading(true);
    getDealInventoriesAPI(payload)
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Publishers.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        setIsDownloading(false);
      })
      .catch(() => {
        // Error
      });
  };

  // // useEffects

  useEffect(() => {
    if (selected?.id) {
      history.push(`/inventory/deals/${selected?.id}`);
      dealsInventories();
      getCustomers();
      setFilterShow(false);
      setCardShow(false);
    }
  }, [selected?.id]); //removed 'deals'

  const selectedDealsCard = (id: any) => {
    if (selected.id !== id) {
      sSelected(deals.find((x: any) => x.id === id));
      history.push(`/inventory/deals/${id}`);
      setIsEditable(false);
      setSelectedTabSources('inventory');
    }
  };

  /* Inventories List Update */
  const handleInventoriesId = (inv: any) => {
    setSelectedInv(inv);
  };

  const handleInventoryUpdate = () => {
    const payload = {
      id: selected.id,
      inventories_ids: selectedInv,
    };
    setIsSaving(true);
    editDealAPI(payload)
      .then((data: any) => {
        if (data.status) {
          dealsInventories();
          setIsEditable(false);
        }
        setTimeout(() => {
          setIsSaving(false);
        }, 300);
      })
      .catch((error: any) => {
        //
      });
  };

  const handleCancelUpdate = () => {
    setIsEditable(false);
    setSelectedInv(inventories?.data);
  };

  useEffect(() => {
    if (adminGenreList.length === 0) {
      getGenreList();
    }
    if (adminCountriesList.length === 0) {
      getCountriesList();
    }
  }, []);

  const getGenreList = () => {
    getRtbGenreListAPI()
      .then((data: any) => {
        if (data) {
          sAdminGenreList(data.data);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const getCountriesList = () => {
    getCountriesAPI()
      .then((data: any) => {
        sAdminCountriesList(data.data);
      })
      .catch(() => {
        //
      });
  };

  const addCustomerColumn = [
    {
      key: 'id',
      sort: true,
      title: 'Customer ID',
      type: 'number',
    },
    {
      key: 'company_name',
      sort: true,
      title: 'Customer Name',
      type: 'string',
    },
    {
      key: 'action',
      sort: false,
      sticky: true,
      title: 'Remove Customer',
      type: 'action',
    },
  ];

  const handleRemoveCustomer = (_event: any) => {
    const payload = {
      deal_id: _event?.dealID,
      customers: [_event?.customerID],
    };
    removeDealCustomersAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          getCustomers();
        }
      })
      .catch((error: any) => {
        // error
      });
  };

  return (
    <>
      <Deal
        edit
        dealTerms={selected ?? {}}
        show={show}
        dealTermsConfig={selected?.config ?? {}}
        onHide={() => {
          sShow(false);
        }}
        updateList={handleDealsList}
        genreList={adminGenreList}
        countriesList={adminCountriesList}
      />

      <DeleteDeal
        id={selected?.id}
        onHide={() => {
          sDelShow(false);
        }}
        show={delShow}
        api={handleDeleteReqDeal}
      />

      <DealCustomer
        id={selected?.id}
        onHide={() => {
          sCsShow(false);
          getCustomers();
        }}
        show={cSshow}
        addedCustomers={dealCustomers.map(({ id }: any) => id)}
      />

      <Filter
        onsubmit={(data: any) => {
          dealsInventories(data);
          updateDealTerms(data);
        }}
        loadingStop={tableSkeletonView}
        kind={'admin'}
        id={selected?.id}
        show={filterShow}
        onHide={() => setFilterShow(false)}
      />

      <div className='content d-flex'>
        <DealsSidebar
          title={glossary.admin_deal_sidebar_title}
          kind={'deal'}
          size={sidebar}
          card={'expanded'}
          items={deals}
          sidebar={setSidebar}
          getTags={() => null}
          empty={noDeal}
          info={selectedDealsCard}
          selected={selected?.id ?? ''}
          userType={'admin'}
          currentPage={currentPage}
          loading={dealCardLoading}
          hasMore={hasMore}
          params={params}
          handleParams={handleParamsChange}
        />

        <div className='view-section'>
          <Tab.Container
            defaultActiveKey={selectedTabSources}
            activeKey={selectedTabSources}
            onSelect={(key: any) => setSelectedTabSources(key)}
          >
            <div className='row align-items-center'>
              <div className='col-4 d-flex align-items-center gap-2'>
                {selected?.name !== undefined && (
                  <h2 className={'text-truncate me-2 mt-2'}>{selected?.name}</h2>
                )}

                {selected?.publishers !== undefined && selected?.publishers === 0 && (
                  <>
                    <BiEditAlt
                      className='svg-icon mt-1'
                      onClick={() => {
                        sShow(true);
                      }}
                      title={glossary.admin_deal_inventory_edit_icon_title}
                      fontSize={22}
                    />
                    <MdDeleteOutline
                      className='svg-icon mt-1'
                      fontSize={22}
                      onClick={() => {
                        sDelShow(true);
                      }}
                      title={glossary.admin_deal_inventory_delete_icon_title}
                    />
                  </>
                )}
              </div>
              <div className='col-4'>
                <Nav variant='pills'>
                  <Nav.Item>
                    <Nav.Link eventKey='inventory'>{glossary.admin_deal_tab_inventory}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='customer'>{glossary.admin_deal_tab_customers}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className='col-4'>
                {selectedTabSources === 'inventory' && (
                  <div className='d-flex align-items-center justify-content-end'>
                    <div className='me-4 d-flex align-items-center w-auto gap-2'>
                      <p className='pt-1'>{glossary.admin_deal_inventory_toggle_button_chart}</p>
                      <Switch
                        api={(chec: any) => {
                          setCardShow(chec);
                          if (chec) setFilterShow(false);
                        }}
                        status={cardShow}
                      />
                    </div>
                    {/* <div className='me-3 d-flex align-items-center w-auto gap-2'>
                      <button className='button button-primary' onClick={() => setFilterShow(true)}>
                        <BiFilterAlt fontSize={18} className='me-2' />
                        {glossary.admin_deal_inventory_toggle_button_filter}
                      </button>
                    </div> */}
                    <Button
                      kind={isDownloading ? 'spinner' : 'primary'}
                      text={
                        isDownloading
                          ? 'Downloading...'
                          : glossary.admin_deal_inventory_button_download_xsl
                      }
                      click={() => downloadInv(dealTerms)}
                    />
                  </div>
                )}
                {selectedTabSources === 'customer' && selected?.publishers === 0 && (
                  <div className='d-flex justify-content-end'>
                    <Button
                      kind={'primary'}
                      text={glossary.admin_deal_customer_button_add_customer}
                      click={() => sCsShow(true)}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <Tab.Content>
                  <Tab.Pane eventKey={'inventory'}>
                    {cardShow && (
                      <div className='row' style={{ marginTop: '20px', marginBottom: '20px' }}>
                        {cardloading ? (
                          <>
                            <div className={'col'} style={{ height: '100%' }}>
                              <InvetoryCard />
                            </div>
                            <div className={'col'} style={{ height: '100%' }}>
                              <InvetoryCard />
                            </div>
                          </>
                        ) : (
                          <>
                            {cards && cards.length > 0 ? (
                              cards.map((card: any, index: any) => {
                                return (
                                  <div
                                    key={index}
                                    className={'col-12 col-lg-6'}
                                    style={{ height: '100%', width: '25%' }}
                                  >
                                    <ChartSection title={card.title} data={card.data} />
                                  </div>
                                );
                              })
                            ) : (
                              <h3 className='d-flex justify-content-center mt-5'>
                                Chart data not found
                              </h3>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    <div className='row'>
                      {tableSkeletonView ? (
                        <div
                          className={'col-12 px-3'}
                          style={{ marginTop: 50, overflow: 'scroll' }}
                        >
                          <TableSkeleton columns={7} rows={10} rowSpacing={10} tableHeight={500} />
                        </div>
                      ) : (
                        <>
                          {noDealInv ? (
                            <div className='d-flex justify-content-center mt-5'>
                              <h3>No Inventories Found</h3>
                            </div>
                          ) : (
                            <>
                              <div style={{ position: 'relative', marginTop: '25px' }}>
                                {selected.publishers === 0 && (
                                  <div style={{ position: 'absolute', right: '10px', top: '26px' }}>
                                    {isEditable && !isSaving && (
                                      <>
                                        <Button
                                          text={'Cancel'}
                                          kind={'secondary'}
                                          type={'button'}
                                          click={handleCancelUpdate}
                                          customClass='me-3'
                                        />
                                        <Button
                                          text={'Save Changes'}
                                          kind={selectedInv.length === 0 ? 'disabled' : 'primary'}
                                          type={'button'}
                                          click={handleInventoryUpdate}
                                        />
                                      </>
                                    )}
                                    {isSaving && (
                                      <Button
                                        type={'button'}
                                        text={glossary.loading}
                                        kind={'spinner'}
                                      />
                                    )}
                                  </div>
                                )}

                                {!isEditable && (
                                  <div className='mt-3'>
                                    <Coretable
                                      column={inventories?.columns ?? []}
                                      ignoreCol={['excluded', 'check_box']}
                                      data={
                                        inventories?.data?.filter((item: any) => item?.check_box) ??
                                        []
                                      }
                                      search={true}
                                      heightCount={'325px'}
                                      action={{
                                        text: 'Edit Inventories',
                                        click: () => setIsEditable(true),
                                      }}
                                      tableName={'Inv-Read-Only'}
                                    />
                                  </div>
                                )}

                                {isEditable && (
                                  <NewInvTable
                                    data={inventories?.data ?? []}
                                    handleSelect={handleInventoriesId}
                                    isAdmin={true}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey={'customer'}>
                    <div className={'row table-responsive'} style={{ marginTop: 40 }}>
                      {dealCustomers.length === 0 ? (
                        <div className={'text-center mt-5'}>
                          <h3 className='mt-5'>
                            {glossary.admin_deal_customer_nocustomer_error_msg}
                          </h3>
                          <p>{glossary.admin_deal_customer_nocustomer_instruction}</p>
                        </div>
                      ) : (
                        <Coretable
                          column={addCustomerColumn}
                          data={dealCustomers}
                          selectedCard={selected}
                          search={true}
                          heightCount={'347px'}
                          ignoreCol={[]}
                          actionController={(_e: any) => handleRemoveCustomer(_e)}
                          tableName={'PMPDeals-Customers'}
                        />
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
