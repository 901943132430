import { useEffect, useState } from 'react';
import Tile from '../../widgets/tile';
import { inventory as inventoryTiles } from '../../helpers/tiles';
import { getInventoryOverviewAPI } from '../../../services/inventory';
import store from '../../../states';
import DspInventories from '../../tables/dspInventories';
import { getDemandEndpointInventoriesAPI } from '../../../services/endpoint';
import TableSkeleton from '../../loaders/table';
import Box from '../../loaders/box';

const DemandTraffic = (props: any) => {
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const [inventoryTilesData, sInventoryTilesData] = useState<any>([]);
  const [inventories, setInventories] = useState<any>([]);
  const [loading, setLoading] = useState({
    table: true,
    overview: true,
  });

  useEffect(() => {
    if (props.endpoint_id) {
      getTrafficData();
    }
  }, [props.endpoint_id]);

  const getTrafficData = () => {
    const payload = {
      end_point: props.endpoint_id,
    };
    setLoading((prev: any) => ({ table: true, overview: true }));
    getDemandEndpointInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          setInventories(data.data);
          inventoryOverview(props.endpoint_id);
          setLoading((prev: any) => ({ ...prev, table: false }));
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const updateInvList = (id: number) => {
    const payload = {
      end_point: id,
    };
    getDemandEndpointInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          setInventories(data.data);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  const inventoryOverview = (id: any) => {
    const payload = {
      end_point: id,
    };
    getInventoryOverviewAPI(payload)
      .then((data: any) => {
        setLoading((prev: any) => ({ ...prev, overview: false }));
        if (data?.status) {
          const tileData = inventoryTiles(data?.data ?? []);
          sInventoryTilesData(tileData);
        }
      })
      .catch(() => {
        //error
      });
  };

  return (
    <div className={'content'}>
      {/* Demand Traffic Overview */}
      {loading?.overview ? (
        <>
          <div className='row align-items-center justify-content-between'>
            <div className='col-6'>
              <div className='d-flex gap-4'>
                {[1, 2, 3].map((index) => {
                  return <Box key={index} />;
                })}
              </div>
            </div>
            <div className='col-2'>&nbsp;</div>
          </div>
        </>
      ) : (
        <>
          <div className={'row overview-tiles align-items-center'}>
            {inventoryTilesData.length > 0 &&
              inventoryTilesData.map((tile: any, index: any) => {
                return (
                  <div key={index} className={'col-auto'}>
                    <Tile
                      onHover={'disabled'}
                      id={tile.id}
                      selected={99}
                      click={() => null}
                      label={tile.label}
                      value={tile.value}
                      info={tile.info}
                      arrow={tile.arrow}
                      deals
                      type={'number'}
                    />
                  </div>
                );
              })}
          </div>
        </>
      )}

      {/* Demand Traffic Table */}
      <div className='dsp-inventory-scrollbody'>
        {loading?.table ? (
          <div className={'row'} style={{ marginTop: 40, overflow: 'scroll' }}>
            <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
          </div>
        ) : (
          <div className={'row dsp-traffic-table table-wrap'} style={{ overflow: 'auto' }}>
            <DspInventories
              rows={inventories ?? []}
              endpoint_id={props?.endpoint_id}
              getTrafficData={updateInvList}
              type={props.type}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DemandTraffic;
