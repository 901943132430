import { useState } from 'react';
import { BsCheck2Circle, BsThreeDotsVertical } from 'react-icons/bs';
// import { FiDownload } from 'react-icons/fi';
// import { RiChatFollowUpLine, RiQuestionnaireLine } from 'react-icons/ri';
// import { TbFileInvoice } from 'react-icons/tb';
import PaymentDispute from '../modals/Payment-dispute';
import { Dropdown, Spinner } from 'react-bootstrap';
import { invoiceDownloadAPI, receiptDownloadAPI } from '../../services/settings';
import { openNewTab } from './functions';

const EarningByPartner = (props: any) => {
  const { accessor, row, controller } = props;

  const [resolveDisputeShow, setResolveDisputeShow] = useState(false);
  const [disputeType, setDisputeType] = useState('');
  const [isInprogress, sIsInprogress] = useState(false);

  const isFollowUp =
    row?.status !== 'received' && row?.due_date !== '-' && new Date() >= new Date(row?.due_date);
  const isDownloadInvoice = row?.status !== 'current_running' && row?.status !== 'pending';
  const isDownloadReceipt = row?.status === 'received' || row?.status === 'paid'; // TODO: Add some key here (&& stripe_receipt_id | payment_proof_link)
  const isMarkAsPaid = row?.status === 'invoice'; // TODO: Add some key here (&& stripe_receipt_id | payment_proof_link)

  const isResolveDispute = row?.status === 'dispute';

  const downloadInvoice = (rowData: any) => {
    const payload = {
      ...rowData,
      type: 'media_cost',
    };
    sIsInprogress(true);
    invoiceDownloadAPI(payload)
      .then((data: any) => {
        if (data.url) {
          const link = document.createElement('a');
          link.href = data?.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        sIsInprogress(false);
      })
      .catch((error: any) => {
        sIsInprogress(false);
        // error
      });
  };

  const downloadReceipt = (rowData: any) => {
    const payload = {
      ...rowData,
      type: 'media_cost',
    };
    sIsInprogress(true);
    receiptDownloadAPI(payload)
      .then((data: any) => {
        if (data?.url) {
          openNewTab(data?.url);
        }
        sIsInprogress(false);
      })
      .catch((error: any) => {
        sIsInprogress(false);
        // error
      });
  };

  return (
    <>
      <PaymentDispute
        show={resolveDisputeShow}
        onHide={() => {
          setResolveDisputeShow(false);
        }}
        type={disputeType}
        paymentID={row?.payment_id}
        updateList={controller}
      />

      {accessor === 'action' && (
        <>
          <Dropdown>
            {isInprogress ? (
              <span className='suggestion-input'>
                <Spinner
                  className='ms-1'
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              </span>
            ) : (
              <Dropdown.Toggle>
                <BsThreeDotsVertical className='svg-icon' style={{ marginLeft: '2px' }} />
              </Dropdown.Toggle>
            )}

            <Dropdown.Menu className='table-action-cell'>
              <Dropdown.Item disabled={!isFollowUp}>Follow Up</Dropdown.Item>

              <Dropdown.Item disabled={!isDownloadInvoice} onClick={() => downloadInvoice(row)}>
                Download Invoice
              </Dropdown.Item>

              {/* <Dropdown.Item disabled={!isDownloadReceipt} onClick={() => downloadReceipt(row)}>
                Download Receipt
              </Dropdown.Item> */}

              <Dropdown.Item disabled={!isMarkAsPaid}>Mark as paid</Dropdown.Item>

              <Dropdown.Item
                disabled={!isResolveDispute}
                onClick={() => {
                  setResolveDisputeShow(true);
                  setDisputeType('solve');
                }}
              >
                Solve Dispute
              </Dropdown.Item>

              <Dropdown.Item
                disabled={!isResolveDispute}
                onClick={() => {
                  setResolveDisputeShow(true);
                  setDisputeType('reject');
                }}
              >
                Reject Dispute
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
    </>
  );
};

export default EarningByPartner;
