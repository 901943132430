import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import store from '../../states';
import Button from '../widgets/button';

export default () => {
  const { push } = useHistory();

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: 'calc(100vh - 66px)',
      }}
    >
      <div
        className={'card-box'}
        style={{
          margin: 'auto',
          rowGap: '16px',
          width: '550px',
          display: 'flex',
          padding: '72px 118px',
          flexDirection: 'column',
        }}
      >
        <h3 style={{ fontWeight: 300, textAlign: 'center' }}>{glossary.password_create_title}</h3>
        <p style={{ textAlign: 'center' }}>{glossary.password_create_description}</p>
        <div>
          <Button
            kind={'primary'}
            size={'full'}
            text={glossary.password_create_button}
            click={() => push('/login')}
          />
        </div>
      </div>
    </div>
  );
};
