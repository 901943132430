import ContentLoader from 'react-content-loader';

const ButtonSkeleton = ({ width, height }: any) => {
  return (
    <ContentLoader
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
    >
      <rect x='0' y='0' rx='5' ry='5' width={width} height={height} />
    </ContentLoader>
  );
};

export default ButtonSkeleton;
