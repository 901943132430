import { Field, ErrorMessage } from 'formik';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { IoMdCheckmark } from 'react-icons/io';

const Input = (props: any) => {
  const { name, label, kind, disable, ...rest } = props;
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      {kind === 'withouticon' && (
        <>
          <label htmlFor={name} className='label'>
            {label}
          </label>
          <Field
            name={name}
            {...rest}
            className={disable ? 'text-input disable-field' : 'text-input'}
            readOnly={disable ? true : false}
          />
          <div className='text-input-error-label small'>
            <ErrorMessage name={name} />
          </div>
        </>
      )}
      {kind === 'withiconstart' && (
        <>
          <label htmlFor={name} className='label'>
            {label}
          </label>
          <div className='input-group flex-nowrap'>
            {rest.iconType === 'atsign' ? (
              <span className='input-group-text'>@</span>
            ) : rest.iconType === 'percentage' ? (
              <span className='input-group-text'>%</span>
            ) : (
              <span className='input-group-text'>{rest.icon ?? '$'}</span>
            )}
            <Field name={name} {...rest} className='text-input' />
          </div>
          <div className='text-input-error-label small'>
            <ErrorMessage name={name} />
          </div>
        </>
      )}
      {kind === 'withiconend' && (
        <>
          <label htmlFor={name} className='label'>
            {label}
          </label>
          <div className='input-group flex-nowrap'>
            <Field name={name} {...rest} className='text-input' />
            <span className='input-group-text'>{rest.icon ?? 'ms'}</span>
          </div>
          <div className='text-input-error-label small'>
            <ErrorMessage name={name} />
          </div>
        </>
      )}
      {kind === 'withdashed' && (
        <>
          <label htmlFor={name} className='label mb-0 me-2'>
            {label}
          </label>
          <Field name={name} {...rest} className='text-input-mini me-2' />
          <div className='text-input-error-label small'>
            <ErrorMessage name={name} />
          </div>
        </>
      )}
      {kind === 'password' && (
        <>
          <label htmlFor={name} className='label'>
            {label}
          </label>
          <div className='formik-password'>
            <Field
              name={name}
              type={isShow ? 'text' : 'password'}
              {...rest}
              className='text-input'
            />
            <button tabIndex={-1} type='button' onClick={() => setIsShow(!isShow)}>
              {isShow ? <FiEyeOff className='svg-icon' /> : <FiEye className='svg-icon' />}
            </button>
          </div>
          <div className='text-input-error-label small'>
            <ErrorMessage name={name} />
          </div>
        </>
      )}
      {kind === 'withvalidate' && (
        <>
          <label htmlFor={name} className='label'>
            {label}
          </label>
          <div className='formik-validate input-group flex-nowrap valid'>
            <span className='input-group-text'>{rest.icon}</span>
            <Field
              name={name}
              {...rest}
              className={disable ? 'text-input disable-field' : 'text-input'}
              readOnly={disable ? true : false}
            />

            {!Object?.keys(rest?.valid?.error)?.includes(name) && rest?.valid?.value[name] !== '' && (
              <span className='right-icon input-group-text bg-transparent'>
                <IoMdCheckmark fontSize={20} color={'green'} />
              </span>
            )}
          </div>

          <div className='text-input-error-label small'>
            <ErrorMessage name={name} />
          </div>
        </>
      )}
    </>
  );
};

export default Input;
