import iabCategories from '../../states/iabCategories';
import platformTypes from '../../states/platformTypes';

export const categories = iabCategories;

export const platforms = platformTypes;

export const countries = (data: any) => {
  const newData = data?.map(({ id, iso2, name }: any) => ({ id, value: iso2, label: name }));
  return newData;
};

export const states = (data: any) => {
  const newData = data?.map(({ id, iso2, name, country_code }: any) => ({
    id,
    value: iso2,
    label: name,
    country: country_code,
  }));
  return newData;
};

export const cities = (data: any) => {
  const newData = data?.map(({ id, state_code, country_code, name }: any) => ({
    id,
    value: name,
    state: state_code,
    country: country_code,
    label: name,
  }));
  return newData;
};

export const sources = (data: any) => {
  const newData = data?.map(({ id, company_name, customer_type, earning }: any) => ({
    customer_type,
    id,
    value: id,
    label: company_name,
    earning,
  }));
  return newData?.filter((x: any) => x.customer_type !== 'demand' && x.earning > 0) ?? [];
};

export const publishers = (data: any) => {
  const onImageError = (e: any) => {
    e.target.src = 'https://infy-ads.s3.us-east-2.amazonaws.com/static/placeholder.png';
  };

  const newData = data?.map(({ company_name, id, thumb }: any) => ({
    label: company_name,
    value: id,
    icon: (
      <img
        src={thumb}
        alt={id}
        style={{ width: '25px', height: '25px', borderRadius: '50%', objectFit: 'cover' }}
        onError={onImageError}
      />
    ),
  }));
  return newData;
};

export const loopList = (data: any) => {
  const newData = data?.map(({ group_name, group_name_id, ...rest }: any) => ({
    value: group_name_id,
    label: group_name,
    ...rest,
  }));
  return newData;
};

export const reelLoopList = (data: any) => {
  const newData = data?.map(({ group_name, group_share_string, ...rest }: any) => ({
    value: group_share_string,
    label: group_name,
    ...rest,
  }));
  return newData;
};
