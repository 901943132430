import React, { useState, useEffect } from 'react';
import Box from '../../loaders/box';
import Bar from '../../charts/bar';
import Line from '../../charts/line';
import Tile from '../../widgets/tile';
import XYChart from '../../loaders/chart';
import Reports from '../../helpers/reports';
import TextSkeleton from '../../loaders/text';
import OverViewTable from '../../tables/overview';
import TableSkeleton from '../../loaders/table';
import BarGraph from '../../loaders/bar-graph';
import LineGraph from '../../loaders/line-graph';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';

interface Props {
  kind: 'performance' | 'empty';
  selectedID?: number;
  refinedLineData?: any;
  singleTileData?: any;
  isLoading?: boolean;
}

const MyCreativesOverview = ({ kind, singleTileData, selectedID, isLoading }: Props) => {
  const [selectedTile, sSelectedTile] = useState<any>(null);
  const [lineData, sLineData] = useState<any>(null);
  const [attribute /* , sAttribute */] = useState<any>(null);
  const [rawLineData /* , sRawLineData */] = useState<any>([]);
  const [refinedLineData, sRefinedLineData] = useState<any>([]);
  const [random, sRandom] = useState(Math.random());

  useEffect(() => {
    sSelectedTile([]);
    sLineData([]);
    sRefinedLineData([]);
  }, [selectedID]);

  useEffect(() => {
    if (singleTileData !== undefined && singleTileData?.length > 0) {
      sSelectedTile(0);
      sRandom(Math.random());
    }
  }, [singleTileData]);

  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  useEffect(() => {
    if (selectedID !== 0 && selectedID !== undefined) {
      sLineData(singleTileData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
    }
  }, [selectedTile, random]);

  // console.log('refinedLineData===', refinedLineData);

  useEffect(() => {
    const bottom: any = [];
    rawLineData.map((_: any, i: any) => {
      return bottom.push({ date: _.date, [attribute]: _[attribute] });
    });
    sRefinedLineData({
      labels: bottom.map((a: any) => a.date),
      values: bottom.map((a: any) => a[attribute]),
    });
  }, [rawLineData, attribute]);

  useEffect(() => {
    if (lineData && lineData.length) {
      let newlabels: any = [];
      newlabels = lineData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = lineData.map((_: any) => _[Object.keys(_)[0]]);
      sRefinedLineData({ labels: newValues, values: newlabels });
    } else {
      sRefinedLineData({ labels: [], values: [] });
    }
  }, [lineData]);

  const dummyData = {
    tile: [
      { label: 'Impressions', arrow: 'up' },
      { label: 'Win Rate', arrow: 'down' },
      { label: 'Average CPM', arrow: 'up' },
      { label: 'Spending', arrow: 'up' },
      { label: 'Total spending', arrow: 'down' },
    ],
    graph: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
      values: ['2500', '3000', '4780', '5907', '8578'],
    },
  };

  return (
    <>
      {kind === 'performance' && (
        <>
          {refinedLineData.labels < 1 && refinedLineData.values < 1 ? (
            <>
              <div className={'row'} style={{ marginTop: '15px', justifyContent: 'space-between' }}>
                <div className='col-6'>
                  <div className='d-flex gap-4'>
                    {[1, 2, 3, 4].map((index) => {
                      return <Box key={index} />;
                    })}
                  </div>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col w-100 m-auto'>
                  <XYChart />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={'row'} style={{ marginTop: 15 }}>
                {singleTileData.map((tile: any, index: any) => {
                  return (
                    <div key={index} className={'col-auto'}>
                      {refinedLineData.labels < 1 && refinedLineData.values < 1 ? null : (
                        <Tile
                          id={tile.id}
                          selected={selectedTile}
                          click={selectTile}
                          label={tile.label}
                          value={tile.value}
                          info={tile.info}
                          arrow={tile.arrow}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <div
                className={'row'}
                style={{
                  height: '320px',
                  marginTop: '24px',
                }}
              >
                <Line label={'Impressions'} data={refinedLineData} attribute={attribute} />
              </div>
            </>
          )}

          {/* <div className={'row report-table'} style={{ marginTop: 24 }}>
            <Reports
              id={selectedID}
              start={startDate}
              end={endDate}
              tabs={datatables}
              random={random}
              customerId={customerID}
              entityType={'tag'}
            />
          </div> */}
        </>
      )}

      {kind === 'empty' && (
        <>
          {isLoading ? (
            <>
              <div className={'row'} style={{ marginTop: '56px', justifyContent: 'space-between' }}>
                <div className='col-6'>
                  <div className='d-flex gap-4'>
                    {[1, 2, 3, 4].map((index) => {
                      return <Box key={index} />;
                    })}
                  </div>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col w-100 m-auto'>
                  <XYChart />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='row'>
                <div className='col-6 disable-field'>
                  <h2 className='text-truncate'>Demo</h2>
                </div>
                <div className='col-6 disable-field' style={{ pointerEvents: 'none' }}>
                  <div
                    className={'calendar__wrap tour-calendar-wrap'}
                    style={{ position: 'relative' }}
                  >
                    <DateRangeFilter onChange={() => null} selectionRange={null} />
                  </div>
                </div>
              </div>

              <div className={'row overview-tiles'} style={{ marginTop: 65 }}>
                <div className='col-auto'>
                  <div className='row'>
                    {dummyData?.tile?.map((_item: any, i) => (
                      <div className={'col-auto'} key={i}>
                        <Tile
                          id={0}
                          selected={i}
                          click={selectTile}
                          label={_item.label}
                          value={'0'}
                          arrow={_item.arrow}
                          dummy={true}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div
                className={'row'}
                style={{
                  height: '320px',
                  marginTop: '24px',
                }}
              >
                <Line label={'Impressions'} data={dummyData?.graph} attribute={attribute} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MyCreativesOverview;
