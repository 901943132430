import React from 'react';
import { FiDownload } from 'react-icons/fi';
import { invoiceDownloadAPI } from '../../services/settings';
import { receiptDownloadAPI } from '../../services/settings';

const SspFinancePayoutManage = (Props: any) => {
  const { accessor, row } = Props;

  const validStatus = ['paid', 'received', 'invoice'];

  const handleDownload = (rowData: any) => {
    if (rowData.status === 'paid') {
      receiptDownloadAPI(rowData).then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${rowData.date}-Receipt.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } else {
      invoiceDownloadAPI(rowData)
        .then((data: any) => {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${rowData.date}-Invoice.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error: any) => {
          //ok
        });
    }
  };

  return (
    <>
      {accessor === 'action' && (
        <>
          {validStatus.includes(row.status) && (
            <FiDownload
              fontSize={18}
              className='svg-icon'
              onClick={() => handleDownload(row)}
              title='Download'
            />
          )}
        </>
      )}
    </>
  );
};

export default SspFinancePayoutManage;
