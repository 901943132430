import React, { useContext, useEffect, useState } from 'react';
import {
  // creativeGetLoopListAPI,
  creativeGetLoopVideosAPI,
  creativeGetProfileVideosAPI,
  // creativeGetSocialProfileAPI,
  creativeGetSocialProfileNewAPI,
  // creativeSetSocialProfileAPI,
  creativeSetVideoLibraryAPI,
} from '../../../services/creative';
// import Dropdown from '../../widgets/dropdown';
// import { reelLoopList } from '../../helpers/dropdowns';
import CreativeVideoTable from '../../tables/CreativeVideoTable';
import Button from '../../widgets/button';
// import MyCreativeSidebar from '../../sidebar/MyCreatives';
// import { FaTiktok } from 'react-icons/fa';
// import { BsYoutube } from 'react-icons/bs';
// import { AiFillInstagram } from 'react-icons/ai';
import store from '../../../states';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import CreativeAdd from '../../modals/creative-add';
import consumerData from '../../contexts/OverViewContext';
import { UserInfoContext } from '../../contexts/userInfoContext';
import CreativeSocialProfile from './creative-socialprofiles';
import CreativeUpload from '../../modals/creative-upload';
import { FiUpload } from 'react-icons/fi';

const VideoLibrary = () => {
  // Context API
  const { tags, sTags }: any = consumerData();
  const { userInfo }: any = useContext(UserInfoContext);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // const [loopList, setLoopList] = useState([]);
  // const [selectedLoop, setSelectedLoop] = useState([]);
  // const [selectedFeedType, setSelectedFeedType] = useState({ value: 'Profile', label: 'Profile' });
  const [videoList, setVideoList] = useState<any>([]);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [hasMoreVideos, setHasMoreVideos] = useState(true);
  const [updatedVideoList, setUpdatedVideoList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [socialProfiles, setSocialProfiles] = useState([]);

  // Creative Upload
  const [creativeUploadShow, setCreativeUploadShow] = useState(false);

  // const getLoopList = () => {
  //   const payload = {
  //     page: 0,
  //     page_size: 200,
  //   };
  //   creativeGetLoopListAPI(payload)
  //     .then((data: any) => {
  //       if (data?.data) {
  //         setLoopList(data?.data);
  //       }
  //     })
  //     .catch((error: any) => {
  //       // error
  //     });
  // };
  //

  const getLooplistvideos = (_loop: any) => {
    const payload = {
      loop_share_string: _loop?.value,
      page: 0,
      page_size: 1000,
    };
    setIsVideoLoading(true);
    if (payload?.loop_share_string !== undefined) {
      creativeGetLoopVideosAPI(payload)
        .then((data: any) => {
          if (data?.data?.chats) {
            setVideoList(data?.data?.chats ?? []);
          }
          setIsVideoLoading(false);
        })
        .catch((error: any) => {
          // error
        });
    }
  };

  const getProfileVideos = (_page: any) => {
    const payload = {
      page: _page,
    };
    setIsVideoLoading(true);
    setHasMoreVideos(true);
    creativeGetProfileVideosAPI(payload)
      .then((data: any) => {
        if (data?.data?.end_of_videos) {
          setHasMoreVideos(false);
          setVideoList([...videoList, ...data?.data?.videos]);
        } else {
          setVideoList([...videoList, ...data?.data?.videos]);
        }
        setIsVideoLoading(false);
      })
      .catch((error: any) => {
        // error
      });
  };

  const getSocialProfiles = () => {
    creativeGetSocialProfileNewAPI()
      .then((data: any) => {
        if (data?.status) {
          setSocialProfiles(data?.data ?? []);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error: any) => {
        // error
      });
  };

  // Sidebar Card & More
  const [selectedCardID, setSelectedCardID] = useState(1);
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [instructionShow, setInstructionShow] = useState(false);

  const [selectedVideos, setSelectedVideos] = useState([]);
  const [createShow, setCreateShow] = useState(false);

  const handleSaveVideo = () => {
    const payload = {
      videos: updatedVideoList?.map((_item: any) => {
        return {
          share_string: _item?.share_string,
          id: _item?.video_id,
          cta: {
            text: _item?.cta_text,
            link: _item?.cta_link,
            pixel_link: _item?.pixel_link,
          },
          hash_tags: _item?.hash_tags,
          ad_copy: _item?.ad_copy,
        };
      }),
    };
    setSaveBtnLoader(true);
    creativeSetVideoLibraryAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          setVideoList(updatedVideoList);
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
        setSaveBtnLoader(false);
        setUpdatedVideoList([]);
      })
      .catch((error: any) => {
        setSaveBtnLoader(false);
        toast.error(glossary.server_error);
      });
  };

  const handleCreativeAdd = (_creativeObj: any, kind: string) => {
    if (kind === 'add') {
      const newCreativeObject = { ..._creativeObj };
      if (tags?.length > 0) {
        sTags([newCreativeObject, ...tags]);
      }
    }
  };

  // Only for getting uploaded video in videolibrary list
  const handleList = () => {
    setPageIndex(0);
    setVideoList([]);
    getProfileVideos(0);
  };

  useEffect(() => {
    // getLoopList();
    setVideoList([]);
    getSocialProfiles();
  }, []);

  // useEffect(() => {
  // setVideoList([]);
  // getLooplistvideos(selectedLoop);
  // }, [selectedLoop]);

  useEffect(() => {
    getProfileVideos(pageIndex);
  }, [pageIndex]);

  return (
    <>
      <Modal show={instructionShow} centered>
        <Modal.Header>
          <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>Alert</h3>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column justify-content-center'>
            <p>
              Please ensure that all entered IDs are correct. Sync videos from all inserted account
              may take 20-24 hours.
            </p>
            <Button
              customClass='mt-3'
              text={glossary.done_btn}
              kind={'primary'}
              click={() => {
                setInstructionShow(false);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      <CreativeAdd
        show={createShow}
        onHide={() => setCreateShow(false)}
        glossary={glossary}
        pSection={'2'}
        creativeDetails={{}}
        creativeConfig={{}}
        genuinUserInfo={userInfo?.genuine_user_name}
        mode={'create'}
        controller={handleCreativeAdd}
        _selectedVideos={selectedVideos}
      />

      <CreativeUpload
        show={creativeUploadShow}
        onHide={() => setCreativeUploadShow(false)}
        updateVideoList={() => handleList()}
        glossary={glossary}
      />

      <div className={'content d-flex'}>
        {/* <div className='row mb-4'>
        <div className='col-lg-4 col-md-5'>
          <Dropdown
            label={'Feed Type'}
            option={[
              { value: 'Profile', label: 'Profile' },
              { value: 'Loop', label: 'Loop' },
            ]}
            onchange={(_event: any) => {
              if (_event?.value === 'Profile') {
                getProfileVideos({ video_id: '', video_type: '' });
              }
              setSelectedLoop([]);
              setSelectedFeedType(_event);
            }}
            value={selectedFeedType}
            search={true}
          />
        </div>
        <div className='col-lg-4 col-md-2'></div>
        <div className='col-lg-4 col-md-5'>
          <Dropdown
            label={'Looplist'}
            option={reelLoopList(loopList)}
            onchange={setSelectedLoop}
            defaultValue={selectedLoop}
            search={true}
            disabled={selectedFeedType?.value === 'Loop' ? false : true}
          />
        </div>
      </div> */}

        {/* Temporary Sidebar */}
        <div className='newsidebar' style={{ minWidth: '300px', maxWidth: '300px' }}>
          <div className={'sidebar-row sidebar-row-first'}>
            <div className={'sidebar-col'}>
              <h3>Library</h3>
            </div>
          </div>
          <div className={'sidebar-list mt-2'} style={{ minWidth: 250 }}>
            {/* width: 300 */}
            <div
              className={`card ${selectedCardID === 1 ? 'card-selected' : ''}`}
              onClick={() => setSelectedCardID(1)}
            >
              <div className={'card-row align-items-center'}>
                <div className={'card-row'}>
                  <div className={'card-col'} style={{ width: '0px' }}>
                    <h6 className={'truncate-hb'} style={{ fontSize: '14px' }}>
                      Videos
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`card ${selectedCardID === 2 ? 'card-selected' : ''}`}
              onClick={() => setSelectedCardID(2)}
            >
              <div className={'card-row align-items-center'}>
                <div className={'card-row'}>
                  <div className={'card-col'} style={{ width: '0px' }}>
                    <h6 className={'truncate-hb'} style={{ fontSize: '14px' }}>
                      Social Profiles
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='view-section' style={{ marginLeft: 300 }}>
          {selectedCardID === 1 && (
            <>
              <div className='row mb-4'>
                <div className='col-12 d-flex justify-content-between'>
                  <div className='d-flex align-items-end gap-3'>
                    <Button
                      text='Generate Creative Tag'
                      kind={selectedVideos?.length > 0 ? 'primary' : 'disabled'}
                      type='button'
                      click={() => setCreateShow(true)}
                    />
                    {selectedVideos?.length >= 10 && (
                      <p className='text-danger'>You can select upto 10 videos per creative.</p>
                    )}
                  </div>

                  <div className='d-flex gap-3'>
                    <Button
                      text={'Video Upload'}
                      kind='secondary'
                      type='button'
                      click={() => setCreativeUploadShow(true)}
                    />

                    {saveBtnLoader ? (
                      <Button text={glossary.loading} kind={'spinner'} />
                    ) : (
                      <Button
                        text={'Save'}
                        kind={updatedVideoList?.length > 0 ? 'primary' : 'disabled'}
                        click={handleSaveVideo}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <CreativeVideoTable
                  data={videoList}
                  selectedData={selectedVideos}
                  handleUpdate={(_list: any) => {
                    setUpdatedVideoList(_list);
                    setVideoList(_list);
                  }}
                  handleSelect={(_videos: any) => setSelectedVideos(_videos)}
                  getNextPage={setPageIndex}
                  hasMore={hasMoreVideos}
                  isListLoading={isVideoLoading}
                  kind={'profile'}
                  type={'video-creative'}
                  // kind={selectedLoop?.length !== 0 ? 'loop' : 'profile'}
                />
              </div>
            </>
          )}

          {selectedCardID === 2 && (
            <CreativeSocialProfile
              instagram={
                socialProfiles?.filter((profile: any) => profile?.type === 'instagram') ?? []
              }
              tiktok={socialProfiles?.filter((profile: any) => profile?.type === 'tiktok') ?? []}
              youtube={socialProfiles?.filter((profile: any) => profile?.type === 'youtube') ?? []}
              socialProfiles={socialProfiles}
              setSocialProfiles={setSocialProfiles}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VideoLibrary;
