import { useState, useEffect } from 'react';
import { AiOutlineMobile } from 'react-icons/ai';
import { GoDeviceDesktop } from 'react-icons/go';
import Mobile from '../../../assets/images/mobile.png';
import Desktop from '../../../assets/images/desktop.png';
import ResolutionView from './ResolutionView';

const DeviceView = ({
  genuinUser,
  videos,
  selectedTagDetails,
  cutomerData,
  createTag,
  extentionData,
  websiteTodos,
  selectedOption,
  selectedResolution,
}: any) => {
  const [selectedButton, setSelectedButton] = useState('mobile');

  // console.log("selectedTagDetails",selectedTagDetails);
  // console.log("cutomerData",cutomerData);
  // console.log("createTag",createTag);
  // console.log("extentionData",extentionData);
  // console.log("selectedOption",selectedOption);
  // console.log("selectedResolution",selectedResolution);

  const handleButtonClick = (buttonType: any) => {
    setSelectedButton(buttonType);
    //@ts-ignore
    document.getElementById('myDiv').classList = buttonType;
  };

  useEffect(() => {
    //@ts-ignore
    document.getElementById('myDiv').classList = 'mobile';
  }, []);

  const landscapeView = [
    'Leaderboard (728 x 90 pixels)',
    'Banner (468 x 60 pixels)',
    'Large Leaderboard (970 x 90 pixels)',
  ];

  return (
    <div className='device-view-wrap'>
      <div className={selectedTagDetails ? 'device-view-tabs details' : 'device-view-tabs'}>
        <button
          className={selectedButton === 'mobile' ? 'active' : ''}
          onClick={() => handleButtonClick('mobile')}
        >
          <AiOutlineMobile fontSize={21} />
        </button>
        <button
          className={selectedButton === 'web' ? 'active' : ''}
          onClick={() => handleButtonClick('web')}
        >
          <GoDeviceDesktop fontSize={21} />
        </button>
      </div>
      <div className='device-view-body'>
        <div id='myDiv' className='DevicePreview-module_section'>
          <div className='DevicePreview-module_component'>
            {selectedButton === 'mobile' && (
              <img className='DevicePreview-module_image' alt='mobile' src={Mobile} />
            )}
            {selectedButton === 'web' && (
              <img className='DevicePreview-module_image' alt='desktop' src={Desktop} />
            )}
            <div className='DevicePreview-module_site'>
              <div className='DevicePreview-module_wrapper'>
                <div className='DevicePreview-module_wrapperScroller'>
                  <div className='index-module_header'>
                    <div className='index-module_container'>
                      <div className='index-module_logo'></div>
                      <div className='index-module_headerItem'></div>
                      <div className='index-module_headerItem'></div>
                      <div className='index-module_headerItem'></div>
                      <div className='index-module_headerItem'></div>
                      <div className='index-module_headerItem'></div>
                      <div className='index-module_headerItem'></div>
                      <div className='index-module_headerItem'></div>
                      <div className='index-module_search'></div>
                      <div className='index-module_profileImage'></div>
                      <div className='index-module_profileName'></div>
                    </div>
                  </div>
                  <div className='DevicePreview-module_container'>
                    <div className='DevicePreview-module_main'>
                      <div className='index-module_leftSidebar'>
                        <div className='index-module_leftSidebarItem'></div>
                        <div className='index-module_leftSidebarItem'></div>
                        <div className='index-module_leftSidebarItem'></div>
                        <div className='index-module_leftSidebarItem'></div>
                        <div className='index-module_leftSidebarItem'></div>
                      </div>
                      <div className='index-module_content'>
                        <div className='index-module_mainHeader'>
                          <div className='index-module_mainHeaderItem'></div>
                          <div className='index-module_mainHeaderItem'></div>
                          <div className='index-module_mainHeaderItem'></div>
                        </div>
                        <div className='index-module_block'>
                          <div className='index-module_row'>
                            <div
                              className='index-module_item'
                              style={{ width: '8px', marginRight: '10px' }}
                            ></div>
                            <div className='index-module_item' style={{ width: '60px' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div className='index-module_item' style={{ width: '100px' }}></div>
                          </div>
                        </div>
                        {selectedButton === 'mobile' &&
                          (selectedOption?.value === 'interstitial' ||
                            selectedOption?.value === 'end-roll' ||
                            selectedOption?.value === 'end_roll' ||
                            selectedOption?.value === 'convert-pip' ||
                            selectedOption?.value === 'convert_pip') && (
                            <div className='index-module_preview'>
                              <div className='index-module_previewWrapper'>
                                {createTag ? (
                                  <div
                                    className='gen-ext'
                                    data-company-id={`${cutomerData?.id ?? ''}`}
                                    data-tag-id={`${
                                      (extentionData?.tag_id || extentionData?.id) ?? ''
                                    }`}
                                    style={{
                                      height: selectedButton === 'mobile' ? '600px' : '600px',
                                      width: selectedButton === 'mobile' ? '250px' : '300px',
                                    }}
                                  ></div>
                                ) : cutomerData && selectedTagDetails ? (
                                  <div
                                    className='gen-ext'
                                    data-company-id={`${cutomerData?.id ?? ''}`}
                                    data-tag-id={`${selectedTagDetails?.id ?? ''}`}
                                    style={{
                                      height: selectedButton === 'mobile' ? '600px' : '600px',
                                      width: selectedButton === 'mobile' ? '250px' : '300px',
                                    }}
                                  ></div>
                                ) : null}
                              </div>
                            </div>
                          )}
                        {/* DESKTOP-Midroll*/}
                        {selectedButton &&
                          (selectedOption?.value === 'mid-roll' ||
                            selectedOption?.value === 'mid_roll') && (
                            <div className='index-module_block_midroll'>
                              <div className='index-module_preview'>
                                <div className='index-module_previewWrapper'>
                                  {createTag ? (
                                    <div
                                      className='gen-ext'
                                      data-company-id={`${cutomerData?.id ?? ''}`}
                                      data-tag-id={`${
                                        (extentionData?.tag_id || extentionData?.id) ?? ''
                                      }`}
                                      style={{
                                        height: selectedButton === 'mobile' ? '600px' : '600px',
                                        width: selectedButton === 'mobile' ? '250px' : '300px',
                                      }}
                                    ></div>
                                  ) : cutomerData && selectedTagDetails ? (
                                    <div
                                      className='gen-ext'
                                      data-company-id={`${cutomerData?.id ?? ''}`}
                                      data-tag-id={`${selectedTagDetails?.id ?? ''}`}
                                      style={{
                                        height: selectedButton === 'mobile' ? '600px' : '600px',
                                        width: selectedButton === 'mobile' ? '250px' : '300px',
                                      }}
                                    ></div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}

                        {/* DESKTOP-Interstitial-largeview (as per resolution) */}
                        {selectedButton &&
                          selectedOption?.value === 'interstitial' &&
                          landscapeView.includes(selectedResolution?.value) && (
                            <div className='index-module_block_midroll'>
                              <div className='index-module_preview'>
                                <div className='index-module_previewWrapper'>
                                  <ResolutionView
                                    resolution={selectedResolution?.value}
                                    companyID={cutomerData?.id}
                                    tagID={
                                      createTag ? extentionData?.tag_id : selectedTagDetails?.id
                                    }
                                    location={'center'}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        <div className='index-module_block'>
                          <div className='index-module_row'>
                            <div className='index-module_item' style={{ width: '50%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div className='index-module_item' style={{ width: '70%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div
                              className='index-module_item'
                              style={{ width: '8px', marginRight: '10px' }}
                            ></div>
                            <div className='index-module_item' style={{ width: '60%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div className='index-module_image' style={{ height: '70px' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div className='index-module_item' style={{ width: '70%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div className='index-module_item' style={{ width: '60%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div
                              className='index-module_item'
                              style={{ width: '7px', marginRight: '10px' }}
                            ></div>
                            <div
                              className='index-module_item'
                              style={{ width: '7px', marginRight: '10px' }}
                            ></div>
                            <div
                              className='index-module_item'
                              style={{ width: '7px', marginRight: '10px' }}
                            ></div>
                          </div>
                        </div>
                        <div className='index-module_block'>
                          <div className='index-module_row'>
                            <div className='index-module_item' style={{ width: '50%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div className='index-module_item' style={{ width: '70%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div
                              className='index-module_item'
                              style={{ width: '8px', marginRight: '10px' }}
                            ></div>
                            <div className='index-module_item' style={{ width: '60%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div className='index-module_image' style={{ height: '70px' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div className='index-module_item' style={{ width: '70%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div className='index-module_item' style={{ width: '60%' }}></div>
                          </div>
                          <div className='index-module_row'>
                            <div
                              className='index-module_item'
                              style={{ width: '7px', marginRight: '10px' }}
                            ></div>
                            <div
                              className='index-module_item'
                              style={{ width: '7px', marginRight: '10px' }}
                            ></div>
                            <div
                              className='index-module_item'
                              style={{ width: '7px', marginRight: '10px' }}
                            ></div>
                          </div>
                        </div>

                        {/* {selectedButton === 'mobile' && selectedOption?.value === 'interstitial' && 
                        <div className='index-module_preview'>
                          <div className='index-module_previewWrapper'>
                            {createTag ? (
                              <div
                                className='gen-ext'
                                data-company-id={`${cutomerData?.id ?? ''}`}
                                data-tag-id={`${extentionData?.tag_id ?? ''}`}
                                style={{
                                  height: selectedButton === 'mobile' ? '600px' : '600px',
                                  width: selectedButton === 'mobile' ? '250px' : '300px',
                                }}
                              ></div>
                            ) : cutomerData && selectedTagDetails ? (
                              <div
                                className='gen-ext'
                                data-company-id={`${cutomerData?.id ?? ''}`}
                                data-tag-id={`${selectedTagDetails?.id ?? ''}`}
                                style={{
                                  height: selectedButton === 'mobile' ? '600px' : '600px',
                                  width: selectedButton === 'mobile' ? '250px' : '300px',
                                }}
                              ></div>
                            ) : null}
                          </div>
                        </div> 
                        } */}
                        {/* DESKTOP-Endroll*/}
                        {selectedButton &&
                          selectedOption?.value !== 'interstitial' &&
                          (selectedOption?.value !== 'convert-pip' ||
                            selectedOption?.value !== 'convert_pip') &&
                          (selectedOption?.value !== 'mid-roll' ||
                            selectedOption?.value !== 'mid_roll') &&
                          (selectedOption?.value === 'end-roll' ||
                            selectedOption?.value === 'end_roll') && (
                            <div className='index-module_block_endroll'>
                              <div className='index-module_preview'>
                                <div className='index-module_previewWrapper'>
                                  {createTag ? (
                                    <div
                                      className='gen-ext'
                                      data-company-id={`${cutomerData?.id ?? ''}`}
                                      data-tag-id={`${
                                        (extentionData?.tag_id || extentionData?.id) ?? ''
                                      }`}
                                      style={{
                                        height: selectedButton === 'mobile' ? '600px' : '600px',
                                        width: selectedButton === 'mobile' ? '250px' : '300px',
                                      }}
                                    ></div>
                                  ) : cutomerData && selectedTagDetails ? (
                                    <div
                                      className='gen-ext'
                                      data-company-id={`${cutomerData?.id ?? ''}`}
                                      data-tag-id={`${selectedTagDetails?.id ?? ''}`}
                                      style={{
                                        height: selectedButton === 'mobile' ? '600px' : '600px',
                                        width: selectedButton === 'mobile' ? '250px' : '300px',
                                      }}
                                    ></div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <div>
                        <div className='index-module_rightSidebar'>
                          <div className='index-module_rightSidebarItem'></div>
                          <div className='index-module_rightSidebarItem'></div>
                          <div className='index-module_rightSidebarItem'></div>
                          <div className='index-module_rightSidebarItem'></div>
                          <div className='index-module_rightSidebarItem'></div>
                          <div className='index-module_rightSidebarItem'></div>
                          <div className='index-module_rightSidebarItem'></div>
                          <div className='index-module_rightSidebarItem'></div>
                          <div className='index-module_rightSidebarItem'></div>
                          <div className='index-module_rightSidebarItem'></div>
                        </div>
                        {/* DESKTOP-Interstitial */}
                        {selectedOption?.value === 'interstitial' && (
                          <div className='index-module_rightSidebar_1'>
                            <div className='index-module_preview'>
                              <div className='index-module_previewWrapper'>
                                {createTag ? (
                                  // <div
                                  //   className='gen-ext'
                                  //   data-company-id={`${cutomerData?.id ?? ''}`}
                                  //   data-tag-id={`${extentionData?.tag_id ?? ''}`}
                                  //   style={{
                                  //     height: '220px',
                                  //     width: '110px',
                                  //   }}
                                  // ></div>
                                  <ResolutionView
                                    resolution={selectedResolution?.value}
                                    companyID={cutomerData?.id}
                                    tagID={extentionData?.tag_id || extentionData?.id}
                                    location={'sidebar'}
                                  />
                                ) : cutomerData && selectedTagDetails ? (
                                  <ResolutionView
                                    resolution={selectedResolution?.value}
                                    companyID={cutomerData?.id}
                                    tagID={selectedTagDetails?.id}
                                    location={'sidebar'}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )}
                        {/* DESKTOP-Convert PIP */}
                        {(selectedOption?.value === 'convert-pip' ||
                          selectedOption?.value === 'convert_pip') && (
                          <div className='index-module_rightSidebar_2'>
                            <div className='index-module_preview'>
                              <div className='index-module_previewWrapper'>
                                {createTag ? (
                                  <div
                                    className='gen-ext'
                                    data-company-id={`${cutomerData?.id ?? ''}`}
                                    data-tag-id={`${
                                      (extentionData?.tag_id || extentionData?.id) ?? ''
                                    }`}
                                    style={{
                                      height: '220px',
                                      width: '110px',
                                    }}
                                  ></div>
                                ) : cutomerData && selectedTagDetails ? (
                                  <div
                                    className='gen-ext'
                                    data-company-id={`${cutomerData?.id ?? ''}`}
                                    data-tag-id={`${selectedTagDetails?.id ?? ''}`}
                                    style={{
                                      height: '220px',
                                      width: '110px',
                                    }}
                                  ></div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* {selectedButton === 'web' && selectedOption?.value === 'convert-pip' && 
                      <div className='DevicePreview-module_wrapper_pip'>
                        <div
                            className='gen-ext'
                            data-company-id={`${cutomerData?.id ?? ''}`}
                            data-tag-id={`${extentionData?.tag_id ?? ''}`}
                            style={{
                              height: '300px',
                              width: '150px',
                            }}
                          ></div>
                      </div>
                      } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceView;
