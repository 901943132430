import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Input from '../widgets/input';
import Button from '../widgets/button';
import { payoutRequestUpdateAPI } from '../../services/settings';
import store from '../../states';

export default (props: any) => {
  const { id, show, onHide, getData } = props;

  const [error] = useState('');
  const [reason, sReason] = useState('');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const requestRejected = () => {
    const payload = {
      id: id,
      status: 'rejected',
      reason: reason,
    };
    payoutRequestUpdateAPI(payload)
      .then((data: any) => {
        sReason('');
        onHide();
        toast.success(data.message);
        getData();
      })
      .catch(() => {
        //
      });
  };

  return (
    <Modal
      onHide={() => {
        onHide();
        sReason('');
      }}
      show={show}
      centered
      size={'lg'}
    >
      <Modal.Header>
        <h2 style={{ margin: 10 }}>Reason for Rejection</h2>
      </Modal.Header>
      <Modal.Body style={{ padding: '40px' }}>
        <>
          <div className={'row'} style={{ marginTop: 20 }}>
            <div className={'col-12'}>
              <label>Reason for Rejection</label>
              <textarea
                className='form-control mt-2 auto-textarea'
                placeholder={'Type a Message'}
                onChange={(e) => sReason(e.target.value)}
                rows={3}
                style={{ resize: 'none' }}
                maxLength={158}
              />
            </div>
            {/* <div className={'col'}>
              <Input
                kind='mini'
                type={'text'}
                error={error}
                name={'reason'}
                change={(e, v) => sReason(v)}
                label={'Reason for Rejection'}
                placeholder={'Type a Message'}
              />
            </div> */}
            {/* <div className={'col'} /> */}
          </div>
          {/* <br />
          <br /> */}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button kind={'secondary'} text={'Cancel'} click={() => onHide()} />
        <Button
          kind={reason !== '' ? 'primary' : 'disabled'}
          text={'Send'}
          click={() => requestRejected()}
        />
      </Modal.Footer>
    </Modal>
  );
};
