import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

import MyCreativesSidebar from '../../sidebar/MyCreatives';
import add from '../../../assets/icons/add.svg';
import Creatives from '../../modals/Creatives';
import Subscriptions from './Subscriptions';
import MyCreativesOverview from '../../components/overview/MyCreatives';

import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';
import { extensionDetailsAPI } from '../../../services/extension';
import { tag as creativeTiles } from '../../helpers/tiles';
import { MdDeleteOutline, MdInfoOutline, MdOutlineRemoveRedEye } from 'react-icons/md';
import { BiEditAlt } from 'react-icons/bi';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';
import { UserInfoContext } from '../../contexts/userInfoContext';
import { creativeGetLoopListAPI, creativeGetUserProfileAPI } from '../../../services/creative';
import DeleteTag from '../../modals/tag-delete';
import { deleteTagAPI } from '../../../services/tags';
import Reports from '../../helpers/reports';
import PageSkeleton from '../../loaders/page-skeleton';
import { modifiedID } from '../../helpers/functions';
// import { PiDotsThreeCircleVertical } from 'react-icons/pi';
import CreativeAdd from '../../modals/creative-add';
import CreativeEdit from '../../modals/creative-edit';
import Button from '../../widgets/button';

const MyCreatives = () => {
  const location = useLocation();
  const history = useHistory();

  // Context API
  const { glossary, customerInfo, userInfo }: any = useContext(UserInfoContext);
  const { getTagsListAPI }: any = useAuthentication();
  const { tags, sTags, dateRangeInventory, sDateRangeInventory }: any = consumerData();

  // Get from initial state
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isOverDue, setIsOverDue] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    if (customerInfo?.stripe_data) {
      setIsSubscribed(customerInfo?.stripe_data?.subscribe ? true : false);
      setIsOverDue(customerInfo?.stripe_data?.over_due);
      setPageLoader(false);
    }
  }, [customerInfo?.stripe_data]);

  // Convert Date
  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  // Pagination States
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [currentPage, sCurrentPage] = useState(0);
  const [hasMore, sHasMore] = useState(true);
  const [params, setParams] = useState({
    page: 0,
    query: '',
    sortBy: {
      id: 'earning',
      desc: true,
    },
  });

  // Others States
  const [createShow, setCreateShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [selectedCreativeID, setSelectedCreativeID] = useState<number>(0);
  const [creativeDetails, setCreativeDetails] = useState<any>({});
  const [currentDateSelection, setCurrentDateSelection] = useState({
    startDate: convert(new Date()),
    endDate: convert(new Date()),
  });
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [singleTileData, setSingleTileData] = useState([]);
  const [modalSection, setModalSection] = useState('1');
  const [paramsStatus, setParamsStatus] = useState(false);
  const [loopList, setLoopList] = useState([]);
  const [genuinUserInfo, setGenuinUserInfo] = useState({});

  const [dataTables, setDataTables] = useState([]);
  const [random, setRandom] = useState(Math.random());

  const createCreatives = () => {
    setModalSection('1');
    setCreateShow(true);
  };

  const updateCreatives = (_section: string) => {
    setModalSection(_section);
    setEditShow(true);
  };

  const handleParamsChange = (page: number, query: string, sortBy: any, status: boolean) => {
    if (
      page !== params.page ||
      query !== params.query ||
      sortBy.id !== params.sortBy.id ||
      sortBy.desc !== params.sortBy.desc
    ) {
      setParams({ page, query, sortBy });
      setParamsStatus(status);
    }
  };

  const getCreativesList = (params: any, id: any) => {
    const payload = {
      page: params.page,
      page_size: 500,
      query: params.query,
      sort_by: {
        sortBy: [params.sortBy],
      },
    };
    setIsListLoading(true);
    getTagsListAPI(payload)
      .then((data: any) => {
        if (data.status) {
          sCurrentPage(params.page);
          //For Page Scroll
          if (params.page > 0) {
            if (data.data.length !== 0) {
              sTags([...tags, ...data.data]);
            } else {
              sHasMore(false);
              sTags(tags);
            }
          } else {
            sTags(data.data);
            setIsListEmpty(data.data.length < 1);
            if (data?.data?.length < 1) {
              history.push(`/my-creatives`);
            }
            if (data?.data?.length > 0 && params?.query !== '') {
              selectCreative(data?.data[0]?.id);
            }
            if (data?.data?.length > 0 && !id) {
              selectCreative(data?.data[0]?.id);
            } else {
              selectCreative(parseInt(id));
            }
          }
        }
        setIsListLoading(false);
      })
      .catch((error: any) => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  useEffect(() => {
    const creativeID = location.pathname.split('/my-creatives/')[1];
    if (tags.length === 0) {
      getCreativesList(params, parseInt(creativeID));
    }
    if (paramsStatus === true) {
      getCreativesList(params, parseInt(creativeID));
    }
  }, [params.page, params.query, params.sortBy, paramsStatus]);

  const selectCreative = (id: number, _props?: any) => {
    if (selectedCreativeID !== id && !isNaN(id)) {
      setDataTables([]);
      setSelectedCreativeID(id);
      location.pathname.startsWith('/my-creatives') && history.push(`/my-creatives/${id}`);
      getGraphData(id, _props);
    }
  };

  const getGraphData = (id: any, _props?: any) => {
    const payload = {
      id: modifiedID(id),
      param: 'overview',
      _payload: {
        start_date: convert(dateRangeInventory?.startDate) || '',
        end_date: convert(dateRangeInventory?.endDate) || '',
        ...(_props?.depth === 1 && {
          tag_height: _props?.tag_height,
          tag_width: _props?.tag_width,
        }),
      },
    };
    setCurrentDateSelection(dateRangeInventory);
    if (dateRangeInventory?.startDate !== dateRangeInventory?.endDate) {
      setIsGraphLoading(true);
      extensionDetailsAPI(payload)
        .then((data: any) => {
          if (data.status) {
            setRandom(Math.random());
            const tileData = creativeTiles(data.total);
            setSingleTileData(tileData);
            setDataTables(tags[0]?.extension_tables);
            // setDataTables(tags?.find((x: any) => x.id === id)?.extension_tables);
            setIsGraphLoading(false);
          }
        })
        .catch((error: any) => {
          // error
        });
    }
  };

  const handleCreativesList = (_creativeObj: any, kind: string) => {
    if (kind === 'add') {
      const newCreativeObject = { ..._creativeObj };
      const newArr = [newCreativeObject, ...tags];
      selectCreative(newCreativeObject.id);
      sTags(newArr);
    }

    if (kind === 'update') {
      const newCreativeObj = { ..._creativeObj };
      const newArray = [...tags];
      const index = newArray.findIndex((item) => item.id === newCreativeObj.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...newCreativeObj };
      }
      sTags(newArray);
      setSelectedCreativeID(newCreativeObj?.id);
    }
  };

  const handleDeleteReqDeal = (id: number) => {
    const payload = {
      id,
    };
    deleteTagAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          sTags((prevData: any) => {
            const indexToRemove = prevData?.findIndex((item: any) => item.id == id);
            const newData = [
              ...prevData.slice(0, indexToRemove),
              ...prevData.slice(indexToRemove + 1),
            ];
            if (indexToRemove < prevData.length - 1) {
              const nextId = prevData[indexToRemove + 1];
              selectCreative(nextId?.id);
            } else if (indexToRemove > 0) {
              const prevId = prevData[indexToRemove - 1];
              selectCreative(prevId?.id);
            } else {
              sTags([]);
              setIsListEmpty(true);
              history.push(`/my-creatives`);
              setSelectedCreativeID(0);
            }

            return newData;
          });
        }
      })
      .catch((error: any) => {
        //error
      });
  };

  const GetSelectionRangeProps = (date: any) => {
    sDateRangeInventory(date);
  };

  // Select card default first while change tab from header by user.
  const handleSelect = () => {
    selectCreative(tags[0]?.id);
  };

  // Get Looplist && Genuin User Profile
  useEffect(() => {
    if (userInfo?.genuine_user_name) {
      handleSelect();
      setGenuinUserInfo(userInfo?.genuine_user_name);

      // const payload = {
      //   page: 0,
      //   page_size: 200,
      // };
      // creativeGetLoopListAPI(payload)
      //   .then((data: any) => {
      //     if (data?.data) {
      //       setLoopList(data?.data);
      //     }
      //   })
      //   .catch((error: any) => {
      //     // error
      //   });

      creativeGetUserProfileAPI({ nickname: userInfo?.genuine_user_name })
        .then((data: any) => {
          if (data?.status) {
            // setGenuinUserInfo(data?.data);
          }
        })
        .catch((error: any) => {
          // error
        });
    }
  }, [userInfo?.genuine_user_name]);

  useEffect(() => {
    if (tags?.length > 0 && selectedCreativeID) {
      // Can't change to strict === in below condition because one is string or another one is interger
      const tagDetails = tags?.find((x: any) => x?.id == selectedCreativeID);

      const tag = tags?.find((x: any) => x?.id == modifiedID(selectedCreativeID));
      const creativeDetails = tag?.tag_sizes?.find((_j: any) => _j.id == selectedCreativeID);

      setCreativeDetails(tagDetails || creativeDetails);
    }
  }, [selectedCreativeID, tags]);

  useEffect(() => {
    if (
      selectedCreativeID &&
      (convert(currentDateSelection?.startDate) !== convert(dateRangeInventory?.startDate) ||
        convert(currentDateSelection?.endDate) !== convert(dateRangeInventory?.endDate))
    ) {
      getGraphData(selectedCreativeID);
    }
  }, [dateRangeInventory, selectedCreativeID]);

  const buttonText: any = (kind: string) => {
    if (kind === 'edit') {
      return (
        <>
          <BiEditAlt fontSize={18} /> Edit
        </>
      );
    } else if (kind === 'details') {
      return (
        <>
          <MdOutlineRemoveRedEye fontSize={18} /> View
        </>
      );
    } else if (kind === 'delete') {
      return (
        <>
          <MdDeleteOutline fontSize={18} /> Delete
        </>
      );
    }
  };

  return (
    <>
      <DeleteTag
        api={() => handleDeleteReqDeal(selectedCreativeID)}
        show={deleteShow}
        onHide={() => setDeleteShow(false)}
      />

      <CreativeAdd
        show={createShow}
        onHide={() => setCreateShow(false)}
        glossary={glossary}
        pSection={'1'}
        creativeDetails={{}}
        creativeConfig={{}}
        genuinUserInfo={genuinUserInfo}
        mode={'create'}
        controller={handleCreativesList}
      />

      <CreativeEdit
        show={editShow}
        onHide={() => setEditShow(false)}
        glossary={glossary}
        pSection={modalSection}
        creativeDetails={creativeDetails}
        creativeConfig={creativeDetails?.config}
        genuinUserInfo={genuinUserInfo}
        mode={'edit'}
        controller={handleCreativesList}
      />

      {pageLoader && <PageSkeleton />}

      {!pageLoader && !isSubscribed && <Subscriptions glossary={glossary} isOverDue={isOverDue} />}

      {!pageLoader && isSubscribed && (
        <div className={'content d-flex'}>
          <MyCreativesSidebar
            title={'Creatives'}
            kind={'creative'}
            card={'expanded'}
            action={{
              icon: add,
              text: 'Generate Creative Tag',
              click: createCreatives,
              className: 'tour-addnew-creative',
            }}
            items={tags}
            selected={selectedCreativeID?.toString()}
            info={(id: any, _props: any) => {
              selectCreative(parseFloat(id), _props); // parseFloat for after update creative skeleton is not stop.
            }}
            empty={isListEmpty}
            loading={isListLoading}
            glossary={glossary}
            currentPage={currentPage}
            params={params}
            hasMore={hasMore}
            handleParams={handleParamsChange}
          />
          <div className='view-section'>
            {/* Demo view for empty tag */}
            {tags?.length === 0 && selectedCreativeID === 0 && (
              <MyCreativesOverview isLoading={isListLoading} kind='empty' />
            )}

            {/* Graph view */}
            {tags?.length > 0 && selectedCreativeID !== 0 && (
              <>
                <div className='row align-items-center'>
                  <div className='col-lg-8 col-md-12'>
                    <div className='d-flex gap-3 align-items-center'>
                      <div style={{ maxWidth: '290px' }}>
                        <h2 className='text-truncate' title={creativeDetails?.name}>
                          {creativeDetails?.name}
                        </h2>
                      </div>
                      {creativeDetails?.depth === 0 && (
                        <>
                          <Button
                            css={{
                              height: '30px',
                              padding: '3px 5px',
                            }}
                            text={buttonText('details')}
                            kind={'secondary'}
                            click={() => updateCreatives('3')}
                          />
                          <Button
                            css={{
                              height: '30px',
                              padding: '3px 5px',
                            }}
                            text={buttonText('edit')}
                            kind={'secondary'}
                            click={() => updateCreatives('1')}
                          />
                          <Button
                            css={{
                              height: '30px',
                              padding: '3px 5px',
                            }}
                            text={buttonText('delete')}
                            kind={'error'}
                            click={() => setDeleteShow(true)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* <div className='col-lg-1 col-md-12'></div> */}
                  <div className='col-lg-4 col-md-12'>
                    <DateRangeFilter
                      onChange={GetSelectionRangeProps}
                      selectionRange={dateRangeInventory}
                    />
                  </div>
                </div>

                <MyCreativesOverview
                  kind='performance'
                  singleTileData={singleTileData}
                  selectedID={selectedCreativeID}
                  isLoading={isGraphLoading}
                />

                <div className={'report-table'} style={{ marginTop: 15 }}>
                  <Reports
                    id={selectedCreativeID}
                    start={dateRangeInventory?.startDate}
                    end={dateRangeInventory?.endDate}
                    tabs={dataTables}
                    random={random}
                    customerId={customerInfo?.id}
                    entityType={'extension'}
                    metaData={{
                      tag_height: creativeDetails?.tag_height,
                      tag_width: creativeDetails?.tag_width,
                    }}
                  />
                </div>
              </>
            )}

            {/* Tag & Details Not found */}
            {/* {isListEmpty && !isListLoading && <>Tag Not Found</>} */}
          </div>
        </div>
      )}
    </>
  );
};

export default MyCreatives;
