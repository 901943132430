import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FixedSizeList } from 'react-window';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import { ListChildComponentProps } from 'react-window';
import ButtonSkeleton from '../loaders/button';

interface Props {
  option: any;
  isMulti?: boolean;
  isClearable?: boolean;
  hasSelectAll?: boolean;
  value: any;
  onSelect(selected: any): void;
  label: string;
  menuPlacement?: string;
  isSearchable?: boolean;
  //Infinite Scroll
  isLoading?: boolean;
  hasMore?: boolean;
  params?: any;
  handleParams?: any;
}

const InfyScrollDropdown = (Props: Props) => {
  const {
    option,
    value,
    label,
    isClearable,
    isMulti,
    hasSelectAll,
    menuPlacement,
    isSearchable,
    onSelect,
    // Infinite Scroll
    isLoading,
    hasMore,
    params,
    handleParams,
  } = Props;

  const [selectedItems, setSelectedItems] = useState<any>(value ?? []); // Selected items state
  const [searchQuery, setSearchQuery] = useState(''); // Search input value
  const [isOpen, setIsOpen] = useState(false); // Dropdown open/close state

  const dropdownRef = useRef(null); // Ref to the dropdown container
  const listRef = useRef(null); // Ref to the list container

  useEffect(() => {
    if (value) {
      setSelectedItems(value);
    }
  }, [value]);

  // Toggle dropdown open/close state
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle click outside dropdown to close it
  const handleClickOutside = (event: any) => {
    //@ts-ignore
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchQuery('');
    }
  };

  // Attach click outside event listener on mount and remove it on unmount
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleItemSelection = (item: any) => {
    if (isMulti) {
      // Multi-select
      const isSelected = selectedItems.some(
        (selectedItem: any) => selectedItem.value === item.value
      );
      let updatedItems: any = [];
      if (isSelected) {
        updatedItems = selectedItems.filter(
          (selectedItem: any) => selectedItem.value !== item.value
        );
      } else {
        updatedItems = [...selectedItems, item];
      }
      setSelectedItems(updatedItems);
      onSelect(updatedItems);
    } else {
      // Single-select
      setSelectedItems([item]);
      setIsOpen(false);
      onSelect([item]);
    }
  };

  const selectAllFilteredItems = () => {
    if (isAllFilteredItemsSelected()) {
      // Uncheck all selected items in the filtered list
      const unselectedItems = selectedItems.filter(
        (selectedItem: any) =>
          !filteredItems.some((filteredItem: any) => filteredItem.value === selectedItem.value)
      );
      setSelectedItems(unselectedItems);
      onSelect(unselectedItems);
    } else {
      // Check all filtered items
      const selectedFilteredItems = filteredItems.filter(
        (filteredItem: any) =>
          !selectedItems.some((selectedItem: any) => selectedItem.value === filteredItem.value)
      );
      const updatedItems = [...selectedItems, ...selectedFilteredItems];
      setSelectedItems(updatedItems);
      onSelect(updatedItems);
    }
  };

  // Filter items based on search query
  const filteredItems = option.filter((item: any) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Check if all filtered items are selected
  const isAllFilteredItemsSelected = () => {
    return (
      filteredItems.length > 0 &&
      filteredItems.every((filteredItem: any) =>
        selectedItems.some((selectedItem: any) => selectedItem.value === filteredItem.value)
      )
    );
  };

  // Rendered row for each item
  const Row = ({ index, style }: ListChildComponentProps) => {
    const item = filteredItems[index];
    const isSelected = selectedItems.some((selectedItem: any) => selectedItem.value === item.value);

    return (
      <div
        key={index}
        className={`custom-dropdown-list-item d-flex align-items-center ${
          isSelected ? 'selected' : ''
        }`}
        style={style}
        onClick={() => toggleItemSelection(item)}
      >
        {isMulti && (
          <input
            type='checkbox'
            checked={isSelected}
            className='custom-dropdown-list-item-checkbox'
            onChange={() => toggleItemSelection(item)}
          />
        )}
        {item.icon && (
          <img
            src={item.icon}
            alt='Icon'
            className='custom-dropdown-icon me-2'
            style={{ width: '28px', height: '28px', borderRadius: '50%' }}
          />
        )}
        <label className='mt-1'>{item.label}</label>
      </div>
    );
  };

  // Calculate the height of the list container
  const getListHeight = () => {
    if (filteredItems.length > 5) {
      return 5 * 40; // Display 5 items at a time
    }
    return filteredItems.length * 40;
  };

  // Entire list clear by click on cross icon
  const handleSelectedClear = () => {
    setSelectedItems([]);
    onSelect([]);
  };

  // Handle search query change
  const handleSearch = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);
    // searchData(query);
  };

  //   const lastItem = displayItems[displayItems.length - 1];
  const lastItem = option[option.length - 1];
  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      if (!isLoading && hasMore && visibleStopIndex === option.length - 1 && lastItem) {
        handleParams(params.page + 1, params.query);
      }
    },
    [params.page, params.query, option.length, lastItem]
  );

  useEffect(() => {
    if (searchQuery !== params.query) {
      const getData: any = setTimeout(() => {
        handleParams(0, searchQuery);
        // filteredItems([]); //Pending
      }, 300);
      return () => clearTimeout(getData);
    }
  }, [searchQuery]);

  // Selected item text return
  const selectedItemsText =
    isMulti && option.length > 0 && selectedItems.length === option.length
      ? 'All items are selected.'
      : selectedItems.map((item: any) => item.label).join(', ');

  // Rendered dropdown
  return (
    <>
      <div className='custom-dropdown-container' ref={dropdownRef}>
        <label className='label'>{label}</label>
        <div className='custom-dropdown-input-container' onClick={toggleDropdown} tabIndex={0}>
          <input
            type='text'
            className='custom-dropdown-input'
            placeholder='Select an item'
            value={selectedItemsText}
            readOnly
          />
          <div className={'dropdown-icon'}>
            {isMulti && selectedItems.length > 0 && (
              <RxCross2 className='svg-icon me-2' fontSize={20} onClick={handleSelectedClear} />
            )}
            {isOpen ? (
              <IoIosArrowUp className='svg-icon up-icon' fontSize={20} />
            ) : (
              <IoIosArrowDown className='svg-icon down-icon' fontSize={20} />
            )}
          </div>
        </div>
        {isOpen && ( // Render the dropdown only if it's open
          <div className={`custom-dropdown-list-container ${menuPlacement ?? 'bottom'}`}>
            <div className='custom-dropdown-list'>
              {/* Search Section */}
              {isSearchable && (
                <div
                  className='custom-dropdown-list-item'
                  style={{ backgroundColor: '#F9F6F9', borderBottom: '1px solid #ccc' }}
                >
                  <input
                    type='search'
                    placeholder='Search'
                    value={searchQuery}
                    onChange={handleSearch} // Handle search query change
                    autoFocus
                  />
                </div>
              )}

              {/* Select All item */}
              {hasSelectAll && (
                <>
                  {searchQuery !== ''
                    ? filteredItems.length > 5
                    : option.length > 0 && (
                        <div
                          className={`custom-dropdown-list-item select-all ${
                            isAllFilteredItemsSelected() ? 'selected' : ''
                          }`}
                          onClick={selectAllFilteredItems}
                        >
                          <input
                            type='checkbox'
                            checked={isAllFilteredItemsSelected()}
                            className='custom-dropdown-list-item-checkbox'
                            onChange={selectAllFilteredItems}
                          />
                          <label>Select All</label>
                        </div>
                      )}
                </>
              )}

              {/* Item List */}
              {filteredItems.length > 0 ? (
                <div className='list-container' ref={listRef}>
                  <FixedSizeList
                    height={getListHeight()}
                    width={`100%`}
                    itemSize={40}
                    itemCount={filteredItems.length}
                    outerRef={listRef}
                    onItemsRendered={handleItemsRendered}
                  >
                    {Row}
                  </FixedSizeList>
                </div>
              ) : (
                <div
                  className='label text-center mt-1 d-flex align-items-center justify-content-center'
                  style={{ height: '35px' }}
                >
                  No options
                </div>
              )}

              {/* Skeleton View */}
              {isLoading && (
                <div className='loader p-2'>
                  <ButtonSkeleton height={'20px'} width={'100%'} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InfyScrollDropdown;
