import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import FormB from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import Radio from '../../widgets/radio';
import Switch from '../../widgets/switch';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import DeviceTypeCheckBox from '../../helpers/DeviceTypeCheckBox';
import { addEndpointAPI, updateEndpointAPI, uploadCreativeAPI } from '../../../services/endpoint';
import store from '../../../states';
import Button from '../../widgets/button';
import FormikController from '../../comman/form/FormikController';
import DatePicker from '../../comman/datepicker/DatePicker';
import ButtonR from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ReactSelect from 'react-select';
import { getMacrosListAPI } from '../../../services/macros';
import { roundTime, windowReload } from '../../helpers/functions';

interface Props {
  enpointListDataConfigObj?: any;
  onHide?: any;
  endpointId?: any;
  enpointListData?: any;
  getEndPointListCard?: any;
  endpontDataStatus?: any;
  editDetailsDSPModal?: any;
  openDetailsModal?: any;
  getEndpointListInfoAPI?: any;
  xmlEndpoint?: any;
  handleCloseModal?: any;
  customerID?: number;
}

const InsertionOrder = (props: Props) => {
  const {
    onHide,
    enpointListDataConfigObj,
    endpointId,
    getEndPointListCard,
    endpontDataStatus,
    openDetailsModal,
    enpointListData,
    xmlEndpoint,
    handleCloseModal,
    customerID,
  } = props;

  const [videoCreativeType, setVideoCreativeType] = useState(
    enpointListData?.type ?? 'vast_upload'
  );
  const [deviceTypeArr, setDeviceTypeArr] = useState([]);
  const [impCapping, setImpCapping] = useState(
    enpointListDataConfigObj?.daily_imp_cap > 0 ? true : false
  );
  const [editDetailsDSPModal, sEditDetailsDSPModal] = useState<any>(false);
  const [checkboxError, setCheckboxError] = useState(deviceTypeArr?.length > 0 ? false : true);
  const [uploadFileError, setUploadFileError] = useState('');
  const fileInputRef: any = useRef(null);
  const [creativeUploading, setCreativeUploading] = useState(false);
  const [startDate, setStartDate] = useState(
    enpointListDataConfigObj?.start_date
      ? new Date(enpointListDataConfigObj?.start_date.toString())
      : roundTime(new Date())
  );
  const [endDate, setEndDate] = useState(
    enpointListDataConfigObj?.end_date
      ? new Date(enpointListDataConfigObj?.end_date.toString())
      : roundTime(new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000))
  );
  const [isLoading, setIsLoading] = useState(false);

  // const currentDate = new Date();

  // const [tagStatus, setTagStatus] = useState('');
  // useEffect(() => {
  //   if (endDate < currentDate) {
  //     setTagStatus('completed');
  //   } else if (startDate > currentDate) {
  //     setTagStatus('upcoming');
  //   } else {
  //     setTagStatus('running');
  //   }
  // }, [enpointListDataConfigObj, currentDate]);

  const [videoUrl, setVideoUrl] = useState(enpointListDataConfigObj?.video_url ?? '');

  // VAST URL
  const [integrationName, sIntegrationName] = useState(enpointListData?.name ?? '');
  const [cpm, setCpm] = useState(enpointListData?.floor_price ?? '');
  const [budget, setBudget] = useState(enpointListDataConfigObj?.budget ?? '');
  const [advDomain, setAdvDomain] = useState(enpointListDataConfigObj?.advertiser_domain ?? '');
  const [impCap, setImpCap] = useState(enpointListDataConfigObj?.daily_imp_cap ?? 0);
  const [_vastUrl, _setVastUrl] = useState(enpointListData?.endpoint ?? '');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const IoFormInitialValues = {
    integrationName: enpointListData?.name ?? '',
    integrationVastxml: xmlEndpoint ?? '',
    integrationVasturl: _vastUrl ?? '',
    // integrationVasturl: xmlEndpoint ?? '',
    integrationCPM: enpointListData?.floor_price ?? '',
    // integrationBudget: enpointListDataConfigObj?.budget ?? '',
    advertiserDomain: enpointListDataConfigObj?.advertiser_domain ?? '',
    impressionCapping: enpointListDataConfigObj?.daily_imp_cap ?? '',
    clickURL: enpointListDataConfigObj?.click_through_url ?? '',
  };

  const IoFormInitialValuesURL = {
    integrationName: integrationName ?? '',
    integrationVasturl: _vastUrl ?? '',
    integrationCPM: cpm ?? '',
    // integrationBudget: budget ?? '',
    advertiserDomain: advDomain ?? '',
    impressionCapping: impCap ?? '',
  };

  const handleVidioCreative = (id: string) => {
    setVideoCreativeType(id);
  };

  /* Endpoint Add Function */
  const initiateInsertionOrder = (e: any) => {
    ReactGA.event('dsp_test_integration_button', {
      category: 'add_endpoints',
      action: 'test_integration_button',
      label: 'test_integration',
    });

    const CPMFloat = parseFloat(e.integrationCPM);
    const ImpCappingFloat = parseFloat(e.impressionCapping);

    const payload = {
      customer_id: customerID,
      endpoint:
        videoCreativeType === 'vast_xml'
          ? e.integrationVastxml
          : videoCreativeType === 'vast_url'
          ? e.integrationVasturl
          : videoUrl,
      endpoint_type: videoCreativeType,
      name: e.integrationName,
      floor_price: CPMFloat,
      config: {
        advertiser_domain: e.advertiserDomain,
        budget: budget !== '' ? parseFloat(budget) : null,
        min_cpm: CPMFloat,
        start_date: startDate.toUTCString(),
        end_date: endDate.toUTCString(),
        device_types: deviceTypeArr,
        daily_imp_cap: !impCapping ? null : ImpCappingFloat,
        max_cpm: 0,
        is_soft_floor: false, //Default
        is_all_traffic: false, //Default
        click_through_url: e.clickURL,
      },
    };
    setIsLoading(true);
    addEndpointAPI(payload)
      .then((data: any) => {
        if (endpontDataStatus === false && data?.status) {
          windowReload();
        }
        if (data?.status === true) {
          getEndPointListCard && getEndPointListCard(data, 'add');
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error: any) => {
        toast.error(glossary.service_unavailable);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      });
  };

  /* Endpoint Update Function */
  const updateEndpoint = (e: any) => {
    ReactGA.event('dsp_test_integration_button', {
      category: 'add_endpoints',
      action: 'test_integration_button',
      label: 'test_integration',
    });

    const CPMFloat = parseFloat(e.integrationCPM);
    const ImpCappingFloat = parseFloat(e.impressionCapping);

    const payload = {
      id: endpointId,
      customer_id: customerID,
      endpoint:
        videoCreativeType === 'vast_xml'
          ? e.integrationVastxml
          : videoCreativeType === 'vast_url'
          ? e.integrationVasturl
          : videoUrl,
      endpoint_type: videoCreativeType,
      name: e.integrationName,
      floor_price: CPMFloat,
      config: {
        advertiser_domain: e.advertiserDomain,
        budget: budget !== '' ? parseFloat(budget) : null,
        min_cpm: CPMFloat,
        start_date: startDate.toUTCString(),
        end_date: endDate.toUTCString(),
        device_types: deviceTypeArr,
        daily_imp_cap: !impCapping ? 0 : ImpCappingFloat,
        max_cpm: 0,
        is_soft_floor: false, //Default
        is_all_traffic: false, //Default
        click_through_url: e.clickURL,
      },
    };

    setIsLoading(true);
    updateEndpointAPI(payload)
      .then((data: any) => {
        if (data?.status === true) {
          toast.success(data.message);
          getEndPointListCard(data, 'update');
        } else {
          toast.error(data?.message);
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        toast.error(glossary.dsp_update_rtb_faild_server_msg);
      });
  };

  /* Regex Validation */
  const re = /^(http|https):/; //gm
  const valReg = /^\d+(\.\d{1,2})?$/;
  const websiteUrlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const domainReg =
    /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  // impressionCapping: Yup.number().when('impCapping', {
  //   is: true,
  //   then: Yup.number()
  //     .required('Enter impression capping value')
  //     .typeError('Enter valid impression capping value'),
  //   otherwise: Yup.number(),
  // }),

  /* Validation Schema */
  const IoFormSchema = Yup.object().shape({
    integrationName: Yup.string().required(glossary.dsp_add_io_popup_input_name_empty_error),
    integrationCPM: Yup.number()
      .required(glossary.dsp_add_io_popup_input_cpm_empty_error)
      .test('superior', glossary.dsp_add_io_popup_input_cpm_valid_error, function (f2: any) {
        return valReg.test(f2);
      })
      .max(99999, glossary.dsp_add_io_popup_input_cpm_length_error)
      .typeError(glossary.dsp_add_io_popup_input_cpm_type_error),
    advertiserDomain: Yup.string()
      .required(glossary.dsp_add_io_popup_input_adv_domain_empty_error)
      .matches(domainReg, glossary.dsp_add_io_popup_input_adv_domain_type_error)
      .typeError(glossary.dsp_add_io_popup_input_adv_domain_type_error),
    clickURL: Yup.string().matches(websiteUrlRegex, 'Valid link url.'),
  });

  const IoFormSchemaXML = Yup.object().shape({
    integrationName: Yup.string().required(glossary.dsp_add_io_popup_input_name_empty_error),
    integrationVastxml: Yup.string().required(
      glossary.dsp_add_io_popup_field_input_vastxml_empty_error
    ),
    integrationCPM: Yup.number()
      .required(glossary.dsp_add_io_popup_input_cpm_empty_error)
      .test('superior', glossary.dsp_add_io_popup_input_cpm_valid_error, function (f2: any) {
        return valReg.test(f2);
      })
      .max(99999, glossary.dsp_add_io_popup_input_cpm_length_error)
      .typeError(glossary.dsp_add_io_popup_input_cpm_type_error),
    // integrationBudget: Yup.number()
    //   // .required(glossary.dsp_add_io_popup_input_budget_empty_error)
    //   .test('superior', glossary.dsp_add_io_popup_input_budget_valid_error, function (f2: any) {
    //     return valReg.test(f2);
    //   })
    //   .typeError(glossary.dsp_add_io_popup_input_budget_type_error),
    advertiserDomain: Yup.string()
      .required(glossary.dsp_add_io_popup_input_adv_domain_empty_error)
      .matches(domainReg, glossary.dsp_add_io_popup_input_adv_domain_type_error)
      .typeError(glossary.dsp_add_io_popup_input_adv_domain_type_error),
    // impressionCapping: Yup.number()
    //   .required(glossary.dsp_add_io_popup_input_imp_cap_empty_error)
    //   .test('superior', glossary.dsp_add_io_popup_input_imp_cap_valid_error, function (f2: any) {
    //     return valReg.test(f2);
    //   })
    //   .typeError(glossary.dsp_add_io_popup_input_imp_cap_type_error),
  });

  const IoFormSchemaURL = Yup.object().shape({
    integrationName: Yup.string().required(glossary.dsp_add_io_popup_input_name_empty_error),
    integrationVasturl: Yup.string()
      .required(glossary.dsp_add_io_popup_field_input_vasturl_empty_error)
      .matches(re, glossary.dsp_add_io_popup_field_input_vasturl_valid_error),
    integrationCPM: Yup.number()
      .required(glossary.dsp_add_io_popup_input_cpm_empty_error)
      .test('superior', glossary.dsp_add_io_popup_input_cpm_valid_error, function (f2: any) {
        return valReg.test(f2);
      })
      .max(99999, glossary.dsp_add_io_popup_input_cpm_length_error)
      .typeError(glossary.dsp_add_io_popup_input_cpm_type_error),
    // integrationBudget: Yup.number()
    //   // .required(glossary.dsp_add_io_popup_input_budget_empty_error)
    //   .test('superior', glossary.dsp_add_io_popup_input_budget_valid_error, function (f2: any) {
    //     return valReg.test(f2);
    //   })
    //   .typeError(glossary.dsp_add_io_popup_input_budget_type_error),
    advertiserDomain: Yup.string()
      .required(glossary.dsp_add_io_popup_input_adv_domain_empty_error)
      .matches(domainReg, glossary.dsp_add_io_popup_input_adv_domain_type_error)
      .typeError(glossary.dsp_add_io_popup_input_adv_domain_type_error),
    // impressionCapping: Yup.number()
    //   .required(glossary.dsp_add_io_popup_input_imp_cap_empty_error)
    //   .test('superior', glossary.dsp_add_io_popup_input_imp_cap_valid_error, function (f2: any) {
    //     return valReg.test(f2);
    //   })
    //   .typeError(glossary.dsp_add_io_popup_input_imp_cap_type_error),
  });

  /* validation checkbox */
  const checkBoxRequired = () => {
    sBackSelect([]);
    if (deviceTypeArr?.length > 0) {
      setCheckboxError(true);
    } else {
      setCheckboxError(false);
    }
  };

  /* auto height for textarea */
  document.querySelectorAll('textarea').forEach((el: any) => {
    el.style.height = el.setAttribute('style', 'height: ' + el.scrollHeight + 'px');
    el.classList.add('auto');
    el.addEventListener('input', (e: any) => {
      el.style.height = 'auto';
      el.style.height = el.scrollHeight + 'px';
    });
  });

  /* Get Date Function */
  const getStartDate = (data: any) => {
    if (data) {
      setStartDate(data);
      // const adjustedEndDate = new Date(data.getTime() + 24 * 60 * 60 * 1000);
      // getEndDate(adjustedEndDate);
    }
  };

  const getEndDate = (data: any) => {
    if (data) {
      setEndDate(data);
    }
  };

  const handleClearFile = () => {
    fileInputRef.current.value = ''; // reset the value to an empty string
  };

  /* Upload Video Validation */
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);

  const handleVideoValidation = () => {
    if (videoUrl !== '') {
      setIsVideoUploaded(true);
    } else {
      setUploadFileError(glossary.dsp_add_io_popup_field_upload_video_empty_error);
      setIsVideoUploaded(false);
    }
  };

  const handleUrlChange = (vUrl: string) => {
    setVideoUrl(vUrl);
  };

  /* Upload Video */
  const uploadCreative = (videoData: any) => {
    setCreativeUploading(true);
    handleCloseModal && handleCloseModal(false);
    setVideoUrl('');
    uploadCreativeAPI(videoData)
      .then((data: any) => {
        setCreativeUploading(false);
        if (data.status) {
          handleUrlChange(data.url);
          toast.success(glossary.dsp_add_io_popup_video_upload_success_msg);
          handleCloseModal && handleCloseModal(true);
          setIsVideoUploaded(true); // Video Uploaded Successfully
        } else {
          handleClearFile();
          handleCloseModal && handleCloseModal(true);
        }
      })
      .catch((error) => {
        if (error) {
          setCreativeUploading(false);
          handleCloseModal && handleCloseModal(true);
          toast.error(glossary.service_unavailable);
          handleClearFile();
        }
      });
  };

  const handleButtonClick = () => {
    setUploadFileError('');
    fileInputRef.current.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (videoUrl && file) {
      handleClearFile();
    }
    const fileType = file?.type;
    if (file && file.size <= 50 * 1024 * 1024 && fileType === 'video/mp4') {
      uploadCreative(file);
    } else {
      setUploadFileError(
        fileType !== 'video/mp4'
          ? glossary.dsp_add_io_popup_field_upload_video_file_type_error
          : glossary.dsp_add_io_popup_field_upload_video_file_size_error
      );
      handleClearFile();
    }
  };

  /***** Section Handling (VAST URL) *****/
  const [section, setSection] = useState('1');
  const [decodeObj, setDecodeObj] = useState<any>([]);
  const [macrosList, setMacrosList] = useState<any>([]);
  const [backSelect, sBackSelect] = useState<any>([]);
  const [detectSelectMacros, setDetectSelectMacros] = useState<any>([]);
  const [originMacros, setOriginMacros] = useState('');
  const [decodeUrlStr, setDecodeUrlStr] = useState<any>('');
  const [selectedMacros, setSelectedMacros] = useState<any>('');
  const [macrosListWait, setMacrosListWait] = useState(true);

  // Handle Previous value for VASTURL
  const handleMacroDetect = (e: any) => {
    initiatDecodeURL(e);
    setSection('2');
    sIntegrationName(e.integrationName);
    setCpm(e.integrationCPM);
    setBudget(budget);
    setImpCap(e.impressionCapping);
    _setVastUrl(e.integrationVasturl);
    setAdvDomain(e.advertiserDomain);
  };

  const handleVastURLEndpoint = (endpointConfig: any) => {
    const payload = {
      integrationName: integrationName,
      integrationVasturl: updatedMacrosURL1 + decodeUrlStr,
      advertiserDomain: advDomain,
      integrationCPM: cpm,
      integrationBudget: budget,
      impressionCapping: impCap,
    };
    if (endpointConfig) {
      updateEndpoint(payload);
    } else {
      initiateInsertionOrder(payload);
    }
  };

  /* Get Bidder list api */
  useEffect(() => {
    setMacrosListWait(true);
    getMacrosListAPI()
      .then((data: any) => {
        if (data.data.length > 0) {
          setMacrosList(data.data);
          setMacrosListWait(false);
        }
      })
      .catch((error: any) => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  }, []);

  // console.log('detectSelectMacros', detectSelectMacros);

  /* params and macros reduce */
  const reduceParamsMacros = detectSelectMacros?.reduce((a: any, b: any) => {
    return (a.value || a) + '&' + b.value + '=' + b.macros;
  }, []);

  const updatedMacrosURL = originMacros + reduceParamsMacros;
  const updatedMacrosURL1 = updatedMacrosURL.replace('?&', '?');

  console.log('updatedMacrosURL1', updatedMacrosURL1);

  /* Decode VAST URL */
  let str: any = '';
  const initiatDecodeURL = (e: any) => {
    const urlMacros = e.integrationVasturl;
    function getQueryParams(url: any) {
      const paramArr = url.slice(url.indexOf('?') + 1).split('&');
      const paramArr1 = url.split('?');
      const getOriginMacros = (paramArr1[0] += '?');
      setOriginMacros(getOriginMacros);
      const params: any = {};
      paramArr.map((param: any) => {
        const [key, val] = param.split('=');
        if (val) {
          //if (val[0] === '[' || val[0] === '{' || val[0] === '$' || val[0] === '%') {
          params[key] = decodeURIComponent(val);
        } else {
          str += `&${key}=${val}`;
          setDecodeUrlStr(str);
        }
      });
      // console.log('params', params);
      return params;
    }
    const cleanedUrl = urlMacros.replace(/\[\s*%\s*(.*?)\s*%\s*\]/g, '[$1]');
    const params = getQueryParams(cleanedUrl);
    setDecodeObj(params);
  };

  // console.log('decodeObj', decodeObj);

  /* get macrose parameter */
  const appOpt = macrosList.map((opt: any) => {
    const updatedMacros = {
      id: opt.id,
      macros: opt.macros,
      value: opt.parameters,
      //label: opt.parameters + ' ' + opt.macros,
      label: `[${opt.parameters}] ${opt.name}`,
    };
    return updatedMacros;
  });

  /* Decode input URL convert to Object */
  const objConvert: any = Object.keys(decodeObj).map((key: any) => ({
    id: key,
    macros: decodeObj[key],
    value: key,
    label: decodeObj[key] + ' ' + key,
  }));

  // console.log('objConvert', objConvert);

  /* filter macros value from input VAST URL */
  // const finalDetectMacros = appOpt.filter((elem: any) => {
  //   return objConvert.find(({ id }: any) => {
  //     return elem.value == id;
  //   });
  // });

  // console.log('finalDetectMacros', finalDetectMacros);

  useEffect(() => {
    const newObjConvert = objConvert.map((v: any) => ({
      value: v.value,
      label: v.label,
      macros: v.macros,
    }));
    const newFinalDetectMacros = objConvert.map((v: any) => ({
      value: v.value,
      label: v.label,
      macros: v.macros,
      delect: 'yes',
    }));
    const result = Array.from(
      [...newObjConvert, ...newFinalDetectMacros]
        .reduce((m, o) => m.set(o.value, o), new Map())
        .values()
    );
    // console.log('result', result);
    setDetectSelectMacros(result);
  }, [decodeObj, macrosList]);

  // Handle macros select dropdown
  const handleChange = (key: string, value: string, e: any) => {
    // Update the selectedMacros state with the selected value
    setSelectedMacros({ macros: e?.macros, key });
    setDecodeObj((prevState: any) => ({
      ...prevState,
      [key]: e.macros,
    }));

    // Update the detectSelectMacros state
    const newArr = detectSelectMacros.map((object: any) =>
      object.value === key ? { ...object, label: `${key} ${e.macros}`, macros: e.macros } : object
    );
    setDetectSelectMacros(newArr);

    // Create an array of labels from appOpt based on the selected key
    const arr = appOpt.reduce((acc: string[], opt: any) => {
      if (opt.label === key) {
        acc.push(opt.label);
      }
      return acc;
    }, []);
    return arr;
  };

  // const handleChange = (key: any, value: any, e: any) => {
  //   setSelectedMacros({ macros: e?.macros, key });
  //   setDecodeObj((prevState:any) => ({
  //     ...prevState,
  //     [key]: e.macros,
  //   }));
  //   const newArr = detectSelectMacros.map((object: any) => {
  //     if (object.value === key) {
  //       // 👇️ change value of name property
  //       return { ...object, label: key + ' ' + e.macros, macros: e.macros };
  //     }
  //     return object;
  //   });

  //   setDetectSelectMacros(newArr);

  //   const arr: any = [];
  //   appOpt.map((opt: any) => {
  //     if (opt.label === key) {
  //       arr.push(opt.label);
  //     } else {
  //       //console.log("opt.label", opt.label)
  //     }
  //   });
  //   return arr;
  // };

  /* back button*/
  const backButton = (check: any) => {
    setSection('1');
    sBackSelect(check);
  };

  const handleBudget = (e: any) => {
    const newValue = e.target.value;
    if (newValue.match(/^[0-9]*\.?\d{0,2}$/)) {
      setBudget(newValue);
    }
  };

  const differenceInHours = Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
  const dateCompare = endDate > startDate ? true : false;

  return (
    <>
      {/* <p
        className={
          tagStatus === 'running'
            ? 'badge badge-primary'
            : tagStatus === 'completed'
            ? 'badge badge-success'
            : tagStatus === 'upcoming'
            ? 'badge badge-secondary'
            : ''
        }
        style={{ position: 'absolute', top: '17px', right: '20px' }}
      >
        {tagStatus}
      </p> */}
      {section === '1' && (
        <Formik
          validationSchema={
            videoCreativeType === 'vast_xml'
              ? IoFormSchemaXML
              : videoCreativeType === 'vast_url'
              ? IoFormSchemaURL
              : IoFormSchema
          }
          initialValues={
            videoCreativeType === 'vast_url' ? IoFormInitialValuesURL : IoFormInitialValues
          }
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(e) => {
            if (enpointListDataConfigObj) {
              if (checkboxError && differenceInHours >= 24 && dateCompare) {
                if (videoCreativeType === 'vast_url') {
                  handleMacroDetect(e);
                } else {
                  const newE = { ...e, integrationBudget: budget };
                  updateEndpoint(newE);
                }
              }
            } else {
              if (checkboxError && differenceInHours >= 24 && dateCompare) {
                if (videoCreativeType === 'vast_upload') {
                  if (isVideoUploaded) {
                    videoCreativeType === 'vast_url'
                      ? handleMacroDetect(e)
                      : initiateInsertionOrder(e);
                  }
                } else {
                  videoCreativeType === 'vast_url'
                    ? handleMacroDetect(e)
                    : initiateInsertionOrder(e);
                }
              }
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className={editDetailsDSPModal ? 'rtb-form edit' : 'rtb-form disable'}>
              <div
                className={getEndPointListCard && 'modal-body p-3'}
                style={{
                  maxHeight: 'calc(100vh - 210px)',
                  overflowY: 'auto',
                  marginBottom: '10px',
                }}
              >
                <div className='row mb-3 mt-1'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.dsp_add_io_popup_input_label_name}
                        placeholder={glossary.dsp_add_io_popup_input_placeholder_name}
                        name='integrationName'
                        maxLength={32}
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <FormikController
                        kind='withiconstart'
                        control='input'
                        type='number'
                        label={glossary.dsp_add_io_popup_input_label_cpm}
                        placeholder={glossary.dsp_add_io_popup_input_placeholder_cpm}
                        name='integrationCPM'
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <div className='d-flex flex-column'>
                        <label className='label'>
                          {glossary.dsp_add_io_popup_input_label_budget}
                        </label>
                        <div className='input-group flex-nowrap'>
                          <span className='input-group-text'>$</span>
                          <input
                            name='budget'
                            className='text-input'
                            type={'text'}
                            placeholder={glossary.dsp_add_io_popup_input_placeholder_budget}
                            maxLength={10}
                            value={budget}
                            onChange={(e) => handleBudget(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-6'>
                    <div className='form-group domain-input-icon'>
                      <FormikController
                        kind='withiconstart'
                        icon={glossary.dsp_add_io_popup_input_adv_domain_icon}
                        control='input'
                        type='text'
                        label={glossary.dsp_add_io_popup_input_label_adv_domain}
                        placeholder={glossary.dsp_add_io_popup_input_placeholder_adv_domain}
                        name='advertiserDomain'
                        maxLength={255}
                      />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-6'>
                        <DatePicker
                          selectedDate={getStartDate}
                          selected={startDate}
                          label={glossary.dsp_add_io_popup_input_label_start_date}
                          time={true}
                        />
                      </div>
                      <div className='col-6'>
                        <DatePicker
                          selectedDate={getEndDate}
                          selected={endDate}
                          label={glossary.dsp_add_io_popup_input_label_end_date}
                          time={true}
                        />
                        <p className='small text-danger'>
                          {dateCompare && differenceInHours < 24
                            ? glossary.dsp_add_io_popup_date_difference_error
                            : ''}
                          {!dateCompare ? glossary.dsp_add_io_popup_date_compare_error : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-3'>
                  <label style={{ fontSize: '14px', color: '#a2a2a2' }}>
                    {glossary.dsp_add_endpoint_field_label_device_type}
                  </label>
                  <DeviceTypeCheckBox
                    setArrFunc={setDeviceTypeArr}
                    enpointListDataConfigObj={enpointListDataConfigObj}
                    backChecked={backSelect}
                  />
                  {checkboxError === false && deviceTypeArr?.length === 0 && (
                    <div className='text-input-error-label small'>
                      {glossary.dsp_add_endpoint_field_device_type_empty_error}
                    </div>
                  )}
                </div>

                <div className='row mb-3'>
                  {!endpointId && (
                    <div className='col-md-6'>
                      <FormB.Group>
                        <label className='label'>
                          {glossary.dsp_add_io_popup_field_label_video_creative}
                        </label>
                        <div
                          className={
                            creativeUploading
                              ? 'd-flex gap-3 mt-1 disable-field'
                              : 'd-flex gap-3 mt-1'
                          }
                        >
                          <Radio
                            label={glossary.dsp_add_io_popup_field_video_creative_radio_upload}
                            size={'mini'}
                            id={'vast_upload'}
                            selected={videoCreativeType}
                            click={handleVidioCreative}
                          />
                          <Radio
                            label={glossary.dsp_add_io_popup_field_video_creative_radio_vastxml}
                            size={'mini'}
                            id={'vast_xml'}
                            selected={videoCreativeType}
                            click={handleVidioCreative}
                          />
                          <Radio
                            label={glossary.dsp_add_io_popup_field_video_creative_radio_vasturl}
                            size={'mini'}
                            id={'vast_url'}
                            selected={videoCreativeType}
                            click={handleVidioCreative}
                          />
                        </div>
                      </FormB.Group>
                    </div>
                  )}

                  <div className='col-md-6'>
                    <div
                      className='mt-2 align-items-center d-flex switch'
                      style={{ paddingTop: '25px', height: '40px' }}
                    >
                      <label className='label mb-0 me-2 pt-1 text-body'>
                        {glossary.dsp_add_io_popup_field_label_impression_capping}
                      </label>
                      <Switch
                        api={(chec: any) => {
                          chec ? setImpCapping(true) : setImpCapping(false);
                        }}
                        status={impCapping}
                      />
                      <div className='form-group ms-4'>
                        {impCapping && (
                          <FormikController
                            kind='withouticon'
                            control='input'
                            type='number'
                            placeholder={glossary.dsp_add_io_popup_input_placeholder_imp_capping}
                            name='impressionCapping'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {videoCreativeType === 'vast_upload' && (
                  <>
                    <div className='row mb-3'>
                      <div className='col-md-6 d-flex flex-column'>
                        <label className='label'>
                          {glossary.dsp_add_io_popup_field_label_upload}
                        </label>
                        {!creativeUploading ? (
                          <Button
                            customClass='w-50'
                            type='button'
                            kind={'primary'}
                            text={
                              !videoUrl
                                ? glossary.dsp_add_io_popup_field_upload_button_select_video_ad
                                : glossary.dsp_add_io_popup_field_upload_button_change_video
                            }
                            click={handleButtonClick}
                          />
                        ) : (
                          <ButtonR
                            className='button button-loading w-50 d-flex align-items-center justify-content-center'
                            disabled
                            type='button'
                          >
                            <Spinner
                              className='me-2'
                              as='span'
                              animation='grow'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                            {glossary.dsp_add_io_popup_field_upload_button_loading}
                          </ButtonR>
                        )}

                        <input
                          ref={fileInputRef}
                          type='file'
                          accept='video/*'
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                        <p className='small text-danger'>{uploadFileError}</p>
                        <a
                          href='https://help.infy.tv/en/articles/7911741-insertion-order-video-upload-guidelines'
                          target='_blank'
                          rel='noreferrer'
                          style={{ width: '200px' }}
                        >
                          {glossary.dsp_add_io_popup_field_upload_hyperlink_video_upload_guidelines}
                        </a>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <FormikController
                            kind='withouticon'
                            control='input'
                            type='text'
                            label={'Click URL'}
                            placeholder={'Enter Click URL'}
                            name='clickURL'
                            //maxLength={32}
                          />
                        </div>
                      </div>
                    </div>
                    {videoCreativeType === 'vast_upload' && videoUrl && (
                      <div className='d-flex ms-auto me-auto' style={{ width: '400px' }}>
                        <video className='rounded' width={'100%'} height={'100%'} controls>
                          <source src={videoUrl} type={'video/mp4'} />
                        </video>
                      </div>
                    )}
                  </>
                )}

                {videoCreativeType !== 'vast_upload' && (
                  <div className='row mb-3'>
                    <div className='form-group'>
                      {videoCreativeType === 'vast_xml' && !endpointId && (
                        <FormikController
                          control='textArea'
                          label={glossary.dsp_add_io_popup_field_label_vastxml}
                          placeholder={''}
                          name='integrationVastxml'
                        />
                      )}
                      {videoCreativeType === 'vast_url' && (
                        <FormikController
                          control='textArea'
                          label={glossary.dsp_add_io_popup_field_label_vasturl}
                          placeholder={''}
                          name='integrationVasturl'
                        />
                      )}
                    </div>
                  </div>
                )}
                {/* Read Only Mode */}
                {enpointListDataConfigObj && videoCreativeType !== 'vast_url' && (
                  <>
                    <div className='row mb-3'>
                      {/* {enpointListDataConfigObj?.video_url ? (
                        <>
                          <div className='col-12 mb-3'>
                            <div className='d-flex ms-auto me-auto' style={{ width: '400px' }}>
                              <video className='rounded' width={'100%'} height={'100%'} controls>
                                <source src={videoUrl} type={'video/mp4'} />
                              </video>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className='col-12'>
                          <div style={{border: "1px solid #a2a2a2", padding: "8px"}}>
                            <p>{xmlEndpoint}</p>
                          </div>
                        </div>
                      )} */}
                      {enpointListDataConfigObj?.video_url && (
                        <>
                          <div className='col-12 mb-3'>
                            <div className='d-flex ms-auto me-auto' style={{ width: '400px' }}>
                              <video className='rounded' width={'100%'} height={'100%'} controls>
                                <source src={videoUrl} type={'video/mp4'} />
                              </video>
                            </div>
                          </div>
                          <div className='col-md-12 mb-3'>
                            <div className='form-group'>
                              <FormikController
                                kind='withouticon'
                                control='input'
                                type='text'
                                label={'Click URL'}
                                placeholder={'Enter Click URL'}
                                name='clickURL'
                                //maxLength={32}
                                disable={true}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className='col-12'>
                        <label className='label'>VAST Xml</label>
                        <div
                          style={{
                            border: '1px solid #a2a2a2',
                            padding: '8px',
                            borderRadius: '4px',
                          }}
                        >
                          <p>{xmlEndpoint}</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={getEndPointListCard && 'modal-footer px-3'}>
                <div className='d-flex justify-content-end gap-3'>
                  {(endpontDataStatus || endpontDataStatus === undefined) && (
                    <Button
                      type='button'
                      kind={creativeUploading ? 'disabled' : 'secondary'}
                      text={glossary.dsp_add_io_popup_button_close}
                      click={() => {
                        onHide();
                      }}
                    />
                  )}
                  {enpointListDataConfigObj ? (
                    <>
                      {openDetailsModal && (
                        <>
                          {!editDetailsDSPModal && (
                            <Button
                              kind={'primary'}
                              type='button'
                              click={() => {
                                sEditDetailsDSPModal(true);
                              }}
                              text={glossary.dsp_details_ip_popup_button_edit}
                            />
                          )}
                        </>
                      )}
                      {(!openDetailsModal || editDetailsDSPModal) && (
                        <>
                          {isLoading || macrosListWait ? (
                            <Button type={'button'} text={glossary.loading} kind={'spinner'} />
                          ) : (
                            <Button
                              type={'submit'}
                              click={() => checkBoxRequired()}
                              kind={'primary'}
                              text={
                                videoCreativeType === 'vast_url'
                                  ? glossary.dsp_update_io_popup_button_check_macros
                                  : glossary.dsp_update_ip_popup_button_update
                              }
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {isLoading || macrosListWait ? (
                        <Button type={'button'} text={glossary.loading} kind={'spinner'} />
                      ) : (
                        <>
                          <Button
                            type={'submit'}
                            click={() => {
                              if (videoCreativeType === 'vast_upload') handleVideoValidation();
                              checkBoxRequired();
                            }}
                            text={
                              videoCreativeType === 'vast_url'
                                ? glossary.dsp_add_io_popup_button_check_macros
                                : glossary.dsp_add_io_popup_button_run_io
                            }
                            kind={creativeUploading ? 'disabled' : 'primary'}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}

      {section === '2' && (
        <>
          <div
            className='modal-body px-3 pb-3 pt-0'
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            {decodeObj.length === 0 ? (
              <h4>Macros Not Detected</h4>
            ) : (
              <div className='bg-white pb-4 position-sticky pt-3 row top-0' style={{ zIndex: 1 }}>
                <div className='col-3'>
                  <h3 style={{ fontWeight: 'lighter', fontSize: '20px' }}>
                    {glossary.dsp_add_vast_popup_section_two_column_params}
                  </h3>
                </div>
                <div className='col-3'>
                  <h3 style={{ fontWeight: 'lighter', fontSize: '20px' }}>
                    {glossary.dsp_add_vast_popup_section_two_column_values}
                  </h3>
                </div>
                <div className='col-4'>
                  <h3 style={{ fontWeight: 'lighter', fontSize: '20px' }}>
                    {glossary.dsp_add_vast_popup_section_two_column_macros}
                  </h3>
                </div>
              </div>
            )}

            {Object.entries(decodeObj).map(([key, value]: any) => {
              return (
                <>
                  <div className='row align-items-center mb-3 pt-3' key={key}>
                    <div className='col-3'>{key}</div>
                    <div className='col-3 fw-lighter'>
                      {selectedMacros?.key === key ? selectedMacros?.macros : value}
                    </div>
                    <div className='col-6 react-select-dropdown'>
                      <ReactSelect
                        classNamePrefix='react-select'
                        options={appOpt}
                        isSearchable={true}
                        onChange={(e) => handleChange(key, value, e)}
                        placeholder={'Not Detected'}
                        defaultValue={appOpt.find((opt: any) => opt.macros === value)}
                        // onInputChange={(value, action) => {
                        //   if (action.action === 'input-change') setInput(value);
                        // }}
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className='modal-footer justify-content-between px-3'>
            <button className='button button-secondary' onClick={() => backButton(deviceTypeArr)}>
              {glossary.dsp_add_vast_popup_section_two_button_back}
            </button>
            <div className='d-flex mx-0'>
              {(endpontDataStatus || endpontDataStatus === undefined) && (
                <button
                  type='button'
                  onClick={() => {
                    onHide();
                  }}
                  className={'button button-large button-secondary float-end me-3'}
                >
                  {glossary.dsp_add_vast_popup_button_close}
                </button>
              )}
              <button
                className='button button-primary'
                onClick={(e) => {
                  handleVastURLEndpoint(enpointListDataConfigObj);
                }}
              >
                {glossary.dsp_add_vast_popup_section_two_button_done}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InsertionOrder;
