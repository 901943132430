import React from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useFlexLayout,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import scrollbarWidth from '../tables/scrollbarWidth';
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import defaultGlobalFilter from '../helpers/DefaultGlobalFilter';
import Switch from '../widgets/switch';

// Todo: Toggle include & exclude functionality implement into this table from old table is pending.

const Table = ({ columns: userColumns, data, globalFilter }: any) => {
  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);
  const filterOptions = { filteredIds: [] };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: userColumns,
      data,
      getSubRows: (row) => row.dsps || [],
      initialState: {
        //@ts-ignore
        globalFilter,
      },
      globalFilter: (rows: any, columnIds: any, filterValue: any) =>
        defaultGlobalFilter(rows, columnIds, filterValue, filterOptions),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'expander', // Make sure it has an ID
          Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
            <span {...getToggleAllRowsExpandedProps()}>
              {/* {isAllRowsExpanded ? (
                <MdOutlineKeyboardArrowDown fontSize={20} className='svg-icon' />
              ) : (
                <MdOutlineKeyboardArrowRight fontSize={20} className='svg-icon' />
              )} */}
            </span>
          ),
          Cell: ({ row }: any) =>
            row.depth === 0 && (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <MdOutlineKeyboardArrowDown fontSize={20} className='svg-icon' />
                ) : (
                  <MdOutlineKeyboardArrowRight fontSize={20} className='svg-icon' />
                )}
              </span>
            ),
          width: 40,
        },
        ...columns,
      ]);
    }
  );

  React.useEffect(() => {
    setGlobalFilter(globalFilter || undefined);
  }, [globalFilter]);

  const avoidID = ['action', 'expander'];

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <tr
          {...row.getRowProps({ style })}
          className={`admin-traffic-tr ${row?.isExpanded && 'expanded'} ${
            row.depth === 1 && 'subrow'
          }`}
        >
          {row.cells.map((cell: any, i: any) => {
            return (
              <td
                {...cell.getCellProps()}
                key={cell?.column?.id}
                className={`${cell?.column?.id} ${
                  !avoidID.includes(cell?.column?.id) && 'text-truncate'
                } admin-traffic-td`}
                title={cell.value}
              >
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
      );
    },
    [prepareRow, rows]
  );

  return (
    <>
      <div className='infy-table admin-traffic mt-0'>
        <table {...getTableProps()} className='table'>
          <thead className='table-header'>
            {headerGroups.map((headerGroup: any, i: any) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column: any, _i: any) => (
                  <th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ className: column.className, title: undefined })
                    )}
                    key={_i}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IoMdArrowDropdown className='svg-icon' fontSize={20} />
                        ) : (
                          <IoMdArrowDropup className='svg-icon' fontSize={20} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <FixedSizeList
              height={800}
              itemCount={rows.length}
              itemSize={35}
              width={totalColumnsWidth + scrollBarSize}
              style={{ width: '100%', height: `calc(100vh - 275px)` }}
            >
              {RenderRow}
            </FixedSizeList>
          </tbody>
        </table>
      </div>
    </>
  );
};

const NewTrafficTable = ({ data, columns, columnWidth }: any) => {
  const [globalFilter, setGlobalFilter] = React.useState('');

  const actionCell = (row: any) => {
    return (
      <>
        <Switch
          key={`${row.id}`}
          //   api={(chec: boolean) => handleChildToggle(chec, row)}
          // api={(chec: any) => table_toggle(chec, row.id.toString())}
          status={row?.value}
          disabled={true}
        />
      </>
    );
  };

  const col = React.useMemo(() => {
    if (columns) {
      return columns.map((column: any) => {
        return {
          Header: column.title,
          accessor: column.key,
          width: columnWidth,
          className: `th_${column.key} sort_${column.sort} sticky_${column.sticky}`,
          Cell: (row: any) => {
            return (
              <>
                {column.key === 'excluded' && actionCell(row)}
                <span data-timeframe={row.name}>{row.value}</span>
              </>
            );
          },
        };
      });
    }
    return [];
  }, [columns]);

  const onChange = (e: any) => setGlobalFilter(e.target.value);

  return (
    <>
      <div className='search-wrap'>
        <input
          value={globalFilter}
          onChange={onChange}
          placeholder={`Search Supply/Demand partner`}
          className='form-control'
          type='search'
          maxLength={32}
        />
      </div>
      <Table
        columns={col}
        data={data}
        expandedRows={true}
        subRowsKey='dsps' // inventories - subRows
        globalFilter={globalFilter}
      />
    </>
  );
};

export default NewTrafficTable;
