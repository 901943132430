import { Field, ErrorMessage } from 'formik';

const TextArea = (props: any) => {
  const { label, name, ...rest } = props;
  return (
    <div>
      <label htmlFor={name} className='label'>
        {label}
      </label>
      <Field as='textarea' id={name} name={name} {...rest} className='form-control textarea-row4' />
      <div className='text-input-error-label small'>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};
export default TextArea;
