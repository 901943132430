import { createSlice, configureStore } from '@reduxjs/toolkit';

import glossary from './glossary';

const glossarySlice = createSlice({
  name: 'glossary',
  initialState: glossary,
  reducers: {
    update: (_, action) => {
      return action.payload;
    },
  },
});

export const { update } = glossarySlice.actions;

const store = configureStore({
  reducer: glossarySlice.reducer,
});

export default store;
