import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
// import CreativeCreate from '../components/creatives/Creative-create';
import NewCreativeCreate from '../components/creatives/NewCreative-create';

interface Props {
  show: boolean;
  onHide(): void;
  glossary: any;
  pSection: '1' | '2';
  creativeDetails: any; // Only for edit creative
  creativeConfig: any; // Only for edit creative
  genuinUserInfo: any;
  mode: 'create' | 'edit';
  controller(_data: any, t: string): void;
  _selectedVideos?: any; // When create tag directly from video library
}

const CreativeAdd = ({
  show,
  onHide,
  glossary,
  pSection,
  genuinUserInfo,
  mode,
  controller,
  _selectedVideos,
}: Props) => {
  // Render Component
  const [currentSection, setCurrentSection] = useState('');

  return (
    <Modal
      onHide={() => {
        onHide();
      }}
      show={show}
      centered
      size={currentSection !== '2' ? 'xl' : 'lg'}
      className={currentSection !== '2' ? 'creative-modal' : ''}
    >
      <Modal.Header>
        <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>Reel Creative</h3>
      </Modal.Header>

      <NewCreativeCreate
        show={show}
        onHide={onHide}
        glossary={glossary}
        pSection={pSection}
        genuinUserInfo={genuinUserInfo}
        mode={mode}
        creativeDetails={{}}
        creativeConfig={{}}
        controller={controller}
        _selectedVideos={_selectedVideos}
        getCurrentSection={setCurrentSection}
      />

      {/* <CreativeCreate
        show={show}
        onHide={onHide}
        glossary={glossary}
        pSection={pSection}
        genuinUserInfo={genuinUserInfo}
        mode={mode}
        creativeDetails={{}}
        creativeConfig={{}}
        controller={controller}
        _selectedVideos={_selectedVideos}
      /> */}
    </Modal>
  );
};

export default CreativeAdd;
