import React, { useMemo } from 'react';
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import TableSkeleton from '../loaders/table';
import scrollbarWidth from './scrollbarWidth';
import { GoLinkExternal } from 'react-icons/go';
import Button from '../widgets/button';
import InfyActionColumn from '../comman/table/InfyActionColumn';

interface Props {
  column: any;
  data: any;
  isAdmin?: boolean;
  ignoreCol?: any;
  search?: boolean;
  isLoading?: boolean;
  heightCount?: string;
  action?:
    | {
        text: string;
        click(): void;
        customClass?: string;
        css?: {
          right: string;
        };
      }
    | undefined;
  tableName: string;
  selectedCard?: any;
  actionController?(_props: any): void;
  fixedHeight?: string;
}

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div
      className='search-wrap'
      style={{
        marginTop: '10px',
      }}
    >
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        type='search'
        placeholder={'Search here'}
        className='form-control'
        maxLength={32}
      />
    </div>
  );
};

export default function Coretable({
  column,
  data,
  ignoreCol,
  search,
  isLoading,
  heightCount,
  action,
  tableName,
  selectedCard,
  actionController,
  fixedHeight,
}: Props) {
  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  // Store table row data
  const tableRows = useMemo(() => {
    return data;
  }, [data]);

  // Modify Columns
  const updateColumns = useMemo(() => {
    return column.filter((col: any) => {
      if (!ignoreCol.includes(col.key)) {
        return col;
      }
    });
  }, [column, ignoreCol]);

  // Modified Cells
  const actionCell = (row: any) => {
    return (
      <InfyActionColumn
        columnKey={row?.column?.id}
        data={row}
        tableName={tableName}
        actionController={actionController}
        selectedCardId={selectedCard}
      />
    );
  };

  // Generate Columns
  const col = React.useMemo(() => {
    if (updateColumns) {
      return updateColumns.map((_column: any) => {
        return {
          Header: _column.title,
          accessor: _column.key,
          width: 150,
          className: `th_${_column.key} sort_${_column.sort} sticky_${_column.sticky}`,
          Cell: (row: any) => {
            return (
              <>
                {_column.key && _column.key === 'action' && actionCell(row)}

                {_column.key === 'name' && row.row.original?.url ? (
                  <div className='d-flex align-items-center gap-2'>
                    <a href={`http://${row.row.original?.url}`} target='_blank' rel='noreferrer'>
                      <GoLinkExternal className='svg-icon' fontSize={16} title='Redirect URL' />
                    </a>
                    <p className='macros-truncate'>{row.row.original?.name}</p>
                  </div>
                ) : (
                  row?.value
                )}
              </>
            );
          },
        };
      });
    }
    return [];
  }, [updateColumns]);

  // Table Instance Property
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // selectedFlatRows, // This is pass add into "RenderRow" dependencies
    totalColumnsWidth,
    // state: { selectedRowIds }, // This is pass add into "RenderRow" dependencies
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable<any>(
    {
      columns: col,
      data: tableRows,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
    useFlexLayout
  );

  // Render table rows
  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <tr
          {...row.getRowProps({
            style,
          })}
        >
          {row.cells.map((cell: any, index: any) => {
            return (
              <td
                {...cell.getCellProps()}
                key={index}
                title={cell.value}
                className={'macros-truncate'}
                style={{
                  padding: '6px 12px',
                  width: '100%',
                }}
              >
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
      );
    },
    [prepareRow, rows]
  );

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        {search && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}

        {action && (
          <div className={action.customClass} style={action.css}>
            <Button text={action.text} click={action.click} kind={'primary'} type={'button'} />
          </div>
        )}
      </div>

      <div className={'coretable'}>
        <table {...getTableProps()} className='table'>
          <thead className='table-header' style={{ zIndex: 0 }}>
            {headerGroups.map((headerGroup: any, index: any) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, index: any) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    key={index}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted && column.isSortedDesc && (
                        <IoMdArrowDropdown className='svg-icon' fontSize={20} />
                      )}
                      {column.isSorted && !column.isSortedDesc && (
                        <IoMdArrowDropup className='svg-icon' fontSize={20} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='table-body' {...getTableBodyProps()}>
            <FixedSizeList
              height={800}
              itemCount={rows?.length}
              itemSize={35}
              width={totalColumnsWidth + scrollBarSize}
              style={{
                width: '100%',
                height:
                  rows?.length < 1
                    ? '0px'
                    : `${fixedHeight ? fixedHeight : `calc(100vh - ${heightCount})`}`,
                overflowX: 'hidden',
              }}
            >
              {RenderRow}
            </FixedSizeList>
          </tbody>
        </table>

        {/* No Data Found Message */}
        {rows?.length < 1 && !isLoading && (
          <div className='text-center mt-5'>
            <h5>Data Not Available</h5>
          </div>
        )}

        {/* Table Data Loading View */}
        {isLoading && <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />}
      </div>
    </>
  );
}
