import { useEffect, useState } from 'react';
import Switch from '../../widgets/switch';
import { Accordion, Card, Button, Nav, useAccordionToggle } from 'react-bootstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { toast } from 'react-toastify';
import { routes } from '../../../services/constants';

import { MdInfoOutline, MdDeleteOutline } from 'react-icons/md';

import 'bootstrap/dist/css/bootstrap.min.css';

export const headers: any = {
  'Content-Type': 'application/json',
};

function SSPSettingTab(props: any) {
  const { tagId, settingGetObj } = props;

  const [softFloorSSAI, sSoftFloorSSAI] = useState<any>(true);

  const [panelIndex, setPanelIndex] = useState();
  const [disableButton, sDisableButton] = useState(true);
  const [autoEnrichment, sAutoEnrichment] = useState(settingGetObj?.is_auto_enrich ?? true);

  const CustomToggle = ({ children, eventKey }: any) => {
    // Decorate the click event to also set state in the component so we can
    // compare the open panel key with the state and toggle classes accordingly
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey);
    });

    const customClass =
      eventKey === panelIndex ? 'accordion-header open-class' : 'accordion-header closed-class';

    return (
      <Nav.Link className={customClass} onClick={customOnClick}>
        {children}
      </Nav.Link>
    );
  };

  const [checked, setChecked] = useState<any>({
    anonymize_device:
      (settingGetObj && settingGetObj.checked && settingGetObj.checked.anonymize_device) ?? false,
    deal_id_endpoint: settingGetObj?.checked?.deal_id_endpoint ?? false,
    anonymize_ip: settingGetObj?.checked?.anonymize_ip ?? false,
    max_min_bitrate: settingGetObj?.checked?.max_min_bitrate ?? false,
  });

  const [stoggleSwitchMix, setToggleSwitchMix] = useState<any>({
    app_store_status: settingGetObj?.stoggleSwitchMix?.app_store_status ?? false,
    app_name_override_status: settingGetObj?.stoggleSwitchMix?.app_name_override_status ?? false,
    app_bundle_override_status:
      settingGetObj?.stoggleSwitchMix?.app_bundle_override_status ?? false,
    app_cat_override_status: settingGetObj?.stoggleSwitchMix?.app_cat_override_status ?? false,
    user_set_coppa_status: settingGetObj?.stoggleSwitchMix?.user_set_coppa_status ?? false,
    video_skip_status: settingGetObj?.stoggleSwitchMix?.video_skip_status ?? false,
    supply_chain_status: settingGetObj?.stoggleSwitchMix?.supply_chain_status ?? false,
  });

  const [contentToggle, setContentToggle] = useState<any>({
    entire_endpoint_requrest:
      (settingGetObj &&
        settingGetObj.contentToggle &&
        settingGetObj.contentToggle.entire_endpoint_requrest) ??
      false,
    content_distributor_override_status:
      settingGetObj?.contentToggle?.content_distributor_override_status ?? false,
    content_channel_override_status:
      settingGetObj?.contentToggle?.content_channel_override_status ?? false,
    content_network_override_status:
      settingGetObj?.contentToggle?.content_network_override_status ?? false,
    content_series_override_status:
      settingGetObj?.contentToggle?.content_series_override_status ?? false,
    content_title_override_status:
      settingGetObj?.contentToggle?.content_title_override_status ?? false,
    content_category_override_status:
      settingGetObj?.contentToggle?.content_category_override_status ?? false,
    content_genre_override_status:
      settingGetObj?.contentToggle?.content_genre_override_status ?? false,
    content_rating_override_status:
      settingGetObj?.contentToggle?.content_rating_override_status ?? false,
    content_prodqual_override_status:
      settingGetObj?.contentToggle?.content_prodqual_override_status ?? false,
    content_keywords_override_status:
      settingGetObj?.contentToggle?.content_keywords_override_status ?? false,
    content_language_override_status:
      settingGetObj?.contentToggle?.content_language_override_status ?? false,

    content_distributor_remove_status:
      settingGetObj?.contentToggle?.content_distributor_remove_status ?? false,
    content_channel_remove_status:
      settingGetObj?.contentToggle?.content_channel_remove_status ?? false,
    content_network_remove_status:
      settingGetObj?.contentToggle?.content_network_remove_status ?? false,
    content_series_remove_status:
      settingGetObj?.contentToggle?.content_series_remove_status ?? false,
    content_title_remove_status: settingGetObj?.contentToggle?.content_title_remove_status ?? false,
    content_category_remove_status:
      settingGetObj?.contentToggle?.content_category_remove_status ?? false,
    content_genre_remove_status: settingGetObj?.contentToggle?.content_genre_remove_status ?? false,
    content_rating_remove_status:
      settingGetObj?.contentToggle?.content_rating_remove_status ?? false,
    content_prodqual_remove_status:
      settingGetObj?.contentToggle?.content_prodqual_remove_status ?? false,
    content_keywords_remove_status:
      settingGetObj?.contentToggle?.content_keywords_remove_status ?? false,
    content_language_remove_status:
      settingGetObj?.contentToggle?.content_language_remove_status ?? false,
  });

  const toggleCheck = (inputName: any) => {
    setChecked((prevState: any) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const toggleSwitchMix = (inputName: any) => {
    setToggleSwitchMix((prevState: any) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const handleContentSwitch = (inputName: any) => {
    setContentToggle((prevState: any) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const initiateTagSetting = (e: any) => {
    sDisableButton(false);
    const payload = {
      setting: {
        ...e.setting[0],
        checked,
        stoggleSwitchMix,
        contentToggle,
        is_auto_enrich: autoEnrichment,
      },
    };
    headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
    const options = {
      headers,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    return new Promise((resolve, reject) => {
      fetch(routes.updateTagSetting + `/${tagId}/settings`, options)
        .then((data) => {
          if (data) {
            toast.success('Save Settings');
            sDisableButton(true);
          }
        })
        .then((data) => resolve(data))
        .catch((error) => {
          toast.error('Something went wrong, please try again later.');
        });
    });
  };

  const handleOnChange = () => {
    sDisableButton(false);
  };

  // const selectOptions = [
  //   { value: 'js', label: 'Javascript' },
  //   { value: 'ja', label: 'Java' },
  //   { value: 'py', label: 'Python' },
  //   { value: 'go', label: 'Go' },
  //   { value: 'cs', label: 'C#' },
  // ];

  // const [selectedvalue, setSelectedValues] = useState<any>(['javascript']);
  // const handleChangeStoreURL = (e: any) => {
  //   const arrVal = selectedvalue;
  //   arrVal.push(e.target.value);
  //   setSelectedValues(arrVal);
  // };

  //console.log('settingGetObj', settingGetObj);

  // const newArray: any = [];

  //console.log('newArray', newArray);

  return (
    <>
      <Formik
        //validationSchema={MsaSignSchema}
        initialValues={{
          setting: [
            {
              app_store_url: settingGetObj?.app_store_url ?? [],
              app_name_override: settingGetObj?.app_name_override ?? [],
              app_bundle_override: settingGetObj?.app_bundle_override ?? [],
              app_cat_override: settingGetObj?.app_cat_override ?? '',
              app_site_name_override: settingGetObj?.app_site_name_override ?? '',
              content: [
                {
                  content_distributor_name_override:
                    settingGetObj?.content?.[0].content_distributor_name_override ?? '',
                  content_channel_override:
                    settingGetObj?.content?.[0].content_channel_override ?? '',
                  content_network_override:
                    settingGetObj?.content?.[0].content_network_override ?? '',
                  content_series_override:
                    settingGetObj?.content?.[0].content_series_override ?? '',
                  content_title_override: settingGetObj?.content?.[0].content_title_override ?? '',
                  content_category_override:
                    settingGetObj?.content?.[0].content_category_override ?? '',
                  content_genre_override: settingGetObj?.content?.[0].content_genre_override ?? '',
                  content_rating_override:
                    settingGetObj?.content?.[0].content_rating_override ?? '',
                  content_prodqual_override:
                    settingGetObj?.content?.[0].content_prodqual_override ?? '',
                  content_keywords_override:
                    settingGetObj?.content?.[0].content_keywords_override ?? '',
                  content_language_override:
                    settingGetObj?.content?.[0].content_language_override ?? '',
                },
              ],
              video: [
                {
                  video_min_ad_duration_override:
                    settingGetObj?.video?.[0].video_min_ad_duration_override ?? '',
                  video_max_ad_duration_override:
                    settingGetObj?.video?.[0].video_max_ad_duration_override ?? '',
                  video_start_delay_override:
                    settingGetObj?.video?.[0].video_start_delay_override ?? '',
                  video_skip_min: settingGetObj?.video?.[0].video_skip_min ?? '',
                  video_skip_after: settingGetObj?.video?.[0].video_skip_after ?? '',
                },
              ],
              supply_chain: [
                {
                  asi: settingGetObj?.supply_chain?.[0].asi ?? '',
                  sid: settingGetObj?.supply_chain?.[0].sid ?? '',
                  name: settingGetObj?.supply_chain?.[0].name ?? '',
                  domain: settingGetObj?.supply_chain?.[0].domain ?? '',
                },
              ],
            },
          ],
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(e, i) => {
          initiateTagSetting(e);
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
          <Form className={autoEnrichment ? 'mt-0 ssp-settings-disabled' : 'mt-0'}>
            {values.setting && values.setting.length > 0
              ? values.setting.map((setting, index) => (
                  <div key={index}>
                    <div className='row mb-4'>
                      <div className='col-8'>
                        <h2 className='mb-2'>Tag Level Rewrite</h2>
                        <p>
                          Set which ORTB objects below should be rewritten, altered or removed from
                          requests before reaching this channel
                        </p>
                        <div className='mt-2 d-flex gap-3'>
                          <div className='icon d-flex gap-1'>
                            <h4>Auto Enrichment</h4>
                            <MdInfoOutline
                              className='svg-icon'
                              title={`To enrich the bid-request parameters (publisher, device and user) of your traffic to maximise your earning, If the toggle switch is "Off," you can make changes and add new parameters. But if the toggle switch is "On," you can't make any changes or add parameters.`}
                              fontSize={20}
                            />
                          </div>
                          <Switch
                            name='auto_enrichment'
                            api={(chec: any) => {
                              sAutoEnrichment(chec);
                              sDisableButton(false);
                            }}
                            status={autoEnrichment}
                          />
                        </div>
                      </div>
                      <div className='col-4 save-settings'>
                        <button
                          type='submit'
                          className={
                            disableButton
                              ? 'button button-large button-disabled float-end'
                              : 'button button-large button-primary float-end'
                          }
                        >
                          Save Settings
                        </button>
                      </div>
                    </div>
                    <div className='col-12'>
                      {/* App Store URL Start */}
                      <Accordion className='accordion-setting mw-100'>
                        {/* App Store URL Start */}
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='0'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>APP Store URL</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {values.setting[index].app_store_url.length > 0 ? (
                                    <div className='accordian-more'>
                                      {values.setting[index].app_store_url.map(
                                        (res: any, index: any) => {
                                          return (
                                            <>
                                              <small
                                                className={
                                                  res.app_store_url_select
                                                    ? 'text-capitalize me-1 active'
                                                    : 'text-capitalize me-1'
                                                }
                                                key={index}
                                              >
                                                {res.app_store_url_select}
                                              </small>
                                              {/* <small>
                                            {res.app_store_url_select
                                              ? `+${res.app_store_url_select - 1} more`
                                              : null}
                                          </small> */}
                                            </>
                                          );
                                        }
                                      )}
                                      {/* {values.setting[index].app_store_url.length
                                        ? values.setting[index].app_store_url.length > 2
                                          ? `${
                                              values.setting[index].app_store_url?.[0]
                                                .app_store_url_select
                                            }, ${
                                              values.setting[index].app_store_url?.[1]
                                                .app_store_url_select
                                            }, +${
                                              values.setting[index].app_store_url.length - 2
                                            } more`
                                          : values.setting[index].app_store_url.length === 1
                                          ? values.setting[index].app_store_url?.[0]
                                              .app_store_url_select
                                          : `${values.setting[index].app_store_url?.[0].app_store_url_select}, ${values.setting[index].app_store_url?.[1].app_store_url_select}`
                                        : null} */}
                                    </div>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {values.setting[index].app_store_url.length > 0 && (
                                    <small>Active</small>
                                  )}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='0'>
                          <Card.Body className='py-4 accordion-body'>
                            <FieldArray
                              //id={`msa.${index}.legalTeamCC`}
                              name={`setting.${index}.app_store_url`}
                              render={(arrayHelpers2) => {
                                //console.log('arrayHelpers2', arrayHelpers2);
                                return (
                                  <>
                                    <div>
                                      {values.setting[index].app_store_url &&
                                        values.setting[index].app_store_url.length > 0 && (
                                          <>
                                            {values.setting[index].app_store_url.map(
                                              (q: any, index2: any) => {
                                                return (
                                                  <div className='row mb-3' key={index2}>
                                                    <div className='col-4'>
                                                      <Field
                                                        component='select'
                                                        name={`setting.${index}.app_store_url.${index2}.app_store_url_select`}
                                                        className='form-select'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                      >
                                                        <option
                                                          value='Select Store'
                                                          label='Select Store'
                                                        />
                                                        <option value='all' label='All' />
                                                        <option value='airtv' label='AirTV' />
                                                        <option value='amazon' label='Amazon' />
                                                        <option value='apple' label='Apple' />
                                                        <option value='bravia' label='Bravia' />
                                                        <option
                                                          value='chromecast'
                                                          label='Chromecast'
                                                        />
                                                        <option value='comcast' label='Comcast' />
                                                        <option value='dish' label='Dish' />
                                                        <option value='generic' label='Generic' />
                                                        <option value='google' label='Google' />
                                                        <option value='hisense' label='Hisense' />
                                                        <option value='huawei' label='Huawei' />
                                                        <option value='lg' label='LG' />
                                                        <option value='nvidia' label='Nvidia' />
                                                        <option
                                                          value='panasonic'
                                                          label='Panasonic'
                                                        />
                                                      </Field>
                                                      {/* <select
                                                          id={`setting.${index}.app_store_url.${index2}.select_store`}
                                                          name={`setting.${index}.app_store_url.${index2}.select_store`}
                                                          //value={null}
                                                          onChange={(e) => handleChangeStoreURL(e)}
                                                          //onBlur={handleBlur}
                                                          className='form-select'
                                                          value={selectedvalue}
                                                        >
                                                          {selectOptions
                                                            .filter(
                                                              (opt) =>
                                                                !selectedvalue.includes(opt.value)
                                                            )
                                                            .map((option: any, index: any) => {
                                                              // console.log('option', option[index]);
                                                              return (
                                                                <option
                                                                  key={index}
                                                                  value={option.value}
                                                                >
                                                                  {option.label}
                                                                </option>
                                                              );
                                                            })}
                                                        </select> */}
                                                    </div>
                                                    <div className='col-2' />
                                                    <div className='col-6'>
                                                      <div className='d-flex gap-3'>
                                                        <Field
                                                          id={`setting.${index}.app_store_url.${index2}.store_url`}
                                                          name={`setting.${index}.app_store_url.${index2}.store_url`}
                                                          placeholder='Enter App Name Override Value'
                                                          className='text-input'
                                                        />
                                                        <MdDeleteOutline
                                                          fontSize={30}
                                                          className='svg-icon'
                                                          onClick={() =>
                                                            arrayHelpers2.remove(index2)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                    </div>
                                    <button
                                      type='button'
                                      className='button button-primary mb-3'
                                      onClick={() => {
                                        arrayHelpers2.push({});
                                      }}
                                    >
                                      + ADD
                                    </button>
                                    <div className='row align-items-center'>
                                      <div className='col-6'>
                                        <label className='label mb-0'>
                                          Add value only if obj. empty in bid request
                                        </label>
                                      </div>
                                      <div className='col-6'>
                                        <div className='align-items-center d-flex'>
                                          <label className='label mb-0 me-2'>
                                            {softFloorSSAI ? 'Enabled' : 'Disable'}
                                          </label>
                                          <div
                                            className={
                                              autoEnrichment ? 'disable-field mt-2' : 'mt-2'
                                            }
                                          >
                                            <Switch
                                              name='app_store_status'
                                              value='Enabled'
                                              api={() => {
                                                toggleSwitchMix('app_store_status');
                                              }}
                                              checked={checked['app_store_status']}
                                              status={
                                                settingGetObj?.stoggleSwitchMix?.app_store_status ??
                                                false
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* App Store URL End */}

                      {/* App Name Override Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='1'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>App Name Override</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {values.setting[index].app_name_override.length > 0 ? (
                                    <div className='accordian-more'>
                                      {values.setting[index].app_name_override.map(
                                        (res: any, index: any) => {
                                          return (
                                            <>
                                              <small
                                                className={
                                                  res.app_name_override_select
                                                    ? 'text-capitalize me-1 active'
                                                    : 'text-capitalize me-1'
                                                }
                                                key={index}
                                              >
                                                {res.app_name_override_select}
                                              </small>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {values.setting[index].app_name_override.length > 0 && (
                                    <small>Active</small>
                                  )}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='1'>
                          <Card.Body className='py-4 accordion-body'>
                            <FieldArray
                              //id={`msa.${index}.legalTeamCC`}
                              name={`setting.${index}.app_name_override`}
                              render={(arrayHelpers2) => {
                                return (
                                  <>
                                    <div>
                                      {values.setting[index].app_name_override &&
                                        values.setting[index].app_name_override.length > 0 && (
                                          <>
                                            {values.setting[index].app_name_override.map(
                                              (q: any, index2: any) => {
                                                //console.log('q',index)
                                                return (
                                                  <div className='row mb-3' key={index2}>
                                                    <div className='col-4'>
                                                      <Field
                                                        component='select'
                                                        name={`setting.${index}.app_name_override.${index2}.app_name_override_select`}
                                                        className='form-select'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                      >
                                                        <option
                                                          value='Select Store'
                                                          label='Select Store'
                                                        />
                                                        <option value='all' label='All' />
                                                        <option value='airtv' label='AirTV' />
                                                        <option value='amazon' label='Amazon' />
                                                        <option value='apple' label='Apple' />
                                                        <option value='bravia' label='Bravia' />
                                                        <option
                                                          value='chromecast'
                                                          label='Chromecast'
                                                        />
                                                        <option value='comcast' label='Comcast' />
                                                        <option value='dish' label='Dish' />
                                                        <option value='generic' label='Generic' />
                                                        <option value='google' label='Google' />
                                                        <option value='hisense' label='Hisense' />
                                                        <option value='huawei' label='Huawei' />
                                                        <option value='lg' label='LG' />
                                                        <option value='nvidia' label='Nvidia' />
                                                        <option
                                                          value='panasonic'
                                                          label='Panasonic'
                                                        />
                                                      </Field>
                                                    </div>
                                                    <div className='col-2' />
                                                    <div className='col-6'>
                                                      <div className='d-flex align-items-center gap-3'>
                                                        <Field
                                                          id={`setting.${index}.app_name_override.${index2}.app_name_override_name_value`}
                                                          name={`setting.${index}.app_name_override.${index2}.app_name_override_name_value`}
                                                          placeholder='Enter App Name Override Value'
                                                          className='text-input'
                                                        />
                                                        <MdDeleteOutline
                                                          fontSize={30}
                                                          className='svg-icon'
                                                          onClick={() =>
                                                            arrayHelpers2.remove(index2)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                    </div>
                                    <button
                                      type='button'
                                      className='button button-primary mb-3'
                                      onClick={() => {
                                        arrayHelpers2.push({});
                                      }}
                                    >
                                      + ADD
                                    </button>
                                    <div className='row align-items-center'>
                                      <div className='col-6'>
                                        <label className='label mb-0'>
                                          Add value only if obj. empty in bid request
                                        </label>
                                      </div>
                                      <div className='col-6'>
                                        <div className='align-items-center d-flex'>
                                          <label className='label mb-0 me-2'>
                                            {softFloorSSAI ? 'Enabled' : 'Disable'}
                                          </label>
                                          <div
                                            className={
                                              autoEnrichment ? 'disable-field mt-2' : 'mt-2'
                                            }
                                          >
                                            <Switch
                                              name='app_name_override_status'
                                              value='Enabled'
                                              api={() => {
                                                toggleSwitchMix('app_name_override_status');
                                              }}
                                              checked={checked['app_name_override_status']}
                                              status={
                                                settingGetObj?.stoggleSwitchMix
                                                  ?.app_name_override_status ?? false
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* App Name Override End */}

                      {/* App Bundle Override Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='2'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>App Bundle Override</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {values.setting[index].app_bundle_override.length > 0 ? (
                                    <div className='accordian-more'>
                                      {values.setting[index].app_bundle_override.map(
                                        (res: any, index: any) => {
                                          return (
                                            <>
                                              <small
                                                className={
                                                  res.app_bundle_override_select
                                                    ? 'text-capitalize me-1 active'
                                                    : 'text-capitalize me-1'
                                                }
                                                key={index}
                                              >
                                                {res.app_bundle_override_select}
                                              </small>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {values.setting[index].app_bundle_override.length > 0 && (
                                    <small>Active</small>
                                  )}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='2'>
                          <Card.Body className='py-4 accordion-body'>
                            <FieldArray
                              //id={`msa.${index}.legalTeamCC`}
                              name={`setting.${index}.app_bundle_override`}
                              render={(arrayHelpers2) => {
                                return (
                                  <>
                                    <div>
                                      {values.setting[index].app_bundle_override &&
                                        values.setting[index].app_bundle_override.length > 0 && (
                                          <>
                                            {values.setting[index].app_bundle_override.map(
                                              (q: any, index2: any) => {
                                                //console.log('q',index)
                                                return (
                                                  <div className='row mb-3' key={index2}>
                                                    <div className='col-4'>
                                                      <Field
                                                        component='select'
                                                        name={`setting.${index}.app_bundle_override.${index2}.app_bundle_override_select`}
                                                        className='form-select'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                      >
                                                        <option
                                                          value='Select Store'
                                                          label='Select Store'
                                                        />
                                                        <option value='all' label='All' />
                                                        <option value='airtv' label='AirTV' />
                                                        <option value='amazon' label='Amazon' />
                                                        <option value='apple' label='Apple' />
                                                        <option value='bravia' label='Bravia' />
                                                        <option
                                                          value='chromecast'
                                                          label='Chromecast'
                                                        />
                                                        <option value='comcast' label='Comcast' />
                                                        <option value='dish' label='Dish' />
                                                        <option value='generic' label='Generic' />
                                                        <option value='google' label='Google' />
                                                        <option value='hisense' label='Hisense' />
                                                        <option value='huawei' label='Huawei' />
                                                        <option value='lg' label='LG' />
                                                        <option value='nvidia' label='Nvidia' />
                                                        <option
                                                          value='panasonic'
                                                          label='Panasonic'
                                                        />
                                                      </Field>
                                                    </div>
                                                    <div className='col-2' />
                                                    <div className='col-6'>
                                                      <div className='d-flex align-items-center gap-3'>
                                                        <Field
                                                          id={`setting.${index}.app_bundle_override.${index2}.app_bundle_override_value`}
                                                          name={`setting.${index}.app_bundle_override.${index2}.app_bundle_override_value`}
                                                          placeholder='Enter App Name Override Value'
                                                          className='text-input'
                                                        />
                                                        <MdDeleteOutline
                                                          fontSize={30}
                                                          className='svg-icon'
                                                          onClick={() =>
                                                            arrayHelpers2.remove(index2)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                    </div>
                                    <button
                                      type='button'
                                      className='button button-primary mb-3'
                                      onClick={() => {
                                        arrayHelpers2.push({});
                                      }}
                                    >
                                      + ADD
                                    </button>
                                    <div className='row align-items-center'>
                                      <div className='col-6'>
                                        <label className='label mb-0'>
                                          Add value only if obj. empty in bid request
                                        </label>
                                      </div>
                                      <div className='col-6'>
                                        <div className='align-items-center d-flex'>
                                          <label className='label mb-0 me-2'>
                                            {softFloorSSAI ? 'Enabled' : 'Disable'}
                                          </label>
                                          <div
                                            className={
                                              autoEnrichment ? 'disable-field mt-2' : 'mt-2'
                                            }
                                          >
                                            <Switch
                                              name='app_bundle_override_status'
                                              value='Enabled'
                                              api={() => {
                                                toggleSwitchMix('app_bundle_override_status');
                                              }}
                                              checked={checked['app_bundle_override_status']}
                                              status={
                                                settingGetObj?.stoggleSwitchMix
                                                  ?.app_bundle_override_status ?? false
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* App Bundle Override End */}

                      {/* App Cat Override Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='3'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>App Cat Override</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {values.setting[index].app_cat_override.length > 0 ? (
                                    <small>Active</small>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {values.setting[index].app_cat_override.length > 0 && (
                                    <small>Active</small>
                                  )}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='3'>
                          <Card.Body className='py-4 accordion-body'>
                            <div className='row align-items-center'>
                              <div className='col-6'>
                                <label className='label mb-0'>
                                  Add value only if obj. empty in bid request
                                </label>
                              </div>
                              <div className='col-6'>
                                <div className='align-items-center d-flex'>
                                  <label className='label mb-0 me-2'>
                                    {softFloorSSAI ? 'Enabled' : 'Disable'}
                                  </label>
                                  <div className={autoEnrichment ? 'disable-field mt-2' : 'mt-2'}>
                                    <Switch
                                      name='app_cat_override_status'
                                      value='Enabled'
                                      api={() => {
                                        toggleSwitchMix('app_cat_override_status');
                                      }}
                                      checked={checked['app_cat_override_status']}
                                      status={
                                        settingGetObj?.stoggleSwitchMix?.app_cat_override_status ??
                                        false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row align-items-center mt-2'>
                              <div className='col-6'>
                                <label className='label'>Override</label>
                              </div>
                              <div className='col-6'>
                                <Field
                                  type='text'
                                  placeholder='Enter App Cat Override value here'
                                  className='text-input'
                                  name={`setting.${index}.app_cat_override`}
                                  onChangeText={settingGetObj?.app_cat_override ?? ''}
                                />
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* App Cat Override End */}

                      {/* Site Name Override Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='4'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>Site Name Override</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {values.setting[index].app_site_name_override.length > 0 ? (
                                    <small>Web</small>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {values.setting[index].app_site_name_override.length > 0 && (
                                    <small>Active</small>
                                  )}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='4'>
                          <Card.Body className='py-4 accordion-body'>
                            <div className='row align-items-center'>
                              <div className='col-6'>
                                <label className='label mb-0'>Web</label>
                              </div>
                              <div className='col-6'>
                                <Field
                                  type='text'
                                  placeholder='Enter Site Name Override value here'
                                  className='text-input'
                                  name={`setting.${index}.app_site_name_override`}
                                  onChangeText={settingGetObj?.app_site_name_override ?? ''}
                                />
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* Site Name Override End */}

                      {/* Site Content Override Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='5'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>Content</div>
                            <div className='col-8'>
                              <div className='row'>
                                {values.setting[index].content[index]
                                  .content_distributor_name_override.length > 0 ||
                                values.setting[index].content[index].content_channel_override
                                  .length > 0 ||
                                values.setting[index].content[index].content_network_override
                                  .length > 0 ||
                                values.setting[index].content[index].content_series_override
                                  .length > 0 ||
                                values.setting[index].content[index].content_title_override.length >
                                  0 ||
                                values.setting[index].content[index].content_category_override
                                  .length > 0 ||
                                values.setting[index].content[index].content_genre_override.length >
                                  0 ||
                                values.setting[index].content[index].content_rating_override
                                  .length > 0 ||
                                values.setting[index].content[index].content_prodqual_override
                                  .length > 0 ||
                                values.setting[index].content[index].content_keywords_override
                                  .length > 0 ||
                                values.setting[index].content[index].content_language_override
                                  .length > 0 ? (
                                  <>
                                    <div className='col-6'>
                                      <small>Content Override</small>
                                    </div>
                                    <div className='col-3'>
                                      <small>Active</small>
                                    </div>
                                  </>
                                ) : (
                                  <div className='col-6'>
                                    <small>Inactive</small>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='5'>
                          <Card.Body className='py-4 accordion-body'>
                            <div className='row align-items-center mb-3'>
                              <div className='col-6'>
                                <label className='label mb-0'>
                                  Remove entire content object from endpoint request
                                </label>
                              </div>
                              <div className='col-6'>
                                <div className='align-items-center d-flex'>
                                  <label className='label mb-0 me-2'>
                                    {softFloorSSAI ? 'Enabled' : 'Disable'}
                                  </label>
                                  <div className={autoEnrichment ? 'disable-field mt-2' : 'mt-2'}>
                                    <Switch
                                      name='entire_endpoint_requrest'
                                      value='Enabled'
                                      api={() => {
                                        handleContentSwitch('entire_endpoint_requrest');
                                      }}
                                      checked={checked['entire_endpoint_requrest']}
                                      status={
                                        settingGetObj?.contentToggle?.entire_endpoint_requrest ??
                                        false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index]
                                      .content_distributor_name_override
                                  }
                                >
                                  Content Distributor Name Override
                                </label>
                                {/* <Content
                                  settingGetObj={settingGetObj}
                                  contentInput={`setting.${index}.content.${index}.content_distributor_name_override`}
                                /> */}
                                <Field
                                  type='text'
                                  placeholder='Enter Content Distributor Name Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_distributor_name_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index]
                                        .content_distributor_name_override.length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index]
                                        .content_distributor_name_override.length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field mt-2' : 'mt-2'}>
                                      <Switch
                                        name='content_distributor_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch(
                                            'content_distributor_override_status'
                                          );
                                        }}
                                        checked={checked['content_distributor_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_distributor_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>
                                    Remove distributor name from request
                                  </label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_distributor_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_distributor_remove_status');
                                        }}
                                        checked={checked['content_distributor_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_distributor_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*Content Channel Override start*/}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_channel_override
                                  }
                                >
                                  Content Channel Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Content Channel Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_channel_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_channel_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_channel_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_channel_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_channel_override_status');
                                        }}
                                        checked={checked['content_channel_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_channel_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove channel from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_channel_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_channel_remove_status');
                                        }}
                                        checked={checked['content_channel_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_channel_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*Content Channel Override end*/}

                            {/* Content Network Override start */}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_network_override
                                  }
                                >
                                  Content Network Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Content Network Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_network_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_network_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_network_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_network_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_network_override_status');
                                        }}
                                        checked={checked['content_network_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_network_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove network from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_network_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_network_remove_status');
                                        }}
                                        checked={checked['content_network_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_network_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Content Network Override end */}

                            {/* Content Series Override start */}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_series_override
                                  }
                                >
                                  Content Series Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Content Channel Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_series_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_series_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_series_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_series_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_series_override_status');
                                        }}
                                        checked={checked['content_series_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_series_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove series from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_series_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_series_remove_status');
                                        }}
                                        checked={checked['content_series_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_series_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*Content Network Override end*/}

                            {/* Content Title Override start */}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_title_override
                                  }
                                >
                                  Content Title Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Content Title Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_title_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_title_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_title_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_title_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_title_override_status');
                                        }}
                                        checked={checked['content_title_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_title_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove title from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_title_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_title_remove_status');
                                        }}
                                        checked={checked['content_title_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_title_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*Content Title Override end*/}

                            {/* Content Category Override start */}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_category_override
                                  }
                                >
                                  Content Category Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Content Category Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_category_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_category_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_category_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_category_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_category_override_status');
                                        }}
                                        checked={checked['content_category_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_category_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove category from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_category_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_category_remove_status');
                                        }}
                                        checked={checked['content_category_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_category_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Content Category Override end*/}

                            {/* Content Genre Override start */}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_genre_override
                                  }
                                >
                                  Content Genre Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Genre Category Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_genre_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_genre_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_genre_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_genre_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_genre_override_status');
                                        }}
                                        checked={checked['content_genre_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_genre_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove genre from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_genre_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_genre_remove_status');
                                        }}
                                        checked={checked['content_genre_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_genre_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Content Genre Override end*/}

                            {/* Content Rating Override start */}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_rating_override
                                  }
                                >
                                  Content Rating Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Content Rating Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_rating_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_rating_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_rating_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_rating_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_rating_override_status');
                                        }}
                                        checked={checked['content_rating_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_rating_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove rating from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_rating_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_rating_remove_status');
                                        }}
                                        checked={checked['content_rating_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_rating_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Content Rating Override end*/}

                            {/* Content Prodqual Override start */}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_prodqual_override
                                  }
                                >
                                  Content Prodqual Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Content Prodqual Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_prodqual_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_prodqual_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_prodqual_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_prodqual_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_prodqual_override_status');
                                        }}
                                        checked={checked['content_prodqual_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_prodqual_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove prodqual from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_prodqual_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_prodqual_remove_status');
                                        }}
                                        checked={checked['content_prodqual_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_prodqual_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Content Rating Override end*/}

                            {/* Content Keywords Override start */}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_keywords_override
                                  }
                                >
                                  Content Keywords Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Keywords Prodqual Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_keywords_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_keywords_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_keywords_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_keywords_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_keywords_override_status');
                                        }}
                                        checked={checked['content_keywords_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_keywords_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove Keywords from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_keywords_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_keywords_remove_status');
                                        }}
                                        checked={checked['content_keywords_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_keywords_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Content Keywords Override end*/}

                            {/* Content Language Override start */}
                            <div className='row mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].content[index].content_language_override
                                  }
                                >
                                  Content Language Override
                                </label>
                                <Field
                                  type='text'
                                  placeholder='Enter Language Prodqual Override value here'
                                  className={
                                    contentToggle.entire_endpoint_requrest === false
                                      ? 'enable-field text-input'
                                      : 'disable-field text-input'
                                  }
                                  name={`setting.${index}.content.${index}.content_language_override`}
                                />
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label
                                    className={
                                      values.setting[index].content[index].content_language_override
                                        .length > 0
                                        ? 'label fw-lighter text-black'
                                        : 'label'
                                    }
                                  >
                                    Add value only if obj. empty in endpoint request
                                  </label>
                                  <div
                                    className={
                                      values.setting[index].content[index].content_language_override
                                        .length > 0
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_distributor_override_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch(
                                            'content_distributor_override_status'
                                          );
                                        }}
                                        checked={checked['content_distributor_override_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_distributor_override_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3'>
                                <label></label>
                                <div className='d-flex align-items-center gap-4 px-2 justify-content-between'>
                                  <label className='label'>Remove Language from request</label>
                                  <div
                                    className={
                                      contentToggle.entire_endpoint_requrest === false
                                        ? 'enable-field'
                                        : 'disable-field'
                                    }
                                  >
                                    <div className={autoEnrichment ? 'disable-field' : ''}>
                                      <Switch
                                        name='content_language_remove_status'
                                        value='Enabled'
                                        api={() => {
                                          handleContentSwitch('content_language_remove_status');
                                        }}
                                        checked={checked['content_language_remove_status']}
                                        status={
                                          settingGetObj?.contentToggle
                                            ?.content_language_remove_status ?? false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Content Language Override end*/}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* Site Content  Override Start */}

                      {/* Device Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='6'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>Device</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {checked.anonymize_device === true ? (
                                    <small>Anonymize Device ID</small>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {checked.anonymize_device === true && <small>Active</small>}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='6'>
                          <Card.Body className='py-4 accordion-body'>
                            <div className='row align-items-center'>
                              <div className='col-6'>
                                <label className='label mb-0'>Anonymize Device ID</label>
                              </div>
                              <div className='col-6'>
                                <input
                                  type='checkbox'
                                  name={`anonymize_device`}
                                  value='holis'
                                  onChange={() => toggleCheck('anonymize_device')}
                                  checked={checked['anonymize_device']}
                                />
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* Device Start */}

                      {/* PMP Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='7'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>PMP</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {checked.deal_id_endpoint === true ? (
                                    <small>Endpoin Deal ID</small>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {checked.deal_id_endpoint === true && <small>Active</small>}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='7'>
                          <Card.Body className='py-4 accordion-body'>
                            <div className='row'>
                              <div className='col-6'>
                                <label className='label mb-0'>
                                  Deal ID passthrough from Endpoint
                                </label>
                              </div>
                              <div className='col-auto'>
                                <input
                                  type='checkbox'
                                  name={`deal_id_endpoint`}
                                  value='holis'
                                  onChange={() => toggleCheck('deal_id_endpoint')}
                                  checked={checked['deal_id_endpoint']}
                                />
                              </div>
                              <div className='col-5'>
                                <div className='d-flex'>
                                  <MdInfoOutline
                                    fontSize={20}
                                    className='flex-shrink-0 me-2 svg-icon'
                                  />
                                  <small>
                                    If enabled, deal IDs passed through the endpoint request will be
                                    added to the bid request
                                  </small>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* Start Start */}

                      {/* User Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='8'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>User</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {checked.anonymize_ip === true ||
                                  stoggleSwitchMix.user_set_coppa_status === true ? (
                                    <>
                                      {checked.anonymize_ip === true && (
                                        <small>Anonymize IP, &nbsp;</small>
                                      )}
                                      {stoggleSwitchMix.user_set_coppa_status === true && (
                                        <small>Set Coppa to 1 &nbsp;</small>
                                      )}
                                    </>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {(stoggleSwitchMix.user_set_coppa_status === true ||
                                    checked.anonymize_ip === true) && <small>Active</small>}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='8'>
                          <Card.Body className='py-4 accordion-body'>
                            <div className='row align-items-center'>
                              <div className='col-6'>
                                <label className='label mb-0'>Anonymize IP</label>
                              </div>
                              <div className='col-6'>
                                <input
                                  type='checkbox'
                                  name={`anonymize_ip`}
                                  value='holis'
                                  onChange={() => toggleCheck('anonymize_ip')}
                                  checked={checked['anonymize_ip']}
                                />
                              </div>
                            </div>
                            <div className='row align-items-center mt-3'>
                              <div className='col-6'>
                                <label className='label mb-0'>Set Coppa to 1</label>
                              </div>
                              <div className='col-6'>
                                <div className='align-items-center d-flex'>
                                  <label className='label mb-0 me-2'>
                                    {softFloorSSAI ? 'Enabled' : 'Disable'}
                                  </label>
                                  <div className={autoEnrichment ? 'disable-field mt-2' : 'mt-2'}>
                                    <Switch
                                      name='user_set_coppa_status'
                                      value='Enabled'
                                      api={() => {
                                        toggleSwitchMix('user_set_coppa_status');
                                      }}
                                      checked={checked['user_set_coppa_status']}
                                      status={
                                        settingGetObj?.stoggleSwitchMix?.user_set_coppa_status ??
                                        false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* User End */}

                      {/* Video Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='9'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>Video</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {values.setting[index].video[index].video_min_ad_duration_override
                                    .length > 0 ||
                                  values.setting[index].video[index].video_max_ad_duration_override
                                    .length > 0 ||
                                  values.setting[index].video[index].video_start_delay_override
                                    .length > 0 ||
                                  values.setting[index].video[index].video_min_ad_duration_override
                                    .length > 0 ||
                                  values.setting[index].video[index].video_skip_min.length > 0 ||
                                  settingGetObj?.stoggleSwitchMix?.video_skip_status === true ||
                                  stoggleSwitchMix?.video_skip_status === true ||
                                  checked.max_min_bitrate === true ? (
                                    <small>Video Setting</small>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {values.setting[index].video[index].video_min_ad_duration_override
                                    .length > 0 ||
                                  values.setting[index].video[index].video_max_ad_duration_override
                                    .length > 0 ||
                                  values.setting[index].video[index].video_start_delay_override
                                    .length > 0 ||
                                  values.setting[index].video[index].video_min_ad_duration_override
                                    .length > 0 ||
                                  values.setting[index].video[index].video_skip_after.length > 0 ||
                                  values.setting[index].video[index].video_skip_min.length > 0 ||
                                  settingGetObj?.stoggleSwitchMix?.video_skip_status === true ||
                                  stoggleSwitchMix?.video_skip_status === true ||
                                  checked.max_min_bitrate === true ? (
                                    <small>Active</small>
                                  ) : null}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='9'>
                          <Card.Body className='py-4 accordion-body'>
                            <div className='row align-items-center mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].video[index]
                                      .video_min_ad_duration_override
                                  }
                                >
                                  Min Ad Duration Override
                                </label>
                              </div>
                              <div className='col-2'>
                                <Field
                                  type='text'
                                  className='text-input'
                                  name={`setting.${index}.video.${index}.video_min_ad_duration_override`}
                                  onChangeText={
                                    settingGetObj?.video?.[0].video_min_ad_duration_override ?? ''
                                  }
                                />
                              </div>
                            </div>
                            <div className='row align-items-center mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].video[index]
                                      .video_max_ad_duration_override
                                  }
                                >
                                  Max Ad Duration Override
                                </label>
                              </div>
                              <div className='col-2'>
                                <Field
                                  type='text'
                                  className='text-input'
                                  name={`setting.${index}.video.${index}.video_max_ad_duration_override`}
                                  onChangeText={
                                    settingGetObj?.video?.[0].video_max_ad_duration_override ?? ''
                                  }
                                  //value={settingVideoObj?.video_max_ad_duration_override}
                                />
                              </div>
                            </div>
                            <div className='row align-items-center mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={
                                    values.setting[index].video[index].video_start_delay_override
                                  }
                                >
                                  Start Delay Override
                                </label>
                              </div>
                              <div className='col-2'>
                                <Field
                                  type='text'
                                  className='text-input'
                                  name={`setting.${index}.video.${index}.video_start_delay_override`}
                                  onChangeText={
                                    settingGetObj?.video?.[0].video_start_delay_override ?? ''
                                  }
                                />
                              </div>
                              <div className='col-4 ps-0'>
                                <div className='d-flex'>
                                  <MdInfoOutline
                                    fontSize={20}
                                    className='flex-shrink-0 me-2 svg-icon'
                                  />
                                  <small>
                                    Refer to Section 5.12 of the oRTB Spec for acceptable values.
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div className='row align-items-center mb-4 mt-3'>
                              <div className='col-6'>
                                <label className='label'>Clear default max/min bitrate</label>
                              </div>
                              <div className='col-6'>
                                <div className='d-flex'>
                                  <input
                                    type='checkbox'
                                    name={`max_min_bitrate`}
                                    value='holis'
                                    onChange={() => toggleCheck('max_min_bitrate')}
                                    checked={checked['max_min_bitrate']}
                                    className='flex-shrink-0 me-4'
                                  />
                                  <MdInfoOutline
                                    fontSize={20}
                                    className='flex-shrink-0 me-2 svg-icon'
                                  />
                                  <small>
                                    Use this option to only pass a min and max bitrate if its sent
                                    in the endpoint request.
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div className='row align-items-center mb-3'>
                              <div className='col-6'>
                                <label className='label mb-0'>Skip</label>
                              </div>
                              <div className='col-6'>
                                <div className='align-items-center d-flex'>
                                  <label className='label mb-0 me-2'>
                                    {softFloorSSAI ? 'Enabled' : 'Disable'}
                                  </label>
                                  <div className={autoEnrichment ? 'disable-field mt-2' : 'mt-2'}>
                                    <Switch
                                      name='video_skip_status'
                                      value='Enabled'
                                      api={() => {
                                        toggleSwitchMix('video_skip_status');
                                      }}
                                      checked={checked['video_skip_status']}
                                      status={
                                        settingGetObj?.stoggleSwitchMix?.video_skip_status ?? false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row align-items-center mb-3'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={values.setting[index].video[index].video_skip_min}
                                >
                                  Skip Min
                                </label>
                              </div>
                              <div className='col-2'>
                                <Field
                                  type='text'
                                  className='text-input'
                                  name={`setting.${index}.video.${index}.video_skip_min`}
                                  onChangeText={settingGetObj?.video?.[0].video_skip_min ?? ''}
                                />
                              </div>
                              <div className='col-4 ps-0'>
                                <div className='d-flex'>
                                  <MdInfoOutline
                                    fontSize={20}
                                    className='flex-shrink-0 me-2 svg-icon'
                                  />
                                  <small>
                                    Videos of total duration greater than this number of seconds can
                                    be skippable; only applicable if the ad is skippable.
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div className='row align-items-center'>
                              <div className='col-6'>
                                <label
                                  className='label'
                                  htmlFor={values.setting[index].video[index].video_skip_after}
                                >
                                  Skip After
                                </label>
                              </div>
                              <div className='col-2'>
                                <Field
                                  type='text'
                                  className='text-input'
                                  name={`setting.${index}.video.${index}.video_skip_after`}
                                  onChangeText={settingGetObj?.video?.[0].video_skip_after ?? ''}
                                />
                              </div>
                              <div className='col-4 ps-0'>
                                <div className='d-flex'>
                                  <MdInfoOutline
                                    fontSize={20}
                                    className='flex-shrink-0 me-2 svg-icon'
                                  />
                                  <small>
                                    Number of seconds a video must play before skipping is enabled;
                                    only applicable if the ad is skippable.
                                  </small>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* Video End */}

                      {/* Supply Chain Start */}
                      <Accordion className='accordion-setting mw-100'>
                        <Accordion.Toggle
                          className='mw-100 w-100 accordion-header'
                          as={Button}
                          variant='link'
                          eventKey='10'
                          onClick={handleOnChange}
                        >
                          <div className='row'>
                            <div className='col-4 title'>Supply Chain</div>
                            <div className='col-8'>
                              <div className='row'>
                                <div className='col-6'>
                                  {settingGetObj?.stoggleSwitchMix?.supply_chain_status === true ||
                                  stoggleSwitchMix?.supply_chain_status === true ? (
                                    <small>Add Schain</small>
                                  ) : (
                                    <small>Inactive</small>
                                  )}
                                </div>
                                <div className='col-3'>
                                  {(settingGetObj?.stoggleSwitchMix?.supply_chain_status === true ||
                                    stoggleSwitchMix?.supply_chain_status === true) && (
                                    <small>Active</small>
                                  )}
                                </div>
                                <div className='col-3'></div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='10'>
                          <Card.Body className='py-4 accordion-body'>
                            <div className='row align-items-center mb-3'>
                              <div className='col-6'>
                                <label className='label mb-0'>Add schain to bid request</label>
                              </div>
                              <div className='col-6'>
                                <div className='align-items-center d-flex'>
                                  <label className='label mb-0 me-2'>
                                    {softFloorSSAI ? 'Enabled' : 'Disable'}
                                  </label>
                                  <div className={autoEnrichment ? 'disable-field mt-2' : 'mt-2'}>
                                    <Switch
                                      name='supply_chain_status'
                                      value='Enabled'
                                      api={() => {
                                        toggleSwitchMix('supply_chain_status');
                                      }}
                                      checked={checked['supply_chain_status']}
                                      status={
                                        settingGetObj?.stoggleSwitchMix?.supply_chain_status ??
                                        false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(settingGetObj?.stoggleSwitchMix?.supply_chain_status === true ||
                              stoggleSwitchMix?.supply_chain_status === true) && (
                              <>
                                <div className='row align-items-center mb-3'>
                                  <div className='col-6'>
                                    <label
                                      className='label'
                                      htmlFor={values.setting[index].supply_chain[index].asi}
                                    >
                                      ASI
                                    </label>
                                  </div>
                                  <div className='col-6'>
                                    <Field
                                      type='text'
                                      className='text-input'
                                      name={`setting.${index}.supply_chain.${index}.asi`}
                                      onChangeText={settingGetObj?.supply_chain?.[0].asi ?? ''}
                                    />
                                  </div>
                                </div>
                                <div className='row align-items-center mb-3'>
                                  <div className='col-6'>
                                    <label
                                      className='label'
                                      htmlFor={values.setting[index].supply_chain[index].sid}
                                    >
                                      SID
                                    </label>
                                  </div>
                                  <div className='col-6'>
                                    <Field
                                      type='text'
                                      className='text-input'
                                      name={`setting.${index}.supply_chain.${index}.sid`}
                                      onChangeText={settingGetObj?.supply_chain?.[0].sid ?? ''}
                                    />
                                  </div>
                                </div>
                                <div className='row align-items-center mb-3'>
                                  <div className='col-6'>
                                    <label
                                      className='label'
                                      htmlFor={values.setting[index].supply_chain[index].name}
                                    >
                                      Name
                                    </label>
                                  </div>
                                  <div className='col-6'>
                                    <Field
                                      type='text'
                                      className='text-input'
                                      name={`setting.${index}.supply_chain.${index}.name`}
                                      onChangeText={settingGetObj?.supply_chain?.[0].name ?? ''}
                                    />
                                  </div>
                                </div>
                                <div className='row align-items-center mb-3'>
                                  <div className='col-6'>
                                    <label
                                      className='label'
                                      htmlFor={values.setting[index].supply_chain[index].domain}
                                    >
                                      Domain
                                    </label>
                                  </div>
                                  <div className='col-6'>
                                    <Field
                                      type='text'
                                      className='text-input'
                                      name={`setting.${index}.supply_chain.${index}.domain`}
                                      onChangeText={settingGetObj?.supply_chain?.[0].domain ?? ''}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                      {/* Supply Chain End */}
                    </div>
                  </div>
                ))
              : null}
          </Form>
        )}
      </Formik>
    </>
  );
}

export default SSPSettingTab;
