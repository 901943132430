import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { GoLinkExternal } from 'react-icons/go';
import { MdClose } from 'react-icons/md';
// import InviteCustomerTable from '../../tables/InviteCustomer';
import { headers, routes } from '../../../services/constants';
import store from '../../../states';
import DiscoverFilter from './DiscoverBy';
// import CSVTable from '../../comman/csvUpload/CsvTable';
import NewInviteCustomer from '../../tables/NewInviteCustomer';

const SpoCustomerList = (Props: any) => {
  const { show, type, getSelectedAgency } = Props;

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [removePublisher, sRemovePublisher] = useState<any>({});
  const [glossary, sGlossary] = useState<any>({});
  const [filter, setFilter] = useState({ country: [], keyword: [], category: [], technology: [] });
  const [selectedRowData, sSelectedRowData] = useState([]);
  // const [loadingBtn, setLoadingBtn] = useState(false);
  const [moveNextPage, setMoveNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   getMoreData(0, '', {}, {});
  // }, [show]);

  const getMoreData = async (pageIndex: number, keyword: string, sort: any, filterBy: any) => {
    setLoading(true);
    setMoveNextPage(false);

    const Payload = {
      page: pageIndex,
      page_size: 50,
      query: keyword,
      sort_by: sort
        ? {
            sortBy: [sort],
          }
        : {},
      category: filterBy.category ?? [],
      country: filterBy.country ?? [],
      keyword: filterBy.keyword ?? [],
      technology: filterBy.technology ?? [],

      ...(Props.dealID !== undefined && { deal_id: Props.dealID }),
    };

    const optionsPost = {
      headers,
      method: 'POST',
      body: JSON.stringify(Payload),
    };

    const response = await fetch(
      type === 'spo-publisher' ? routes?.customerSSP : routes?.customerSPO,
      optionsPost
    );

    try {
      const newData = await response.json();
      // handle parsed data
      setCurrentPage(pageIndex);
      if (newData?.next_page) {
        setMoveNextPage(newData?.next_page);
      }

      if (newData?.data?.length === 0) {
        setHasMore(false);
      }
      if (pageIndex > 0) {
        const mergeData: any = [...data, ...newData.data];
        setData(mergeData.filter((item: any) => !item.is_exist));
      } else {
        setData(newData?.data.filter((item: any) => !item.is_exist));
        setColumns(newData?.columns);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      // handle parsing error
    }
  };

  // On scroll next page
  function onNextPage(pageNumber: number) {
    getMoreData(pageNumber, keyword, '', filter);
  }

  // Get Search Input Value
  function onSearchChage(val: string) {
    setKeyword(val);
    if (val.length > 0) {
      getMoreData(0, val, {}, filter);
    } else {
      getMoreData(0, '', {}, filter);
    }
    setData([]);
  }

  // Sorting Data
  // function handleSort(sortBy: any) {
  //   getMoreData(0, '', sortBy, filter);
  //   setLoading(false);
  // }

  // Call Back function for any action event
  const apiCallBack = () => {
    getMoreData(0, '', {}, {});
  };

  // remove publisher
  const handleRemovePublisher = (id: string) => {
    // sSelectedRowData((prev: any) => prev.filter((item: any) => item.domain !== id));
    sRemovePublisher(selectedRowData.find((item: any) => item.domain === id));

    setTimeout(() => {
      sRemovePublisher({});
    }, 200);
  };

  // handle selected row data
  const handleSelectedRowData = (array: any) => {
    sSelectedRowData(array);
  };

  useEffect(() => {
    if (getSelectedAgency) getSelectedAgency(selectedRowData);
  }, [getSelectedAgency, selectedRowData]);

  const handleFilter = (val: any) => {
    setFilter({
      country: val.country ?? [],
      keyword: val.keyword ?? [],
      category: val.category ?? [],
      technology: val.technology ?? [],
    });

    if (val?.country || val?.keyword) {
      getMoreData(0, keyword, '', val);
      setHasMore(true);
      setData([]);
    }
  };

  return (
    <>
      <div className='d-flex'>
        <div className='' style={{ width: '250px', paddingRight: 10 }}>
          <h3 className='pt-2'>{glossary.spo_invite_popup_filter_title}</h3>
          <DiscoverFilter getFilter={(filter: any) => handleFilter(filter)} />
        </div>
        <div className='row w-100'>
          <div className='col-6'>
            <NewInviteCustomer
              rows={data}
              columns={columns}
              onNextPage={onNextPage}
              onSearchChage={onSearchChage}
              hasMore={hasMore}
              loading={loading}
              apiCallBack={apiCallBack}
              search={true}
              moveNextPage={moveNextPage}
              type={type}
              selectedRowData={handleSelectedRowData}
              currentPage={currentPage}
              removeRow={removePublisher}
            />

            {/* <InviteCustomerTable
              data={data}
              columns={columns}
              onNextPage={onNextPage}
              onSearchChage={onSearchChage}
              onSort={handleSort}
              hasMore={hasMore}
              loading={loading}
              columnWidth={430}
              apiCallBack={apiCallBack}
              search={true}
              tableName={'invite-customer'}
              selectedRowData={handleSelectedRowData}
              removeRow={removePublisher}
              type={type}
              moveNextPage={moveNextPage}
            /> */}
          </div>
          <div className='col-6'>
            <div style={{ padding: '13px 0px' }}>
              <h3>
                {type === 'spo-publisher'
                  ? glossary.spo_invite_popup_selected_agencies
                  : glossary.spo_invite_popup_selected_publisher}{' '}
                ({selectedRowData.length}/10)
              </h3>
            </div>
            <div className='infy-table invite-customer' style={{ height: 'calc(100vh - 325px)' }}>
              <table className={'table'}>
                <thead className='table-header'>
                  <tr>
                    <th scope='col'>{glossary.spo_invite_table_column_remove}</th>
                    <th scope='col' className='th_name'>
                      {glossary.spo_invite_table_column_name}
                    </th>
                    {/* <th scope='col' className='th_domain'>
                        {glossary.spo_invite_table_column_domain}
                      </th> */}
                  </tr>
                </thead>
                <tbody>
                  {selectedRowData &&
                    selectedRowData?.map((item: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ width: '40px', maxWidth: '40px' }}
                            className='td_selection text-center'
                          >
                            <MdClose
                              key={item?.index}
                              className='svg-icon'
                              fontSize={19}
                              onClick={() => {
                                handleRemovePublisher(item.domain);
                              }}
                              title={'Remove'}
                            />
                          </td>
                          <td
                            style={{ width: '430px', maxWidth: '430px' }}
                            className='td_name text-truncate'
                          >
                            <div className='align-items-center d-flex'>
                              <Avatar
                                className='me-2'
                                src={item?.logo_url ?? 'U'}
                                size={'25'}
                                round
                              />

                              <a
                                href={`http://www.${item?.domain}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {item?.name ?? '-'}
                                <GoLinkExternal
                                  className='svg-icon ms-2'
                                  fontSize={15}
                                  title='Redirect URL'
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpoCustomerList;
