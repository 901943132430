import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form as RForm } from 'react-bootstrap';
import Button from '../widgets/button';
import store from '../../states';
import { Formik, Form } from 'formik';
import FormB from 'react-bootstrap/Form';
import * as Yup from 'yup';
import FormikController from '../comman/form/FormikController';
import Radio from '../widgets/radio';
import Dropdown from '../widgets/dropdown';
import { categories, cities, countries, states } from '../helpers/dropdowns';
import CustomDropdown from '../widgets/customDropdown';
import Input from '../widgets/input';
import { getRtbArtistListAPI, getRtbGenreListAPI, getRtbTitleListAPI } from '../../services/deals';
import { MdInfoOutline } from 'react-icons/md';
import ReactGA from 'react-ga4';
import {
  createDealAPI,
  editDealAPI,
  getCitiesAPI,
  getCountriesAPI,
  getStatesAPI,
} from '../../services/inventory';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const prodqOpt = [
  { label: 'Unknown', value: 0 },
  { label: 'Professionally Produced', value: 1 },
  { label: 'Prosumer', value: 2 },
  { label: 'User Generated (UGC)', value: 3 },
];

const contextOpt = [
  { label: 'Video', value: 1 },
  { label: 'Game', value: 2 },
  { label: 'Music', value: 3 },
  { label: 'Application', value: 4 },
  { label: 'Text', value: 5 },
  { label: 'Other', value: 6 },
  { label: 'Unknown', value: 7 },
];

const qagmediaratingOpt = [
  { label: 'All Audiences', value: 1 },
  { label: 'Everyone Over Age 12', value: 2 },
  { label: 'Mature Audiences', value: 3 },
];

export default ({
  show,
  onHide,
  dealTerms,
  edit,
  selectedInventories,
  dealTermsConfig,
  updateList,
  countriesList,
  genreList,
}: any) => {
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const { push } = useHistory();

  const DealInitialValues = {
    name: dealTerms?.name ?? '',
    cpm: dealTerms?.cpm ?? '',
    impressions: dealTermsConfig?.pg_impression ?? '',
    mincpm: dealTermsConfig?.min_cpm ?? '',
    maxcpm: dealTermsConfig?.max_cpm ?? '',
    description: dealTerms?.description ?? '',
    series: dealTermsConfig['content.series'] ?? '',
    isrcCode: dealTermsConfig['content.isrc'] ?? '',
    producerName: dealTermsConfig['content.producer.name'] ?? '',
    producerDomain: dealTermsConfig['content.producer.domain'] ?? '',
    contentRating: dealTermsConfig['content.contentrating'] ?? '',
    userRating: dealTermsConfig['content.userrating'] ?? '',
    keywords: dealTermsConfig['content.keywords'] ?? '',
    contentLength: dealTermsConfig['content.len'] ?? '',
    language: dealTermsConfig['content.language'] ?? '',
    networkName: dealTermsConfig['content.network.name'] ?? '',
    networkDomain: dealTermsConfig['content.network.domain'] ?? '',
    channelName: dealTermsConfig['content.channel.name'] ?? '',
    channelDomain: dealTermsConfig['content.channel.domain'] ?? '',
  };

  const [isLoading, setIsLoading] = useState(false);

  const [dealType, setDealType] = useState('PG');
  const [selectedIabCategories, setSelectedIabCategories] = useState([]);
  const [selectedDeviceTypes, sSelectedDeviceTypes] = useState([]);
  const [selectedPlatformTypes, sSelectedPlatformTypes] = useState([]);
  const [applicationType, sApplicationType] = useState([]);

  // const [countriesList, sCountriesList] = useState([]);
  const [selectedCountries, sSelectedCountries] = useState([]);
  const [statesList, sStatesList] = useState([]);
  const [selectedStates, sSelectedStates] = useState<any>([]);
  const [citiesList, sCitiesList] = useState([]);
  const [selectedCities, sSelectedCities] = useState<any>([]);

  const [artist, sArtist] = useState('');
  const [artistQuery, setArtistQuery] = useState('');
  const [artistList, setArtiseList] = useState([]);
  const [isArtistLoading, setIsArtistLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [titleQuery, setTitleQuery] = useState('');
  const [titleList, setTitleList] = useState([]);
  const [titleLoading, setTitleLoading] = useState(false);

  // const [genreList, setGenreList] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState<any>([]);

  const [prodq, sProdq] = useState<any>(0);
  const [context, sContext] = useState<any>(7);
  const [qagmediarating, sQagmediarating] = useState<any>(1);
  const [keywordsArray, sKeywordsArray] = useState([]);
  const [livestream, sLivestream] = useState<any>(0);
  const [sourceRelationship, sSourceRelationship] = useState<any>(0);
  const [embeddable, sEmbeddable] = useState<any>(0);

  const valReg = /^\d+(\.\d{1,2})?$/;
  const digitsReg = /^\d*$/;

  const PMPdealSchema = Yup.object().shape({
    name: Yup.string().required(glossary.ssp_createdeal_input_field_name_empty_error),
    mincpm: Yup.number()
      .required(glossary.ssp_createdeal_input_field_mincpm_empty_error)
      .test('superior', glossary.ssp_createdeal_input_field_mincpm_valid_error, function (f2: any) {
        return valReg.test(f2);
      })
      .typeError(glossary.ssp_createdeal_input_field_mincpm_valid_error)
      .max(99999, glossary.ssp_createdeal_input_field_mincpm_length_error),
    maxcpm: Yup.number()
      .required(glossary.ssp_createdeal_input_field_maxcpm_empty_error)
      .test('superior', glossary.ssp_createdeal_input_field_maxcpm_valid_error, function (f2: any) {
        return valReg.test(f2);
      })
      .typeError(glossary.ssp_createdeal_input_field_maxcpm_valid_error)
      .test(
        'superior',
        glossary.ssp_createdeal_input_field_min_max_compare_error,
        function (f2: any) {
          const ref2: any = Yup.ref('mincpm');
          return f2 >= this.resolve(ref2);
        }
      )
      .max(99999, glossary.ssp_createdeal_input_field_maxcpm_length_error),
  });

  const PGdealSchema = Yup.object().shape({
    name: Yup.string().required(glossary.ssp_createdeal_input_field_name_empty_error),
    cpm: Yup.number()
      .required(glossary.ssp_createdeal_input_field_cpm_empty_error)
      .test('superior', glossary.ssp_createdeal_input_field_cpm_valid_error, function (f2: any) {
        return valReg.test(f2);
      })
      .typeError(glossary.ssp_createdeal_input_field_cpm_valid_error)
      .max(99999, glossary.ssp_createdeal_input_field_cpm_length_error),
    impressions: Yup.number()
      .required(glossary.ssp_createdeal_input_field_imp_empty_error)
      .test('superior', glossary.ssp_createdeal_input_field_imp_valid_error, function (f2: any) {
        return digitsReg.test(f2);
      })
      .typeError(glossary.ssp_createdeal_input_field_imp_valid_error),
    //   .max(99999, 'Impressions field is allow max 5 digits.'),
  });

  const createDeal = (e: any) => {
    const payload = {
      name: e.name,
      description: e.description,
      inventories_ids: selectedInventories,
      ...(dealType === 'PG' && { cpm: e.cpm }),
      config: {
        ...(dealType === 'PG' && { pg_impression: e.impressions }),
        deal_type: dealType.toLowerCase(),
        'content.cat': selectedIabCategories?.map(({ value }: any) => value),
        'user.geo.country': selectedCountries,
        'user.geo.region': selectedStates,
        'user.geo.city': selectedCities,
        'device.type': selectedDeviceTypes,
        'platform.type': selectedPlatformTypes,
        'content.artist': artist,
        'content.series': e.series,
        'content.title': title,
        'content.genre': selectedGenre?.value ?? '',
        'content.isrc': e.isrcCode,
        'content.producer.name': e.producerName,
        'content.producer.domain': e.producerDomain,
        'content.prodq': prodq,
        'content.context': context,
        'content.contentrating': e.contentRating,
        'content.userrating': e.userRating,
        'content.qagmediarating': qagmediarating,
        'content.keywords': e.keywords,
        'content.kwarray': keywordsArray,
        'content.livestream': livestream,
        'content.sourcerelationship': sourceRelationship,
        'content.len': e.contentLength !== '' ? e.contentLength : null,
        'content.language': e.language !== '' ? e.language : null,
        'content.embeddable': embeddable,
        'content.network.domain': e.networkDomain,
        'content.network.name': e.networkName,
        'content.channel.domain': e.channelDomain,
        'content.channel.name': e.channelName,
        'application.type': applicationType,
        ...(dealType === 'PMP' && { min_cpm: e.mincpm, max_cpm: e.maxcpm }),
      },
    };
    setIsLoading(true);
    createDealAPI(payload)
      .then((data: any) => {
        if (data.status) {
          push('/inventory/deals');
          updateList && updateList(data, 'add');
          onHide();
          toast.success(data.message);
          ReactGA.event('admin_deal_create_response', {
            category: 'Admin',
            action: 'click',
            label: 'admin_deal_create_response',
          });
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        toast.error(glossary.server_error);
      });
  };

  const updateDeal = (e: any) => {
    const payload = {
      id: dealTerms.id,
      name: e.name,
      description: e.description,
      ...(dealType === 'PG' && { cpm: e.cpm }),
      config: {
        ...(dealType === 'PG' && { pg_impression: e.impressions }),
        deal_type: dealType.toLowerCase(),
        'content.cat': selectedIabCategories?.map(({ value }: any) => value),
        'user.geo.country': selectedCountries,
        'user.geo.region': selectedStates,
        'user.geo.city': selectedCities,
        'device.type': selectedDeviceTypes,
        'platform.type': selectedPlatformTypes,
        'content.artist': artist,
        'content.series': e.series,
        'content.title': title,
        'content.genre': selectedGenre?.value ?? '',
        'content.isrc': e.isrcCode,
        'content.producer.name': e.producerName,
        'content.producer.domain': e.producerDomain,
        'content.prodq': prodq,
        'content.context': context,
        'content.contentrating': e.contentRating,
        'content.userrating': e.userRating,
        'content.qagmediarating': qagmediarating,
        'content.keywords': e.keywords,
        'content.kwarray': keywordsArray,
        'content.livestream': livestream,
        'content.sourcerelationship': sourceRelationship,
        'content.len': e.contentLength !== '' ? e.contentLength : null,
        'content.language': e.language !== '' ? e.language : null,
        'content.embeddable': embeddable,
        'content.network.domain': e.networkDomain,
        'content.network.name': e.networkName,
        'content.channel.domain': e.channelDomain,
        'content.channel.name': e.channelName,
        'application.type': applicationType,
        ...(dealType === 'PMP' && { min_cpm: e.mincpm, max_cpm: e.maxcpm }),
      },
    };
    setIsLoading(true);
    editDealAPI(payload)
      .then((data: any) => {
        if (data.status) {
          toast.success(data.message);
          updateList && updateList(data.data, 'update');
          onHide();
          ReactGA.event('admin_deal_update_response', {
            category: 'api_response',
            action: 'api_response',
            label: 'admin_deal_update_response',
          });
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        // setIsLoading(false);
      });
  };

  // const getGenreList = () => {
  //   getRtbGenreListAPI()
  //     .then((data: any) => {
  //       if (data) {
  //         setGenreList(data.data);
  //       }
  //     })
  //     .catch((error: any) => {
  //       //ok
  //     });
  // };

  const handleCheckBoxes = (e: any) => {
    if (e.target.name.startsWith('deviceTypes')) {
      let newDeviceTypes: any = [...selectedDeviceTypes];
      if (e.target.name === 'deviceTypesTV') {
        if (e.target.checked) {
          newDeviceTypes.push('tv');
        } else {
          newDeviceTypes = newDeviceTypes.filter((x: string) => x !== 'tv');
        }
      }
      if (e.target.name === 'deviceTypesMobile') {
        if (e.target.checked) {
          newDeviceTypes.push('mobile');
        } else {
          newDeviceTypes = newDeviceTypes.filter((x: string) => x !== 'mobile');
        }
      }
      if (e.target.name === 'deviceTypesDesktop') {
        if (e.target.checked) {
          newDeviceTypes.push('desktop');
        } else {
          newDeviceTypes = newDeviceTypes.filter((x: string) => x !== 'desktop');
        }
      }
      sSelectedDeviceTypes(newDeviceTypes);
    }
    if (e.target.name.startsWith('platformTypes')) {
      let newPlatformTypes: any = [...selectedPlatformTypes];
      if (e.target.name === 'platformTypesOTT') {
        if (e.target.checked) {
          newPlatformTypes.push('ott');
        } else {
          newPlatformTypes = newPlatformTypes.filter((x: string) => x !== 'ott');
        }
      }
      if (e.target.name === 'platformTypesCTV') {
        if (e.target.checked) {
          newPlatformTypes.push('ctv');
        } else {
          newPlatformTypes = newPlatformTypes.filter((x: string) => x !== 'ctv');
        }
      }
      sSelectedPlatformTypes(newPlatformTypes);
    }
    if (e.target.name.startsWith('applicationType')) {
      let newApplicationType: any = [...applicationType];
      if (e.target.name === 'applicationTypeApp') {
        if (e.target.checked) {
          newApplicationType.push('App');
        } else {
          newApplicationType = newApplicationType.filter((x: string) => x !== 'App');
        }
      }
      if (e.target.name === 'applicationTypeSite') {
        if (e.target.checked) {
          newApplicationType.push('Site');
        } else {
          newApplicationType = newApplicationType.filter((x: string) => x !== 'Site');
        }
      }
      sApplicationType(newApplicationType);
    }
  };

  const LanguageFieldLabel = () => {
    return (
      <>
        {glossary.ssp_createdeal_input_field_lan_code_label}
        <a
          href='https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes'
          target='_blank'
          rel='noreferrer'
        >
          <MdInfoOutline
            fontSize={16}
            title='Valid language code details'
            className='ms-2 svg-icon'
          />
        </a>
      </>
    );
  };

  // const getCountriesList = () => {
  //   getCountriesAPI()
  //     .then((data: any) => {
  //       sCountriesList(data.data);
  //     })
  //     .catch(() => {
  //       //
  //     });
  // };

  // useEffect(() => {
  //   getGenreList();
  //   getCountriesList();
  // }, []);

  useEffect(() => {
    if (selectedCountries.length > 0) {
      const payload = {
        countries: selectedCountries.map(({ id }: any) => id),
      };
      getStatesAPI(payload)
        .then((data: any) => {
          sStatesList(data.data);
        })
        .catch(() => {
          //
        });
    } else {
      sStatesList([]);
      sSelectedStates([]);
    }
    const newArr: any[] = [];
    if (selectedCountries.length > 0) {
      selectedStates.forEach((e1: any) =>
        selectedCountries.forEach((e2: any) => {
          if (e1.country === e2.value) {
            newArr.push(e1);
          }
        })
      );
    }
    sSelectedStates(newArr);
  }, [selectedCountries]);

  useEffect(() => {
    if (selectedStates.length > 0) {
      const payload = {
        states: selectedStates.map(({ id }: any) => id),
      };
      getCitiesAPI(payload)
        .then((data: any) => {
          sCitiesList(data.data);
        })
        .catch(() => {
          //
        });
    } else {
      sCitiesList([]);
      sSelectedCities([]);
    }
    const newArr: any[] = [];
    if (selectedStates.length > 0) {
      selectedCities.forEach((e1: any) =>
        selectedStates.forEach((e2: any) => {
          if (e1.state === e2.value) {
            newArr.push(e1);
          }
        })
      );
    }
    sSelectedCities(newArr);
  }, [selectedStates]);

  useEffect(() => {
    if (dealTermsConfig) {
      sSelectedDeviceTypes(dealTermsConfig['device.type'] ?? []);
      sSelectedPlatformTypes(dealTermsConfig['platform.type'] ?? []);
      sApplicationType(dealTermsConfig['application.type'] ?? []);
      sSelectedCountries(dealTermsConfig['user.geo.country'] ?? []);
      sSelectedStates(dealTermsConfig['user.geo.region'] ?? []);
      sSelectedCities(dealTermsConfig['user.geo.city'] ?? []);
      setTitle(dealTermsConfig['content.title'] ?? '');
      sArtist(dealTermsConfig['content.artist'] ?? '');
      setSelectedGenre(
        dealTermsConfig['content.genre']
          ? { label: dealTermsConfig['content.genre'], value: dealTermsConfig['content.genre'] }
          : []
      );
      sProdq(dealTermsConfig['content.prodq'] ?? 0);
      sContext(dealTermsConfig['content.context'] ?? 7);
      sQagmediarating(dealTermsConfig['content.qagmediarating'] ?? 1);
      sKeywordsArray(dealTermsConfig['content.kwarray'] ?? []);
      sLivestream(dealTermsConfig['content.livestream'] ?? 0);
      sSourceRelationship(dealTermsConfig['content.sourcerelationship'] ?? 0);
      sEmbeddable(dealTermsConfig['content.embeddable'] ?? 0);
      setDealType(dealTermsConfig?.deal_type?.toUpperCase() ?? 'PG');
    }
  }, [dealTermsConfig]);

  useEffect(() => {
    if (dealTermsConfig['content.cat'] && dealTermsConfig['content.cat'].length > 0) {
      const newArr: any = [];
      categories.forEach((e1: any) => {
        dealTermsConfig['content.cat'].forEach((e2: any) => {
          if (e1.value === e2) {
            newArr.push(e1);
          }
        });
      });
      setSelectedIabCategories(newArr);
    }
  }, [dealTermsConfig['content.cat']]);

  useEffect(() => {
    if (artistQuery) {
      setIsArtistLoading(true);
      const getData: any = setTimeout(() => {
        getRtbArtistListAPI(artistQuery)
          .then((data: any) => {
            setIsArtistLoading(false);
            if (data.status) setArtiseList(data.data);
          })
          .catch((error: any) => {
            //ok
          });
      }, 300);

      return () => clearTimeout(getData);
    }
    if (artistQuery === '') setArtiseList([]);
  }, [artistQuery]);

  useEffect(() => {
    if (titleQuery) {
      const getData: any = setTimeout(() => {
        setTitleLoading(true);
        getRtbTitleListAPI(titleQuery)
          .then((data: any) => {
            if (data.status) setTitleList(data.data);
            setTitleLoading(false);
          })
          .catch((error: any) => {
            //ok
          });
      }, 300);

      return () => clearTimeout(getData);
    }
    if (titleQuery === '') setTitleList([]);
  }, [titleQuery]);

  return (
    <>
      <Modal
        onHide={() => {
          onHide();
        }}
        show={show}
        centered
        size={'sm'}
      >
        <div className='modal-header px-3'>
          <h2>{edit ? glossary.ssp_updatedeal_title : glossary.ssp_createdeal_title}</h2>
        </div>

        <Formik
          validationSchema={dealType === 'PMP' ? PMPdealSchema : PGdealSchema}
          initialValues={DealInitialValues}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={(e: any) => {
            if (edit) {
              updateDeal(e);
            } else {
              createDeal(e);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div
                className='modal-body p-3'
                style={{
                  maxHeight: 'calc(100vh - 210px)',
                  overflowY: 'auto',
                }}
              >
                <div className='row mb-3'>
                  <div className='col-4'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_name_label}
                        placeholder={glossary.ssp_createdeal_input_field_name_placeholder}
                        name='name'
                      />
                    </div>
                  </div>
                  <div className='col-auto sspdeal-radio'>
                    &nbsp;
                    <FormB.Group>
                      <Radio
                        label={glossary.ssp_createdeal_radio_pg}
                        size={'mini'}
                        id={'PG'}
                        selected={dealType}
                        click={(key: string) => setDealType(key)}
                      />
                      <Radio
                        label={glossary.ssp_createdeal_radio_pmp}
                        size={'mini'}
                        id={'PMP'}
                        selected={dealType}
                        click={(key: string) => setDealType(key)}
                      />
                    </FormB.Group>
                  </div>
                  {dealType === 'PMP' ? (
                    <>
                      <div className='col-3'>
                        <div className='form-group'>
                          <FormikController
                            kind='withiconstart'
                            control='input'
                            type='number'
                            label={glossary.ssp_createdeal_input_field_mincpm_label}
                            placeholder={glossary.ssp_createdeal_input_field_mincpm_placeholder}
                            name='mincpm'
                          />
                        </div>
                      </div>
                      <div className='col-3'>
                        <div className='form-group'>
                          <FormikController
                            kind='withiconstart'
                            control='input'
                            type='number'
                            label={glossary.ssp_createdeal_input_field_maxcpm_label}
                            placeholder={glossary.ssp_createdeal_input_field_maxcpm_placeholder}
                            name='maxcpm'
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='col-3'>
                        <div className='form-group'>
                          <FormikController
                            kind='withiconstart'
                            control='input'
                            type='number'
                            label={glossary.ssp_createdeal_input_field_cpm_label}
                            placeholder={glossary.ssp_createdeal_input_field_cpm_placeholder}
                            name='cpm'
                          />
                        </div>
                      </div>
                      <div className='col-3'>
                        <div className='form-group'>
                          <FormikController
                            kind='withouticon'
                            control='input'
                            type='number'
                            label={glossary.ssp_createdeal_input_field_imp_label}
                            placeholder={glossary.ssp_createdeal_input_field_imp_placeholder}
                            name='impressions'
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className='row mb-3'>
                  <FormikController
                    control='textArea'
                    label={glossary.ssp_createdeal_input_field_description_label}
                    placeholder={glossary.ssp_createdeal_input_field_description_placeholder}
                    name='description'
                  />
                </div>
                <div className='row mb-3'>
                  <div className='col-md-3'>
                    <Dropdown
                      kind='multi'
                      label={glossary.ssp_createdeal_ddl_field_iab_category_label}
                      option={categories}
                      value={selectedIabCategories}
                      onchange={setSelectedIabCategories}
                      search={true}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <label className='label'>
                      {glossary.ssp_createdeal_checkbox_field_device_type_label}
                    </label>
                    <RForm.Group style={{ display: 'flex', columnGap: 5, marginTop: 8 }}>
                      <RForm.Check
                        name={'deviceTypesTV'}
                        onChange={handleCheckBoxes}
                        className={'inventory'}
                        type='checkbox'
                        label={glossary.ssp_createdeal_checkbox_label_tv}
                        defaultChecked={selectedDeviceTypes?.find((x: any) => {
                          if (x === 'tv') {
                            return true;
                          } else {
                            return false;
                          }
                        })}
                      />
                      &nbsp;
                      <RForm.Check
                        name={'deviceTypesDesktop'}
                        onChange={handleCheckBoxes}
                        className={'inventory'}
                        type='checkbox'
                        label={glossary.ssp_createdeal_checkbox_label_desktop}
                        defaultChecked={selectedDeviceTypes?.find((x: any) => {
                          if (x === 'desktop') {
                            return true;
                          } else {
                            return false;
                          }
                        })}
                      />
                      &nbsp;
                      <RForm.Check
                        name={'deviceTypesMobile'}
                        onChange={handleCheckBoxes}
                        className={'inventory'}
                        type='checkbox'
                        label={glossary.ssp_createdeal_checkbox_label_mobile}
                        defaultChecked={selectedDeviceTypes?.find((x: any) => {
                          if (x === 'mobile') {
                            return true;
                          } else {
                            return false;
                          }
                        })}
                      />
                    </RForm.Group>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <label className='label'>
                      {glossary.ssp_createdeal_checkbox_field_platform_type_label}
                    </label>
                    <RForm.Group style={{ display: 'flex', columnGap: 5, marginTop: 8 }}>
                      <RForm.Check
                        name={'platformTypesOTT'}
                        onChange={handleCheckBoxes}
                        className={'inventory'}
                        type='checkbox'
                        label={glossary.ssp_createdeal_checkbox_label_ott}
                        defaultChecked={selectedPlatformTypes?.find((x: any) => {
                          if (x === 'ott') {
                            return true;
                          } else {
                            return false;
                          }
                        })}
                      />
                      &nbsp;
                      <RForm.Check
                        name={'platformTypesCTV'}
                        onChange={handleCheckBoxes}
                        className={'inventory'}
                        type='checkbox'
                        label={glossary.ssp_createdeal_checkbox_label_ctv}
                        defaultChecked={selectedPlatformTypes?.find((x: any) => {
                          if (x === 'ctv') {
                            return true;
                          } else {
                            return false;
                          }
                        })}
                      />
                    </RForm.Group>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <label className='label'>
                      {glossary.ssp_createdeal_checkbox_field_application_type_label}
                    </label>
                    <RForm.Group style={{ display: 'flex', columnGap: 5, marginTop: 8 }}>
                      <RForm.Check
                        name={'applicationTypeApp'}
                        onChange={handleCheckBoxes}
                        className={'inventory'}
                        type='checkbox'
                        label={glossary.ssp_createdeal_checkbox_label_app}
                        defaultChecked={applicationType?.find((x: any) => {
                          if (x === 'App') {
                            return true;
                          } else {
                            return false;
                          }
                        })}
                      />
                      &nbsp;
                      <RForm.Check
                        name={'applicationTypeSite'}
                        onChange={handleCheckBoxes}
                        className={'inventory'}
                        type='checkbox'
                        label={glossary.ssp_createdeal_checkbox_label_site}
                        defaultChecked={applicationType?.find((x: any) => {
                          if (x === 'Site') {
                            return true;
                          } else {
                            return false;
                          }
                        })}
                      />
                    </RForm.Group>
                  </div>
                  <div className='col-md-3 mb-2'>
                    {/* <CustomDropdown
                      label={glossary.ddl_countries}
                      option={countries(countriesList)}
                      value={selectedCountries}
                      onSelect={sSelectedCountries}
                      isMulti
                      placeholder={glossary.ddl_countries_placeholder}
                    /> */}
                    <Dropdown
                      kind='multi'
                      label={glossary.ddl_countries}
                      option={countries(countriesList)}
                      onchange={sSelectedCountries}
                      value={selectedCountries}
                      search={true}
                      selectAll={false}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    {/* <CustomDropdown
                      label={glossary.ddl_states}
                      option={states(statesList)}
                      value={selectedStates}
                      onSelect={sSelectedStates}
                      isMulti
                      placeholder={glossary.ddl_states_placeholder}
                    /> */}
                    <Dropdown
                      kind='multi'
                      label={glossary.ddl_states}
                      option={states(statesList)}
                      onchange={sSelectedStates}
                      value={selectedStates}
                      search={true}
                      selectAll={false}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    {/* <CustomDropdown
                      label={glossary.ddl_cities}
                      option={cities(citiesList)}
                      value={selectedCities}
                      onSelect={sSelectedCities}
                      isMulti
                      placeholder={glossary.ddl_cities_placeholder}
                    /> */}
                    <Dropdown
                      kind='multi'
                      label={glossary.ddl_cities}
                      option={cities(citiesList)}
                      onchange={sSelectedCities}
                      value={selectedCities}
                      search={true}
                      selectAll={false}
                    />
                  </div>
                  <div className='col-md-3 mb-2 suggestion-input'>
                    <Input
                      kind='suggestion'
                      type='text'
                      length={32}
                      name={'title'}
                      value={title}
                      option={titleList}
                      isLoading={titleLoading}
                      change={(e, v) => {
                        setTitle(v);
                        setTitleQuery(v);
                      }}
                      label={glossary.ssp_createdeal_input_field_title_label}
                      placeholder={glossary.ssp_createdeal_input_field_title_placeholder}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_series_label}
                        placeholder={glossary.ssp_createdeal_input_field_series_placeholder}
                        name='series'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2 suggestion-input'>
                    <Input
                      kind='suggestion'
                      type='text'
                      length={32}
                      name={'artist'}
                      value={artist}
                      option={artistList}
                      isLoading={isArtistLoading}
                      change={(e, v) => {
                        sArtist(v);
                        setArtistQuery(v);
                      }}
                      label={glossary.ssp_createdeal_input_field_artist_label}
                      placeholder={glossary.ssp_createdeal_input_field_artist_placeholder}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <Dropdown
                      label={glossary.ssp_createdeal_ddl_field_genre_label}
                      option={genreList}
                      value={selectedGenre ?? []}
                      onchange={setSelectedGenre}
                      search={true}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_isrc_code_label}
                        placeholder={glossary.ssp_createdeal_input_field_isrc_code_placeholder}
                        name='isrcCode'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_producer_label}
                        placeholder={glossary.ssp_createdeal_input_field_producer_placeholder}
                        name='producerName'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_producer_domain_label}
                        placeholder={
                          glossary.ssp_createdeal_input_field_producer_domain_placeholder
                        }
                        name='producerDomain'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <Dropdown
                      label={glossary.ssp_createdeal_ddl_field_product_qlt_label}
                      option={prodqOpt}
                      value={prodqOpt?.find((x: any) => {
                        if (x?.value === prodq) {
                          return { label: x.label, value: x.value };
                        }
                      })}
                      onchange={(e) => sProdq(e.value)}
                      search={false}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <Dropdown
                      label={glossary.ssp_createdeal_ddl_field_context_label}
                      option={contextOpt}
                      value={contextOpt?.find((x: any) => {
                        if (x?.value === context) {
                          return { label: x.label, value: x.value };
                        }
                      })}
                      onchange={(e) => sContext(e.value)}
                      search={false}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_content_rating_label}
                        placeholder={glossary.ssp_createdeal_input_field_content_rating_placeholder}
                        name='contentRating'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_user_rating_label}
                        placeholder={glossary.ssp_createdeal_input_field_user_rating_placeholder}
                        name='userRating'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <Dropdown
                      label={glossary.ssp_createdeal_ddl_field_media_rating_label}
                      option={qagmediaratingOpt}
                      value={qagmediaratingOpt.find((x: any) => {
                        if (qagmediarating === x.value) {
                          return { label: x.label, value: x.value };
                        }
                      })}
                      onchange={(e) => sQagmediarating(e?.value)}
                      search={false}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_keywords_label}
                        placeholder={glossary.ssp_createdeal_input_field_keywords_placeholder}
                        name='keywords'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <Input
                      length={32}
                      kind='text'
                      type={'text'}
                      name={'kwarray'}
                      value={keywordsArray?.join(',')}
                      change={(e: any, v: any) => {
                        const handleinput = v.split(',');
                        sKeywordsArray(handleinput);
                      }}
                      label={glossary.ssp_createdeal_input_field_keyword_array_label}
                      placeholder={glossary.ssp_createdeal_input_field_keyword_array_placeholder}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <Dropdown
                      label={glossary.ssp_createdeal_ddl_field_livestream_label}
                      option={[
                        { label: 'Not live', value: 0 },
                        { label: 'Content is live', value: 1 },
                      ]}
                      value={{
                        label: livestream === 0 ? 'Not live' : 'Content is live',
                        value: livestream,
                      }}
                      onchange={(e) => sLivestream(e.value)}
                      search={false}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <Dropdown
                      label={glossary.ssp_createdeal_ddl_field_source_label}
                      option={[
                        { label: 'Indirect', value: 0 },
                        { label: 'Direct', value: 1 },
                      ]}
                      value={{
                        label: sourceRelationship === 0 ? 'Indirect' : 'Direct',
                        value: sourceRelationship,
                      }}
                      onchange={(e) => sSourceRelationship(e.value)}
                      search={false}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withiconend'
                        icon={'Sec'}
                        control='input'
                        type='number'
                        label={glossary.ssp_createdeal_input_field_content_length_label}
                        placeholder={glossary.ssp_createdeal_input_field_content_length_placeholder}
                        name='contentLength'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={<LanguageFieldLabel />}
                        placeholder={glossary.ssp_createdeal_input_field_lan_code_placeholder}
                        name='language'
                        maxLength={3}
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <Dropdown
                      label={glossary.ssp_createdeal_ddl_field_embeddable_label}
                      option={[
                        { label: 'No', value: 0 },
                        { label: 'Yes', value: 1 },
                      ]}
                      value={{ label: embeddable === 0 ? 'No' : 'Yes', value: embeddable }}
                      onchange={(e) => sEmbeddable(e.value)}
                      search={false}
                    />
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_network_name_label}
                        placeholder={glossary.ssp_createdeal_input_field_network_name_placeholder}
                        name='networkName'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_network_domain_label}
                        placeholder={glossary.ssp_createdeal_input_field_network_domain_placeholder}
                        name='networkDomain'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_channel_name_label}
                        placeholder={glossary.ssp_createdeal_input_field_channel_name_placeholder}
                        name='channelName'
                      />
                    </div>
                  </div>
                  <div className='col-md-3 mb-2'>
                    <div className='form-group'>
                      <FormikController
                        kind='withouticon'
                        control='input'
                        type='text'
                        label={glossary.ssp_createdeal_input_field_channel_domain_label}
                        placeholder={glossary.ssp_createdeal_input_field_channel_domain_placeholder}
                        name='channelDomain'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer px-3'>
                <div className='d-flex gap-3 justify-content-end'>
                  <Button
                    kind={'secondary'}
                    text={glossary.ssp_createdeal_button_close}
                    type='button'
                    click={() => onHide()}
                  />
                  {isLoading ? (
                    <Button
                      kind={'spinner'}
                      text={
                        edit
                          ? glossary.ssp_updatedeal_button_update_loading
                          : glossary.ssp_createdeal_button_create_loading
                      }
                      type='button'
                    />
                  ) : (
                    <Button
                      kind={'primary'}
                      text={
                        edit
                          ? glossary.ssp_updatedeal_button_update
                          : glossary.ssp_createdeal_button_create
                      }
                      type='submit'
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
