export default [
  { label: 'platform', value: 'platform' },
  { label: 'Amazon', value: 'Amazon' },
  { label: 'Android', value: 'Android' },
  { label: 'AppleTV', value: 'AppleTV' },
  { label: 'Chrome OS', value: 'Chrome OS' },
  { label: 'Fire OS', value: 'Fire OS' },
  { label: 'LG proprietary', value: 'LG proprietary' },
  { label: 'Linux', value: 'Linux' },
  { label: 'Linux - TiVo', value: 'Linux - TiVo' },
  { label: 'OpenBSD', value: 'OpenBSD' },
  { label: 'Orsay OS', value: 'Orsay OS' },
  { label: 'PlayStation 4 OS', value: 'PlayStation 4 OS' },
  { label: 'PlayStation 5 OS', value: 'PlayStation 5 OS' },
  { label: 'PlayStation OS', value: 'PlayStation OS' },
  { label: 'Roku', value: 'Roku' },
  { label: 'Roku OS', value: 'Roku OS' },
  { label: 'Samsung', value: 'Samsung' },
  { label: 'Samsung proprietary', value: 'Samsung proprietary' },
  { label: 'SmartCast', value: 'SmartCast' },
  { label: 'SmartCast OS', value: 'SmartCast OS' },
  { label: 'Tizen', value: 'Tizen' },
  { label: 'Undefined', value: 'Undefined' },
  { label: 'Vidaa', value: 'Vidaa' },
  { label: 'WEBOS', value: 'WEBOS' },
  { label: 'Windows', value: 'Windows' },
  { label: 'X1', value: 'X1' },
  { label: 'android', value: 'android' },
  { label: 'chromecast', value: 'chromecast' },
  { label: 'iOS', value: 'iOS' },
  { label: 'linux', value: 'linux' },
  { label: 'mac os', value: 'mac os' },
  { label: 'other', value: 'other' },
  { label: 'roku', value: 'roku' },
  { label: 'roku os', value: 'roku os' },
  { label: 'tizen', value: 'tizen' },
  { label: 'tvOS', value: 'tvOS' },
  { label: 'webOS', value: 'webOS' },
  { label: 'webos', value: 'webos' },
  { label: 'Other', value: 'Other' },
];
