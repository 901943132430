export default [
  {
    name: 'United States',
    label: '+1',
    code: 'US',
    value: 1,
    icon: 'http://surl.li/jtfsj',
  },
  {
    name: 'India',
    label: '+91',
    code: 'IN',
    value: 91,
  },
  {
    name: 'Guernsey',
    label: '+44',
    code: 'GG',
    value: 44,
  },
  {
    name: 'Australia',
    label: '+61',
    code: 'AU',
    value: 61,
  },
  {
    name: 'Bahamas',
    label: '+1242',
    code: 'BS',
    value: 1242,
  },
  {
    name: 'Barbados',
    label: '+1246',
    code: 'BB',
    value: 1246,
  },
  {
    name: 'Anguilla',
    label: '+1264',
    code: 'AI',
    value: 1264,
  },
  {
    name: 'Antigua and Barbuda',
    label: '+1268',
    code: 'AG',
    value: 1268,
  },
  {
    name: 'Virgin Islands, British',
    label: '+1284',
    code: 'VG',
    value: 1284,
  },
  {
    name: 'Virgin Islands, U.S.',
    label: '+1340',
    code: 'VI',
    value: 1340,
  },
  {
    name: 'Bermuda',
    label: '+1441',
    code: 'BM',
    value: 1441,
  },
  {
    name: 'Grenada',
    label: '+1473',
    code: 'GD',
    value: 1473,
  },
  {
    name: 'Turks and Caicos Islands',
    label: '+1649',
    code: 'TC',
    value: 1649,
  },
  {
    name: 'Montserrat',
    label: '+1664',
    code: 'MS',
    value: 1664,
  },
  {
    name: 'Northern Mariana Islands',
    label: '+1670',
    code: 'MP',
    value: 1670,
  },
  {
    name: 'Guam',
    label: '+1671',
    code: 'GU',
    value: 1671,
  },
  {
    name: 'AmericanSamoa',
    label: '+1684',
    code: 'AS',
    value: 1684,
  },
  {
    name: 'Saint Lucia',
    label: '+1758',
    code: 'LC',
    value: 1758,
  },
  {
    name: 'Dominica',
    label: '+1767',
    code: 'DM',
    value: 1767,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    label: '+1784',
    code: 'VC',
    value: 1784,
  },
  {
    name: 'Dominican Republic',
    label: '+1849',
    code: 'DO',
    value: 1849,
  },
  {
    name: 'Trinidad and Tobago',
    label: '+1868',
    code: 'TT',
    value: 1868,
  },
  {
    name: 'Saint Kitts and Nevis',
    label: '+1869',
    code: 'KN',
    value: 1869,
  },
  {
    name: 'Jamaica',
    label: '+1876',
    code: 'JM',
    value: 1876,
  },
  {
    name: 'Puerto Rico',
    label: '+1939',
    code: 'PR',
    value: 1939,
  },
  {
    name: 'Egypt',
    label: '+20',
    code: 'EG',
    value: 20,
  },
  {
    name: 'South Sudan',
    label: '+211',
    code: 'SS',
    value: 211,
  },
  {
    name: 'Morocco',
    label: '+212',
    code: 'MA',
    value: 212,
  },
  {
    name: 'Algeria',
    label: '+213',
    code: 'DZ',
    value: 213,
  },
  {
    name: 'Tunisia',
    label: '+216',
    code: 'TN',
    value: 216,
  },
  {
    name: 'Libyan Arab Jamahiriya',
    label: '+218',
    code: 'LY',
    value: 218,
  },
  {
    name: 'Gambia',
    label: '+220',
    code: 'GM',
    value: 220,
  },
  {
    name: 'Senegal',
    label: '+221',
    code: 'SN',
    value: 221,
  },
  {
    name: 'Mauritania',
    label: '+222',
    code: 'MR',
    value: 222,
  },
  {
    name: 'Mali',
    label: '+223',
    code: 'ML',
    value: 223,
  },
  {
    name: 'Guinea',
    label: '+224',
    code: 'GN',
    value: 224,
  },
  {
    name: "Cote d'Ivoire",
    label: '+225',
    code: 'CI',
    value: 225,
  },
  {
    name: 'Burkina Faso',
    label: '+226',
    code: 'BF',
    value: 226,
  },
  {
    name: 'Niger',
    label: '+227',
    code: 'NE',
    value: 227,
  },
  {
    name: 'Togo',
    label: '+228',
    code: 'TG',
    value: 228,
  },
  {
    name: 'Benin',
    label: '+229',
    code: 'BJ',
    value: 229,
  },
  {
    name: 'Mauritius',
    label: '+230',
    code: 'MU',
    value: 230,
  },
  {
    name: 'Liberia',
    label: '+231',
    code: 'LR',
    value: 231,
  },
  {
    name: 'Sierra Leone',
    label: '+232',
    code: 'SL',
    value: 232,
  },
  {
    name: 'Ghana',
    label: '+233',
    code: 'GH',
    value: 233,
  },
  {
    name: 'Nigeria',
    label: '+234',
    code: 'NG',
    value: 234,
  },
  {
    name: 'Chad',
    label: '+235',
    code: 'TD',
    value: 235,
  },
  {
    name: 'Central African Republic',
    label: '+236',
    code: 'CF',
    value: 236,
  },
  {
    name: 'Cameroon',
    label: '+237',
    code: 'CM',
    value: 237,
  },
  {
    name: 'Cape Verde',
    label: '+238',
    code: 'CV',
    value: 238,
  },
  {
    name: 'Sao Tome and Principe',
    label: '+239',
    code: 'ST',
    value: 239,
  },
  {
    name: 'Equatorial Guinea',
    label: '+240',
    code: 'GQ',
    value: 240,
  },
  {
    name: 'Gabon',
    label: '+241',
    code: 'GA',
    value: 241,
  },
  {
    name: 'Congo',
    label: '+242',
    code: 'CG',
    value: 242,
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    label: '+243',
    code: 'CD',
    value: 243,
  },
  {
    name: 'Angola',
    label: '+244',
    code: 'AO',
    value: 244,
  },
  {
    name: 'Guinea-Bissau',
    label: '+245',
    code: 'GW',
    value: 245,
  },
  {
    name: 'British Indian Ocean Territory',
    label: '+246',
    code: 'IO',
    value: 246,
  },
  {
    name: 'Seychelles',
    label: '+248',
    code: 'SC',
    value: 248,
  },
  {
    name: 'Sudan',
    label: '+249',
    code: 'SD',
    value: 249,
  },
  {
    name: 'Rwanda',
    label: '+250',
    code: 'RW',
    value: 250,
  },
  {
    name: 'Ethiopia',
    label: '+251',
    code: 'ET',
    value: 251,
  },
  {
    name: 'Somalia',
    label: '+252',
    code: 'SO',
    value: 252,
  },
  {
    name: 'Djibouti',
    label: '+253',
    code: 'DJ',
    value: 253,
  },
  {
    name: 'Kenya',
    label: '+254',
    code: 'KE',
    value: 254,
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    label: '+255',
    code: 'TZ',
    value: 255,
  },
  {
    name: 'Uganda',
    label: '+256',
    code: 'UG',
    value: 256,
  },
  {
    name: 'Burundi',
    label: '+257',
    code: 'BI',
    value: 257,
  },
  {
    name: 'Mozambique',
    label: '+258',
    code: 'MZ',
    value: 258,
  },
  {
    name: 'Zambia',
    label: '+260',
    code: 'ZM',
    value: 260,
  },
  {
    name: 'Madagascar',
    label: '+261',
    code: 'MG',
    value: 261,
  },
  {
    name: 'Mayotte',
    label: '+262',
    code: 'YT',
    value: 262,
  },
  {
    name: 'Zimbabwe',
    label: '+263',
    code: 'ZW',
    value: 263,
  },
  {
    name: 'Namibia',
    label: '+264',
    code: 'NA',
    value: 264,
  },
  {
    name: 'Malawi',
    label: '+265',
    code: 'MW',
    value: 265,
  },
  {
    name: 'Lesotho',
    label: '+266',
    code: 'LS',
    value: 266,
  },
  {
    name: 'Botswana',
    label: '+267',
    code: 'BW',
    value: 267,
  },
  {
    name: 'Swaziland',
    label: '+268',
    code: 'SZ',
    value: 268,
  },
  {
    name: 'Comoros',
    label: '+269',
    code: 'KM',
    value: 269,
  },
  {
    name: 'South Africa',
    label: '+27',
    code: 'ZA',
    value: 27,
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    label: '+290',
    code: 'SH',
    value: 290,
  },
  {
    name: 'Eritrea',
    label: '+291',
    code: 'ER',
    value: 291,
  },
  {
    name: 'Aruba',
    label: '+297',
    code: 'AW',
    value: 297,
  },
  {
    name: 'Faroe Islands',
    label: '+298',
    code: 'FO',
    value: 298,
  },
  {
    name: 'Greenland',
    label: '+299',
    code: 'GL',
    value: 299,
  },
  {
    name: 'Greece',
    label: '+30',
    code: 'GR',
    value: 30,
  },
  {
    name: 'Netherlands',
    label: '+31',
    code: 'NL',
    value: 31,
  },
  {
    name: 'Belgium',
    label: '+32',
    code: 'BE',
    value: 32,
  },
  {
    name: 'France',
    label: '+33',
    code: 'FR',
    value: 33,
  },
  {
    name: 'Spain',
    label: '+34',
    code: 'ES',
    value: 34,
  },
  {
    name: 'Cayman Islands',
    label: '+345',
    code: 'KY',
    value: 345,
  },
  {
    name: 'Gibraltar',
    label: '+350',
    code: 'GI',
    value: 350,
  },
  {
    name: 'Portugal',
    label: '+351',
    code: 'PT',
    value: 351,
  },
  {
    name: 'Luxembourg',
    label: '+352',
    code: 'LU',
    value: 352,
  },
  {
    name: 'Ireland',
    label: '+353',
    code: 'IE',
    value: 353,
  },
  {
    name: 'Iceland',
    label: '+354',
    code: 'IS',
    value: 354,
  },
  {
    name: 'Albania',
    label: '+355',
    code: 'AL',
    value: 355,
  },
  {
    name: 'Malta',
    label: '+356',
    code: 'MT',
    value: 356,
  },
  {
    name: 'Cyprus',
    label: '+357',
    code: 'CY',
    value: 357,
  },
  {
    name: 'Aland Islands',
    label: '+358',
    code: 'AX',
    value: 358,
  },
  {
    name: 'Bulgaria',
    label: '+359',
    code: 'BG',
    value: 359,
  },
  {
    name: 'Hungary',
    label: '+36',
    code: 'HU',
    value: 36,
  },
  {
    name: 'Lithuania',
    label: '+370',
    code: 'LT',
    value: 370,
  },
  {
    name: 'Latvia',
    label: '+371',
    code: 'LV',
    value: 371,
  },
  {
    name: 'Estonia',
    label: '+372',
    code: 'EE',
    value: 372,
  },
  {
    name: 'Moldova',
    label: '+373',
    code: 'MD',
    value: 373,
  },
  {
    name: 'Armenia',
    label: '+374',
    code: 'AM',
    value: 374,
  },
  {
    name: 'Belarus',
    label: '+375',
    code: 'BY',
    value: 375,
  },
  {
    name: 'Andorra',
    label: '+376',
    code: 'AD',
    value: 376,
  },
  {
    name: 'Monaco',
    label: '+377',
    code: 'MC',
    value: 377,
  },
  {
    name: 'San Marino',
    label: '+378',
    code: 'SM',
    value: 378,
  },
  {
    name: 'Holy See (Vatican City State)',
    label: '+379',
    code: 'VA',
    value: 379,
  },
  {
    name: 'Ukraine',
    label: '+380',
    code: 'UA',
    value: 380,
  },
  {
    name: 'Serbia',
    label: '+381',
    code: 'RS',
    value: 381,
  },
  {
    name: 'Montenegro',
    label: '+382',
    code: 'ME',
    value: 382,
  },
  {
    name: 'Croatia',
    label: '+385',
    code: 'HR',
    value: 385,
  },
  {
    name: 'Slovenia',
    label: '+386',
    code: 'SI',
    value: 386,
  },
  {
    name: 'Bosnia and Herzegovina',
    label: '+387',
    code: 'BA',
    value: 387,
  },
  {
    name: 'Macedonia',
    label: '+389',
    code: 'MK',
    value: 389,
  },
  {
    name: 'Italy',
    label: '+39',
    code: 'IT',
    value: 39,
  },
  {
    name: 'Romania',
    label: '+40',
    code: 'RO',
    value: 40,
  },
  {
    name: 'Switzerland',
    label: '+41',
    code: 'CH',
    value: 41,
  },
  {
    name: 'Czech Republic',
    label: '+420',
    code: 'CZ',
    value: 420,
  },
  {
    name: 'Slovakia',
    label: '+421',
    code: 'SK',
    value: 421,
  },
  {
    name: 'Liechtenstein',
    label: '+423',
    code: 'LI',
    value: 423,
  },
  {
    name: 'Austria',
    label: '+43',
    code: 'AT',
    value: 43,
  },

  {
    name: 'Denmark',
    label: '+45',
    code: 'DK',
    value: 45,
  },
  {
    name: 'Sweden',
    label: '+46',
    code: 'SE',
    value: 46,
  },
  {
    name: 'Norway',
    label: '+47',
    code: 'NO',
    value: 47,
  },
  {
    name: 'Poland',
    label: '+48',
    code: 'PL',
    value: 48,
  },
  {
    name: 'Germany',
    label: '+49',
    code: 'DE',
    value: 49,
  },
  {
    name: 'Falkland Islands (Malvinas)',
    label: '+500',
    code: 'FK',
    value: 500,
  },
  {
    name: 'Belize',
    label: '+501',
    code: 'BZ',
    value: 501,
  },
  {
    name: 'Guatemala',
    label: '+502',
    code: 'GT',
    value: 502,
  },
  {
    name: 'El Salvador',
    label: '+503',
    code: 'SV',
    value: 503,
  },
  {
    name: 'Honduras',
    label: '+504',
    code: 'HN',
    value: 504,
  },
  {
    name: 'Nicaragua',
    label: '+505',
    code: 'NI',
    value: 505,
  },
  {
    name: 'Costa Rica',
    label: '+506',
    code: 'CR',
    value: 506,
  },
  {
    name: 'Panama',
    label: '+507',
    code: 'PA',
    value: 507,
  },
  {
    name: 'Saint Pierre and Miquelon',
    label: '+508',
    code: 'PM',
    value: 508,
  },
  {
    name: 'Haiti',
    label: '+509',
    code: 'HT',
    value: 509,
  },
  {
    name: 'Peru',
    label: '+51',
    code: 'PE',
    value: 51,
  },
  {
    name: 'Mexico',
    label: '+52',
    code: 'MX',
    value: 52,
  },
  {
    name: 'Cuba',
    label: '+53',
    code: 'CU',
    value: 53,
  },
  {
    name: 'Argentina',
    label: '+54',
    code: 'AR',
    value: 54,
  },
  {
    name: 'Brazil',
    label: '+55',
    code: 'BR',
    value: 55,
  },
  {
    name: 'Chile',
    label: '+56',
    code: 'CL',
    value: 56,
  },
  {
    name: 'Colombia',
    label: '+57',
    code: 'CO',
    value: 57,
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    label: '+58',
    code: 'VE',
    value: 58,
  },
  {
    name: 'Guadeloupe',
    label: '+590',
    code: 'GP',
    value: 590,
  },
  {
    name: 'Bolivia, Plurinational State of',
    label: '+591',
    code: 'BO',
    value: 591,
  },
  {
    name: 'Ecuador',
    label: '+593',
    code: 'EC',
    value: 593,
  },
  {
    name: 'French Guiana',
    label: '+594',
    code: 'GF',
    value: 594,
  },
  {
    name: 'Guyana',
    label: '+595',
    code: 'GY',
    value: 595,
  },
  {
    name: 'Martinique',
    label: '+596',
    code: 'MQ',
    value: 596,
  },
  {
    name: 'Suriname',
    label: '+597',
    code: 'SR',
    value: 597,
  },
  {
    name: 'Uruguay',
    label: '+598',
    code: 'UY',
    value: 598,
  },
  {
    name: 'Netherlands Antilles',
    label: '+599',
    code: 'AN',
    value: 599,
  },
  {
    name: 'Malaysia',
    label: '+60',
    code: 'MY',
    value: 60,
  },
  {
    name: 'Indonesia',
    label: '+62',
    code: 'ID',
    value: 62,
  },
  {
    name: 'Philippines',
    label: '+63',
    code: 'PH',
    value: 63,
  },
  {
    name: 'New Zealand',
    label: '+64',
    code: 'NZ',
    value: 64,
  },
  {
    name: 'Singapore',
    label: '+65',
    code: 'SG',
    value: 65,
  },
  {
    name: 'Thailand',
    label: '+66',
    code: 'TH',
    value: 66,
  },
  {
    name: 'Timor-Leste',
    label: '+670',
    code: 'TL',
    value: 670,
  },
  {
    name: 'Antarctica',
    label: '+672',
    code: 'AQ',
    value: 672,
  },
  {
    name: 'Brunei Darussalam',
    label: '+673',
    code: 'BN',
    value: 673,
  },
  {
    name: 'Nauru',
    label: '+674',
    code: 'NR',
    value: 674,
  },
  {
    name: 'Papua New Guinea',
    label: '+675',
    code: 'PG',
    value: 675,
  },
  {
    name: 'Tonga',
    label: '+676',
    code: 'TO',
    value: 676,
  },
  {
    name: 'Solomon Islands',
    label: '+677',
    code: 'SB',
    value: 677,
  },
  {
    name: 'Vanuatu',
    label: '+678',
    code: 'VU',
    value: 678,
  },
  {
    name: 'Fiji',
    label: '+679',
    code: 'FJ',
    value: 679,
  },
  {
    name: 'Palau',
    label: '+680',
    code: 'PW',
    value: 680,
  },
  {
    name: 'Wallis and Futuna',
    label: '+681',
    code: 'WF',
    value: 681,
  },
  {
    name: 'Cook Islands',
    label: '+682',
    code: 'CK',
    value: 682,
  },
  {
    name: 'Niue',
    label: '+683',
    code: 'NU',
    value: 683,
  },
  {
    name: 'Samoa',
    label: '+685',
    code: 'WS',
    value: 685,
  },
  {
    name: 'Kiribati',
    label: '+686',
    code: 'KI',
    value: 686,
  },
  {
    name: 'New Caledonia',
    label: '+687',
    code: 'NC',
    value: 687,
  },
  {
    name: 'Tuvalu',
    label: '+688',
    code: 'TV',
    value: 688,
  },
  {
    name: 'French Polynesia',
    label: '+689',
    code: 'PF',
    value: 689,
  },
  {
    name: 'Tokelau',
    label: '+690',
    code: 'TK',
    value: 690,
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    label: '+691',
    code: 'FM',
    value: 691,
  },
  {
    name: 'Marshall Islands',
    label: '+692',
    code: 'MH',
    value: 692,
  },
  {
    name: 'Russia',
    label: '+7',
    code: 'RU',
    value: 7,
  },
  {
    name: 'Kazakhstan',
    label: '+77',
    code: 'KZ',
    value: 77,
  },
  {
    name: 'Japan',
    label: '+81',
    code: 'JP',
    value: 81,
  },
  {
    name: 'Korea, Republic of South Korea',
    label: '+82',
    code: 'KR',
    value: 82,
  },
  {
    name: 'Vietnam',
    label: '+84',
    code: 'VN',
    value: 84,
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    label: '+850',
    code: 'KP',
    value: 850,
  },
  {
    name: 'Hong Kong',
    label: '+852',
    code: 'HK',
    value: 852,
  },
  {
    name: 'Macao',
    label: '+853',
    code: 'MO',
    value: 853,
  },
  {
    name: 'Cambodia',
    label: '+855',
    code: 'KH',
    value: 855,
  },
  {
    name: 'Laos',
    label: '+856',
    code: 'LA',
    value: 856,
  },
  {
    name: 'China',
    label: '+86',
    code: 'CN',
    value: 86,
  },
  {
    name: 'Pitcairn',
    label: '+872',
    code: 'PN',
    value: 872,
  },
  {
    name: 'Bangladesh',
    label: '+880',
    code: 'BD',
    value: 880,
  },
  {
    name: 'Taiwan',
    label: '+886',
    code: 'TW',
    value: 886,
  },
  {
    name: 'Turkey',
    label: '+90',
    code: 'TR',
    value: 90,
  },
  {
    name: 'Pakistan',
    label: '+92',
    code: 'PK',
    value: 92,
  },
  {
    name: 'Afghanistan',
    label: '+93',
    code: 'AF',
    value: 93,
  },
  {
    name: 'Sri Lanka',
    label: '+94',
    code: 'LK',
    value: 94,
  },
  {
    name: 'Myanmar',
    label: '+95',
    code: 'MM',
    value: 95,
  },
  {
    name: 'Maldives',
    label: '+960',
    code: 'MV',
    value: 960,
  },
  {
    name: 'Lebanon',
    label: '+961',
    code: 'LB',
    value: 961,
  },
  {
    name: 'Jordan',
    label: '+962',
    code: 'JO',
    value: 962,
  },
  {
    name: 'Syrian Arab Republic',
    label: '+963',
    code: 'SY',
    value: 963,
  },
  {
    name: 'Iraq',
    label: '+964',
    code: 'IQ',
    value: 964,
  },
  {
    name: 'Kuwait',
    label: '+965',
    code: 'KW',
    value: 965,
  },
  {
    name: 'Saudi Arabia',
    label: '+966',
    code: 'SA',
    value: 966,
  },
  {
    name: 'Yemen',
    label: '+967',
    code: 'YE',
    value: 967,
  },
  {
    name: 'Oman',
    label: '+968',
    code: 'OM',
    value: 968,
  },
  {
    name: 'Palestinian Territory, Occupied',
    label: '+970',
    code: 'PS',
    value: 970,
  },
  {
    name: 'United Arab Emirates',
    label: '+971',
    code: 'AE',
    value: 971,
  },
  {
    name: 'Israel',
    label: '+972',
    code: 'IL',
    value: 972,
  },
  {
    name: 'Bahrain',
    label: '+973',
    code: 'BH',
    value: 973,
  },
  {
    name: 'Qatar',
    label: '+974',
    code: 'QA',
    value: 974,
  },
  {
    name: 'Bhutan',
    label: '+975',
    code: 'BT',
    value: 975,
  },
  {
    name: 'Mongolia',
    label: '+976',
    code: 'MN',
    value: 976,
  },
  {
    name: 'Nepal',
    label: '+977',
    code: 'NP',
    value: 977,
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    label: '+98',
    code: 'IR',
    value: 98,
  },
  {
    name: 'Tajikistan',
    label: '+992',
    code: 'TJ',
    value: 992,
  },
  {
    name: 'Turkmenistan',
    label: '+993',
    code: 'TM',
    value: 993,
  },
  {
    name: 'Azerbaijan',
    label: '+994',
    code: 'AZ',
    value: 994,
  },
  {
    name: 'Georgia',
    label: '+995',
    code: 'GE',
    value: 995,
  },
  {
    name: 'Kyrgyzstan',
    label: '+996',
    code: 'KG',
    value: 996,
  },
  {
    name: 'Uzbekistan',
    label: '+998',
    code: 'UZ',
    value: 998,
  },
];

// [
//   {
//     name: 'Afghanistan',
//     label: '+93',
//     code: 'AF',
//   },
//   {
//     name: 'Aland Islands',
//     label: '+358',
//     code: 'AX',
//   },
//   {
//     name: 'Albania',
//     label: '+355',
//     code: 'AL',
//   },
//   {
//     name: 'Algeria',
//     label: '+213',
//     code: 'DZ',
//   },
//   {
//     name: 'AmericanSamoa',
//     label: '+1684',
//     code: 'AS',
//   },
//   {
//     name: 'Andorra',
//     label: '+376',
//     code: 'AD',
//   },
//   {
//     name: 'Angola',
//     label: '+244',
//     code: 'AO',
//   },
//   {
//     name: 'Anguilla',
//     label: '+1264',
//     code: 'AI',
//   },
//   {
//     name: 'Antarctica',
//     label: '+672',
//     code: 'AQ',
//   },
//   {
//     name: 'Antigua and Barbuda',
//     label: '+1268',
//     code: 'AG',
//   },
//   {
//     name: 'Argentina',
//     label: '+54',
//     code: 'AR',
//   },
//   {
//     name: 'Armenia',
//     label: '+374',
//     code: 'AM',
//   },
//   {
//     name: 'Aruba',
//     label: '+297',
//     code: 'AW',
//   },
//   {
//     name: 'Australia',
//     label: '+61',
//     code: 'AU',
//   },
//   {
//     name: 'Austria',
//     label: '+43',
//     code: 'AT',
//   },
//   {
//     name: 'Azerbaijan',
//     label: '+994',
//     code: 'AZ',
//   },
//   {
//     name: 'Bahamas',
//     label: '+1242',
//     code: 'BS',
//   },
//   {
//     name: 'Bahrain',
//     label: '+973',
//     code: 'BH',
//   },
//   {
//     name: 'Bangladesh',
//     label: '+880',
//     code: 'BD',
//   },
//   {
//     name: 'Barbados',
//     label: '+1246',
//     code: 'BB',
//   },
//   {
//     name: 'Belarus',
//     label: '+375',
//     code: 'BY',
//   },
//   {
//     name: 'Belgium',
//     label: '+32',
//     code: 'BE',
//   },
//   {
//     name: 'Belize',
//     label: '+501',
//     code: 'BZ',
//   },
//   {
//     name: 'Benin',
//     label: '+229',
//     code: 'BJ',
//   },
//   {
//     name: 'Bermuda',
//     label: '+1441',
//     code: 'BM',
//   },
//   {
//     name: 'Bhutan',
//     label: '+975',
//     code: 'BT',
//   },
//   {
//     name: 'Bolivia, Plurinational State of',
//     label: '+591',
//     code: 'BO',
//   },
//   {
//     name: 'Bosnia and Herzegovina',
//     label: '+387',
//     code: 'BA',
//   },
//   {
//     name: 'Botswana',
//     label: '+267',
//     code: 'BW',
//   },
//   {
//     name: 'Brazil',
//     label: '+55',
//     code: 'BR',
//   },
//   {
//     name: 'British Indian Ocean Territory',
//     label: '+246',
//     code: 'IO',
//   },
//   {
//     name: 'Brunei Darussalam',
//     label: '+673',
//     code: 'BN',
//   },
//   {
//     name: 'Bulgaria',
//     label: '+359',
//     code: 'BG',
//   },
//   {
//     name: 'Burkina Faso',
//     label: '+226',
//     code: 'BF',
//   },
//   {
//     name: 'Burundi',
//     label: '+257',
//     code: 'BI',
//   },
//   {
//     name: 'Cambodia',
//     label: '+855',
//     code: 'KH',
//   },
//   {
//     name: 'Cameroon',
//     label: '+237',
//     code: 'CM',
//   },
//   {
//     name: 'Canada',
//     label: '+1',
//     code: 'CA',
//   },
//   {
//     name: 'Cape Verde',
//     label: '+238',
//     code: 'CV',
//   },
//   {
//     name: 'Cayman Islands',
//     label: '+ 345',
//     code: 'KY',
//   },
//   {
//     name: 'Central African Republic',
//     label: '+236',
//     code: 'CF',
//   },
//   {
//     name: 'Chad',
//     label: '+235',
//     code: 'TD',
//   },
//   {
//     name: 'Chile',
//     label: '+56',
//     code: 'CL',
//   },
//   {
//     name: 'China',
//     label: '+86',
//     code: 'CN',
//   },
//   {
//     name: 'Christmas Island',
//     label: '+61',
//     code: 'CX',
//   },
//   {
//     name: 'Cocos (Keeling) Islands',
//     label: '+61',
//     code: 'CC',
//   },
//   {
//     name: 'Colombia',
//     label: '+57',
//     code: 'CO',
//   },
//   {
//     name: 'Comoros',
//     label: '+269',
//     code: 'KM',
//   },
//   {
//     name: 'Congo',
//     label: '+242',
//     code: 'CG',
//   },
//   {
//     name: 'Congo, The Democratic Republic of the Congo',
//     label: '+243',
//     code: 'CD',
//   },
//   {
//     name: 'Cook Islands',
//     label: '+682',
//     code: 'CK',
//   },
//   {
//     name: 'Costa Rica',
//     label: '+506',
//     code: 'CR',
//   },
//   {
//     name: "Cote d'Ivoire",
//     label: '+225',
//     code: 'CI',
//   },
//   {
//     name: 'Croatia',
//     label: '+385',
//     code: 'HR',
//   },
//   {
//     name: 'Cuba',
//     label: '+53',
//     code: 'CU',
//   },
//   {
//     name: 'Cyprus',
//     label: '+357',
//     code: 'CY',
//   },
//   {
//     name: 'Czech Republic',
//     label: '+420',
//     code: 'CZ',
//   },
//   {
//     name: 'Denmark',
//     label: '+45',
//     code: 'DK',
//   },
//   {
//     name: 'Djibouti',
//     label: '+253',
//     code: 'DJ',
//   },
//   {
//     name: 'Dominica',
//     label: '+1767',
//     code: 'DM',
//   },
//   {
//     name: 'Dominican Republic',
//     label: '+1849',
//     code: 'DO',
//   },
//   {
//     name: 'Ecuador',
//     label: '+593',
//     code: 'EC',
//   },
//   {
//     name: 'Egypt',
//     label: '+20',
//     code: 'EG',
//   },
//   {
//     name: 'El Salvador',
//     label: '+503',
//     code: 'SV',
//   },
//   {
//     name: 'Equatorial Guinea',
//     label: '+240',
//     code: 'GQ',
//   },
//   {
//     name: 'Eritrea',
//     label: '+291',
//     code: 'ER',
//   },
//   {
//     name: 'Estonia',
//     label: '+372',
//     code: 'EE',
//   },
//   {
//     name: 'Ethiopia',
//     label: '+251',
//     code: 'ET',
//   },
//   {
//     name: 'Falkland Islands (Malvinas)',
//     label: '+500',
//     code: 'FK',
//   },
//   {
//     name: 'Faroe Islands',
//     label: '+298',
//     code: 'FO',
//   },
//   {
//     name: 'Fiji',
//     label: '+679',
//     code: 'FJ',
//   },
//   {
//     name: 'Finland',
//     label: '+358',
//     code: 'FI',
//   },
//   {
//     name: 'France',
//     label: '+33',
//     code: 'FR',
//   },
//   {
//     name: 'French Guiana',
//     label: '+594',
//     code: 'GF',
//   },
//   {
//     name: 'French Polynesia',
//     label: '+689',
//     code: 'PF',
//   },
//   {
//     name: 'Gabon',
//     label: '+241',
//     code: 'GA',
//   },
//   {
//     name: 'Gambia',
//     label: '+220',
//     code: 'GM',
//   },
//   {
//     name: 'Georgia',
//     label: '+995',
//     code: 'GE',
//   },
//   {
//     name: 'Germany',
//     label: '+49',
//     code: 'DE',
//   },
//   {
//     name: 'Ghana',
//     label: '+233',
//     code: 'GH',
//   },
//   {
//     name: 'Gibraltar',
//     label: '+350',
//     code: 'GI',
//   },
//   {
//     name: 'Greece',
//     label: '+30',
//     code: 'GR',
//   },
//   {
//     name: 'Greenland',
//     label: '+299',
//     code: 'GL',
//   },
//   {
//     name: 'Grenada',
//     label: '+1473',
//     code: 'GD',
//   },
//   {
//     name: 'Guadeloupe',
//     label: '+590',
//     code: 'GP',
//   },
//   {
//     name: 'Guam',
//     label: '+1671',
//     code: 'GU',
//   },
//   {
//     name: 'Guatemala',
//     label: '+502',
//     code: 'GT',
//   },
//   {
//     name: 'Guernsey',
//     label: '+44',
//     code: 'GG',
//   },
//   {
//     name: 'Guinea',
//     label: '+224',
//     code: 'GN',
//   },
//   {
//     name: 'Guinea-Bissau',
//     label: '+245',
//     code: 'GW',
//   },
//   {
//     name: 'Guyana',
//     label: '+595',
//     code: 'GY',
//   },
//   {
//     name: 'Haiti',
//     label: '+509',
//     code: 'HT',
//   },
//   {
//     name: 'Holy See (Vatican City State)',
//     label: '+379',
//     code: 'VA',
//   },
//   {
//     name: 'Honduras',
//     label: '+504',
//     code: 'HN',
//   },
//   {
//     name: 'Hong Kong',
//     label: '+852',
//     code: 'HK',
//   },
//   {
//     name: 'Hungary',
//     label: '+36',
//     code: 'HU',
//   },
//   {
//     name: 'Iceland',
//     label: '+354',
//     code: 'IS',
//   },
//   {
//     name: 'India',
//     label: '+91',
//     code: 'IN',
//   },
//   {
//     name: 'Indonesia',
//     label: '+62',
//     code: 'ID',
//   },
//   {
//     name: 'Iran, Islamic Republic of Persian Gulf',
//     label: '+98',
//     code: 'IR',
//   },
//   {
//     name: 'Iraq',
//     label: '+964',
//     code: 'IQ',
//   },
//   {
//     name: 'Ireland',
//     label: '+353',
//     code: 'IE',
//   },
//   {
//     name: 'Isle of Man',
//     label: '+44',
//     code: 'IM',
//   },
//   {
//     name: 'Israel',
//     label: '+972',
//     code: 'IL',
//   },
//   {
//     name: 'Italy',
//     label: '+39',
//     code: 'IT',
//   },
//   {
//     name: 'Jamaica',
//     label: '+1876',
//     code: 'JM',
//   },
//   {
//     name: 'Japan',
//     label: '+81',
//     code: 'JP',
//   },
//   {
//     name: 'Jersey',
//     label: '+44',
//     code: 'JE',
//   },
//   {
//     name: 'Jordan',
//     label: '+962',
//     code: 'JO',
//   },
//   {
//     name: 'Kazakhstan',
//     label: '+77',
//     code: 'KZ',
//   },
//   {
//     name: 'Kenya',
//     label: '+254',
//     code: 'KE',
//   },
//   {
//     name: 'Kiribati',
//     label: '+686',
//     code: 'KI',
//   },
//   {
//     name: "Korea, Democratic People's Republic of Korea",
//     label: '+850',
//     code: 'KP',
//   },
//   {
//     name: 'Korea, Republic of South Korea',
//     label: '+82',
//     code: 'KR',
//   },
//   {
//     name: 'Kuwait',
//     label: '+965',
//     code: 'KW',
//   },
//   {
//     name: 'Kyrgyzstan',
//     label: '+996',
//     code: 'KG',
//   },
//   {
//     name: 'Laos',
//     label: '+856',
//     code: 'LA',
//   },
//   {
//     name: 'Latvia',
//     label: '+371',
//     code: 'LV',
//   },
//   {
//     name: 'Lebanon',
//     label: '+961',
//     code: 'LB',
//   },
//   {
//     name: 'Lesotho',
//     label: '+266',
//     code: 'LS',
//   },
//   {
//     name: 'Liberia',
//     label: '+231',
//     code: 'LR',
//   },
//   {
//     name: 'Libyan Arab Jamahiriya',
//     label: '+218',
//     code: 'LY',
//   },
//   {
//     name: 'Liechtenstein',
//     label: '+423',
//     code: 'LI',
//   },
//   {
//     name: 'Lithuania',
//     label: '+370',
//     code: 'LT',
//   },
//   {
//     name: 'Luxembourg',
//     label: '+352',
//     code: 'LU',
//   },
//   {
//     name: 'Macao',
//     label: '+853',
//     code: 'MO',
//   },
//   {
//     name: 'Macedonia',
//     label: '+389',
//     code: 'MK',
//   },
//   {
//     name: 'Madagascar',
//     label: '+261',
//     code: 'MG',
//   },
//   {
//     name: 'Malawi',
//     label: '+265',
//     code: 'MW',
//   },
//   {
//     name: 'Malaysia',
//     label: '+60',
//     code: 'MY',
//   },
//   {
//     name: 'Maldives',
//     label: '+960',
//     code: 'MV',
//   },
//   {
//     name: 'Mali',
//     label: '+223',
//     code: 'ML',
//   },
//   {
//     name: 'Malta',
//     label: '+356',
//     code: 'MT',
//   },
//   {
//     name: 'Marshall Islands',
//     label: '+692',
//     code: 'MH',
//   },
//   {
//     name: 'Martinique',
//     label: '+596',
//     code: 'MQ',
//   },
//   {
//     name: 'Mauritania',
//     label: '+222',
//     code: 'MR',
//   },
//   {
//     name: 'Mauritius',
//     label: '+230',
//     code: 'MU',
//   },
//   {
//     name: 'Mayotte',
//     label: '+262',
//     code: 'YT',
//   },
//   {
//     name: 'Mexico',
//     label: '+52',
//     code: 'MX',
//   },
//   {
//     name: 'Micronesia, Federated States of Micronesia',
//     label: '+691',
//     code: 'FM',
//   },
//   {
//     name: 'Moldova',
//     label: '+373',
//     code: 'MD',
//   },
//   {
//     name: 'Monaco',
//     label: '+377',
//     code: 'MC',
//   },
//   {
//     name: 'Mongolia',
//     label: '+976',
//     code: 'MN',
//   },
//   {
//     name: 'Montenegro',
//     label: '+382',
//     code: 'ME',
//   },
//   {
//     name: 'Montserrat',
//     label: '+1664',
//     code: 'MS',
//   },
//   {
//     name: 'Morocco',
//     label: '+212',
//     code: 'MA',
//   },
//   {
//     name: 'Mozambique',
//     label: '+258',
//     code: 'MZ',
//   },
//   {
//     name: 'Myanmar',
//     label: '+95',
//     code: 'MM',
//   },
//   {
//     name: 'Namibia',
//     label: '+264',
//     code: 'NA',
//   },
//   {
//     name: 'Nauru',
//     label: '+674',
//     code: 'NR',
//   },
//   {
//     name: 'Nepal',
//     label: '+977',
//     code: 'NP',
//   },
//   {
//     name: 'Netherlands',
//     label: '+31',
//     code: 'NL',
//   },
//   {
//     name: 'Netherlands Antilles',
//     label: '+599',
//     code: 'AN',
//   },
//   {
//     name: 'New Caledonia',
//     label: '+687',
//     code: 'NC',
//   },
//   {
//     name: 'New Zealand',
//     label: '+64',
//     code: 'NZ',
//   },
//   {
//     name: 'Nicaragua',
//     label: '+505',
//     code: 'NI',
//   },
//   {
//     name: 'Niger',
//     label: '+227',
//     code: 'NE',
//   },
//   {
//     name: 'Nigeria',
//     label: '+234',
//     code: 'NG',
//   },
//   {
//     name: 'Niue',
//     label: '+683',
//     code: 'NU',
//   },
//   {
//     name: 'Norfolk Island',
//     label: '+672',
//     code: 'NF',
//   },
//   {
//     name: 'Northern Mariana Islands',
//     label: '+1670',
//     code: 'MP',
//   },
//   {
//     name: 'Norway',
//     label: '+47',
//     code: 'NO',
//   },
//   {
//     name: 'Oman',
//     label: '+968',
//     code: 'OM',
//   },
//   {
//     name: 'Pakistan',
//     label: '+92',
//     code: 'PK',
//   },
//   {
//     name: 'Palau',
//     label: '+680',
//     code: 'PW',
//   },
//   {
//     name: 'Palestinian Territory, Occupied',
//     label: '+970',
//     code: 'PS',
//   },
//   {
//     name: 'Panama',
//     label: '+507',
//     code: 'PA',
//   },
//   {
//     name: 'Papua New Guinea',
//     label: '+675',
//     code: 'PG',
//   },
//   {
//     name: 'Paraguay',
//     label: '+595',
//     code: 'PY',
//   },
//   {
//     name: 'Peru',
//     label: '+51',
//     code: 'PE',
//   },
//   {
//     name: 'Philippines',
//     label: '+63',
//     code: 'PH',
//   },
//   {
//     name: 'Pitcairn',
//     label: '+872',
//     code: 'PN',
//   },
//   {
//     name: 'Poland',
//     label: '+48',
//     code: 'PL',
//   },
//   {
//     name: 'Portugal',
//     label: '+351',
//     code: 'PT',
//   },
//   {
//     name: 'Puerto Rico',
//     label: '+1939',
//     code: 'PR',
//   },
//   {
//     name: 'Qatar',
//     label: '+974',
//     code: 'QA',
//   },
//   {
//     name: 'Romania',
//     label: '+40',
//     code: 'RO',
//   },
//   {
//     name: 'Russia',
//     label: '+7',
//     code: 'RU',
//   },
//   {
//     name: 'Rwanda',
//     label: '+250',
//     code: 'RW',
//   },
//   {
//     name: 'Reunion',
//     label: '+262',
//     code: 'RE',
//   },
//   {
//     name: 'Saint Barthelemy',
//     label: '+590',
//     code: 'BL',
//   },
//   {
//     name: 'Saint Helena, Ascension and Tristan Da Cunha',
//     label: '+290',
//     code: 'SH',
//   },
//   {
//     name: 'Saint Kitts and Nevis',
//     label: '+1869',
//     code: 'KN',
//   },
//   {
//     name: 'Saint Lucia',
//     label: '+1758',
//     code: 'LC',
//   },
//   {
//     name: 'Saint Martin',
//     label: '+590',
//     code: 'MF',
//   },
//   {
//     name: 'Saint Pierre and Miquelon',
//     label: '+508',
//     code: 'PM',
//   },
//   {
//     name: 'Saint Vincent and the Grenadines',
//     label: '+1784',
//     code: 'VC',
//   },
//   {
//     name: 'Samoa',
//     label: '+685',
//     code: 'WS',
//   },
//   {
//     name: 'San Marino',
//     label: '+378',
//     code: 'SM',
//   },
//   {
//     name: 'Sao Tome and Principe',
//     label: '+239',
//     code: 'ST',
//   },
//   {
//     name: 'Saudi Arabia',
//     label: '+966',
//     code: 'SA',
//   },
//   {
//     name: 'Senegal',
//     label: '+221',
//     code: 'SN',
//   },
//   {
//     name: 'Serbia',
//     label: '+381',
//     code: 'RS',
//   },
//   {
//     name: 'Seychelles',
//     label: '+248',
//     code: 'SC',
//   },
//   {
//     name: 'Sierra Leone',
//     label: '+232',
//     code: 'SL',
//   },
//   {
//     name: 'Singapore',
//     label: '+65',
//     code: 'SG',
//   },
//   {
//     name: 'Slovakia',
//     label: '+421',
//     code: 'SK',
//   },
//   {
//     name: 'Slovenia',
//     label: '+386',
//     code: 'SI',
//   },
//   {
//     name: 'Solomon Islands',
//     label: '+677',
//     code: 'SB',
//   },
//   {
//     name: 'Somalia',
//     label: '+252',
//     code: 'SO',
//   },
//   {
//     name: 'South Africa',
//     label: '+27',
//     code: 'ZA',
//   },
//   {
//     name: 'South Sudan',
//     label: '+211',
//     code: 'SS',
//   },
//   {
//     name: 'South Georgia and the South Sandwich Islands',
//     label: '+500',
//     code: 'GS',
//   },
//   {
//     name: 'Spain',
//     label: '+34',
//     code: 'ES',
//   },
//   {
//     name: 'Sri Lanka',
//     label: '+94',
//     code: 'LK',
//   },
//   {
//     name: 'Sudan',
//     label: '+249',
//     code: 'SD',
//   },
//   {
//     name: 'Suriname',
//     label: '+597',
//     code: 'SR',
//   },
//   {
//     name: 'Svalbard and Jan Mayen',
//     label: '+47',
//     code: 'SJ',
//   },
//   {
//     name: 'Swaziland',
//     label: '+268',
//     code: 'SZ',
//   },
//   {
//     name: 'Sweden',
//     label: '+46',
//     code: 'SE',
//   },
//   {
//     name: 'Switzerland',
//     label: '+41',
//     code: 'CH',
//   },
//   {
//     name: 'Syrian Arab Republic',
//     label: '+963',
//     code: 'SY',
//   },
//   {
//     name: 'Taiwan',
//     label: '+886',
//     code: 'TW',
//   },
//   {
//     name: 'Tajikistan',
//     label: '+992',
//     code: 'TJ',
//   },
//   {
//     name: 'Tanzania, United Republic of Tanzania',
//     label: '+255',
//     code: 'TZ',
//   },
//   {
//     name: 'Thailand',
//     label: '+66',
//     code: 'TH',
//   },
//   {
//     name: 'Timor-Leste',
//     label: '+670',
//     code: 'TL',
//   },
//   {
//     name: 'Togo',
//     label: '+228',
//     code: 'TG',
//   },
//   {
//     name: 'Tokelau',
//     label: '+690',
//     code: 'TK',
//   },
//   {
//     name: 'Tonga',
//     label: '+676',
//     code: 'TO',
//   },
//   {
//     name: 'Trinidad and Tobago',
//     label: '+1868',
//     code: 'TT',
//   },
//   {
//     name: 'Tunisia',
//     label: '+216',
//     code: 'TN',
//   },
//   {
//     name: 'Turkey',
//     label: '+90',
//     code: 'TR',
//   },
//   {
//     name: 'Turkmenistan',
//     label: '+993',
//     code: 'TM',
//   },
//   {
//     name: 'Turks and Caicos Islands',
//     label: '+1649',
//     code: 'TC',
//   },
//   {
//     name: 'Tuvalu',
//     label: '+688',
//     code: 'TV',
//   },
//   {
//     name: 'Uganda',
//     label: '+256',
//     code: 'UG',
//   },
//   {
//     name: 'Ukraine',
//     label: '+380',
//     code: 'UA',
//   },
//   {
//     name: 'United Arab Emirates',
//     label: '+971',
//     code: 'AE',
//   },
//   {
//     name: 'United Kingdom',
//     label: '+44',
//     code: 'GB',
//   },
//   {
//     name: 'United States',
//     label: '+1',
//     code: 'US',
//   },
//   {
//     name: 'Uruguay',
//     label: '+598',
//     code: 'UY',
//   },
//   {
//     name: 'Uzbekistan',
//     label: '+998',
//     code: 'UZ',
//   },
//   {
//     name: 'Vanuatu',
//     label: '+678',
//     code: 'VU',
//   },
//   {
//     name: 'Venezuela, Bolivarian Republic of Venezuela',
//     label: '+58',
//     code: 'VE',
//   },
//   {
//     name: 'Vietnam',
//     label: '+84',
//     code: 'VN',
//   },
//   {
//     name: 'Virgin Islands, British',
//     label: '+1284',
//     code: 'VG',
//   },
//   {
//     name: 'Virgin Islands, U.S.',
//     label: '+1340',
//     code: 'VI',
//   },
//   {
//     name: 'Wallis and Futuna',
//     label: '+681',
//     code: 'WF',
//   },
//   {
//     name: 'Yemen',
//     label: '+967',
//     code: 'YE',
//   },
//   {
//     name: 'Zambia',
//     label: '+260',
//     code: 'ZM',
//   },
//   {
//     name: 'Zimbabwe',
//     label: '+263',
//     code: 'ZW',
//   },
// ];
