import { Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Tile from '../../widgets/tile';
import Input from '../../widgets/input';
import { GrRefresh } from 'react-icons/gr';
import { TbWorld } from 'react-icons/tb';
import store from '../../../states';
// import { getTrafficDashboardAPI } from '../../../services/trafficdashboard';
// import DateRangePickerComponent from '../../comman/datepicker/DateRangePicker';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';
// import moment from 'moment';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';
import NewTrafficTable from '../../tables/NewTrafficTable';
import TableSkeleton from '../../loaders/table';

const TrafficDashboard = () => {
  const { getTrafficDashboardAPI }: any = useAuthentication();
  const {
    trafficTotalData,
    sTrafficTotalData,
    trafficTableData,
    sTrafficTableData,
    dateRangeTraffic,
    sDateRangeTraffic,
  }: any = consumerData();

  //const [trafficTotalData, sTrafficTotalData] = useState<any>([]);
  //const [trafficTableData, sTrafficTableData] = useState<any>([]);
  const [, /* selectedTile */ sSelectedTile] = useState(0);
  const [onswitch, sOffSwitch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dateChange, setDateChange] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  /* set date on laod api and set api */
  // const [selectionRange, setSelectionRange] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: 'selection',
  // });

  const GetSelectionRangeProps = (data: any) => {
    //setSelectionRange(data);
    sDateRangeTraffic(data);
    setDateChange(true);
  };

  useEffect(() => {
    if (trafficTableData.length === 0) {
      gettrafficDashboardData('');
    } else {
      setLoading(false);
    }
  }, []);

  const gettrafficDashboardData = (type_local: any) => {
    const payload = {
      type: type_local,
      start_date: dateRangeTraffic.startDate.toLocaleDateString('fr-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      end_date: dateRangeTraffic.endDate.toLocaleDateString('fr-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    };
    getTrafficDashboardAPI(payload).then((data: any) => {
      sTrafficTotalData(data.overview);
      sTrafficTableData(data);
      setLoading(false);
      setDateChange(false);
    });
  };

  const toggle = () => {
    sOffSwitch(!onswitch);
    gettrafficDashboardData(!onswitch ? 'dsp' : 'ssp');
    setLoading(true);
  };

  const refreshBtn = () => {
    gettrafficDashboardData(!onswitch ? 'ssp' : 'dsp');
    sTrafficTableData([]);
    sTrafficTotalData([]);
    setLoading(true);
  };

  const search = (val: any) => {
    //
  };

  // useEffect(() => {
  //   gettrafficDashboardData(!onswitch ? 'ssp' : 'dsp');
  //   setLoading(true);
  //   setDateChange(false);
  // }, [selectionRange]);

  useEffect(() => {
    if (dateChange) gettrafficDashboardData(!onswitch ? 'ssp' : 'dsp');
    setDateChange(false);
  }, [dateChange]);

  return (
    <>
      <div className='content traffic-dashboard px-4 pt-5 position-relative'>
        <Row className='pb-sm-1 align-items-center'>
          <Col lg={12} xl={6} className='mt-0'>
            <div className='align-items-end d-flex gap-1'>
              {trafficTotalData.length > 0 &&
                trafficTotalData.map((tile: any, index: any) => {
                  return (
                    <div key={index} className={'col-auto'}>
                      <Tile
                        onHover={'disabled'}
                        id={tile.id}
                        selected={99}
                        click={selectTile}
                        label={tile.label}
                        value={tile.value}
                        info={tile.info}
                        arrow={tile.arrow}
                        deals
                      />
                    </div>
                  );
                })}
            </div>
          </Col>
          <Col lg={12} xl={6} className='mt-lg-3 mt-xl-0'>
            <div className='align-items-center d-flex gap-4 justify-content-end position-relative'>
              <div className='d-flex align-items-center w-auto'>
                <p className='pt-1'>{glossary.admin_traffic_dash_toggle_button_dsp_view}</p>
                <div className='d-flex switch-wrap w-auto ms-2'>
                  <input
                    className={onswitch ? 'on' : 'off'}
                    type='checkbox'
                    onChange={toggle}
                    id='switch'
                  />
                  <label htmlFor='switch'></label>
                </div>
              </div>
              <div className={'tour-finance'}>
                <button
                  className='button button-secondary'
                  style={{ width: '110px' }}
                  onClick={() => {
                    refreshBtn();
                  }}
                >
                  <GrRefresh className='svg-icon' />
                  {glossary.admin_traffic_dash_button_refresh}
                </button>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TbWorld className='svg-icon me-2 fs-4' strokeWidth='1.4' />
                {glossary.admin_traffic_dash_text_button_utc_timezone}
              </div>
              <div className={'calendar__wrap'} style={{ display: 'flex', alignItems: 'center' }}>
                <DateRangeFilter
                  onChange={GetSelectionRangeProps}
                  selectionRange={dateRangeTraffic}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {loading ? (
              <div>
                <div className='search-input-wrap'>
                  <Input
                    label=''
                    type='search'
                    placeholder={glossary.admin_traffic_table_input_search_field_placeholder}
                    length={32}
                    text=''
                    kind='mini'
                    change={(e, v) => {
                      search(v);
                    }}
                  />
                </div>
                <br />
                <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
              </div>
            ) : (
              // <TrafficTable
              //   rows={trafficTableData.data ?? []}
              //   columns={trafficTableData.columns ?? []}
              //   columnWidth={150}
              // />
              <NewTrafficTable
                data={trafficTableData.data ?? []}
                columns={trafficTableData.columns ?? []}
                columnWidth={150}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TrafficDashboard;
