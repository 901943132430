import React from 'react';
import ButtonSkeleton from './button';
import CardLoader from './card';
import TextSkeleton from './text';
import Box from './box';
import XYChart from './chart';

const PageSkeleton = () => {
  return (
    <>
      <div className={'content d-flex'}>
        {/* Sidebar View */}
        <div className='newsidebar'>
          <div className={'sidebar-row sidebar-row-first'}>
            <div className={'sidebar-col mb-3'}>
              <TextSkeleton width={160} height={20} lineCount={1} />
            </div>
          </div>

          <div className={'sidebar-row sidebar-row-secound'}>
            <div className={'sidebar-col'} id='first-btn'>
              <ButtonSkeleton height={'40px'} width={'115px'} />
            </div>
          </div>

          <div className={'sidebar-list'} style={{ minWidth: 300, overflow: 'hidden' }}>
            <CardLoader />
            <CardLoader />
            <CardLoader />
          </div>
        </div>

        {/* Content View */}
        <div className='view-section'>
          <div className='row align-items-center'>
            <div className='col-lg-4 col-md-12'>
              <TextSkeleton width={160} height={20} lineCount={1} />
            </div>
            <div className='col-lg-4 col-md-12'></div>
            <div className='col-lg-4 col-md-12'>
              <TextSkeleton width={'100%'} height={20} lineCount={1} />
            </div>
          </div>

          <div className={'row'} style={{ marginTop: '56px', justifyContent: 'space-between' }}>
            <div className='col-6'>
              <div className='d-flex gap-4'>
                {[1, 2, 3, 4].map((index) => {
                  return <Box key={index} />;
                })}
              </div>
            </div>
          </div>
          <div className='row' style={{ marginTop: '50px' }}>
            <div className='col w-100 m-auto'>
              <XYChart />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSkeleton;
