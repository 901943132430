import { useEffect, useState } from 'react';
import Switch from 'react-switch';

export default (props: any) => {
  const root = document.documentElement;
  const primary2 = getComputedStyle(root).getPropertyValue('--primary-2');

  const Varibale = {
    onColor: '#cbcbcb',
    onHandleColor: primary2,
    offHandleColor: '#787878',
    offColor: '#CBCBCB',
  };

  const { api, status, disabled } = props;

  const [checked, sChecked] = useState<boolean>(status);

  useEffect(() => {
    sChecked(status);
  }, [status]);

  return (
    <Switch
      checked={checked}
      onChange={(chec) => {
        api(chec);
        sChecked(checked ? false : true);
      }}
      onColor={Varibale.onColor}
      onHandleColor={Varibale.onHandleColor}
      offHandleColor={Varibale.offHandleColor}
      offColor={Varibale.offColor}
      handleDiameter={20}
      uncheckedIcon={false}
      checkedIcon={false}
      height={15}
      width={30}
      disabled={disabled ?? false}
    />
  );
};
