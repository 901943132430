export const resolutionOpt: any = [
  {
    label: 'Medium Rectangle (300 x 250 pixels)',
    value: 'Medium Rectangle (300 x 250 pixels)',
    width: '250px',
    height: '300px',
  },
  {
    label: 'Large Rectangle (336 x 280 pixels)',
    value: 'Large Rectangle (336 x 280 pixels)',
    width: '336px',
    height: '280px',
  },
  {
    label: 'Leaderboard (728 x 90 pixels)',
    value: 'Leaderboard (728 x 90 pixels)',
    width: '728px',
    height: '90px',
  },
  {
    label: 'Half Page (300 x 600 pixels)',
    value: 'Half Page (300 x 600 pixels)',
    width: '300px',
    height: '600px',
  },
  {
    label: 'Wide Skyscraper (160 x 600 pixels)',
    value: 'Wide Skyscraper (160 x 600 pixels)',
    width: '160px',
    height: '600px',
  },
  {
    label: 'Skyscraper (120 x 600 pixels)',
    value: 'Skyscraper (120 x 600 pixels)',
    width: '120px',
    height: '600px',
  },
  {
    label: 'Banner (468 x 60 pixels)',
    value: 'Banner (468 x 60 pixels)',
    width: '468px',
    height: '60px',
  },
  {
    label: 'Square (250 x 250 pixels)',
    value: 'Square (250 x 250 pixels)',
    width: '250px',
    height: '250px',
  },
  {
    label: 'Small Square (200 x 200 pixels)',
    value: 'Small Square (200 x 200 pixels)',
    width: '200px',
    height: '200px',
  },
  {
    label: 'Large Leaderboard (970 x 90 pixels)',
    value: 'Large Leaderboard (970 x 90 pixels)',
    width: '970px',
    height: '90px',
  },
  {
    label: 'Billboard (970 x 250 pixels)',
    value: 'Billboard (970 x 250 pixels)',
    width: '970px',
    height: '250px',
  },
  {
    label: 'Portrait (300 x 1050 pixels)',
    value: 'Portrait (300 x 1050 pixels)',
    width: '300px',
    height: '1050px',
  },
];

export const newResolutionOpt: any = [
  {
    label: '300 x 600 pixels',
    value: '300 x 600 pixels',
    width: '300px',
    height: '600px',
  },
  // {
  //   label: '240 x 400 pixels',
  //   value: '240 x 400 pixels',
  //   width: '240px',
  //   height: '400px',
  // },
  // {
  //   label: '120 x 240 pixels',
  //   value: '120 x 240 pixels',
  //   width: '120px',
  //   height: '240px',
  // },
  // {
  //   label: '250 x 250 pixels',
  //   value: '250 x 250 pixels',
  //   width: '250px',
  //   height: '250px',
  // },
  {
    label: '300 x 250 pixels',
    value: '300 x 250 pixels',
    width: '300px',
    height: '250px',
  },
  // {
  //   label: '336 x 280 pixels',
  //   value: '336 x 280 pixels',
  //   width: '336px',
  //   height: '280px',
  // },
  // {
  //   label: '720 x 300 pixels',
  //   value: '720 x 300 pixels',
  //   width: '720px',
  //   height: '300px',
  // },
  // {
  //   label: '160 x 600 pixels',
  //   value: '160 x 600 pixels',
  //   width: '160px',
  //   height: '600px',
  // },
  // {
  //   label: '120 x 600 pixels',
  //   value: '120 x 600 pixels',
  //   width: '120px',
  //   height: '600px',
  // },
];

export const viewTypeOpt: any = [
  { value: 'interstitial', label: 'Interstitial' },
  { value: 'mid-roll', label: 'Mid Roll' },
  { value: 'end-roll', label: 'End Roll' },
  { value: 'convert-pip', label: 'Convert pip' },
];

export const addRellviewTypeOpt: any = [
  { value: 'interstitial', label: 'Interstitial' },
  { value: 'mid_roll', label: 'Mid Roll' },
  { value: 'end_roll', label: 'End Roll' },
  { value: 'convert_pip', label: 'Convert pip' },
];

export const feedTypeOpt: any = [
  { value: 'smart', label: 'Smart Feed' },
  { value: 'loop', label: 'Loop Table' },
  { value: 'fixed', label: 'Added videos' },
  { value: 'user', label: 'My profile' },
];
