import { useEffect, useState } from 'react';
import Switch from '../../widgets/switch';
import { default as ReactSelect } from 'react-select';
import { getMacrosListPostAPI } from '../../../services/macros';
import TableSkeleton from '../../loaders/table';

interface Props {
  enpointListData?: any;
  gamParamURL?: any;
  updateMacros?: any;
}

const customStyles = {
  placeholder: (provided: any) => ({
    ...provided,
    '&:hover': {
      background: '#f4f4f4',
      color: '#731780',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused ? '#F9F6F9' : '#ffffff',
    color: state.isSelected ? '#731780' : '#000000',
    '&:hover': {
      background: '#F9F6F9',
      color: '#731780',
    },
  }),
  control: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    //height: 52,
    border: state.isFocused ? '1.5px solid #731780' : '1px solid #a2a2a2',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      border: '1.5px solid #731780',
      boxShadow: 'none',
    },
  }),
};

const GamMacros = (props: Props) => {
  const { enpointListData, updateMacros } = props;
  const [GAMMacros, sGAMMacros] = useState(
    enpointListData?.config?.is_customized_gam_endpoint ?? true
  );
  const [GAMDATA, sGAMDATA] = useState([]);
  const [skeleton, sSkeleton] = useState(true);

  const initiateMacrosListPostAPI = () => {
    const payload = {
      device_type: 'gam',
    };
    sSkeleton(true);
    getMacrosListPostAPI(payload)
      .then((data: any) => {
        sGAMDATA(data.data);
        sSkeleton(false);
      })
      .catch((error: any) => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  useEffect(() => {
    initiateMacrosListPostAPI();
  }, [!GAMDATA]);

  const [decodeObj, setDecodeObj] = useState<any>({});
  const [multiSelectParameters, setMultiSelectParameters] = useState<any>([]);
  const [multiSelectParamValue, setMultiSelectParamValue] = useState('');
  const [singleSelectArray, setSingleSelectArray] = useState<any>([]);
  const [singleSelectValue, setSIngleSelectValue] = useState('');
  const [singleSelectParamValue, setSingleSelectParamValue] = useState('');

  const decodeObjKey = Object.keys(decodeObj);
  const decodeObjValue = Object.values(decodeObj);

  const defaultURL = enpointListData?.endpoint;
  const splitURL = defaultURL.toString().split('&');

  let finalGAMmacrosURL: any = `${splitURL[0]}`;
  for (const index in decodeObjKey) {
    if (decodeObjValue[index]) {
      finalGAMmacrosURL =
        finalGAMmacrosURL + '&' + decodeObjKey[index] + '=' + decodeObjValue[index];
    }
  }

  props.gamParamURL(finalGAMmacrosURL);
  //props.gamParamURL('https://pubads.g.doubleclick.net/gampad/ads?iu=/22863332527/Test_ad_unit_007');

  /* Decode vast URL */
  const initiatDecodeURL = () => {
    const urlMacros = enpointListData?.endpoint;
    const parts = urlMacros.split('?'); // Split the string at the question mark
    const result = parts[0]; // Get the first part of the split array
    function getQueryParams(urlMacros: any) {
      const paramArr = urlMacros.slice(urlMacros.indexOf('?') + 1).split('&');
      const params: any = {};
      paramArr.map((param: any) => {
        const [key, val] = param.split('=');
        params[key] = decodeURIComponent(val);
      });
      return params;
    }
    const params = getQueryParams(urlMacros);
    delete params.iu;
    setDecodeObj(params);
  };

  useEffect(() => {
    initiatDecodeURL();
  }, [enpointListData]);

  /* Decode input URL convert to Array */
  const objConvert: any = Object.keys(decodeObj).map((key: any) => ({
    parameters: key,
    label: decodeObj[key],
    value: decodeObj[key],
  }));

  const handleInput = (e: any, parameters: any) => {
    setDecodeObj((prevState: any) => ({ ...prevState, [parameters]: e.target.value }));
  };

  //handle Multi Select
  const handleMultiSelect = (e: any, parameters: any) => {
    setMultiSelectParameters(e);
    setMultiSelectParamValue(parameters);
  };

  //handle single Select
  const handleSingleSelect = (e: any, parameters: any) => {
    const selectedParam = singleSelectArray;
    setSingleSelectArray([...selectedParam, { ...e, parameters }]);
    setSIngleSelectValue(e.value);
    setSingleSelectParamValue(parameters);
  };

  //Multi Select
  useEffect(() => {
    if (multiSelectParameters?.length > 0) {
      let bUrl = '';
      multiSelectParameters.forEach((appObj: any) => {
        bUrl = bUrl + appObj.value + ',';
      });
      const newnUrl = bUrl.slice(0, -1);
      setDecodeObj((prevState: any) => ({ ...prevState, [multiSelectParamValue]: newnUrl }));
    }
  }, [multiSelectParameters, multiSelectParamValue]);

  //Single Select
  useEffect(() => {
    if (singleSelectValue) {
      setDecodeObj((prevState: any) => ({
        ...prevState,
        [singleSelectParamValue]: singleSelectValue,
      }));
    }
  }, [singleSelectValue, singleSelectParamValue]);

  function convertDimensions(dimensions: any) {
    const result = [];

    for (let i = 0; i < dimensions.length; i++) {
      const [width, height] = dimensions[i].split('x');
      result.push({ label: width, value: height });
    }

    return result;
  }

  updateMacros(GAMMacros);

  return (
    <>
      <div className='row my-4'>
        <div className='d-flex gap-2 mt-0 gam-macros'>
          <Switch
            api={(chec: any) => {
              chec === true ? sGAMMacros(true) : sGAMMacros(false);
            }}
            status={GAMMacros}
          />
          <span className='fw-lighter'>Auto Update Macros</span>
        </div>
      </div>
      {!GAMMacros && (
        <>
          {skeleton ? (
            <div className='row mt-3'>
              <div className='col-6'>
                <TableSkeleton columns={2} rows={5} rowSpacing={2} tableHeight={200} />
              </div>
            </div>
          ) : (
            <>
              <div className='row mb-3'>
                <div className='col-3'>
                  <h3 style={{ fontWeight: 'lighter', fontSize: '20px' }}>Parameters</h3>
                </div>
                <div className='col-3'>
                  <h3 style={{ fontWeight: 'lighter', fontSize: '20px' }}>Value</h3>
                </div>
              </div>
              <div className='mb-3'>
                {GAMDATA?.map((item: any, i: any) => {
                  return (
                    <div className='row align-items-center mb-3' key={i}>
                      <div className='col-3'>{item.parameters}</div>

                      {item?.attributes?.is_drop_down_type === true ? (
                        <div className='col-3 select-row'>
                          <>
                            {item?.attributes?.is_multi_select === true ? (
                              <>
                                {objConvert.length === 0 ? (
                                  <>
                                    <ReactSelect
                                      key={item.id}
                                      id={item.id}
                                      options={item.attributes.drop_down_values_formatted}
                                      onChange={(e: any) => handleMultiSelect(e, item.parameters)}
                                      isMulti={true}
                                      defaultValue={item.attributes.default_value_formatted}
                                      styles={customStyles}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <ReactSelect
                                      key={item.id}
                                      id={item.id}
                                      options={item.attributes.drop_down_values_formatted}
                                      onChange={(e: any) => handleMultiSelect(e, item.parameters)}
                                      isMulti={true}
                                      defaultValue={objConvert?.filter((el: any) => {
                                        if (el.parameters === item.parameters) {
                                          const elementSizes = el.value;
                                          const sizesArray = elementSizes.split(',');
                                          // const newSelectedSize = sizesArray.map((el1: any) => {
                                          //   return { label: el1, value: el1 };
                                          // });
                                          //return sizesArray;
                                          //console.log('sizesArray', sizesArray);
                                          const converted = convertDimensions(sizesArray);
                                          //console.log('converted', converted);

                                          return converted;
                                        }
                                      })}
                                      styles={customStyles}
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {objConvert.length === 0 ? (
                                  <>
                                    <ReactSelect
                                      key={item.id}
                                      id={item.id}
                                      options={item.attributes.drop_down_values_formatted}
                                      onChange={(e: any) => handleSingleSelect(e, item.parameters)}
                                      defaultValue={item.attributes.default_value_formatted}
                                      styles={customStyles}
                                    />
                                  </>
                                ) : (
                                  <ReactSelect
                                    key={item.id}
                                    id={item.id}
                                    options={item.attributes.drop_down_values_formatted}
                                    onChange={(e: any) => handleSingleSelect(e, item.parameters)}
                                    defaultValue={objConvert?.map((el: any) => {
                                      if (el.parameters === item.parameters) {
                                        return { label: el.value, value: el.value };
                                      }
                                    })}
                                    styles={customStyles}
                                  />
                                )}
                              </>
                            )}
                          </>
                        </div>
                      ) : (
                        <div className='col-3'>
                          {objConvert.length === 0 ? (
                            <input
                              type='text'
                              className='form-control'
                              onChange={(e) => handleInput(e, item.parameters)}
                              defaultValue={item?.attributes?.default_value_formatted}
                            />
                          ) : (
                            <input
                              type='text'
                              className='form-control'
                              onChange={(e) => handleInput(e, item.parameters)}
                              defaultValue={objConvert
                                .filter((el: any) => {
                                  if (el.parameters === item.parameters) {
                                    return true;
                                  }
                                  return false;
                                })
                                .map((val: any) => {
                                  return val.value;
                                })}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {/* {GAMDATA.length === 0 ? (
            <h4>Macros Not Detected</h4>
          ) : (
            <div className='row mb-3'>
              <div className='col-3'>
                <h3 style={{ fontWeight: 'lighter', fontSize: '20px' }}>Parameters</h3>
              </div>
              <div className='col-3'>
                <h3 style={{ fontWeight: 'lighter', fontSize: '20px' }}>Value</h3>
              </div>
            </div>
          )}
          {skeleton ? (
            <div className='row mt-3'>
              <div className='col-6'>
                <TableSkeleton columns={2} rows={5} rowSpacing={2} tableHeight={200} />
              </div>
            </div>
          ) : (
          
          )} */}
        </>
      )}
    </>
  );
};

export default GamMacros;
