export default {
  columns: [
    {
      key: 'name',
      label: 'name',
    },
    {
      key: 'first_name',
      label: 'first_name',
    },
    {
      key: 'last_name',
      label: 'last_name',
    },
    {
      key: 'email',
      label: 'email',
    },
  ],
  data: [
    {
      name: 'Initech',
      first_name: 'Thomas',
      last_name: 'Kelly',
      email: 'info@Initech.com',
    },
  ],
};
