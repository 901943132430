import { useEffect, useState } from 'react';
import Customer from '../../modals/customer';
import { getCustomersAPI } from '../../../services/customers';
import InfyTableComponent from '../../comman/table/InfyTableComponent';
import { routes } from '../../../services/constants';
import { useLocation } from 'react-router-dom';
import store from '../../../states';

export default ({ toast }: any) => {
  const { pathname } = useLocation();

  const [customers, sCustomers] = useState<any>([]);
  const [customerModal, sCustomerModal] = useState(false);
  const [apiPath, sApiPath] = useState('');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const getCustomers = () => {
    const payload = {
      order: 'desc',
      order_by: 'created_on',
      query: '',
      // id: '',
    };
    getCustomersAPI(payload)
      .then((data: any) => {
        if (data) {
          sCustomers(data);
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  useEffect(() => {
    if (localStorage.getItem('r') === 'infy-admin') {
      //getCustomers();
    }
  }, []);

  useEffect(() => {
    if (pathname === '/settings/customers') {
      sApiPath(routes.getCustomers);
    }
  }, [pathname, routes]);

  return (
    <>
      <Customer
        updateList={getCustomers}
        show={customerModal}
        onHide={() => sCustomerModal(false)}
      />
      <div className={'row'} style={{ justifyContent: 'space-between' }}>
        <div className={'col'}>
          <h2 style={{ fontWeight: 200 }}>{glossary.setting_customers_title}</h2>
        </div>
        <div />
      </div>
      <div className={'row customers-data table__responsive'}>
        <InfyTableComponent
          columnWidth={130}
          api={apiPath}
          method={'POST'}
          tableName='Admin Customers'
          downloadCSV={false}
          search={true}
          searchPlaceholder={glossary.setting_customers_table_search_placeholder}
        />
      </div>
    </>
  );
};
