import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import JWT from 'jsonwebtoken';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import FormB from 'react-bootstrap/Form';
import Radio from '../../widgets/radio';
import Switch from '../../widgets/switch';

import { addEndpointAPI, updateEndpointAPI } from '../../../services/endpoint';
import { toast } from 'react-toastify';

import store from '../../../states';

import FormikController from '../../comman/form/FormikController';
import GamMacros from './Gam-macros';
import Button from '../../widgets/button';
import { windowReload } from '../../helpers/functions';

interface Props {
  enpointListDataConfigObj?: any;
  onHide?: any;
  endpointId?: any;
  enpointListData?: any;
  getEndPointListCard?: any;
  endpontDataStatus?: any;
  editDetailsDSPModal?: any;
  openDetailsModal?: any;
  getEndpointListInfoAPI?: any;
  xmlEndpoint?: any;
}

const GAMdetails = (props: Props) => {
  const {
    onHide,
    enpointListDataConfigObj,
    endpointId,
    getEndPointListCard,
    endpontDataStatus,
    openDetailsModal,
    enpointListData,
    getEndpointListInfoAPI,
  } = props;

  //GAM Form Initial Values
  const RtbFormInitialValues = {
    integrationName: enpointListData?.name ?? '',
    integrationEndPoint: enpointListData?.endpoint ?? '',
    integrationMinCPM: enpointListDataConfigObj?.min_cpm ?? 0,
    integrationMaxCPM: enpointListDataConfigObj?.max_cpm ?? 0,
    integrationCPM: enpointListData?.floor_price ?? 0,
  };

  const [softFloor, sSoftFloor] = useState(
    enpointListDataConfigObj?.is_soft_floor?.toString() ?? 'true'
  );
  const [customerID, sCustomerID] = useState(); // Set customer id
  const [allTraffic, sAllTraffic] = useState(enpointListDataConfigObj?.is_all_traffic ?? true); // handle all traffic toggle
  const [editDetailsDSPModal, sEditDetailsDSPModal] = useState<any>(false); // Get GAM all details
  const [gamParamURL, sGAMParamURL] = useState(''); // GAM param final url set
  const [glossary, sGlossary] = useState<any>({}); // Set Glossary
  const [updateMacrosToggle, sUpdateMacrosToggle] = useState();
  const [isSubmitting, setSubmitting] = useState(false);

  //Get Glossary
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  //Handle Soft Floor toggle
  const selectSoftFloor = (id: string) => {
    sSoftFloor(id);
  };

  // Get customer id from JWT token
  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    const decoded = JWT.decode(token, { complete: true });
    // @ts-ignore
    sCustomerID(decoded.payload.company_id);
  }, []);

  const valReg = /^\d+(\.\d{1,2})?$/;

  // validation schema
  const SignupSchema = Yup.object().shape({
    integrationName: Yup.string().required(glossary.dsp_add_endpoint_input_name_empty_error),
    integrationMinCPM: Yup.number()
      .required(glossary.dsp_add_endpoint_input_min_floor_price_empty_error)
      .test(
        'superior',
        glossary.dsp_add_endpoint_input_min_floor_price_valid_error,
        function (f2: any) {
          return valReg.test(f2);
        }
      )
      .typeError(glossary.dsp_add_endpoint_input_min_floor_price_valid_error)
      .max(99999, glossary.dsp_add_endpoint_input_min_floor_max_length_error),
    integrationMaxCPM: Yup.number()
      .required(glossary.dsp_add_endpoint_input_max_floor_price_empty_error)
      .test(
        'superior',
        glossary.dsp_add_endpoint_input_max_floor_price_valid_error,
        function (f2: any) {
          return valReg.test(f2);
        }
      )
      .typeError(glossary.dsp_add_endpoint_input_max_floor_price_valid_error)
      .test('superior', glossary.dsp_add_endpoint_input_compare_error, function (f2: any) {
        const ref2: any = Yup.ref('integrationMinCPM');
        return f2 >= this.resolve(ref2);
      })
      .max(99999, glossary.dsp_add_endpoint_input_max_floor_price_max_length_error),
    integrationEndPoint: Yup.string()
      .url(glossary.dsp_add_endpoint_input_url_valid_error)
      .required(glossary.dsp_add_endpoint_input_url_empty_error),
  });

  const SignupSchema1 = Yup.object().shape({
    integrationName: Yup.string().required(glossary.dsp_add_endpoint_input_name_empty_error),
    integrationCPM: Yup.number()
      .required(glossary.dsp_add_endpoint_input_floor_price_empty_error)
      .test(
        'superior',
        glossary.dsp_add_endpoint_input_floor_price_valid_error,
        function (f2: any) {
          return valReg.test(f2);
        }
      )
      .typeError(glossary.dsp_add_endpoint_input_floor_price_valid_error)
      .max(99999, glossary.dsp_add_endpoint_input_floor_price_max_length_error),
    integrationEndPoint: Yup.string()
      .url(glossary.dsp_add_endpoint_input_url_valid_error)
      .required(glossary.dsp_add_endpoint_input_url_empty_error),
  });

  //Add GAM data
  const initiateRTG = (e: any) => {
    ReactGA.event('dsp_test_integration_button', {
      category: 'add_endpoints',
      action: 'test_integration_button',
      label: 'test_integration',
    });

    const minCPMFloat = parseFloat(e.integrationMinCPM);
    const maxCPMFloat = parseFloat(e.integrationMaxCPM);
    const CPMFloat = parseFloat(e.integrationCPM);

    const payload = {
      customer_id: customerID,
      endpoint: e.integrationEndPoint,
      endpoint_type: 'GAM',
      name: e.integrationName,
      config: {
        min_cpm: softFloor === 'true' ? minCPMFloat : CPMFloat,
        max_cpm: softFloor === 'true' ? maxCPMFloat : 0,
        is_soft_floor: softFloor === 'true' ? true : false,
        is_all_traffic: allTraffic ? true : false,
      },
    };
    setSubmitting(true);
    addEndpointAPI(payload)
      .then((data: any) => {
        if (endpontDataStatus === false) {
          windowReload();
        }
        if (data?.message === 'End point added!') {
          getEndPointListCard(data, 'add');
          toast.success(glossary.dsp_add_endpoint_success_msg);
        } else {
          toast.error(glossary.dsp_add_rtb_endpoint_exist_error);
        }
        setTimeout(() => {
          setSubmitting(false);
        }, 300);
      })
      .catch((error) => {
        // toast.error(glossary.dsp_add_endpoint_server_error);
      });
  };

  //Update End point data start
  const updateEndpoint = (e: any) => {
    ReactGA.event('dsp_test_integration_button', {
      category: 'add_endpoints',
      action: 'test_integration_button',
      label: 'test_integration',
    });

    const minCPMFloat = parseFloat(e.integrationMinCPM);
    const maxCPMFloat = parseFloat(e.integrationMaxCPM);
    const CPMFloat = parseFloat(e.integrationCPM);

    const payload = {
      id: endpointId,
      customer_id: customerID,
      endpoint: gamParamURL,
      endpoint_type: 'GAM',
      name: e.integrationName,
      config: {
        min_cpm: softFloor === 'true' ? minCPMFloat : CPMFloat,
        max_cpm: softFloor === 'true' ? maxCPMFloat : 0,
        is_soft_floor: softFloor === 'true' ? true : false,
        is_all_traffic: allTraffic ? true : false,
        is_customized_gam_endpoint: updateMacrosToggle === true ? true : false,
      },
    };
    setSubmitting(true);
    updateEndpointAPI(payload)
      .then((data: any) => {
        if (data?.message === 'Update successfully') {
          toast.success(glossary.dsp_update_rtb_success_msg);
          getEndPointListCard(data, 'update');
        }
        setTimeout(() => {
          setSubmitting(false);
        }, 300);
      })
      .catch((error: any) => {
        toast.error(glossary.dsp_update_rtb_faild_server_msg);
      });
  };

  //get GAM URL with params
  const getGamParamURL = (url: string) => {
    sGAMParamURL(url);
  };

  const getUpdateMacros = (val: any) => {
    sUpdateMacrosToggle(val);
  };

  return (
    <>
      <Formik
        validationSchema={softFloor === 'true' ? SignupSchema : SignupSchema1}
        initialValues={RtbFormInitialValues}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(e) => {
          if (enpointListDataConfigObj) {
            updateEndpoint(e);
          } else {
            initiateRTG(e);
          }
        }}
      >
        {({ errors, touched }) => (
          <Form className={editDetailsDSPModal ? 'rtb-form edit' : 'rtb-form disable'}>
            <div
              className={getEndPointListCard && 'modal-body p-3'}
              style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto',
              }}
            >
              <div className='row mb-3 mt-1'>
                <div className='col-md-5'>
                  <div className='form-group'>
                    <FormikController
                      kind='withouticon'
                      control='input'
                      type='text'
                      label={glossary.dsp_add_endpoint_input_name_label}
                      placeholder={glossary.dsp_add_endpoint_input_name_placeholder}
                      name='integrationName'
                      maxLength={1024}
                    />
                  </div>
                </div>
                <div className='col-auto'>
                  <div className='align-items-center d-flex switch' style={{ paddingTop: '30px' }}>
                    <Switch
                      api={(chec: any) => {
                        chec === true ? sAllTraffic(true) : sAllTraffic(false);
                      }}
                      status={allTraffic}
                    />
                    <label className='label mb-0 ms-2 pt-1 text-body'>
                      {glossary.dsp_add_endpoint_toggle_all_traffic}
                    </label>
                  </div>
                </div>
                {allTraffic === false && (
                  <>
                    <div className='col-auto'>
                      <FormB.Group className='d-flex form-group mt-3 pt-1 flex-column'>
                        <Radio
                          label={glossary.dsp_add_endpoint_radio_button_floor_range}
                          size={'mini'}
                          id={'true'}
                          selected={softFloor}
                          click={selectSoftFloor}
                        />
                        <Radio
                          label={glossary.dsp_add_endpoint_radio_button_fix_floor}
                          size={'mini'}
                          id={'false'}
                          selected={softFloor}
                          click={selectSoftFloor}
                        />
                      </FormB.Group>
                    </div>
                    <div className='col'>
                      <div className='d-flex gap-3'>
                        {softFloor === 'true' ? (
                          <>
                            <div className='form-group'>
                              <FormikController
                                kind='withouticon'
                                control='input'
                                type='number'
                                label={glossary.dsp_add_endpoint_input_min_floor_price_label}
                                placeholder={
                                  glossary.dsp_add_endpoint_input_min_floor_price_placeholder
                                }
                                name='integrationMinCPM'
                                maxLength={1024}
                              />
                            </div>
                            <div className='form-group'>
                              <FormikController
                                kind='withouticon'
                                control='input'
                                type='number'
                                label={glossary.dsp_add_endpoint_input_max_floor_price_label}
                                placeholder={
                                  glossary.dsp_add_endpoint_input_max_floor_price_placeholder
                                }
                                name='integrationMaxCPM'
                                maxLength={1024}
                                //onChangeText={enpointListDataConfigObj?.max_cpm ?? ''}
                              />
                            </div>
                          </>
                        ) : (
                          <div className='form-group'>
                            <FormikController
                              kind='withouticon'
                              control='input'
                              type='number'
                              label={glossary.dsp_add_endpoint_input_floor_price_label}
                              placeholder={glossary.dsp_add_endpoint_input_floor_price_placeholder}
                              name='integrationCPM'
                              //maxLength={1024}
                              //onChangeText={enpointListData?.floor_price ?? ''}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <div className='form-group'>
                    <label style={{ fontSize: '14px', color: '#a2a2a2' }}>Ad Unit Code</label>
                    <Field
                      type='text'
                      name='integrationAdUnitCode'
                      placeholder={'adUnitCode'}
                      className='text-input disable-field'
                      value={enpointListDataConfigObj?.gam?.adUnitCode ?? ''}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label style={{ fontSize: '14px', color: '#a2a2a2' }}>Network</label>
                    <Field
                      type='text'
                      name='integrationAdUnitCode'
                      placeholder={'adUnitCode'}
                      className='text-input disable-field'
                      value={enpointListDataConfigObj?.gam?.network ?? ''}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-6'>
                  <div className='form-group'>
                    <label style={{ fontSize: '14px', color: '#a2a2a2' }}>GAM Id</label>
                    <Field
                      type='text'
                      name='integrationGAMId'
                      placeholder={'adUnitCode'}
                      className='text-input disable-field'
                      value={enpointListDataConfigObj?.gam?.id ?? ''}
                    />
                  </div>
                </div>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col'>
                      <div className='form-group'>
                        <label style={{ fontSize: '14px', color: '#a2a2a2' }}>Parent</label>
                        <Field
                          type='text'
                          name='integrationGAMId'
                          placeholder={'adUnitCode'}
                          className='text-input disable-field'
                          value={enpointListDataConfigObj?.gam?.parent ?? ''}
                        />
                      </div>
                    </div>
                    <div className='col'>
                      <div className='form-group'>
                        <label style={{ fontSize: '14px', color: '#a2a2a2' }}>Parent Id</label>
                        <Field
                          type='text'
                          name='integrationGAMId'
                          placeholder={'adUnitCode'}
                          className='text-input disable-field'
                          value={enpointListDataConfigObj?.gam?.parentId ?? ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <div className='form-group'>
                    <label style={{ fontSize: '14px', color: '#a2a2a2' }}>GAM URL</label>
                    <p
                      className='text-input h-auto gam-input disable-field'
                      style={{ lineHeight: '20px' }}
                    >
                      {gamParamURL}
                    </p>
                  </div>
                </div>
              </div>
              <GamMacros
                enpointListData={enpointListData}
                gamParamURL={getGamParamURL}
                updateMacros={getUpdateMacros}
              />
            </div>
            <div className={'modal-footer px-3'}>
              <div className='d-flex justify-content-end gap-3'>
                <Button
                  type='button'
                  click={() => {
                    onHide();
                  }}
                  kind={'secondary'}
                  text={glossary.dsp_upadte_rtb_popup_button_close}
                />

                {isSubmitting ? (
                  <Button
                    type='button'
                    click={() => {
                      sEditDetailsDSPModal(true);
                    }}
                    kind={'primary'}
                    text={
                      !openDetailsModal || editDetailsDSPModal
                        ? glossary.updating
                        : glossary.loading
                    }
                  />
                ) : (
                  <>
                    {openDetailsModal && !editDetailsDSPModal && (
                      <Button
                        type='button'
                        click={() => {
                          sEditDetailsDSPModal(true);
                        }}
                        kind={'primary'}
                        text={glossary.dsp_details_rtb_popup_button_edit}
                      />
                    )}
                    {(!openDetailsModal || editDetailsDSPModal) && (
                      <Button type='submit' kind={'primary'} text={'Update'} />
                    )}
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GAMdetails;
