import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import JWT from 'jsonwebtoken';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';

import Input from '../widgets/input';
import Button from '../widgets/button';

import { loginAPI } from '../../services/authentication';
// import { getCustomerInfoAPI } from '../../services/customers';
// import { getTagsAPI } from '../../services/tags';

import bg2 from '../../assets/images/bg-2.png';
import genuinBG from '../../assets/images/bg-genuin.png';
import store from '../../states';
import { UserInfoContext } from '../contexts/userInfoContext';
import { cretivesDomain } from '../helpers/functions';
import { Spinner } from 'react-bootstrap';

interface LoginPasswordForm {
  email: string;
  password: string;
}

const loginPasswordInitialValues = {
  email: '',
  password: '',
};

const loginPasswordInitialErrors: LoginPasswordForm = {
  email: '',
  password: '',
};

export default () => {
  const { push, location } = useHistory<{ email: string }>();

  /* Context API's */
  const { handleInitialState, getUserInfo }: any = useContext(UserInfoContext);

  const [errors, sErrors] = useState<LoginPasswordForm>(loginPasswordInitialErrors);
  const [loginPasswordForm, sLoginPasswordForm] = useState<LoginPasswordForm>(
    loginPasswordInitialValues
  );
  const [spinnerBtn, sSpinnerBtn] = useState(false);

  // Multiple Toast Message Handle Start //
  const [isToastShown, setIsToastShown] = useState(false);

  const handleToast = (msg: string) => {
    if (!isToastShown) {
      toast.error(msg);
      setIsToastShown(true);
    }
  };
  // Multiple Toast Message Handle End //

  const updateLoginPasswordFormValues = (key: string, value: any) => {
    sErrors(loginPasswordInitialErrors);
    const newLoginPasswordForm = { ...loginPasswordForm };
    newLoginPasswordForm[key as keyof typeof loginPasswordInitialValues] = value;
    sLoginPasswordForm(newLoginPasswordForm);
    setIsToastShown(false);
  };

  const validateLoginPasswordForm = () => {
    let valid = true;
    const newErrors = { ...errors };
    if (validator.isEmpty(loginPasswordForm.password)) {
      valid = false;
      newErrors['password'] = glossary.password_input_field_empty_error;
    }
    if (valid) {
      return true;
    } else {
      sErrors(newErrors);
      return false;
    }
  };

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  ReactGA.send({ hitType: 'pageview', page: '/login-password' });

  const initiateLogin = () => {
    ReactGA.event({
      category: 'login',
      action: 'click',
      label: 'final_login_button',
      // email: loginPasswordForm.email
    });
    if (validateLoginPasswordForm())
      if (validateLoginPasswordForm()) {
        const payload = {
          email: loginPasswordForm.email,
          password: loginPasswordForm.password,
        };
        sSpinnerBtn(true);
        loginAPI(payload)
          .then((data: any) => {
            if (data.status) {
              localStorage.setItem('token', data.access_token);
              if (data.access_token) {
                const tokenPayload: any = JWT.decode(data.access_token);
                localStorage.setItem('role_id', tokenPayload?.role);
                if (tokenPayload?.admin) {
                  if (data.first_login) {
                    localStorage.clear();
                    push(`/create-password/${data.reset_token}`, data.access_token);
                  } else {
                    localStorage.setItem('r', 'infy-admin');
                    push('/customers');
                    getUserInfo();
                  }
                } else {
                  if (data.first_login === true) {
                    //console.log("reset tocken", data)
                    localStorage.clear();
                    push(`/create-password/${data.reset_token}`, data.access_token);
                    //location.pathname.startsWith(`/forgot-password/?${data.reset_token}`)
                  } else {
                    localStorage.setItem('r', 'customer');
                    localStorage.setItem('customer_type', data.customer_type);
                    if (data.customer_type === 'demand' || data.customer_type === '') {
                      push('/sources/demand');
                      handleInitialState();
                    } else if (data.customer_type === 'publisher' || data.customer_type === 'spo') {
                      localStorage.setItem('customer_type', data.customer_type);
                      push('/publisher/supply');
                      handleInitialState();
                    } else {
                      localStorage.setItem('customer_type', data.customer_type);
                      push('/my-creatives');
                      handleInitialState();
                    }
                  }
                }
              }
            } else {
              handleToast(data.msg);
              toast.error(data.message);
            }
            sSpinnerBtn(false);
          })
          .catch((error) => {
            toast.error(glossary.service_unavailable);
            sSpinnerBtn(false);
          });
      }
  };

  useEffect(() => {
    if (location.state?.email) {
      const newLoginPasswordForm = { ...loginPasswordForm };
      newLoginPasswordForm['email'] = location.state.email;
      sLoginPasswordForm({ ...newLoginPasswordForm });
    } else {
      push('/login');
    }
  }, []);

  const handleForgotPasswordClick = () => {
    // Use push to navigate to the ForgotPassword route with the state
    push('/forgot-password', { email: location?.state?.email });
  };

  const hasCreatives = cretivesDomain();

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            width: 'fit-content',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 'calc(100vh - 66px)',
          }}
        >
          <div
            className={'card-box'}
            style={{
              margin: '72px',
              rowGap: '16px',
              width: '380px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h3 style={{ fontWeight: 300 }}>
              {hasCreatives ? 'Login with Genuin' : glossary.password_title}
            </h3>
            <p>{glossary.password_title_description}</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                initiateLogin();
              }}
              noValidate
            >
              <div style={{ marginBottom: 16 }}>
                <Input
                  style={{ paddingRight: '40px' }}
                  name={'password'}
                  kind='text'
                  label={glossary.password_input_field_label}
                  change={updateLoginPasswordFormValues}
                  type={'password'}
                  error={errors.password}
                  placeholder={glossary.password_input_field_placeholder}
                  length={100}
                  autoFocus
                />
              </div>
              {spinnerBtn ? (
                <Button kind={'spinner'} text={glossary.loading} size={'full'} />
              ) : (
                <Button
                  type={spinnerBtn ? 'button' : 'submit'}
                  kind={'primary'}
                  text={glossary.password_button}
                  size={'full'}
                />
              )}
            </form>
            <p
              onClick={handleForgotPasswordClick}
              style={{ fontSize: 12, fontWeight: 200, cursor: 'pointer' }}
            >
              {glossary.password_hyperlink}
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundSize: 'cover',
            height: 'calc(100vh - 66px)',
            width: 'calc(100vw - 450px)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${hasCreatives ? genuinBG : bg2})`,
          }}
        />
      </div>
    </>
  );
};
