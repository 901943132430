import ContentLoader from 'react-content-loader';

const TableSkeleton = ({ columns, rows, rowSpacing, tableHeight }: any) => {
  const width = 100 / columns;
  const height = (100 - rowSpacing * (rows - 1)) / rows;

  return (
    <ContentLoader
      viewBox={`0 0 ${100} ${`${height}%`}`}
      backgroundColor='#f0f0f0'
      foregroundColor='#dedede'
      style={{ width: '100%', height: `${tableHeight}px` }}
    >
      {[...Array(columns)].map((_, colIndex) =>
        [...Array(rows)].map((_, rowIndex) => (
          <rect
            key={`col-${colIndex}-row-${rowIndex}`}
            x={`${colIndex * width}%`}
            y={`${rowIndex * (height + rowSpacing)}%`}
            rx='3'
            ry='3'
            width={`${width - 2}%`}
            height={`${height - 2}%`}
          />
        ))
      )}
    </ContentLoader>
  );
};

export default TableSkeleton;
