import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from 'react-datepicker';
import { IoCalendarOutline } from 'react-icons/io5';
import { FaCalendarAlt } from 'react-icons/fa';

interface Props {
  selectedDate(data: any): void;
  selected?: any;
  label?: string;
  time?: boolean;
  kind?: string;
}

const DatePicker = (props: Props) => {
  const newDate = new Date().setHours(0, 0, 0, 0);

  const [startDate, setStartDate] = useState<any>(props?.selected ?? new Date(newDate));

  useEffect(() => {
    if (startDate && startDate) props.selectedDate(startDate);
  }, [startDate]);

  const CustomInput = ({ value, onClick }: any) => (
    <div className='input-group flex-nowrap'>
      <span className='input-group-text'>
        <IoCalendarOutline fontSize={18} />
      </span>
      <input
        className='text-input'
        type='text'
        value={value}
        onClick={onClick}
        readOnly
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
  // <div>
  //   <input
  //     className='text-input'
  //     type='text'
  //     value={value}
  //     onClick={onClick}
  //     readOnly
  //     style={{ cursor: 'pointer' }}
  //   />
  //   <div className='calendar-icon' onClick={onClick}>
  //     <IoCalendarOutline className='svg-icon' fontSize={20} />
  //   </div>
  // </div>;

  return (
    <>
      <div className='react-date-picker'>
        <label className='label d-block'>{props.label ?? 'Start Date'}</label>
        <ReactDatePicker
          className='text-input'
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
          dateFormat={!props.time ? 'dd/MM/yyyy' : 'dd/MM/yyyy, h:mm aa'}
          onKeyDown={(e: any) => e.preventDefault()}
          minDate={new Date()}
          showTimeSelect={props.time === true ?? false}
          timeFormat='h:mm aa'
          timeIntervals={60}
          popperPlacement='auto'
          customInput={<CustomInput />}
        />
      </div>
    </>
  );
};

export default DatePicker;
