import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
// import { Accordion, Card } from 'react-bootstrap';

import { HiOutlineBell } from 'react-icons/hi';
import { IoMdHelpCircleOutline } from 'react-icons/io';

import noNotifications from '../../assets/images/no-notifications.svg';
import noNotifications1 from '../../assets/images/no-notifications1.svg';

import {
  getNotificationsAPI,
  viewNotificationAPI,
  viewNotificationsAPI,
} from '../../services/authentication';
import HelpSectionFAQ from '../components/help/faq';
import { cretivesDomain } from '../helpers/functions';

const markAsRead = ({ id, api }: any) => {
  const payload = {
    id,
  };
  viewNotificationAPI(payload).then(() => {
    api();
  });
};

const markAllAsRead = (api: any) => {
  viewNotificationsAPI().then(() => api());
};

const NotificationCard = ({ data, api, closePanel }: any) => {
  const { push } = useHistory();
  return (
    <>
      {data ? (
        <div
          className={'barside-panel-card'}
          style={{
            backgroundColor: data.is_viewed ? 'rgba(0,0,0,0.03)' : '',
          }}
          onClick={() => {
            markAsRead({ id: data.id, api });
            push(data.slug);
            closePanel();
          }}
        >
          <div className='position-relative'>
            <div className='row'>
              <div className='col-7'>
                <h6>{data.title}</h6>
                <p style={{ wordBreak: 'break-word', width: '110px' }}>{data.description}</p>
              </div>
              <div className='col align-items-center me-lg-1'>
                <h6 className={'barside-panel-card-time'}>{data.created_on}</h6>
              </div>
            </div>
            {!data.is_viewed && <div className={'barside-panel-card-status'} />}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default () => {
  const location = useLocation();

  const [display, setDisplay] = useState(false);
  const [panel, setPanel] = useState(false);
  const [helpPanel, setHelpPanel] = useState(false);
  const [notifications, sNotifications] = useState<any>([0]);
  const [notificationCount, sNotificationCount] = useState<number>(0);
  const [hasUnread, sHasUnread] = useState(false);

  const openPanel = () => {
    setHelpPanel(false);
    setPanel(panel ? false : true);
  };

  const openHelpPanel = () => {
    setPanel(false);
    setHelpPanel(helpPanel ? false : true);
  };

  const toggleContextMenu = (e: Event) => {
    const overlay1 = document.getElementById('div-overlay-1');
    const overlay2 = document.getElementById('div-overlay-2');
    const target = e.target;
    if (target === overlay1 || target === overlay2) {
      setPanel(false);
      setHelpPanel(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [location.pathname]);

  const getNotifications = () => {
    sNotifications([0]);
    sNotificationCount(0);
    getNotificationsAPI({ page: 1 })
      .then((data: any) => {
        sNotifications(data?.data);
        sNotificationCount(data?.data.filter((n: any) => !n.is_viewed)?.length);
      })
      .catch(() => {
        //
      });
  };

  useEffect(() => {
    if (notifications?.length) {
      const unread = notifications?.find((x: any) => !x?.is_viewed);
      if (unread) sHasUnread(true);
      else sHasUnread(false);
    }
  }, [notifications]);

  const isAllViewed = notifications?.every((x: any) => x?.is_viewed);

  useEffect(() => {
    if (
      display &&
      (localStorage.getItem('r') === 'customer' || localStorage.getItem('r') === 'infy-admin')
    ) {
      getNotifications();
    }
    document.addEventListener('click', toggleContextMenu, false);
    return () => document.removeEventListener('click', toggleContextMenu, false);
  }, [display]);

  const hasCreatives = cretivesDomain();

  return (
    <>
      {display && (
        <>
          <div style={{ right: panel || helpPanel ? 300 : 0 }} className={'barside'}>
            <div className={'barside-icons'}>
              <div className={'position-relative'}>
                <HiOutlineBell
                  className={'tour-notif svg-icon'}
                  id={'barside-icon-1'}
                  title={'Notifications'}
                  fontSize={25}
                  onClick={openPanel}
                  style={{ cursor: 'pointer' }}
                />
                {hasUnread && notificationCount > 0 && (
                  <div
                    className={'barside-icons-count'}
                    style={{
                      height: notificationCount < 99 ? 20 : 10,
                      width: notificationCount < 99 ? 20 : 10,
                      top: notificationCount < 99 ? -10 : 0,
                      left: notificationCount < 99 ? -10 : 0,
                    }}
                  >
                    <div style={{ margin: 'auto' }}>
                      {notificationCount < 99 ? notificationCount : ''}
                    </div>
                  </div>
                )}
              </div>
              <div>
                <IoMdHelpCircleOutline
                  className='tour-help svg-icon'
                  id={'barside-icon-2'}
                  title={'Help'}
                  fontSize={25}
                  onClick={openHelpPanel}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
          </div>
          {panel && (
            <>
              <div id={'div-overlay-1'} className={'overlay'} style={{ zIndex: 2 }} />
              <div id={'barside-panel'} className={'barside-panel'}>
                <div className='align-items-center d-flex justify-content-between'>
                  <h4>Notifications</h4>
                  <span
                    className={isAllViewed ? 'barside-panel-disable' : 'barside-panel-small'}
                    onClick={() => {
                      markAllAsRead(getNotifications);
                    }}
                  >
                    Mark All as Read
                  </span>
                </div>
                <div className='mt-3'>
                  {notifications &&
                    notifications.length > 0 &&
                    notifications.map((notif: any) => (
                      <>
                        <NotificationCard
                          data={notif}
                          api={getNotifications}
                          closePanel={openPanel}
                        />
                      </>
                    ))}
                  {notifications && notifications.length < 1 && (
                    <>
                      <div className='d-flex justify-content-center'>
                        <img
                          src={hasCreatives ? noNotifications1 : noNotifications}
                          width={210}
                          height={135}
                        />
                      </div>
                      <br />
                      <h5 style={{ textAlign: 'center' }}>No Notifications</h5>
                    </>
                  )}
                </div>
                <div />
              </div>
            </>
          )}
          {helpPanel && (
            <>
              <div id={'div-overlay-2'} className={'overlay'} style={{ zIndex: 2 }} />
              <div id={'barside-panel'} className={'barside-panel'}>
                <div className='align-items-center d-flex justify-content-between px-2 mb-3'>
                  <h4>Help</h4>
                </div>
                <div>
                  <HelpSectionFAQ />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
