import Spinner from 'react-bootstrap/Spinner';

interface Props {
  icon?: string;
  text: string;
  size?: 'full';
  kind?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'success'
    | 'link'
    | 'disabled'
    | 'disabled-secondary'
    | 'spinner';
  css?: {
    height?: string;
    padding?: string;
    color?: string;
    border?: string;
    margin?: string;
    background?: string;
    minWidth?: any;
    maxWidth?: any;
    borderTopLeftRadius?: number;
    borderBottomLeftRadius?: number;
    borderTopRightRadius?: number;
    borderBottomRightRadius?: number;
  };
  customClass?: string;
  type?: 'submit' | 'button';
  click?(): void;
}

export default (props: Props) => {
  const { icon, text, size, kind, css, type, click, customClass } = props;

  let className = 'button';

  if (size === 'full') className += ' button-full';

  switch (kind) {
    case 'primary':
      className += ' button-primary';
      break;
    case 'secondary':
      className += ' button-secondary';
      break;
    case 'error':
      className += ' button-error';
      break;
    case 'success':
      className += ' button-success';
      break;
    case 'link':
      className += ' button-link';
      break;
    case 'disabled':
      className += ' button-disabled';
      break;
    case 'disabled-secondary':
      className += ' button-disabled-secondary';
      break;
    case 'spinner':
      className += ' button button-loading';
      break;
    default:
      break;
  }

  return (
    <button
      type={type}
      style={css}
      onClick={click}
      className={className + ' ' + customClass}
      disabled={
        kind === 'disabled' || kind === 'disabled-secondary' || kind === 'spinner' ? true : false
      }
    >
      {icon && (
        <img src={icon} width={16} height={16} style={{ marginRight: 4, marginBottom: 4 }} />
      )}
      {kind === 'spinner' && (
        <Spinner
          className='me-2'
          as='span'
          animation='grow'
          size='sm'
          role='status'
          aria-hidden='true'
        />
      )}
      {text}
    </button>
  );
};
