import { useState } from 'react';
import { BrowserRouter, NavLink, Route, Switch } from 'react-router-dom';
import SourcesPage from './Sources';
import PartnersPage from './Partners';

const PublisherDemand = (props: any) => {

  const [expandStatus, sExpandStatus] = useState<any>(true);
  const [searchToggle, sSearchToggle] = useState<any>(true);

  const getExpandSidebar = (expand: boolean) => {
    sExpandStatus(expand);
  };
  const getSearchToggle = (expand: boolean) => {
    sSearchToggle(expand);
  };

  return (
    <>
      <div
        className={
          expandStatus
            ? 'publisher-spo content d-flex'
            : 'publisher-spo content d-flex open-sidebar'
        }
      >
          <BrowserRouter>
            <div className={searchToggle ? 'ssp-deals-sidebar-tabs' : 'ssp-deals-sidebar-tabs  d-none'}>
              <NavLink to='/publisher/demand/sources' activeClassName='active'>
                  Sources
              </NavLink>
              <NavLink to='/publisher/demand/partners' activeClassName='active'>
                  Partners
              </NavLink>
            </div>
            <Switch>
              <Route path='/publisher/demand/sources'>
                <SourcesPage sExpandStatusProp={getExpandSidebar} sSearchToggleProp={getSearchToggle}/>
              </Route>
              <Route path='/publisher/demand/partners'>
                <PartnersPage sExpandStatusProp={getExpandSidebar} sSearchToggleProp={getSearchToggle}/>
              </Route>
            </Switch>
          </BrowserRouter>
      </div>
    </>
  );
};

export default PublisherDemand;
