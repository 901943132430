import { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
// import { useIntercom } from 'react-use-intercom';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';
// import JWT from 'jsonwebtoken';
// import LogRocket from 'logrocket';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import Tile from '../../widgets/tile';
import Line from '../../charts/line';
import MsaSign from '../../modals/msa-sign';
import InvitePublisherModal from '../../modals/partner-invite';

// import DateRangePickerComponent from '../../comman/datepicker/DateRangePicker';

import add from '../../../assets/icons/add.svg';

import Reports from '../../helpers/reports';
import { tag as tagTiles } from '../../helpers/tiles';
import store from '../../../states';

import { getEndpointReportAPI } from '../../../services/endpoint';
import { getCustomerListSPOAPI, getPartnerReportAPI } from '../../../services/spo';
import { UserInfoContext } from '../../contexts/userInfoContext';
import SPOSidebar from '../../sidebar/SPO';
import SPOInventories from './Inventories';
// import BoxSkeleton from '../../skeleton/box';
import XYChart from '../../loaders/chart';
import Box from '../../loaders/box';
import PartnerOverview from '../../components/overview/partner';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';
import InvitedComponent from '../../components/spo/invited/invited';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';
// import moment from 'moment';

const SPOPublisher = () => {
  const { getPublishersSpoListAPI }: any = useAuthentication();
  const { spoPartner, sSpoPartner, dateRangeSPOPublisher, sDateRangeSPOPublisher }: any =
    consumerData();

  const { pathname } = useLocation();

  const [selectedTile, sSelectedTile] = useState(0);
  const [sidebar, sSidebar] = useState('collapsed');
  const [selectedCustomer, sSelectedCustomer] = useState('');
  const [customer, sCustomer] = useState(false);
  const [singleTileData, sSingleTileData] = useState<any>([]);
  const [lineData, sLineData] = useState<any>(null);
  const [attribute /* , sAttribute */] = useState<any>(null);
  const [rawLineData /* , sRawLineData */] = useState<any>([]);
  const [refinedLineData, sRefinedLineData] = useState<any>([]);
  const [empty, sEmpty] = useState(false);
  //const [spoPartner, sSpoPartner] = useState<any>([]);
  const [customerDetails, sCustomerDetails] = useState<any>(null);
  const [pDatatables, sPDatatables] = useState([]);
  const [random, sRandom] = useState(Math.random());
  const [showMsaSign, sShowMsaSign] = useState(false);
  //const [skeleton, sSkeleton] = useState(true);
  const [isCardLoading, sIsCardLoading] = useState(true);
  const [allData, setAllData] = useState(false);

  const { msaDetails }: any = useContext(UserInfoContext);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const history = useHistory();

  /* date picker start */
  // const [selectionRange, setSelectionRange] = useState({
  //   startDate: new Date(new Date().setDate(new Date().getDate() - 6)), // Number for days before
  //   endDate: new Date(),
  //   key: 'selection',
  // });
  const [currentDateSelection, sCurrentDateSelection] = useState<any>(dateRangeSPOPublisher);

  const GetSelectionRangeProps = (data: any) => {
    //setSelectionRange(data);
    sDateRangeSPOPublisher(data);
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  /* add class while expand sidebar */
  const setSidebar = (size: string) => {
    sSidebar(size);
  };

  /* overview data above chart */
  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  /* select card */
  const selectedCard = (id: string) => {
    if (selectedCustomer !== id) {
      history.push(`/sources/publishers/${id}`);
      sPDatatables([]);
      sSingleTileData([]);
      sRefinedLineData([]);
      sLineData([]);
      sSelectedCustomer(id);
      getCustomerDetails(id);
    }
  };

  useEffect(() => {
    if (selectedCustomer !== '') {
      sLineData(singleTileData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
    }
  }, [selectedTile, random]);

  /* Get costomer data */
  const getCustomerDetails = (id: string) => {
    const payload = {
      id,
      start: convert(dateRangeSPOPublisher.startDate) || '',
      end: convert(dateRangeSPOPublisher.endDate) || '',
      time_zone: 'Asia/Kolkata',
    };
    sCurrentDateSelection(dateRangeSPOPublisher);
    setAllData(false);
    if (dateRangeSPOPublisher.startDate !== dateRangeSPOPublisher.endDate) {
      getPartnerReportAPI(payload)
        .then((data: any) => {
          if (data) {
            const tileData = tagTiles(data.total);
            sSingleTileData(tileData);
            sSelectedTile(0);
            sRandom(Math.random());
            sPDatatables(spoPartner.find((x: any) => x.id === id)?.tables ?? []);
            setAllData(true);
          }
        })
        .catch(() => {
          // toast.error('Service unavailable, Check back after sometime.');
        });
    }
  };
  /* Get costomer data */

  /***** Handle on scroll API's start *****/
  const [isEmpty, sIsEmpty] = useState(false);
  const [currentPage, sCurrentPage] = useState(0);
  const [hasMore, sHasMore] = useState(true);

  const [params, setParams] = useState({
    page: 0,
    query: '',
    sortBy: {
      id: 'earning',
      desc: true,
    },
  });
  const [paramsStatus, setParamsStatus] = useState(false);

  useEffect(() => {
    const endpointid = pathname.split('/sources/publishers/')[1];
    if (spoPartner.length === 0 || params.query === '') {
      getEndpointListInfo(params, endpointid);
    } else {
      sIsCardLoading(false);
    }
    if (paramsStatus === true) {
      getEndpointListInfo(params, endpointid);
    }
  }, [params.page, params.query, params.sortBy, paramsStatus]);

  const handleParamsChange = (page: number, query: string, sortBy: any, status: boolean) => {
    if (
      page !== params.page ||
      query !== params.query ||
      sortBy.id !== params.sortBy.id ||
      sortBy.desc !== params.sortBy.desc
    ) {
      setParams({ page, query, sortBy });
      setParamsStatus(status);
    }
    if (params.sortBy !== sortBy || params.query !== query) {
      sSpoPartner([]);
    }
  };

  /***** Handle on scroll API's end *****/
  const getEndpointListInfo = (params: any, _id: any) => {
    const payload = {
      page: params.page,
      page_size: 500,
      query: params.query,
      sort_by: {
        sortBy: [params.sortBy],
      },
    };
    sIsCardLoading(true);
    setAllData(false);
    getPublishersSpoListAPI(payload)
      .then((data: any) => {
        if (data) {
          sCurrentPage(params.page);
          sIsEmpty(params.page === 0 && data.data.length === 0 ? true : false);
          if (params.page > 0) {
            if (data.data.length !== 0) {
              sSpoPartner([...spoPartner, ...data.data]);
            } else {
              sSpoPartner(spoPartner);
              sHasMore(false);
              selectedCard(params.page === 0 ? spoPartner[0].id : selectedCustomer);
            }
            sIsCardLoading(false);
          } else {
            if (params.page === 0 && params.query.length < 1 && data.data.length < 1) sEmpty(true);
            sSpoPartner(data.data);
            sPDatatables(data.data.tables);
            //compare id when type id in param
            if (_id) {
              selectedCard(data.data.find((x: any) => x.id == _id)?.id);
            } else {
              //selectedCard(data.data[0].id);
            }
            sIsCardLoading(false);
          }
          sCustomer(false);
          setAllData(true);
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  /***** UPDATE ENDPOINT LIST BY ADD/UPDATE ENDPOINT START *****/
  const handleEndpointLists = (endpointObject: any, kind: string) => {
    const newEndpointObject = { ...endpointObject.success };
    if (kind === 'add') {
      //const newArr = [newEndpointObject, ...endPointData];
      const newArr = [...Object.values(newEndpointObject), ...spoPartner];
      sSpoPartner(newArr);
      selectedCard(newEndpointObject[0].id);
      sCustomer(false);
    }
  };

  useEffect(() => {
    if (
      selectedCustomer &&
      (convert(currentDateSelection.startDate) !== convert(dateRangeSPOPublisher.startDate) ||
        convert(currentDateSelection.endDate) !== convert(dateRangeSPOPublisher.endDate))
    ) {
      getCustomerDetails(selectedCustomer);
    }
  }, [dateRangeSPOPublisher]);

  useEffect(() => {
    const getCustomer: any = spoPartner.find((x: any) => x.id === selectedCustomer);
    sCustomerDetails(getCustomer);
  }, [selectedCustomer]);

  /* Chart sata start */
  useEffect(() => {
    const bottom: any = [];
    rawLineData.map((_: any, i: any) => {
      return bottom.push({ date: _.date, [attribute]: _[attribute] });
    });
    sRefinedLineData({
      labels: bottom.map((a: any) => a.date),
      values: bottom.map((a: any) => a[attribute]),
    });
  }, [rawLineData, attribute]);

  useEffect(() => {
    if (lineData && lineData.length) {
      let newlabels: any = [];
      newlabels = lineData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = lineData.map((_: any) => _[Object.keys(_)[0]]);
      /* replace new value array */
      sRefinedLineData({ labels: newValues, values: newlabels });
      //sRefinedLineData({ labels: newlabels, values: newValues });
    } else {
      sRefinedLineData({ labels: [], values: [] });
    }
  }, [lineData]);
  /* Chart sata end */

  const createEndpoint = () => {
    ReactGA.event('add_new_button', {
      category: 'end_point_header_biding',
      action: 'click',
      label: '+_add_new_button_click',
    });
    if (msaDetails.status) {
      sCustomer(true);
    }
    if (!msaDetails.generate_button) {
      sShowMsaSign(true);
    }
    if (!msaDetails.status && msaDetails.generate_button) {
      toast.success('MSA was sent to your email. Please check your mailbox.');
    }
  };

  /* selected while you reload page */
  const [selectedTabPublisher, setSelectedTabPublisher] = useState(
    localStorage.getItem('selectedTabPublisher') ?? 'performance'
  );

  const handlePublisherTabs = (key: any) => {
    setSelectedTabPublisher(key);
  };

  useEffect(() => {
    localStorage.setItem('selectedTabPublisher', selectedTabPublisher);
  }, [selectedTabPublisher]);
  /* selected while you reload page */

  const handleUpdatePublisher = (customerObj: any, kind: string, req?: string) => {
    if (kind === 'add') {
      const newArray = [...spoPartner];
      const index = newArray.findIndex((item) => item.id === customerObj.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...customerObj };
      }
      sSpoPartner(newArray);
      if (req === 'approve' && customerObj.assigned_status !== 'registered') {
        //sSection('1');
        sSelectedCustomer(customerObj.id);
        sCustomerDetails(customerObj);
      }
    }

    if (kind === 'update') {
      const newArray = [...spoPartner];
      const index = newArray.findIndex((item) => item.id === customerObj.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...customerObj };
      }
      sSpoPartner(newArray);
      if (req === 'reject' && customerObj.assigned_status !== 'pending') {
        //sSection('1');
        sSelectedCustomer(customerObj.id);
        sCustomerDetails(customerObj);
      }
    }

    // console.log('customerObj', customerObj);

    const newArray = [...spoPartner];
    const index = newArray.findIndex((item) => item.id === customerObj.id);
    if (index !== -1) {
      newArray[index] = { ...newArray[index], ...customerObj };
      // newArray[index] = { ...newArray[index], assigned_status: customerObj.assigned_status };
    }
    sSpoPartner(newArray);
  };

  return (
    <>
      {/* Invite Publisher modal*/}
      <InvitePublisherModal
        show={customer}
        getEndPointList={handleEndpointLists}
        onHide={() => {
          sCustomer(false);
        }}
      />
      {/* MSA modal*/}
      <MsaSign
        show={showMsaSign}
        onHide={() => {
          sShowMsaSign(false);
        }}
      />
      <div className={'content d-flex'}>
        <SPOSidebar
          title={'Publishers'}
          kind={'SPO'}
          size={'collapsed'}
          action={{
            icon: add,
            text: 'Invite',
            className: 'tour-addnew-dsp',
            click: createEndpoint,
          }}
          overviewAction={{
            text: glossary.overview_button,
            click: () => {
              sSelectedCustomer('');
              history.push('/sources/publishers');
            },
            isOverview: selectedCustomer === '' ? true : false,
          }}
          card={'expanded'}
          items={spoPartner}
          sidebar={setSidebar}
          info={selectedCard}
          getTags={(updated: any) => {
            //handleUpdatePublisher(updated);
            handleUpdatePublisher(updated, '');
            selectedCard(selectedCustomer ?? 0);
          }}
          empty={isEmpty}
          selected={selectedCustomer}
          currentPage={currentPage}
          loading={isCardLoading}
          hasMore={hasMore}
          params={params}
          handleParams={handleParamsChange}
          expandSidebar={() => null}
          searchToggle={() => null}
          allData={true}
        />
        <div className='view-section'>
          {selectedCustomer === '' && spoPartner ? (
            <PartnerOverview empty={empty} />
          ) : (
            <>
              {customerDetails?.assigned_status === 'registered' ||
              customerDetails?.assigned_status === 'reject' ||
              customerDetails?.assigned_status === 'pending' ? (
                <>
                  <InvitedComponent
                    selectedCusomer={selectedCustomer}
                    partnerDetails={customerDetails}
                    glossary={glossary}
                    getCustomers={handleUpdatePublisher}
                  />
                </>
              ) : (
                <Tab.Container
                  defaultActiveKey={selectedTabPublisher}
                  onSelect={(key: any) => handlePublisherTabs(key)}
                >
                  <div className='row align-items-center'>
                    <div className='col-4'>
                      <div className='d-flex gap-3 align-items-center'>
                        {selectedCustomer !== '' && customerDetails && (
                          <h2 className='text-truncate'>{customerDetails.company_name}</h2>
                        )}
                      </div>
                    </div>

                    <div className='col-4'>
                      <Nav variant='pills'>
                        <Nav.Item>
                          <Nav.Link eventKey='performance'>Performance</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey='inventories'>Inventories</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    {selectedCustomer !== '' &&
                      customerDetails &&
                      selectedTabPublisher === 'performance' && (
                        <div className='col-4'>
                          <div
                            className={'calendar__wrap tour-calendar-wrap'}
                            style={{ position: 'relative' }}
                          >
                            <div className={'calendar__wrap tour-calendar-wrap'}>
                              <DateRangeFilter
                                onChange={GetSelectionRangeProps}
                                selectionRange={dateRangeSPOPublisher}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <Tab.Content>
                        <Tab.Pane eventKey='performance'>
                          {selectedCustomer !== '' && customerDetails && (
                            <>
                              <div
                                className={'row'}
                                style={{
                                  marginTop: '53px',
                                }}
                              >
                                {singleTileData.map((tile: any, index: any) => {
                                  return (
                                    <div key={index} className={'col-auto'}>
                                      {refinedLineData.labels < 1 &&
                                      refinedLineData.values < 1 ? null : (
                                        <Tile
                                          id={tile.id}
                                          selected={selectedTile}
                                          click={selectTile}
                                          label={tile.label}
                                          value={tile.value}
                                          info={tile.info}
                                          arrow={tile.arrow}
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                              {refinedLineData.labels < 1 && refinedLineData.values < 1 ? (
                                <>
                                  <div className={'row mt-0'}>
                                    <div className='col-6'>
                                      <div className='d-flex gap-4'>
                                        {[1, 2, 3, 4].map((index) => {
                                          return <Box key={index} />;
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='row mt-4'>
                                    <div className='col w-100 m-auto'>
                                      <XYChart />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className={'row  mt-4'}
                                  style={{
                                    height: '320px',
                                  }}
                                >
                                  <Line
                                    label={'Impressions'}
                                    data={refinedLineData}
                                    attribute={attribute}
                                  />
                                </div>
                              )}
                              <div className={'row mt-4 report-table'}>
                                <Reports
                                  id={selectedCustomer}
                                  start={dateRangeSPOPublisher.startDate}
                                  end={dateRangeSPOPublisher.endDate}
                                  tabs={pDatatables}
                                  random={random}
                                  entityType={'partner'}
                                />
                              </div>
                            </>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey='inventories'>
                          <SPOInventories
                            selectedCardId={selectedCustomer}
                            type={'spo-inventories'}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SPOPublisher;
