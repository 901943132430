import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import store from '../../states';
import Button from '../widgets/button';

export default ({ api, onHide, show, status }: any) => {
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Header>
        <h2 style={{ margin: 10 }}>{glossary.ssp_tag_activity_popup_title}</h2>
      </Modal.Header>
      <Modal.Body style={{ padding: '40px' }}>
        {glossary.ssp_tag_activity_popup_instruction}
      </Modal.Body>
      <Modal.Footer>
        <Button
          kind={'secondary'}
          text={glossary.ssp_tag_activity_popup_button_cancel}
          click={() => {
            onHide();
          }}
        />
        <Button
          kind={'primary'}
          text={glossary.ssp_tag_activity_popup_button_pause}
          click={() => {
            api();
            onHide();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
