import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { setAdsSubdomainAPI } from '../../../services/endpoint';
import Button from '../../widgets/button';
import { GoLinkExternal } from 'react-icons/go';
import { useContext, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { UserInfoContext } from '../../contexts/userInfoContext';
import { MdInfoOutline } from 'react-icons/md';
import { BsCheckLg } from 'react-icons/bs';

interface Props {
  onHide?: any;
}

const CampaignBuild = (props: Props) => {
  const { customerInfo }: any = useContext(UserInfoContext);
  const { onHide } = props;

  const [domain, setDomain] = useState(false);
  const [subDomain, setSubDomain] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, sError] = useState('');
  const [inputValue, sInputValue] = useState<any>();

  const debouncedHandleSubdamain = debounce(handleSubdomain, 1000);

  function handleSubdomain(event: any) {
    const { value } = event.target;
    const regex = /^[a-zA-Z0-9]+$/;
    sInputValue(value.length);

    if (!value) {
      // If the subdomain is empty, show an error message
      sError('Subdomain cannot be empty.');
      return;
    }

    if (!regex.test(value)) {
      // If the subdomain contains special characters, show an error message
      sError('Invalid subdomain. Special characters are not allowed.');
      return;
    }
    // If the subdomain is valid, clear any existing error message
    sError('');

    setSubDomain(value);
    const payload = {
      ads_domain: value,
    };
    setLoader(true);
    setAdsSubdomainAPI(payload)
      .then((data: any) => {
        if (data.status) {
          //toast.success(data.message);
          setDomain(true);
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  const BASE_URL = process.env.REACT_APP_API_URL ? 'ads.infy.tv' : 'sbads.infy.tv';
  // const BASE_URL = 'localhost:3000';

  return (
    <>
      <div
        className={'modal-body p-3'}
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto',
        }}
      >
        <div className='align-items-center d-flex form-group'>
          <div className='position-relative'>
            <input
              className='form-control w-auto'
              type='text'
              name={'subDomain'}
              placeholder={''}
              onChange={(e: any) => debouncedHandleSubdamain(e)}
              defaultValue={customerInfo?.rtb_config?.ads_domain ?? ''}
              style={{ paddingRight: '30px' }}
            />
            <span
              style={{
                position: 'absolute',
                top: '6px',
                right: '5px',
              }}
            >
              {domain && inputValue > 0 && <BsCheckLg fontSize={24} color='green' />}
            </span>
          </div>
          <span className='mx-1 fs-6'>{BASE_URL}</span>
          <>
            {inputValue !== 0 && (
              <>
                {loader ? (
                  <Spinner
                    animation='border'
                    style={{ color: '#731780', width: '25px', height: '25px' }}
                  />
                ) : (
                  <>
                    {((domain && subDomain) || customerInfo?.rtb_config?.ads_domain) && (
                      <a
                        href={`http://${
                          subDomain || customerInfo?.rtb_config?.ads_domain
                        }.${BASE_URL}?redirect=true`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <GoLinkExternal
                          className='svg-icon flex-shrink-0'
                          fontSize={20}
                          title='Redirect URL'
                        />
                      </a>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>
        {error && <p className='small text-danger'>{error}</p>}
        <div className='mt-3 d-flex'>
          <MdInfoOutline fontSize={20} className='flex-shrink-0' /> &nbsp;
          <p>
            Enter the desired domain prefix for your new link. A domain prefix is a unique
            identifier that will be appended to the Infy Ads domain to create your customized link.
            For example, if your domain prefix is <b className='fw-lighter'>mycompany</b>, your new
            link will be &nbsp;
            <b className='fw-lighter'>mycompany.infytv.ads.tv</b>. Double-check the domain prefix
            you entered to ensure it accurately represents your brand
          </p>
        </div>
      </div>
      <div className={'modal-footer px-3'}>
        <div className='d-flex justify-content-end gap-3'>
          <Button
            type='button'
            kind={'secondary'}
            text={'Close'}
            click={() => {
              onHide();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CampaignBuild;
