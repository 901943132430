import { useContext, useState } from 'react';
import ReactJoyride, { TooltipRenderProps } from 'react-joyride';
import Button from '../../widgets/button';
import { updateTourAPI } from '../../../services/tags';
import { UserInfoContext } from '../../contexts/userInfoContext';

const TourAfterSubscription = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
}: TooltipRenderProps) => (
  <div {...tooltipProps} style={{ background: 'white', padding: 24, borderRadius: 5 }}>
    <p style={{ textAlign: 'left', fontWeight: 200, fontSize: 16 }}>{step.title}</p>
    <br />
    <p style={{ textAlign: 'left', maxWidth: '500px' }}>{step.content}</p>
    <br />
    <br />
    <div className='d-flex justify-content-between'>
      <div className='d-flex gap-3 align-items-center'>
        <div {...skipProps}>
          <Button kind={'secondary'} text={'Skip'} />
        </div>
        {/* <div>
          <a
            href='https://help.infy.tv/en/collections/3796998-faq'
            target={'_blank'}
            rel='noreferrer'
            className='text-primary fw-lighter'
          >
            Know More
          </a>
        </div> */}
      </div>
      <div className='d-flex'>
        {index > 0 && (
          <div {...backProps}>
            <Button kind={'link'} text={'Previous'} />
          </div>
        )}
        {continuous && index !== 7 && (
          <div {...primaryProps}>
            <Button kind={'primary'} text={'Next'} />
          </div>
        )}
        {continuous && index === 7 && (
          <div {...closeProps}>
            <Button kind={'primary'} text={"Let's Go"} />
          </div>
        )}
        {!continuous && (
          <div {...closeProps}>
            <Button kind={'primary'} text={'Close'} />
          </div>
        )}
      </div>
    </div>
  </div>
);

interface Props {
  tourDoneProps?: any;
  isSubscribe?: any;
  loader?: any;
  steps?: any;
}

const CreativeTour = (props: Props) => {
  const { isSubscribe, loader, steps, tourDoneProps } = props;

  // Context API
  const { setUserInfo, userInfo }: any = useContext(UserInfoContext);

  const [tourDone, sTourDone] = useState('');

  if (!userInfo?.user?.tour) {
    // If it's empty or undefined, add a class to the body
    document.body.classList.add('tour-empty');
  } else {
    // If it's not empty, remove the class from the body
    document.body.classList.remove('tour-empty');
  }

  const [creativeAfterSubscriptionSteps] = useState<any>([
    {
      target: '.tour-mycreatives',
      title: 'Manage Your Creatives',
      content:
        'You can use this section to review your creative content along with real-time reporting data.',
      // disableBeacon: true,
    },
    {
      target: '.tour-video-library',
      title: 'Manage Your Videos & Social Profiles',
      content:
        'You can effortlessly import videos from your diverse social media accounts, including Instagram, TikTok and YouTube. Additionally, you have complete control over managing all your videos, allowing you to customize CTA Text & Link as desired.',
    },
    {
      target: '.tour-brand-config',
      title: 'Brand Config',
      content: 'Here, easily configure the logo and name of both the Advertiser and Agency.',
    },
    {
      target: '.tour-settings',
      title: 'Manage Profile',
      content:
        'Click on settings to manage your account, company and financial settings and Logout.',
      placement: 'bottom',
    },
    {
      target: '.tour-addnew-creative',
      title: 'Add New Creative',
      content: `Begin by creating a new creative, and once it's successfully created, you'll be able to preview your reel.`,
      placement: 'right',
    },
    {
      target: '.overview-tiles',
      title: 'Overview',
      content: 'You can check Impressions, Average CPM, Spending of your source.',
    },
    // {
    //   target: '.tour-chart',
    //   title: 'Impression',
    //   content: 'Here you can get performace visualisation of your source.',
    // },
    {
      target: '.tour-notif',
      title: 'Notification',
      content: 'Check your notification regularly for updates on your platform.',
      placement: 'left',
    },
    {
      target: '.tour-help',
      title: 'Help',
      content:
        'Get instant assistance with all your queries by reaching out to our dedicated support team.',
      placement: 'left',
    },
  ]);

  const handleTourCallback = (data: any) => {
    const { action } = data;
    if (action === 'close' || action === 'skip') {
      updateTourAPI({ tour: 'done' })
        .then((data: any) => {
          setUserInfo((prev: any) => ({
            ...prev,
            user: {
              ...prev.user,
              tour: 'done',
            },
          }));
          sTourDone(data.status);

          //Intercom
          // if (data.status) {
          //   const token = localStorage.getItem('token') || '';
          //   const decoded = JWT.decode(token, { complete: true });

          //   // @ts-ignore
          //   const user_id = decoded?.payload?.user_id;
          //   // @ts-ignore
          //   const customer_id = decoded?.payload?.company_id;
          //   // @ts-ignore
          //   const email = decoded?.payload?.identity;
          //   // @ts-ignore
          //   const userName = JSON.parse(localStorage?.getItem('user'))?.firstName ?? '';
          //   bootWithProps({ user_id, customer_id, email, userName });
          // }
        })
        .catch(() => {
          //
        });
    }
  };

  props.tourDoneProps(tourDone);

  return (
    <>
      {isSubscribe && loader && steps && (
        <ReactJoyride
          callback={handleTourCallback}
          continuous={true}
          steps={creativeAfterSubscriptionSteps}
          spotlightClicks={true}
          tooltipComponent={TourAfterSubscription}
          showSkipButton={true}
          disableScrolling={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          run={isSubscribe ? true : false}
        />
      )}
    </>
  );
};

export default CreativeTour;
