import { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { toast } from 'react-toastify';

import ReactGA from 'react-ga4';
import JWT from 'jsonwebtoken';
import LogRocket from 'logrocket';

import Tile from '../../widgets/tile';
import Pill from '../../widgets/pill';
import Button from '../../widgets/button';
import Line from '../../charts/line';

import XYChart from '../../loaders/chart';
import Box from '../../loaders/box';

import Customer from '../../modals/customer';
import InviteCustomer from '../../modals/customer-invite';
import Reject from '../../modals/reject';

import { tag as tagTiles } from '../../helpers/tiles';
import Reports from '../../helpers/reports';
import store from '../../../states';
import AdminSidebar from '../../sidebar/Admin';
import PlatformCost from '../../tables/PlatformCost';
import DateRangeFilter from '../../comman/datepicker/NewDatePicker';
import CustomerDetails from '../../components/customerDetails/details';
import CustomerLogin from '../../components/customerDetails/login';
import MarginandPaymentTerms from '../../components/customerDetails/margin';
import PageSkeleton from '../../components/customerDetails/skeleton';
import CustomerUserTable from '../../comman/table/customer-details';

import { IoCopyOutline } from 'react-icons/io5';
import add from '../../../assets/icons/add.svg';

import {
  getCustomerAPI,
  getPayoutsAPI,
  getCustomersAPI,
  getCustomerUserAPI,
  validateCustomerAPI,
  getSourcesAPI,
  getPlatFormCostAPI,
  getManagersListAPI,
} from '../../../services/customers';

import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';

const css = {
  minCol: {
    minWidth: 'fit-content',
  },
  maxCol: {
    maxWidth: 'fit-content',
  },
};

export default () => {
  const { getCustomersAPI }: any = useAuthentication();
  const { customers, sCustomers, dateRangeCustomer, sDateRangeCustomer }: any = consumerData();

  const { pathname } = useLocation();
  const history = useHistory();

  const [selectedTile, sSelectedTile] = useState(0);
  const [selectedCustomer, sSelectedCustomer] = useState('');
  const [section, sSection] = useState('2');
  const [customer, sCustomer] = useState(false);
  //const [customers, sCustomers] = useState<any>([]);
  const [customerDetails, sCustomerDetails] = useState<any>(null);
  const [rejectionModal, sRejectionModal] = useState(false);
  const [singleTileData, sSingleTileData] = useState<any>([]);
  const [lineData, sLineData] = useState<any>(null);
  const [attribute /* , sAttribute */] = useState<any>(null);
  const [rawLineData /* , sRawLineData */] = useState<any>([]);
  const [refinedLineData, sRefinedLineData] = useState<any>([]);
  const [empty, sEmpty] = useState(false);
  const [iCustomer, sICustomer] = useState(false);
  const [costRowData, sCostRowData] = useState();
  const [cardLoading, sCardLoading] = useState(true);
  const [customerType, setCustomerType] = useState<any>('');
  const [currentSelectedId, sCurrentSelectedId] = useState('');
  const [random, sRandom] = useState(Math.random());
  const [allData, setAllData] = useState(false);
  const [datatables, sDatatables] = useState({
    users: [],
    payouts: [],
    managers: [],
    sources: [],
  });
  const [pDatatables, sPDatatables] = useState([]);
  const [isApproving, setIsApproving] = useState(false);

  // const [selectionRange, setSelectionRange] = useState({
  //   startDate: new Date(new Date().setDate(new Date().getDate() - 6)), // Number for days before
  //   endDate: new Date(),
  //   key: 'selection',
  // });

  const [currentDateSelection, sCurrentDateSelection] = useState(dateRangeCustomer);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const GetSelectionRangeProps = (data: any) => {
    //setSelectionRange(data);
    sDateRangeCustomer(data);
  };

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  /* LogRocket */
  const token = localStorage.getItem('token') || '';
  const decoded: any = JWT.decode(token, { complete: true });
  LogRocket.identify(decoded?.payload.user_id, {
    email: decoded?.payload.identity,
    customerType: 'Admin',
  });

  /* Handle Pagenation */
  const [currentPage, sCurrentPage] = useState(0);
  const [hasMore, sHasMore] = useState(true);
  const [params, setParams] = useState({
    page: 0,
    query: '',
    sortBy: {
      id: 'created_on',
      desc: true,
    },
  });

  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  const selectedCardType = (type: any) => {
    setCustomerType(type?.customer_type);
  };

  const selectSection = (id: string) => {
    sSection(id);
  };

  useEffect(() => {
    const _customerID = pathname.split('/customers/')[1];
    getCustomers(params, _customerID);
  }, [params.page, params.query, params.sortBy]);

  const handleParamsChange = (page: number, query: string, sortBy: any) => {
    if (
      page !== params.page ||
      query !== params.query ||
      sortBy.id !== params.sortBy.id ||
      sortBy.desc !== params.sortBy.desc
    ) {
      setParams({ page, query, sortBy });
    }
    if (params.sortBy !== sortBy || params.query !== query) {
      sCustomers([]);
    }
  };

  /* Get Customers List*/
  const getCustomers = async (params: any, _id: any) => {
    const payload = {
      page: params.page,
      page_size: 500,
      query: params.query,
      sort_by: {
        sortBy: [params.sortBy],
      },
    };
    try {
      sHasMore(true);
      sCardLoading(true);

      const data: any = await getCustomersAPI(payload);

      if (data) {
        sCardLoading(false);
        sCurrentPage(params.page || 0);

        // For Page Scroll
        if (params.page && params.page > 0) {
          if (data.data.length !== 0) {
            sCustomers([...customers, ...data.data]);
          } else {
            sHasMore(false);
            sCustomers(customers);
            sEmpty(data.data.length < 1);
            selectedCard(params.page === 0 ? customers[0].id : selectedCustomer);
          }
        } else {
          if (data.data.length < 1) sEmpty(true);
          sCustomers(data.data);
        }
      }
    } catch (error) {
      // Handle the error or display a message
      // toast.error('Service unavailable, Check back after sometime.');
    }
  };

  //Get customer id while select card in sidebar
  const selectedCard = (id: string) => {
    sCurrentSelectedId(id);
    if (id !== undefined && selectedCustomer !== id && id !== currentSelectedId) {
      history.push(`/customers/${id}`);
      sPDatatables([]);
      sSingleTileData([]);
      sRefinedLineData([]);
      sLineData([]);
      sDatatables({
        users: [],
        payouts: [],
        managers: [],
        sources: [],
      });
      sSelectedCustomer(id);
      getCustomerDetails(id);
      getCustomerUsers(id);
      getPlatFormCost(id);
    }
  };

  useEffect(() => {
    if (selectedCustomer !== '') {
      sLineData(singleTileData?.find((x: any) => x.id === selectedTile)?.lineData ?? []);
    }
  }, [selectedTile, random]);

  useMemo(() => {
    if (customers.length > 0 || selectedCustomer) {
      sPDatatables(customers.find((x: any) => x.id === selectedCustomer)?.tables ?? []);
    }
  }, [customers, selectedCustomer]);

  //Get customer details from api
  const getCustomerDetails = async (id: string) => {
    const payload = {
      id,
      start: convert(dateRangeCustomer.startDate) || '',
      end: convert(dateRangeCustomer.endDate) || '',
      time_zone: 'Asia/Kolkata',
    };

    // Set the current date selection
    sCurrentDateSelection(dateRangeCustomer);
    setAllData(false);
    try {
      // Check if startDate and endDate are different
      if (dateRangeCustomer.startDate !== dateRangeCustomer.endDate) {
        const data: any = await getCustomerAPI(payload);
        if (data) {
          const tileData = tagTiles(data.total);
          sSingleTileData(tileData);
          sSelectedTile(0);
          sRandom(Math.random());
          sPDatatables(customers.find((x: any) => x.id === selectedCustomer)?.tables ?? []);
          setAllData(true);
        }
      }
    } catch (error) {
      // Handle errors or show error messages if necessary
      // toast.error('Service unavailable, Check back after sometime.');
    }
  };

  const getCustomerUsers = async (id: string) => {
    try {
      const payload = {
        id,
      };

      // Use Promise.all to run multiple API calls simultaneously
      const [data, data2, data3] = await Promise.all([
        getCustomerUserAPI(payload),
        getManagersListAPI(payload),
        getPayoutsAPI({ id, remove_action: true }),
      ]);

      if (data) {
        const tables: any = { ...datatables };
        tables.users = data;

        if (data3) {
          tables.managers = data3;
          sDatatables(tables);
        }

        if (data2) {
          tables.payouts = data2;
        }

        const sourcesData = await getSourcesAPI(payload);
        if (sourcesData) {
          tables.sources = sourcesData;
        }
      }
    } catch (error) {
      // Handle errors or show error messages if necessary
      // toast.error('Service unavailable, Check back after sometime.');
    }
  };

  /***** UPDATE CUSTOMERS LIST BY ADD/UPDATE START *****/
  const handleCustomersList = (customerObj: any, kind: string, req?: string) => {
    if (kind === 'add') {
      const newArr = [customerObj, ...customers];
      sCustomers(newArr);
      selectedCard(customerObj.id);
    }

    if (kind === 'update') {
      const newArray = [...customers];
      const index = newArray.findIndex((item) => item.id === customerObj.id);
      if (index !== -1) {
        newArray[index] = { ...newArray[index], ...customerObj };
      }
      sCustomers(newArray);
      if (req === 'reject' && customerObj.status !== 'pending') {
        sSection('1');
        sSelectedCustomer(customerObj.id);
        sCustomerDetails(customerObj);
      }
    }
  };

  const validateCustomer = (flag: any, reason: string) => {
    ReactGA.event('infy_admin_customers_request_approve_button', {
      category: 'approve_button',
      action: 'request_approved',
      label: 'approved',
    });
    const payload = {
      customer_id: customerDetails.id,
      approve: flag ? 'yes' : 'no',
      reason: reason ?? '',
    };
    setIsApproving(true);
    validateCustomerAPI(payload)
      .then((data: any) => {
        if (data && data.status) {
          customerDetails.status = 'active';
          handleCustomersList(data.data, 'update');
          // getCustomers();
          ReactGA.event('customers_request_status', {
            category: 'api_response',
            action: 'api_response',
            label: data.status,
            id: customerDetails.id,
          });
        } else {
          toast.error(data.msg);
        }
        setIsApproving(false);
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  useEffect(() => {
    if (
      selectedCustomer &&
      (convert(currentDateSelection.startDate) !== convert(dateRangeCustomer.startDate) ||
        convert(currentDateSelection.endDate) !== convert(dateRangeCustomer.endDate))
    ) {
      getCustomerDetails(selectedCustomer);
    }
  }, [dateRangeCustomer]);

  useEffect(() => {
    const getCustomer: any = customers.find((x: any) => x.id === selectedCustomer);
    sCustomerDetails(getCustomer);
    if (getCustomer && getCustomer.status === 'pending') {
      sSection('2');
    } else {
      sSection('1');
    }
    sSelectedTile(0);
  }, [selectedCustomer]);

  useEffect(() => {
    const bottom: any = [];
    rawLineData.map((_: any, i: any) => {
      return bottom.push({ date: _.date, [attribute]: _[attribute] });
    });
    sRefinedLineData({
      labels: bottom.map((a: any) => a.date),
      values: bottom.map((a: any) => a[attribute]),
    });
  }, [rawLineData, attribute]);

  useEffect(() => {
    if (lineData && lineData.length) {
      let newlabels: any = [];
      newlabels = lineData.map((_: any) => _[Object.keys(_)[1]]);
      let newValues: any = [];
      newValues = lineData.map((_: any) => _[Object.keys(_)[0]]);
      /* replace new value array */
      sRefinedLineData({ labels: newValues, values: newlabels });
      //sRefinedLineData({ labels: newlabels, values: newValues });
    } else {
      sRefinedLineData({ labels: [], values: [] });
    }
  }, [lineData]);

  const getPlatFormCost = (id: string) => {
    const payload = {
      id,
    };
    getPlatFormCostAPI(payload)
      .then((data: any) => {
        sCostRowData(data.data);
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const handlePlatformCost = () => {
    getPlatFormCost(selectedCustomer);
  };

  return (
    <>
      <Customer show={customer} updateList={handleCustomersList} onHide={() => sCustomer(false)} />
      <InviteCustomer
        id={selectedCustomer}
        show={iCustomer}
        onHide={() => {
          sICustomer(false);
        }}
      />
      <Reject
        toast={toast}
        id={selectedCustomer}
        getCustomers={handleCustomersList}
        show={rejectionModal}
        onHide={() => {
          sRejectionModal(false);
        }}
      />
      <div
        className={'content'}
        style={{
          display: 'flex',
        }}
      >
        {localStorage.getItem('role_id') === '2' ? (
          <AdminSidebar
            title={glossary.admin_sidebar_title}
            kind={'customer'}
            size={'collapsed'}
            card={'expanded'}
            items={customers}
            // sidebar={setSidebar}
            info={selectedCard}
            empty={empty}
            selectCardInfo={selectedCardType}
            selected={selectedCustomer}
            currentPage={currentPage}
            loading={cardLoading}
            hasMore={hasMore}
            params={params}
            handleParams={handleParamsChange}
          />
        ) : (
          <>
            <AdminSidebar
              title={glossary.admin_sidebar_title}
              kind={'customer'}
              size={'collapsed'}
              card={'expanded'}
              action={{
                icon: add,
                text: glossary.admin_sidebar_button_invite,
                click: () => {
                  ReactGA.event('+invite_button', {
                    category: 'invite',
                    action: 'click',
                    label: 'sidebar_customers_invite',
                  });
                  sCustomer(true);
                },
              }}
              items={customers}
              // sidebar={setSidebar}
              info={selectedCard}
              empty={empty}
              selectCardInfo={selectedCardType}
              selected={selectedCustomer}
              currentPage={currentPage}
              loading={cardLoading}
              hasMore={hasMore}
              params={params}
              handleParams={handleParamsChange}
              allData={allData}
            />
          </>
        )}

        <div className='main__container'>
          {customers.length > 0 ? (
            <>
              {selectedCustomer !== '' && customerDetails && (
                <div className={'row flex-column flex-lg-row gap-3 gap-lg-0'}>
                  <div className={'col-4'}>
                    <div className={'row'}>
                      <div className={'mt-3 d-flex align-items-center'}>
                        <h2
                          className='text-truncate'
                          style={{
                            fontWeight: 200,
                            paddingRight: '10px',
                            width: 'auto',
                            //maxWidth: '250px',
                          }}
                        >
                          {customerDetails?.company_name ?? '-'}
                        </h2>
                        {customerDetails?.status !== 'on_hold' &&
                          customerDetails?.status !== 'pending' && (
                            <CustomerLogin customerDetails={customerDetails} />
                          )}
                      </div>
                    </div>
                  </div>

                  <div className={allData ? 'col-4 tabs-wrap' : 'col-4 tabs-wrap disable-field'}>
                    {customerDetails?.status !== 'pending' && (
                      <Pill
                        items={[
                          { id: '1', label: glossary.admin_dash_tab_performance },
                          { id: '2', label: glossary.admin_dash_tab_customer_details },
                        ]}
                        select={selectSection}
                        selected={section}
                      />
                    )}
                  </div>

                  <div
                    className={
                      'align-items-center col-4 d-flex flex-row justify-content-end calendar__wrap'
                    }
                    style={{ position: 'relative' }}
                  >
                    {customerDetails?.status !== 'pending' ? (
                      <DateRangeFilter
                        onChange={GetSelectionRangeProps}
                        selectionRange={dateRangeCustomer}
                      />
                    ) : (
                      <>
                        {customerDetails.customer_type === 'publisher' && (
                          <>
                            <IoCopyOutline
                              className='svg-icon me-2'
                              fontSize={20}
                              title={glossary.admin_ssp_cus_detail_tab_link_copy_icon_title}
                              onClick={() => {
                                navigator.clipboard.writeText(customerDetails.invite_link);
                                toast.success(glossary.admin_ssp_cus_detail_tab_link_copied_msg);
                              }}
                            />
                            {customerDetails.invite_status === 'invite' ? (
                              <Button
                                kind={'primary'}
                                text={glossary.admin_ssp_cus_detail_tab_button_invite}
                                click={() => sICustomer(true)}
                              />
                            ) : (
                              <Button
                                kind={'primary'}
                                text={glossary.admin_ssp_cus_detail_tab_button_re_invite}
                                click={() => sICustomer(true)}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}

              {selectedCustomer !== '' && section === '1' && customerDetails && (
                <>
                  {refinedLineData.labels < 1 && refinedLineData.values < 1 ? (
                    <>
                      <div
                        className={'row'}
                        style={{ marginTop: 56, justifyContent: 'space-between' }}
                      >
                        <div className='col-10'>
                          <div className='d-flex gap-4'>
                            {[1, 2, 3, 4, 5, 6].map((index) => {
                              return <Box key={index} />;
                            })}
                          </div>
                        </div>
                      </div>
                      <div className='row mt-4'>
                        <div className='col w-100 m-auto'>
                          <XYChart />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Tile data like totla traffic, impression etc.. */}
                      <div
                        className={'row'}
                        style={{ marginTop: 56, justifyContent: 'space-between' }}
                      >
                        <div className={'col'} style={css.maxCol}>
                          <div className={'row'}>
                            {singleTileData.map((tile: any, index: any) => {
                              return (
                                <div key={index} className={'col-auto'} style={css.minCol}>
                                  {refinedLineData.labels < 1 &&
                                  refinedLineData.values < 1 ? null : (
                                    <Tile
                                      id={tile.id}
                                      selected={selectedTile}
                                      click={selectTile}
                                      label={tile.label}
                                      value={tile.value}
                                      info={tile.info}
                                      arrow={tile.arrow}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className={'col'} style={css.maxCol}></div>
                      </div>

                      {/* View chart as per selected tile */}
                      <div
                        className={'row'}
                        style={{
                          height: '320px',
                          marginTop: '24px',
                        }}
                      >
                        <Line label={'Impressions'} data={refinedLineData} attribute={attribute} />
                      </div>
                    </>
                  )}

                  {/* Reporting table as per data */}
                  <div className={'row'} style={{ marginTop: 24 }}>
                    <Reports
                      id={selectedCustomer}
                      start={dateRangeCustomer.startDate}
                      end={dateRangeCustomer.endDate}
                      tabs={pDatatables}
                      random={random}
                      entityType={'customer'}
                    />
                  </div>
                </>
              )}

              {selectedCustomer !== '' && section === '2' && customerDetails && (
                <>
                  {/* Customer Details */}
                  <CustomerDetails customerDetails={customerDetails} />

                  {customerDetails?.status === 'pending' && (
                    <div
                      className='ms-4 gap-4 align-items-center'
                      style={{ display: 'flex', marginTop: 24 }}
                    >
                      {isApproving ? (
                        <Button kind={'spinner'} text={'Approving...'} type='button' />
                      ) : (
                        <Button
                          type='button'
                          kind={'primary'}
                          text={glossary.admin_customer_ssp_req_button_approve}
                          click={() => {
                            validateCustomer(true, '');
                          }}
                        />
                      )}
                      <span className='text-button' onClick={() => sRejectionModal(true)}>
                        Reject
                      </span>
                    </div>
                  )}

                  {customerDetails?.status !== 'pending' && (
                    <>
                      {/* Publisher Margin, Floor Price Range and Payment Terms */}
                      <MarginandPaymentTerms
                        customerDetails={customerDetails}
                        customers={customers}
                      />

                      {/* Platform Cost table only for Publishers */}
                      {customerDetails.customer_type === 'publisher' && (
                        <div className='main__container__box'>
                          <div className='row'>
                            <div className='col-12 admin-platform-cost'>
                              <PlatformCost
                                rows={costRowData ?? []}
                                //columns={columnsData ?? []}
                                search={false}
                                button={true}
                                title={'Platform Cost'}
                                updateData={handlePlatformCost}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* User, Manager, Pyaout and Tags/Sources table */}
                      <div className='customer-details-table'>
                        <CustomerUserTable
                          selectedCustomer={selectedCustomer}
                          customerType={customerType}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <PageSkeleton cardLoading={cardLoading} />
          )}
        </div>
      </div>
    </>
  );
};
