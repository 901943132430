import { useState, useEffect } from 'react';

interface Props {
  enpointListDataConfigObj?: any;
  setArrFunc?: any;
  backChecked?: any;
}

const DeviceTypeCheckBox = (props: Props) => {
  const { enpointListDataConfigObj, setArrFunc, backChecked } = props;

  const [checkedAll, setCheckedAll] = useState<any>(false);
  const [deviceType, setDeviceType] = useState([]);
  const [deviceTypeAll, setDeviceTypeAll] = useState();

  const [checked, setChecked] = useState<any>({
    dt1: false,
    dt2: false,
    dt3: false,
    dt4: false,
    dt5: false,
    dt6: false,
    dt7: false,
  });

  // iterate through backChecked and update checked state
  useEffect(() => {
    backChecked?.forEach((value: any) => {
      const key = `dt${value}`;
      if (value) {
        setChecked((prevState: any) => ({ ...prevState, [key]: true }));
      }
    });
  }, [backChecked]);

  const toggleCheck = (inputName: any) => {
    setChecked((prevState: any) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const selectAll = (value: any) => {
    setCheckedAll(value);
    setChecked((prevState: any) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };

  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);

  const arrCheckBox: any = [];

  if (checked.dt1 === true) {
    arrCheckBox.push(1);
  }
  if (checked.dt2 === true) {
    arrCheckBox.push(2);
  }
  if (checked.dt3 === true) {
    arrCheckBox.push(3);
  }
  if (checked.dt4 === true) {
    arrCheckBox.push(4);
  }
  if (checked.dt5 === true) {
    arrCheckBox.push(5);
  }
  if (checked.dt6 === true) {
    arrCheckBox.push(6);
  }
  if (checked.dt7 === true) {
    arrCheckBox.push(7);
  }

  const handleChecked = (device: any) => {
    for (let i = 0; i < device.length; i++) {
      if (device[i] === 1) {
        setChecked((prevState: any) => {
          const newState = { ...prevState };
          newState['dt1'] = true;
          return newState;
        });
      }
      if (device[i] === 2) {
        setChecked((prevState: any) => {
          const newState = { ...prevState };
          newState['dt2'] = true;
          return newState;
        });
      }
      if (device[i] === 3) {
        setChecked((prevState: any) => {
          const newState = { ...prevState };
          newState['dt3'] = true;
          return newState;
        });
      }
      if (device[i] === 4) {
        setChecked((prevState: any) => {
          const newState = { ...prevState };
          newState['dt4'] = true;
          return newState;
        });
      }
      if (device[i] === 5) {
        setChecked((prevState: any) => {
          const newState = { ...prevState };
          newState['dt5'] = true;
          return newState;
        });
      }
      if (device[i] === 6) {
        setChecked((prevState: any) => {
          const newState = { ...prevState };
          newState['dt6'] = true;
          return newState;
        });
      }
      if (device[i] === 7) {
        setChecked((prevState: any) => {
          const newState = { ...prevState };
          newState['dt7'] = true;
          return newState;
        });
      }
    }
  };

  useEffect(() => {
    setDeviceType(enpointListDataConfigObj?.device_types);
    if (enpointListDataConfigObj?.device_types.length) {
      handleChecked(enpointListDataConfigObj?.device_types);
    }
    setDeviceTypeAll(enpointListDataConfigObj?.device_types?.length);
  }, [enpointListDataConfigObj?.device_types]);

  useEffect(() => {
    setArrFunc(arrCheckBox);
  }, [checked]);

  return (
    <div className='d-flex flex-wrap gap-3'>
      <div className='d-flex align-items-center'>
        <input
          type='checkbox'
          onChange={(event) => selectAll(event.target.checked)}
          checked={checkedAll}
        />
        <label className='mb-0 ms-2 text-black label pt-1'>All</label>
      </div>
      <div className='d-flex align-items-center'>
        <input
          type='checkbox'
          name='dt1'
          onChange={() => toggleCheck('dt1')}
          checked={checked['dt1']}
        />
        <label className='mb-0 ms-2 text-black label pt-1'>Mobile/Tablet</label>
      </div>
      <div className='d-flex align-items-center'>
        <input
          type='checkbox'
          name='dt2'
          onChange={() => toggleCheck('dt2')}
          checked={checked['dt2']}
        />
        <label className='mb-0 ms-2 text-black label pt-1'>Personal Computer</label>
      </div>
      <div className='d-flex align-items-center'>
        <input
          type='checkbox'
          name='dt3'
          onChange={() => toggleCheck('dt3')}
          checked={checked['dt3']}
        />
        <label className='mb-0 ms-2 text-black label pt-1'>Connected TV</label>
      </div>
      <div className='d-flex align-items-center'>
        <input
          type='checkbox'
          name='dt4'
          onChange={() => toggleCheck('dt4')}
          checked={checked['dt4']}
        />
        <label className='mb-0 ms-2 text-black label pt-1'>Phone</label>
      </div>
      <div className='d-flex align-items-center'>
        <input
          type='checkbox'
          name='dt5'
          onChange={() => toggleCheck('dt5')}
          checked={checked['dt5']}
        />
        <label className='mb-0 ms-2 text-black label pt-1'>Tablet</label>
      </div>
      <div className='d-flex align-items-center'>
        <input
          type='checkbox'
          name='dt6'
          onChange={() => toggleCheck('dt6')}
          checked={checked['dt6']}
        />
        <label className='mb-0 ms-2 text-black label pt-1'>Connected Device</label>
      </div>
      <div className='d-flex align-items-center'>
        <input
          type='checkbox'
          name='dt7'
          onChange={() => toggleCheck('dt7')}
          checked={checked['dt7']}
        />
        <label className='mb-0 ms-2 text-black label pt-1'>Set Top Box</label>
      </div>
    </div>
  );
};

export default DeviceTypeCheckBox;
