import { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../widgets/button';
import FormikController from '../comman/form/FormikController';
import { creativeProfileVideoUploadAPI } from '../../services/creative';

interface UploadCreativeForm {
  show: boolean;
  onHide(): void;
  updateVideoList(): void;
  glossary: any;
}

const HashtagField = ({ value: initialValue, handleValue }: any) => {
  const [inputText, setInputText] = useState(initialValue?.join(' '));
  const [resultText, setResultText] = useState('');

  const processInput = (text: string) => {
    const tags = text.split(' ')?.filter((tag: string) => tag !== '');
    const filteredTags = tags?.filter((tag: string) =>
      tag === '#' ? tags?.indexOf(tag) < 4 : true
    );
    return filteredTags?.join(' ');
  };

  const handleInputChange = (event: any) => {
    const newText = event?.target?.value;

    if (newText?.includes('  ')) {
      return;
    }

    if (newText?.split('#').length > 4) {
      return;
    }
    if (newText?.endsWith(' ') && newText?.split('#')?.length === 4) {
      return;
    }

    if (!newText?.startsWith('#')) {
      return;
    }

    setInputText(newText);
    const processedText = processInput(newText);
    setResultText(processedText);
  };

  const handleKeyDown = (event: any) => {
    if (event?.key === 'Enter') {
      event?.preventDefault();
    }

    if (event?.key === 'Backspace' && inputText?.endsWith('#')) {
      setInputText(inputText?.slice(0, -1));
      const processedText = processInput(inputText?.slice(0, -1));
      setResultText(processedText);
    }
  };

  const onBlur = () => {
    handleValue(inputText?.length > 0 ? inputText?.split(' ') : []);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setInputText(initialValue?.join(' '));
  }, [initialValue]);

  return (
    <>
      <label className='label'>Hashtags</label>
      <input
        className='text-input'
        placeholder={'Enter hashtags seperated by space'}
        value={inputText ?? ''}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
      />
    </>
  );
};

const CreativeUpload = ({ show, onHide, updateVideoList, glossary }: UploadCreativeForm) => {
  const [uploadFileError, setUploadFileError] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const [rawVideoFile, setRawVideoFile] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const fileInputRef: any = useRef(null);

  const validationSchema = Yup.object().shape({
    cta_text: Yup.string().nullable(),
    cta_link: Yup.string().url('Enter valid URL').typeError('Enter valid URL'),
    pixel_url: Yup.string().url('Enter valid pixel URL').typeError('Enter valid pixel URL'),
  });

  const handleClearFile = () => {
    fileInputRef.current.value = ''; // reset the value to an empty string
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const fileType = file?.type;
    if (file && file.size <= 400 * 1024 * 1024 && fileType === 'video/mp4') {
      setRawVideoFile(URL.createObjectURL(file));
      setSelectedFile(file);
    } else {
      setUploadFileError(
        fileType !== 'video/mp4'
          ? 'Invalid file type. Only .mp4 is allowed.'
          : 'Selected file is too large.'
      );
      setSelectedFile('');
      handleClearFile();
    }
  };

  const clearStates = () => {
    setSelectedFile('');
    handleClearFile();
    setRawVideoFile('');
    setUploadFileError('');
  };

  const uploadCreative = (_e: any) => {
    const formdata = new FormData();
    formdata.append('video', _e?.video);
    formdata.append('descriptions', '');
    formdata.append('link', '');
    formdata.append(
      'video_library_props',
      `{
          "cta": { 
            "text": "${_e?.cta_text}", 
            "link": "${_e?.cta_link}", 
            "ad_copy": "${_e?.ad_copy}",
            "show_url_meta": ${_e?.url_meta}
          },
          "pixel_url": "${_e?.pixel_url}"
        }`
    );
    formdata.append('hashtags', _e?.hash_tags);

    setIsUploading(true);
    creativeProfileVideoUploadAPI(formdata)
      .then((data: any) => {
        if (data?.status) {
          toast.success(data?.message);
          updateVideoList();
          setHashtags([]);
          clearStates();
          onHide();
        } else {
          clearStates();
          toast.error(data?.message);
        }
        setIsUploading(false);
      })
      .catch((error: any) => {
        clearStates();
        setIsUploading(false);
        toast.error(glossary.server_error);
      });
  };

  const handleButtonClick = () => {
    clearStates();
    fileInputRef?.current?.click();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setHashtags([]);
          clearStates();
          onHide();
        }}
        backdrop={isUploading ? 'static' : true}
        centered
        size={'lg'}
      >
        <Modal.Header>
          <h4>Upload Creative</h4>
        </Modal.Header>

        <Formik
          initialValues={{
            cta_text: '',
            cta_link: '',
            url_meta: false,
            ad_copy: '',
            pixel_url: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(e) => uploadCreative({ ...e, video: selectedFile, hash_tags: hashtags })}
        >
          <Form>
            <Modal.Body
              style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto',
              }}
            >
              <div className='row'>
                <div className='col-lg-6 col-md-12'>
                  <div className='d-flex flex-column align-items-center gap-3'>
                    <>
                      <div
                        style={{
                          backgroundColor: '#e7e7e7',
                          width: '250px',
                          height: '400px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <video
                          height={'100%'}
                          src={rawVideoFile}
                          width={'100%'}
                          autoPlay
                          controls
                        />
                      </div>
                      <p className='small text-danger'>{uploadFileError}</p>
                    </>
                    <Button
                      text='Choose File'
                      kind={isUploading ? 'disabled' : 'secondary'}
                      type='button'
                      click={handleButtonClick}
                    />
                    <label className='label'>Supported File: MP4</label>

                    <input
                      ref={fileInputRef}
                      type='file'
                      accept='video/*'
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className='col-lg-6 col-md-12'>
                  <div className='row'>
                    <div className='col-12 mb-3'>
                      <FormikController
                        type='text'
                        control='input'
                        kind='withouticon'
                        name={'cta_text'}
                        label={'CTA Text'}
                        placeholder={'Enter CTA Text'}
                        maxLength={24}
                      />
                    </div>
                    <div className='col-12 mb-3'>
                      <FormikController
                        type='text'
                        control='input'
                        kind='withouticon'
                        name={'cta_link'}
                        label={'CTA Link'}
                        placeholder={'Enter CTA Link'}
                      />
                    </div>
                    <div className='col-12 mb-3'>
                      <HashtagField value={hashtags} handleValue={(val: any) => setHashtags(val)} />
                    </div>
                    <div className='col-12 mb-3'>
                      <Field type='checkbox' name='url_meta' />
                      <span className='ms-2'>URL Meta</span>
                    </div>
                    <div className='col-12 mb-3'>
                      <FormikController
                        type='text'
                        control='input'
                        kind='withouticon'
                        name={'ad_copy'}
                        label={'Ad Copy'}
                        placeholder={'Enter Ad Copy Text'}
                        maxLength={24}
                      />
                    </div>
                    <div className='col-12 mb-3'>
                      <FormikController
                        type='text'
                        control='input'
                        kind='withouticon'
                        name={'pixel_url'}
                        label={'Pixel URL'}
                        placeholder={'Please put your pixel URL here'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer className='gap-2'>
              <Button
                text='Cancel'
                kind={isUploading ? 'disabled' : 'secondary'}
                type='button'
                click={() => {
                  setHashtags([]);
                  clearStates();
                  onHide();
                }}
              />
              {isUploading ? (
                <Button text='Uploading...' kind='spinner' type='button' />
              ) : (
                <Button
                  text='Upload'
                  kind={uploadFileError === '' && selectedFile ? 'primary' : 'disabled'}
                  type='submit'
                />
              )}
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CreativeUpload;
