import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import store from '../../states';
import InvitePublisherList from '../components/spo/InvitePublisherList';
import InviteForm from '../components/spo/InviteForm';

const InvitePublisherModal = ({ id, onHide, show, getEndPointList, type }: any) => {
  const [glossary, sGlossary] = useState<any>({});

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <Modal onHide={onHide} show={show} centered size={'xl'} className='tabs-modal'>
        <Tabs defaultActiveKey='your-connect'>
          <Tab eventKey='your-connect' title={glossary.spo_invite_modal_tab_title1}>
            <InviteForm id={id} onHide={onHide} getEndPointList={getEndPointList} type={type} />
          </Tab>
          <Tab
            eventKey='publisher-library'
            title={
              type === 'spo-publisher'
                ? glossary.spo_invite_modal_tab_title2_agencies
                : glossary.spo_invite_modal_tab_title2
            }
          >
            <InvitePublisherList
              id={id}
              onHide={onHide}
              getEndPointList={getEndPointList}
              type={type}
            />
          </Tab>
        </Tabs>
      </Modal>
    </>
  );
};

export default InvitePublisherModal;
