import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import validator from 'validator';
import ReactGA from 'react-ga4';

import Input from '../widgets/input';
import Button from '../widgets/button';

import {
  changePasswordAPI,
  setPasswordAPI,
  userTokenVerifyAPI,
} from '../../services/authentication';

import { ToastContainer, toast } from 'react-toastify';
import store from '../../states';
import TextSkeleton from '../loaders/text';

interface PasswordForm {
  token: string;
  password: string;
  confirm: string;
}

const passwordFormInitialValues = {
  token: '',
  password: '',
  confirm: '',
};

const passwordFormInitialErrors: PasswordForm = {
  token: '',
  password: '',
  confirm: '',
};

export default () => {
  const { push, location } = useHistory<any>();

  const [glossary, sGlossary] = useState<any>({});
  const [min, sMin] = useState<boolean>(false);
  const [num, sNum] = useState<boolean>(false);
  const [upc, sUpc] = useState<boolean>(false);
  const [spc, sSpc] = useState<boolean>(false);
  const [errors, sErrors] = useState<PasswordForm>(passwordFormInitialErrors);
  const [passwordForm, sPasswordForm] = useState<PasswordForm>(passwordFormInitialValues);

  const [firstLogin, sFirstLogin] = useState(false);

  const [skeleton, setSkeleton] = useState(false);

  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const updatePasswordFormValues = (key: string, value: any) => {
    sErrors(passwordFormInitialErrors);
    const newPassword = { ...passwordForm };
    newPassword[key as keyof typeof passwordFormInitialValues] = value;
    sPasswordForm(newPassword);
  };

  const validatePasswordForm = () => {
    let valid = true;
    const newErrors = { ...errors };
    if (validator.isEmpty(passwordForm.password)) {
      valid = false;
      newErrors['password'] = glossary.create_password_newpassword_empty_error;
    }
    if (validator.isEmpty(passwordForm.confirm)) {
      valid = false;
      newErrors['confirm'] = glossary.create_password_confirmpassword_empty_error;
    }
    if (passwordForm.password !== passwordForm.confirm) {
      valid = false;
      newErrors['confirm'] = glossary.create_password_error;
    }
    if (valid) {
      return true;
    } else {
      sErrors(newErrors);
      return false;
    }
  };

  const initiatePasswordCreation = () => {
    ReactGA.event({
      category: 'password',
      action: 'click',
      label: 'new_user_password',
    });
    if (validatePasswordForm())
      if (validatePasswordForm()) {
        const accessToken: any = location.state;
        if (firstLogin) {
          const payload = {
            password: passwordForm.password,
            confirm_password: passwordForm.confirm,
          };
          changePasswordAPI(payload, accessToken)
            .then((data: any) => {
              if (data.status) {
                localStorage.clear();
                push('/password-set');
              }
            })
            .catch((error: any) => {
              toast.error(error);
            });
        } else {
          const payload = {
            token: passwordForm.token,
            password: passwordForm.password,
            confirm_password: passwordForm.confirm,
          };
          setPasswordAPI(payload)
            .then((data: any) => {
              if (data.status) {
                ReactGA.event('create_password_api_response', {
                  category: 'password',
                  action: 'api_response',
                  label: data.status,
                });
                push('/password-set');
              } else {
                toast.error(data.msg);
              }
            })
            .catch((error) => {
              toast.error('Service unavailable, Check back after sometime.');
            });
        }
      }
  };

  useEffect(() => {
    const accessToken: any = location.state;
    if (accessToken) {
      sFirstLogin(true);
      //localStorage.setItem('token', accessToken);
    }
  }, []);

  useEffect(() => {
    const newPassword = { ...passwordForm };
    newPassword['token'] = location.pathname.split('/create-password/')[1];
    sPasswordForm({ ...newPassword });

    //This is use for User Change password verify
    const tokendata = location.pathname.split('/create-password/')[1];
    if (tokendata !== 'null') {
      userTokenVerify(tokendata);
    }
  }, []);

  //User Change Password Token Verify
  const userTokenVerify = (data: any) => {
    const payload = {
      token: data,
    };
    setSkeleton(true);
    userTokenVerifyAPI(payload)
      .then((data: any) => {
        if (!data.status) {
          push(`${data.redirect_url}`, { ...location.state, msg: data.message });
        }
        setSkeleton(false);
      })
      .catch((error: any) => {
        toast.error('Service unavailable, Check back after sometime.');
      });
  };

  // window.addEventListener('popstate', () => {
  //   // push('/login');
  //   window.location.href = '/login';
  //   localStorage.clear();
  // });

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100vw',
          height: 'calc(100vh - 66px)',
        }}
      >
        <div
          className={'card-box'}
          style={{
            margin: 'auto',
            rowGap: '16px',
            width: '550px',
            display: 'flex',
            padding: '72px 118px',
            flexDirection: 'column',
          }}
        >
          {skeleton ? (
            <>
              <div style={{ width: 'max-content' }}>
                <TextSkeleton width='100%' height={20} lineCount={1} />
              </div>
              <div className='' style={{ width: 'max-content' }}>
                <TextSkeleton width='100%' height={20} lineCount={1} />
              </div>
              <div className={'row'} style={{ marginTop: '20px' }}>
                <TextSkeleton width={300} height={250} lineCount={3} />
              </div>
            </>
          ) : (
            <>
              <h3 style={{ fontWeight: 300, textAlign: 'center' }}>
                {glossary.create_password_title}
              </h3>
              <p style={{ textAlign: 'center' }}>{glossary.create_password_title_description}</p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  initiatePasswordCreation();
                }}
                style={{
                  rowGap: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div>
                  <Input
                    style={{ paddingRight: '40px' }}
                    name={'password'}
                    kind='text'
                    label={glossary.create_password_input_field_label_newpassword}
                    change={updatePasswordFormValues}
                    type={'password'}
                    error={errors.password}
                    placeholder={glossary.create_password_input_field_placeholder_newpassword}
                    typing={(i: any) => {
                      sMin(i.target.value.length >= 6 ? true : false);
                      sNum(/\d/.test(i.target.value) ? true : false);
                      sUpc(/[A-Z]/.test(i.target.value) ? true : false);
                      sSpc(/[^A-Za-z0-9]/.test(i.target.value) ? true : false);
                    }}
                    length={100}
                  />
                </div>
                <div>
                  <Input
                    style={{ paddingRight: '40px' }}
                    name={'confirm'}
                    kind='text'
                    label={glossary.create_password_input_field_label_confirmpassword}
                    change={updatePasswordFormValues}
                    type={'password'}
                    error={errors.confirm}
                    placeholder={glossary.create_password_input_field_placeholder_confirmpassword}
                    length={100}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <ul style={{ fontSize: '14px', margin: 'auto' }}>
                    <li style={{ color: min ? '#40C057' : '#A2A2A2' }}>
                      {glossary.create_password_validation_one}
                    </li>
                    <li style={{ color: num ? '#40C057' : '#A2A2A2' }}>
                      {glossary.create_password_validation_two}
                    </li>
                    <li style={{ color: upc ? '#40C057' : '#A2A2A2' }}>
                      {glossary.create_password_validation_three}
                    </li>
                    <li style={{ color: spc ? '#40C057' : '#A2A2A2' }}>
                      {glossary.create_password_validation_four}
                    </li>
                  </ul>
                </div>
                <Button
                  kind={min && num && upc && spc ? 'primary' : 'disabled'}
                  text={glossary.create_password_button}
                  size={'full'}
                  type={'submit'}
                />
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};
