import { useEffect, useState } from 'react';
import Button from '../../../widgets/button';
import { AiFillLinkedin } from 'react-icons/ai';
import { IoCopyOutline } from 'react-icons/io5';
import './invited.scss';
import {
  customerApproveAPI,
  customerReinviteAPI,
  customerRejectAPI,
  getReInvitePartnerListAPI,
} from '../../../../services/spo';
import { toast } from 'react-toastify';
import { MdInfoOutline } from 'react-icons/md';
import TableSkeleton from '../../../loaders/table';

export default ({ selectedCusomer, partnerDetails, glossary, getCustomers }: any) => {
  // State to store the selected row values
  const [customerList, setCustomerList] = useState<any>([]);
  const [approached, setApproached] = useState(false);
  const [remainDays, setRemainDays] = useState();
  const [loader, setLoader] = useState(true);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const reInvitePartnerListAPI = () => {
    const payload = {
      id: selectedCusomer,
    };

    setLoader(true);
    getReInvitePartnerListAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          setCustomerList(data?.data);
          setApproached(data?.approached);
          setRemainDays(data?.remain_days);
        }
        setLoader(false);
      })
      .catch((error: any) => {
        toast.error(glossary.dsp_update_rtb_faild_server_msg);
      });
  };

  useEffect(() => {
    reInvitePartnerListAPI();
  }, [selectedCusomer]);

  // Function to handle "Invite Again" button click
  const handleInviteAgainClick = (row: any, multi: boolean) => {
    const payload = {
      parthner_id: selectedCusomer,
      users: multi ? row : [row.id],
    };
    customerReinviteAPI(payload)
      .then((data: any) => {
        if (data.status) {
          reInvitePartnerListAPI();
        }
      })
      .catch(() => {
        //toast.error(glossary.ssp_createssai_failed_msg);
      });
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (rowId: string) => {
    if (selectedRows.includes(rowId)) {
      // If row is already selected, remove it from the selectedRows array
      setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((id) => id !== rowId));
    } else {
      // If row is not selected, add it to the selectedRows array
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, rowId]);
    }
  };

  const hadleAprove = () => {
    const payload = {
      id: selectedCusomer,
    };
    customerApproveAPI(payload)
      .then((data: any) => {
        toast.success(data.message);
        getCustomers(data?.customer_obj, 'add', 'approve');
        //getCustomers(data.success[0], 'add');
      })
      .catch(() => {
        //toast.error(glossary.ssp_createssai_failed_msg);
      });
  };

  const hadleReject = () => {
    const payload = {
      id: selectedCusomer,
    };
    customerRejectAPI(payload)
      .then((data: any) => {
        toast.success(data.message);
        getCustomers(data?.customer_obj, 'update', 'reject');
      })
      .catch(() => {
        //toast.error(glossary.ssp_createssai_failed_msg);
      });
  };

  return (
    <div className='resend'>
      <div className='row align-items-center mb-3'>
        <div className='col-4 mt-0'>
          <div className='d-flex gap-3 align-items-center'>
            {selectedCusomer !== '' && partnerDetails && (
              <h2 className='text-truncate' style={{ lineHeight: '40px' }}>
                {partnerDetails.company_name}
              </h2>
            )}
          </div>
        </div>
      </div>
      {partnerDetails?.assigned_status === 'reject' && (
        <div className='resend-details'>
          <h4 style={{ fontSize: '22px' }}>Rejected</h4>
          {localStorage.getItem('customer_type') === 'publisher' && (
            <p className='mt-3' style={{ fontSize: '18px' }}>
              We regret to inform you that parthner has declined the partnership request.
            </p>
          )}
          {localStorage.getItem('customer_type') === 'spo' && (
            <p className='mt-3' style={{ fontSize: '18px' }}>
              We regret to inform you that parthner has declined the partnership request.
            </p>
          )}
        </div>
      )}
      {partnerDetails?.assigned_status !== 'registered' &&
        partnerDetails?.assigned_status !== 'reject' && (
          <div className='resend-details'>
            <h4 style={{ fontSize: '22px' }}>{glossary.spo_invited_ssp_title}</h4>
            {localStorage.getItem('customer_type') === 'publisher' && (
              <p className='mt-3' style={{ fontSize: '18px' }}>
                {glossary.spo_invited_ssp_description}
              </p>
            )}
            {localStorage.getItem('customer_type') === 'spo' && (
              <p className='mt-3' style={{ fontSize: '18px' }}>
                {glossary.spo_invited_spo_description}
              </p>
            )}
          </div>
        )}

      {partnerDetails?.assigned_status === 'registered' && (
        <>
          <div className='resend-details mt-4'>
            <div className='row pb-3'>
              <div className='col'>
                <h3>Customer Details</h3>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-12 col-lg-4'>
                <label className='label'>Company Name</label>
                <p>{partnerDetails?.company_name ?? '-'}</p>
              </div>
              <div className='col-12 col-lg-4'>
                <label className='label'>Name</label>
                <p className='text-truncate'>{partnerDetails?.name ?? '-'}</p>
              </div>
              <div className='col-12 col-lg-4'>
                <label className='label'>Official Email</label>
                <p>{partnerDetails?.email ?? '-'}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-4'>
                <label className='label'>Name of Publisher</label>
                <p>{partnerDetails?.publisher_name ?? '-'}</p>
              </div>
              <div className='col-12 col-lg-4'>
                <label className='label'>Average Traffic</label>
                <p className='text-truncate'>{partnerDetails?.traffic_formatted ?? '-'}</p>
              </div>
              <div className='col-12 col-lg-4'>
                <label className='label'>Website URL</label>
                <p>
                  <a
                    href={`https://${partnerDetails?.website_url}` ?? '#'}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {partnerDetails?.website_url ?? '-'}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className='d-flex gap-4 align-items-center mt-4'>
            <Button
              kind={'primary'}
              text={'Aprove'}
              click={() => {
                hadleAprove();
              }}
            />
            <span className='text-button' onClick={() => hadleReject()}>
              Reject
            </span>
          </div>
        </>
      )}

      {partnerDetails?.assigned_status !== 'registered' &&
        partnerDetails?.assigned_status !== 'reject' && (
          <>
            {loader ? (
              <div className='mt-4'>
                <TableSkeleton columns={5} rows={5} rowSpacing={2} tableHeight={180} />
              </div>
            ) : (
              <>
                <div className='d-flex align-items-center justify-content-between mt-4'>
                  <h4 className='resend-title'>
                    Resend Invite {customerList.length > 1 && `to all ${customerList.length}`}
                  </h4>
                  {!approached && selectedRows.length > 0 && (
                    <Button
                      type='submit'
                      kind={'primary'}
                      text={'Invite Selected'}
                      click={() => handleInviteAgainClick(selectedRows, true)}
                    />
                  )}
                </div>
                <h3 className='resend-subtitle'>Invited Users</h3>
                <div className='resend-container'>
                  {customerList?.map((row: any) => (
                    <div key={row.index} className='resend-row'>
                      {!approached && (
                        <div className='resend-column _1'>
                          <input
                            type='checkbox'
                            onChange={() => handleCheckboxChange(row.id)}
                            checked={selectedRows.includes(row.id)}
                          />
                        </div>
                      )}
                      <div className='resend-column _2'>
                        <p>
                          <img src={row?.thumb} className='resend-column-thumb' /> {row?.firstname}{' '}
                          {row?.lastname}
                        </p>
                      </div>
                      <div className='resend-column _3'>
                        <p>{row?.email}</p>
                      </div>

                      {partnerDetails?.assigned_status !== 'registered' && (
                        <div className='resend-column _4'>
                          <p>
                            <AiFillLinkedin fontSize={22} /> Copy Invite Link{' '}
                            <IoCopyOutline
                              className='svg-icon'
                              onClick={() => {
                                navigator.clipboard.writeText(row?.invite_link);
                                toast.success(
                                  glossary.ssp_createssai_success_endpoint_url_copy_success_msg
                                );
                              }}
                              title={'Copy URL'}
                              fontSize={20}
                            />
                          </p>
                        </div>
                      )}

                      {!approached && (
                        <div className='resend-column _5'>
                          <Button
                            type='submit'
                            kind={'secondary'}
                            text={'Invite Again'}
                            click={() => handleInviteAgainClick(row, false)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {approached && (
                  <div className='mt-3'>
                    <p className='align-items-center d-flex gap-2'>
                      <MdInfoOutline className='svg-icon' fontSize={20} />
                      Your partner has already been invited. Please note that you can extend another
                      invitation to your partner after the forthcoming {remainDays} days.
                    </p>
                  </div>
                )}
              </>
            )}
          </>
        )}
    </div>
  );
};
