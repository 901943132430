import { useState } from 'react';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { toast } from 'react-toastify';
import {
  paymentProofUploadAPI,
  payoutRequestUpdateAPI,
  receiptDownloadAPI,
} from '../../services/settings';
import RejectPayoutReq from '../modals/reject-payout-req';

const PayoutRequestManage = (props: any) => {
  const { accessor, row, api } = props;

  const [OpenModal, sOpenModal] = useState(false); // open and close modal
  const [selectedID, setSelectedID] = useState('');
  const [requestID, setRequestID] = useState('');

  //Functionality for Download and Upload
  const rejectRequest = (id: any) => {
    sOpenModal(true);
    setSelectedID(id);
  };

  const downloadReceipt = (rowData: any) => {
    receiptDownloadAPI(rowData)
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${rowData.company_name}-receipt.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error: any) => {
        // console.log('receipt error', error);
      });
  };

  const paymentProofDownload = (id: any, pProof: any) => {
    const url = pProof;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ABS.pdf');
    link.download = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  const uploadFile = (new_file: any) => {
    const fd = new FormData();
    fd.append('file', new_file);
    fd.append('id', requestID);

    if (new_file) {
      paymentProofUploadAPI(fd)
        .then((data: any) => {
          setRequestID('');
          if (data.url) {
            api();
            toast.success('Payment Proof uploaded successfully.');
          }
        })
        .catch(() => {
          //ok
        });
    }
  };

  const sendRequestID = (id: any) => {
    setRequestID(id);
  };

  const approveRequest = (id: any, status: any) => {
    const payload = {
      id: id,
      status: status,
      reason: '',
    };
    payoutRequestUpdateAPI(payload)
      .then((data) => {
        api();
        toast.success(`Request ${status} successfully.`);
      })
      .catch((err: any) => {
        // console.log('error', err.message.status);
      });
  };

  return (
    <>
      <RejectPayoutReq
        show={OpenModal}
        id={selectedID}
        onHide={() => sOpenModal(false)}
        getData={api}
      />
      {accessor === 'approve_reject' &&
        (row.status === 'requested' ? (
          <>
            <span
              style={{
                color: '#1d58d7',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={() => {
                approveRequest(row.id, 'approved');
              }}
            >
              Approve
            </span>
            <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => rejectRequest(row.id)}>
              Reject
            </span>
          </>
        ) : row.status === 'approved' && row.payment_proof !== null ? (
          <span
            style={{ cursor: 'pointer', color: '#731780' }}
            onClick={() => approveRequest(row.id, 'paid')}
          >
            Mark Paid
          </span>
        ) : (
          ''
        ))}

      {accessor === 'payment_proof' &&
        (row.payment_proof === null ? (
          row.status === 'approved' && (
            <>
              <div>
                <label htmlFor='upload_file'>
                  <FiUpload
                    className='svg-icon fs-5'
                    onClick={() => {
                      sendRequestID(row.id);
                    }}
                  />
                </label>
                <input
                  type={'file'}
                  id={'upload_file'}
                  onChange={(e: any) => uploadFile(e?.target?.files[0])}
                  accept='application/pdf, application/msword, 
                    application/vnd.ms-excel, application/vnd.ms-powerpoint'
                  style={{ display: 'none' }}
                ></input>
              </div>
            </>
          )
        ) : (
          <>
            <FiDownload
              className='svg-icon fs-5'
              onClick={() => paymentProofDownload(row?.id, row?.payment_proof)}
            />
          </>
        ))}

      {accessor === 'receipt' && row.status === 'paid' && (
        <FiDownload className='svg-icon fs-5' onClick={() => downloadReceipt(row)} />
      )}
    </>
  );
};

export default PayoutRequestManage;
