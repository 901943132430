import { useState, useEffect } from 'react';
import JWT from 'jsonwebtoken';
import { toast } from 'react-toastify';
import Radio from '../../widgets/radio';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormB from 'react-bootstrap/Form';
import { IoCopyOutline } from 'react-icons/io5';
import { MdInfoOutline } from 'react-icons/md';
import { createTagAPI, editTagAPI, sendEmailAPI } from '../../../services/tags';
import Switch from '../../widgets/switch';
import MultiRangeSlider from 'multi-range-slider-react';
// import { Range, getTrackBackground } from 'react-range';
import store from '../../../states';
import ReactGA from 'react-ga4';
import FormikController from '../../comman/form/FormikController';
import MacrosDropDown from '../../helpers/MacrosDropDown';
import Button from '../../widgets/button';
import { windowReload } from '../../helpers/functions';

const SSAIEndpoint = ({
  kind,
  pSection,
  getTags,
  onHide,
  // adsTxt,
  tagId,
  createTag,
  selectedTagDetails,
  initialCreate,
}: any) => {
  const SSAIInitialValues = {
    sourceName: createTag ? '' : selectedTagDetails?.name,
    streamTimeout: createTag ? '' : selectedTagDetails?.config?.stream_timeout,
    inventoryTimeout: createTag ? '' : selectedTagDetails?.config?.inventory_timeout,
    originURL: createTag ? '' : selectedTagDetails?.config?.origin_url,
    floorPrice: createTag ? '' : selectedTagDetails?.floor_price,
  };

  const SendEmailSSAIValues = {
    endIntegrationInstruction: '',
  };
  const [appParamFinalUrl, setAppParamFinalUrl] = useState('');
  const [siteParamFinalUrl, setSiteParamFinalUrl] = useState('');
  const [impParamFinalUrl, setImpParamFinalUrl] = useState('');
  const [deviceParamFinalUrl, setDeviceParamFinalUrl] = useState('');
  const [contentParamFinalUrl, setContentParamFinalUrl] = useState('');
  const [siteContentParamFinalUrl, setSiteContentParamFinalUrl] = useState('');
  const [userParamFinalUrl, setUserParamFinalUrl] = useState('');
  const [customParamFinalUrl, setCustomParamFinalUrl] = useState('');
  const [selectedAppParamFinalUrl, setSelectedAppParamFinalUrl] = useState('');
  const [selectedSiteParamFinalUrl, setSelectedSiteParamFinalUrl] = useState('');

  const [section, sSection] = useState(pSection);
  const [streamType, setStreamType] = useState(
    createTag ? 'vod' : selectedTagDetails?.config?.stream_type?.toString()
  );
  const [type, setType] = useState(
    createTag ? 'app' : selectedTagDetails?.config?.type?.toString()
  );
  const [definition, setDefinition] = useState(
    createTag ? 'query' : selectedTagDetails?.config?.definition?.toString()
  );

  const [SSAIReponseData, sSSAIReponseData] = useState<any>('');

  const [minValue, set_minValue] = useState<any>(
    createTag ? 15 : selectedTagDetails?.config?.min_duration
  );
  const [maxValue, set_maxValue] = useState<any>(
    createTag ? 300 : selectedTagDetails?.config?.max_duration
  );

  // const [adstxtURL, setAdstxtURL] = useState([]);
  const [id, setID] = useState('');
  const [mailSent, sMailSent] = useState(false);
  // const [btnDisabled, sBtnDisabled] = useState(false);
  const [companyID, sCompanyID] = useState();
  const [softFloor, sSoftFloor] = useState(createTag ? false : selectedTagDetails?.is_soft_floor);
  const [slotCount, sSlotCount] = useState(
    createTag ? false : selectedTagDetails?.config?.is_slot_count
  );
  const [count, setCount] = useState(createTag ? 10 : selectedTagDetails?.config?.slot_count);
  const [spinnerBtn, sSpinnerBtn] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   setAdstxtURL(adsTxt);
  // }, [adsTxt]);

  //Manage section step-1 and step-2
  useEffect(() => {
    sSection(pSection);
  }, [pSection]);

  const globalUrlSSAI =
    selectedTagDetails?.config?.stream_type === 'live'
      ? `https://nxs.infy.tv/ssai/master.m3u8?u={url}&t=${tagId}&live=0&avod=1&c=${companyID}`
      : `https://nxs.infy.tv/ssai/master.m3u8?u={url}&t=${tagId}&live=0&avod=1&c=${companyID}&ad_breaks=0,-1`;

  //Create Final macros url
  const tagFinalURLSSAI = (
    globalUrlSSAI +
    appParamFinalUrl +
    selectedAppParamFinalUrl +
    siteParamFinalUrl +
    impParamFinalUrl +
    deviceParamFinalUrl +
    contentParamFinalUrl +
    siteContentParamFinalUrl +
    userParamFinalUrl +
    customParamFinalUrl
  ).replace('?&', '?');

  //get stream type id
  const selectStreamType = (id: string) => {
    setStreamType(id);
    if (id === 'vod') {
      selectDefinition('query');
    } else {
      selectDefinition('hls');
    }
  };

  //get type id
  const selectType = (id: string) => {
    setType(id);
  };

  //get defination id
  const selectDefinition = (id: string) => {
    setDefinition(id);
  };

  const integerReg = /^\d+$/;

  const SSAISchema = Yup.object().shape({
    sourceName: Yup.string().required(glossary.ssp_createssai_input_endpointname_empty_error),
    streamTimeout: Yup.number()
      .typeError(glossary.ssp_createssai_input_stream_type_error)
      .required(glossary.ssp_createssai_input_stream_empty_error)
      .test('superior', glossary.ssp_createssai_input_stream_zero_error, function (f1: any) {
        return f1 > 0;
      })
      .test('superior', glossary.ssp_createssai_input_stream_type_error, function (f2: any) {
        return integerReg.test(f2);
      })
      .max(99999, 'Timeout allow 5 digits'),
    inventoryTimeout: Yup.number()
      .typeError(glossary.ssp_createssai_input_inventory_timeout_type_error)
      .required(glossary.ssp_createssai_input_inventory_timeout_empty_error)
      .test(
        'superior',
        glossary.ssp_createssai_input_inventory_timeout_zero_error,
        function (f1: any) {
          return f1 > 0;
        }
      )
      .test(
        'superior',
        glossary.ssp_createssai_input_inventory_timeout_type_error,
        function (f2: any) {
          return integerReg.test(f2);
        }
      )
      .max(99999, 'Timeout allow 5 digits'),
    floorPrice: Yup.number()
      .typeError(glossary.ssp_createssai_input_fallback_type_error)
      .required(glossary.ssp_createssai_input_fallback_empty_error)
      .test('superior', glossary.ssp_createssai_input_fallback_zero_error, function (f1: any) {
        return f1 > 0;
      })
      .max(99999, 'Floor Price allow 5 digits'),
  });

  const baseRegHLS = /(.m3u8)/g;
  //const baseRegDash = /(.mpd)/g;

  const SSAISchema1 = Yup.object().shape({
    sourceName: Yup.string().required(glossary.ssp_createssai_input_endpointname_empty_error),
    originURL: Yup.string()
      .url(glossary.ssp_createssai_streamtype_live_input_url_error)
      .required(glossary.ssp_createssai_streamtype_live_input_url_empty_error)
      .matches(baseRegHLS, glossary.ssp_createssai_streamtype_live_input_url_valid_error),
    streamTimeout: Yup.number()
      .typeError(glossary.ssp_createssai_input_stream_type_error)
      .required(glossary.ssp_createssai_input_stream_empty_error)
      .test('superior', glossary.ssp_createssai_input_stream_zero_error, function (f1: any) {
        return f1 > 0;
      })
      .max(99999, 'Stream Timeout allow 5 digits'),
    inventoryTimeout: Yup.number()
      .typeError(glossary.ssp_createssai_input_inventory_timeout_type_error)
      .required(glossary.ssp_createssai_input_inventory_timeout_empty_error)
      .test(
        'superior',
        glossary.ssp_createssai_input_inventory_timeout_zero_error,
        function (f1: any) {
          return f1 > 0;
        }
      )
      .max(99999, 'Inventory Timeout allow 5 digits'),
    floorPrice: Yup.number()
      .typeError(glossary.ssp_createssai_input_fallback_type_error)
      .required(glossary.ssp_createssai_input_fallback_empty_error)
      .test('superior', glossary.ssp_createssai_input_fallback_zero_error, function (f1: any) {
        return f1 > 0;
      })
      .max(99999, 'Floor Price allow 5 digits'),
  });

  const handleCreateSSAI = (e: any) => {
    ReactGA.event('ssp_supply_ssai_create_button', {
      category: 'ssai',
      action: 'click',
      label: 'ssp_supply_ssai_create_button',
    });
    const payload = {
      name: e.sourceName,
      tag_type: 'ssai',
      minimum_cpm: e.floorPrice,
      config: {
        name: e.sourceName,
        stream_type: streamType,
        origin_url: e.originURL,
        stream_timeout: parseInt(e.streamTimeout),
        inventory_timeout: parseInt(e.inventoryTimeout),
        type: type,
        definition: definition,
        min_duration: minValue,
        max_duration: maxValue,
        //slot_count: values[0],
        is_slot_count: slotCount,
        slot_count: slotCount === false ? 0 : count,
        pod_construction: slotCount === false ? 'pod_duration' : 'slot_count',
        minimum_cpm: e.floorPrice,
        is_soft_floor: softFloor === true ? true : false,
      },
    };
    sSpinnerBtn(true);
    createTagAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          sSection('2');
          if (data.url) {
            ReactGA.event('ssp_supply_ssai_create_response', {
              category: 'ssai',
              action: 'click',
              label: 'ssp_supply_ssai_create_response',
            });
          }
          //sBtnDisabled(false);
          //setStitchedURL(data.url);
          // setAdstxtURL(data.ads_txt);
          setID(data.id);
          sSSAIReponseData(payload);
          getTags(data);
          sSpinnerBtn(false);
          toast.success(data.message);
        } else {
          sSpinnerBtn(false);
          toast.error(data.message);
        }
      })
      .catch(() => {
        //toast.error(glossary.ssp_createssai_failed_msg);
      });
  };

  const handleUpdateSSAI = (e: any) => {
    ReactGA.event('ssp_supply_ssai_create_button', {
      category: 'ssai',
      action: 'click',
      label: 'ssp_supply_ssai_create_button',
    });
    const payload = {
      tag_id: tagId,
      name: e.sourceName,
      tag_type: 'ssai',
      minimum_cpm: e.floorPrice,
      config: {
        name: e.sourceName,
        stream_type: streamType,
        origin_url: e.originURL,
        stream_timeout: parseInt(e.streamTimeout),
        inventory_timeout: parseInt(e.inventoryTimeout),
        type: type,
        definition: definition,
        min_duration: minValue,
        max_duration: maxValue,
        //slot_count: values[0],
        is_slot_count: slotCount,
        slot_count: slotCount === false ? 0 : count,
        pod_construction: slotCount === false ? 'pod_duration' : 'slot_count',
        minimum_cpm: e.floorPrice,
        is_soft_floor: softFloor === true ? true : false,
      },
    };
    sSpinnerBtn(true);
    editTagAPI(payload)
      .then((data: any) => {
        if (data.status === true) {
          sSection('2');
          if (data.url) {
            ReactGA.event('ssp_supply_ssai_create_response', {
              category: 'ssai',
              action: 'click',
              label: 'ssp_supply_ssai_create_response',
            });
          }
          //sBtnDisabled(false);
          //setStitchedURL(data.url);
          // setAdstxtURL(data.ads_txt);
          setID(data.id);
          sSSAIReponseData(payload);
          getTags(data.data);
          sSpinnerBtn(false);
          toast.success(data.message);
        } else {
          sSpinnerBtn(false);
          toast.error(data.message);
        }
      })
      .catch(() => {
        //toast.error(glossary.ssp_createssai_failed_msg);
      });
  };

  const SSAIEamilSendSchema = Yup.object().shape({
    endIntegrationInstruction: Yup.string()
      .email(glossary.ssp_createssai_success_email_valid_error)
      .required(glossary.ssp_createssai_success_email_empty_error),
  });

  const initiateSendEmailSSAI = (e: any) => {
    if (tagId) {
      ReactGA.event('ssp_supply_ssai_email_send_button', {
        category: 'ssai',
        action: 'click',
        label: 'ssp_supply_ssai_email_send_button',
      });
      const id = tagId;
      const payload = {
        id,
        email: e.endIntegrationInstruction,
        ssai_url: tagFinalURLSSAI,
      };
      sendEmailAPI(payload)
        .then((data: any) => {
          if (data) {
            ReactGA.event('ssp_supply_ssai_email_send_response', {
              category: 'ssai',
              action: 'click',
              label: 'ssp_supply_ssai_email_send_response',
            });
            sMailSent(true);
            toast.success(glossary.ssp_createssai_success_mailsent_msg);
          }
        })
        .catch(() => {
          //
        });
    } else {
      const payload = {
        id,
        email: e.endIntegrationInstruction,
        ssai_url: tagFinalURLSSAI,
      };
      sendEmailAPI(payload)
        .then((data: any) => {
          if (data) {
            sMailSent(true);
            toast.success(glossary.glossary.ssp_createssai_success_mailsent_msg);
          }
        })
        .catch(() => {
          //
        });
    }
  };

  // Get min and max value
  const handleInputRange = (e: any) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  // Get Company id from JWT token
  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    const decoded = JWT.decode(token, { complete: true });
    // @ts-ignore
    sCompanyID(decoded.payload.company_id);
  }, []);

  const GetAppParamFinalUrl = (data: any) => {
    setAppParamFinalUrl(data);
  };

  const GetSiteParamFinalUrl = (data: any) => {
    setSiteParamFinalUrl(data);
  };

  const GetImpParamUFinalUrl = (data: any) => {
    setImpParamFinalUrl(data);
  };

  const GetDeviceParamFinalUrl = (data: any) => {
    setDeviceParamFinalUrl(data);
  };

  const GetContentParamFinalUrl = (data: any) => {
    setContentParamFinalUrl(data);
  };

  const GetSiteContentParamFinalUrl = (data: any) => {
    setSiteContentParamFinalUrl(data);
  };

  const GetUserParamFinalUrl = (data: any) => {
    setUserParamFinalUrl(data);
  };

  const GetCustomParamFinalUrl = (data: any) => {
    setCustomParamFinalUrl(data);
  };

  const GetSelectedAppParamFinalUrl = (data: any) => {
    setSelectedAppParamFinalUrl(data);
  };

  const GetSelectedSiteParamFinalUrl = (data: any) => {
    setSelectedSiteParamFinalUrl(data);
  };

  // increase swipe count
  function handlePlusClick() {
    if (count < 10) {
      setCount(count + 1);
    } else {
      toast.warning('Maximum value reached (10)');
    }
  }

  // decrease swipe count
  function handleMinusClick() {
    if (count > 1) {
      setCount(count - 1);
    }
  }

  return (
    <>
      {section === '1' && (
        <>
          {initialCreate && (
            <div className={'pt-4 px-3'}>
              <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
                {glossary.ssp_createssai_title}
              </h3>
            </div>
          )}
          {kind === 'update' && (
            <div className='modal-header px-3'>
              <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>Update SSAI End Point</h3>
            </div>
          )}
          <Formik
            validationSchema={streamType === 'vod' ? SSAISchema : SSAISchema1}
            initialValues={SSAIInitialValues}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(e) => {
              if (kind === 'update') {
                handleUpdateSSAI(e);
              } else {
                handleCreateSSAI(e);
              }
            }}
          >
            {({ values, errors, touched }) => (
              <Form className='rtb-form'>
                <div
                  className='modal-body p-3'
                  style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto',
                  }}
                >
                  <div className='mb-3 row'>
                    <div className='col-4'>
                      <div className='form-group'>
                        <FormikController
                          kind='withouticon'
                          control='input'
                          type='text'
                          label={glossary.ssp_createssai_input_endpointname_label}
                          placeholder={glossary.ssp_createssai_input_endpointname_placeholder}
                          name='sourceName'
                        />
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='form-group'>
                        <label className='label'>
                          {glossary.ssp_createssai_type_radio_buttons_label}
                        </label>
                        <FormB.Group className='d-flex form-group button-radio'>
                          <Radio
                            label={glossary.ssp_createssai_type_radio_button_app}
                            size={'mini'}
                            id={'app'}
                            selected={type}
                            click={selectType}
                          />
                          <Radio
                            label={glossary.ssp_createssai_type_radio_button_website}
                            size={'mini'}
                            id={'website'}
                            selected={type}
                            click={selectType}
                          />
                        </FormB.Group>
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='form-group'>
                        <label className='label'>
                          {glossary.ssp_createssai_streamtype_radio_buttons_label}
                        </label>
                        <FormB.Group
                          className={
                            kind === 'update'
                              ? 'disable-field d-flex form-group button-radio'
                              : 'd-flex form-group button-radio'
                          }
                        >
                          <Radio
                            label={glossary.ssp_createssai_streamtype_radio_button_vod}
                            size={'mini'}
                            id={'vod'}
                            selected={streamType}
                            click={selectStreamType}
                          />
                          <Radio
                            label={glossary.ssp_createssai_streamtype_radio_button_live}
                            size={'mini'}
                            id={'live'}
                            selected={streamType}
                            click={selectStreamType}
                          />
                        </FormB.Group>
                      </div>
                    </div>
                    <div className='col-5'>
                      <div className='row'>
                        {streamType === 'live' && (
                          <div className='col'>
                            <div
                              className={
                                kind === 'update' ? 'form-group disable-field' : 'form-group'
                              }
                            >
                              <FormikController
                                kind='withouticon'
                                control='input'
                                type='text'
                                label={glossary.ssp_createssai_streamtype_live_input_url_label}
                                placeholder={
                                  glossary.ssp_createssai_streamtype_live_input_url_placeholder
                                }
                                name='originURL'
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-5'>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='form-group'>
                            <FormikController
                              kind='withiconend'
                              control='input'
                              type='number'
                              label={glossary.ssp_createssai_input_stream_timeout_label}
                              placeholder={glossary.ssp_createssai_input_stream_timeout_placeholder}
                              name='streamTimeout'
                            />
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='form-group'>
                            <FormikController
                              kind='withiconend'
                              control='input'
                              type='number'
                              label={glossary.ssp_createssai_input_inventory_timeout_label}
                              placeholder={
                                glossary.ssp_createssai_input_inventory_timeout_placeholder
                              }
                              name='inventoryTimeout'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='form-group'>
                        <label className='label'>
                          {glossary.ssp_createssai_definition_radio_buttons_title}
                        </label>
                        <FormB.Group className='d-flex form-group button-radio'>
                          {streamType !== 'live' && (
                            <>
                              <Radio
                                label={glossary.ssp_createssai_definition_radio_button_query_params}
                                size={'mini'}
                                id={'query'}
                                selected={definition}
                                click={selectDefinition}
                              />
                              <Radio
                                label={glossary.ssp_createssai_definition_radio_button_hls_ad}
                                size={'mini'}
                                id={'hls'}
                                selected={definition}
                                click={selectDefinition}
                              />
                            </>
                          )}
                          {streamType === 'live' && (
                            <Radio
                              label={glossary.ssp_createssai_definition_radio_button_hls_ad}
                              size={'mini'}
                              id={'hls'}
                              selected={'hls'}
                              click={selectDefinition}
                            />
                          )}
                        </FormB.Group>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-5'>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='form-group'>
                            <FormikController
                              kind='withiconstart'
                              control='input'
                              type='number'
                              label={glossary.ssp_createssai_input_label_fallback_floor_price}
                              placeholder={
                                glossary.ssp_createssai_input_placeholder_fallback_floor_price
                              }
                              name='floorPrice'
                            />
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label className='label'>
                              {glossary.ssp_createssai_softfloor_toggle_label}
                              {softFloor
                                ? ` ${glossary.ssp_createssai_softfloor_toggle_enabled_label}`
                                : ` ${glossary.ssp_createssai_softfloor_toggle_disabled_label}`}
                            </label>
                            <div className='mt-2'>
                              <Switch
                                api={(chec: any) => {
                                  chec === true ? sSoftFloor(true) : sSoftFloor(false);
                                }}
                                status={softFloor}
                                disabled={values?.floorPrice ? false : true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-4'>
                      <div>
                        <label className='label'>
                          {glossary.ssp_createssai_pod_duration_rangebar_label}
                        </label>
                        <div className='multi-range-slider-1 px-0'>
                          <MultiRangeSlider
                            min={15}
                            max={300}
                            step={15}
                            label={false}
                            ruler={false}
                            stepOnly={true}
                            minValue={minValue}
                            maxValue={maxValue}
                            onChange={(e) => {
                              handleInputRange(e);
                            }}
                            style={{ border: 'none', boxShadow: 'none', padding: '15px 10px' }}
                            barLeftColor='#a2a2a2'
                            barInnerColor='#731780'
                            barRightColor='#a2a2a2'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className='d-flex'>
                        <div className='form-group'>
                          <label className='label'>
                            {glossary.ssp_createtag_slot_count_rangebar_label}
                          </label>
                          <div className='mt-3'>
                            <Switch
                              api={(chec: any) => {
                                chec === true ? sSlotCount(true) : sSlotCount(false);
                              }}
                              status={slotCount}
                            />
                          </div>
                        </div>
                        {slotCount && (
                          <div className='d-flex' style={{ marginTop: 30 }}>
                            <Button
                              type='button'
                              kind={count <= 1 ? 'disabled-secondary' : 'primary'}
                              click={handleMinusClick}
                              text='-'
                              css={{
                                minWidth: 40,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                            />
                            <div
                              className='d-flex align-items-center justify-content-center'
                              style={{
                                borderTop: '1px solid #cbcbcb',
                                borderBottom: '1px solid #cbcbcb',
                                width: 50,
                                height: 38,
                              }}
                            >
                              <p className='text-black'>{count}</p>
                            </div>
                            <Button
                              type='button'
                              kind={count >= 10 ? 'disabled-secondary' : 'primary'}
                              click={handlePlusClick}
                              text='+'
                              css={{
                                minWidth: 40,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <span className='align-items-center d-flex gap-1 text-info-1'>
                        <MdInfoOutline fontSize={18} className='s-svg-icon mb-1' />{' '}
                        {glossary.ssp_createssai_instruction_one}
                      </span>
                    </div>
                    {softFloor && (
                      <div className='col-12'>
                        <span className='align-items-center d-flex gap-1 text-info-1'>
                          <MdInfoOutline fontSize={18} className='s-svg-icon mb-1' />
                          {glossary.ssp_createssai_softfloor_toggle_instruction}
                          <a
                            href='https://help.infy.tv/en/'
                            target='_blank'
                            rel='noreferrer'
                            style={{ color: '#731780' }}
                          >
                            {glossary.ssp_createtag_instruction_hyperlink}
                          </a>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className={initialCreate ? 'px-3 pb-3' : 'modal-footer px-3'}>
                  <div className='d-flex w-100 justify-content-between'>
                    <div className='col-auto'>
                      <div className='d-flex gap-2 justify-content-around'>
                        <div
                          className={
                            section === '1' ? 'createtag-pagination active' : 'createtag-pagination'
                          }
                        ></div>
                        <div
                          className={
                            section === '2' ? 'createtag-pagination active' : 'createtag-pagination'
                          }
                        ></div>
                      </div>
                      <label className='mt-2'>{glossary.ssp_createssai_note_one}</label>
                    </div>
                    <div className='col-auto'>
                      <div className='d-flex gap-3'>
                        {!initialCreate && (
                          <Button
                            type='button'
                            kind={'secondary'}
                            customClass={'ms-3'}
                            text={'Close'}
                            click={onHide}
                          />
                        )}
                        {spinnerBtn ? (
                          <Button
                            type='button'
                            kind={'spinner'}
                            customClass={'col-auto'}
                            text={'Loading...'}
                          />
                        ) : (
                          <Button
                            type={'submit'}
                            kind={'primary'}
                            text={
                              kind === 'update' ? 'Update' : glossary.ssp_createssai_create_button
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}

      {section === '2' && (
        <>
          <div className={createTag ? 'mt-4 px-3' : 'modal-header px-3'}>
            <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
              {glossary.ssp_createssai_success_title}
            </h3>
          </div>
          <div
            className='modal-body p-3'
            style={{
              maxHeight: initialCreate ? '100%' : 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            <Formik
              validationSchema={SSAIEamilSendSchema}
              initialValues={SendEmailSSAIValues}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(e) => {
                initiateSendEmailSSAI(e);
              }}
            >
              {({ errors, touched }) => (
                <Form className='rtb-form'>
                  <div className='row align-items-center mb-3'>
                    <div className='col-6'>
                      <div className='d-flex'>
                        <div className='form-group'>
                          <FormikController
                            kind='withouticon'
                            control='input'
                            type='text'
                            label={glossary.ssp_createssai_success_input_email}
                            placeholder={glossary.ssp_createssai_success_input_placeholder_email}
                            name='endIntegrationInstruction'
                            length={320}
                          />
                        </div>
                        <div className='d-flex flex-column'>
                          <label className='label'>&nbsp;</label>
                          <button
                            type='submit'
                            className={
                              mailSent
                                ? 'button-disabled button button-large button-primary ms-3'
                                : 'button button-large button-primary ms-3'
                            }
                          >
                            {glossary.ssp_createssai_success_button_send}
                          </button>
                        </div>
                      </div>
                    </div>
                    {selectedTagDetails ? (
                      <div className='col-6'>
                        <div className='row'>
                          <div className={'col-2'}>
                            <label className='label'>
                              {glossary.ssp_createtag_success_field_label_floor_price}
                            </label>
                            <p>{selectedTagDetails?.floor_price_formatted}</p>
                          </div>
                          <div className={'col-2'}>
                            <label className='label'>
                              {glossary.ssp_createtag_success_field_label_is_soft}
                            </label>
                            <p>{selectedTagDetails?.is_soft_floor === true ? 'On' : 'Off'}</p>
                          </div>
                          <div className={'col-auto'}>
                            <label className='label'>
                              {glossary.ssp_createtag_success_field_label_duration_range}
                            </label>
                            <p>
                              {selectedTagDetails?.config?.min_duration}-
                              {selectedTagDetails?.config?.max_duration}
                            </p>
                          </div>
                          <div className={'col-2'}>
                            <label className='label'>Slot Count</label>
                            <p>{selectedTagDetails?.config?.slot_count}</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='col-6'>
                        <div className='row'>
                          <div className={'col-2'}>
                            <label className='label'>
                              {glossary.ssp_createtag_success_field_label_floor_price}
                            </label>
                            <p>{SSAIReponseData?.config?.minimum_cpm}</p>
                          </div>
                          <div className={'col-2'}>
                            <label className='label'>
                              {glossary.ssp_createtag_success_field_label_is_soft}
                            </label>
                            <p>{SSAIReponseData?.config?.is_soft_floor === true ? 'On' : 'Off'}</p>
                          </div>
                          <div className={'col-auto'}>
                            <label className='label'>
                              {glossary.ssp_createtag_success_field_label_duration_range}
                            </label>
                            <p>
                              {SSAIReponseData?.config?.min_duration}-
                              {SSAIReponseData?.config?.max_duration}
                            </p>
                          </div>
                          <div className={'col-2'}>
                            <label className='label'>
                              {glossary.ssp_createssai_pod_radio_button_slot_count}
                            </label>
                            <p>{SSAIReponseData?.config?.slot_count}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>

            <MacrosDropDown
              createTag={createTag}
              selectedTagDetails={selectedTagDetails}
              deviceType={type}
              appParamFinalUrl={GetAppParamFinalUrl}
              siteParamFinalUrl={GetSiteParamFinalUrl}
              impParamUFinalUrl={GetImpParamUFinalUrl}
              deviceParamFinalUrl={GetDeviceParamFinalUrl}
              contentParamFinalUrl={GetContentParamFinalUrl}
              siteContentParamFinalUrl={GetSiteContentParamFinalUrl}
              userParamFinalUrl={GetUserParamFinalUrl}
              customParamFinalUrl={GetCustomParamFinalUrl}
              selectedAppParamFinalUrl={GetSelectedAppParamFinalUrl}
              selectedSiteParamFinalUrl={GetSelectedSiteParamFinalUrl}
            />

            <div className='row'>
              <div className='col-12'>
                <div className='createtag-vasturl'>
                  {createTag ? (
                    <p className='createtag-macrosurl'>
                      <>
                        {type === 'app' &&
                          (globalUrlSSAI +
                            // selectedType +
                            // selectedDefinition +
                            // selectedCunstruction +
                            selectedAppParamFinalUrl +
                            deviceParamFinalUrl +
                            contentParamFinalUrl +
                            userParamFinalUrl +
                            customParamFinalUrl ??
                            '')}
                        {type === 'website' &&
                          (globalUrlSSAI +
                            // selectedType +
                            // selectedDefinition +
                            // selectedCunstruction +
                            selectedSiteParamFinalUrl +
                            siteParamFinalUrl +
                            deviceParamFinalUrl +
                            siteContentParamFinalUrl +
                            userParamFinalUrl +
                            customParamFinalUrl ??
                            '')}
                      </>
                    </p>
                  ) : (
                    <p className='createtag-macrosurl'>
                      {globalUrlSSAI +
                        appParamFinalUrl +
                        siteParamFinalUrl +
                        impParamFinalUrl +
                        deviceParamFinalUrl +
                        contentParamFinalUrl +
                        siteContentParamFinalUrl +
                        userParamFinalUrl +
                        customParamFinalUrl ?? ''}
                    </p>
                  )}

                  {globalUrlSSAI && (
                    <div className='createtag-vasturl-icon'>
                      {createTag ? (
                        <>
                          {type === 'app' && (
                            <IoCopyOutline
                              className='svg-icon'
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  globalUrlSSAI +
                                    // selectedType +
                                    // selectedDefinition +
                                    // selectedCunstruction +
                                    selectedAppParamFinalUrl +
                                    impParamFinalUrl +
                                    deviceParamFinalUrl +
                                    contentParamFinalUrl +
                                    userParamFinalUrl +
                                    customParamFinalUrl
                                );
                                toast.success(
                                  glossary.ssp_createssai_success_endpoint_url_copy_success_msg
                                );
                              }}
                              title={'Copy URL'}
                              fontSize={20}
                            />
                          )}
                          {type === 'website' && (
                            <IoCopyOutline
                              className='svg-icon'
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  globalUrlSSAI +
                                    // selectedType +
                                    // selectedDefinition +
                                    // selectedCunstruction +
                                    selectedSiteParamFinalUrl +
                                    siteParamFinalUrl +
                                    impParamFinalUrl +
                                    deviceParamFinalUrl +
                                    siteContentParamFinalUrl +
                                    userParamFinalUrl +
                                    customParamFinalUrl
                                );
                                toast.success(
                                  glossary.ssp_createssai_success_endpoint_url_copy_success_msg
                                );
                              }}
                              title={'Copy URL'}
                              fontSize={20}
                            />
                          )}
                        </>
                      ) : (
                        <IoCopyOutline
                          className='svg-icon'
                          onClick={() => {
                            navigator.clipboard.writeText(
                              globalUrlSSAI +
                                appParamFinalUrl +
                                impParamFinalUrl +
                                siteParamFinalUrl +
                                deviceParamFinalUrl +
                                contentParamFinalUrl +
                                siteContentParamFinalUrl +
                                userParamFinalUrl +
                                customParamFinalUrl
                            );
                            toast.success(
                              glossary.ssp_createssai_success_endpoint_url_copy_success_msg
                            );
                          }}
                          title={'Copy URL'}
                          fontSize={20}
                        />
                      )}
                    </div>
                  )}
                </div>
                <span className='text-info-1 mt-2 d-flex align-items-center'>
                  <MdInfoOutline className='s-svg-icon me-1' fontSize={20} />
                  {glossary.ssp_createssai_success_instruction_one}
                </span>
              </div>
              {/* Temporary hide Ads.txt */}
              {/* <div className='col-6'>
                {adstxtURL && (
                  <div className='createtag-vasturl'>
                    {adstxtURL.map((record: any) => {
                      return (
                        <>
                          <p>{record}</p>
                        </>
                      );
                    })}
                    <div className='createtag-vasturl-icon'>
                      <IoCopyOutline
                        className='svg-icon'
                        onClick={() => {
                          navigator.clipboard.writeText(adstxtURL?.toString());
                          toast.success(glossary.ssp_createssai_success_adstxt_copy_success_msg);
                        }}
                        title={'Copy Records'}
                        fontSize={20}
                      />
                    </div>
                  </div>
                )}
                <label className='form-label mt-1'>
                  <span>{glossary.ssp_createssai_success_instruction_two}</span>
                </label>
              </div> */}
            </div>
          </div>
          <div className={initialCreate ? 'px-3 p-3' : 'modal-footer px-3'}>
            <div
              className={
                createTag && section === '2'
                  ? 'd-flex w-100 justify-content-between'
                  : 'd-flex w-100 justify-content-end'
              }
            >
              <div className={createTag && section === '2' ? 'col-auto' : 'd-none'}>
                <div className='d-flex gap-2 justify-content-around'>
                  <div
                    className={
                      section === '1' ? 'createtag-pagination active' : 'createtag-pagination'
                    }
                  ></div>
                  <div
                    className={
                      section === '2' ? 'createtag-pagination active' : 'createtag-pagination'
                    }
                  ></div>
                </div>
                <label className='mt-2'>{glossary.ssp_createssai_success_note_one}</label>
              </div>
              <div className='col-auto gap-3 d-flex'>
                {!initialCreate && (
                  <Button
                    kind={'secondary'}
                    text={'Close'}
                    click={() => {
                      sMailSent(false);
                      onHide();
                    }}
                  />
                )}
                <Button
                  kind={'primary'}
                  text={glossary.ssp_createssai_success_button_done}
                  click={() => {
                    sMailSent(false);
                    ReactGA.event('ssp_supply_ssai_details_done_button', {
                      category: 'ssai',
                      action: 'click',
                      label: 'ssp_supply_ssai_details_done_button',
                    });
                    initialCreate ? windowReload() : onHide();
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SSAIEndpoint;
