import { useEffect, useState } from 'react';
import Tile from '../../widgets/tile';
import TableSkeleton from '../../loaders/table';
import DspInventories from '../../tables/dspInventories';
import { inventory as inventoryTiles } from '../../helpers/tiles';
import { sspGetInventoryOverviewAPI } from '../../../services/inventory';
import { getDemandEndpointInventoriesAPI } from '../../../services/endpoint';
import { getPublisherInventoriesAPI } from '../../../services/tags';
import Box from '../../loaders/box';

const SPOInventories = (props: any) => {
  const [, /* selectedTile */ sSelectedTile] = useState(0);
  const [inventoryTilesData, sInventoryTilesData] = useState<any>([]);
  const [dealTerms, sDealTerms] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [endpointTraffic, setEndpointTraffic] = useState<any>([]);

  const selectTile = (id: number) => {
    sSelectedTile(id);
  };

  useEffect(() => {
    if (props.selectedCardId) {
      getEndpointTraffic();
      inventoryOverview();
    }
  }, [props.selectedCardId]);

  const getEndpointTraffic = () => {
    const payload = {
      ssp_id: [props.selectedCardId],
    };
    setLoading(true);
    getPublisherInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          setEndpointTraffic(data);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  //Get Table data after action on Table
  const getTrafficData = () => {
    const payload = {
      iab_category: dealTerms.cat,
      device_type: dealTerms.dev,
      platform_category: dealTerms.pla,
      country: dealTerms.ctr,
      state: dealTerms.stt,
      city: dealTerms.cty,
      ssp_id: dealTerms.ssp_id,
      end_point: props.selectedCardId,
    };
    getDemandEndpointInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          setEndpointTraffic(data);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  //Get Inventories overview
  const inventoryOverview = () => {
    const payload = {
      ssp_id: [props.selectedCardId],
    };

    sspGetInventoryOverviewAPI(payload).then((data: any) => {
      const tileData = inventoryTiles(data?.data ?? []);
      sInventoryTilesData(tileData);
    });
  };

  return (
    <div className={'content'} style={{ display: 'flex' }}>
      <div className='w-100'>
        {loading ? (
          <>
            <div
              className='row align-items-center justify-content-between'
              style={{
                marginTop: 56,
              }}
            >
              <div className='col-6'>
                <div className='d-flex gap-4'>
                  {[1, 2, 3, 4].map((index) => {
                    return <Box key={index} />;
                  })}
                </div>
              </div>
              <div className='col-2'>&nbsp;</div>
            </div>
          </>
        ) : (
          <>
            <div
              className={'row overview'}
              style={{
                marginTop: 34,
              }}
            >
              {inventoryTilesData.length > 0 &&
                inventoryTilesData.map((tile: any, index: any) => {
                  return (
                    <div key={index} className={'col-auto'}>
                      <Tile
                        onHover={'disabled'}
                        id={tile.id}
                        selected={99}
                        click={selectTile}
                        label={tile.label}
                        value={tile.value}
                        info={tile.info}
                        arrow={tile.arrow}
                        deals
                        type={'number'}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        )}
        <>
          {loading ? (
            <div className={'row'} style={{ marginTop: 40, overflow: 'scroll' }}>
              <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
            </div>
          ) : endpointTraffic?.data?.length > 0 ? (
            <>
              <div className={'row'} style={{ overflow: 'auto' }}>
                <DspInventories
                  rows={endpointTraffic?.data ?? []}
                  endpoint_id={props.selectedCardId}
                  getTrafficData={getTrafficData}
                  type={props.type}
                />
              </div>
            </>
          ) : (
            <h3 className='text-center mt-5'>Data not found</h3>
          )}
        </>
      </div>
    </div>
  );
};

export default SPOInventories;
