import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Dropdown from '../../widgets/dropdown';
import countryCodes from '../../../states/countryCodes';
import { UserInfoContext } from '../../contexts/userInfoContext';
import { toast } from 'react-toastify';
import OtpInput from '../extension/OtpInput';
import {
  creativeCompleteProfileAPI,
  creativeSendOtpAPI,
  creativeUserValidateAPI,
  creativeVerifyOtpAPI,
} from '../../../services/creative';
import { useHistory } from 'react-router-dom';
import JWT from 'jsonwebtoken';
import { IoMdCheckmark } from 'react-icons/io';
import Button from '../../widgets/button';
import { formatDobDate } from '../../helpers/functions';
import Calender from '../../comman/datepicker/Calender';

interface Props {
  show: boolean;
  onHide(): void;
}

const CreativesLogin = ({ show, onHide }: Props) => {
  //Context API
  const { glossary, handleInitialState }: any = useContext(UserInfoContext);
  const { push } = useHistory();

  const [selectedOption, setSelectedOption] = useState({ value: 1, label: '+1' });
  const [phoneNo, setPhoneNo] = useState<any>('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [hasOTPSent, setHasOTPSent] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [otp, setOtp] = useState('');
  const [enteredPhoneNo, setEnteredPhoneNo] = useState('');
  const [validateOTP, sValidateOTP] = useState(false);
  const [loginUserID, setLoginUserID] = useState('');
  const [isPreConditionErr, setIsPreConditioErr] = useState(false);
  const [isProfileCompleted, setIsProfileCompleted] = useState(true);

  const [OTPSending, setOTPSending] = useState(false);
  const [otpVerifyLoader, setOtpVerifyLoader] = useState(false);

  const [validGenuinUser, setValidGenuinUser] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [profileSubmitting, setProfileSubmitting] = useState(false);
  const [selectedDob, setSelectedDob] = useState<any>(new Date('2000/01/01'));

  // Handler Start
  const debounce = (func: any, delay: any) => {
    let timeoutId: any;
    return function (...args: any) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        //@ts-ignore
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedHandlePhoneNumber = useCallback(
    debounce((value: any) => {
      const regex = /^[0-9]{10,16}$/;
      if (regex.test(value)) {
        setPhoneNumberError(false);
      } else {
        setPhoneNumberError(true);
      }
    }, 1000),
    []
  );
  // Handler End

  const handlePhoneNumber = (event: any) => {
    const value = event.target.value;
    //setPhoneNo(value);
    if (/^[0-9]*$/.test(value)) {
      setPhoneNo(value);
      debouncedHandlePhoneNumber(value);
    }
  };

  const onChangeOTP = (value: string) => setOtp(value);

  // Delay Handler
  const [delay, setDelay] = useState<any>();
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  // Only for Resend button
  useMemo(() => {
    if (delay === 0) {
      setResendOTP(true);
      setIsPreConditioErr(false);
    } else {
      setResendOTP(false);
    }
  }, [delay]);

  const handleSendOTP = (_kind: string) => {
    const payload = {
      phone_number: `${selectedOption.label + phoneNo}`,
    };
    setOTPSending(_kind === 'send' ? true : false); // Resend button
    if (_kind === 'resend') setResendOTP(false); // Resend button
    setEnteredPhoneNo(`${selectedOption.label + phoneNo}`);
    creativeSendOtpAPI(payload)
      .then((data: any) => {
        if (data.status) {
          setDelay(data?.data?.retryTime);
          setLoginUserID(data?.data?.user_id);
          toast.success(data?.message);
          setOTPSending(false);
          setHasOTPSent(true);
          setOtp('');
        } else {
          if (data?.message?.code === '1009') setPhoneNumberError(true);
          if (data?.message?.data?.retryTime) {
            setIsPreConditioErr(true);
            setDelay(data?.message?.data?.retryTime);
          }

          toast.error(data.message);
          setOTPSending(false);
        }
      })
      .catch((error: any) => {
        setOTPSending(false);
      });
  };

  const handleValidateOTP = () => {
    const payload = {
      phone_number: enteredPhoneNo,
      otp: otp,
      user_id: loginUserID,
    };
    setOtpVerifyLoader(true);
    creativeVerifyOtpAPI(payload)
      .then((data: any) => {
        if (data?.access_token) {
          const tokenPayload: any = JWT.decode(data.access_token);
          localStorage.setItem('r', 'customer');
          localStorage.setItem('token', data.access_token);
          localStorage.setItem('customer_type', data.customer_type);
          localStorage.setItem('role_id', tokenPayload?.role);
          push('/my-creatives');
          handleInitialState();
        } else {
          setIsProfileCompleted(data?.complete_profile === false ? false : true);
          sValidateOTP(false);
          if (data.message !== '') toast.error(data.message);
        }
        setOtpVerifyLoader(false);
      })
      .catch(() => {
        setOtpVerifyLoader(false);
      });
  };

  const clearAll = () => {
    setResendOTP(false);
    setOTPSending(false);
    setOtp('');
    setHasOTPSent(false);
    setIsPreConditioErr(false);
    setLoginUserID('');
    sValidateOTP(false);
    setEnteredPhoneNo('');
    setPhoneNumberError(false);
  };

  // Validate Genuin User

  const isIdEmpty = validGenuinUser !== '' ? false : true;

  const verifyGenuinId = (user_id: string) => {
    const payload = {
      nickname: user_id,
    };
    setIsValidating(true);
    creativeUserValidateAPI(payload)
      .then((data: any) => {
        if (data.status) {
          setIsVerified(true);
        } else {
          setIsVerified(false);
        }
        setIsValidating(false);
      })
      .catch(() => {
        // Server Error
      });
  };

  /* Verifying Genuin ID's */
  useEffect(() => {
    if (validGenuinUser?.length > 0) {
      setIsValidating(true);
      setIsVerified(false);
      const getData = setTimeout(() => {
        verifyGenuinId(validGenuinUser);
      }, 500);

      return () => clearTimeout(getData);
    }

    if (validGenuinUser === '') {
      setTimeout(() => {
        setIsValidating(false);
        setIsVerified(false);
      }, 200);
    }
  }, [validGenuinUser.length]);

  const getDob = (_date: any) => {
    setSelectedDob(_date);
  };

  const handleProfileComplete = () => {
    const payload = {
      user_id: loginUserID,
      nickname: validGenuinUser,
      dob: formatDobDate(selectedDob),
    };
    setProfileSubmitting(true);
    creativeCompleteProfileAPI(payload)
      .then((data: any) => {
        if (data?.access_token) {
          const tokenPayload: any = JWT.decode(data.access_token);
          localStorage.setItem('r', 'customer');
          localStorage.setItem('token', data.access_token);
          localStorage.setItem('customer_type', data.customer_type);
          localStorage.setItem('role_id', tokenPayload?.role);
          push('/my-creatives');
          handleInitialState();
        } else {
          toast.error(data?.message || glossary.service_unavailable);
        }
        setProfileSubmitting(false);
      })
      .catch((error: any) => {
        setProfileSubmitting(false);
        toast.error(glossary.service_unavailable);
      });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
      }}
      centered
      size={'lg'}
      backdrop={OTPSending || hasOTPSent ? 'static' : true}
      keyboard={OTPSending || hasOTPSent ? false : true}
    >
      <div
        className='modal-body'
        style={{
          margin: '20px 100px 20px 100px',
        }}
      >
        {isProfileCompleted && (
          <>
            <div className='row mb-3'>
              <div className='col-12'>
                <label className='label'>Phone No.</label>
                <div className='row'>
                  <div className='col'>
                    <div className='input-group'>
                      <span className='input-group-text p-0 country-select'>
                        <Dropdown
                          label=''
                          option={countryCodes}
                          onchange={(event: any) => {
                            clearAll();
                            setSelectedOption(event);
                          }}
                          value={selectedOption}
                          search={true}
                          classNamePrefix={'country'}
                          disabled={
                            !OTPSending && (isNaN(delay) || delay === 0 || resendOTP) ? false : true
                          }
                        />
                      </span>
                      <input
                        type={'text'}
                        className='form-control'
                        inputMode='numeric'
                        onChange={(event: any) => {
                          clearAll();
                          handlePhoneNumber(event);
                        }}
                        disabled={
                          !OTPSending && (isNaN(delay) || delay === 0 || resendOTP) ? false : true
                        }
                        value={phoneNo}
                        maxLength={16}
                      />
                    </div>
                  </div>
                  <div className='col-auto'>
                    {OTPSending && (
                      <button
                        className='button button-primary'
                        style={{ minWidth: 98 }}
                        type='button'
                        disabled
                      >
                        <span
                          className='spinner-border spinner-border-sm'
                          role='status'
                          aria-hidden='true'
                        ></span>
                      </button>
                    )}

                    {!OTPSending && (
                      <>
                        {isPreConditionErr || hasOTPSent ? (
                          <button type='button' disabled className={'button button-disabled'}>
                            Send OTP
                          </button>
                        ) : (
                          <button
                            type='button'
                            disabled={phoneNo.length >= 10 && !phoneNumberError ? false : true}
                            className={`button ${
                              phoneNo.length >= 10 && !phoneNumberError
                                ? 'button-primary'
                                : 'button-disabled'
                            }`}
                            onClick={() => handleSendOTP('send')}
                          >
                            Send OTP
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {phoneNumberError && (
                  <p className='small text-danger'>
                    {glossary.ssp_createext_input_phone_valid_error}
                  </p>
                )}
              </div>
              <p>
                {isPreConditionErr && minutes >= 0 && seconds >= 0 ? (
                  <>
                    <p className='small text-danger' style={{ maxWidth: '440px' }}>
                      You have attempted to send an OTP multiple times with this mobile number.
                      Please try again after the specified waiting period has elapsed.
                    </p>
                    <span>
                      {glossary.ssp_createext_field_label_time_remaining} {minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  </>
                ) : null}
              </p>
            </div>

            {hasOTPSent && !validateOTP && (
              <div className='mb-3 row'>
                <div className='col-12'>
                  <div className='text-left'>
                    <h6>{glossary.ssp_createext_otp_instruction}</h6>
                    <div>
                      <span>{glossary.ssp_createext_otp_instruction_code_sent}</span>
                      {'  '}
                      <small>*******{enteredPhoneNo && enteredPhoneNo?.slice(-4)}</small>
                    </div>

                    <p>
                      {minutes === 0 && seconds === 0 ? null : (
                        <span>
                          {glossary.ssp_createext_field_label_time_remaining} {minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className='col-12'>
                  <div id='otp' className='d-flex gap-4'>
                    <OtpInput value={otp} valueLength={6} onChange={onChangeOTP} />
                    {otpVerifyLoader ? (
                      <button
                        className='button button-primary'
                        style={{ minWidth: 87 }}
                        type='button'
                        disabled
                      >
                        <span
                          className='spinner-border spinner-border-sm'
                          role='status'
                          aria-hidden='true'
                        ></span>
                      </button>
                    ) : (
                      <button
                        type='button'
                        className={
                          otp.length === 6
                            ? 'col-auto button button-primary'
                            : 'col-auto button button-disabled'
                        }
                        onClick={handleValidateOTP}
                      >
                        {glossary.ssp_createext_field_label_validate}
                      </button>
                    )}
                  </div>
                </div>

                {/* Resend OTP Section */}
                {hasOTPSent && resendOTP && minutes === 0 && seconds === 0 && (
                  <div className='col-12 mt-2'>
                    {`Didn't get a code?`}{' '}
                    <b className='text-button' onClick={() => handleSendOTP('resend')}>
                      Resend OTP
                    </b>
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {!isProfileCompleted && (
          <>
            <div className='row'>
              <div className='col-3'></div>
              <div className='col-6'>
                <label className='label'>User Name</label>
                <div className={'input-group'}>
                  <span className='input-group-text'>@</span>
                  <input
                    type='text'
                    className='form-control'
                    onChange={(event: any) => {
                      setValidGenuinUser(event.target.value);
                    }}
                    placeholder={'Enter Username'}
                    value={validGenuinUser}
                  />

                  {!isValidating && isVerified && !isIdEmpty && (
                    <span className='input-group-text bg-transparent'>
                      <IoMdCheckmark fontSize={20} color={'green'} />
                    </span>
                  )}
                </div>
                {!isVerified && !isIdEmpty && !isValidating && (
                  <p className='small text-danger'>Username already taken. Please pick another.</p>
                )}
              </div>
              <div className='col-3'></div>
            </div>
            <div className='row mt-1'>
              <div className='col-3'></div>
              <div className='col-6'>
                <Calender onChange={getDob} label='DOB' />
              </div>
              <div className='col-3'></div>
            </div>
            <div className='row mt-3'>
              <div className='col-3'></div>
              <div className='col-6'>
                {profileSubmitting ? (
                  <Button kind={'spinner'} type='button' size='full' text={glossary.loading} />
                ) : (
                  <Button
                    kind={isVerified && !isIdEmpty && !isValidating ? 'primary' : 'disabled'}
                    text='Submit'
                    type='button'
                    size='full'
                    click={handleProfileComplete}
                  />
                )}
              </div>
              <div className='col-3'></div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CreativesLogin;
