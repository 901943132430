import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { generateTokenAPI } from '../../services/authentication';
import store from '../../states';
import Button from '../widgets/button';

export default () => {
  const { state } = useLocation();
  const { push } = useHistory();

  const generateToken = () => {
    // @ts-ignore
    generateTokenAPI({ method: 'forget', email: state?.email })
      .then((data: any) => {
        if (data.status) toast.success(glossary.forget_password_linksent_message);
        else toast.error(data.msg);
      })
      .catch(() => null);
  };

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: 'calc(100vh - 66px)',
      }}
    >
      <div
        className={'card-box'}
        style={{
          margin: 'auto',
          rowGap: '16px',
          width: '550px',
          display: 'flex',
          padding: '72px 118px',
          flexDirection: 'column',
        }}
      >
        <h3 style={{ fontWeight: 300, textAlign: 'center' }}>
          {glossary.forget_password_recovery_title}
        </h3>
        <p style={{ textAlign: 'center' }}>{glossary.forget_password_recovery_description}</p>
        <div>
          <p style={{ textAlign: 'center' }}>
            {glossary.forget_password_recovery_text}
            <Button
              kind={'link'}
              text={glossary.forget_password_recovery_hyperlink}
              click={() => generateToken()}
            />
          </p>
        </div>

        <Button kind='primary' text={'Login'} click={() => push('/login')} />
      </div>
    </div>
  );
};
