import { useContext, useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { toast } from 'react-toastify';
import { creativeBrandConfigLogoUploadAPI } from '../../../services/creative';
import { UserInfoContext } from '../../contexts/userInfoContext';

const defaultSrc = '';
interface Props {
  type: string;
}
export const CreativeBrandLogo = ({ type }: Props) => {
  const { customerInfo, setCustomerInfo }: any = useContext(UserInfoContext);

  //const { localStoreURL } = props;
  const [error, setError] = useState<any>(null);
  const [image, setImage] = useState<any>(defaultSrc);
  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState<any>();
  const [fileData, sFileData] = useState({});
  const [uploadFileStaus, sUploadFileStaus] = useState(false);
  const [profileLoader, sProfileLoader] = useState(false);

  const [advertiserLogo, setAdvertiserLogo] = useState('');
  const [agencyLogo, setAgencyLogo] = useState('');

  const uploadProfilePhoto = (e: any) => {
    sUploadFileStaus(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    // Check if the file size is within the allowed limit (in bytes)
    const maxSize = 2 * 1024 * 1024; // 2 MB
    if (file.size > maxSize) {
      setError('File size is too large. Please select a file smaller than 2MB.');
      setImage(null);
      return;
    }

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    reader.onload = () => {
      // Check if the file type is valid (in this example, we only allow images)
      if (file.type.split('/')[0] !== 'image') {
        setError('Invalid file type. Please select an image file.');
        setImage(null);
      } else {
        setError(null);
        setImage(reader.result as any);
      }
    };
    reader.readAsDataURL(files[0]);

    sFileData(e?.target?.files[0]);
  };

  const updateCustomerInfoObj = (_type: string, _url: string) => {
    setCustomerInfo((prev: any) => {
      return {
        ...prev,
        rtb_config: {
          ...prev.rtb_config,
          creative_data: {
            ...prev.rtb_config.creative_data,
            ...(_type === 'agency' ? { agency_logo: _url } : { advertiser_logo: _url }),
          },
        },
      };
    });
    _type === 'agency' ? setAgencyLogo(_url) : setAdvertiserLogo(_url);
  };

  const getCropData = (data: any) => {
    sProfileLoader(true);
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  useEffect(() => {
    if (cropData && uploadFileStaus) {
      const payload = {
        file_data: cropData,
        type: type === 'agency' ? 'creative_agency' : 'creative_advertiser',
      };
      sProfileLoader(true);
      creativeBrandConfigLogoUploadAPI(payload)
        .then((data: any) => {
          sUploadFileStaus(false);
          if (data.status === true) {
            updateCustomerInfoObj(type, data?.url);
            sProfileLoader(false);
            toast.success(type === 'agency' ? 'Agency logo updated.' : 'Advertiser logo updated.');
          }
        })
        .catch((error: any) => {
          toast.error('Something went wrong, please try later.');
        });
    }
  }, [cropData]);

  useEffect(() => {
    if (customerInfo?.rtb_config?.creative_data) {
      setAdvertiserLogo(customerInfo?.rtb_config?.creative_data?.advertiser_logo);
      setAgencyLogo(customerInfo?.rtb_config?.creative_data?.agency_logo);
    }
  }, [customerInfo]);

  return (
    <>
      <div className='row'>
        <div className='col-4'>
          {uploadFileStaus ? (
            <div className='position-relative' style={{ width: 200 }}>
              <Cropper
                style={{
                  height: 200,
                  width: 200,
                  borderRadius: '50%',
                  //overflow: 'hidden',
                  backgroundColor: 'gray',
                }}
                zoomable={false}
                zoomTo={1}
                initialAspectRatio={1}
                preview='.img-preview'
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={true}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
          ) : (
            <div className='position-relative' style={{ width: 200 }}>
              <div
                className='align-items-center d-flex justify-content-center'
                style={{ width: '200px', height: '200px', borderRadius: '50%', overflow: 'hidden' }}
              >
                {type === 'agency' ? (
                  <img
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    src={
                      agencyLogo ||
                      'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png'
                    }
                    alt=''
                  />
                ) : (
                  <img
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    src={
                      advertiserLogo ||
                      'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png'
                    }
                    alt=''
                  />
                )}
              </div>
            </div>
          )}
          <div className='position-relative' style={{ width: 200 }}>
            <div className='upload'>
              {uploadFileStaus && !error ? (
                <>
                  {profileLoader ? (
                    <div className='upload-loading'></div>
                  ) : (
                    <div className='upload-button'>
                      <span title='upload image' onClick={() => getCropData(fileData)}>
                        &nbsp;
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <div className='upload-file'>
                  <input
                    type='file'
                    accept='image/*'
                    title='upload image'
                    onChange={(e) => uploadProfilePhoto(e)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {error && (
        <p
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            color: 'red',
            margin: '0 auto',
            width: '150px',
          }}
        >
          {error}
        </p>
      )}
    </>
  );
};

export default CreativeBrandLogo;
