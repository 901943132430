import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pill from '../../widgets/pill';
import CustomerTabs from 'react-bootstrap/Tabs';
import CustomerTab from 'react-bootstrap/Tab';
// import Bar from '../../charts/bar';
// import Line from '../../charts/line';
// import Doughnut from '../../charts/doughnut';

// import BarGraph from '../../loaders/bar-graph';
// import LineGraph from '../../loaders/line-graph';

import { routes } from '../../../services/constants';
// import { receivablesAPI } from '../../../services/settings';
import store from '../../../states';
import InfyTableComponent from '../../comman/table/InfyTableComponent';
import StripConnect from './stripConnect';
import { UserInfoContext } from '../../contexts/userInfoContext';

export default ({ toast /*store*/ }: any) => {
  const { pathname } = useLocation();
  const { customerInfo }: any = useContext(UserInfoContext);

  const [financeSection, sFinanceSection] = useState('1');
  // const [receivablesData, sReceivablesData] = useState<any>([]);
  // const [earningsApiPath, sEarningsApiPath] = useState('');

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  // SPO Finance table
  const [spendingApi, setSpendingApi] = useState('');
  const [platformCostApi, setPlatformCostApi] = useState('');
  const [earningByPartnerApi, setEarningByPartnerApi] = useState('');

  useEffect(() => {
    if (localStorage.getItem('r') === 'infy-admin' && pathname === '/settings/finance') {
      setPlatformCostApi(routes.adminPlatformCost);
      setEarningByPartnerApi(routes.adminEarningByPartner);
    }

    if (localStorage.getItem('customer_type') === 'spo' && pathname === '/settings/finance') {
      setSpendingApi(routes.spoSpending);
      setPlatformCostApi(routes.spoPlatformCost);
      setEarningByPartnerApi(routes.spoEarningByPartner);
    }

    if (localStorage.getItem('customer_type') === 'demand' && pathname === '/settings/finance') {
      setSpendingApi(routes.demandSpending);
    }

    if (localStorage.getItem('customer_type') === 'creative' && pathname === '/settings/finance') {
      setPlatformCostApi(routes.publisherPlatformCost);
    }
  }, [pathname]);

  const adminTabConfig = [
    {
      key: 'platform-cost',
      title: 'Platform Cost',
      api: platformCostApi,
      method: 'POST',
      tableName: 'Platform Cost',
      downloadCSV: false,
      search: false,
    },
    {
      key: 'earnings',
      title: 'Earnings',
      api: earningByPartnerApi,
      method: 'POST',
      tableName: 'earnings',
      downloadCSV: false,
      search: false,
    },
  ];

  const dspTabConfig = [
    {
      key: 'media-cost',
      title: 'Media Cost',
      api: spendingApi,
      method: 'POST',
      tableName: 'Spending-By-Partner',
      downloadCSV: false,
      search: false,
    },
    {
      key: 'details',
      title: 'Details',
      tableName: 'DSP Customer Details',
      downloadCSV: false,
      search: false,
    },
  ];

  const spoTabConfig = [
    {
      key: 'partner',
      title: 'Spending By Partner',
      api: earningByPartnerApi,
      method: 'POST',
      tableName: 'Spending-By-Partner',
      downloadCSV: false,
      search: false,
    },
    // {
    //   key: 'platform-cost',
    //   title: 'Platform Cost',
    //   api: platformCostApi,
    //   method: 'POST',
    //   tableName: 'Platform Cost',
    //   downloadCSV: false,
    //   search: false,
    // },
    // {
    //   key: 'spending',
    //   title: 'Monthly Spending',
    //   api: spendingApi,
    //   method: 'POST',
    //   tableName: 'Monthly Spending',
    //   downloadCSV: false,
    //   search: false,
    // },
    {
      key: 'details',
      title: 'Details',
      tableName: 'SPO Customer Details',
      downloadCSV: false,
      search: false,
    },
  ];

  const creativeTabConfig = [
    {
      key: 'platform-cost',
      title: 'Platform Cost',
      api: platformCostApi,
      method: 'POST',
      tableName: 'Platform Cost',
      downloadCSV: false,
      search: false,
    },
    {
      key: 'details',
      title: 'Details',
      tableName: 'SPO Customer Details',
      downloadCSV: false,
      search: false,
    },
  ];

  return (
    <>
      {localStorage.getItem('r') === 'infy-admin' && (
        <>
          <div className={'row align-items-center mb-4 finance-container admin'}>
            <div className={'col text-start'}>
              <h2 style={{ fontWeight: 200 }}>Finance</h2>
            </div>
            <CustomerTabs defaultActiveKey={adminTabConfig[0]?.key} className='my-3'>
              {adminTabConfig?.map((tab: any) => (
                <CustomerTab key={tab.key} eventKey={tab.key} title={tab.title}>
                  <>
                    {pathname === '/settings/finance' && (
                      <InfyTableComponent
                        columnWidth={130}
                        api={tab.api}
                        method={tab.method}
                        tableName={tab.tableName}
                        downloadCSV={tab.downloadCSV}
                        search={tab.search}
                      />
                    )}
                  </>
                </CustomerTab>
              ))}
            </CustomerTabs>
            {/* <div className='col col-auto'>
              <Pill
                items={[
                  { id: '1', label: glossary.admin_finance_tab_label_platformcost },
                  { id: '2', label: glossary.admin_finance_tab_label_earnings },
                ]}
                select={sFinanceSection}
                selected={financeSection}
              />
            </div>
            <div className={'col text-end'}></div> */}
          </div>

          {/* class: table__responsive */}
          {/* <div className={'row'} style={{ marginTop: 40 }}>
            {financeSection === '1' && (
              <>
                <InfyTableComponent
                  columnWidth={130}
                  api={platformCostApi}
                  method={'POST'}
                  tableName={'Platform Cost'}
                  downloadCSV={false}
                  search={false}
                />
              </>
            )}
            {financeSection === '2' && (
              <>
                <InfyTableComponent
                  columnWidth={130}
                  api={earningByPartnerApi}
                  method={'POST'}
                  tableName='earnings'
                  downloadCSV={false}
                  search={false}
                />
              </>
            )}
          </div> */}
        </>
      )}

      {localStorage.getItem('customer_type') === 'demand' && (
        <>
          <div className={'row align-items-center mb-4 finance-container dsp'}>
            <div className={'col text-start'}>
              <h2 style={{ fontWeight: 200 }}>Finance</h2>
            </div>
            {/* <div className='col col-auto'>
              <Pill
                items={[
                  { id: '1', label: 'Media Cost' },
                  { id: '2', label: 'Details' },
                ]}
                select={sFinanceSection}
                selected={financeSection}
              />
            </div> */}
            <CustomerTabs defaultActiveKey={dspTabConfig[0]?.key} className='my-3'>
              {dspTabConfig?.map((tab: any) => (
                <CustomerTab key={tab.key} eventKey={tab.key} title={tab.title}>
                  {tab.key === 'details' ? (
                    <StripConnect />
                  ) : (
                    <>
                      {pathname === '/settings/finance' && (
                        <InfyTableComponent
                          columnWidth={130}
                          api={tab.api}
                          method={tab.method}
                          tableName={tab.tableName}
                          downloadCSV={tab.downloadCSV}
                          search={tab.search}
                        />
                      )}
                    </>
                  )}
                </CustomerTab>
              ))}
            </CustomerTabs>
            {/* <div className={'col text-end'}></div> */}
          </div>
          {/* {financeSection === '1' && (
            <InfyTableComponent
              columnWidth={130}
              api={spendingApi}
              tableName={'Spending-By-Partner'}
              method={'POST'}
              downloadCSV={false}
              search={false}
            />
          )}
          {financeSection === '2' && <StripConnect />} */}
        </>
      )}

      {localStorage.getItem('customer_type') === 'spo' && (
        <>
          <div className={'row align-items-center mb-4 finance-container spo'}>
            <div className={'col text-start'}>
              <h2 style={{ fontWeight: 200 }}>Finance</h2>
            </div>
            <CustomerTabs defaultActiveKey={spoTabConfig[0]?.key} className='my-3'>
              {spoTabConfig?.map((tab: any) => (
                <CustomerTab key={tab.key} eventKey={tab.key} title={tab.title}>
                  {tab.key === 'details' ? (
                    <StripConnect />
                  ) : (
                    <>
                      {pathname === '/settings/finance' && (
                        <InfyTableComponent
                          columnWidth={130}
                          api={tab.api}
                          method={tab.method}
                          tableName={tab.tableName}
                          downloadCSV={tab.downloadCSV}
                          search={tab.search}
                        />
                      )}
                    </>
                  )}
                </CustomerTab>
              ))}
            </CustomerTabs>
            {/* <div className='col col-auto'>
              <Pill
                items={[
                  { id: '1', label: 'Spending by partner' },
                  { id: '2', label: 'Platform Cost' },
                  { id: '3', label: 'Monthly Spendings' },
                  { id: '4', label: 'Details' },
                ]}
                select={sFinanceSection}
                selected={financeSection}
              />
            </div>
            <div className={'col text-end'}></div> */}
          </div>

          {/* {financeSection === '1' && (
            <div style={{ marginTop: 40 }}>
              <InfyTableComponent
                columnWidth={130}
                api={earningByPartnerApi}
                tableName={'Spending-By-Partner'}
                method={'POST'}
                downloadCSV={false}
                search={false}
              />
            </div>
          )}

          {financeSection === '2' && (
            <div style={{ marginTop: 40 }}>
              <InfyTableComponent
                columnWidth={130}
                api={platformCostApi}
                tableName={'Platform Cost'}
                method={'POST'}
                downloadCSV={false}
                search={false}
              />
            </div>
          )}

          {financeSection === '3' && (
            <div style={{ marginTop: 40 }}>
              <InfyTableComponent
                columnWidth={130}
                api={spendingApi}
                method={'POST'}
                downloadCSV={false}
                search={false}
              />
            </div>
          )}

          {financeSection === '4' && <StripConnect />} */}
        </>
      )}

      {localStorage.getItem('customer_type') === 'creative' && (
        <div className={'row align-items-center mb-4 finance-container spo'}>
          <div className={'col text-start'}>
            <h2 style={{ fontWeight: 200 }}>Finance</h2>
          </div>
          <CustomerTabs defaultActiveKey={creativeTabConfig[0]?.key} className='my-3'>
            {creativeTabConfig?.map((tab: any) => (
              <CustomerTab
                key={tab.key}
                eventKey={tab.key}
                title={tab.title}
                disabled={
                  tab?.key === 'details' && !customerInfo?.stripe_data?.subscribe ? true : false
                }
              >
                {tab.key === 'details' ? (
                  <StripConnect />
                ) : (
                  <>
                    {pathname === '/settings/finance' && (
                      <InfyTableComponent
                        columnWidth={130}
                        api={tab.api}
                        method={tab.method}
                        tableName={tab.tableName}
                        downloadCSV={tab.downloadCSV}
                        search={tab.search}
                      />
                    )}
                  </>
                )}
              </CustomerTab>
            ))}
          </CustomerTabs>
        </div>
      )}
    </>
  );
};

/* {localStorage.getItem('r') === 'infy-admin' ? (
              <div
                className={'row overview-graphs'}
                style={{
                  marginTop: 24,
                  minHeight: '250px',
                  maxHeight: 'fit-content',
                  borderRadius: '4px',
                  padding: '24px',
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                }}
              >
                {financeSection === '1' && (
                  <>
                    <div className={'col'} style={{ maxWidth: '200px', maxHeight: '200px' }}>
                      <Doughnut
                        label={label}
                        data={{
                          labels: [
                            'Netflix',
                            'Mx Player',
                            'Prime Video',
                            'Discovery Plus',
                            'Hotstar',
                          ],
                          values: [5, 2, 3.5, 4.25, 2.5],
                        }}
                        attribute={attribute}
                      />
                    </div>
                    <div className={'col'} style={{ width: '300px', height: '200px' }}>
                      {
                        <Bar
                          label={label}
                          data={{
                            labels: [
                              'Netflix',
                              'Mx Player',
                              'Prime Video',
                              'Discovery Plus',
                              'Hotstar',
                            ],
                            values: [5.43, 2.67, 3.34, 4.23, 2.5],
                          }}
                          attribute={attribute}
                        />
                      }
                    </div>
                    <div className={'col'} style={{ width: '300px', height: '200px' }}>
                      {
                        <Line
                          label={label}
                          data={{
                            labels: [
                              'Jul 5',
                              'Jul 6',
                              'Jul 7',
                              'Jul 8',
                              'Jul 9',
                              'Jul 10',
                              'Jul 11',
                            ],
                            values: [
                              `${2}`,
                              `${3}`,
                              `${4.25}`,
                              `${7}`,
                              `${2.75}`,
                              `${5.67}`,
                              `${2}`,
                            ],
                          }}
                          attribute={attribute}
                        />
                      }
                    </div>
                  </>
                )}
                {financeSection === '2' && (
                  <>
                    <div className={'col'} style={{ maxWidth: '200px', maxHeight: '200px' }}>
                      <Doughnut
                        label={label}
                        data={{
                          labels: [
                            'Ad China',
                            'Google Double Click',
                            'Publisher Direct',
                            'Rubicon',
                            'Fyber',
                          ],
                          values: [2.34, 3.67, 1.34, 5.23, 2.5],
                        }}
                        attribute={attribute}
                      />
                    </div>
                    <div className={'col'} style={{ width: '300px', height: '200px' }}>
                      <Bar
                        label={label}
                        data={{
                          labels: [
                            'Ad China',
                            'Google Double Click',
                            'Publisher Direct',
                            'Rubicon',
                            'Fyber',
                          ],
                          values: [2.34, 3.67, 1.34, 5.23, 2.5],
                        }}
                        attribute={attribute}
                      />
                    </div>
                    <div className={'col'} style={{ width: '300px', height: '200px' }}>
                      <Line
                        label={label}
                        data={{
                          labels: ['Jul 5', 'Jul 6', 'Jul 7', 'Jul 8', 'Jul 9', 'Jul 10', 'Jul 11'],
                          values: [
                            `${1.67}`,
                            `${3.46}`,
                            `${4.35}`,
                            `${2.54}`,
                            `${3.75}`,
                            `${4.27}`,
                            `${1.98}`,
                          ],
                        }}
                        attribute={attribute}
                      />
                    </div>
                  </>
                )}
                {financeSection === '3' && (
                  <>
                    <div className={'col'} style={{ maxWidth: '200px', maxHeight: '200px' }}>
                      <Doughnut
                        label={label}
                        data={{
                          labels: [
                            'Netflix',
                            'Mx Player',
                            'Prime Video',
                            'Discovery Plus',
                            'Hotstar',
                          ],
                          values: [1.34, 4.67, 2.34, 1.23, 3.5],
                        }}
                        attribute={attribute}
                      />
                    </div>
                    <div className={'col'} style={{ width: '300px', height: '200px' }}>
                      <Bar
                        label={label}
                        data={{
                          labels: [
                            'Netflix',
                            'Mx Player',
                            'Prime Video',
                            'Discovery Plus',
                            'Hotstar',
                          ],
                          values: [1.34, 4.67, 2.34, 1.23, 3.5],
                        }}
                        attribute={attribute}
                      />
                    </div>
                    <div className={'col'} style={{ width: '300px', height: '200px' }}>
                      <Line
                        label={label}
                        data={{
                          labels: ['Jul 5', 'Jul 6', 'Jul 7', 'Jul 8', 'Jul 9', 'Jul 10', 'Jul 11'],
                          values: [2.67, 4.46, 5.35, 3.54, 2.75, 3.27, 0.98],
                        }}
                        attribute={attribute}
                      />
                    </div>
                  </>
                )}
              </div>
            ) : null} */
