import { useEffect, useState } from 'react';
import store from '../../../states';
import { toast } from 'react-toastify';
import { IoCopyOutline } from 'react-icons/io5';

const HtmlCode = ({
  createTag,
  selectedTag,
  cutomerData,
  extentionData,
  websiteTodos,
  resolution,
}: any) => {
  const [glossary, sGlossary] = useState<any>({});

  const [selectedTagDetails, setSelectedTagDetails] = useState<any>({});

  useEffect(() => {
    if (selectedTag) setSelectedTagDetails(selectedTag);
  }, [selectedTag]);

  //GET glossary
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className='createtag-vasturl' style={{ minHeight: 'auto', height: 'auto' }}>
        {createTag ? (
          <>
            <p className='h-100'>
              {`<html>
                      <body>
                        <div
                        class="gen-ext"
                        data-tag-id="${extentionData?.tag_id || extentionData?.id}"
                        data-company-id="${cutomerData?.id}"
                       ${
                         extentionData?.view_type === 'interstitial'
                           ? `style="width: ${resolution?.width || '300px'}; height: ${
                               resolution?.height || '600px'
                             };"`
                           : ''
                       }
                      ></div>
                      <script src="https://media.begenuin.com/cxr/gen_ext.min.js"></script>
                  </body>
                </html>`}
            </p>
            <div className='createtag-vasturl-icon'>
              <IoCopyOutline
                className='svg-icon'
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      `<html>
                          <body>
                            <div
                            class="gen-ext"
                            data-tag-id="${extentionData?.tag_id || extentionData?.id}"
                            data-company-id="${cutomerData?.id}"
                            ${
                              extentionData?.view_type === 'interstitial'
                                ? `style="width: ${resolution?.width || '300px'}; height: ${
                                    resolution?.height || '600px'
                                  };"`
                                : ''
                            }
                          ></div>
                          <script src="https://media.begenuin.com/cxr/gen_ext.min.js"></script>
                          </body>
                      </html>`
                    )
                    .toString();
                  toast.success(glossary.ssp_createtag_success_vast_copy_success_msg);
                }}
                title={'Copy URL'}
                fontSize={20}
              />
            </div>
          </>
        ) : (
          <>
            <p className='h-100'>
              {`<html>
                      <body>
                        <div
                        class="gen-ext"
                        data-tag-id="${selectedTagDetails?.id}"
                        data-company-id="${cutomerData?.id}"
                        ${
                          selectedTagDetails?.config?.extension_config?.view_type === 'interstitial' || selectedTagDetails?.config?.view_type === 'interstitial'
                            ? `style="width: ${resolution?.width || '300px'}; height: ${
                                resolution?.height || '600px'
                              };"`
                            : ''
                        }
                      ></div>
                      <script src="https://media.begenuin.com/cxr/gen_ext.min.js"></script>
                      </body>
                  </html>`}
            </p>
            <div className='createtag-vasturl-icon'>
              <IoCopyOutline
                className='svg-icon'
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      `<html>
                          <body>
                            <div
                            class="gen-ext"
                            data-tag-id="${selectedTagDetails?.id}"
                            data-company-id="${cutomerData?.id}"
                            ${
                              selectedTagDetails?.config?.extension_config?.view_type ===
                              'interstitial' || selectedTagDetails?.config?.view_type ===
                              'interstitial'
                                ? `style="width: ${resolution?.width || '300px'}; height: ${
                                    resolution?.height || '600px'
                                  };"`
                                : ''
                            }
                          ></div>
                          <script src="https://media.begenuin.com/cxr/gen_ext.min.js"></script>
                          </body>
                      </html>`
                    )
                    .toString();
                  toast.success(glossary.ssp_createtag_success_vast_copy_success_msg);
                }}
                title={'Copy URL'}
                fontSize={20}
              />
            </div>
          </>
        )}
      </div>
      <div className='row'>
        <div className='col-12'>
          <h4 className='my-3'>{glossary.ssp_createext_details_field_title_instruction}</h4>
          <p className='mb-2'>{glossary.ssp_createext_details_field_title_instruction_one}</p>
          <p className='mb-2'>
            <span className='fw-lighter'>
              {glossary.ssp_createext_details_field_title_step_one}{' '}
            </span>
            {glossary.ssp_createext_details_field_step_one_inst}
          </p>
          <p className='mb-2'>
            <span className='fw-lighter'>
              {glossary.ssp_createext_details_field_title_step_two}{' '}
            </span>{' '}
            {glossary.ssp_createext_details_field_step_two_inst}
          </p>
          <p className='mb-2'>
            <span className='fw-lighter'>
              {glossary.ssp_createext_details_field_title_step_three}{' '}
            </span>{' '}
            {glossary.ssp_createext_details_field_step_three_inst}
          </p>
          <p className='mb-2'>
            <span className='fw-lighter'>
              {glossary.ssp_createext_details_field_title_step_four}{' '}
            </span>{' '}
            {glossary.ssp_createext_details_field_step_four_inst}
          </p>
          <p className='mb-2'>
            <span className='fw-lighter'>
              {glossary.ssp_createext_details_field_title_step_five}{' '}
            </span>{' '}
            {glossary.ssp_createext_details_field_step_five_inst}
          </p>
          <p className='mb-2'>
            <span className='fw-lighter'>
              {glossary.ssp_createext_details_field_title_step_six}{' '}
            </span>{' '}
            {glossary.ssp_createext_details_field_step_six_inst}
          </p>
          <p className='mb-2'>
            <span className='fw-lighter'>
              {glossary.ssp_createext_details_field_title_step_seven}{' '}
            </span>
            {glossary.ssp_createext_details_field_step_seven_inst}
          </p>
          <p className='mb-2'>
            <span className='fw-lighter'>
              {glossary.ssp_createext_details_field_title_step_eight}{' '}
            </span>
            {glossary.ssp_createext_details_field_step_eight_inst}
          </p>
          <p className='mb-2'>
            <span className='fw-lighter'>
              {glossary.ssp_createext_details_field_title_step_nine}{' '}
            </span>
            {glossary.ssp_createext_details_field_step_nine_inst}
          </p>
          <p className='mt-1'>
            <a
              style={{ color: '#731780' }}
              href='https://help.infy.tv/en/articles/7241019-how-to-integrate-extension-in-your-website'
              target={'_blank'}
              rel='noreferrer'
            >
              {glossary.ssp_createext_details_hyperlink_know_more}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default HtmlCode;
