import { useEffect, useState } from 'react';

import Input from '../../widgets/input';
import Button from '../../widgets/button';

import add from '../../../assets/icons/add-b.svg';
import { MdDeleteOutline } from 'react-icons/md';

import { getGlossaryAPI, updateGlossaryAPI } from '../../../services/settings';

import Customize from '../../modals/customize';
import AddGlossary from '../../modals/add-glossary';
import TableSkeleton from '../../loaders/table';

export default ({ toast, store, section }: any) => {
  const [customSection, sCustomSection] = useState('2');
  const [updatedGlossary, sUpdateGlossary] = useState([]);
  const [glossarySettings, sGlossarySettings] = useState([]);
  const [backupGlossarySettings, sBackupGlossarySettings] = useState([]);
  const [addGlossaryModal, setAddGlossaryModal] = useState(false);
  const [skeleton, sSkeleton] = useState(true);

  const [show, sShow] = useState(false);

  const ImageCard = (props: any) => {
    return (
      <div
        style={{
          width: 175,
          height: 125,
          backgroundColor: '#C4C4C4',
          borderRadius: 4,
          position: 'relative',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            position: 'absolute',
            width: '100%',
            bottom: 0,
            left: 0,
            padding: '8px 12px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h6>{props.text}</h6>
            <MdDeleteOutline fontSize={18} style={{ marginTop: 2 }} title='Delete' />
          </div>
        </div>
      </div>
    );
  };

  const NewCard = () => {
    return (
      <div
        onClick={() => sShow(true)}
        style={{
          display: 'flex',
          width: 175,
          height: 125,
          borderRadius: 4,
          border: '1px dashed #979797',
          cursor: 'pointer',
        }}
      >
        <img style={{ margin: 'auto' }} src={add} width={16} height={16} />
      </div>
    );
  };

  const getGlossary = () => {
    sSkeleton(true);
    getGlossaryAPI()
      .then((data: any) => {
        sGlossarySettings(data.data);
        sBackupGlossarySettings(data.data);
        sSkeleton(false);
      })
      .catch(() => {
        //
      });
  };

  const updateGlossary = (id: any, value: any) => {
    const newGlossary: any = [...updatedGlossary];
    const obj: any = newGlossary.find((x: any) => x.glossary_id === id);
    if (obj) {
      const glossaryIndex: any = newGlossary.findIndex((x: any) => x.glossary_id === id);
      newGlossary[glossaryIndex].value = value;
      sUpdateGlossary(newGlossary);
    } else {
      newGlossary.push({ lang_id: '1', glossary_id: id, value });
      sUpdateGlossary(newGlossary);
    }
  };

  const initiateGlossaryUpdation = () => {
    const payload = {
      glossary: updatedGlossary,
    };
    sSkeleton(true);
    updateGlossaryAPI(payload).then(() => {
      sUpdateGlossary([]);
      getGlossary();
    });
  };

  useEffect(() => {
    if (localStorage.getItem('r') === 'infy-admin') {
      getGlossary();
    }
  }, []);

  return (
    <>
      {customSection === '1' && (
        <>
          <Customize
            id={''}
            show={show}
            onHide={() => {
              sShow(false);
            }}
          />
          <h5>Startup Images</h5>
          <div className={'row'} style={{ marginTop: 24, columnGap: 24 }}>
            <ImageCard text={'Login'} />
            <ImageCard text={'Password'} />
            <ImageCard text={'Signup'} />
            <ImageCard text={'Join'} />
          </div>
          <br />
          <hr />
          <br />
          <h5>Onboarding</h5>
          <div className={'row'} style={{ marginTop: 24, columnGap: 24 }}>
            <ImageCard text={'1. Welcome'} />
            <ImageCard text={'2. Payout'} />
            <ImageCard text={'3. Optimise'} />
            <NewCard />
          </div>
          <br />
          <hr />
          <br />
          <h5>Platform Content</h5>
          <br />
          <Button text={'Edit'} kind={'secondary'} click={() => sCustomSection('2')} />
        </>
      )}
      {customSection === '2' && (
        <>
          <AddGlossary
            show={addGlossaryModal}
            onHide={() => setAddGlossaryModal(false)}
            api={getGlossary}
          />
          {/* <h4
            onClick={() => sCustomSection('1')}
            style={{ color: '#1D58D7', cursor: 'pointer', width: 'fit-content' }}
          >
            Back
          </h4>
          <br /> */}
          <div className='d-flex justify-content-between align-items-center'>
            <h5>Platform Content</h5>
            <div className='d-flex gap-3'>
              <Input
                name={'glossarySearch'}
                kind={'mini'}
                type={'text'}
                change={(e, v) => {
                  const newGlossary = [...backupGlossarySettings];
                  sGlossarySettings(newGlossary.filter((x: any) => x.glossary_name.includes(v)));
                }}
                // @ts-ignore
                placeholder={'Search for a Key'}
                label={''}
              />

              {updatedGlossary.length > 0 ? (
                <>
                  <Button
                    click={() => initiateGlossaryUpdation()}
                    kind={'secondary'}
                    text={'Update'}
                    type={'submit'}
                  />
                  <Button
                    click={() => {
                      sUpdateGlossary([]);
                      sGlossarySettings([]);
                      getGlossary();
                    }}
                    kind={'link'}
                    text={'Discard'}
                    type='button'
                  />
                </>
              ) : (
                <Button click={() => setAddGlossaryModal(true)} kind={'primary'} text={'Add'} />
              )}
            </div>
          </div>
          <div className='table__responsive'>
            {skeleton ? (
              <div className='mt-4'>
                <TableSkeleton columns={5} rows={9} rowSpacing={2} tableHeight={400} />
              </div>
            ) : (
              <table className={'gloss-table'}>
                <thead>
                  <tr>
                    <th>Screen / Key</th>
                    <th>Content</th>
                  </tr>
                </thead>
                <tbody>
                  {glossarySettings.map((gloss: any, index) => (
                    <tr key={index}>
                      <td>{gloss.glossary_name}</td>
                      <td
                        contentEditable
                        onInput={(e: any) => updateGlossary(gloss.glossary_id, e.target.innerText)}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            initiateGlossaryUpdation();
                          }
                        }}
                      >
                        {gloss.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      )}
    </>
  );
};
