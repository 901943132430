import { useEffect, useState } from 'react';
import store from '../../../states';

const UserProfile = ({ userProfile, genuinUser, genuinData }: any) => {
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className='d-flex align-items-center mt-3'>
        <div className='d-flex flex-column'>
          <img
            src={
              userProfile
                ? userProfile
                : 'https://infy-ads.s3.us-east-2.amazonaws.com/static/placeholder.png'
            }
            alt={genuinUser}
            width={40}
            height={40}
            className='rounded-pill me-3'
          />
          {/* <span>@{genuinUser}</span> */}
        </div>
        <div className='d-flex gap-3'>
          <div className='text-center'>
            <p className='fw-lighter' style={{ fontSize: '12px', lineHeight: '16px' }}>
              {glossary.ssp_createext_field_label_view}
            </p>
            <p style={{ fontSize: '12px', lineHeight: '16px' }}>{genuinData?.views ?? '-'}</p>
          </div>
          <div className='text-center'>
            <p className='fw-lighter' style={{ fontSize: '12px', lineHeight: '16px' }}>
              {glossary.ssp_createext_field_label_videos}
            </p>
            <p style={{ fontSize: '12px', lineHeight: '16px' }}>{genuinData?.videos ?? '-'}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
