import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
// import { Range, getTrackBackground } from 'react-range';
import ButtonR from 'react-bootstrap/Button';
// import Spinner from 'react-bootstrap/Spinner';

import { categories, platforms, countries, states, cities, sources } from '../helpers/dropdowns';
import { getCountriesAPI, getStatesAPI, getCitiesAPI } from '../../services/inventory';
import store from '../../states';
import { getCustomersAPI } from '../../services/customers';
import Dropdown from '../widgets/dropdown';
import { Modal } from 'react-bootstrap';
import { getRtbArtistListAPI, getRtbGenreListAPI, getRtbTitleListAPI } from '../../services/deals';
import Input from './input';
import CustomDropdown from './customDropdown';
// import DatePicker from '../comman/datepicker/DatePicker';
// import Input from './input';

interface Props {
  onsubmit(data: any): void;
  loadingStop: any;
  id?: string;
  filteredData?(data: any): void;
  kind?: 'admin' | 'demand' | 'default';
  show?: boolean;
  onHide?: any;
}

export default (props: Props) => {
  const { onsubmit, loadingStop, id, kind, show, onHide } = props;

  // Dropdown Options Start
  const contextOpt = [
    { label: 'Video', value: 1 },
    { label: 'Game', value: 2 },
    { label: 'Music', value: 3 },
    { label: 'Application', value: 4 },
    { label: 'Text', value: 5 },
    { label: 'Other', value: 6 },
    { label: 'Unknown', value: 7 },
  ];

  const prodqOpt = [
    { label: 'Unknown', value: 0 },
    { label: 'Professionally Produced', value: 1 },
    { label: 'Prosumer', value: 2 },
    { label: 'User Generated (UGC)', value: 3 },
  ];

  const qagmediaratingOpt = [
    { label: 'All Audiences', value: 1 },
    { label: 'Everyone Over Age 12', value: 2 },
    { label: 'Mature Audiences', value: 3 },
  ];
  // Dropdown Options End

  /* All States */
  // const [values, sValues] = useState([0, 24]);
  const [lowerCpm, setLowerCpm] = useState<any>(0);
  const [upperCpm, setUpperCpm] = useState<any>(0);
  const [glossary, sGlossary] = useState<any>({});
  const [btnLoading, setBtnLoading] = useState(false);

  const [citiesList, sCitiesList] = useState<any>([]);
  const [statesList, sStatesList] = useState<any>([]);
  const [countriesList, sCountriesList] = useState<any>([]);
  const [sourcesList, sSourcesList] = useState<any>([]);

  const [selectedStates, sSelectedStates] = useState<any>([]);
  const [selectedCities, sSelectedCities] = useState<any>([]);
  const [selectedSources, sSelectedSources] = useState<any>([]);
  const [selectedCountries, sSelectedCountries] = useState<any>([]);
  const [selectedCategories, sSelectedCategories] = useState<any>([]);
  const [selectedDeviceTypes, sSelectedDeviceTypes] = useState<any>([]);
  const [selectedPlatformTypes, sSelectedPlatformTypes] = useState<any>([]);
  const [selectedPlatformCategories, sSelectedPlatformCategories] = useState<any>([]);
  const [dealObj, setDealObj] = useState<any>();
  const [cpmError, setCpmError] = useState(false);

  //New Fields
  const [artist, sArtist] = useState('');
  const [series, sSeries] = useState('');
  const [title, sTitle] = useState('');
  const [isrc, sIsrc] = useState('');
  const [producerName, sProducerName] = useState('');
  const [producerDomain, sProducerDomain] = useState('');

  const [prodq, sProdq] = useState<any>([]);
  const [context, sContext] = useState<any>([]);
  const [contentrating, sContentrating] = useState('');
  const [userrating, sUserrating] = useState('');
  const [qagmediarating, sQagmediarating] = useState<any>([]);
  const [keywords, sKeywords] = useState('');
  const [keywordsArray, sKeywordsArray] = useState<any>([]);
  const [livestream, sLivestream] = useState<any>([]);
  const [sourceRelationship, sSourceRelationship] = useState<any>([]);
  const [len, sLen] = useState<any>(null);
  const [language, sLanguage] = useState<any>(null);
  const [embeddable, sEmbeddable] = useState<any>([]);
  const [networkDomain, sNetworkDomain] = useState('');
  const [channelDomain, sChannelDomain] = useState('');
  const [networkName, sNetworkName] = useState('');
  const [channelName, sChannelName] = useState('');
  const [applicationType, sApplicationType] = useState<any>([]);
  // const [startDate, setStartDate] = useState<any>();
  const [previousFilter, setPreviousFilter] = useState<any>({});

  const [artistQuery, setArtistQuery] = useState('');
  const [artistList, setArtiseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [titleQuery, setTitleQuery] = useState('');
  const [titleList, setTitleList] = useState([]);
  const [titleLoading, setTitleLoading] = useState(false);

  const [genreList, setGenreList] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState<any>([]);

  //Hadling Float Value for CPM Start
  const step = 0.01;
  const max = 999.99;

  const handleLowerCpm = (e: any) => {
    const newValue = e.target.value;

    if (/^-?[0-9]*\.?[0-9]*$/.test(newValue) && newValue <= max) {
      setLowerCpm(newValue);
    } else {
      //ok
    }
  };

  const handleUpperCpm = (e: any) => {
    const newValue = e.target.value;

    if (/^-?[0-9]*\.?[0-9]*$/.test(newValue) && newValue <= max) {
      setUpperCpm(newValue);
    } else {
      //ok
    }
  };
  //Hadling Float Value for CPM End

  useEffect(() => {
    if (parseFloat(lowerCpm) > parseFloat(upperCpm)) {
      setCpmError(true);
    }
    if (parseFloat(lowerCpm) <= parseFloat(upperCpm)) setCpmError(false);
  }, [lowerCpm, upperCpm]);

  /* Glossary */
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (id) onClear();
  }, [id]);

  // Clear Filter Value
  const onClear = () => {
    setLowerCpm(0);
    setUpperCpm(0);
    sCitiesList([]);
    sStatesList([]);
    sSelectedCities([]);
    sSelectedStates([]);
    sSelectedSources([]);
    sSelectedCountries([]);
    sSelectedCategories([]);
    sSelectedDeviceTypes([]);
    sSelectedPlatformTypes([]);
    sSelectedPlatformCategories([]);

    // New Fields
    sArtist('');
    sSeries('');
    sTitle('');
    sIsrc('');
    sProducerName('');
    sProducerDomain('');
    sProdq([]);
    sContext([]);
    sContentrating('');
    sUserrating('');
    sQagmediarating([]);
    sKeywords('');
    sKeywordsArray([]);
    sLivestream([]);
    sSourceRelationship([]);
    sLen('');
    sLanguage('');
    sEmbeddable([]);
    sNetworkDomain('');
    sChannelDomain('');
    sNetworkName('');
    sChannelName('');
    sApplicationType([]);
    // setStartDate(new Date());
    setTitleList([]);
    setArtiseList([]);
    setSelectedGenre([]);
  };

  // Values
  useEffect(() => {
    let newCountries = [],
      newStates = [],
      newCities = [],
      newCategories = [],
      newSources = [],
      newPlatformsCategories = [];
    if (selectedCountries.length > 0) {
      newCountries = selectedCountries;
    }
    if (selectedStates.length > 0) {
      newStates = selectedStates;
    }
    if (selectedCities.length > 0) {
      newCities = selectedCities;
    }
    if (selectedCategories.length > 0) {
      newCategories = selectedCategories.map(({ value }: any) => value);
    }
    if (selectedPlatformCategories.length > 0) {
      newPlatformsCategories = selectedPlatformCategories.map(({ value }: any) => value);
    }
    if (selectedSources.length > 0) {
      newSources = selectedSources.map(({ value }: any) => value);
    }
    setDealObj({
      cat: newCategories, //Iab Category
      dev: selectedDeviceTypes, //Mobile, Desktop, Tablet
      p_cat: newPlatformsCategories, //Roku, Apple TV, Chrome OS
      ctr: newCountries, //Country
      stt: newStates, //State
      cty: newCities, //Cities
      p_type: selectedPlatformTypes, //OTT, CTV
      lower_cpm: parseFloat(lowerCpm),
      upper_cpm: parseFloat(upperCpm),

      // New Fields
      artist: artist,
      series: series,
      title: title,
      genre: selectedGenre.value,
      isrc: isrc,
      producerName: producerName,
      producerDomain: producerDomain,
      prodq: prodq,
      context: context,
      contentrating: contentrating,
      userrating: userrating,
      qagmediarating: qagmediarating,
      keywords: keywords,
      keywordsArray: keywordsArray,
      livestream: livestream,
      sourceRelationship: sourceRelationship,
      len: len,
      language: language,
      embeddable: embeddable,
      networkDomain: networkDomain,
      channelDomain: channelDomain,
      networkName: networkName,
      channelName: channelName,
      applicationType: applicationType,
      // startDate: startDate,
    });
  }, [
    selectedCategories,
    selectedDeviceTypes,
    selectedPlatformTypes,
    selectedPlatformCategories,
    selectedCountries,
    selectedStates,
    selectedCities,
    selectedSources,
    lowerCpm,
    upperCpm,

    // New Fields
    artist,
    series,
    title,
    selectedGenre,
    isrc,
    producerName,
    producerDomain,
    prodq,
    context,
    contentrating,
    userrating,
    qagmediarating,
    keywords,
    keywordsArray,
    livestream,
    sourceRelationship,
    len,
    language,
    embeddable,
    networkDomain,
    channelDomain,
    networkName,
    channelName,
    applicationType,
    show,
    // startDate,
  ]);

  // Apply Previous Filter
  useEffect(() => {
    if (previousFilter) {
      if (Object.entries(previousFilter).length > 0 && previousFilter?.cat.length > 0) {
        const newCategories: any = [];
        previousFilter?.cat?.forEach((e1: any) => {
          categories?.forEach((e2: any) => {
            if (e1 === e2.value) {
              newCategories.push(e2);
            }
          });
        });
        sSelectedCategories(newCategories ?? []);
      }
      sSelectedDeviceTypes(previousFilter.dev ?? []); // for initial value and onChange
      sSelectedPlatformTypes(previousFilter.p_type ?? []);
      sSelectedCountries(previousFilter.ctr ?? []);
      sSelectedStates(previousFilter.stt ?? []);
      sSelectedCities(previousFilter.cty ?? []);
      setLowerCpm(previousFilter.lower_cpm ?? 0);
      setUpperCpm(previousFilter.upper_cpm ?? 0);
      sArtist(previousFilter.artist ?? '');
      sSeries(previousFilter.series ?? '');
      sTitle(previousFilter.title ?? '');
      sIsrc(previousFilter.isrc ?? '');
      sProducerName(previousFilter.producerName ?? '');
      sProducerDomain(previousFilter.producerDomain ?? '');
      sContentrating(previousFilter.contentrating ?? '');
      sUserrating(previousFilter.userrating ?? '');
      sQagmediarating(previousFilter.qagmediarating ?? []);
      sKeywords(previousFilter.keywords ?? '');
      sLen(previousFilter.len ?? null);
      sLanguage(previousFilter.language ?? null);
      sNetworkDomain(previousFilter.networkDomain ?? '');
      sNetworkName(previousFilter.networkName ?? '');
      sChannelName(previousFilter.channelName ?? '');
      sChannelDomain(previousFilter.channelDomain ?? '');
      sProdq(previousFilter.prodq ?? []);
      sContext(previousFilter.context ?? []);
      sLivestream(previousFilter.livestream ?? []);
      sSourceRelationship(previousFilter.sourceRelationship ?? []);
      sEmbeddable(previousFilter.embeddable ?? []);
      sKeywordsArray(previousFilter.kwarray ?? []);
      sApplicationType(previousFilter.applicationType ?? []);
      setTitleList([]);
      setArtiseList([]);
      setSelectedGenre(
        previousFilter.genre ? { label: previousFilter.genre, value: previousFilter.genre } : []
      );
    }
  }, [previousFilter, show]);

  const onSubmit = (newObj: string) => {
    if (!cpmError) {
      onHide();
      setBtnLoading(true);
      onsubmit && onsubmit(newObj);
    }
  };

  /* Handle Checkbox */
  const handleCheckBoxes = (e: any) => {
    if (e.target.name.startsWith('deviceTypes')) {
      let newDeviceTypes = [...selectedDeviceTypes];
      if (e.target.name === 'deviceTypesTV') {
        if (e.target.checked) {
          newDeviceTypes.push('tv');
        } else {
          newDeviceTypes = newDeviceTypes.filter((x) => x !== 'tv');
        }
      }
      if (e.target.name === 'deviceTypesMobile') {
        if (e.target.checked) {
          newDeviceTypes.push('mobile');
        } else {
          newDeviceTypes = newDeviceTypes.filter((x) => x !== 'mobile');
        }
      }
      if (e.target.name === 'deviceTypesDesktop') {
        if (e.target.checked) {
          newDeviceTypes.push('desktop');
        } else {
          newDeviceTypes = newDeviceTypes.filter((x) => x !== 'desktop');
        }
      }
      sSelectedDeviceTypes(newDeviceTypes);
    }
    if (e.target.name.startsWith('platformTypes')) {
      let newPlatformTypes = [...selectedPlatformTypes];
      if (e.target.name === 'platformTypesOTT') {
        if (e.target.checked) {
          newPlatformTypes.push('ott');
        } else {
          newPlatformTypes = newPlatformTypes.filter((x) => x !== 'ott');
        }
      }
      if (e.target.name === 'platformTypesCTV') {
        if (e.target.checked) {
          newPlatformTypes.push('ctv');
        } else {
          newPlatformTypes = newPlatformTypes.filter((x) => x !== 'ctv');
        }
      }
      sSelectedPlatformTypes(newPlatformTypes);
    }
    if (e.target.name.startsWith('applicationType')) {
      let newApplicationType = [...applicationType];
      if (e.target.name === 'applicationTypeApp') {
        if (e.target.checked) {
          newApplicationType.push('App');
        } else {
          newApplicationType = newApplicationType.filter((x) => x !== 'App');
        }
      }
      if (e.target.name === 'applicationTypeSite') {
        if (e.target.checked) {
          newApplicationType.push('Site');
        } else {
          newApplicationType = newApplicationType.filter((x) => x !== 'Site');
        }
      }
      sApplicationType(newApplicationType);
    }
  };

  //Button Loading
  useEffect(() => {
    if (!loadingStop) {
      setBtnLoading(false);
    }
  }, [loadingStop]);

  const getCustomers = () => {
    const payload = {
      order: 'asc',
      order_by: 'created_on',
      query: '',
      // id: '',
    };
    getCustomersAPI(payload)
      .then((data: any) => {
        if (data) {
          sSourcesList(data.data);
        }
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const getGenreList = () => {
    getRtbGenreListAPI()
      .then((data: any) => {
        if (data) {
          setGenreList(data.data);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  /* Get Countries, States, Cities List API */
  useEffect(() => {
    getCountriesAPI()
      .then((data: any) => {
        sCountriesList(data.data);
      })
      .catch(() => {
        // ok
      });
    if (kind === 'admin') getCustomers();
    getGenreList();
  }, []);

  useEffect(() => {
    if (selectedCountries.length > 0) {
      const payload = {
        countries: selectedCountries.map(({ id }: any) => id),
      };
      getStatesAPI(payload)
        .then((data: any) => {
          sStatesList(data.data);
        })
        .catch(() => {
          // ok
        });
    } else {
      sStatesList([]);
      sSelectedStates([]);
    }
    const newArr: any[] = [];
    if (selectedCountries.length > 0) {
      selectedStates.forEach((e1: any) =>
        selectedCountries.forEach((e2: any) => {
          if (e1.country == e2.value) {
            newArr.push(e1);
          }
        })
      );
    }
    sSelectedStates(newArr);
  }, [selectedCountries]);

  useEffect(() => {
    if (selectedStates.length > 0) {
      const payload = {
        states: selectedStates.map(({ id }: any) => id),
      };
      getCitiesAPI(payload)
        .then((data: any) => {
          sCitiesList(data.data);
        })
        .catch(() => {
          // ok
        });
    } else {
      sCitiesList([]);
      sSelectedCities([]);
    }
    const newArr: any[] = [];
    if (selectedStates.length > 0) {
      selectedCities.forEach((e1: any) =>
        selectedStates.forEach((e2: any) => {
          if (e1.state == e2.value) {
            newArr.push(e1);
          }
        })
      );
    }
    sSelectedCities(newArr);
  }, [selectedStates]);

  useEffect(() => {
    if (artistQuery) {
      const getData: any = setTimeout(() => {
        setIsLoading(true);
        getRtbArtistListAPI(artistQuery)
          .then((data: any) => {
            if (data.status) setArtiseList(data.data);
            setIsLoading(false);
          })
          .catch((error: any) => {
            //ok
          });
      }, 300);

      return () => clearTimeout(getData);
    }
    if (artistQuery === '') setArtiseList([]);
  }, [artistQuery]);

  useEffect(() => {
    if (titleQuery) {
      const getData: any = setTimeout(() => {
        setTitleLoading(true);
        getRtbTitleListAPI(titleQuery)
          .then((data: any) => {
            if (data.status) setTitleList(data.data);
            setTitleLoading(false);
          })
          .catch((error: any) => {
            //ok
          });
      }, 300);

      return () => clearTimeout(getData);
    }
    if (titleQuery === '') setTitleList([]);
  }, [titleQuery]);

  // const getStartDate = (data: any) => {
  //   if (data) {
  //     setStartDate(data);
  //   }
  // };

  return (
    <Modal centered size={'xl'} show={show} onHide={onHide}>
      {/* Header */}
      <div className='modal-header px-3'>
        <h3>Filter</h3>
      </div>

      {/* Body */}
      <div
        className='modal-body p-3'
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto',
        }}
      >
        <div className='row mt-1'>
          <div className='col-md-3 mb-2'>
            <Dropdown
              kind='multi'
              label={glossary.admin_inv_sidebar_ddl_label_iab_category}
              option={categories}
              value={selectedCategories}
              onchange={sSelectedCategories}
              search={true}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column justify-content-around'>
            <label className='label'>{glossary.admin_inv_sidebar_field_label_device_type}</label>
            <Form.Group className='d-flex gap-2'>
              <Form.Check
                name={'deviceTypesTV'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label={glossary.admin_inv_sidebar_checkbox_tv}
                checked={selectedDeviceTypes?.includes('tv')}
              />
              &nbsp;
              <Form.Check
                name={'deviceTypesDesktop'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label={glossary.admin_inv_sidebar_checkbox_desktop}
                checked={selectedDeviceTypes?.includes('desktop')}
              />
              &nbsp;
              <Form.Check
                name={'deviceTypesMobile'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label={glossary.admin_inv_sidebar_checkbox_mobile}
                checked={selectedDeviceTypes?.includes('mobile')}
              />
            </Form.Group>
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column justify-content-around'>
            <label className='label'>Platform Type</label>
            <Form.Group style={{ display: 'flex', columnGap: 5, marginTop: 5 }}>
              <Form.Check
                name={'platformTypesOTT'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label='OTT'
                checked={selectedPlatformTypes?.includes('ott')}
              />
              &nbsp;
              <Form.Check
                name={'platformTypesCTV'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label='CTV'
                checked={selectedPlatformTypes?.includes('ctv')}
              />
            </Form.Group>
          </div>
          <div className='col-md-3 mb-2'>
            <label className='label'>Application Type</label>
            <Form.Group style={{ display: 'flex', columnGap: 5, marginTop: 8 }}>
              <Form.Check
                name={'applicationTypeApp'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label={'App'}
                checked={applicationType?.includes('App')}
              />
              &nbsp;
              <Form.Check
                name={'applicationTypeSite'}
                onChange={handleCheckBoxes}
                className={'inventory'}
                type='checkbox'
                label={'Site'}
                checked={applicationType?.includes('Site')}
              />
            </Form.Group>
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <div className='row'>
              <div className='col-6'>
                <label className='label mb-0'>Lower CPM</label>
                <input
                  type='text'
                  value={lowerCpm}
                  maxLength={5}
                  onChange={handleLowerCpm}
                  className='text-input-mini w-100'
                  placeholder={'Ex: $ 25.78'}
                  //style={{ maxWidth: '150px', minWidth: '100px' }}
                />
              </div>
              <div className='col-6'>
                <label className='label mb-0'>Upper CPM</label>
                <input
                  type='text'
                  value={upperCpm}
                  maxLength={5}
                  onChange={handleUpperCpm}
                  className='text-input-mini w-100'
                  placeholder={'Ex: $ 43.96'}
                  //style={{ maxWidth: '150px', minWidth: '100px' }}
                />
                <p className={cpmError ? 'd-block text-input-error-label small' : 'd-none'}>
                  Upper CPM more than lower
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-3 mb-2'>
            <CustomDropdown
              label={glossary.admin_inv_sidebar_ddl_label_countries}
              option={countries(countriesList)}
              value={selectedCountries}
              onSelect={sSelectedCountries}
              isMulti
            />
            {/* <Dropdown
              kind='multi'
              label={glossary.admin_inv_sidebar_ddl_label_countries}
              option={countries(countriesList)}
              value={selectedCountries}
              onchange={sSelectedCountries}
              search={true}
              selectAll={false}
            /> */}
          </div>
          <div className='col-md-3 mb-2'>
            <CustomDropdown
              label={glossary.admin_inv_sidebar_ddl_label_states}
              option={states(statesList)}
              value={selectedStates}
              onSelect={sSelectedStates}
              isMulti
            />
            {/* <Dropdown
              kind='multi'
              label={glossary.admin_inv_sidebar_ddl_label_states}
              option={states(statesList)}
              value={selectedStates}
              onchange={sSelectedStates}
              search={true}
              selectAll={false}
            /> */}
          </div>
          <div className='col-md-3 mb-2'>
            <CustomDropdown
              label={glossary.admin_inv_sidebar_ddl_label_cities}
              option={cities(citiesList)}
              value={selectedCities}
              onSelect={sSelectedCities}
              isMulti
            />
            {/* <Dropdown
              kind='multi'
              label={glossary.admin_inv_sidebar_ddl_label_cities}
              option={cities(citiesList)}
              value={selectedCities}
              onchange={sSelectedCities}
              search={true}
              selectAll={false}
            /> */}
          </div>
          <div className='col-md-3 mb-2'>
            <Dropdown
              kind='multi'
              label={glossary.admin_inv_sidebar_ddl_label_platform}
              option={platforms}
              value={selectedPlatformCategories}
              onchange={sSelectedPlatformCategories}
              search={true}
            />
          </div>
          {kind === 'admin' && (
            <div className='col-md-3 mb-2'>
              <Dropdown
                kind='multi'
                name='sources'
                label={glossary.admin_inv_sidebar_ddl_label_sources}
                option={sources(sourcesList)}
                value={selectedSources}
                onchange={sSelectedSources}
                search={true}
              />
            </div>
          )}
          {/* <div className='col-md-3 mb-2'>
            <DatePicker selectedDate={getStartDate} selected={startDate} />
          </div> */}
          <div className='col-md-3 mb-2 d-flex flex-column suggestion-input'>
            <Input
              kind='suggestion'
              type='text'
              length={32}
              name={'artist'}
              value={artist}
              option={artistList}
              isLoading={isLoading}
              change={(e, v) => {
                sArtist(v);
                setArtistQuery(v);
              }}
              label={'Artist'}
              placeholder={'Enter artist'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Series</label>
            <input
              type='text'
              value={series}
              maxLength={32}
              onChange={(e: any) => sSeries(e.target.value)}
              className='text-input'
              placeholder={'Enter series'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column suggestion-input'>
            <Input
              kind='suggestion'
              type='text'
              length={32}
              name={'title'}
              value={title}
              option={titleList}
              isLoading={titleLoading}
              change={(e, v) => {
                sTitle(v);
                setTitleQuery(v);
              }}
              label={'Title'}
              placeholder={'Enter title'}
            />
          </div>
          <div className='col-md-3 mb-2'>
            <Dropdown
              label={'Genre'}
              option={genreList}
              defaultValue={selectedGenre}
              onchange={setSelectedGenre}
              search={true}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>ISRC Code</label>
            <input
              type='text'
              value={isrc}
              maxLength={32}
              onChange={(e: any) => sIsrc(e.target.value)}
              className='text-input'
              placeholder={'Enter isrc code'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Producer Name</label>
            <input
              type='text'
              value={producerName}
              maxLength={32}
              onChange={(e: any) => sProducerName(e.target.value)}
              className='text-input'
              placeholder={'Enter producer name'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Producer Domain</label>
            <input
              type='text'
              value={producerDomain}
              maxLength={32}
              onChange={(e: any) => sProducerDomain(e.target.value)}
              className='text-input'
              placeholder={'Enter producer domain'}
            />
          </div>
          <div className='col-md-3 mb-2'>
            <Dropdown
              label={'Product Quality'}
              option={prodqOpt}
              defaultValue={
                prodq.length === 0
                  ? []
                  : prodqOpt?.find((x: any) => {
                      if (x?.value === prodq) {
                        return { label: x.label, value: x.value };
                      }
                    })
              }
              onchange={(e) => sProdq(e.value)}
              search={false}
            />
          </div>
          <div className='col-md-3 mb-2'>
            <Dropdown
              label={'Context'}
              option={contextOpt}
              defaultValue={
                context.length === 0
                  ? []
                  : contextOpt?.find((x: any) => {
                      if (x?.value === context) {
                        return { label: x.label, value: x.value };
                      }
                    })
              }
              onchange={(e) => sContext(e.value)}
              search={false}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Content Rating</label>
            <input
              type='text'
              value={contentrating}
              maxLength={32}
              onChange={(e: any) => sContentrating(e.target.value)}
              className='text-input'
              placeholder={'Enter content rating'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>User Rating</label>
            <input
              type='text'
              value={userrating}
              maxLength={32}
              onChange={(e: any) => sUserrating(e.target.value)}
              className='text-input'
              placeholder={'Enter user rating'}
            />
          </div>
          <div className='col-md-3 mb-2'>
            <Dropdown
              label={'Media rating per IQG g'}
              option={qagmediaratingOpt}
              value={
                qagmediarating.length === 0
                  ? []
                  : qagmediaratingOpt.find((x: any) => {
                      if (qagmediarating === x.value) {
                        return { label: x.label, value: x.value };
                      }
                    })
              }
              onchange={(e) => sQagmediarating(e?.value)}
              search={false}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Keywords</label>
            <input
              type='text'
              value={keywords}
              maxLength={32}
              onChange={(e: any) => sKeywords(e.target.value)}
              className='text-input'
              placeholder={'Enter keywords'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Keywords Array</label>
            <input
              type='text'
              value={keywordsArray?.join(',')}
              maxLength={32}
              onChange={(e: any) => {
                const handleinput = e?.target?.value?.split(',');
                sKeywordsArray(handleinput);
              }}
              className='text-input'
              placeholder={'Enter keywords array'}
            />
          </div>
          <div className='col-md-3 mb-2'>
            <Dropdown
              label={'Live Stream'}
              option={[
                { label: 'Not live', value: 0 },
                { label: 'Content is live', value: 1 },
              ]}
              defaultValue={
                livestream.length === 0
                  ? []
                  : {
                      label: livestream === 0 ? 'Not live' : 'Content is live',
                      value: livestream,
                    }
              }
              onchange={(e) => sLivestream(e.value)}
              search={false}
            />
          </div>
          <div className='col-md-3 mb-2'>
            <Dropdown
              label={'Source Relationship'}
              option={[
                { label: 'Indirect', value: 0 },
                { label: 'Direct', value: 1 },
              ]}
              defaultValue={
                sourceRelationship.length === 0
                  ? []
                  : {
                      label: sourceRelationship === 0 ? 'Indirect' : 'Direct',
                      value: sourceRelationship,
                    }
              }
              onchange={(e) => sSourceRelationship(e.value)}
              search={false}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Length</label>
            <input
              type='number'
              value={len}
              maxLength={32}
              onChange={(e: any) => sLen(e.target.value)}
              className='text-input'
              placeholder={'Enter length'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Language</label>
            <input
              type='text'
              value={language}
              maxLength={32}
              onChange={(e: any) => {
                if (e.target.value !== '') {
                  sLanguage(e.target.value);
                } else {
                  sLanguage(null);
                }
              }}
              className='text-input'
              placeholder={'Enter language'}
            />
          </div>
          <div className='col-md-3 mb-2'>
            <Dropdown
              label={'Embeddable'}
              option={[
                { label: 'No', value: 0 },
                { label: 'Yes', value: 1 },
              ]}
              value={
                embeddable.length === 0
                  ? []
                  : { label: embeddable === 0 ? 'No' : 'Yes', value: embeddable }
              }
              onchange={(e) => sEmbeddable(e.value)}
              search={false}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Network Domain</label>
            <input
              type='text'
              value={networkDomain}
              maxLength={32}
              onChange={(e: any) => sNetworkDomain(e.target.value)}
              className='text-input'
              placeholder={'Enter network domain'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Channel Domain</label>
            <input
              type='text'
              value={channelDomain}
              maxLength={32}
              onChange={(e: any) => sChannelDomain(e.target.value)}
              className='text-input'
              placeholder={'Enter channel domain'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Network Name</label>
            <input
              type='text'
              value={networkName}
              maxLength={32}
              onChange={(e: any) => sNetworkName(e.target.value)}
              className='text-input'
              placeholder={'Enter network name'}
            />
          </div>
          <div className='col-md-3 mb-2 d-flex flex-column'>
            <label className='label'>Channel Name</label>
            <input
              type='text'
              value={channelName}
              maxLength={32}
              onChange={(e: any) => sChannelName(e.target.value)}
              className='text-input'
              placeholder={'Enter channel name'}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div
        className={
          !btnLoading
            ? 'modal-footer px-3 justify-content-between'
            : 'modal-footer px-3 justify-content-end'
        }
      >
        {!btnLoading ? (
          <>
            <div className='d-flex justify-content-start'>
              <button
                className='button button-secondary'
                onClick={() => {
                  onClear();
                }}
              >
                Clear
              </button>
            </div>
            <div className='d-flex gap-3'>
              <button className='button button-secondary' onClick={onHide}>
                Close
              </button>
              <button
                className='button button-primary'
                onClick={(e: any) => {
                  onSubmit(dealObj);
                  setPreviousFilter(dealObj);
                }}
              >
                {glossary.admin_inv_sidebar_button_submit}
              </button>
            </div>
          </>
        ) : (
          <ButtonR
            className='button button-loading d-flex align-items-center justify-content-center'
            disabled
          >
            Loading...
          </ButtonR>
        )}
      </div>
    </Modal>
  );
};
