import { headers, routes } from './constants';

export const HBLoginUrlAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.HBLoginUrl, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const HBSendEmailLoginAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.HBSendEmailLogin, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getHBlogoutAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getHBlogout, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getHBSendEmailsAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getHBSendEmails, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};
