import { headers, routes } from './constants';

export const getEndpointsListAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getEndpointList, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getEndpointReportAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getEndpointReport + `/${payload.id}`, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const addEndpointAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.addEndpoint, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const updateEndpointAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.updateEndpoint, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getBidderListAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getBidderList, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getHbCodeAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getHbCode, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const demandAdTxtAddAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.demandAdTxtAdd, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getDemandAdTxtAPI = () => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getDemandAdTxt, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getDemandEndpointInventoriesAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getDemandEndpointInventories, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getHeaderBidderEmailAPI = (payload: any) => {
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getHeaderBidderEmail + `/${payload}`, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const uploadCreativeAPI = (payload: any) => {
  const formData = new FormData();
  formData.append('video', payload);
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token') ?? ''}`,
    },
    method: 'POST',
    body: formData,
  };
  return new Promise((resolve, reject) => {
    fetch(routes.uploadCreative, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

// export const demandOverviewAPI = (payload: any) => {
//   headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
//   const options = {
//     headers,
//     method: 'POST',
//     body: JSON.stringify(payload),
//   };
//   return new Promise((resolve, reject) => {
//     fetch(routes.demandOverview, options)
//       .then((data) => data.json())
//       .then((data) => resolve(data))
//       .catch((error) => reject(error));
//   });
// };

export const getLoopListAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getLoopList, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const setAdsSubdomainAPI = (payload: any) => {
  headers['Authorization'] = `Bearer ${localStorage.getItem('token') ?? ''}`;
  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return new Promise((resolve, reject) => {
    fetch(routes.setAdsSubdomain, options)
      .then((data) => data.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getVastXMLAPI = (payload: any) => {
  const options = {
    headers,
    method: 'GET',
  };
  return new Promise((resolve, reject) => {
    fetch(routes.getVastXml + `/${payload.xml_code}/vast_xml`, options)
      .then((data) => data.text())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};
