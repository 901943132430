import { Modal } from 'react-bootstrap';

import Input from '../widgets/input';

export default ({ show, onHide }: any) => {
  return (
    <Modal onHide={onHide} show={show} centered size={'lg'}>
      <Modal.Body style={{ padding: '40px' }}>
        <div style={{ maxWidth: 400 }}>
          <Input
            label={'Title'}
            type={'text'}
            change={() => null}
            kind={'text'}
            placeholder={'Add a Title'}
          />
        </div>
        <div style={{ margin: '20px 0', height: 360, border: '1px solid gray' }}></div>
        <Input
          label={'Description'}
          type={'text'}
          change={() => null}
          kind={'text'}
          placeholder={'Add a Description'}
        />
      </Modal.Body>
    </Modal>
  );
};
