import { useContext, useState } from 'react';
import ReactJoyride, { TooltipRenderProps } from 'react-joyride';
import Button from '../../widgets/button';
import { updateTourAPI } from '../../../services/tags';
// import { useIntercom } from 'react-use-intercom';
// import JWT from 'jsonwebtoken';
import { UserInfoContext } from '../../contexts/userInfoContext';

const TourAfterCreateCardSSP = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
}: TooltipRenderProps) => (
  <div {...tooltipProps} style={{ background: 'white', padding: 24, borderRadius: 5 }}>
    <p style={{ textAlign: 'left', fontWeight: 200 }}>{step.title}</p>
    <br />
    <p style={{ textAlign: 'left' }}>{step.content}</p>
    <br />
    <br />
    <div className='d-flex justify-content-between'>
      <div className='d-flex gap-3 align-items-center'>
        <div {...skipProps}>
          <Button kind={'secondary'} text={'Skip'} />
        </div>
        <div>
          {step.target === '.tour-payouts' && (
            <a
              href='https://help.infy.tv/en/articles/6870945-how-to-check-earning-and-request-payouts'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-balance' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-platform-cost' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-settings' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-addnew-ssp' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.overview-tiles .col' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.overview-graphs' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.card-0' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.card-1' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.card-2' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-notif' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-help' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
        </div>
      </div>
      <div className='d-flex'>
        {index > 0 && (
          <div {...backProps}>
            <Button kind={'link'} text={'Prev'} />
          </div>
        )}
        {continuous && index !== 11 && (
          <div {...primaryProps}>
            <Button kind={'primary'} text={'Next'} />
          </div>
        )}
        {continuous && index === 11 && (
          <div {...closeProps}>
            <Button kind={'primary'} text={"Let's Go"} />
          </div>
        )}
        {!continuous && (
          <div {...closeProps}>
            <Button kind={'primary'} text={'Close'} />
          </div>
        )}
      </div>
    </div>
  </div>
);

const TourCardSSP = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
}: TooltipRenderProps) => (
  <div {...tooltipProps} style={{ background: 'white', padding: 24, borderRadius: 5 }}>
    <p style={{ textAlign: 'left', fontWeight: 200 }}>{step.title}</p>
    <br />
    <p style={{ textAlign: 'left' }}>{step.content}</p>
    <br />
    <br />
    <div className='d-flex justify-content-between'>
      <div className='d-flex gap-3 align-items-center'>
        <div {...skipProps}>
          <Button kind={'secondary'} text={'Skip'} />
        </div>
        <div>
          {step.target === '.tour-settings' && (
            <a
              href='https://help.infy.tv/en/articles/6870945-how-to-check-earning-and-request-payouts'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-create-vasttag' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-create-SSAI' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-notif' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
          {step.target === '.tour-help' && (
            <a
              href='https://help.infy.tv/en/articles/6870936-how-to-connect-demand-using-rtb-header-bidding-connectors-and-vast-url'
              target={'_blank'}
              rel='noreferrer'
              className='text-primary fw-lighter'
            >
              Know More
            </a>
          )}
        </div>
      </div>
      <div className='d-flex'>
        {index > 0 && (
          <div {...backProps}>
            <Button kind={'link'} text={'Prev'} />
          </div>
        )}
        {continuous && index !== 5 && (
          <div {...primaryProps}>
            <Button kind={'primary'} text={'Next'} />
          </div>
        )}
        {continuous && index === 5 && (
          <div {...closeProps}>
            <Button kind={'primary'} text={"Let's Go"} />
          </div>
        )}
        {!continuous && (
          <div {...closeProps}>
            <Button kind={'primary'} text={'Close'} />
          </div>
        )}
      </div>
    </div>
  </div>
);

interface Props {
  tourDoneProps?: any;
  tagsStatus?: any;
  loader?: any;
  steps?: any;
}

const PublisherTour = (props: Props) => {
  const { tagsStatus, loader, steps, tourDoneProps } = props;

  // Context API
  const { setUserInfo, userInfo }: any = useContext(UserInfoContext);

  if (!userInfo?.user?.tour) {
    // If it's empty or undefined, add a class to the body
    document.body.classList.add('tour-empty');
  } else {
    // If it's not empty, remove the class from the body
    document.body.classList.remove('tour-empty');
  }

  const [tourStepsSSP] = useState<any>([
    {
      target: '.tour-settings',
      title: 'Settings',
      content: 'Click on settings to manage your profile, company and financial settings.',
    },
    {
      target: ".on-bording-tabs > [data-rb-event-key='addReel']",
      title: 'Create Ad Reels Endpoint',
      content:
        'Fill in the Default Video Extension Endpoint Parameters fields with the information.',
      placement: 'top',
    },
    {
      target: ".on-bording-tabs > [data-rb-event-key='vastTag']",
      title: 'Create VAST Tag',
      content: 'Fill in the Default VAST Tag Parameters fields with the information.',
      placement: 'top',
    },
    {
      target: ".on-bording-tabs > [data-rb-event-key='ssai']",
      title: 'Create SSAI Endpoint',
      content: 'Fill in the Default SSAI Endpoint Parameters fields with the information.',
      placement: 'top',
    },
    {
      target: '.tour-notif',
      title: 'Notification',
      content: 'Check your notification regularly for updates on your platform.',
      placement: 'left',
    },
    {
      target: '.tour-help',
      title: 'Help',
      content:
        'Get instant assistance with all your queries by reaching out to our dedicated support team.',
      placement: 'left',
    },
  ]);

  const [tourAfterCreateTagStepsSSP] = useState<any>([
    {
      target: '.tour-payouts',
      title: 'Manage Your Payout',
      content: 'Shortcut to manage your finance, add paymemt details and manage Payout.',
    },
    {
      target: '.tour-balance',
      title: 'Manage Your Available Balance',
      content:
        'Stay on top of your finances with real-time access to your available balance at any time.',
    },
    {
      target: '.tour-platform-cost',
      title: 'Platform Cost',
      content:
        'Maximize your ad spend with our cost-effective and customizable adtech platform solutions.',
    },
    {
      target: '.tour-settings',
      title: 'Settings',
      content: 'Click on settings to manage your profile, company and financial settings.',
      placement: 'left',
    },
    {
      target: '.tour-addnew-ssp',
      title: 'Add New Source',
      content: 'Create new tag.',
      placement: 'right',
    },
    {
      target: '.overview-tiles .col',
      title: 'Overview',
      content: 'You can check impressions and earings of your all tags.',
    },
    {
      target: '.overview-graphs',
      title: 'Impression',
      content: 'Here you can get performace visualisation of your tags.',
    },
    {
      target: '.card-0',
      title: 'Top Earning',
      content: 'You can get all information about tags and how well the are Top Earning.',
    },
    {
      target: '.card-1',
      title: 'Top Performing',
      content: 'You can get all information about tags and how well the are Top Performing.',
    },
    {
      target: '.card-2',
      title: 'Low Performing',
      content: 'You can get all information about tags and how well the are Low Performing.',
    },
    {
      target: '.tour-notif',
      title: 'Notification',
      content: 'Check your notification regularly for updates on your platform.',
      placement: 'left',
    },
    {
      target: '.tour-help',
      title: 'Help',
      content:
        'Get instant assistance with all your queries by reaching out to our dedicated support team.',
      placement: 'left',
    },
  ]);

  const [tourDone, sTourDone] = useState('');

  const handleTourCallback = (data: any) => {
    const { action } = data;
    if (action === 'close' || action === 'skip') {
      updateTourAPI({ tour: 'done' })
        .then((data: any) => {
          setUserInfo((prev: any) => ({
            ...prev,
            user: {
              ...prev.user,
              tour: 'done',
            },
          }));
          sTourDone(data.status);
          //Intercom
          // if (data.status) {
          //   const token = localStorage.getItem('token') || '';
          //   const decoded = JWT.decode(token, { complete: true });
          //   // @ts-ignore
          //   const user_id = decoded?.payload?.user_id;
          //   // @ts-ignore
          //   const customer_id = decoded?.payload?.company_id;
          //   // @ts-ignore
          //   const email = decoded?.payload?.identity;
          //   // @ts-ignore
          //   const userName = JSON.parse(localStorage?.getItem('user'))?.firstName ?? '';
          //   bootWithProps({ user_id, customer_id, email, userName });
          // }
        })
        .catch(() => {
          //
        });
    }
  };

  //Intercom
  // const { update, boot } = useIntercom();
  // const bootWithProps = ({ user_id, customer_id, email, userName }: any) => {
  //   //@ts-ignore
  //   boot({
  //     name: userName,
  //     email: email,
  //     customAttributes: { user_id: user_id, customerID: customer_id },
  //   });
  // };

  props.tourDoneProps(tourDone);

  return (
    <>
      {!tagsStatus && loader && steps && (
        <ReactJoyride
          callback={handleTourCallback}
          continuous={true}
          steps={tourStepsSSP}
          spotlightClicks={true}
          tooltipComponent={TourCardSSP}
          showSkipButton={true}
          disableScrolling={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
        />
      )}

      {tagsStatus && loader && steps && (
        <ReactJoyride
          callback={handleTourCallback}
          continuous={true}
          steps={tourAfterCreateTagStepsSSP}
          spotlightClicks={true}
          tooltipComponent={TourAfterCreateCardSSP}
          showSkipButton={true}
          disableScrolling={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
        />
      )}
    </>
  );
};

export default PublisherTour;
