import Button from '../../widgets/button';
import { useEffect, useRef, useState } from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { IoCopyOutline } from 'react-icons/io5';
import { MdInfoOutline } from 'react-icons/md';
import {
  addPublisherAdstxtAPI,
  clearPublisherAdstxtAPI,
  getPublisherAdstxtAPI,
} from '../../../services/settings';
import { useLocation } from 'react-router-dom';
import useAuthentication from '../../contexts/OverViewContext';
import consumerData from '../../contexts/OverViewContext';

export default ({ toast, store }: any) => {
  const { getPublisherAdstxtAPI }: any = useAuthentication();
  const { adsTxtDataPublisher, sAdsTxtDataPublisher }: any = consumerData();

  //const [adstxtUrl, setAdstxtUrl] = useState();
  //const [adsTxtData, setAdsTxtData] = useState<any>();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const textAreaRef: any = useRef(null);
  const [adsTxt, setAdsTxt] = useState<any>();
  const [isClearing, setIsClearing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { pathname } = useLocation();

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setAdsTxt(adsTxtDataPublisher?.ads_txt_data);
  }, [adsTxtDataPublisher]);

  useEffect(() => {
    if (pathname === '/settings/ssp-Adstxt' || adsTxtDataPublisher?.url === '') {
      api();
    } else {
      setBtnDisabled(false);
    }
  }, []);

  const api = () => {
    getPublisherAdstxtAPI()
      .then((data: any) => {
        if (data) {
          sAdsTxtDataPublisher(data);
          //sAdsTxtPublisher(data.ads_txt_data);
          //setAdstxtUrl(data.url);
          setBtnDisabled(false);
        }
      })
      .catch((error: any) => {
        toast.error(error);
      });
  };

  // Auto Resize textarea
  const resizeTextArea = () => {
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
  };
  useEffect(resizeTextArea, [adsTxt]);

  const handleUpdateAdsTxt = (e: any) => {
    if (e.target.value || e.target.value == '') {
      setAdsTxt(e.target.value);
      setBtnDisabled(true);
      setIsToastShown(false);
    }
  };

  // Multiple Toast Message Handle Start //
  const [isToastShown, setIsToastShown] = useState(false);

  const handleToast = () => {
    if (!isToastShown) {
      toast.success('Ads.txt cleared successful.', {
        toastId: 'adstxt-clear',
      });
      setIsToastShown(true);
    }
  };
  // Multiple Toast Message Handle End //

  const clearAdstxt = () => {
    textAreaRef.current.style.height = '42px';
    setIsClearing(true);
    clearPublisherAdstxtAPI()
      .then((data: any) => {
        if (data.status) {
          handleToast();
          api();
          setBtnDisabled(false);
          setAdsTxt('');
        }
        setTimeout(() => {
          setIsClearing(false);
        }, 200);
      })
      .catch((error: any) => {
        //ok
      });
  };

  const addPublisherAdstxt = () => {
    const payload = {
      ad_txt_data: adsTxt,
    };
    setIsUpdating(true);
    addPublisherAdstxtAPI(payload)
      .then((data: any) => {
        setBtnDisabled(false);
        if (data.status === true) {
          toast.success(glossary.setting_ssp_adstxt_add_update_success_msg, {
            toastId: 'adstxt-add',
          });
          api();
        } else {
          api();
          toast.error(glossary.setting_ssp_adstxt_add_valid_error, {
            toastId: 'adstxt-error',
          });
        }
        setTimeout(() => {
          setIsUpdating(false);
        }, 300);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className='align-items-start flex-column gap-3 justify-content-start row flex-lg-row'>
        <div className={'col'}>
          <h2 style={{ fontWeight: 200 }}>{glossary.setting_ssp_adstxt_title}</h2>
        </div>
      </div>

      <div className='row mt-4 align-items-md-start'>
        <div className='col left'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <h3>{glossary.setting_ssp_adstxt_field_label_redirect_url}</h3>
                <div className=' w-75'>
                  <div
                    className='createtag-vasturl mt-2'
                    style={{ maxHeight: '39px', padding: '6px 10px', minHeight: 'inherit' }}
                  >
                    <div className='align-items-center d-flex justify-content-between'>
                      <p>{adsTxtDataPublisher?.url}</p>
                      {adsTxtDataPublisher?.url && (
                        <>
                          <div>
                            <IoCopyOutline
                              fontSize={20}
                              className='svg-icon me-2'
                              title='Copy URL'
                              onClick={() => {
                                navigator.clipboard.writeText(adsTxtDataPublisher?.url);
                                toast.success(
                                  glossary.setting_ssp_adstxt_field_redirect_url_copy_success_msg
                                );
                              }}
                            />
                            <a href={adsTxtDataPublisher?.url} target='_black' rel=''>
                              <GoLinkExternal
                                className='svg-icon'
                                fontSize={20}
                                title='Redirect URL'
                              />
                            </a>
                          </div>

                          {/* createtag-vasturl-icon */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='card'>
                <h1 className='fw-lighter h4'>
                  {glossary.setting_ssp_adstxt_field_publish_adstxt_label}
                </h1>
                <label className='form-label d-flex mt-2'>
                  <MdInfoOutline className='s-svg-icon flex-shrink-0 me-1' fontSize={20} />
                  <span>{glossary.setting_ssp_adstxt_publish_adstxt_field_instructions}</span>
                </label>
              </div>
            </div>
            <div className='col-12'>
              <div className='card'>
                <h5 className='fw-lighter h4'>{glossary.setting_ssp_adstxt_field_label_example}</h5>
                <label className='label'>
                  {glossary.setting_ssp_adstxt_example_field_instructions}
                </label>
                <a
                  href={adsTxtDataPublisher?.url}
                  target='_black'
                  rel=''
                  style={{ width: 'fit-content' }}
                >
                  {adsTxtDataPublisher?.url}
                </a>
              </div>
            </div>
            <div className='col-12'>
              <div className='card'>
                <h1 className='fw-lighter h4'>
                  {glossary.setting_ssp_adstxt_field_publish_app_adstxt_label}
                </h1>
                <label className='form-label d-flex mt-2'>
                  <MdInfoOutline className='s-svg-icon flex-shrink-0 me-1' fontSize={20} />
                  <span>{glossary.setting_ssp_adstxt_publish_app_adstxt_field_instructions}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='col right'>
          <h3 style={{ fontWeight: 200 }}>{glossary.setting_ssp_adstxt_field_add_adstxt_label}</h3>
          <label className='form-label d-flex mt-2'>
            <MdInfoOutline className='s-svg-icon flex-shrink-0 me-1' fontSize={20} />
            <span>{glossary.setting_ssp_adstxt_field_add_adstxt_instructions}</span>
          </label>
          <textarea
            ref={textAreaRef}
            className='form-control mt-2 auto-textarea'
            rows={1}
            value={adsTxt}
            onChange={handleUpdateAdsTxt}
          >
            {adsTxt}
          </textarea>

          <div className='mt-3 d-flex justify-content-end gap-3'>
            {isUpdating ? (
              <Button kind={'spinner'} text={glossary.updating} type='button' />
            ) : (
              <Button
                type={btnDisabled ? 'button' : 'submit'}
                kind={btnDisabled ? 'primary' : 'disabled'}
                text={glossary.setting_ssp_adstxt_button_update}
                click={() => {
                  addPublisherAdstxt();
                }}
              />
            )}
            {isClearing ? (
              <Button kind={'spinner'} text={'Clearing...'} type='button' />
            ) : (
              <Button
                kind={'primary'}
                text={glossary.setting_ssp_adstxt_button_clear_adstxt}
                click={() => {
                  clearAdstxt();
                  sAdsTxtDataPublisher('');
                  setBtnDisabled(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
