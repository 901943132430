import { useEffect, useState, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import Button from '../../widgets/button';
import { CSVLink } from 'react-csv';
import sampleCSV from '../../../states/inviteCustomerCSV';
import store from '../../../states';
import { Formik, Form, FieldArray } from 'formik';
import FormikController from '../form/FormikController';
import { Modal } from 'react-bootstrap';
import { debounce } from 'lodash';

import {
  customerInviteSPOAPI,
  customerInviteSSPAPI,
  inviteCheckEmailAPI,
} from '../../../services/spo';
import { toast } from 'react-toastify';

const CSVTable = ({ onConvertedArray, onHide, type, getEndPointList }: any) => {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [domainName, setDomainName] = useState<any>([]);
  const [emailError, setEmailError] = useState<any>([]);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const fileReader = new FileReader();

  const handleBulkUpload = (e: any) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string: any) => {
    const csvHeader = string
      .slice(0, string.indexOf('\n'))
      .split(',')
      .map((header: string) => header.replace(/"/g, ''));
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows
      .map((row: string) => {
        const values = row.split(',').map((value: string) => value.replace(/"/g, ''));
        const obj = csvHeader.reduce((object: any, header: any, index: any) => {
          object[header] = values[index];
          return object;
        }, {});

        // Check if any key has an empty or undefined value
        const hasEmptyValue = Object.values(obj).some(
          (value) => value === undefined || value === ''
        );
        if (!hasEmptyValue) {
          return obj;
        }
        return null;
      })
      .filter((obj: any) => obj !== null);

    setArray(array);
  };

  useEffect(() => {
    if (file) {
      fileReader.onload = function (event: any) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  }, [file]);

  //convert array as per payload structure
  const convertedArray = useMemo(() => {
    return array.map((item: any) => {
      const users = [
        {
          first_name: item.first_name,
          last_name: item.last_name,
          email: item.email,
        },
      ];

      return {
        name: item.name,
        domain: '',
        users: users,
      };
    });
  }, [array]);

  //pass rows data parent component
  useEffect(() => {
    onConvertedArray(convertedArray);
  }, [convertedArray.length > 0]);

  const handleCheckEmail = useCallback((array: any) => {
    array.forEach((item: any, index: any) => {
      const users = item.users;

      users.forEach((user: any) => {
        const payload = {
          email: user.email,
        };
        inviteCheckEmailAPI(payload)
          .then((data: any) => {
            if (data.status === false) {
              const errorEmail = payload.email;
              //console.log('data', data?.message);
              const validationError = data?.message;
              //toast.error(data?.message);
              setEmailError((prevEmailError: any) => [
                ...prevEmailError,
                { errorEmail, validationError, index },
              ]);
              //console.log('array', array);
            } else {
              const domain = data.data.domain;
              const email = data.data.email;
              setDomainName((prevDomainName: any) => [...prevDomainName, { domain, email }]);
            }
          })
          .catch((error) => {
            // Handle error
          });
      });
    });
  }, []);

  //console.log('email error', emailError);

  useEffect(() => {
    if (convertedArray.length > 0) {
      handleCheckEmail(convertedArray);
    }
  }, [convertedArray, handleCheckEmail]);

  const handleSubmit = (e: any) => {
    const payload = {
      customers: e.customer,
    };
    //console.log('payload', payload);
    setLoadingBtn(true);
    if (type === 'spo-publisher') {
      customerInviteSSPAPI(payload)
        .then((data: any) => {
          if (data.invitation_status === 0) {
            toast.error(data?.message);
            setLoadingBtn(false);
          }
          if (data.invitation_status === 1) {
            getEndPointList(data, 'add');
            setLoadingBtn(false);
            toast.error(data?.message);
          }
          if (data.status === true && data.invitation_status === 2) {
            getEndPointList(data, 'add');
            setLoadingBtn(false);
            toast.success(data?.message);
          }
          if (data.error === 400) {
            setLoadingBtn(false);
          }
        })
        .catch((error: any) => {
          toast.error(error);
        });
    } else {
      customerInviteSPOAPI(payload)
        .then((data: any) => {
          if (data.invitation_status === 0) {
            toast.error(data?.message);
            setLoadingBtn(false);
          }
          if (data.invitation_status === 1) {
            getEndPointList(data, 'add');
            setLoadingBtn(false);
            toast.error(data?.message);
          }
          if (data.status === true && data.invitation_status === 2) {
            getEndPointList(data, 'add');
            setLoadingBtn(false);
            toast.success(data?.message);
          }
        })
        .catch((error: any) => {
          toast.error(error);
        });
    }
  };

  const validationSchema = Yup.object().shape({
    customer: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(glossary.spo_invite_popup_input_company_name_empty_error),
        users: Yup.array().of(
          Yup.object().shape({
            first_name: Yup.string().required(
              glossary.spo_invite_popup_input_first_name_empty_error
            ),
            last_name: Yup.string().required(glossary.spo_invite_popup_input_last_name_empty_error),
            email: Yup.string()
              //.email(glossary.spo_invite_popup_input_email_valid_error)
              .required(glossary.spo_invite_popup_input_email_empty_error),
          })
        ),
      })
    ),
  });

  const debouncedHandleCheckEmail = debounce(handleCheckEmail1, 1000);

  function handleCheckEmail1(event: any, index: any) {
    const { value } = event.target;
    const payload = {
      email: value,
    };
    //console.log('payload', payload);
    inviteCheckEmailAPI(payload)
      .then((data: any) => {
        if (data.status === false) {
          //toast.error(data?.message);
          const errorEmail = payload.email;
          const validationError = data?.message;
          setEmailError((prevEmailError: any) => [
            ...prevEmailError,
            { errorEmail, validationError, index },
          ]);
        } else {
          const domain = data.data.domain;
          const email = data.data.email;
          //console.log('domain', domain, email, data.status, index);
          setEmailError((prevEmailError: any[]) =>
            prevEmailError.filter((item: any) => item.index !== index)
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  return (
    <>
      {/* Your JSX code for the table */}
      {convertedArray.length > 0 && (
        <>
          <Formik
            initialValues={{
              customer: convertedArray,
            }}
            validationSchema={validationSchema}
            onSubmit={(e) => {
              e.customer.forEach((customer: any) => {
                const domainObj = domainName.find(
                  (obj: any) => obj.email === customer?.users[0].email
                );
                customer.domain = domainObj?.domain || '';
              });
              handleSubmit(e);
            }}
          >
            {({ values }) => (
              <>
                <Form>
                  <Modal.Body
                    className={'p-3'}
                    style={{
                      maxHeight: 'calc(100vh - 210px)',
                      overflowY: 'auto',
                    }}
                  >
                    <FieldArray name='customer'>
                      {({ remove, push }) => (
                        <div className='col'>
                          {values.customer.map((customer, customerIndex) => (
                            <div className='row mb-3' key={customerIndex}>
                              <div className='col-3'>
                                <FormikController
                                  type='text'
                                  control='input'
                                  kind='withouticon'
                                  name={`customer[${customerIndex}].name`}
                                  placeholder={
                                    glossary.spo_invite_popup_input_company_name_placeholder
                                  }
                                  label={glossary.spo_invite_popup_input_field_label_company_name}
                                />
                              </div>
                              <div className='col'>
                                <FieldArray name={`customer.${customerIndex}.users`}>
                                  {({ remove: removeUser, push: pushUser }) => (
                                    <>
                                      {customer.users.map((users, userIndex) => (
                                        <div className='row' key={userIndex}>
                                          <div className='col-4'>
                                            <FormikController
                                              type='text'
                                              control='input'
                                              kind='withouticon'
                                              name={`customer.${customerIndex}.users.${userIndex}.first_name`}
                                              placeholder={
                                                glossary.spo_invite_popup_input_first_name_placeholder
                                              }
                                              label={
                                                glossary.spo_invite_popup_input_field_label_first_name
                                              }
                                            />
                                          </div>
                                          <div className='col-4'>
                                            <FormikController
                                              type='text'
                                              control='input'
                                              kind='withouticon'
                                              name={`customer.${customerIndex}.users.${userIndex}.last_name`}
                                              placeholder={
                                                glossary.spo_invite_popup_input_last_name_placeholder
                                              }
                                              label={
                                                glossary.spo_invite_popup_input_field_label_last_name
                                              }
                                            />
                                          </div>
                                          <div className='col-4'>
                                            <FormikController
                                              type='text'
                                              control='input'
                                              kind='withouticon'
                                              name={`customer.${customerIndex}.users.${userIndex}.email`}
                                              placeholder={
                                                glossary.spo_invite_popup_input_email_placeholder
                                              }
                                              label={
                                                glossary.spo_invite_popup_input_field_label_email
                                              }
                                              onInput={(e: any) =>
                                                debouncedHandleCheckEmail(e, customerIndex)
                                              }
                                            />
                                            {emailError.map((item: any, index:any) => {
                                              if (item.errorEmail === customer.users[0].email) {
                                                return (
                                                  <p className='text-danger small' key={index}>
                                                    {/* Email from common provider */}
                                                    {item?.validationError}
                                                  </p>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                              <div className='col-auto'>
                                <div className='d-flex gap-3'>
                                  {customerIndex > 0 && (
                                    <div className='d-flex flex-column'>
                                      <label className='label'>&nbsp;</label>
                                      <Button
                                        type='button'
                                        customClass='button'
                                        text='x'
                                        click={() => {
                                          remove(customerIndex);
                                          setEmailError((prevEmailError: any[]) =>
                                            prevEmailError.filter(
                                              (item: any) =>
                                                item.errorEmail !== customer.users[0].email
                                            )
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div className='d-flex flex-column'>
                                    <label className='label'>&nbsp;</label>
                                    <Button
                                      type='button'
                                      kind={'primary'}
                                      text='+'
                                      click={() =>
                                        push({
                                          name: '',
                                          domain: '',
                                          users: [{ email: '', first_name: '', last_name: '' }],
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </Modal.Body>
                  <Modal.Footer className='px-3 gap-2'>
                    <Button
                      type='button'
                      kind={'secondary'}
                      customClass={'float-end'}
                      text={glossary.spo_invite_popup_button_cancel}
                      click={() => {
                        onHide();
                      }}
                    />
                    {!loadingBtn ? (
                      <Button
                        type='submit'
                        kind={emailError.length > 0 ? 'disabled-secondary' : 'primary'}
                        customClass={'float-end'}
                        text={glossary.spo_invite_popup_button_invite}
                      />
                    ) : (
                      <Button type='button' kind={'spinner'} text={'Loading...'} />
                    )}
                  </Modal.Footer>
                </Form>
              </>
            )}
          </Formik>
        </>
      )}
      <div className='position-absolute' style={{ left: '15px', bottom: '15px' }}>
        <div className='d-flex align-items-center gap-3'>
          <p className='fw-lighter'>{glossary.spo_invited_csv_upload_label}</p>
          <div className='upload-input'>
            <input
              type='file'
              id='csvFileInput'
              accept='.csv'
              onChange={handleBulkUpload}
              className='button'
            />
            <Button type='button' kind='secondary' text={glossary.spo_invited_csv_button_upload} />
          </div>
          <CSVLink filename={`SampleCSV.csv`} headers={sampleCSV?.columns} data={sampleCSV?.data}>
            <Button type='button' kind='primary' text={glossary.spo_invited_csv_button_sample} />
          </CSVLink>
        </div>
      </div>
    </>
  );
};

export default CSVTable;
