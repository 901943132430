import { useEffect, useState } from 'react';
// import { reportsAPI } from '../../services/tags';
import { inventoryToggleIncludeAPI, inventoryToggleExcludeAPI } from '../../services/inventory';
import Tabs from '../widgets/tabs';
import InfyTable from '../tables/InfyTable';
import { headers, routes } from '../../services/constants';
import BrandSafety from '../modals/brand-safety';
import { toast } from 'react-toastify';
import { modifiedID } from './functions';
import { reportToggleStatusChangeAPI } from '../../services/tags';

const Table = ({
  selected_id,
  tabName,
  tabPath,
  customerId,
  entityType,
  convert,
  start,
  end,
  getTableData,
  metaData,
}: any) => {
  const [data, setData] = useState<any>([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [brandSafety, setBrandSafety] = useState(false);
  const [scrollXevent, setScrollXevent] = useState<number | null>(null);
  const [sortby, setSortby] = useState({});

  let tableType = '';

  switch (tabName) {
    case 'Advertisers':
      tableType = 'advertiser';
      break;
    case 'Creatives':
      tableType = 'creative';
      break;
    case 'DSPs':
      tableType = 'endpoint';
      break;
    case 'Demand':
      tableType = 'endpoint';
      break;
    case 'Exchanges':
      tableType = 'exchange';
      break;
    case 'Tags':
      tableType = 'tag';
      break;
    case 'Duration':
      tableType = 'duration';
      break;
    case 'Campaigns':
      tableType = 'campaign';
      break;
    case 'Inventories':
      tableType = 'inventory';
      break;
    case 'Publishers':
      tableType = 'publisher';
      break;
    case 'Location':
      tableType = 'country';
      break;
    case 'Device Type':
      tableType = 'device';
      break;
    default:
      break;
  }

  const handleChangeStatus = (_row: any) => {
    const payload = {
      ..._row,
    };
    reportToggleStatusChangeAPI(payload)
      .then((data: any) => {
        if (data?.status) {
          getMoreData(0, '', {});
          toast.success('Status Update.');
        }
      })
      .catch((error: any) => {
        console.log('error===', error);
      });
  };

  const inventoryToggleInclude = (
    _row_id: any,
    _table_type: string,
    selected_id: string,
    customerId: number
  ) => {
    let payload: any;

    if (localStorage.getItem('r') === 'infy-admin') {
      payload = {
        a_id: [_row_id] ?? [0],
        a_type: _table_type,
        customer_id: selected_id,
      };
    } else {
      payload = {
        a_id: [_row_id] ?? [0],
        a_type: _table_type,
        customer_id: customerId,
        r_id: selected_id,
        r_type: entityType,
      };
    }
    inventoryToggleIncludeAPI(payload)
      .then((data: any) => {
        if (data.status) getMoreData(0, '', {});
        toast.success('Status Update.');
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const inventoryToggleExclude = (
    _row_id: any,
    _table_type: string,
    selected_id: string,
    customerId: number
  ) => {
    let payload: any;

    if (localStorage.getItem('r') === 'infy-admin') {
      payload = {
        a_id: [_row_id] ?? [0],
        a_type: _table_type,
        customer_id: selected_id,
      };
    } else {
      payload = {
        a_id: [_row_id] ?? [0],
        a_type: _table_type,
        customer_id: customerId,
        r_id: selected_id,
        r_type: entityType,
      };
    }
    inventoryToggleExcludeAPI(payload)
      .then((data: any) => {
        if (data.status) getMoreData(0, '', {});
        toast.success('Status Update.');
      })
      .catch(() => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  const getMoreData = async (pageIndex: number, keyword: string, sort: any) => {
    setLoading(true);

    const requestBody = {
      id: modifiedID(selected_id),
      path: tabPath,
      start_date: convert(start) || '',
      end_date: convert(end) || '',
      time_zone: 'Asia/Kolkata',
      page: pageIndex,
      page_size: 20,
      sort_by: {
        sortBy: [sort],
      },
      query: keyword,
      type: 'user', // Default type
      ...(metaData && { ...metaData }),
    };

    if (entityType) {
      if (entityType === 'end_point') requestBody.type = 'endpoint';
      else if (entityType === 'partner') requestBody.type = 'partner';
      else if (entityType === 'extension') requestBody.type = 'extension';
      else if (localStorage.getItem('r') === 'infy-admin') requestBody.type = 'admin';
    }

    const requestOptions = {
      headers,
      method: 'POST',
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        (requestBody.type === 'endpoint'
          ? routes.getEndpointReport
          : requestBody.type === 'partner'
          ? routes.getPartnerReport
          : requestBody.type === 'extension'
          ? routes.extentionDetails
          : requestBody.type === 'admin'
          ? routes.getCustomer
          : routes.getTagDetails) + `/${requestBody.id}${requestBody.path}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      const { data, columns } = responseData;

      if (data.length === 0) {
        setHasMore(false);
        setLoading(false);
        return; // Exit without further processing
      }

      if (pageIndex > 0) {
        setData((prevData: any) => [...prevData, ...data]);
      } else {
        setData(data);
        setColumns(columns);
        getTableData(data);
      }

      setLoading(false);
    } catch (error) {
      // handle error here
      console.error('Error:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Perform the default API call
    getMoreData(0, '', {});
  }, []);

  // On scroll next page
  function onNextPage(pageNumber: number) {
    // Perform the API call for vertical scroll
    getMoreData(pageNumber, keyword, {});

    // Check if there's a horizontal scroll in progress
    if (scrollXevent !== null) {
      return; // Skip the API call for horizontal scroll
    }

    // Check if there's no more data to fetch
    if (!hasMore) {
      return; // Skip the API call if no more data
    }
  }

  // Get Search Input Value
  function onSearchChage(val: string) {
    setKeyword(val);
    if (val.length > 0) {
      getMoreData(0, val, sortby);
    } else {
      getMoreData(0, '', {});
      setHasMore(true);
    }
  }

  // Sorting Data
  function handleSort(sortBy: any) {
    getMoreData(0, keyword, sortBy);
    setLoading(false);
    setSortby(sortBy);
  }

  // Call Back function for any action event
  const apiCallBack = () => {
    getMoreData(0, keyword, sortby);
  };

  // Handle brand safty
  const handleBrandSafety = () => {
    setBrandSafety(true);
  };

  // handle table scroll event for api call
  const handleScrollXevent = (e: any) => {
    //const { scrollLeft } = e?.target;
    setScrollXevent(e);
  };

  return (
    <>
      {/* Brand Safety Modal */}
      <BrandSafety
        show={brandSafety}
        entityType={entityType}
        onHide={() => setBrandSafety(false)}
        selectedCardId={selected_id}
        apiCallBack={apiCallBack}
      />

      <InfyTable
        data={data}
        columns={columns}
        onNextPage={onNextPage}
        onSearchChage={onSearchChage}
        onSort={handleSort}
        hasMore={hasMore}
        loading={loading}
        columnWidth={130}
        apiCallBack={apiCallBack}
        tableName={'Report'}
        customerId={customerId}
        reportTableType={tableType}
        selectedCardId={selected_id}
        csvFileName={tabName}
        // toggleInclude={inventoryToggleInclude}
        // toggleExclude={inventoryToggleExclude}
        toggleInclude={handleChangeStatus}
        toggleExclude={handleChangeStatus}
        downloadCSV={true}
        search={true}
        action={
          tableType === 'advertiser'
            ? {
                text: 'Brand Safety',
                click: handleBrandSafety,
                customClass: 'position-absolute',
                css: {
                  right: '160px',
                },
              }
            : undefined
        }
        scrollXeventProp={handleScrollXevent}
      />
    </>
  );
};

export default (props: any) => {
  const [loaded, sLoaded] = useState(false);

  const [tabsToRender, sTabsToRender] = useState<any>([]);

  const { id, tabs, start, end, random, entityType, customerId, metaData } = props;

  const convert = (str: any) => {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  const [srTable, sSrTable] = useState<any>([]);
  const [isTabsShow, setIsTabsShow] = useState<any>();

  useEffect(() => {
    sLoaded(false);
    sTabsToRender([]);
    sSrTable([]);
    setIsTabsShow(false);
  }, [id, start, end]);

  useEffect(() => {
    const checkIfAnyArrayHasData = async () => {
      const promises = srTable.map(async (array: any) => {
        await new Promise((resolve) => setTimeout(resolve, 300));
        return array.length > 0;
      });

      const results = await Promise.all(promises);
      return results.some((result) => result);
    };

    checkIfAnyArrayHasData()
      .then((result) => {
        setIsTabsShow(result);
      })
      .catch((error) => {
        //
      });
  }, [srTable]);

  useEffect(() => {
    if (tabsToRender?.length <= 0) {
      if (tabs && tabs?.length > 0) {
        if (!loaded) {
          // Render Tabs
          const tabsData = tabs.map((tab: any) => {
            return { name: tab.name, path: tab.path, id };
          });

          sLoaded(true);

          Promise.all(tabsData).then((results) => {
            const newTabsToRender = [...tabsToRender];
            results.forEach((result: any) => {
              if (id === result?.id) {
                newTabsToRender.push({
                  id: result.name,
                  label: result.name,
                  content: (
                    <Table
                      tabName={result.name}
                      tabPath={result.path}
                      selected_id={id}
                      customerId={customerId}
                      entityType={entityType}
                      convert={convert}
                      start={start}
                      end={end}
                      getTableData={(table: any) =>
                        sSrTable((prevTableData: any) => [...prevTableData, table])
                      }
                      metaData={metaData} // Adding key in payload
                    />
                  ),
                });
              }
            });
            sTabsToRender(newTabsToRender);
          });
        }
      }
    }
  }, [tabs, random]);

  return (
    <>
      <div className={isTabsShow ? 'd-block' : 'd-none'} style={{ position: 'relative' }}>
        <Tabs items={tabsToRender} selected={tabsToRender[0]?.id ?? ''} tabLength={tabs?.length} />
      </div>
    </>
  );
};
