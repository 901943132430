import { useState, useEffect, useMemo } from 'react';
// import { MultiSelect } from 'react-multi-select-component';
import { getMacrosListPostAPI } from '../../services/macros';
import store from '../../states';

import { MdInfoOutline } from 'react-icons/md';
// import Dropdown from '../widgets/dropdown';
import MacroDropdown from '../widgets/macros-dropdown';
import ButtonSkeleton from '../loaders/button';

interface Props {
  createTag?: any;
  deviceType?: any;
  selectedTagDetails?: any;
  appParamFinalUrl?: any;
  siteParamFinalUrl?: any;
  impParamUFinalUrl?: any;
  deviceParamFinalUrl?: any;
  contentParamFinalUrl?: any;
  siteContentParamFinalUrl?: any;
  userParamFinalUrl?: any;
  customParamFinalUrl?: any;
  selectedAppParamFinalUrl?: any;
  selectedSiteParamFinalUrl?: any;
}

const MacrosDropDown = (props: Props) => {
  const { deviceType, selectedTagDetails, createTag } = props;

  const [isDataLoading, setIsDataLoading] = useState(false);

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const [getMacrosList, setGetMacrosList] = useState<any>([]);

  const [selectedSiteParameters, setSelectedSiteParameters] = useState([]);
  const [selectedSiteParamUrl, setSelectedSiteParamUrl] = useState('');

  const [selectedAppParameters, setSelectedAppParameters] = useState([]);
  const [selectedAppParamUrl, setSelectedAppParamUrl] = useState('');

  const [appParameters, setAppParameters] = useState([]);
  const [appParamUrl, setAppParamUrl] = useState('');

  const [siteParameters, setSiteParameters] = useState([]);
  const [siteParamUrl, setSiteParamUrl] = useState('');

  const [impressionParameters, setImpressionParameters] = useState([]);
  const [impressionParamUrl, setImpressionParamUrl] = useState('');

  const [deviceParameters, setDeviceParameters] = useState([]);
  const [deviceParamUrl, setDeviceParamUrl] = useState('');

  const [siteContentParameters, setSiteContentParameters] = useState([]);
  const [siteContentParamUrl, setSiteContentParamUrl] = useState('');

  const [contentParameters, setContentParameters] = useState([]);
  const [contentParamUrl, setContentParamUrl] = useState('');

  const [userParameters, setUserParameters] = useState([]);
  const [userParamUrl, setUserParamUrl] = useState('');

  const [customParameters, setCustomParameters] = useState([]);
  const [customParamUrl, setCustomParamUrl] = useState('');

  //For Custom Parameters
  const excludeObjects = ['App', 'Site', 'Imp', 'Device', 'Content', 'SiteContent', 'User', 'Regs'];

  //filter app parameters
  const AppParametersOptions =
    getMacrosList && getMacrosList?.filter((data: any) => data.object === 'App');

  const appOpt =
    AppParametersOptions &&
    AppParametersOptions?.map((opt: any) => {
      return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
    });

  //filter site parameters
  const SiteParametersOptions =
    getMacrosList && getMacrosList?.filter((data: any) => data.object === 'Site');

  const siteOpt =
    SiteParametersOptions &&
    SiteParametersOptions?.map((opt: any) => {
      return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
    });

  //filter Impression parameters
  const ImpressionParametersOptions =
    getMacrosList && getMacrosList?.filter((data: any) => data.object === 'Imp');

  const impressionOpt =
    ImpressionParametersOptions &&
    ImpressionParametersOptions?.map((opt: any) => {
      return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
    });

  //filter device parameters
  const DeviceParametersOptions =
    getMacrosList && getMacrosList?.filter((data: any) => data.object === 'Device');

  const deviceOpt =
    DeviceParametersOptions &&
    DeviceParametersOptions?.map((opt: any) => {
      return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
    });

  //filter content parameters
  const ContentParametersOptions =
    getMacrosList && getMacrosList?.filter((data: any) => data.object === 'Content');

  const contentOpt =
    ContentParametersOptions &&
    ContentParametersOptions?.map((opt: any) => {
      return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
    });

  //filter site content parameters
  const SiteContentParametersOptions =
    getMacrosList && getMacrosList?.filter((data: any) => data.object === 'SiteContent');

  const siteContentOpt =
    SiteContentParametersOptions &&
    SiteContentParametersOptions?.map((opt: any) => {
      return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
    });

  //filter User parameters
  const UserParametersOptions =
    getMacrosList &&
    getMacrosList?.filter((data: any) => data.object === 'User' || data.object === 'Regs');

  const userOpt =
    UserParametersOptions &&
    UserParametersOptions?.map((opt: any) => {
      return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
    });

  //filter custome parameters
  const CustomParametersOptions =
    getMacrosList && getMacrosList?.filter((data: any) => !excludeObjects.includes(data.object));

  const customOpt =
    CustomParametersOptions &&
    CustomParametersOptions?.map((opt: any) => {
      return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
    });

  //Selected site parameters create url
  useEffect(() => {
    if (selectedSiteParameters.length > 0) {
      let bUrl = '';
      selectedSiteParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setSelectedSiteParamUrl(bUrl);
    } else {
      setSelectedSiteParamUrl('');
    }
  }, [selectedSiteParameters]);

  //Selected app parameters create url
  useEffect(() => {
    if (selectedAppParameters.length > 0) {
      let bUrl = '';
      selectedAppParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setSelectedAppParamUrl(bUrl);
    } else {
      setSelectedAppParamUrl('');
    }
  }, [selectedAppParameters]);

  //app parameter create url
  useEffect(() => {
    if (appParameters.length > 0) {
      let bUrl = '';
      appParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setAppParamUrl(bUrl);
    } else {
      setAppParamUrl('');
    }
  }, [appParameters]);

  //site parameter create url
  useEffect(() => {
    if (siteParameters.length > 0) {
      let bUrl = '';
      siteParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setSiteParamUrl(bUrl);
    } else {
      setSiteParamUrl('');
    }
  }, [siteParameters]);

  //Impression parameter create url
  useEffect(() => {
    if (impressionParameters.length > 0) {
      let bUrl = '';
      impressionParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setImpressionParamUrl(bUrl);
    } else {
      setImpressionParamUrl('');
    }
  }, [impressionParameters]);

  //device Parameters create url
  useEffect(() => {
    if (deviceParameters.length > 0) {
      let bUrl = '';
      deviceParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setDeviceParamUrl(bUrl);
    } else {
      setDeviceParamUrl('');
    }
  }, [deviceParameters]);

  //Content Parameters create url
  useEffect(() => {
    if (contentParameters.length > 0) {
      let bUrl = '';
      contentParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setContentParamUrl(bUrl);
    } else {
      setContentParamUrl('');
    }
  }, [contentParameters]);

  //Site Content Parameters create url
  useEffect(() => {
    if (siteContentParameters.length > 0) {
      let bUrl = '';
      siteContentParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setSiteContentParamUrl(bUrl);
    } else {
      setSiteContentParamUrl('');
    }
  }, [siteContentParameters]);

  //User Parameters create url
  useEffect(() => {
    if (userParameters.length > 0) {
      let bUrl = '';
      userParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setUserParamUrl(bUrl);
    } else {
      setUserParamUrl('');
    }
  }, [userParameters]);

  //custom Parameters create url
  useEffect(() => {
    if (customParameters.length >= 0) {
      let bUrl = '';
      customParameters.forEach((appObj: any) => {
        bUrl = bUrl + '&' + appObj.param + '=' + appObj.value;
      });
      setCustomParamUrl(bUrl);
    } else {
      setCustomParamUrl('');
    }
  }, [customParameters]);

  const initiateMacrosListPostAPI = () => {
    const payload = {
      device_type: deviceType === 'app' ? 'app' : 'website',
    };
    const payloadDetails = {
      device_type: selectedTagDetails?.config?.type,
    };
    setIsDataLoading(true);
    getMacrosListPostAPI(selectedTagDetails ? payloadDetails : payload)
      .then((data: any) => {
        setGetMacrosList(data.data);
        setIsDataLoading(false);
      })
      .catch((error: any) => {
        // toast.error('Service unavailable, Check back after sometime.');
      });
  };

  useEffect(() => {
    if (deviceType === selectedTagDetails?.config?.type) {
      initiateMacrosListPostAPI();
    } else {
      initiateMacrosListPostAPI();
    }
  }, [deviceType, selectedTagDetails?.config?.type]);

  props.appParamFinalUrl(appParamUrl);
  props.siteParamFinalUrl(siteParamUrl);
  props.impParamUFinalUrl(impressionParamUrl);
  props.deviceParamFinalUrl(deviceParamUrl);
  props.contentParamFinalUrl(contentParamUrl);
  props.siteContentParamFinalUrl(siteContentParamUrl);
  props.userParamFinalUrl(userParamUrl);
  props.customParamFinalUrl(customParamUrl);
  props.selectedAppParamFinalUrl(selectedAppParamUrl);
  props.selectedSiteParamFinalUrl(selectedSiteParamUrl);

  const [macros, setMacros] = useState([]);
  const [selectedMacros, setSelectMacros] = useState([]);
  const [currentKey, setCurrentKey] = useState('All');

  const filterBy = deviceType !== 'app' ? ['App', 'Content'] : ['Site', 'SiteContent'];

  useEffect(() => {
    const filteredAllMacros = getMacrosList
      ?.filter((data: any) => !data.object.includes(filterBy))
      ?.map((opt: any) => {
        return {
          id: opt.id,
          label: opt.name,
          value: opt.macros,
          param: opt.parameters,
          default: opt.default,
        };
      });

    setMacros(filteredAllMacros);
    setSelectMacros(
      filteredAllMacros.filter((x: any) => {
        return x.default;
      })
    );
  }, [deviceType, getMacrosList]);

  useMemo(() => {
    const filteredAllMacros = getMacrosList
      ?.filter((data: any) => !data.object.includes(filterBy))
      ?.map((opt: any) => {
        return {
          id: opt.id,
          label: opt.name,
          value: opt.macros,
          param: opt.parameters,
          default: opt.default,
        };
      });

    if (currentKey === 'All') {
      setMacros(filteredAllMacros);
    }
  }, [deviceType, getMacrosList, currentKey]);

  const FilteredMacros = (filterKey: string) => {
    const filteredAllMacros = getMacrosList
      ?.filter((data: any) => !data.object.includes(filterBy))
      ?.map((opt: any) => {
        return {
          id: opt.id,
          label: opt.name,
          value: opt.macros,
          param: opt.parameters,
          default: opt.default,
        };
      });

    const appOpt = getMacrosList
      ?.filter((data: any) => data.object === 'App')
      ?.map((opt: any) => {
        return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
      });

    const siteOpt = getMacrosList
      ?.filter((data: any) => data.object === 'Site')
      ?.map((opt: any) => {
        return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
      });

    const impressionOpt = getMacrosList
      ?.filter((data: any) => data.object === 'Imp')
      ?.map((opt: any) => {
        return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
      });

    const deviceOpt = getMacrosList
      ?.filter((data: any) => data.object === 'Device')
      ?.map((opt: any) => {
        return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
      });

    const contentOpt = getMacrosList
      ?.filter((data: any) => data.object === 'Content')
      ?.map((opt: any) => {
        return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
      });

    const siteContentOpt = getMacrosList
      ?.filter((data: any) => data.object === 'SiteContent')
      ?.map((opt: any) => {
        return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
      });

    const userOpt = getMacrosList
      ?.filter((data: any) => data.object === 'User' || data.object === 'Regs')
      ?.map((opt: any) => {
        return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
      });

    const customOpt = getMacrosList
      ?.filter((data: any) => !excludeObjects.includes(data.object))
      ?.map((opt: any) => {
        return { id: opt.id, label: opt.name, value: opt.macros, param: opt.parameters };
      });

    if (filterKey === 'All') {
      setMacros(filteredAllMacros);
    }
    if (filterKey === 'Publisher') {
      setMacros(deviceType === 'app' ? appOpt : siteOpt);
    }
    if (filterKey === 'Impressions') {
      setMacros(impressionOpt);
    }
    if (filterKey === 'Device') {
      setMacros(deviceOpt);
    }
    if (filterKey === 'Content') {
      setMacros(deviceType === 'app' ? contentOpt : siteContentOpt);
    }
    if (filterKey === 'User') {
      setMacros(userOpt);
    }
    if (filterKey === 'Custom') {
      setMacros(customOpt);
    }
  };

  useEffect(() => {
    if (selectedMacros.length > 0) {
      let bUrl = '';
      selectedMacros.forEach((obj: any) => {
        bUrl = bUrl + '&' + obj.param + '=' + obj.value;
      });
      setContentParamUrl(bUrl);
    } else {
      setContentParamUrl('');
    }
  }, [selectedMacros]);

  return (
    <>
      <div className='row mb-3 macros-dropdown'>
        <div className='col-12'>
          <label className='label'>
            {glossary.ssp_createssai_success_macros_instruction}
            <a
              href='https://help.infy.tv/en/articles/6926417-macros'
              target={'_blank'}
              rel='noreferrer'
              title='Help'
            >
              <MdInfoOutline className='svg-icon ms-1' fontSize={20} />
            </a>
          </label>
        </div>
        <div className='macro-dropdown col-12'>
          {isDataLoading ? (
            <>
              &nbsp;
              <ButtonSkeleton height={38} width={'100%'} />
            </>
          ) : (
            <MacroDropdown
              label={'Macros'}
              option={macros}
              value={selectedMacros}
              onSelect={setSelectMacros}
              isMulti
              filtersOption={[
                'All',
                'Publisher',
                'Impressions',
                'Device',
                'Content',
                'User',
                'Custom',
              ]}
              filter={(e: string) => {
                FilteredMacros(e);
                setCurrentKey(e);
              }}
              hasSelectAll
              currentKey={currentKey}
              setCurrentKey={(e: string) => setCurrentKey(e)}
            />
          )}
        </div>
        {/* <div className='col'>
          <div className='form-group react-select-dropdown app-parameters'>
            {createTag ? (
              <>
                {deviceType === 'app' && (
                  <Dropdown
                    kind='multi'
                    label={glossary.ssp_createssai_success_macros_publisher_ddl_label}
                    option={appOpt}
                    value={selectedAppParameters}
                    onchange={setSelectedAppParameters}
                    search={true}
                  />
                )}
                {deviceType === 'website' && (
                  <Dropdown
                    kind='multi'
                    label={glossary.ssp_createssai_success_macros_publisher_ddl_label}
                    option={siteOpt}
                    value={selectedSiteParameters}
                    onchange={setSelectedSiteParameters}
                    search={true}
                  />
                )}
              </>
            ) : (
              <>
                {deviceType === 'app' && (
                  <Dropdown
                    kind='multi'
                    label={glossary.ssp_createssai_success_macros_publisher_ddl_label}
                    option={appOpt}
                    value={appParameters}
                    onchange={setAppParameters}
                    search={true}
                  />
                )}
                {deviceType === 'website' && (
                  <Dropdown
                    kind='multi'
                    label={glossary.ssp_createssai_success_macros_publisher_ddl_label}
                    option={siteOpt}
                    value={siteParameters}
                    onchange={setSiteParameters}
                    search={true}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className='col'>
          <div className='form-group react-select-dropdown device-parameters'>
            <Dropdown
              kind='multi'
              label={glossary.ssp_createssai_success_macros_imp_ddl_label}
              option={impressionOpt}
              value={impressionParameters}
              onchange={setImpressionParameters}
              search={true}
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group react-select-dropdown device-parameters'>
            <Dropdown
              kind='multi'
              label={glossary.ssp_createssai_success_macros_device_ddl_label}
              option={deviceOpt}
              value={deviceParameters}
              onchange={setDeviceParameters}
              search={true}
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group react-select-dropdown stream-parameters'>
            {deviceType === 'app' && (
              <Dropdown
                kind='multi'
                label={glossary.ssp_createssai_success_macros_content_ddl_label}
                option={contentOpt}
                value={contentParameters}
                onchange={setContentParameters}
                search={true}
              />
            )}
            {deviceType === 'website' && (
              <Dropdown
                kind='multi'
                label={glossary.ssp_createssai_success_macros_content_ddl_label}
                option={siteContentOpt}
                value={siteContentParameters}
                onchange={setSiteContentParameters}
                search={true}
              />
            )}
          </div>
        </div>
        <div className='col'>
          <div className='form-group react-select-dropdown privacy-parameters'>
            <Dropdown
              kind='multi'
              label={glossary.ssp_createssai_success_macros_user_ddl_label}
              option={userOpt}
              value={userParameters}
              onchange={setUserParameters}
              search={true}
            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group react-select-dropdown privacy-parameters'>
            <Dropdown
              kind='multi'
              label={glossary.ssp_createssai_success_macros_custom_ddl_label}
              option={customOpt}
              value={customParameters}
              onchange={setCustomParameters}
              search={true}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MacrosDropDown;
