import { useEffect, useState } from 'react';
import store from '../../../states';
import { toast } from 'react-toastify';
import { IoCopyOutline } from 'react-icons/io5';

const JavaScriptCode = ({
  createTag,
  selectedTagDetails,
  cutomerData,
  extentionData,
  websiteTodos,
  resolution,
}: any) => {
  const [glossary, sGlossary] = useState<any>({});

  //GET glossary
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);
  return (
    <>
      <div className='createtag-vasturl' style={{ minHeight: 'auto', height: 'auto' }}>
        {createTag ? (
          <>
            <p className='h-100'>
              {`<div
                  class="gen-ext"
                  data-tag-id="${extentionData?.tag_id || extentionData?.id}"
                  data-company-id="${cutomerData?.id}"
                  ${
                    extentionData?.view_type === 'interstitial' ||
                    extentionData?.config?.view_type === 'interstitial'
                      ? `style="width: ${resolution?.width || '300px'}; height: ${
                          resolution?.height || '600px'
                        };"`
                      : ''
                  }
                    ></div>`}
            </p>
            <div className='createtag-vasturl-icon'>
              <IoCopyOutline
                className='svg-icon'
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      `<div
                          class="gen-ext"
                          data-tag-id="${extentionData?.tag_id || extentionData?.id}"
                          data-company-id="${cutomerData?.id}"
                          ${
                            extentionData?.view_type === 'interstitial' ||
                            extentionData?.config?.view_type === 'interstitial'
                              ? `style="width: ${resolution?.width || '300px'}; height: ${
                                  resolution?.height || '600px'
                                };"`
                              : ''
                          }
                        ></div>`
                    )
                    .toString();
                  toast.success(glossary.ssp_createtag_success_vast_copy_success_msg);
                }}
                title={'Copy URL'}
                fontSize={20}
              />
            </div>
          </>
        ) : (
          <>
            <p className='h-100'>
              {`<div
                  class="gen-ext"
                  data-tag-id="${selectedTagDetails?.id}"
                  data-company-id="${cutomerData?.id}"
                  ${
                    selectedTagDetails?.config?.extension_config?.view_type === 'interstitial' ||
                    selectedTagDetails?.config?.view_type === 'interstitial'
                      ? `style="width: ${resolution?.width || '300px'}; height: ${
                          resolution?.height || '600px'
                        };"`
                      : ''
                  }
                ></div>`}
            </p>
            <div className='createtag-vasturl-icon'>
              <IoCopyOutline
                className='svg-icon'
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      `<div
                        class="gen-ext"
                        data-tag-id="${selectedTagDetails?.id}"
                        data-company-id="${cutomerData?.id}"
                        ${
                          selectedTagDetails?.config?.extension_config?.view_type ===
                            'interstitial' ||
                          selectedTagDetails?.config?.view_type === 'interstitial'
                            ? `style="width: ${resolution?.width || '300px'}; height: ${
                                resolution?.height || '600px'
                              };"`
                            : ''
                        }
                      ></div>`
                    )
                    .toString();
                  toast.success(glossary.ssp_createtag_success_vast_copy_success_msg);
                }}
                title={'Copy URL'}
                fontSize={20}
              />
            </div>
          </>
        )}
      </div>
      <div className='row'>
        <div className='col-12'>
          <h4 className='my-3'>Instruction</h4>
          <p className='mb-2'>
            <span className='fw-lighter'>Step-1: </span>
            Add `div` where you want to add a content extension to your page. Ideally, you can add
            it at the end of your article
          </p>
          <p className='mb-0'>
            <span className='fw-lighter'>Step-2: </span> Include this tag inside the body of your
            page:
            <div
              className='border mb-0 pb-0 position-relative p-2 mt-2'
              style={{ borderRadius: '4px', height: '92px' }}
            >
              <code
                style={{
                  color: '#212529',
                }}
              >
                &lt;script
                src=&apos;https://media.begenuin.com/cxr/gen_ext.min.js&apos;&gt;&lt;/script&gt;
              </code>
              <div
                style={{
                  right: '0px',
                  bottom: '2px',
                  position: 'absolute',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <IoCopyOutline
                  className='svg-icon'
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        `<script src='https://media.begenuin.com/cxr/gen_ext.min.js'></script>`
                      )
                      .toString();
                    toast.success(glossary.ssp_createtag_success_vast_copy_success_msg);
                  }}
                  title={'Copy Script'}
                  fontSize={20}
                />
              </div>
            </div>
          </p>
          <p className='mt-1'>
            <a
              style={{ color: '#731780' }}
              href='https://help.infy.tv/en/articles/7241019-how-to-integrate-extension-in-your-website'
              target={'_blank'}
              rel='noreferrer'
            >
              Know More
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default JavaScriptCode;
