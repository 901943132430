import { useState } from 'react';
import ContentLoader from 'react-content-loader';

import Line from '../charts/line';

const Loader = (props: any) => {
  const { empty } = props;
  const [show, sShow] = useState(false);
  return (
    <>
      {empty ? (
        <div
          style={{ display: 'flex', columnGap: 45, minHeight: 300 }}
          onMouseEnter={() => sShow(true)}
          onMouseLeave={() => sShow(false)}
        >
          {show ? (
            <Line
              data={{
                labels: [
                  'Your Tag',
                  'Your Tag',
                  'Your Tag',
                  'Your Tag',
                  'Your Tag',
                  'Your Tag',
                  'Your Tag',
                  'Your Tag',
                  'Your Tag',
                  'Your Tag',
                ],
                values: [15, 30, 45, 60, 75, 90, 105, 120, 135, 150],
              }}
            />
          ) : (
            <ContentLoader
              title=''
              speed={0}
              width={'100%'}
              height={300}
              viewBox='0 0 460 250'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
              {...props}
            >
              <rect x='430' y='10' rx='0' ry='0' width='24' height='230' />
              <rect x='330' y='50' rx='0' ry='0' width='24' height='190' />
              <rect x='280' y='70' rx='0' ry='0' width='24' height='170' />
              <rect x='230' y='90' rx='0' ry='0' width='24' height='150' />
              <rect x='155' y='343' rx='0' ry='0' width='24' height='130' />
              <rect x='130' y='130' rx='0' ry='0' width='24' height='110' />
              <rect x='74' y='297' rx='0' ry='0' width='24' height='90' />
              <rect x='380' y='30' rx='0' ry='0' width='24' height='210' />
              <rect x='180' y='110' rx='0' ry='0' width='24' height='130' />
              <rect x='80' y='150' rx='0' ry='0' width='24' height='90' />
              <rect x='30' y='170' rx='0' ry='0' width='24' height='70' />
            </ContentLoader>
          )}
        </div>
      ) : (
        <ContentLoader
          title=''
          speed={0}
          width={460}
          height={250}
          viewBox='0 0 460 250'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          {...props}
        >
          {/* <rect x='430' y='10' rx='0' ry='0' width='24' height='230' /> */}
          <rect x='330' y='50' rx='0' ry='0' width='24' height='190' />
          <rect x='280' y='70' rx='0' ry='0' width='24' height='170' />
          <rect x='230' y='90' rx='0' ry='0' width='24' height='150' />
          <rect x='155' y='343' rx='0' ry='0' width='24' height='130' />
          <rect x='130' y='130' rx='0' ry='0' width='24' height='110' />
          <rect x='74' y='297' rx='0' ry='0' width='24' height='90' />
          <rect x='380' y='30' rx='0' ry='0' width='24' height='210' />
          <rect x='180' y='110' rx='0' ry='0' width='24' height='130' />
          <rect x='80' y='150' rx='0' ry='0' width='24' height='90' />
          <rect x='30' y='170' rx='0' ry='0' width='24' height='70' />
        </ContentLoader>
      )}
    </>
  );
};

export default Loader;
