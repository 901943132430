import { useEffect, useState } from 'react';
import store from '../../states';
import { useHistory } from 'react-router-dom';

export default () => {
  const history = useHistory();
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  //const BASE_URL = process.env.REACT_APP_API_URL ? 'https://app.infy.tv' : 'https://sbapp.infy.tv';

  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     // This function will be called whenever the user navigates
  //     // forward or backward in the browser history.
  //     history.push(`${BASE_URL}/login`);
  //     //console.log('User navigated', history);
  //   });

  //   // Return a cleanup function that will be called when the
  //   // component is unmounted.
  //   return () => {
  //     unlisten();
  //   };
  // }, [history]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: 'calc(100vh - 66px)',
      }}
    >
      <div
        className={'card-box'}
        style={{
          margin: 'auto',
          rowGap: '16px',
          width: '550px',
          display: 'flex',
          position: 'relative',
          padding: '72px 110px',
          flexDirection: 'column',
        }}
      >
        <h3 style={{ fontWeight: 300, textAlign: 'center' }}>{glossary.signup_request_title}</h3>
        <p style={{ textAlign: 'center' }}>{glossary.signup_request_description}</p>
        <div
          className={'row'}
          style={{
            right: '0',
            width: '100%',
            bottom: '-50px',
            position: 'absolute',
            justifyContent: 'flex-end',
          }}
        >
          <a
            style={{
              fontWeight: 200,
              margin: '6px 12px',
              width: 'fit-content',
            }}
            href='https://infy.tv/privacy-policy'
            target='_blank'
            rel='noreferrer'
          >
            {glossary.signup_request_hyperlink_privacy_policy}
          </a>
          <a
            style={{
              fontWeight: 200,
              margin: '6px 12px',
              width: 'fit-content',
            }}
            href='https://infy.tv/terms-conditions'
            target='_blank'
            rel='noreferrer'
          >
            {glossary.signup_request_hyperlink_terms}
          </a>
        </div>
      </div>
    </div>
  );
};
