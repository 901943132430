import { useState } from 'react';
import { Modal } from 'react-bootstrap';
// import CreativeCreate from '../components/creatives/Creative-create';
import NewCreativeCreate from '../components/creatives/NewCreative-create';

interface EditProps {
  show: boolean;
  onHide(): void;
  glossary: any;
  pSection: string; // '1' | '2'
  creativeDetails: any;
  creativeConfig: any;
  genuinUserInfo: any;
  mode: 'edit';
  controller(_data: any, t: string): void;
}

const CreativeEdit = ({
  show,
  onHide,
  glossary,
  pSection,
  creativeDetails,
  creativeConfig,
  genuinUserInfo,
  mode,
  controller,
}: EditProps) => {
  const [currentSection, setCurrentSection] = useState('');

  return (
    <>
      <Modal
        onHide={() => {
          onHide();
        }}
        show={show}
        centered
        size={currentSection !== '2' ? 'xl' : 'lg'}
        className={currentSection !== '2' ? 'creative-modal' : ''}
      >
        <Modal.Header>
          <h3 style={{ fontWeight: 'lighter', fontSize: '24px' }}>
            {pSection === '1' ? 'Update Reel Creative' : 'Reel Creative Details'}
          </h3>
        </Modal.Header>

        <NewCreativeCreate
          show={show}
          onHide={onHide}
          glossary={glossary}
          pSection={pSection}
          genuinUserInfo={genuinUserInfo}
          mode={mode}
          creativeDetails={creativeDetails}
          creativeConfig={creativeConfig}
          controller={controller}
          getCurrentSection={setCurrentSection}
        />

        {/* <CreativeCreate
          show={show}
          onHide={onHide}
          glossary={glossary}
          pSection={pSection}
          genuinUserInfo={genuinUserInfo}
          mode={mode}
          creativeDetails={creativeDetails}
          creativeConfig={creativeConfig}
          controller={controller}
        /> */}
      </Modal>
    </>
  );
};

export default CreativeEdit;
