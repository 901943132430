import { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlinePlayCircle, AiOutlinePauseCircle } from 'react-icons/ai';

function VideoPlayer(Props: any) {
  const { show, onHide, video } = Props;

  const videoRef = useRef(null);

  // const [isPlaying, setIsPlaying] = useState(false);

  // const handlePlayPause = () => {
  //   const video: any = videoRef.current;
  //   if (video.ended) {
  //     video.currentTime = 0;
  //   }
  //   setIsPlaying(!isPlaying);
  //   if (isPlaying) {
  //     video.pause();
  //   } else {
  //     video.play();
  //   }
  // };

  // const handleVideoEnd = () => {
  //   setIsPlaying(false);
  // };

  return (
    <>
      <Modal show={show} onHide={onHide} size='lg' centered>
        <Modal.Body style={{ height: '500px' }}>
          {/* <div className='video-container'> */}
          <video
            ref={videoRef}
            width={'100%'}
            height={'100%'}
            // autoPlay
            // muted
            // onEnded={handleVideoEnd}
            controls
            controlsList='nodownload'
            disablePictureInPicture
            // style={{ borderRadius: '15px' }}
          >
            <source src={video} type={'video/mp4'} />
          </video>
          {/* <div className='play-icon'>
              {!isPlaying ? (
                <AiOutlinePlayCircle className='' fontSize={30} onClick={handlePlayPause} />
              ) : (
                <AiOutlinePauseCircle fontSize={30} onClick={handlePlayPause} />
              )}
            </div> */}
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer className='py-2'>
          <button type='button' className='button button-secondary' onClick={onHide}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VideoPlayer;
