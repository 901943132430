import { useCallback, useEffect, useState } from 'react';
import Card from '../widgets/card';
import CardLoader from '../loaders/card';
import { MdSearch } from 'react-icons/md';
import { FixedSizeList } from 'react-window';
import Button from '../widgets/button';
import ButtonSkeleton from '../loaders/button';
import { AiOutlineHome } from 'react-icons/ai';

interface Props {
  title: string;
  kind: 'deal';
  size: 'collapsed' | 'expanded';
  card: 'collapsed' | 'expanded';
  items: any[];
  sidebar?(size: string): void;
  getTags?(): void;
  empty?: boolean;
  info?(card: string): void;
  selected?: string;
  currentPage?: number;
  loading?: boolean;
  hasMore?: boolean;
  userType?: string;
  params?: any;
  handleParams?: any;
  searchToggle?: any;
  allData?: any;
  action?: {
    icon: string;
    text: string;
    click(): void;
    className?: string;
  };
  overviewAction?: {
    text: string;
    click(): void;
    className?: string;
    isOverview?: boolean;
  };
}

const SspDealsSidebar = (props: Props) => {
  const {
    title,
    kind,
    size,
    card,
    items,
    info,
    getTags,
    currentPage,
    loading,
    hasMore,
    userType,
    params,
    handleParams,
    searchToggle,
    action,
    overviewAction,
    allData,
  } = props;

  const [displayItems, sDisplayItems] = useState(items);
  const [selected, sSelected] = useState(props.selected);
  const [sideSize, sSideSize] = useState(size);
  const [cardSize, sCardSize] = useState(card);
  const [searchBar, sSearchBar] = useState(false);
  const [searchContent, sSearchContent] = useState('');

  useEffect(() => {
    sSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {
    sDisplayItems(items);
  }, [items]);

  const select = (id: string, item?: any) => {
    sSelected(id);
    if (info) info(id);
  };

  useEffect(() => {
    sSideSize(size);
  }, [size]);

  useEffect(() => {
    if (searchContent !== params.query) {
      const getData: any = setTimeout(() => {
        handleParams(0, searchContent, true);
        sDisplayItems([]);
      }, 300);
      return () => clearTimeout(getData);
    }
  }, [searchContent]);

  /***** Data Render on scroll *****/
  const renderCard = ({ index, style }: any) => {
    const item = displayItems && displayItems[index];
    return (
      <div style={style}>
        <Card
          id={item.id}
          key={index}
          text={item.name || item.company_name}
          kind={kind}
          size={cardSize}
          info={{
            items: item,
            type: item.config.deal_type ?? 'pg',
            cpm: item.cpm_formatted ?? '-',
            minCpm: item.config.min_cpm_formatted ?? '-',
            maxCpm: item.config.max_cpm_formatted ?? '-',
            impressions: item.config.pg_impression_formatted ?? '-',
            publishers: item.publishers,
            endpointItem: item,
            status: item.status,
            userType: 'publisher',
          }}
          selected={selected}
          click={() => select(item.id, item)}
          getTags={getTags}
          allData={allData}
        />
      </div>
    );
  };

  const lastItem = displayItems[displayItems?.length - 1];
  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      if (
        displayItems?.length >= 500 &&
        hasMore &&
        visibleStopIndex === displayItems?.length - 1 &&
        lastItem
      ) {
        handleParams(params.page + 1, params.query, params.sortBy, true);
      }
    },
    [params.page, params.query, params.sortBy, displayItems?.length, lastItem]
  );
  /***** Onscroll Fetch Data End *****/

  const handleSearch = () => {
    if (searchBar) {
      sSearchBar(false);
      props.searchToggle(searchBar);
    } else {
      sSearchBar(true);
      props.searchToggle(searchBar);
    }
  };

  return (
    <div className='newsidebar'>
      <div className={'sidebar-row sidebar-row-first'}>
        <div className={'sidebar-col'}>
          <h3>{title}</h3>
        </div>
        <div className='sidebar-col'>
          <div className={'sidebar-row'}>
            {searchBar && (
              <div className={'sidebar-col'}>
                <input
                  autoFocus
                  onChange={(e) => {
                    if (e.target.value === '') {
                      handleParams(0, '', params.sortBy, true);
                      sDisplayItems([]);
                    }
                    sSearchContent(e.target.value);
                  }}
                  id={'search-bar'}
                  value={searchContent}
                  type={'search'}
                  maxLength={32}
                  placeholder={'Search Keywords..'}
                  className='search-input ps-0'
                  style={{
                    width: '245px',
                    maxWidth: '245px',
                    position: 'absolute',
                    // position: expand ? 'inherit' : 'absolute',
                    left: '25px',
                    height: '35px',
                  }}
                />
              </div>
            )}
            <div className={'sidebar-col'}>
              <MdSearch
                className='svg-icon'
                id={'search-btn'}
                onClick={() => {
                  handleSearch();
                }}
                title={'Search'}
                fontSize={20}
                style={{ marginLeft: 8, marginRight: 5 }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={'sidebar-row sidebar-row-secound'}>
        <div className={'sidebar-col'} id='first-btn'>
          {action && (
            <div style={{ marginTop: 16, marginBottom: 16, height: '37px' }}>
              {loading && params?.page === 0 && displayItems.length < 1 ? (
                <ButtonSkeleton height={'40px'} width={'115px'} />
              ) : (
                <Button
                  kind={'primary'}
                  icon={action.icon}
                  text={action.text}
                  click={action.click}
                  customClass={action.className}
                />
              )}
            </div>
          )}
        </div>

        <div className={'sidebar-col'}>
          {overviewAction && displayItems.length > 0 && (
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <button
                className={
                  !overviewAction.isOverview
                    ? 'button button-overview p-0'
                    : 'button-secondary button p-0'
                }
                onClick={overviewAction.click}
                style={{ minWidth: '40px' }}
              >
                <AiOutlineHome fontSize={20} />
              </button>
              {/* <Button
                kind={overviewAction.isOverview ? 'secondary' : 'secondary'}
                text={overviewAction.text}
                click={overviewAction.click}
                customClass={
                  !overviewAction.isOverview ? 'button-overview' : overviewAction.className
                }
              /> */}
            </div>
          )}
        </div>
      </div>

      <div className={'sidebar-list'} style={{ minWidth: 334 }}>
        <FixedSizeList
          height={1000}
          width={300}
          itemCount={displayItems.length}
          itemSize={130}
          onItemsRendered={handleItemsRendered}
          style={{
            width: '100%',
            height: displayItems.length > 0 ? `calc(100vh - 205px)` : `0px`,
            overflowY: 'auto',
          }}
        >
          {renderCard}
        </FixedSizeList>

        {/***** Skeleton View *****/}
        {loading && (
          <>
            <CardLoader />
            <CardLoader />
            <CardLoader />
          </>
        )}
        {/***** When data not found on search *****/}
        {(!displayItems || displayItems.length < 1) && (
          <>{props.empty && <p style={{ textAlign: 'center', padding: 24 }}>No Deals</p>}</>
        )}
      </div>
    </div>
  );
};

export default SspDealsSidebar;
