import { useEffect, useState } from 'react';
import { getRequestedDealInventoriesAPI } from '../../services/deals';
import TableSkeleton from '../loaders/table';
import ReactTable from '../tables/reactTable';
import useAuthentication from '../contexts/OverViewContext';
import consumerData from '../contexts/OverViewContext';

const RequestedDealPublisher = (props: any) => {
  // const { getRequestedDealInventoriesAPI }:any = useAuthentication();
  // const {
  //   dealInventories,
  //     sDealInventories,
  // }:any = consumerData();
  const { dealID, empty } = props;

  const [dealInventories, sDealInventories] = useState<any>([]);
  const [skeleton, sSkeleton] = useState(false);
  const [previousID, sPreviousID] = useState(dealID);

  const getReqDealInventoriesList = (id: any) => {
    const payload = {
      requested_deal_id: id,
    };
    sSkeleton(true);
    getRequestedDealInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          sPreviousID(id);
          sDealInventories(data ?? []);
        }
        sSkeleton(false);
      })
      .catch((error: any) => {
        //ok
      });
  };

  /* While Make any action in table */
  const updateTableData = (id: any) => {
    const payload = {
      requested_deal_id: id,
    };
    getRequestedDealInventoriesAPI(payload)
      .then((data: any) => {
        if (data) {
          sDealInventories(data ?? []);
        }
      })
      .catch((error: any) => {
        //ok
      });
  };

  useEffect(() => {
    if (dealID !== '' && dealID !== undefined) {
      getReqDealInventoriesList(dealID);
    }
  }, [dealID]);

  return (
    <>
      <div>
        {skeleton ? (
          <TableSkeleton columns={4} rows={10} rowSpacing={2} tableHeight={500} />
        ) : (
          <ReactTable
            tableColumns={previousID === dealID ? dealInventories.columns : []}
            tableRows={previousID === dealID ? dealInventories.data : []}
            search={true}
            searchPlaceholder={'Search Here...'}
            kind={'requested-deal-publishers'}
            styletable={true}
            dealID={dealID}
            controller={updateTableData}
          />
        )}
      </div>
    </>
  );
};

export default RequestedDealPublisher;
