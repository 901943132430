import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import validator from 'validator';

import Input from '../widgets/input';
import Button from '../widgets/button';

import { changePasswordAPI } from '../../services/authentication';

import { toast } from 'react-toastify';
import store from '../../states';

interface PasswordForm {
  password: string;
  confirm: string;
}

const passwordFormInitialValues = {
  password: '',
  confirm: '',
};

const passwordFormInitialErrors: PasswordForm = {
  password: '',
  confirm: '',
};

export default (props: any) => {
  const { show, onHide } = props;

  const [min, sMin] = useState<boolean>(false);
  const [num, sNum] = useState<boolean>(false);
  const [upc, sUpc] = useState<boolean>(false);
  const [spc, sSpc] = useState<boolean>(false);
  const [errors, sErrors] = useState<PasswordForm>(passwordFormInitialErrors);
  const [passwordForm, sPasswordForm] = useState<PasswordForm>(passwordFormInitialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updatePasswordFormValues = (key: string, value: any) => {
    sErrors(passwordFormInitialErrors);
    const newPassword = { ...passwordForm };
    newPassword[key as keyof typeof passwordFormInitialValues] = value;
    sPasswordForm(newPassword);
  };

  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const validatePasswordForm = () => {
    let valid = true;
    const newErrors = { ...errors };
    if (validator.isEmpty(passwordForm.password)) {
      valid = false;
      newErrors['password'] = 'Enter your Password';
    }
    if (validator.isEmpty(passwordForm.confirm)) {
      valid = false;
      newErrors['confirm'] = 'Confirm your Password';
    }
    if (passwordForm.password !== passwordForm.confirm) {
      valid = false;
      newErrors['confirm'] = 'Passwords do not Match';
    }
    if (valid) {
      return true;
    } else {
      sErrors(newErrors);
      return false;
    }
  };

  const initiatePasswordCreation = () => {
    if (validatePasswordForm())
      if (validatePasswordForm()) {
        const accessToken: any = localStorage.getItem('token');
        const payload = {
          password: passwordForm.password,
          confirm_password: passwordForm.confirm,
        };
        setIsSubmitting(true);
        changePasswordAPI(payload, accessToken)
          .then((data: any) => {
            if (data.status) {
              sMin(false);
              sUpc(false);
              sNum(false);
              sSpc(false);
              sErrors(passwordFormInitialErrors);
              onHide();
              toast.success('Your password has been updated.');
            } else {
              toast.error(data.msg);
            }
            setIsSubmitting(false);
          })
          .catch((error) => {
            // toast.error('Service unavailable, Check back after sometime.');
          });
      }
  };

  return (
    <>
      <Modal
        onHide={() => {
          sMin(false);
          sUpc(false);
          sNum(false);
          sSpc(false);
          sErrors(passwordFormInitialErrors);
          onHide();
        }}
        show={show}
        centered
        size={'lg'}
      >
        <Modal.Body>
          <div
            className={'card-box'}
            style={{
              margin: 'auto',
              rowGap: '16px',
              width: '550px',
              display: 'flex',
              padding: '72px 118px',
              flexDirection: 'column',
              boxShadow: 'none',
            }}
          >
            <h3 style={{ fontWeight: 300, textAlign: 'center' }}>Create New Password</h3>
            <p style={{ textAlign: 'center' }}>{glossary.create_password_title_description}</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                initiatePasswordCreation();
              }}
              style={{
                rowGap: '16px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div>
                <Input
                  style={{ paddingRight: '40px' }}
                  name={'password'}
                  kind='text'
                  label={'New Password'}
                  change={updatePasswordFormValues}
                  type={'password'}
                  error={errors.password}
                  placeholder={'Enter new Password'}
                  typing={(i: any) => {
                    sMin(i.target.value.length >= 6 ? true : false);
                    sNum(/\d/.test(i.target.value) ? true : false);
                    sUpc(/[A-Z]/.test(i.target.value) ? true : false);
                    sSpc(/[^A-Za-z0-9]/.test(i.target.value) ? true : false);
                  }}
                  length={100}
                />
              </div>
              <div>
                <Input
                  style={{ paddingRight: '40px' }}
                  name={'confirm'}
                  kind='text'
                  label={'Confirm Password'}
                  change={updatePasswordFormValues}
                  type={'password'}
                  error={errors.confirm}
                  placeholder={'Confirm Password'}
                  length={100}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <ul style={{ fontSize: '14px', margin: 'auto' }}>
                  <li style={{ color: min ? '#40C057' : '#A2A2A2' }}>Minimum 6 Characters</li>
                  <li style={{ color: num ? '#40C057' : '#A2A2A2' }}>Have One Number</li>
                  <li style={{ color: upc ? '#40C057' : '#A2A2A2' }}>Have One Uppercase</li>
                  <li style={{ color: spc ? '#40C057' : '#A2A2A2' }}>Have One Special Character</li>
                </ul>
              </div>
              {isSubmitting ? (
                <Button kind={'spinner'} text={glossary.loading} size={'full'} />
              ) : (
                <Button
                  kind={min && num && upc && spc ? 'primary' : 'disabled'}
                  text={'Set Password'}
                  size={'full'}
                  type={'submit'}
                />
              )}
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
