import { useEffect, useState } from 'react';
import { AiFillInstagram } from 'react-icons/ai';
import { BsYoutube } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import store from '../../../states';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikController from '../../comman/form/FormikController';
import { MdInfoOutline } from 'react-icons/md';

export const SoicalUrl = ({
  selectedTagDetails,
  importMoreVideo,
  loginSuccessful,
  validateOTP,
  socialURLprops,
  urlError,
  kind,
}: any) => {
  const [glossary, sGlossary] = useState<any>({});
  useEffect(() => {
    sGlossary(store.getState());
    const unsubscribe = store.subscribe(() => {
      sGlossary(store.getState());
    });
    return () => unsubscribe();
  }, []);

  const regex = {
    insta: /(https?)?:?(www)?instagram\.com/,
    youtube: /(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/,
    tiktok: /\bhttps?:\/\/(?:m|www)\.tiktok\.com\/.*\b(?:(?:usr|v|embed|user)\/|\?shareId=)(\d+)\b/,
  };

  const validationSchema = Yup.object().shape({
    instagram: Yup.string()
      .nullable()
      .trim()
      .notOneOf([''], glossary.ssp_createext_input_instagram_valid_error),

    youtube: Yup.string()
      .nullable()
      .trim()
      .notOneOf([''], glossary.ssp_createext_input_youtube_valid_error),

    tiktok: Yup.string()
      .nullable()
      .trim()
      .notOneOf([''], glossary.ssp_createext_input_tiktok_valid_error),

    // instagram: Yup.string().matches(
    //   regex?.insta,
    //   glossary.ssp_createext_input_instagram_valid_error
    // ),
    // youtube: Yup.string().matches(regex?.youtube, glossary.ssp_createext_input_youtube_valid_error),
    // tiktok: Yup.string().matches(regex?.tiktok, glossary.ssp_createext_input_tiktok_valid_error),
  });

  const initialValues = {
    instagram: selectedTagDetails?.import_video_data?.insta_url ?? '',
    youtube: selectedTagDetails?.import_video_data?.yt_url ?? '',
    tiktok: selectedTagDetails?.import_video_data?.tik_toc_url ?? '',
  };

  const submitForm = (e: any) => {
    socialURLprops({
      insta: e.instagram,
      youtube: e.youtube,
      tiktok: e.tiktok,
    });
  };

  const handleError = (_err: any) => {
    urlError(_err?.instagram || _err?.youtube || _err?.tiktok ? true : false);
  };

  return (
    <>
      {importMoreVideo && (loginSuccessful || validateOTP) && (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={submitForm}
        >
          {({ errors, values, handleSubmit }) => (
            <Form>
              <div className={`${kind === 'creatives' ? 'row' : 'row mt-4'}`}>
                <div className='form-group col-12 mb-2'>
                  <FormikController
                    kind='withvalidate'
                    control='input'
                    type='text'
                    icon={<AiFillInstagram fontSize={20} />}
                    label={glossary.ssp_createext_input_label_instagram}
                    placeholder={kind === 'creatives' ? 'john_doe' : 'example.com'}
                    name='instagram'
                    valid={{ error: errors, value: values }}
                    onBlur={(e: any) => {
                      handleSubmit(e);
                      kind !== 'creatives' && handleError(errors);
                    }}
                  />
                </div>

                <div className='form-group col-12 mb-2'>
                  <FormikController
                    kind='withvalidate'
                    control='input'
                    type='text'
                    icon={<BsYoutube fontSize={20} />}
                    label={glossary.ssp_createext_input_label_youtube}
                    placeholder={kind === 'creatives' ? 'johndoe_channel' : 'example.com'}
                    name='youtube'
                    valid={{ error: errors, value: values }}
                    onBlur={(e: any) => {
                      handleSubmit(e);
                      kind !== 'creatives' && handleError(errors);
                    }}
                  />
                </div>

                <div className='form-group col-12 mb-2'>
                  <FormikController
                    kind='withvalidate'
                    control='input'
                    type='text'
                    icon={<FaTiktok fontSize={20} />}
                    label={glossary.ssp_createext_input_label_tiktok}
                    placeholder={kind === 'creatives' ? 'john_doe' : 'example.com'}
                    name='tiktok'
                    valid={{ error: errors, value: values }}
                    onBlur={(e: any) => {
                      handleSubmit(e);
                      kind !== 'creatives' && handleError(errors);
                    }}
                  />
                </div>
              </div>
              {kind !== 'creatives' && (
                <div className='d-flex align-items-center mt-2'>
                  <MdInfoOutline className='s-svg-icon flex-shrink-0 me-2' fontSize={18} />
                  <p>
                    Above all inserted link will take 15-20 hours for import feed from that account.
                  </p>
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default SoicalUrl;
